import { Instance, types } from 'mobx-state-tree';
import {
    AgentTransactionDetailStore,
    createAgentTransactionDetailStore
} from './AgentTransactionDetailStore';
import {
    AgentTransactionHistoryStore,
    createAgentTransactionHistoryStore
} from './AgentTransactionHistoryStore';
import {
    AgentTransactionSearchStore,
    createAgentTransactionSearchStore
} from './AgentTransactionSearchStore';
import {
    AgencyBankingWithdrawRequestStore,
    createAgencyBankingWithdrawRequestStore
} from './AgencyBankingWithdrawRequestStore';
import {
    createRequestMoveFundsOutOfYafikaStore,
    RequestMoveFundsOutOfYafikaStore
} from './RequestMoveFundsOutOfYafikaStore';
import {
    AgentTransactionRequestHistoryStore,
    createAgentTransactionRequestHistoryStore
} from './AgentTransactionRequestHistoryStore';
import {
    CheckMoveFundsOutOfYafikaRequestStore,
    createCheckMoveFundsOutOfYafikaRequestStore
} from './CheckMoveFundsOutOfYafikaRequestStore';
import { AgentSearchStore, createAgentSearchStore } from './AgentSearchStore';
import { AgentBasicDetailsStore, createAgentBasicDetailsStore } from './AgentBasicDetailsStore';
import {
    CheckAgentStatusUpdateRequestStore,
    createCheckAgentStatusUpdateRequestStore
} from './CheckAgentStatusUpdateRequestStore';
import {
    AgentRequestHistorySectionStore,
    createAgentRequestHistorySectionStore
} from './AgentRequestHistorySectionStore';
import {
    AgentRequestsHistoryStore,
    createAgentRequestsHistoryStore
} from './AgentRequestsHistoryStore';
import { createUpdateAgentStateStore, UpdateAgentStateStore } from './UpdateAgentStateStore';

export const AgencyBankingStore = types.model({
    agentTransactionSearchStore: AgentTransactionSearchStore,
    agentTransactionDetailStore: AgentTransactionDetailStore,
    agentTransactionHistoryStore: AgentTransactionHistoryStore,
    agencyBankingWithdrawRequestStore: AgencyBankingWithdrawRequestStore,
    requestMoveFundsOutOfYafikaStore: RequestMoveFundsOutOfYafikaStore,
    agentTransactionRequestHistoryStore: AgentTransactionRequestHistoryStore,
    checkAgentStatusUpdateRequestStore: CheckAgentStatusUpdateRequestStore,
    checkMoveFundsOutOfYafikaRequestStore: CheckMoveFundsOutOfYafikaRequestStore,
    agentSearchStore: AgentSearchStore,
    agentBasicDetailsStore: AgentBasicDetailsStore,
    agentRequestsHistoryStore: AgentRequestsHistoryStore,
    agentRequestHistorySectionStore: AgentRequestHistorySectionStore,
    updateAgentStateStore: UpdateAgentStateStore
});

export const createAgencyBankingStore = (): Instance<typeof AgencyBankingStore> => {
    return AgencyBankingStore.create({
        agentTransactionSearchStore: createAgentTransactionSearchStore(),
        agentTransactionDetailStore: createAgentTransactionDetailStore(),
        agentTransactionHistoryStore: createAgentTransactionHistoryStore(),
        agencyBankingWithdrawRequestStore: createAgencyBankingWithdrawRequestStore(),
        requestMoveFundsOutOfYafikaStore: createRequestMoveFundsOutOfYafikaStore(),
        agentTransactionRequestHistoryStore: createAgentTransactionRequestHistoryStore(),
        checkMoveFundsOutOfYafikaRequestStore: createCheckMoveFundsOutOfYafikaRequestStore(),
        agentSearchStore: createAgentSearchStore(),
        agentBasicDetailsStore: createAgentBasicDetailsStore(),
        checkAgentStatusUpdateRequestStore: createCheckAgentStatusUpdateRequestStore(),
        agentRequestsHistoryStore: createAgentRequestsHistoryStore(),
        agentRequestHistorySectionStore: createAgentRequestHistorySectionStore(),
        updateAgentStateStore: createUpdateAgentStateStore()
    });
};
