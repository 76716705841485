import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SidebarComponent } from '../common/sidebar/SidebarComponent';
import { observer } from 'mobx-react';
import { useNetworkingErrorStore } from '../../networking/store/hooks';
import { NetworkingError } from '../../error/store/ErrorStore';
import { useHomeStore } from '../store/hooks';
import { ErrorDialog } from '../common/components/dialog/ErrorDialog';
import { useTranslation } from 'react-i18next';

export const HomeLayout = observer((): React.ReactElement => {
    const palette = usePalette();
    const navigate = useNavigate();
    const errorStore = useNetworkingErrorStore();
    const store = useHomeStore();
    const { t } = useTranslation();
    const [isInvalidRequestError, setIsInvalidRequestError] = useState(false);
    const [isTimeOutError, setIsTimeOutError] = useState(false);

    useEffect(() => {
        if (errorStore.error) {
            switch (errorStore.error) {
                case NetworkingError.InternalServerError:
                    navigate('/500');
                    break;
                case NetworkingError.PageNotFound:
                    navigate('/404');
                    break;
                case NetworkingError.RPCError:
                    navigate('/auth');
                    store.signOutUser();
                    break;
                case NetworkingError.InvalidRequest:
                    setIsInvalidRequestError(true);
                    break;
                case NetworkingError.TimeOut:
                    setIsTimeOutError(true);
                    break;
            }
            errorStore.removeError();
        }
    }, [errorStore.error]);

    return (
        <Box sx={{ display: 'flex', background: palette.background[300], minHeight: '100vh' }}>
            <Box sx={{ width: '240px', flexShrink: 0 }}>
                <SidebarComponent />
            </Box>
            <Box sx={{ flexGrow: 1, position: 'relative' }}>
                <Outlet />
                <ErrorDialog
                    title={t('common.somethingWentWrong')}
                    errorMessage={t('common.somethingWentWrongProcessingRequest')}
                    isErrorDialogOpen={isInvalidRequestError || isTimeOutError}
                    onClose={(): void => {
                        if (isTimeOutError) {
                            setIsTimeOutError(false);
                        } else {
                            setIsInvalidRequestError(false);
                        }
                        // This is done because it is very much possible that the page is stuck in a loading state while making the RPC call
                        // hence to break the loading state, the page is reloaded
                        window.location.reload();
                    }}
                />
            </Box>
        </Box>
    );
});
