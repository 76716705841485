import React from 'react';
import { CopyButton } from '../../../../assets/icons/CopyButton';
import { Button } from '../../../common/components/Button';

interface CopyFieldProps {
    text: string;
}

export const CopyField = ({ text }: CopyFieldProps): React.ReactElement => {
    return (
        <Button
            name="copy"
            size="small"
            icon={<CopyButton height="16px" width="16px" />}
            iconPosition="left"
            variant="outlined-color"
            onClick={(): void => {
                navigator.clipboard.writeText(text);
            }}
        />
    );
};
