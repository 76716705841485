import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { Instance } from 'mobx-state-tree';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { AmountModel } from '../../common/models/AmountModel';
import { UserAvatar } from '../../common/components/UserAvatar';
import { getAmountString } from '../../common/utils/UIUtils';
import { Yafika } from '../../../../assets/icons/Yafika';

interface LinkTransactionSummaryProps {
    profilePhotoUrl: string | null;
    title: string;
    subtitle: string | React.ReactElement;
    status?: StatusType;
    amount?: Instance<typeof AmountModel>;
}

export const LinkTransactionSummary = ({
    title,
    subtitle,
    profilePhotoUrl,
    status,
    amount
}: LinkTransactionSummaryProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {profilePhotoUrl ? (
                    <UserAvatar src={profilePhotoUrl} size="small" />
                ) : (
                    <Yafika width="48px" height="48px" />
                )}
                <Box sx={{ marginLeft: '16px' }}>
                    <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                        {title}
                    </Typography>
                    <Typography sx={{ ...typography.body2, color: palette.label[200] }}>
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
            {(status || amount) && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {status && <StatusComponent variant="status" status={status} />}
                    {amount && (
                        <Box
                            sx={{
                                ...typography.sh2,
                                marginLeft: '16px',
                                whiteSpace: 'nowrap',
                                color: palette.label[300]
                            }}>
                            {getAmountString(amount)}
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
