import {
    RequestStatus,
    Amount,
    BOName,
    Currency,
    GetGenericFundsTransferRequestListRPC,
    TransferRequest,
    TransferRequestAccount,
    UserName
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];

const TransferRequestlist: TransferRequest[] = [
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        null,
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID('4cee8447-4812-4165-baf5-038b7fd4d434')),
        RequestStatus.RequestStatus.PENDING
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID('4cee8447-4812-4165-baf5-038b7fd4d434')),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        null,
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.PENDING
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.DENIED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        null,
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('ffe7fc35-ffda-4ed6-be49-b6efb07e3c17'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.WITHDRAWN
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_DSTV_SUBSCRIPTION'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.DENIED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        null,
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_DSTV_SUBSCRIPTION'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.PENDING
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_DSTV_SUBSCRIPTION'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.DENIED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        null,
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.WITHDRAWN
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.APPROVED
    ),
    new TransferRequest(
        new LeoUUID(),
        new LeoTimestamp(new Date().toISOString()),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new Amount(50000000, new Currency('MWK')),
        new TransferRequestAccount.SystemAccount('BILLER_AIRTEL_AIRTIME'),
        new TransferRequestAccount.UserAccount(new LeoUUID()),
        RequestStatus.RequestStatus.DENIED
    )
];

export class MockGetGenericFundsTransferRequestListRPCImpl extends GetGenericFundsTransferRequestListRPC {
    execute(
        request: GetGenericFundsTransferRequestListRPC.Request
    ): Promise<
        LeoRPCResult<
            GetGenericFundsTransferRequestListRPC.Response,
            GetGenericFundsTransferRequestListRPC.Errors.Errors
        >
    > {
        let list: TransferRequest[] = [];
        if (request.accountId) {
            list = TransferRequestlist.filter((transferRequest) => {
                if (transferRequest.toAccountId instanceof TransferRequestAccount.UserAccount) {
                    if (request.status) {
                        return (
                            transferRequest.status === request.status &&
                            transferRequest.toAccountId.accountId.uuid.includes(request.accountId!)
                        );
                    } else {
                        return transferRequest.toAccountId.accountId.uuid.includes(
                            request.accountId!
                        );
                    }
                }
            });
        } else {
            list = TransferRequestlist.filter((transferRequest) => {
                if (request.status) {
                    return transferRequest.status === request.status;
                } else {
                    return transferRequest;
                }
            });
        }
        const response = new GetGenericFundsTransferRequestListRPC.Response(list.length, list);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetGenericFundsTransferRequestListRPC.Response,
                GetGenericFundsTransferRequestListRPC.Errors.Errors
            >
        >;
    }
}
