import { SignOutBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockSignOutBOUserRPCImpl extends SignOutBOUserRPC {
    execute(
        _request: SignOutBOUserRPC.Request
    ): Promise<LeoRPCResult<SignOutBOUserRPC.Response, SignOutBOUserRPC.Errors.Errors>> {
        const response = new SignOutBOUserRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<SignOutBOUserRPC.Response, SignOutBOUserRPC.Errors.Errors>
        >;
    }
}
