import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import nyTranslation from './ny/translation.json';

export const resources = {
    en: {
        translation: enTranslation
    },
    ny: {
        translation: nyTranslation
    }
};

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources,
    interpolation: { escapeValue: false }
});
