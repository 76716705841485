import { Stack, styled, Typography } from '@mui/material';
import { Button, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorComponentProps {
    title: string;
    description: string;
    errorImage: React.ReactElement;
    onGoToHomeClick: () => void;
}

const ErrorContainer = styled(Stack)({
    justifyContent: 'center',
    alignItems: 'center',
    height: '220px'
});

const ImageContainer = styled(Stack)({
    width: '120px',
    height: '120px',
    borderRadius: '60px',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
});

export const ErrorComponent = ({
    title,
    description,
    errorImage,
    onGoToHomeClick
}: ErrorComponentProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    return (
        <ErrorContainer>
            <ImageContainer>{errorImage}</ImageContainer>
            <Typography sx={{ ...typography.sh2, color: palette.label[200], mt: '8px' }}>
                {title}
            </Typography>
            <Typography sx={{ ...typography.body2, color: palette.label[200], mb: '8px' }}>
                {description}
            </Typography>
            <Button
                name="GoToHome"
                size="small"
                title={t('errors.goToHome')}
                variant="outlined-color"
                onClick={onGoToHomeClick}
            />
        </ErrorContainer>
    );
};
