import { IntermediateRequestStatus, NewBOUserRequestDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';
import {
    getWithdrawRequestModel,
    WithdrawRequestModel
} from '../../common/models/WithdrawRequestModel';

export const NewBOUserRequestDetailsModel = types.model({
    status: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    ),
    evaluatorComment: types.maybeNull(BOUserCommentModel),
    requesterComment: types.maybeNull(BOUserCommentModel),
    withdrawalComment: types.maybeNull(WithdrawRequestModel),
    requestId: types.string
});

export const getNewBOUserRequestDetails = (
    requestDetails: NewBOUserRequestDetails.NewBOUserRequestDetails
): Instance<typeof NewBOUserRequestDetailsModel> => {
    let evaluatorComment: Instance<typeof BOUserCommentModel> | null = null;
    let requesterComment: Instance<typeof BOUserCommentModel> | null = null;
    let withdrawalComment: Instance<typeof WithdrawRequestModel> | null = null;
    let status = IntermediateRequestStatus.IntermediateRequestStatus.PENDING;
    if (
        requestDetails instanceof NewBOUserRequestDetails.Approved ||
        requestDetails instanceof NewBOUserRequestDetails.Denied
    ) {
        status =
            requestDetails instanceof NewBOUserRequestDetails.Approved
                ? IntermediateRequestStatus.IntermediateRequestStatus.APPROVED
                : IntermediateRequestStatus.IntermediateRequestStatus.DENIED;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof NewBOUserRequestDetails.Pending) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.PENDING;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof NewBOUserRequestDetails.Denied) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.DENIED;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
    }
    if (requestDetails instanceof NewBOUserRequestDetails.Withdrawn) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN;
        withdrawalComment = getWithdrawRequestModel(
            requestDetails.withdrawalComment,
            requestDetails.makerComment.text,
            requestDetails.requestRaisedAt
        );
    }

    if (requestDetails instanceof NewBOUserRequestDetails.Processing) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.PROCESSING;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }

    return NewBOUserRequestDetailsModel.create({
        status,
        withdrawalComment,
        requesterComment,
        evaluatorComment,
        requestId: requestDetails.requestId.uuid
    });
};
