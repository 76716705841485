// This enum is kept in a different file since both edit and add BO user workflows will validate the user.
export enum ValidateBOUserErrors {
    InvalidBOUserId = 'INVALID_BO_USER_ID',
    UserAlreadyArchived = 'USER_ALREADY_ARCHIVED',
    UserAlreadyInactive = 'USER_ALREADY_INACTIVE',
    EmailIdAlreadyExists = 'EMAIL_ID_ALREADY_EXISTS',
    InvalidEmailId = 'INVALID_EMAIL_ID',
    InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
    PhoneNumberAlreadyExists = 'PHONE_NUMBER_ALREADY_EXISTS',
    InvalidRolesCombination = 'INVALID_ROLES_COMBINATION',
    InvalidCountryCode = 'INVALID_COUNTRY_CODE',
    InvalidCountryPhoneCode = 'INVALID_COUNTRY_PHONE_CODE',
    InvalidRoleId = 'INVALID_ROLE_ID',
    CannotUpdateSelfProfile = 'CANNOT_UPDATE_SELF_PROFILE',
    RequestToValidateBOUserFailed = 'REQUEST_TO_VALIDATE_BO_USER_FAILED',
    AgentCannotBeAnAgentManager = 'AGENT_CANNOT_BE_AN_AGENT_MANAGER',
    UserAppliedToBeAnAgent = 'USER_APPLIED_TO_BE_AN_AGENT',
    RequestAlreadyRaisedWithSamePhoneNumber = 'REQUEST_ALREADY_RAISED_WITH_SAME_PHONE_NUMBER',
    RequestAlreadyRaisedWithSameEmailId = 'REQUEST_ALREADY_RAISED_WITH_SAME_EMAIL_ID'
}
