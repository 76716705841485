import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';

export interface SubListItemProp {
    label: string;
    path?: string;
    icon?: React.ReactElement;
    isDestructive?: boolean;
}

export interface ListItemComponentProps {
    listItem: SubListItemProp;
    onSelect: (path?: string) => void;
    activeItem: string;
}

export const ListItem = ({
    listItem,
    onSelect,
    activeItem
}: ListItemComponentProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();

    const StyledListItemButton = styled(ListItemButton)(() => ({
        width: '100%',
        height: '40px',
        padding: '8px',
        borderRadius: '8px',
        flexGrow: 0,
        flex: 'none',
        '&.Mui-selected': {
            backgroundColor: listItem.isDestructive ? palette.error[100] : palette.primary[100],
            '&:hover': {
                backgroundColor: listItem.isDestructive ? palette.error[100] : palette.primary[100]
            }
        }
    }));

    const getColor = (isActive?: boolean, isDestructive?: boolean): string => {
        if (isDestructive) {
            return palette.error[300];
        } else if (isActive) {
            return palette.primary[300];
        }
        return palette.label[300];
    };

    const StyledTypography = styled(Typography)(() => ({
        ...(listItem.label === activeItem ? typography.small2 : typography.body2),
        color: getColor(listItem.label === activeItem, listItem.isDestructive),
        marginLeft: '8px'
    }));

    const getIcon = (
        icon: React.ReactElement,
        color: string
    ): React.ReactElement<React.SVGProps<SVGSVGElement>> | undefined => {
        if (icon) {
            const updatedImage = React.cloneElement(icon, {
                color,
                width: '20px',
                height: '20px'
            });
            return updatedImage;
        }
    };

    const handleClick = (): void => {
        onSelect(listItem.path);
    };

    return (
        <StyledListItemButton selected={listItem.label === activeItem} onClick={handleClick}>
            {listItem.icon &&
                getIcon(
                    listItem.icon,
                    getColor(listItem.label === activeItem, listItem.isDestructive)
                )}
            <StyledTypography>{listItem.label}</StyledTypography>
        </StyledListItemButton>
    );
};
