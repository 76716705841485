import { CheckToArchiveAgentUpdateRequestWithCommissionRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckToArchiveAgentUpdateRequestWithCommissionRPCImpl extends CheckToArchiveAgentUpdateRequestWithCommissionRPC {
    execute(
        _request: CheckToArchiveAgentUpdateRequestWithCommissionRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckToArchiveAgentUpdateRequestWithCommissionRPC.Response,
            CheckToArchiveAgentUpdateRequestWithCommissionRPC.Errors.Errors
        >
    > {
        const response = new CheckToArchiveAgentUpdateRequestWithCommissionRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckToArchiveAgentUpdateRequestWithCommissionRPC.Response,
                CheckToArchiveAgentUpdateRequestWithCommissionRPC.Errors.Errors
            >
        >;
    }
}
