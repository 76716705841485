import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import {
    GetTransactionValidationRuleRequestHistoryRPC,
    RequestStatus,
    ItemsPerPage,
    PageIndex,
    GetTransactionValidationRuleRequestHistorySortOrder,
    GetTransactionValidationRuleRequestHistoryPaginationResponse
} from '@resolut-tech/bcn-rpcs';
import { useGetTransactionValidationRuleRequestHistoryRPCClient } from '../rpcs/RPC';
import {
    createDownloadTransactionRulesStore,
    DownloadTransactionRulesStore
} from './DownloadTransactionRulesStore';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { GetTransactionValidationRuleRequestHistorySortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionValidationRuleRequestHistorySortOrder';
import { getPGFullName } from '../../../../utils/StringUtils';
import { RuleHistoryDetailsModel } from '../../common/models/RulesModel';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ViewTransactionRulesHistoryStoreErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const ViewTransactionRulesHistoryStore = types
    .model({
        requestStatus: types.string,
        totalItems: types.number,
        ruleRequestDetails: types.array(RuleHistoryDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<ViewTransactionRulesHistoryStoreErrors>(
                    'ViewTransactionRulesHistoryStoreErrors',
                    Object.values(ViewTransactionRulesHistoryStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        ),
        downloadFileStore: DownloadTransactionRulesStore
    })
    .views(() => ({
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        updateRequestStatus(requestStatus: string): void {
            store.requestStatus = requestStatus;
        },
        fetchTransactionRuleRequestsDetails: flow(function* (
            pageIndex: number,
            dropdownValue?: string,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            let requestStatus = null;
            if (RequestStatus.isInstanceOf(dropdownValue)) {
                requestStatus = RequestStatus.fromDTO({
                    case: dropdownValue ?? store.requestStatus
                });
            }
            const request = new GetTransactionValidationRuleRequestHistoryRPC.Request(
                [
                    new GetTransactionValidationRuleRequestHistorySortOrder(
                        0,
                        GetTransactionValidationRuleRequestHistorySortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                requestStatus,
                new ItemsPerPage(store.itemsPerPage()),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetTransactionValidationRuleRequestHistoryRPC.Response,
                GetTransactionValidationRuleRequestHistoryRPC.Errors.Errors
            > = yield useGetTransactionValidationRuleRequestHistoryRPCClient(apiClient).execute(
                request
            );
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const ruleRequestDetails =
                    response.getTransactionValidationRuleRequestHistoryPaginationResponse.map(
                        (
                            paginationResponse: GetTransactionValidationRuleRequestHistoryPaginationResponse
                        ) => {
                            return {
                                requestId: paginationResponse.requestId.uuid,
                                requestedAt: getFormattedTimeDateWithComma(
                                    new Date(paginationResponse.requestedAt.timestamp)
                                ),
                                requestStatus: paginationResponse.requestStatus,
                                checkedBy: paginationResponse.checkedBy
                                    ? getPGFullName(paginationResponse.checkedBy)
                                    : null,
                                requestedBy: getPGFullName(paginationResponse.requestedBy)
                            };
                        }
                    );
                store.totalItems = response.totalItems;
                store.ruleRequestDetails = cast(ruleRequestDetails);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case ViewTransactionRulesHistoryStoreErrors.InvalidPageIndex:
                        store.error = ViewTransactionRulesHistoryStoreErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetTransactionValidationRuleRequestHistoryRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetTransactionValidationRuleRequestHistoryRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createViewTransactionRulesHistoryStore = (): Instance<
    typeof ViewTransactionRulesHistoryStore
> => {
    return ViewTransactionRulesHistoryStore.create({
        requestStatus: RequestStatus.RequestStatus.PENDING,
        ruleRequestDetails: [],
        totalItems: 0,
        downloadFileStore: createDownloadTransactionRulesStore()
    });
};
