import { Stack } from '@mui/material';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { PageHeader } from '../../common/components/PageHeader';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getStatusTypeFromIntermediateRequestStatus } from '../../common/utils/StatusUIUtils';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { AgentTransactionRequestsHistoryFilter } from '../components/AgentTransactionRequestHistoryFilter';
import {
    AgentTransactionRequestHistoryErrors,
    AgentTransactionRequestHistoryFilterOptions
} from '../store/AgentTransactionRequestHistoryStore';
import { useAgentTransactionRequestHistoryStore } from '../store/hooks';
import { getAgentTransactionRequestsHistoryInitialFilter } from '../utils/UIUtils';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const AgentTransactionRequestHistory = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const navigate = useNavigate();
    const store = useAgentTransactionRequestHistoryStore();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(
            t('agencyBanking.transactionRequestHistoryTitle'),
            window.location.pathname
        );
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '160px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '160px',
                align: 'right'
            },
            {
                id: 'requestedBy',
                name: t('common.requestedBy'),
                width: '160px'
            },
            {
                id: 'evaluatedBy',
                name: t('common.evaluatedBy'),
                width: '160px'
            },
            {
                id: 'requestId',
                name: t('agencyBanking.requestId'),
                width: '160px'
            },
            {
                id: 'transactionId',
                name: t('common.transactionId'),
                width: '160px',
                ellipsisContent: true
            },
            {
                id: 'requestStatus',
                name: t('common.requestStatus'),
                width: '160px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<typeof AgentTransactionRequestHistoryFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.getAgentTransactionRequestHistory(
            option.page ? option.page - 1 : 0,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case AgentTransactionRequestHistoryErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.agentTransactionRequests.map((agentTransactionRequest) => {
            return [
                { data: agentTransactionRequest.createdAt },
                { data: agentTransactionRequest.amount, align: 'right' },
                { data: agentTransactionRequest.requestedBy },
                { data: agentTransactionRequest.evaluatedBy ?? '-' },
                { data: agentTransactionRequest.requestId ?? '-' },
                { data: agentTransactionRequest.transactionId },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getStatusTypeFromIntermediateRequestStatus(
                                agentTransactionRequest.requestStatus
                            )}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack>
            <PageHeader
                title={t('agencyBanking.transactionRequestHistoryTitle')}
                subtitle={t('agencyBanking.transactionRequestHistorySubtitle')}
            />
            <Table
                name="AgentTransactionRequestHistoryTable"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('agencyBanking.transactionsSearchTableLoadingState') }
                }}
                filterOption={{
                    initialFilterValue:
                        (store.filterOptions as Instance<
                            typeof AgentTransactionRequestHistoryFilterOptions
                        >) ?? getAgentTransactionRequestsHistoryInitialFilter(t),
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <AgentTransactionRequestsHistoryFilter
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={store.minimumSearchTextLength()}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    const agentTransactionRequest = store.agentTransactionRequests[index];
                    if (agentTransactionRequest) {
                        navigate(
                            `/agency-banking/transactions/details?transactionId=${agentTransactionRequest.transactionId}`
                        );
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected Agent Transaction Request in the list with length ${store.agentTransactionRequests.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </Stack>
    );
});
