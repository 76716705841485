import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { useGetTransactionSearchResultsClient } from '../rpcs/RPC';
import {
    BackOfficeTransaction,
    GetTransactionSearchResultsRPC,
    PhoneCode,
    SearchText,
    TransactionType
} from '@resolut-tech/bcn-rpcs';
import { TransactionSearchBy } from '@resolut-tech/bcn-rpcs/build/back-office/transactionSearchBy';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { TransactionSearchByDropdown } from '../utils/UIUtils';

export enum TransactionSearchErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX',
    InvalidSearchTextError = 'INVALID_SEARCH_TEXT_ERROR'
}

export const Transaction = types.model({
    transactionId: types.string,
    createdAt: types.string,
    transactionType: typeof TransactionType.TransactionType,
    senderName: types.maybeNull(types.string),
    senderMobileNumber: types.maybeNull(types.string),
    debitAccountId: types.maybeNull(types.string),
    receiverName: types.maybeNull(types.string),
    receiverMobileNumber: types.maybeNull(types.string),
    creditAccountId: types.maybeNull(types.string)
});

export const TransactionFilterOptions = types.model({
    searchBy: types.string,
    searchText: types.maybeNull(types.string),
    searchByFirstName: types.maybeNull(types.string),
    searchByLastName: types.maybeNull(types.string),
    searchByPhoneCode: types.maybeNull(types.string)
});

export const TransactionSearchStore = types
    .model({
        countryListStore: CountryListStore,
        isTableIdle: types.boolean,
        filterOptions: TransactionFilterOptions,
        totalItems: types.number,
        transactions: types.array(Transaction),
        error: types.maybeNull(
            types.union(
                types.enumeration<TransactionSearchErrors>(
                    'TransactionSearchErrors',
                    Object.values(TransactionSearchErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .views((store) => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10,
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        }
    }))
    .actions((store) => ({
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        removeError(): void {
            store.error = null;
        },
        updateFilterOptions(filterOptions: Instance<typeof TransactionFilterOptions>): void {
            store.isTableIdle = false;
            store.filterOptions = filterOptions;
        },
        fetchTransactionDetails: flow(function* (
            searchBy: string,
            searchText: string,
            pageIndex: number,
            phoneCode: string,
            firstName?: string,
            lastName?: string,
            sortOrder?: 'asc' | 'desc'
        ) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                let transactionSearchBy: TransactionSearchBy.TransactionSearchBy;
                switch (searchBy) {
                    case TransactionSearchByDropdown.AccountId:
                        transactionSearchBy = new TransactionSearchBy.AccountId(
                            new SearchText(searchText)
                        );
                        break;
                    case TransactionSearchByDropdown.Name:
                        transactionSearchBy = new TransactionSearchBy.Name(
                            firstName ? new SearchText(firstName) : null,
                            lastName ? new SearchText(lastName) : null
                        );
                        break;
                    case TransactionSearchByDropdown.MobileNumber:
                        transactionSearchBy = new TransactionSearchBy.MobileNumber(
                            new PhoneCode(phoneCode),
                            new SearchText(searchText)
                        );
                        break;
                    case TransactionSearchByDropdown.TransactionId:
                        transactionSearchBy = new TransactionSearchBy.TransactionId(
                            new SearchText(searchText)
                        );
                        break;
                    default:
                        loggerStore.error(
                            `Unknown searchBy: ${searchBy} value found in TransactionSearchStore`
                        );
                        const error = new Error();
                        error.name = NetworkingError.InternalError;
                        throw error;
                }
                const request = new GetTransactionSearchResultsRPC.Request(
                    transactionSearchBy,
                    pageIndex,
                    store.itemsPerPage(),
                    getSortOrder(sortOrder)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetTransactionSearchResultsRPC.Response,
                    GetTransactionSearchResultsRPC.Errors.InvalidPageIndex
                > = yield useGetTransactionSearchResultsClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const transactions = response.transactions.map(
                        (transaction: BackOfficeTransaction) => {
                            return {
                                transactionId: transaction.transactionId.uuid,
                                createdAt: getFormattedTimeDateWithComma(
                                    new Date(transaction.createdAt.timestamp)
                                ),
                                transactionType: transaction.transactionType,
                                senderName: transaction.senderName ? transaction.senderName : null,
                                senderMobileNumber: transaction.senderMobileNumber?.phoneNumber,
                                debitAccountId: transaction.debitAccountId?.uuid,
                                receiverName: transaction.recipientName
                                    ? transaction.recipientName
                                    : null,
                                receiverMobileNumber:
                                    transaction.recipientMobileNumber?.phoneNumber,
                                creditAccountId: transaction.creditAccountId?.uuid
                            };
                        }
                    );
                    store.totalItems = response.totalItems;
                    store.transactions = cast(transactions);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case TransactionSearchErrors.InvalidPageIndex:
                            store.error = TransactionSearchErrors.InvalidPageIndex;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionSearchResultsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetTransactionSearchResultsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidSearchTextError:
                            store.error = TransactionSearchErrors.InvalidSearchTextError;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionSearchResultsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetTransactionSearchResultsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createTransactionSearchStore = (): Instance<typeof TransactionSearchStore> => {
    return TransactionSearchStore.create({
        isTableIdle: true,
        filterOptions: TransactionFilterOptions.create({
            searchBy: '',
            searchText: '',
            searchByFirstName: '',
            searchByLastName: '',
            searchByPhoneCode: ''
        }),
        totalItems: 0,
        transactions: [],
        countryListStore: createCountryListStore()
    });
};
