import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BitmapImageType,
    BOUserComment,
    GetRefundHistoryRPC,
    MultiResolutionBitmapImage,
    RefundHistory,
    RefundSummary,
    RemoteBitmapImage,
    RequestDetails,
    UserName,
    Comment,
    Amount,
    Currency,
    BOName
} from '@resolut-tech/bcn-rpcs';

export class MockGetRefundHistoryRPCImpl extends GetRefundHistoryRPC {
    execute(
        _request: GetRefundHistoryRPC.Request
    ): Promise<LeoRPCResult<GetRefundHistoryRPC.Response, GetRefundHistoryRPC.Errors.Errors>> {
        const response = new GetRefundHistoryRPC.Response([
            new RefundHistory(
                new RequestDetails.Pending(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Wade'), new BOName('Warren')),
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        ),
                        new LeoTimestamp('2022-09-30T02:03:59Z'),
                        new Comment('The transaction was done by mistake by the user.')
                    )
                ),
                new RefundSummary(
                    new Amount(50000000, new Currency('MWK')),
                    new Amount(50000, new Currency('MWK')),
                    new Amount(100000, new Currency('MWK'))
                )
            ),
            new RefundHistory(
                new RequestDetails.Withdrawn(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Wade'), new BOName('Warren')),
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        ),
                        new LeoTimestamp('2022-09-30T02:03:59Z'),
                        new Comment('The transaction was done by mistake by the user.')
                    ),
                    new LeoTimestamp(),
                    new Comment('This request was raised by mistake.')
                ),
                new RefundSummary(
                    new Amount(50000000, new Currency('MWK')),
                    new Amount(50000, new Currency('MWK')),
                    new Amount(100000, new Currency('MWK'))
                )
            ),
            new RefundHistory(
                new RequestDetails.Approved(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Rosie'), new BOName('Downey')),
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        ),
                        new LeoTimestamp('2022-09-30T02:03:59Z'),
                        new Comment('The refund request was approved.')
                    ),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Wade'), new BOName('Warren')),
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        ),
                        new LeoTimestamp('2022-09-30T02:03:59Z'),
                        new Comment('The transaction was done by mistake by the user.')
                    )
                ),
                new RefundSummary(
                    new Amount(50000000, new Currency('MWK')),
                    new Amount(50000, new Currency('MWK')),
                    new Amount(100000, new Currency('MWK'))
                )
            )
        ]);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetRefundHistoryRPC.Response, GetRefundHistoryRPC.Errors.Errors>
        >;
    }
}
