import { AuditLog, DateRange, GetAuditLogsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getFormattedTimeDateWithComma, getLeoDate } from '../../common/utils/DateUtils';
import {
    DateRangeFilterOptions,
    getDateRangeInitialFilter
} from '../../common/utils/TableFilterUtils';
import { useGetAuditLogsClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AuditLogsErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const AuditLogModel = types.model({
    name: types.maybeNull(types.string),
    profilePhoto: types.maybeNull(types.string),
    phoneNumber: types.string,
    eventInfo: types.string,
    additionalData: types.string,
    timestamp: types.string
});

export const AuditLogsStore = types
    .model({
        filterOptions: DateRangeFilterOptions,
        totalItems: types.number,
        auditLogs: types.array(AuditLogModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AuditLogsErrors>(
                    'AuditLogsErrors',
                    Object.values(AuditLogsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        updateFilterOptions(filterOptions: Instance<typeof DateRangeFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        getAuditLogs: flow(function* (
            dateRange: Instance<typeof DateRangeFilterOptions> | null,
            pageIndex: number,
            itemsPerPage: number
        ) {
            const loggerStore = getLoggerStore(store);
            const request = new GetAuditLogsRPC.Request(
                dateRange &&
                    dateRange.startDate &&
                    dateRange.endDate &&
                    new DateRange(getLeoDate(dateRange.startDate), getLeoDate(dateRange.endDate)),
                pageIndex,
                itemsPerPage
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<GetAuditLogsRPC.Response, GetAuditLogsRPC.Errors.Errors> =
                yield useGetAuditLogsClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.totalItems = response.totalItems;
                const auditLogs = response.logs.map((logs: AuditLog) => {
                    return {
                        name: logs.name && getFullName(logs.name),
                        profilePhoto: logs.profilePhoto && getImageUrl(logs.profilePhoto),
                        additionalData: logs.additionalData,
                        eventInfo: logs.eventInfo,
                        phoneNumber: logs.phoneNumber.phoneNumber,
                        timestamp: getFormattedTimeDateWithComma(new Date(logs.timestamp.timestamp))
                    };
                });
                store.auditLogs = cast(auditLogs);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case AuditLogsErrors.InvalidPageIndex:
                        store.error = AuditLogsErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(`Unhandled error: ${error} in GetAuditLogsRPC`);
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetAuditLogsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 100
    }));

export const createAuditLogsStore = (): Instance<typeof AuditLogsStore> => {
    return AuditLogsStore.create({
        filterOptions: getDateRangeInitialFilter(),
        totalItems: 0,
        auditLogs: []
    });
};
