import { RequestStatus } from '@resolut-tech/bcn-rpcs';
import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { TFunction } from 'react-i18next';
import { AnyEnum } from '../../common/enums/AnyEnum';

const getSearchByValue = (value: RequestStatus.RequestStatus, t: TFunction): string => {
    const values = RequestStatus.RequestStatus;
    switch (value) {
        case values.PENDING:
            return t('common.pending');
        case values.WITHDRAWN:
            return t('common.withdrawn');
        case values.APPROVED:
            return t('common.approved');
        case values.DENIED:
            return t('common.denied');
        case values.DISCARDED:
            return t('common.discarded');
    }
};

export const getRequestTransferSearchByOptions = (t: TFunction): DropdownItem[] => {
    let values = Object.keys(RequestStatus.RequestStatus);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value as RequestStatus.RequestStatus, t);
        return { name: dropdownValue ?? t('common.any'), value };
    });
};
