import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    GetUserRolesRPC,
    LocalizedText,
    WorkflowDependentRoles,
    BORole
} from '@resolut-tech/bcn-rpcs';

const boRoles = [
    new WorkflowDependentRoles(new LocalizedText('Reports'), [
        new BORole(
            'Reports Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Reports'),
            new LocalizedText('View Reports, Download Reports')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('Generic Funds Transfer'), [
        new BORole(
            'Generic Funds Transfer Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Generic Funds Transfer'),
            new LocalizedText('View Requests, View Details')
        ),
        new BORole(
            'Generic Funds Transfer Maker',
            new LocalizedText('Maker'),
            new LocalizedText('Generic Funds Transfer'),
            new LocalizedText('View Requests, View Details, Raise Request')
        ),
        new BORole(
            'Generic Funds Transfer Checker',
            new LocalizedText('Checker'),
            new LocalizedText('Generic Funds Transfer'),
            new LocalizedText('View Requests, View Details, Evaluate Request')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('Fee Determination Rules'), [
        new BORole(
            'Fee Determination Rules Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Fee Determination Rules'),
            new LocalizedText('View Rules, View History, Download Rules')
        ),
        new BORole(
            'Fee Determination Rules Maker',
            new LocalizedText('Maker'),
            new LocalizedText('Fee Determination Rules'),
            new LocalizedText('View Rules, View History, Download Rules, Raise Request')
        ),
        new BORole(
            'Fee Determination Rules Checker',
            new LocalizedText('Checker'),
            new LocalizedText('Fee Determination Rules'),
            new LocalizedText('View Rules, View History, Download Rules, Evaluate Request')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('Transaction Validation Rules'), [
        new BORole(
            'Transaction Validation Rules Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Transaction Validation Rules'),
            new LocalizedText('View Rules, View History, Download Rules')
        ),
        new BORole(
            'Transaction Validation Rules Maker',
            new LocalizedText('Maker'),
            new LocalizedText('Transaction Validation Rules'),
            new LocalizedText('View Rules, View History, Download Rules, Raise Request')
        ),
        new BORole(
            'Transaction Validation Rules Checker',
            new LocalizedText('Checker'),
            new LocalizedText('Transaction Validation Rules'),
            new LocalizedText('View Rules, View History, Download Rules, Evaluate Request')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('Transactions'), [
        new BORole(
            'Transactions Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Transactions'),
            new LocalizedText('View List, View Details, View History')
        ),
        new BORole(
            'Transactions Refund Maker',
            new LocalizedText('Refund Maker'),
            new LocalizedText('Transactions'),
            new LocalizedText('View List, View Details, View History, Raise Request')
        ),
        new BORole(
            'Transactions Refund Checker',
            new LocalizedText('Refund Checker'),
            new LocalizedText('Transactions'),
            new LocalizedText('View List, View Details, View History, Evaluate Request')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('Audit Logs'), [
        new BORole(
            'Audit Logs Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('Audit Logs'),
            new LocalizedText('View Logs')
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('BCN User Management'), [
        new BORole(
            'BCN User Management Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('BCN User Management'),
            new LocalizedText(
                'View BCN Users List, View Requests, View Details, Download Documents'
            )
        ),
        new BORole(
            'BCN User Management Maker',
            new LocalizedText('Maker'),
            new LocalizedText('BCN User Management'),
            new LocalizedText(
                'View BCN Users List, View Requests, View Details, Download Documents, Raise Request'
            )
        ),
        new BORole(
            'BCN User Management Checker',
            new LocalizedText('Checker'),
            new LocalizedText('BCN User Management'),
            new LocalizedText(
                'View BCN Users List, View Requests, View Details, Download Documents, Evaluate Request'
            )
        )
    ]),
    new WorkflowDependentRoles(new LocalizedText('BO User Management'), [
        new BORole(
            'BO User Management Viewer',
            new LocalizedText('Viewer'),
            new LocalizedText('BO User Management'),
            new LocalizedText(
                'View BO Users List, View BO Users Requests List, View BO User Details'
            )
        ),
        new BORole(
            'BO User Management Maker',
            new LocalizedText('Maker'),
            new LocalizedText('BO User Management'),
            new LocalizedText(
                'View BO Users List, View BO Users Requests List, View BO User Details, Raise Request, Force Reset Password, Regenerate Auth Codes'
            )
        ),
        new BORole(
            'BO User Management Checker',
            new LocalizedText('Checker'),
            new LocalizedText('BO User Management'),
            new LocalizedText(
                'View BO Users List, View BO Users Requests List, View BO User Details, Evaluate Requests, Force Reset Password, Regenerate Auth Codes'
            )
        )
    ])
];

export class MockGetUserRolesRPCImpl extends GetUserRolesRPC {
    execute(
        _request: GetUserRolesRPC.Request
    ): Promise<LeoRPCResult<GetUserRolesRPC.Response, GetUserRolesRPC.Errors.Errors>> {
        const response = new GetUserRolesRPC.Response(boRoles);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetUserRolesRPC.Response, GetUserRolesRPC.Errors.Errors>
        >;
    }
}
