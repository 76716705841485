import { Grid } from '@mui/material';
import { Button, DropdownInputField, DropdownItem } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequestStatusOptionsWithAny } from '../../common/utils/StatusUIUtils';
import { getRequestTypeOptionsWithAny } from '../utils/RequestTypeUtils';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { Instance } from 'mobx-state-tree';
import { BOUserRequestsFilterOptions } from '../store/BOUserRequestsStore';

interface BORequestsFilterProps {
    initialFilter: Instance<typeof BOUserRequestsFilterOptions>;
    filter: Instance<typeof BOUserRequestsFilterOptions>;
    setFilter: (filter: Instance<typeof BOUserRequestsFilterOptions>) => void;
}

export const BORequestsFilter = ({
    initialFilter,
    filter,
    setFilter
}: BORequestsFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const statusOptions = getRequestStatusOptionsWithAny();
    const requestTypeOptions = getRequestTypeOptionsWithAny();
    const [statusDropdownData, setStatusDropdownData] = useState(filter.status);
    const [typeDropdownData, setTypeDropdownData] = useState(filter.type);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        setStatusDropdownData(filter.status);
        setTypeDropdownData(filter.type);
    }, [filter]);

    useEffect(() => {
        if (statusDropdownData !== filter.status || typeDropdownData !== filter.type) {
            setIsSearchDisabled(false);
        } else {
            setIsSearchDisabled(true);
        }
    }, [statusDropdownData, typeDropdownData]);

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="statusDropdown"
                        value={statusDropdownData}
                        onSelect={(inputValue: DropdownItem): void =>
                            setStatusDropdownData(inputValue.value)
                        }
                        label={t('common.requestStatus')}
                        options={statusOptions}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="typeDropdown"
                        value={typeDropdownData}
                        onSelect={(inputValue: DropdownItem): void =>
                            setTypeDropdownData(inputValue.value)
                        }
                        label={t('boUser.requestType')}
                        options={requestTypeOptions}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="8px">
                    <Button
                        name="plain-color"
                        variant="plain-color"
                        color="error"
                        size="medium"
                        title={t('common.reset')}
                        isDisabled={isInitialFilter}
                        onClick={(): void => {
                            setFilter(initialFilter);
                        }}
                    />
                    <Button
                        name="filled"
                        variant="filled"
                        size="medium"
                        isDisabled={isSearchDisabled}
                        title={t('common.search')}
                        onClick={(): void => {
                            setFilter({ status: statusDropdownData, type: typeDropdownData });
                        }}
                    />
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
