import * as React from 'react';
import { styled } from '@mui/material/styles';
import MUIStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Box, Typography } from '@mui/material';
import checkIcon from '../../../../assets/check.svg';

interface StepperInterface {
    steps: string[];
    activeStep: number;
}

const StepIcon = ({ active, completed, icon }: StepIconProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const iconColor = active || completed ? palette.background[400] : palette.label[200];
    const StyledIcon = styled(Box)(() => ({
        ...typography.small2,
        color: palette.background[400],
        width: '32px',
        height: '32px',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    }));

    return (
        <StyledIcon
            sx={{
                backgroundColor:
                    active || completed ? palette.primary[300] : palette.background[100]
            }}>
            {completed ? (
                <img src={checkIcon} />
            ) : (
                <Typography color={iconColor}>{icon}</Typography>
            )}
        </StyledIcon>
    );
};

export const Stepper = ({ steps, activeStep }: StepperInterface): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const StyledConnector = styled(StepConnector)(() => ({
        [`& .${stepConnectorClasses.line}`]: {
            height: '2px',
            width: '40px',
            border: '0px',
            backgroundColor: palette.outline[300],
            borderRadius: '250px'
        }
    }));

    return (
        <MUIStepper
            sx={{ width: 'max-content' }}
            activeStep={activeStep}
            connector={<StyledConnector sx={{ px: '8px' }} />}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel
                        sx={{ ...typography.body2, color: palette.label[300] }}
                        StepIconComponent={StepIcon}>
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </MUIStepper>
    );
};
