import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    CheckerResponse,
    CheckRefundRequestRPC,
    Comment,
    GetCheckerRefundSummaryRPC
} from '@resolut-tech/bcn-rpcs';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { Status } from '../../common/enums/StatusEnum';
import { useCheckRefundRequestRPCClient, useGetCheckerRefundSummaryRPCClient } from '../rpcs/RPC';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum CheckRequestDetailsErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    UnableToPerformExchange = 'UNABLE_TO_PERFORM_EXCHANGE',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    SenderAccountInactive = 'SENDER_ACCOUNT_INACTIVE',
    RecipientAccountInactive = 'RECIPIENT_ACCOUNT_INACTIVE',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    ReceivingAccountWouldCrossLimit = 'RECEIVING_ACCOUNT_WOULD_CROSS_LIMIT',
    RefundPeriodExpired = 'REFUND_PERIOD_EXPIRED',
    UnableToRefund = 'UNABLE_TO_REFUND',
    SenderProfileDisabled = 'SENDER_PROFILE_DISABLED',
    RecipientProfileDisabled = 'RECIPIENT_PROFILE_DISABLED',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    RequestUnderProcess = 'REQUEST_UNDER_PROCESS',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    InvalidExchangeRateId = 'INVALID_EXCHANGE_RATE_ID',
    SenderProfileArchived = 'SENDER_PROFILE_ARCHIVED',
    RecipientProfileArchived = 'RECIPIENT_PROFILE_ARCHIVED'
}

const getStoreError = (
    error: GetCheckerRefundSummaryRPC.Errors.Errors | CheckRefundRequestRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): CheckRequestDetailsErrors | NetworkingError => {
    switch (error.code) {
        case CheckRequestDetailsErrors.InvalidRequestId:
            return CheckRequestDetailsErrors.InvalidRequestId;
        case CheckRequestDetailsErrors.UnableToPerformExchange:
            return CheckRequestDetailsErrors.UnableToPerformExchange;
        case CheckRequestDetailsErrors.InsufficientBalance:
            return CheckRequestDetailsErrors.InsufficientBalance;
        case CheckRequestDetailsErrors.SenderAccountInactive:
            return CheckRequestDetailsErrors.SenderAccountInactive;
        case CheckRequestDetailsErrors.RecipientAccountInactive:
            return CheckRequestDetailsErrors.RecipientAccountInactive;
        case CheckRequestDetailsErrors.RequestAlreadyChecked:
            return CheckRequestDetailsErrors.RequestAlreadyChecked;
        case CheckRequestDetailsErrors.ReceivingAccountWouldCrossLimit:
            return CheckRequestDetailsErrors.ReceivingAccountWouldCrossLimit;
        case CheckRequestDetailsErrors.RefundPeriodExpired:
            return CheckRequestDetailsErrors.RefundPeriodExpired;
        case CheckRequestDetailsErrors.UnableToRefund:
            return CheckRequestDetailsErrors.UnableToRefund;
        case CheckRequestDetailsErrors.SenderProfileDisabled:
            return CheckRequestDetailsErrors.SenderProfileDisabled;
        case CheckRequestDetailsErrors.RecipientProfileDisabled:
            return CheckRequestDetailsErrors.RecipientProfileDisabled;
        case CheckRequestDetailsErrors.RequestAlreadyWithdrawn:
            return CheckRequestDetailsErrors.RequestAlreadyWithdrawn;
        case CheckRequestDetailsErrors.RequestAlreadyDiscarded:
            return CheckRequestDetailsErrors.RequestAlreadyDiscarded;
        case CheckRequestDetailsErrors.RequestUnderProcess:
            return CheckRequestDetailsErrors.RequestUnderProcess;
        case CheckRequestDetailsErrors.CannotCheckSelfRequest:
            return CheckRequestDetailsErrors.CannotCheckSelfRequest;
        case CheckRequestDetailsErrors.InvalidExchangeRateId:
            return CheckRequestDetailsErrors.InvalidExchangeRateId;
        case CheckRequestDetailsErrors.SenderProfileArchived:
            return CheckRequestDetailsErrors.SenderProfileArchived;
        case CheckRequestDetailsErrors.RecipientProfileArchived:
            return CheckRequestDetailsErrors.RecipientProfileArchived;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in CheckRequestDetailsStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): CheckRequestDetailsErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidLeoUUIDError:
            return CheckRequestDetailsErrors.InvalidRequestId;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in CheckRequestDetailsStore`);
            return NetworkingError.InternalError;
    }
};

export const CheckRequestDetailsStore = types
    .model('RefundTransactionDetails', {
        baseAmount: types.maybeNull(AmountModel),
        bankFee: types.maybeNull(AmountModel),
        additionalFee: types.maybeNull(AmountModel),
        totalAmount: types.maybeNull(AmountModel),
        errorLimitAmount: types.maybeNull(types.string),
        exchangeRateId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckRequestDetailsErrors>(
                    'RequestTransactionRefundErrors',
                    Object.values(CheckRequestDetailsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        removeError: (): void => {
            store.error = null;
            store.errorLimitAmount = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        getRefundRequestDetails: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetCheckerRefundSummaryRPC.Request(new LeoUUID(requestId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetCheckerRefundSummaryRPC.Response,
                    GetCheckerRefundSummaryRPC.Errors.Errors
                > = yield useGetCheckerRefundSummaryRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.baseAmount = getAmountModel(response.baseAmount);
                    store.bankFee = response.bankFee && getAmountModel(response.bankFee);
                    store.additionalFee =
                        response.additionalFee && getAmountModel(response.additionalFee);
                    store.totalAmount = getAmountModel(response.totalAmount);
                    store.exchangeRateId = response.exchangeRateId?.uuid ?? null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetCheckRefundSummaryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetCheckRefundSummaryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        checkRefundRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.comment && store.status) {
                    const request = new CheckRefundRequestRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment)),
                        store.exchangeRateId ? new LeoUUID(store.exchangeRateId) : null
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckRefundRequestRPC.Response,
                        CheckRefundRequestRPC.Errors.Errors
                    > = yield useCheckRefundRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckRefundRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in CheckRefundRequestRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createCheckRequestDetailsStore = (): Instance<typeof CheckRequestDetailsStore> => {
    return CheckRequestDetailsStore.create();
};
