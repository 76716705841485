import { Box, Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import {
    ColDef,
    GridOptions,
    ICellRendererParams,
    RowDragEvent,
    CellEditingStoppedEvent
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Card, Icon, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Trash } from '../../../../../assets/icons/Trash';
import { Button } from '../../../../common/components/Button';

interface EditRulesProps<RowData> {
    rows: RowData[];
    // There is no need to send the ColDef for index column and action column. Since it will be common for both fee rules and transaction rules.
    columns: ColDef[];
    setRows: (rows: RowData[]) => void;
    onInsertButtonClick: () => void;
}

export const EditRules = <RowData,>({
    rows,
    columns,
    setRows,
    onInsertButtonClick
}: EditRulesProps<RowData>): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    const DataGridContainer = styled(Box)({
        height: 'calc(100vh - 226px)',
        // Remove the border on the grid wrapper and header row.
        '.ag-root-wrapper, .ag-header': { border: 'none' },
        // By default the height is set as 48px. Since Leo-Table uses 56px as height, update the header row height and content row height.
        '.ag-header-row, .ag-row': { height: '56px' },
        '.ag-header-cell': {
            boxSizing: 'border-box',
            padding: '16px',
            borderTop: `1px solid ${palette.outline[200]}`,
            borderBottom: `1px solid ${palette.outline[200]}`,
            height: '56px',
            background: palette.background[400]
        },
        '.ag-header-cell-text': { ...typography.small3, color: palette.label[200] },
        '.ag-cell': {
            verticalAlign: 'top',
            height: '56px',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: '16px',
            borderBottom: 'none'
        },
        '.ag-cell-value': { ...typography.body2, color: palette.label[300] },
        // In the index column the row drag is present and content is not aligned center due to change in style of element with class "ag-cell", hence set the height as auto to dynamically align the contents to center.
        '.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value)': { height: 'auto' }
    });

    const ActionButtonCellRenderer = (params: ICellRendererParams<RowData>): React.ReactElement => {
        return (
            <Button
                variant="plain-color"
                color="error"
                size="small"
                name="Delete"
                icon={<Trash />}
                iconPosition="left"
                style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                onClick={(): void => {
                    rows.splice(params.rowIndex, 1);
                    setRows([...rows]);
                }}
            />
        );
    };

    const getColumnDefs = (): ColDef[] => {
        return [
            {
                field: 'slNo',
                width: 100,
                headerName: t('rulesCSVHeaders.slNo'),
                editable: false,
                valueGetter: 'node.rowIndex + 1',
                rowDrag: true
            },
            ...columns,
            {
                field: 'action',
                width: 56,
                headerName: '',
                cellRenderer: ActionButtonCellRenderer,
                editable: false
            }
        ];
    };

    const gridOptions: GridOptions = {
        rowData: rows,
        columnDefs: getColumnDefs(),
        animateRows: true,
        defaultColDef: {
            suppressMovable: true,
            editable: true
        },
        tooltipShowDelay: 0,
        overlayNoRowsTemplate: t('common.noCurrentRulesFound'),
        onCellEditingStopped: (event: CellEditingStoppedEvent<RowData>): void => {
            if (event.valueChanged && event.rowIndex !== null) {
                rows[event.rowIndex] = event.data;
                setRows([...rows]);
            }
        },
        onRowDragEnd: (event: RowDragEvent<RowData>): void => {
            const moveInArray = (arr: RowData[], fromIndex: number, toIndex: number): void => {
                const element = arr[fromIndex];
                arr.splice(fromIndex, 1);
                arr.splice(toIndex, 0, element);
            };

            const movingNode = event.node;
            const overNode = event.overNode;
            const rowNeedsToMove = movingNode !== overNode;
            if (rowNeedsToMove) {
                const movingData = movingNode.data;
                const overData = overNode?.data;
                if (movingData && overData) {
                    const fromIndex = rows.indexOf(movingData);
                    const toIndex = rows.indexOf(overData);
                    moveInArray(rows, fromIndex, toIndex);
                    setRows([...rows]);
                }
            }
            event.api.refreshCells();
        }
    };

    return (
        <Card style={{ width: '100%' }}>
            <Stack padding={2} alignItems="flex-end">
                <Button
                    variant="plain-neutral"
                    size="small"
                    name="Insert"
                    title={t('common.insert')}
                    icon={<Icon type="plus" color="inherit" />}
                    iconPosition="left"
                    onClick={onInsertButtonClick}
                />
            </Stack>
            <DataGridContainer className="ag-theme-alpine">
                <AgGridReact gridOptions={gridOptions} singleClickEdit={true} />
            </DataGridContainer>
        </Card>
    );
};
