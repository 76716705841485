import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestDialog } from './RequestDialog';

interface WithdrawDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmit: (comment: string) => void;
}

export function WithdrawDialog({
    isDialogOpen,
    onClose,
    onSubmit
}: WithdrawDialogProps): React.ReactElement {
    const { t } = useTranslation();

    return (
        <RequestDialog
            isDialogOpen={isDialogOpen}
            title={t('common.withdrawRequest')}
            buttonTitle={t('common.withdrawRequest')}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
}
