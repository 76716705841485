import { Box, Typography, styled } from '@mui/material';
import {
    LoadingIndicator,
    TextInputField,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthCodeEntryStore } from '../stores/hooks';
import { AuthErrorDialog } from '../components/AuthErrorDialog';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { AuthCodeEntryErrors } from '../stores/AuthCodeEntryStore';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from '../../../assets/icons/AlertCircle';
import { NetworkingError } from '../../error/store/ErrorStore';
import { Button } from '../../common/components/Button';

export const EnterAuthCode = observer((): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();
    const store = useAuthCodeEntryStore();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [authCodeError, setAuthCodeError] = useState<boolean>(false);
    const [disableVerifyButton, setDisableVerifyButton] = useState<boolean>(true);

    useEffect(() => {
        // this is done to ensure that store is reset when screen is presented
        store.resetStore();
    }, []);

    useEffect(() => {
        if (isDialogOpen === false) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            store.removeError();
        }
    }, [isDialogOpen]);

    const StyledButtonContainer = styled(Box)(() => ({
        width: '360px',
        height: '56px',
        marginTop: authCodeError ? '180px' : '200px'
    }));

    const EnterAuthCodeTypography = styled(Typography)({
        color: palette.label[200],
        ...typography.body2,
        width: '360px',
        height: '48px',
        textAlign: 'center'
    });

    const onAuthCodeTextChange = (value: string): void => {
        setAuthCodeError(false);
        store.removeError();
        store.setAuthCode(value);
        if (store.error === AuthCodeEntryErrors.IncorrectAuthCodeLength) {
            setDisableVerifyButton(true);
        } else {
            setDisableVerifyButton(false);
        }
    };

    const authCodeErrorHelperText = (): string | undefined => {
        if (store.error && store.error === AuthCodeEntryErrors.InvalidAuthCode) {
            return t('signIn.invalidAuthCode');
        }
        return undefined;
    };

    const handleAuthCodeError = (): void => {
        if (store.error) {
            switch (store.error) {
                case AuthCodeEntryErrors.InvalidAuthCode:
                    setAuthCodeError(true);
                    break;
                case AuthCodeEntryErrors.IncorrectAuthCodeLength:
                    setAuthCodeError(false);
                    break;
                case NetworkingError.InternalError:
                    setIsDialogOpen(true);
                    break;
                default:
                    setIsDialogOpen(true);
                    break;
            }
        }
    };

    const authCodeTextLabel = (): React.ReactElement => {
        return (
            <Typography
                sx={{
                    ...typography.body2,
                    color: palette.label[200],
                    width: '40px',
                    marginTop: '12px',
                    whiteSpace: 'nowrap'
                }}>
                {`${store.authCodeLHS()} -`}
            </Typography>
        );
    };

    const authCodeTextField = (): React.ReactElement => {
        return (
            <TextInputField
                name="auth-code"
                value={store.authCode}
                type="number"
                onTextChange={onAuthCodeTextChange}
                label={t('signIn.authCode')}
                helperText={authCodeErrorHelperText()}
                helperIcon={authCodeError ? <AlertCircle /> : undefined}
                error={authCodeError}
                inputProps={{
                    maxLength: store.authCodeMaxLength()
                }}
                style={{
                    width: '316px',
                    marginLeft: '4px'
                }}
            />
        );
    };

    const onVerifyAuthCodeClick = async (): Promise<void> => {
        setLoading(true);
        await store.confirmAuthCode();
        if (store.error) {
            handleAuthCodeError();
        }
        if (store.otpId) {
            navigate('/auth/enter-otp', { replace: true });
        }
        setLoading(false);
    };

    const verifyAuthButton = (): React.ReactElement => {
        return (
            <StyledButtonContainer>
                <Button
                    name="verifyAuthCode"
                    variant="filled"
                    size="large"
                    title={t('signIn.verifyAuthCode')}
                    isDisabled={disableVerifyButton}
                    color="primary"
                    onClick={async (): Promise<void> => {
                        await onVerifyAuthCodeClick();
                    }}
                    style={{
                        width: '360px'
                    }}
                />
            </StyledButtonContainer>
        );
    };

    const errorDialog = (): React.ReactElement => {
        let error: CommonAuthErrors | NetworkingError | AuthCodeEntryErrors | undefined;
        if (store.error) {
            error = store.error as CommonAuthErrors | NetworkingError;
        }
        return <AuthErrorDialog open={isDialogOpen} error={error} setOpen={setIsDialogOpen} />;
    };

    return (
        <>
            <EnterAuthCodeTypography>{t('signIn.enterAuthCode')}</EnterAuthCodeTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '24px',
                    width: '360px'
                }}>
                {authCodeTextLabel()}
                {authCodeTextField()}
            </Box>
            {verifyAuthButton()}
            {errorDialog()}
            <LoadingIndicator isLoading={loading} color="white" />
        </>
    );
});
