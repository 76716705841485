import {
    CountryCBSId,
    CountryCode,
    GetCountryAndCBSIdListForFDRRPC,
    LocalizedText
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCountryAndCBSIdListForFDRRPCImpl extends GetCountryAndCBSIdListForFDRRPC {
    execute(
        _request: GetCountryAndCBSIdListForFDRRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCountryAndCBSIdListForFDRRPC.Response,
            GetCountryAndCBSIdListForFDRRPC.Errors.Errors
        >
    > {
        const cbsCountryId: CountryCBSId[] = [
            new CountryCBSId(
                new LocalizedText('Malawi'),
                new CountryCode('25'),
                new LeoUUID('e751127b-df82-4bd2-894d-7096be687ed1')
            ),
            new CountryCBSId(
                new LocalizedText('India'),
                new CountryCode('91'),
                new LeoUUID('4944bd58-30f8-4c02-bf22-1ef52cdc7763')
            ),
            new CountryCBSId(
                new LocalizedText('Zambia'),
                new CountryCode('26'),
                new LeoUUID('4c41fab4-164b-44d8-9522-4e541d6949cf')
            )
        ];
        const response = new GetCountryAndCBSIdListForFDRRPC.Response(cbsCountryId);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCountryAndCBSIdListForFDRRPC.Response,
                GetCountryAndCBSIdListForFDRRPC.Errors.Errors
            >
        >;
    }
}
