import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface ViewUnclaimedFundsActionSectionProps {
    onViewDetailsButtonClick: () => void;
}

export const ViewUnclaimedFundsActionSection = ({
    onViewDetailsButtonClick
}: ViewUnclaimedFundsActionSectionProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 16px 16px 16px',
                borderTop: `1px solid ${palette.outline[200]}`,
                justifyContent: 'flex-start'
            }}>
            <Button
                name="secondary-button"
                variant="outlined-color"
                size="small"
                title={t('common.viewDetails')}
                onClick={onViewDetailsButtonClick}
                style={{
                    width: '100px'
                }}
            />
        </Box>
    );
};
