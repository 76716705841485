import { Grid, Stack, styled, Typography } from '@mui/material';
import { BOGender } from '@resolut-tech/bcn-rpcs';
import {
    DropdownInputField,
    DropdownItem,
    InvalidEmailError,
    TextInputField,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { CountryModel, CountryViewModel } from '../../../store/country-list/CountryListStore';
import { BOUserBasicDetailsModel } from '../store/BOUserDetailStore';
import { useValidateBOUserDetailsStore } from '../store/hooks';
import { getBOUserGenderOptions } from '../utils/UIUtils';

interface BasicDetailFieldsProps {
    country: Instance<typeof CountryModel> | null;
    countries: CountryViewModel[] | null;
    onCountryChange: (value: CountryViewModel | null) => void;
    emailErrorMessage: string | null;
    mobileNumberErrorMessage: string | null;
    onEmailChange: () => void;
    onMobileNumberChange: () => void;
    onInvalidEmail: (error: string) => void;
    userDetails?: Instance<typeof BOUserBasicDetailsModel>;
}

export const BasicDetailFields = observer(
    ({
        country,
        countries,
        onCountryChange,
        emailErrorMessage,
        mobileNumberErrorMessage,
        onEmailChange,
        onMobileNumberChange,
        userDetails,
        onInvalidEmail
    }: BasicDetailFieldsProps): React.ReactElement => {
        const palette = usePalette();
        const typography = useTypography();
        const { t } = useTranslation();
        const store = useValidateBOUserDetailsStore();

        const StyledTypography = styled(Typography)({
            ...typography.small2,
            color: palette.label[300]
        });

        useEffect(() => {
            // isStorePrefilled is used to check if the details of the user are pre-filled in the store while editing the user.
            if (userDetails && !store.isStorePrefilled()) {
                store.preFillData();
                const _country = countries?.find((countryIterator) => {
                    return countryIterator.name === userDetails?.countryName;
                });
                if (_country) {
                    onCountryChange(_country);
                    store.setCountry(_country);
                    store.setPhoneNumber(userDetails.phoneNumber.slice(_country.phoneCode.length));
                }
            }
        }, []);

        const getCountryDropdownItems = (): DropdownItem[] => {
            if (countries) {
                return countries.map((countryIterator) => {
                    return {
                        name: `${countryIterator.name} (${countryIterator.phoneCode})`,
                        value: countryIterator.name,
                        image: (
                            <img
                                src={countryIterator.flagURL}
                                style={{ height: '15px', width: '20px', marginRight: '8px' }}
                            />
                        )
                    };
                });
            }
            return [];
        };

        const handleCountrySelection = (value: DropdownItem): void => {
            const _country = countries?.find((countryIterator) => {
                return countryIterator.name === value.value;
            });
            if (_country) {
                onCountryChange(_country);
                store.setCountry(_country);
                store.setPhoneNumber('');
            }
        };

        const getBasicDetails = (): React.ReactElement => {
            return (
                <Grid container spacing="12px">
                    <Grid item xs={6}>
                        <TextInputField
                            name="firstName"
                            value={store.firstName ?? ''}
                            type="text"
                            onTextChange={store.setFirstName}
                            isRequired={true}
                            label={t('common.firstName')}
                            style={{ width: '242px' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInputField
                            name="lastName"
                            value={store.lastName ?? ''}
                            type="text"
                            onTextChange={store.setLastName}
                            label={t('common.lastName')}
                            style={{ width: '242px' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInputField
                            name="otherNames"
                            value={store.otherNames ?? ''}
                            type="text"
                            onTextChange={store.setOtherNames}
                            label={t('common.otherNames')}
                            style={{ width: '242px' }}
                        />
                    </Grid>
                    {!userDetails && (
                        <>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <DropdownInputField
                                    name="country"
                                    value={country?.name}
                                    onSelect={handleCountrySelection}
                                    label={t('common.country')}
                                    options={getCountryDropdownItems()}
                                    style={{ width: '242px' }}
                                    isRequired={true}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6}>
                        <TextInputField
                            name="mobileNumber"
                            value={store.phoneNumber ?? ''}
                            type="number"
                            onTextChange={(value: string): void => {
                                store.setPhoneNumber(value);
                                store.removeError();
                                onMobileNumberChange();
                            }}
                            inputAdornmentPlacement="left"
                            inputAdornmentText={country?.phoneCode}
                            isRequired={true}
                            label={t('common.mobileNumber')}
                            style={{ width: '242px' }}
                            error={mobileNumberErrorMessage ? true : false}
                            helperText={mobileNumberErrorMessage ?? undefined}
                            helperIcon={mobileNumberErrorMessage ? <AlertCircle /> : undefined}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInputField
                            name="emailId"
                            value={store.emailId ?? ''}
                            type="email"
                            onTextChange={(
                                value: string,
                                error: InvalidEmailError | undefined
                            ): void => {
                                store.setEmailId(value);
                                store.removeError();
                                onEmailChange();
                                if (error) {
                                    onInvalidEmail(t('common.invalidEmail'));
                                }
                            }}
                            label={t('common.emailId')}
                            isRequired={true}
                            style={{ width: '242px' }}
                            error={emailErrorMessage ? true : false}
                            helperText={emailErrorMessage ?? undefined}
                            helperIcon={emailErrorMessage ? <AlertCircle /> : undefined}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropdownInputField
                            name="gender"
                            value={store.gender ?? undefined}
                            onSelect={(gender): void => {
                                store.setGender(gender.value as BOGender.BOGender);
                            }}
                            label={t('common.gender')}
                            isRequired={true}
                            options={getBOUserGenderOptions(t)}
                            style={{ width: '242px' }}
                        />
                    </Grid>
                </Grid>
            );
        };

        return (
            <Stack spacing="12px" sx={{ marginBottom: '20px' }}>
                <StyledTypography>{t('common.basicDetails')}</StyledTypography>
                {getBasicDetails()}
            </Stack>
        );
    }
);
