import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { ColDef } from 'ag-grid-community';
import { useUpdateTransactionRulesStore, useViewTransactionRulesStore } from '../store/hooks';
import { observer } from 'mobx-react';
import { LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import { EditRules } from '../../common/components/rules/EditRules';
import { TransactionValidationRuleModel } from '../models/TransactionValidationRuleModel';
import { Instance } from 'mobx-state-tree';
import { InsertTransactionRulesRowDialog } from '../components/InsertTransactionRulesRowDialog';
import { EditRulesUpdateRequestDialog } from '../../common/components/rules/EditRulesUpdateRequestDialog';
import { generateCSVFile } from '../../common/utils/FileUtils';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { UpdateTransactionRulesErrors } from '../store/UpdateTransactionRulesStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { RuleValidationErrorComponent } from '../../common/components/rules/RuleValidationErrorComponent';
import {
    parseRuleDecimalAmount,
    parseFormattedString,
    getCountryNameFromCbsId,
    getCbsIdFromCountryName
} from '../../common/utils/UIUtils';
import { getFormattedTimeFromSeconds } from '../../common/utils/DateUtils';
import { RuleEditMechanism } from '@resolut-tech/bcn-rpcs';

export interface TransactionRulesRowData {
    monetaryLimit: string;
    periodicLimit: string;
    timeWindowInSeconds: string;
    cbsId: string;
}

export const EditTransactionRules = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useViewTransactionRulesStore();
    const updateTransactionRulesStore = useUpdateTransactionRulesStore();
    const [isLoading, setIsLoading] = useState(false);
    const initialRows = useRef<TransactionRulesRowData[]>([]);
    const [rows, setRows] = useState<TransactionRulesRowData[]>([]);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isTransactionRulesEdited = JSON.stringify(initialRows.current) === JSON.stringify(rows);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showRequestTransactionRulesUpdateDialog, setShowRequestTransactionRulesUpdateDialog] =
        useState(false);
    const [errorDialogLabel, setErrorDialogLabel] = useState<string | null>(null);
    const [isRuleUpdated, setIsRuleUpdated] = useState(false);
    const [ruleValidationError, setRuleValidationError] = useState<string | null>(null);

    const isEmptyRow = (row: TransactionRulesRowData): boolean => {
        if (
            row.monetaryLimit !== '' ||
            row.periodicLimit !== '' ||
            row.timeWindowInSeconds !== '' ||
            row.cbsId !== ''
        ) {
            return false;
        } else {
            return true;
        }
    };

    // setRowData checks for empty row in row data. If there is an empty row while updating the row data, it will be removed.
    const setRowData = (_rows: TransactionRulesRowData[]): void => {
        const filteredRows = _rows.filter((row) => !isEmptyRow(row));
        setRows(filteredRows);
    };

    const transactionValidationRuleModelToRows = (
        rules: Instance<typeof TransactionValidationRuleModel>[]
    ): TransactionRulesRowData[] => {
        return rules.map((rule) => ({
            monetaryLimit: rule.monetaryLimit.toString(),
            periodicLimit: rule.periodicLimit.toString() ?? '',
            timeWindowInSeconds: rule.timeWindowInSeconds.toString() ?? '',
            cbsId: rule.cbsId
        }));
    };

    const fetchCurrentTransactionDeterminationRuleDetails = async (): Promise<void> => {
        setIsLoading(true);
        await store.fetchCurrentTransactionValidationDetails().then(() => {
            return store.fetchCountryAndCBSIdList();
        });
        if (store.error) {
            setErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
        } else if (store.currentTransactionValidationRules) {
            const _rows = transactionValidationRuleModelToRows(
                store.currentTransactionValidationRules
            );
            const _initialRows = transactionValidationRuleModelToRows(
                store.currentTransactionValidationRules
            );
            initialRows.current = _initialRows;
            for (const row of _rows) {
                row.cbsId = getCountryNameFromCbsId(store.getCbsIdWithCountryList(), row.cbsId);
            }
            setRowData(_rows);
        } else {
            setRowData([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchCurrentTransactionDeterminationRuleDetails();

        return (): void => {
            setIsLoading(false);
            setRows([]);
            updateTransactionRulesStore.resetStore();
        };
    }, []);

    const columns: ColDef[] = [
        {
            field: 'monetaryLimit',
            minWidth: 200,
            headerName: t('rulesCSVHeaders.transactionRules.monetaryLimit'),
            valueFormatter: (transactionRule): string => {
                const _transactionRule: TransactionRulesRowData = transactionRule.data;
                if (_transactionRule) {
                    return parseRuleDecimalAmount(_transactionRule.monetaryLimit);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'periodicLimit',
            flex: 1,
            minWidth: 144,
            headerName: t('rulesCSVHeaders.transactionRules.periodicLimit'),
            valueFormatter: (transactionRule): string => {
                const _transactionRule: TransactionRulesRowData = transactionRule.data;
                if (_transactionRule) {
                    return parseFormattedString(_transactionRule.periodicLimit);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'timeWindowInSeconds',
            flex: 1,
            minWidth: 144,
            headerName: t('rulesCSVHeaders.transactionRules.timeWindowInSeconds'),
            valueFormatter: (transactionRule): string => {
                const _transactionRule: TransactionRulesRowData = transactionRule.data;
                if (_transactionRule) {
                    return parseFormattedString(_transactionRule.timeWindowInSeconds);
                } else {
                    return '';
                }
            },
            tooltipValueGetter: (params): string => {
                if (params.value) {
                    return getFormattedTimeFromSeconds(params.value);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'cbsId',
            flex: 1,
            minWidth: 160,
            headerName: t('common.cbs'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: store.getCbsIdCountryList()
            }
        }
    ];

    const getDataAsCsv = (): string => {
        // CSV file data is generated manually. It should contain both the headers and rows.
        // csvHeaders will be used as the first line in CSV file to hold the headers.
        const csvHeaders = `Sum of Amount of Transactions,Total Number of Transactions,Time Window in Seconds,CBS ID`;
        const csvRows = rows.map((row) => {
            const monetaryLimit = row.monetaryLimit;
            const cbsId = getCbsIdFromCountryName(store.getCbsIdWithCountryList(), row.cbsId);
            return `${monetaryLimit},${row.periodicLimit},${row.timeWindowInSeconds},${cbsId}`;
        });
        return [csvHeaders, ...csvRows].join('\n');
    };

    const getActionElement = (): ActionElement => {
        return {
            primaryButton: {
                title: t('common.requestUpdate'),
                isDisabled: isTransactionRulesEdited,
                onClick: (): void => {
                    const csvData = getDataAsCsv();
                    updateTransactionRulesStore.setCsvData(csvData);
                    setShowRequestTransactionRulesUpdateDialog(true);
                }
            },
            secondaryButton: {
                title: t('common.cancel'),
                onClick: (): void => {
                    navigate('/transaction-rules/update');
                }
            }
        };
    };

    const onUploadRuleSubmitFile = async (recordId: LeoUUID): Promise<void> => {
        updateTransactionRulesStore.setFileId(recordId.uuid);
        await updateTransactionRulesStore.updateTransactionRule(
            RuleEditMechanism.RuleEditMechanism.INLINE_EDIT
        );
        if (updateTransactionRulesStore.error) {
            switch (updateTransactionRulesStore.error) {
                case UpdateTransactionRulesErrors.InvalidFile:
                    setRuleValidationError(
                        updateTransactionRulesStore.errorLocalizedText ??
                            t('common.updateRuleError')
                    );
                    break;
                case UpdateTransactionRulesErrors.InvalidComment:
                    setErrorDialogLabel(t('common.invalidComment'));
                    break;
                case UpdateTransactionRulesErrors.UnknownFile:
                    setErrorDialogLabel(t('common.updateRuleError'));
                    break;
                default:
                    setErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            setIsRuleUpdated(true);
        }
    };

    return (
        <Stack>
            <PageHeader
                title={t('transactionRules.updateRules')}
                subtitle={t('transactionRules.updateRulesSubtitle')}
                actionElement={getActionElement()}
            />
            <InsertTransactionRulesRowDialog
                isDialogOpen={isDialogOpen}
                onDialogClose={(): void => {
                    setIsDialogOpen(false);
                }}
                onInsertButtonClick={(row): void => {
                    setRowData([...rows, row]);
                }}
            />
            {updateTransactionRulesStore.csvData && (
                <EditRulesUpdateRequestDialog
                    title={t('transactionRules.updateRules')}
                    isDialogOpen={showRequestTransactionRulesUpdateDialog}
                    onDialogClose={(): void => {
                        setShowRequestTransactionRulesUpdateDialog(false);
                        updateTransactionRulesStore.resetStore();
                    }}
                    setStoreComment={updateTransactionRulesStore.setComment}
                    csvFile={generateCSVFile(
                        updateTransactionRulesStore.csvData,
                        t('transactionRules.generatedCSVFileName')
                    )}
                    uploadStore={updateTransactionRulesStore.uploadTransactionRulesStore}
                    onUploadRuleSubmitFile={onUploadRuleSubmitFile}
                    setErrorDialogLabel={setErrorDialogLabel}
                    validatingRulesMessage={t('transactionRules.validatingRules')}
                />
            )}
            <ErrorDialog
                title={t('transactionRules.updateRules')}
                isErrorDialogOpen={Boolean(errorDialogLabel)}
                errorMessage={errorDialogLabel}
                onClose={(): void => {
                    setErrorDialogLabel(null);
                    updateTransactionRulesStore.resetError();
                    store.removeError();
                }}
            />
            <SuccessDialog
                title={t('transactionRules.updateRules')}
                isDialogOpen={isRuleUpdated}
                successMessage={t('common.requestRaisedSuccessfully')}
                onCancel={(): void => {
                    setIsRuleUpdated(false);
                    navigate('/transaction-rules/update');
                }}
            />
            <Stack padding="32px">
                {isLoading ? (
                    <LoadingIndicator isLoading={isLoading} />
                ) : (
                    <Stack spacing="32px">
                        {ruleValidationError && (
                            <RuleValidationErrorComponent errorText={ruleValidationError} />
                        )}
                        <EditRules
                            rows={rows}
                            columns={columns}
                            setRows={setRowData}
                            onInsertButtonClick={(): void => {
                                setIsDialogOpen(true);
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
});
