import { BOPlatform, Password, SubmitResetPasswordRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { useSubmitResetPasswordRPCClient } from '../rpcs/RPC';
import { PasswordPolicyModel } from '../../common/models/PasswordPolicyModel';
import { SignInStore } from './SignInStore';
import { getAPIClient } from '../../networking/store/NetworkingStore';
import { LeoErrors } from '../../home/common/errors/LeoErrors';
import { getLoggerStore } from '../../../log/hooks';
import { LoggerStore } from '../../../log/LoggerStore';
import { NetworkingError } from '../../error/store/ErrorStore';

export enum NewPasswordEntryErrors {
    InsecurePassword = 'INSECURE_PASSWORD',
    ReusedPassword = 'REUSED_PASSWORD',
    PasswordMismatch = 'PASSWORD_MISMATCH',
    OTPValidatedTokenExpired = 'OTP_VALIDATED_TOKEN_EXPIRED',
    InvalidOTPValidatedToken = 'INVALID_OTP_VALIDATED_TOKEN'
}

const getStoreError = (
    error: SubmitResetPasswordRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): NewPasswordEntryErrors | CommonAuthErrors | LeoErrors | NetworkingError => {
    switch (error.code) {
        case CommonAuthErrors.InactiveState:
            return CommonAuthErrors.InactiveState;
        case NewPasswordEntryErrors.InvalidOTPValidatedToken:
        case NewPasswordEntryErrors.OTPValidatedTokenExpired:
            return NetworkingError.InternalError;
        case NewPasswordEntryErrors.InsecurePassword:
            return NewPasswordEntryErrors.InsecurePassword;
        case NewPasswordEntryErrors.ReusedPassword:
            return NewPasswordEntryErrors.ReusedPassword;
        case CommonAuthErrors.BoUserDisabled:
            return CommonAuthErrors.BoUserDisabled;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in NewPasswordEntryStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): LeoErrors | CommonAuthErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidPasswordError:
            return LeoErrors.InvalidPasswordError;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in NewPasswordEntryStore`);
            return NetworkingError.InternalError;
    }
};

export const NewPasswordEntryStore = types
    .model({
        newPassword: types.string,
        confirmNewPassword: types.string,
        error: types.maybeNull(
            types.union(
                types.enumeration<NewPasswordEntryErrors>(
                    'NewPasswordEntryErrors',
                    Object.values(NewPasswordEntryErrors)
                ),
                types.enumeration<CommonAuthErrors>(
                    'CommonAuthErrors',
                    Object.values(CommonAuthErrors)
                ),
                types.enumeration<LeoErrors>('LeoErrors', Object.values(LeoErrors)),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        completeSignInProcess(): void {
            const signInStore = getParent<typeof SignInStore>(store);
            signInStore.completeSignInProcess();
        },
        resetStore(): void {
            store.newPassword = '';
            store.confirmNewPassword = '';
            store.error = null;
        },
        setNewPassword(newPassword: string): void {
            store.newPassword = newPassword;
        },
        setConfirmNewPassword(confirmNewPassword: string): void {
            store.confirmNewPassword = confirmNewPassword;
        },
        removeError(): void {
            store.error = null;
        },
        submitResetPassword: flow(function* () {
            const loggerStore = getLoggerStore(store);
            if (store.newPassword === store.confirmNewPassword) {
                try {
                    store.error = null;
                    const _newPassword = new Password(store.newPassword);
                    const signInStore = getParent<typeof SignInStore>(store);
                    const otpValidatedToken = signInStore.otpValidatedToken();
                    if (otpValidatedToken) {
                        const request: SubmitResetPasswordRPC.Request =
                            new SubmitResetPasswordRPC.Request(
                                otpValidatedToken,
                                _newPassword.password,
                                BOPlatform.BOPlatform.WEB
                            );
                        const apiClient = getAPIClient(store);
                        const result: LeoRPCResult<
                            SubmitResetPasswordRPC.Response,
                            SubmitResetPasswordRPC.Errors.Errors
                        > = yield useSubmitResetPasswordRPCClient(apiClient).execute(request);
                        if (result instanceof LeoRPCResult.Response) {
                            return;
                        } else if (result instanceof LeoRPCResult.Error) {
                            const { error } = result;
                            store.error = getStoreError(error, loggerStore);
                        } else {
                            loggerStore.error(
                                `Unknown error occurred in SubmitResetPasswordRPC with result: ${result}`
                            );
                            store.error = NetworkingError.InternalError;
                        }
                    } else {
                        // since we can't recover from this error hence internal error is thrown here
                        loggerStore.debug('otpValidatedToken not found in NewPasswordEntryStore');
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        store.error = getLeoError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error: ${error} occurred in NewPasswordEntryStore`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                }
            } else {
                store.error = NewPasswordEntryErrors.PasswordMismatch;
            }
        })
    }))
    .views((store) => ({
        getPasswordPolicy: (): Instance<typeof PasswordPolicyModel> | null => {
            const signInStore = getParent<typeof SignInStore>(store);
            return signInStore.passwordPolicy();
        }
    }));

export const createNewPasswordEntryStore = (): Instance<typeof NewPasswordEntryStore> => {
    return NewPasswordEntryStore.create({
        newPassword: '',
        confirmNewPassword: ''
    });
};
