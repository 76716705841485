import { RequestTransactionRefundRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestTransactionRefundRPCImpl extends RequestTransactionRefundRPC {
    execute(
        _request: RequestTransactionRefundRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestTransactionRefundRPC.Response,
            RequestTransactionRefundRPC.Errors.Errors
        >
    > {
        const response = new RequestTransactionRefundRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestTransactionRefundRPC.Response,
                RequestTransactionRefundRPC.Errors.Errors
            >
        >;
    }
}
