import { Stack } from '@mui/material';
import {
    Icon,
    LoadingIndicator,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { EmptyStateComponent } from '../../common/components/EmptyStateComponent';
import thumbUp from '../../../../assets/thumbs-up.svg';
import { observer } from 'mobx-react';
import { LoadingState } from '../../common/components/LoadingState';
import {
    useCheckCBSTransferRequestStore,
    useDownloadUnclaimedFundsRequestStore
} from '../store/hooks';
import { DownloadRequestsZipDialog } from '../components/DownloadRequestsZipDialog';
import { getAmountString, getFormattedPhoneNumber } from '../../common/utils/UIUtils';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestStatus } from '../../common/enums/RequestStatus';
import { TransactionSummary } from '../components/TransactionSummary';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { useNavigate } from 'react-router-dom';
import { Status } from '../../common/enums/StatusEnum';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { CheckCBSTransferRequestStoreErrors } from '../store/CheckCBSTransferRequestStore';
import { UnclaimedProcessingRequestDetailCard } from '../components/UnclaimedProcessingCard';
import { UnclaimedFundsPendingRequestCard } from '../components/UnclaimedFundsPendingRequestCard';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { DownloadUnclaimedFundsRequestStoreErrors } from '../store/DownloadUnclaimedFundsRequestStore';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ApproveCBSTransfer = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const store = useCheckCBSTransferRequestStore();
    const [isDownloadRequestsZipDialogOpen, setIsDownloadRequestsZipDialogOpen] =
        useState<boolean>(false);
    const palette = usePalette();
    const downloadUnclaimedFundsRequestStore = useDownloadUnclaimedFundsRequestStore();
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>();
    const userStore = useUserStore();
    const navigate = useNavigate();
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState<boolean>(false);
    const [requestDialogBoxTitle, setRequestDialogBoxTitle] = useState<string | null>(null);
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();

    const fetchCountryList = async (): Promise<void> => {
        await store.fetchCountries();
    };

    const fetchPendingUnclaimedFundsRequestDetails = async (): Promise<void> => {
        setLoading(true);
        await store.getCheckerUnclaimedFundsRequestInfoSummary();
        setLoading(false);
        if (store.error === NetworkingError.InternalError) {
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
    };

    useEffect(() => {
        fetchCountryList();
        fetchPendingUnclaimedFundsRequestDetails();
        breadcrumbStore.setInitialLink(
            t('unclaimedFunds.approveCBSTransfer'),
            window.location.pathname
        );
    }, []);

    const DownloadRequestsZipDialogBox = (): React.ReactElement => {
        return (
            <DownloadRequestsZipDialog
                isDialogOpen={isDownloadRequestsZipDialogOpen}
                onCancel={(): void => {
                    setIsDownloadRequestsZipDialogOpen(false);
                }}
            />
        );
    };

    const onApproveClick = (): void => {
        store.setStatus(Status.APPROVED);
        setRequestDialogBoxTitle(t('unclaimedFunds.approveCBSTransfer'));
        setIsRequestCheckDialogOpen(true);
    };

    const onDenyClick = (): void => {
        store.setStatus(Status.DENIED);
        setRequestDialogBoxTitle(t('unclaimedFunds.denyCBSTransfer'));
        setIsRequestCheckDialogOpen(true);
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogTitle(null);
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
                errorMessage={errorDialogMessage}
            />
        );
    };

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '160px'
            },
            {
                id: 'userName',
                name: t('common.userName'),
                width: '160px'
            },
            {
                id: 'userMobileNumber',
                name: t('common.userMobileNumber'),
                width: '160px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '160px'
            },
            {
                id: 'country',
                name: t('common.country'),
                width: '120px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<undefined>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (store.requestDetails?.requestId) {
            await store.getUnclaimedFundsRequestDetailsList(
                store.requestDetails.requestId,
                option.page ? option.page - 1 : 0,
                store.itemsPerPage(),
                option.sort?.order
            );
        } else {
            loggerStore.debug('Cannot find request Id GetPendingUnclaimedFundsRequestDetailsRPC');
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        if (store.error) {
            switch (store.error) {
                case CheckCBSTransferRequestStoreErrors.InvalidRequestId:
                case CheckCBSTransferRequestStoreErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.unclaimedFundsList.map((unclaimedFund) => {
            return [
                { data: unclaimedFund.createdAt },
                { data: unclaimedFund.userName },
                { data: getFormattedPhoneNumber(unclaimedFund.userPhoneNumber) },
                { data: getAmountString(unclaimedFund.amount) },
                { data: unclaimedFund.country },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const onClickApproveOrDenyUpdate = async (comment: string): Promise<void> => {
        setIsRequestCheckDialogOpen(false);
        const requestId = store.requestDetails?.requestId;
        if (requestId) {
            setScreenBlockingLoading(true);
            await store.checkCBSTransferRequest(requestId, comment);
            setScreenBlockingLoading(false);
            if (store.error) {
                if (store.status === Status.APPROVED) {
                    setErrorDialogTitle(t('unclaimedFunds.approveCBSTransfer'));
                } else {
                    setErrorDialogTitle(t('unclaimedFunds.denyCBSTransfer'));
                }
                switch (store.error) {
                    case CheckCBSTransferRequestStoreErrors.CannotCheckSelfRequest:
                        setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.InvalidRequestId:
                        setErrorDialogMessage(t('common.somethingWentWrong'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.RequestDiscardedDueToUnknownError:
                        setErrorDialogMessage(t('unclaimedFunds.failedToMoveOutErrorMessage'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.RequestDiscarded:
                        setErrorDialogMessage(t('unclaimedFunds.failedToMoveOutErrorMessage'));
                        break;
                    case CheckCBSTransferRequestStoreErrors.InvalidComment:
                        setErrorDialogMessage(t('common.invalidComment'));
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            } else {
                // Since the request has been approved or denied, we will fetch the Pending unclaimed funds request details again.
                fetchPendingUnclaimedFundsRequestDetails();
            }
        }
    };

    const RequestCheckDialogBox = (): React.ReactElement => {
        return (
            <RequestDialog
                isDialogOpen={isRequestCheckDialogOpen}
                title={requestDialogBoxTitle}
                buttonTitle={
                    store.status === Status.APPROVED
                        ? t('common.approveTransfer')
                        : t('common.denyTransfer')
                }
                onSubmit={async (comment: string): Promise<void> => {
                    await onClickApproveOrDenyUpdate(comment);
                }}
                onClose={(): void => {
                    setIsRequestCheckDialogOpen(false);
                    store.resetComment();
                }}
            />
        );
    };

    const handleDownloadButtonClick = async (id: string): Promise<void> => {
        setScreenBlockingLoading(true);
        await downloadUnclaimedFundsRequestStore.downloadUnclaimedFundsRequest(id);
        setScreenBlockingLoading(false);
        if (downloadUnclaimedFundsRequestStore.error) {
            setErrorDialogTitle(t('common.downloadRequestsZip'));
            switch (downloadUnclaimedFundsRequestStore.error) {
                case DownloadUnclaimedFundsRequestStoreErrors.RequestToDownloadIsInProgress:
                    setErrorDialogMessage(t('unclaimedFunds.downloadRequestsZipInProgress'));
                    break;
                case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestId:
                case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestType:
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            setIsDownloadRequestsZipDialogOpen(true);
        }
    };

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckCBSTransferRequest
    );

    const getPendingCard = (): React.ReactElement => {
        const showPendingCard: Boolean = Boolean(
            store.requestDetails?.status === RequestStatus.PENDING
        );
        if (store.requestDetails && showPendingCard && store.requestDetails.requesterComment) {
            return (
                <UnclaimedFundsPendingRequestCard
                    comment={store.requestDetails.requesterComment}
                    onDenyClick={onDenyClick}
                    onApproveClick={onApproveClick}
                    showApproveAndDenySection={showApproveAndDenySection}>
                    {store.totalAmount && store.totalNoOfTransaction && (
                        <TransactionSummary
                            onDownloadClick={(): void => {
                                if (store.requestDetails) {
                                    handleDownloadButtonClick(store.requestDetails.requestId);
                                } else {
                                    loggerStore.debug(
                                        'Cannot find request id in CheckCBSTransferRequest Store while downloading the transaction summary'
                                    );
                                    setErrorDialogMessage(
                                        t('common.somethingWentWrongProcessingRequest')
                                    );
                                }
                            }}
                            totalAmount={store.totalAmount}
                            status={store.requestDetails.status}
                            totalNoOfTransaction={store.totalNoOfTransaction}
                        />
                    )}
                </UnclaimedFundsPendingRequestCard>
            );
        } else {
            return <></>;
        }
    };

    const getProcessingCard = (): React.ReactElement => {
        const showProcessingCard: Boolean = Boolean(
            store.requestDetails?.status === RequestStatus.PROCESSING
        );
        if (store.requestDetails && showProcessingCard && store.requestDetails.requesterComment) {
            return (
                <UnclaimedProcessingRequestDetailCard
                    makerComment={store.requestDetails.requesterComment}
                    // Checker comment is always present when the request is in `Processing` state.
                    checkerComment={store.requestDetails.evaluatorComment!}
                    viewDownloadButton={true}
                    onSecondaryButtonClick={(): void => {
                        if (store.requestDetails) {
                            handleDownloadButtonClick(store.requestDetails.requestId);
                        } else {
                            loggerStore.debug(
                                'Cannot find request id in Check CBS Transfer Request Store'
                            );
                            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        }
                    }}
                />
            );
        } else {
            loggerStore.debug(
                'Cannot find requestDetails or requesterComment while showing processing card'
            );
            return <></>;
        }
    };

    return (
        <Stack>
            <LoadingIndicator isLoading={screenBlockingLoading} color="white" />
            <PageHeader
                title={t('unclaimedFunds.approveCBSTransfer')}
                subtitle={t('unclaimedFunds.approveUnclaimedFundsSubtitle')}
            />
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    {store.requestDetails ? (
                        <Stack
                            spacing="32px"
                            sx={{
                                padding: '32px',
                                boxSizing: 'border-box',
                                width: '100%'
                            }}>
                            {getPendingCard()}
                            {getProcessingCard()}
                            <Table
                                // To remove the border and borderRadius added on the table view when used inside a section component.
                                styleOverrides={{
                                    borderRadius: '0px',
                                    border: 'none',
                                    width: 'calc(100vw - 304px)'
                                }}
                                name="unclaimedFundsRequestDetailsList"
                                headers={getHeaders()}
                                onTableOptionsChange={getData}
                                paginationOption={{
                                    itemsPerPage: store.itemsPerPage(),
                                    getPageIndicatorText(startItem, endItem, totalItems): string {
                                        return t('common.paginationIndicationText', {
                                            startItem,
                                            endItem,
                                            totalItems
                                        });
                                    }
                                }}
                                viewOverrides={{
                                    empty: { message: t('common.noResultsFound') }
                                }}
                                onRowClick={(_row, index): void => {
                                    const selectedTransaction = store.unclaimedFundsList[index];
                                    if (selectedTransaction) {
                                        navigate(
                                            `/unclaimed-funds/search/transaction-details?unclaimedFundId=${selectedTransaction.unclaimedFundId}`
                                        );
                                    } else {
                                        loggerStore.debug(
                                            `Array index: ${index} for selected Transaction in the list with length ${store.unclaimedFundsList.length} is out of bounds`
                                        );
                                        setErrorDialogMessage(
                                            t('common.somethingWentWrongProcessingRequest')
                                        );
                                    }
                                }}
                            />
                            {isDownloadRequestsZipDialogOpen && DownloadRequestsZipDialogBox()}
                            {errorDialogMessage && ErrorDialogBox()}
                            {isRequestCheckDialogOpen && RequestCheckDialogBox()}
                        </Stack>
                    ) : (
                        <EmptyStateComponent text={t('common.noActiveRequests')} image={thumbUp} />
                    )}
                </>
            )}
        </Stack>
    );
});
