import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    GetAgentRequestsPaginationResponse,
    GetAgentRequestsRPC,
    PGUserName,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';

const agentRequestsList: GetAgentRequestsPaginationResponse[] = [
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.PENDING,
        new PGUserName('Wade', 'Warren'),
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.PENDING,
        new PGUserName('Cameron', 'Williamson'),
        new PGUserName('Arlene', 'McCoy'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.APPROVED,
        new PGUserName('Wade', 'Warren'),
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.APPROVED,
        new PGUserName('Cameron', 'Williamson'),
        new PGUserName('Arlene', 'McCoy'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.DENIED,
        new PGUserName('Wade', 'Warren'),
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.DENIED,
        new PGUserName('Cameron', 'Williamson'),
        new PGUserName('Arlene', 'McCoy'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.DISCARDED,
        new PGUserName('Wade', 'Warren'),
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetAgentRequestsPaginationResponse(
        new LeoTimestamp(),
        RequestStatus.RequestStatus.DISCARDED,
        new PGUserName('Cameron', 'Williamson'),
        new PGUserName('Arlene', 'McCoy'),
        null,
        new LeoUUID()
    )
];

export class MockGetAgentRequestsRPCImpl extends GetAgentRequestsRPC {
    execute(
        request: GetAgentRequestsRPC.Request
    ): Promise<LeoRPCResult<GetAgentRequestsRPC.Response, GetAgentRequestsRPC.Errors.Errors>> {
        let list: GetAgentRequestsPaginationResponse[] = agentRequestsList;

        if (request.requestStatus) {
            list = list.filter((t) => {
                return t.requestStatus === request.requestStatus;
            });
        }

        const response = new GetAgentRequestsRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetAgentRequestsRPC.Response, GetAgentRequestsRPC.Errors.Errors>
        >;
    }
}
