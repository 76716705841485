import { Box, Typography } from '@mui/material';
import { Section, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface ProfileManagementComponentProps {
    userId: string;
    setIsDialogOpen: (load: boolean) => void;
    setIsArchiveDialogOpen: (load: boolean) => void;
    allowDeactivateOrReactivate: boolean;
    allowArchive: boolean;
    deactivationOrActivationTitle: string;
    deactivationOrActivationSubtitle: string;
    archiveTitle: string;
    archiveSubtitle: string;
}

export const ProfileManagementComponent = observer(
    ({
        userId,
        setIsDialogOpen,
        setIsArchiveDialogOpen,
        allowDeactivateOrReactivate,
        allowArchive,
        deactivationOrActivationTitle,
        deactivationOrActivationSubtitle,
        archiveTitle,
        archiveSubtitle
    }: ProfileManagementComponentProps): React.ReactElement => {
        const { t } = useTranslation();
        const typography = useTypography();

        return (
            <>
                <Section
                    title={t('common.profileManagement')}
                    allowViewChange={false}
                    content={[
                        <Box key={userId} sx={{ padding: '16px' }}>
                            {allowDeactivateOrReactivate && (
                                <Box>
                                    <Button
                                        name="secondaryButton"
                                        variant="outlined-neutral"
                                        size="small"
                                        title={deactivationOrActivationTitle}
                                        onClick={(): void => setIsDialogOpen(true)}
                                    />
                                    <Typography
                                        sx={{
                                            ...typography.body2,
                                            marginTop: '8px',
                                            marginBottom: '16px'
                                        }}>
                                        {deactivationOrActivationSubtitle}
                                    </Typography>
                                </Box>
                            )}
                            {allowArchive && (
                                <Box>
                                    <Button
                                        name="secondaryButton"
                                        variant="outlined-color"
                                        color="error"
                                        size="small"
                                        title={archiveTitle}
                                        onClick={(): void => setIsArchiveDialogOpen(true)}
                                    />
                                    <Typography sx={{ ...typography.body2, marginTop: '8px' }}>
                                        {archiveSubtitle}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ]}
                />
            </>
        );
    }
);
