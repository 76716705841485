import { Stack } from '@mui/material';
import { LoadingIndicator, TextAreaInputField } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { EmptyStateComponent } from '../../common/components/EmptyStateComponent';
import thumbUp from '../../../../assets/thumbs-up.svg';
import { useEvaluateFeeRulesStore } from '../store/hooks';
import { observer } from 'mobx-react';
import { LoadingState } from '../../common/components/LoadingState';
import { EvaluateFeeRulesErrors } from '../store/EvaluateFeeRulesStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { DownloadFileErrorDialog } from '../../common/components/DownloadFileError';
import { Status } from '../../common/enums/StatusEnum';
import { ViewFeeRulesChangesComponent } from '../components/ViewFeeRulesChangesComponent';
import { PendingRuleRequestDetailsSectionComponent } from '../../common/components/rules/PendingRuleRequestDetailsSectionComponent';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { Dialog } from '../../../common/components/Dialog';

export const ApproveFeeRules = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const store = useEvaluateFeeRulesStore();
    const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState<boolean>(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isDownloadFileErrorDialogOpen, setIsDownloadFileErrorDialogOpen] = useState(false);
    const loggerStore = useLoggerStore();

    const fetchPendingRequest = async (): Promise<void> => {
        setIsErrorDialogOpen(false);
        setIsLoadingData(true);
        store.resetStore();
        await store.fetchPendingFeeRuleDetail();
        setIsLoadingData(false);
    };

    useEffect(() => {
        fetchPendingRequest();
    }, []);

    const onClickApproveOrDenyUpdate = async (): Promise<void> => {
        setIsRequestCheckDialogOpen(false);
        setIsScreenBlockingLoading(true);
        const _requestId = store.requestId;
        if (_requestId) {
            await store.evaluatePendingRequest(_requestId);
        } else {
            loggerStore.debug('Cannot find requestId in EvaluateFeeRulesStore');
            setIsErrorDialogOpen(true);
        }
        if (store.error) {
            setIsErrorDialogOpen(true);
        } else {
            // Since the request is success, we will fetch the request again.
            fetchPendingRequest();
        }
        store.resetComment();
        setIsScreenBlockingLoading(false);
    };

    const onButtonClick = (status: Status): void => {
        store.setStatus(status);
        setIsRequestCheckDialogOpen(true);
    };

    const getErrorTitle = (): string | undefined => {
        switch (store.error) {
            case EvaluateFeeRulesErrors.InvalidComment:
                return undefined;
            default:
                return store.status === Status.APPROVED
                    ? t('common.approveUpdate')
                    : t('common.denyUpdate');
        }
    };

    const showViewFeeRulesChanges = Boolean(
        store.feeRuleDetail?.currentRuleFile && store.feeRuleDetail.newRuleFile
    );

    const getErrorText = (): string | undefined => {
        switch (store.error) {
            case EvaluateFeeRulesErrors.RequestAlreadyChecked:
                return t('common.requestAlreadyChecked');
            case EvaluateFeeRulesErrors.RequestAlreadyWithdrawn:
                return t('common.requestAlreadyWithdrawn');
            case EvaluateFeeRulesErrors.RequestDiscarded:
                return t('common.requestAlreadyDiscarded');
            case EvaluateFeeRulesErrors.CannotCheckSelfRequest:
                return t('common.cannotCheckSelfRequest');
            case EvaluateFeeRulesErrors.InvalidComment:
                return t('common.invalidComment');
            case NetworkingError.InternalError:
                return t('common.somethingWentWrongProcessingRequest');
            default:
                return undefined;
        }
    };

    const onDownloadButtonClick = async (
        documentId: string,
        fileName: RulesDownloadFileNameEnum
    ): Promise<void> => {
        setIsFileDownloading(true);
        await store.downloadFileStore.downloadFile(documentId, fileName);
        setIsFileDownloading(false);
        if (store.downloadFileStore.error) {
            setIsDownloadFileErrorDialogOpen(true);
        }
    };

    return (
        <Stack>
            <LoadingIndicator isLoading={isScreenBlockingLoading} color="white" />
            <DownloadFileErrorDialog
                isErrorDialogOpen={isDownloadFileErrorDialogOpen}
                onClose={(): void => setIsDownloadFileErrorDialogOpen(false)}
                storeError={store.downloadFileStore.error}
            />
            <ErrorDialog
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): Promise<void> => fetchPendingRequest()}
                title={getErrorTitle()}
                errorMessage={getErrorText()}
            />
            <Dialog
                open={isRequestCheckDialogOpen}
                onClose={(): void => setIsRequestCheckDialogOpen(false)}
                title={
                    store.status === Status.APPROVED
                        ? t('common.approveUpdate')
                        : t('common.denyUpdate')
                }
                primaryButtonText={
                    store.status === Status.APPROVED
                        ? t('common.approveUpdate')
                        : t('common.denyUpdate')
                }
                secondaryButtonText={t('common.cancel')}
                isPrimaryButtonDisabled={!Boolean(store.comment && store.comment.trim().length > 0)}
                onPrimaryButtonClick={async (): Promise<void> => {
                    onClickApproveOrDenyUpdate();
                }}
                onSecondaryButtonClick={async (): Promise<void> => {
                    setIsRequestCheckDialogOpen(false);
                    store.resetComment();
                }}
                disableBackdropClick>
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={store.comment ?? undefined}
                    onTextChange={(inputValue: string): void => {
                        store.setComment(inputValue);
                        store.removeError();
                    }}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                    error={store.error === EvaluateFeeRulesErrors.InvalidComment}
                    helperIcon={<AlertCircle />}
                />
            </Dialog>
            <PageHeader
                title={t('feeRules.approveUpdates')}
                subtitle={t('feeRules.approveUpdatesSubtitle')}
            />
            {isLoadingData ? (
                <LoadingState />
            ) : (
                <>
                    {store.feeRuleDetail &&
                    store.feeRuleDetail.boUserComment &&
                    store.feeRuleDetail.newRuleFile ? (
                        <Stack margin="32px" spacing="32px" direction="column">
                            <PendingRuleRequestDetailsSectionComponent
                                isLoading={isFileDownloading}
                                boUserComment={store.feeRuleDetail.boUserComment}
                                onDenyClick={(): void => {
                                    onButtonClick(Status.DENIED);
                                }}
                                onApproveClick={(): void => {
                                    onButtonClick(Status.APPROVED);
                                }}
                                newRuleFile={store.feeRuleDetail.newRuleFile}
                                currentRuleFile={store.feeRuleDetail.currentRuleFile}
                                newRuleFileName={RulesDownloadFileNameEnum.NewFeeRulesFile}
                                currentRuleFileName={RulesDownloadFileNameEnum.CurrentFeeRulesFile}
                                onDownloadClick={(documentId, fileName): Promise<void> =>
                                    onDownloadButtonClick(documentId, fileName)
                                }
                                showApproveDenyActionButton={true}
                            />
                            {showViewFeeRulesChanges && store.requestId && (
                                <ViewFeeRulesChangesComponent
                                    store={store.feeDeterminationRulesDiffStore}
                                    requestId={store.requestId}
                                />
                            )}
                        </Stack>
                    ) : (
                        <EmptyStateComponent text={t('common.noActiveRequests')} image={thumbUp} />
                    )}
                </>
            )}
        </Stack>
    );
});
