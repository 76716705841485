import {
    Amount,
    BOName,
    BOUserComment,
    Currency,
    RequestDetails,
    UnclaimedFundsRequestDetails,
    UserName,
    Comment,
    GetCheckerUnclaimedFundsRequestInfoSummaryRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCheckerUnclaimedFundsRequestInfoSummaryRPCImpl extends GetCheckerUnclaimedFundsRequestInfoSummaryRPC {
    execute(
        _request: GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Response,
            GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Errors.Errors
        >
    > {
        const response = new GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Response(
            new UnclaimedFundsRequestDetails(
                25,
                new Amount(50000000, new Currency('MWK')),
                new RequestDetails.Pending(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID('75975dc3-d239-4a87-bd0f-d0f428af1e24'),
                        new UserName(new BOName('Jane'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('Money needs to be sent to CBS account.')
                    )
                )
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Response,
                GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Errors.Errors
            >
        >;
    }
}
