import {
    Gender,
    GetKYCDocumentHistoryDetailsRPC,
    KYCData,
    UserPersonalDetails
} from '@resolut-tech/bcn-rpcs';
import {
    KYCDocument,
    KYCDocumentEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/kYCDocument';
import { Instance, types } from 'mobx-state-tree';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getDateString } from '../../common/utils/DateUtils';

export const userPersonalDetailsModel = types.model({
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherName: types.maybeNull(types.string),
    gender: types.enumeration<Gender.Gender>('Gender', Object.values(Gender.Gender)),
    dateOfBirth: types.string,
    nationalId: types.string
});

const getUserPersonalDetailsModel = (
    personalDetail: UserPersonalDetails
): Instance<typeof userPersonalDetailsModel> => {
    return userPersonalDetailsModel.create({
        firstName: personalDetail.firstName,
        lastName: personalDetail.lastName ?? null,
        otherName: personalDetail.otherName ?? null,
        gender: personalDetail.gender,
        dateOfBirth: getDateString(new Date(personalDetail.dateOfBirth.date)),
        nationalId: personalDetail.nationalId
    });
};

export const kycDataModel = types.model({
    userPersonalDetails: userPersonalDetailsModel,
    emailId: types.maybeNull(types.string),
    placeOfBirth: types.string,
    nationalIdIssueDate: types.string,
    nationalIdExpiryDate: types.string,
    residentAddress: types.string,
    postalAddress: types.string
});

const getKycDataModel = (kycData: KYCData): Instance<typeof kycDataModel> => {
    return kycDataModel.create({
        userPersonalDetails: getUserPersonalDetailsModel(kycData.userPersonalDetails),
        emailId: kycData.emailId?.emailId ?? null,
        placeOfBirth: kycData.placeOfBirth,
        nationalIdIssueDate: getDateString(new Date(kycData.nationalIdIssueDate.date)),
        nationalIdExpiryDate: getDateString(new Date(kycData.nationalIdExpiryDate.date)),
        residentAddress: kycData.residentAddress,
        postalAddress: kycData.postalAddress
    });
};

export const kycDocumentModel = types.model({
    documentUrl: types.string,
    documentType: types.enumeration<KYCDocumentEnums.DocumentType.DocumentType>(
        'documentType',
        Object.values(KYCDocumentEnums.DocumentType.DocumentType)
    )
});

const getKycDocumentModel = (kycDocument: KYCDocument): Instance<typeof kycDocumentModel> => {
    return kycDocumentModel.create({
        documentUrl: kycDocument.documentUrl.toString(),
        documentType: kycDocument.documentType
    });
};

export const kycDocumentDetailsModel = types.model({
    kycData: kycDataModel,
    profilePhoto: types.maybeNull(types.string),
    nationalIdFront: kycDocumentModel,
    nationalIdBack: kycDocumentModel,
    passportPhotoFront: types.maybeNull(kycDocumentModel),
    passportPhotoBack: types.maybeNull(kycDocumentModel),
    signaturePhoto: kycDocumentModel,
    proofOfResidence: kycDocumentModel
});

export const getKycDocumentDetailsModel = (
    details: GetKYCDocumentHistoryDetailsRPC.Response
): Instance<typeof kycDocumentDetailsModel> => {
    return kycDocumentDetailsModel.create({
        kycData: getKycDataModel(details.kycData),
        profilePhoto: details.profilePhoto && getImageUrl(details.profilePhoto),
        nationalIdFront: getKycDocumentModel(details.nationalIdFront),
        nationalIdBack: getKycDocumentModel(details.nationalIdBack),
        passportPhotoFront: details.passportPhotoFront
            ? getKycDocumentModel(details.passportPhotoFront)
            : null,
        passportPhotoBack: details.passportPhotoBack
            ? getKycDocumentModel(details.passportPhotoBack)
            : null,
        signaturePhoto: getKycDocumentModel(details.signaturePhoto),
        proofOfResidence: getKycDocumentModel(details.proofOfResidence)
    });
};
