import { Instance } from 'mobx-state-tree';
import { FeeDeterminationRuleModel } from '../models/FeeDeterminationRuleModel';

export const feeDeterminationRuleModelToString = (
    rule: Instance<typeof FeeDeterminationRuleModel>
): string => {
    return [
        rule.transactionType,
        rule.amountRangeLow?.toString() ?? '',
        rule.amountRangeHigh?.toString() ?? '',
        rule.cbsId,
        rule.feeType,
        rule.percentage?.toString() ?? '',
        rule.upperBound?.toString() ?? '',
        rule.lowerBound?.toString() ?? '',
        rule.flatFee?.toString() ?? '',
        rule.bankFeePercentage?.toString() ?? '',
        rule.counterPartyFeePercentage?.toString() ?? '',
        rule.agentCommissionPercentage?.toString() ?? ''
    ].join(',');
};

export const stringToFeeDeterminationRuleModel = (
    rule: string
): Instance<typeof FeeDeterminationRuleModel> => {
    const [
        transactionType,
        amountRangeLow,
        amountRangeHigh,
        cbsId,
        feeType,
        percentage,
        upperBound,
        lowerBound,
        flatFee,
        bankFeePercentage,
        counterPartyFeePercentage,
        agentCommissionPercentage
    ] = rule.split(',');
    return FeeDeterminationRuleModel.create({
        transactionType,
        feeType,
        amountRangeLow: amountRangeLow ? Number(amountRangeLow) : null,
        amountRangeHigh: amountRangeHigh ? Number(amountRangeHigh) : null,
        bankFeePercentage: bankFeePercentage ? Number(bankFeePercentage) : null,
        counterPartyFeePercentage: counterPartyFeePercentage
            ? Number(counterPartyFeePercentage)
            : null,
        agentCommissionPercentage: agentCommissionPercentage
            ? Number(agentCommissionPercentage)
            : null,
        flatFee: flatFee ? Number(flatFee) : null,
        percentage: percentage ? Number(percentage) : null,
        lowerBound: lowerBound ? Number(lowerBound) : null,
        upperBound: upperBound ? Number(upperBound) : null,
        cbsId
    });
};
