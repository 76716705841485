import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    CheckTransactionValidationRuleRequestRPC,
    Comment,
    GetTransactionValidationRuleRequestDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import {
    useCheckTransactionValidationRuleRPCClient,
    useGetTransactionValidationRuleRequestDetailsRPCClient
} from '../rpcs/RPC';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    FileDetailsModel,
    getCurrentRuleFileModel,
    getNewRuleFileModel,
    RuleDetailModel
} from '../../common/models/RulesModel';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { Status } from '../../common/enums/StatusEnum';
import {
    CheckerResponse,
    CheckerResponseEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { getBOUserCommentModel } from '../../common/models/BOUserCommentModel';
import {
    createDownloadTransactionRulesStore,
    DownloadTransactionRulesStore
} from './DownloadTransactionRulesStore';
import {
    createTransactionValidationRulesDiffStore,
    TransactionValidationRulesDiffStore
} from './TransactionValidationRulesDiffStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum EvaluateTransactionRulesErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    InvalidComment = 'INVALID_COMMENT',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    RequestDiscarded = 'REQUEST_DISCARDED',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN'
}

export const EvaluateTransactionRulesStore = types
    .model('EvaluateTransactionRulesStore', {
        requestId: types.maybeNull(types.string),
        transactionValidationRuleDetail: types.maybeNull(RuleDetailModel),
        newRuleFile: types.maybeNull(FileDetailsModel),
        oldRuleFile: types.maybeNull(FileDetailsModel),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<EvaluateTransactionRulesErrors>(
                    'EvaluateTransactionRulesErrors',
                    Object.values(EvaluateTransactionRulesErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        ),
        downloadFileStore: DownloadTransactionRulesStore,
        transactionValidationRulesDiffStore: TransactionValidationRulesDiffStore
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetStore(): void {
            store.requestId = null;
            store.comment = null;
            store.newRuleFile = null;
            store.transactionValidationRuleDetail = null;
            store.oldRuleFile = null;
            store.status = null;
            store.error = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = '';
        },
        removeError(): void {
            store.error = null;
        },
        fetchPendingTransactionRuleDetails: flow(function* () {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetTransactionValidationRuleRequestDetailsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetTransactionValidationRuleRequestDetailsRPC.Response,
                never
            > = yield useGetTransactionValidationRuleRequestDetailsRPCClient(apiClient).execute(
                request
            );
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const transactionRule = response.transactionValidationRuleDetail;
                if (transactionRule) {
                    store.requestId = transactionRule.requestId.uuid;
                    const boUserComment = getBOUserCommentModel(transactionRule.boUserComment);
                    const newRuleFile = getNewRuleFileModel(transactionRule.newRuleFile);
                    const currentRuleFile = getCurrentRuleFileModel(
                        transactionRule.currentRuleFile
                    );
                    const transactionRuleDetail = { boUserComment, newRuleFile, currentRuleFile };
                    store.transactionValidationRuleDetail = transactionRuleDetail;
                }
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                store.transactionValidationRuleDetail = null;
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetTransactionValidationRuleRequestDetailsRPC`
                );
                store.error = NetworkingError.InternalError;
            } else {
                store.transactionValidationRuleDetail = null;
                loggerStore.error(
                    `Unknown error occurred in GetTransactionValidationRuleRequestDetailsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        evaluatePendingRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                if (store.comment && requestId && store.status) {
                    const request = new CheckTransactionValidationRuleRequestRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckTransactionValidationRuleRequestRPC.Response,
                        CheckTransactionValidationRuleRequestRPC.Errors.Errors
                    > = yield useCheckTransactionValidationRuleRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case EvaluateTransactionRulesErrors.InvalidRequestId:
                                store.error = EvaluateTransactionRulesErrors.InvalidRequestId;
                                break;
                            case EvaluateTransactionRulesErrors.RequestAlreadyChecked:
                                store.error = EvaluateTransactionRulesErrors.RequestAlreadyChecked;
                                break;
                            case EvaluateTransactionRulesErrors.RequestDiscarded:
                                store.error = EvaluateTransactionRulesErrors.RequestDiscarded;
                                break;
                            case EvaluateTransactionRulesErrors.CannotCheckSelfRequest:
                                store.error = EvaluateTransactionRulesErrors.CannotCheckSelfRequest;
                                break;
                            case EvaluateTransactionRulesErrors.RequestAlreadyWithdrawn:
                                store.error =
                                    EvaluateTransactionRulesErrors.RequestAlreadyWithdrawn;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckTransactionValidationRuleRequestRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckTransactionValidationRuleRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `store.comment, store.requestId, and store.status cannot be null as they are set before calling evaluatePendingRequest`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = EvaluateTransactionRulesErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = EvaluateTransactionRulesErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckTransactionValidationRuleRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckTransactionValidationRuleRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createEvaluateTransactionRulesStore = (): Instance<
    typeof EvaluateTransactionRulesStore
> => {
    return EvaluateTransactionRulesStore.create({
        downloadFileStore: createDownloadTransactionRulesStore(),
        transactionValidationRulesDiffStore: createTransactionValidationRulesDiffStore()
    });
};
