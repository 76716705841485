import { Box, Typography } from '@mui/material';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { useCountdownTimer } from '../../bcn-users/utils/useCountdownTimer';
import { ProfileManagementComponent } from '../../common/components/ProfileManagementComponent';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { useUpdateAgentStateStore } from '../store/hooks';
import { UpdateAgentStateStoreErrors } from '../store/UpdateAgentStateStore';
import { AgentArchiveWarningDialog } from './AgentArchiveWarningDialog';
import { Dialog } from '../../../common/components/Dialog';

interface AgentProfileManagementSectionProps {
    agentId: string;
    agentStatus: UserStatus.UserStatus;
    setScreenLoading: (load: boolean) => void;
    getUpdatedData: () => void;
}

export const AgentProfileManagementSection = observer(
    ({
        agentId,
        agentStatus,
        setScreenLoading,
        // Fetch the updated data for the agent corresponding to the agentId.
        getUpdatedData
    }: AgentProfileManagementSectionProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
        const store = useUpdateAgentStateStore();
        const userStore = useUserStore();
        const [countdown, setCountdown] = useCountdownTimer(5);
        const [isArchiveWarningDialogOpen, setIsArchiveWarningDialogOpen] = useState(false);
        const navigate = useNavigate();

        const getIsSubmitButtonDisabled = (): boolean =>
            !Boolean(store.comment && store.comment.trim().length > 0);

        const getErrorText = (): string => {
            switch (store.error) {
                case UpdateAgentStateStoreErrors.InvalidComment:
                    return t('common.invalidComment');
                case UpdateAgentStateStoreErrors.InvalidAgentId:
                    // This is a developer error since the agentId is received for the server.
                    return t('common.somethingWentWrong');
                case UpdateAgentStateStoreErrors.AgentAlreadyActivated:
                    return t('agencyBanking.agentAlreadyActive');
                case UpdateAgentStateStoreErrors.AgentAlreadyDeactivated:
                    return t('agencyBanking.agentAlreadyDeactivated');
                case UpdateAgentStateStoreErrors.AgentAlreadyArchived:
                    return t('agencyBanking.agentAlreadyArchived');
                case UpdateAgentStateStoreErrors.RequestAlreadyRaised:
                    return t('common.requestAlreadyRaised');
                case UpdateAgentStateStoreErrors.CannotArchiveActiveAgent:
                    return t('common.somethingWentWrong');
                case UpdateAgentStateStoreErrors.BcnUserAccountDisabled:
                    return t('common.bcnUserAccountDisabled', {
                        id: store.bcnUserId
                    });
                case NetworkingError.InternalError:
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrong');
            }
        };

        const onSubmit = async (): Promise<void> => {
            setIsDialogOpen(false);
            setScreenLoading(true);
            agentStatus === UserStatus.UserStatus.ACTIVE
                ? await store.raiseRequestToDeactivateAgent(agentId)
                : await store.raiseRequestToActivateAgent(agentId);
            if (store.error) {
                setIsDialogOpen(true);
            } else {
                getUpdatedData();
            }
            setScreenLoading(false);
        };

        const onSubmitToArchiveUser = async (): Promise<void> => {
            setIsArchiveDialogOpen(false);
            setScreenLoading(true);
            await store.raiseRequestToArchiveAgent(agentId);
            if (store.balanceAmount) {
                setCountdown(5);
                setIsArchiveWarningDialogOpen(true);
            } else if (store.pendingTransactionsFilter) {
                setIsArchiveWarningDialogOpen(true);
            } else if (store.error) {
                setIsArchiveDialogOpen(true);
            } else {
                getUpdatedData();
            }
            setScreenLoading(false);
        };

        const onSubmitToArchiveUserWithBalance = async (): Promise<void> => {
            setIsArchiveWarningDialogOpen(false);
            setScreenLoading(true);
            await store.raiseRequestToArchiveAgentWithBalance(agentId);
            if (store.error) {
                setIsArchiveDialogOpen(true);
            } else {
                getUpdatedData();
            }
            setScreenLoading(false);
        };

        const getPrimaryButtonTitle = (): string | undefined => {
            if (store.error) {
                return undefined;
            } else {
                return t('common.submitRequest');
            }
        };

        const getSecondaryButtonTitle = (): string => {
            if (store.error) {
                return t('common.dismiss');
            } else {
                return t('common.cancel');
            }
        };

        const getErrorComponent = (): React.ReactElement => {
            return (
                <Box sx={{ textAlign: 'center' }}>
                    <AlertCircle
                        style={{ height: '32px', width: '32px', color: palette.error[300] }}
                    />
                    <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                        {getErrorText()}
                    </Typography>
                </Box>
            );
        };

        const getCommentField = (): React.ReactElement => {
            return (
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={store.comment ?? ''}
                    onTextChange={(inputValue: string): void => {
                        store.setComment(inputValue);
                    }}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            );
        };

        const getArchiveAgentDialog = (): React.ReactElement => {
            return (
                <Dialog
                    open={isArchiveDialogOpen}
                    title={t('agencyBanking.requestToArchiveAgent')}
                    primaryButtonText={getPrimaryButtonTitle()}
                    isPrimaryButtonDisabled={getIsSubmitButtonDisabled()}
                    onPrimaryButtonClick={(): Promise<void> => onSubmitToArchiveUser()}
                    secondaryButtonText={getSecondaryButtonTitle()}
                    onSecondaryButtonClick={(): void => {
                        setIsArchiveDialogOpen(false);
                        store.removeError();
                        store.resetComment();
                    }}
                    disableBackdropClick={true}>
                    {store.error ? getErrorComponent() : getCommentField()}
                </Dialog>
            );
        };

        const getReactivateDeactivateAgentDialog = (): React.ReactElement => {
            return (
                <Dialog
                    open={isDialogOpen}
                    title={
                        agentStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('agencyBanking.requestForAgentReactivation')
                            : t('agencyBanking.requestForAgentDeactivation')
                    }
                    primaryButtonText={getPrimaryButtonTitle()}
                    isPrimaryButtonDisabled={getIsSubmitButtonDisabled()}
                    onPrimaryButtonClick={(): Promise<void> => onSubmit()}
                    secondaryButtonText={getSecondaryButtonTitle()}
                    onSecondaryButtonClick={(): void => {
                        setIsDialogOpen(false);
                        if (store.error) {
                            store.removeError();
                        } else {
                            store.resetComment();
                        }
                    }}
                    disableBackdropClick={true}>
                    {store.error ? getErrorComponent() : getCommentField()}
                </Dialog>
            );
        };

        return (
            <>
                {getReactivateDeactivateAgentDialog()}
                {getArchiveAgentDialog()}
                <AgentArchiveWarningDialog
                    isDialogOpen={isArchiveWarningDialogOpen}
                    onDialogClose={(): void => {
                        setIsArchiveWarningDialogOpen(false);
                        store.removeError();
                        store.removeBalanceAmount();
                        store.removePendingTransactionsFilter();
                        store.resetComment();
                    }}
                    onSubmit={async (): Promise<void> => {
                        if (store.pendingTransactionsFilter) {
                            store.setTransactionsFilter();
                            setIsArchiveWarningDialogOpen(false);
                            // Navigate to `AgentTransactionList` page with prefilled filters.
                            // The user will not be allowed to navigate back from this page.
                            navigate('/agency-banking/transactions', { replace: true });
                        } else {
                            await onSubmitToArchiveUserWithBalance();
                        }
                    }}
                    countdown={countdown}
                />
                <ProfileManagementComponent
                    userId={agentId}
                    setIsDialogOpen={setIsDialogOpen}
                    setIsArchiveDialogOpen={setIsArchiveDialogOpen}
                    allowDeactivateOrReactivate={userStore.privileges.includes(
                        UserPrivileges.RequestActivateDeactivateAgent
                    )}
                    allowArchive={
                        userStore.privileges.includes(UserPrivileges.RequestArchiveAgent) &&
                        agentStatus === UserStatus.UserStatus.DEACTIVATED
                    }
                    deactivationOrActivationTitle={
                        agentStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('agencyBanking.requestForAgentReactivation')
                            : t('agencyBanking.requestForAgentDeactivation')
                    }
                    deactivationOrActivationSubtitle={
                        agentStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('agencyBanking.requestForAgentReactivationDescription')
                            : t('agencyBanking.requestForAgentDeactivationDescription')
                    }
                    archiveTitle={t('agencyBanking.requestToArchiveAgent')}
                    archiveSubtitle={t('agencyBanking.requestToArchiveAgentDescription')}
                />
            </>
        );
    }
);
