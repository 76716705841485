import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import {
    CountryCode,
    DateRange,
    GetReportDetailsRPC,
    GetReportFileRPC,
    ReportType
} from '@resolut-tech/bcn-rpcs';
import { useGetReportDetailRPCClient, useGetReportFileRPCClient } from '../rpcs/RPC';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { DateRangePickerInput } from '@surya-digital/leo-reactjs-ui';
import { downloadFile } from '../../common/utils/FileUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLeoDate } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ReportDataErrors {
    LargeDateRange = 'LARGE_DATE_RANGE',
    InvalidDateRange = 'INVALID_DATE_RANGE',
    CouldNotGenerateFile = 'COULD_NOT_GENERATE_FILE',
    NoRecordsFound = 'NO_RECORDS_FOUND'
}

const getStoreError = (
    error: GetReportDetailsRPC.Errors.Errors | GetReportFileRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): ReportDataErrors | NetworkingError => {
    switch (error.code) {
        case ReportDataErrors.LargeDateRange:
            return ReportDataErrors.LargeDateRange;
        case ReportDataErrors.InvalidDateRange:
            return ReportDataErrors.InvalidDateRange;
        case ReportDataErrors.CouldNotGenerateFile:
            return ReportDataErrors.CouldNotGenerateFile;
        case ReportDataErrors.NoRecordsFound:
            return ReportDataErrors.NoRecordsFound;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in ReportStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): LeoErrors | ReportDataErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidLeoDateError:
            return LeoErrors.InvalidLeoDateError;
        case LeoErrors.InvalidDateRangeError:
            return ReportDataErrors.InvalidDateRange;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in ReportStore`);
            return NetworkingError.InternalError;
    }
};

const ReportData = types.model({
    date: types.Date,
    value: types.number
});
export const ReportStore = types
    .model('ReportDetailStore', {
        countryListStore: CountryListStore,
        countryCode: types.maybeNull(types.string),
        reportType: types.string,
        reportData: types.maybeNull(types.array(ReportData)),
        reportFileUrl: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<ReportDataErrors>(
                    'ReportDataErrors',
                    Object.values(ReportDataErrors)
                ),
                types.enumeration<LeoErrors>('LeoErrors', Object.values(LeoErrors)),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetStore(): void {
            store.countryCode = null;
            store.reportData = null;
            store.reportFileUrl = null;
            store.error = null;
            store.reportType = '';
        },
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        fetchReportData: flow(function* (countryCode: string, dateRange?: DateRangePickerInput) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const _countryCode = new CountryCode(countryCode);
                const request = new GetReportDetailsRPC.Request(
                    _countryCode,
                    store.reportType as ReportType.ReportType,
                    dateRange && dateRange.startDate && dateRange.endDate
                        ? new DateRange(
                              getLeoDate(dateRange.startDate),
                              getLeoDate(dateRange.endDate)
                          )
                        : undefined
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetReportDetailsRPC.Response,
                    GetReportDetailsRPC.Errors.Errors
                > = yield useGetReportDetailRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.reportData = cast(
                        response.reportData?.map((data) => {
                            return ReportData.create({
                                date: new Date(data.date.date),
                                value: data.dataValue
                            });
                        })
                    );
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                    store.reportData = null;
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetReportDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in GetReportDetailsRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        fetchFileId: flow(function* (dateRange?: DateRangePickerInput) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            if (store.countryCode) {
                try {
                    const request = new GetReportFileRPC.Request(
                        new CountryCode(store.countryCode),
                        store.reportType as ReportType.ReportType,
                        dateRange?.startDate && dateRange.endDate
                            ? new DateRange(
                                  getLeoDate(dateRange.startDate),
                                  getLeoDate(dateRange.endDate)
                              )
                            : undefined
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        GetReportFileRPC.Response,
                        GetReportFileRPC.Errors.Errors
                    > = yield useGetReportFileRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        store.reportFileUrl = response.downloadUrl.toString();
                        downloadFile(store.reportFileUrl);
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in GetReportFileRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        store.error = getLeoError(error, loggerStore);
                    } else {
                        loggerStore.error(`Unknown error: ${error} occurred in GetReportFileRPC`);
                        store.error = NetworkingError.InternalError;
                    }
                }
            } else {
                loggerStore.debug('Country not found in ReportStore');
                store.error = NetworkingError.InternalError;
            }
        }),
        setReportType(type: string): void {
            store.reportType = type;
        },
        setCountryCode(countryCode: string): void {
            store.countryCode = countryCode;
        },
        resetDate(): void {
            store.reportData = null;
        },
        removeError(): void {
            store.error = null;
        }
    }))
    .views((store) => ({
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        }
    }));

export const createReportStore = (): Instance<typeof ReportStore> => {
    const countryList = createCountryListStore();
    return ReportStore.create({
        countryListStore: countryList,
        reportType: ''
    });
};
