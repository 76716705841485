import { CheckBOUserChangeRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckBOUserChangeRequestRPCImpl extends CheckBOUserChangeRequestRPC {
    execute(
        _request: CheckBOUserChangeRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckBOUserChangeRequestRPC.Response,
            CheckBOUserChangeRequestRPC.Errors.Errors
        >
    > {
        const response = new CheckBOUserChangeRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckBOUserChangeRequestRPC.Response,
                CheckBOUserChangeRequestRPC.Errors.Errors
            >
        >;
    }
}
