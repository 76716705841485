import {
    CheckBCNUserUpdateRequestRPC,
    CheckerResponse,
    CheckToArchiveBCNUserUpdateRequestWithBalanceRPC,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance, cast } from 'mobx-state-tree';
import {
    useCheckBCNUserUpdateRequestRPCClient,
    useCheckToArchiveBCNUserUpdateRequestWithBalanceRPCClient
} from '../rpcs/RPC';
import { Status } from '../../common/enums/StatusEnum';
import {
    getNonZeroAccountDetailsModel,
    NonZeroAccountDetailsModel
} from '../models/NonZeroAccountDetailsModel';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { LoggerStore } from '../../../../log/LoggerStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserCheckRequestStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    InvalidComment = 'INVALID_COMMENT',
    PendingTransactionsFound = 'PENDING_TRANSACTIONS_FOUND',
    UnableToArchiveUser = 'UNABLE_TO_ARCHIVE_USER',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    AgentAccountActive = 'AGENT_ACCOUNT_ACTIVE'
}

const getStoreError = (
    error:
        | CheckBCNUserUpdateRequestRPC.Errors.Errors
        | CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): BCNUserCheckRequestStoreErrors | NetworkingError => {
    switch (error.code) {
        case BCNUserCheckRequestStoreErrors.InvalidRequestId:
            return BCNUserCheckRequestStoreErrors.InvalidRequestId;
        case BCNUserCheckRequestStoreErrors.RequestAlreadyChecked:
            return BCNUserCheckRequestStoreErrors.RequestAlreadyChecked;
        case BCNUserCheckRequestStoreErrors.PendingTransactionsFound:
            return BCNUserCheckRequestStoreErrors.PendingTransactionsFound;
        case BCNUserCheckRequestStoreErrors.UnableToArchiveUser:
            return BCNUserCheckRequestStoreErrors.UnableToArchiveUser;
        case BCNUserCheckRequestStoreErrors.CannotCheckSelfRequest:
            return BCNUserCheckRequestStoreErrors.CannotCheckSelfRequest;
        case BCNUserCheckRequestStoreErrors.RequestAlreadyDiscarded:
            return BCNUserCheckRequestStoreErrors.RequestAlreadyDiscarded;
        case BCNUserCheckRequestStoreErrors.RequestAlreadyWithdrawn:
            return BCNUserCheckRequestStoreErrors.RequestAlreadyWithdrawn;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in AuthCodeEntryStore`);
            return NetworkingError.InternalError;
    }
};

export const BCNUserCheckRequestStore = types
    .model({
        requestId: types.maybeNull(types.string),
        nonZeroAccountDetails: types.array(NonZeroAccountDetailsModel),
        totalBalance: types.maybeNull(AmountModel),
        agentId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserCheckRequestStoreErrors>(
                    'BCNUserCheckRequestStoreErrors',
                    Object.values(BCNUserCheckRequestStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetComment(): void {
            store.comment = null;
        },
        resetNonZeroAccountDetails(): void {
            store.nonZeroAccountDetails = cast([]);
        },
        removeError(): void {
            store.error = null;
            store.agentId = null;
        },
        checkPendingRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            store.comment = comment;
            try {
                if (store.comment && store.requestId && store.status) {
                    const request = new CheckBCNUserUpdateRequestRPC.Request(
                        new LeoUUID(store.requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckBCNUserUpdateRequestRPC.Response,
                        CheckBCNUserUpdateRequestRPC.Errors.Errors
                    > = yield useCheckBCNUserUpdateRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (error instanceof CheckBCNUserUpdateRequestRPC.Errors.NonZeroAccount) {
                            const accountDetails = error.accounts.map((account) =>
                                getNonZeroAccountDetailsModel(account)
                            );
                            store.nonZeroAccountDetails = cast(accountDetails);
                            store.totalBalance = getAmountModel(error.totalBalance);
                        } else if (
                            error instanceof CheckBCNUserUpdateRequestRPC.Errors.AgentAccountActive
                        ) {
                            store.agentId = error.agentId.uuid;
                            store.error = BCNUserCheckRequestStoreErrors.AgentAccountActive;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckBCNUserUpdateRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `Cannot find status, comment or requestId in CheckBCNUserUpdateRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BCNUserCheckRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckBCNUserUpdateRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckBCNUserUpdateRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        checkToArchivePendingRequestWithBalance: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            try {
                if (store.comment && store.requestId && store.status) {
                    const request = new CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Request(
                        new LeoUUID(store.requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Response,
                        CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Errors.Errors
                    > = yield useCheckToArchiveBCNUserUpdateRequestWithBalanceRPCClient(
                        apiClient
                    ).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckToArchiveBCNUserUpdateRequestWithBalanceRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `Cannot find status, comment or requestId in CheckToArchiveBCNUserUpdateRequestWithBalanceRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BCNUserCheckRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckToArchiveBCNUserUpdateRequestWithBalanceRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckToArchiveBCNUserUpdateRequestWithBalanceRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createBCNUserCheckRequestStore = (): Instance<typeof BCNUserCheckRequestStore> => {
    return BCNUserCheckRequestStore.create();
};
