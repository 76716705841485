import {
    CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse,
    CBSTransferForUnclaimedFundsRequestHistoryRPC,
    IntermediateRequestStatus,
    PGAmount,
    PGUserName
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const cBSTransferForUnclaimedFundsRequestHistoryPaginationResponse: CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse[] =
    [
        new CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse(
            new LeoTimestamp(),
            IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
            new PGAmount(50000000, 'MWK'),
            new PGUserName('Wade', 'Warren'),
            new PGUserName('Eric', 'Gbozo'),
            new LeoUUID()
        ),
        new CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse(
            new LeoTimestamp(),
            IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
            new PGAmount(50000000, 'MWK'),
            new PGUserName('Wade', 'Warren'),
            null,
            new LeoUUID()
        )
    ];
export class MockCBSTransferForUnclaimedFundsRequestHistoryImpl extends CBSTransferForUnclaimedFundsRequestHistoryRPC {
    execute(
        request: CBSTransferForUnclaimedFundsRequestHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            CBSTransferForUnclaimedFundsRequestHistoryRPC.Response,
            CBSTransferForUnclaimedFundsRequestHistoryRPC.Errors.Errors
        >
    > {
        let list = cBSTransferForUnclaimedFundsRequestHistoryPaginationResponse;
        if (request.requestStatus) {
            list = list.filter((obj) => obj.requestStatus === request.requestStatus);
        }
        const response = new CBSTransferForUnclaimedFundsRequestHistoryRPC.Response(
            list,
            list.length
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CBSTransferForUnclaimedFundsRequestHistoryRPC.Response,
                CBSTransferForUnclaimedFundsRequestHistoryRPC.Errors.Errors
            >
        >;
    }
}
