import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface WithdrawUnclaimedFundsActionSectionProps {
    onWithdrawClick: () => void;
    onViewDetailsButtonClick?: () => void;
}

export const WithdrawUnclaimedFundsActionSection = ({
    onWithdrawClick,
    onViewDetailsButtonClick
}: WithdrawUnclaimedFundsActionSectionProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 16px 16px 16px',
                borderTop: `1px solid ${palette.outline[200]}`,
                justifyContent: onViewDetailsButtonClick ? 'space-between' : 'flex-end'
            }}>
            {onViewDetailsButtonClick && (
                <Button
                    name="secondary-button"
                    variant="outlined-color"
                    size="small"
                    title={t('common.viewDetails')}
                    onClick={onViewDetailsButtonClick}
                    style={{
                        width: '100px'
                    }}
                />
            )}
            <Button
                name="secondary-button"
                variant="outlined-neutral"
                size="small"
                title={t('common.withdrawRequest')}
                onClick={onWithdrawClick}
            />
        </Box>
    );
};
