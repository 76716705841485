import { LeoEmailId, LeoPhoneNumber, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BitmapImageType,
    BOGender,
    BOName,
    BORole,
    GetProfileDetailsRPC,
    LocalizedText,
    MultiResolutionBitmapImage,
    PasswordPolicy,
    RemoteBitmapImage,
    UserName
} from '@resolut-tech/bcn-rpcs';

export class MockGetProfileDetailsRPC extends GetProfileDetailsRPC {
    execute(
        _request: GetProfileDetailsRPC.Request
    ): Promise<LeoRPCResult<GetProfileDetailsRPC.Response, GetProfileDetailsRPC.Errors.Errors>> {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );

        const response = new GetProfileDetailsRPC.Response(
            new UserName(new BOName('Esther'), new BOName('Howard')),
            new BOName('Esther'),
            profilePicture,
            new LeoPhoneNumber('+265995118354'),
            new LeoEmailId('esther.howard@email.com'),
            BOGender.BOGender.MALE,
            [
                new BORole(
                    'Generic Funds Transfer Maker',
                    new LocalizedText('Maker'),
                    new LocalizedText('Generic Funds Transfer'),
                    new LocalizedText('View Requests, View Details, Raise Request')
                ),
                new BORole(
                    'Fee Determination Rules Checker',
                    new LocalizedText('Checker'),
                    new LocalizedText('Fee Determination Rules'),
                    new LocalizedText('View Rules, View History, Download Rules, Evaluate Request')
                ),
                new BORole(
                    'Transactions Refund Maker',
                    new LocalizedText('Refund Maker'),
                    new LocalizedText('Transactions'),
                    new LocalizedText('View List, View Details, View History, Raise Request')
                )
            ],
            new PasswordPolicy(new LocalizedText('Your Password Must:'), [
                new LocalizedText('Must be atleast 8 characters long.'),
                new LocalizedText(
                    'Contains atleast 1 upper case character(s), 1 lower case character(s), 1 special character(s) and 1 digit(s).'
                ),
                new LocalizedText('Doesn’t contain common dictionary words.'),
                new LocalizedText('Not start or end with white spaces.')
            ])
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetProfileDetailsRPC.Response, GetProfileDetailsRPC.Errors.Errors>
        >;
    }
}
