import {
    CheckGenericFundsTransferRequestRPC,
    CheckGenericFundsTransferRequestRPCClientImpl,
    GetCheckerGenericFundsTransferSummaryRPC,
    GetCheckerGenericFundsTransferSummaryRPCClientImpl,
    GetGenericFundsTransferDetailsRPC,
    GetGenericFundsTransferDetailsRPCClientImpl,
    GetGenericFundsTransferRequestListRPC,
    GetGenericFundsTransferRequestListRPCClientImpl,
    GetSystemAccountsRPC,
    GetSystemAccountsRPCClientImpl,
    RequestGenericFundsTransferRPC,
    RequestGenericFundsTransferRPCClientImpl,
    SubmitGenericFundsTransferRequestRPC,
    SubmitGenericFundsTransferRequestRPCClientImpl,
    ValidateAccountDetailsRPC,
    ValidateAccountDetailsRPCClientImpl,
    WithdrawGenericFundsTransferRequestRPC,
    WithdrawGenericFundsTransferRequestRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';
import { MockCheckGenericFundsTransferRequestRPCImpl } from './MockCheckGenericFundsTransferRequestRPCImpl';
import { MockGetCheckerGenericFundsTransferSummaryRPCImpl } from './MockGetCheckerGenericFundsTransferSummaryRPCImpl';
import { MockGetSystemAccountsRPCImpl } from './MockGetSystemAccountsRPCImpl';
import { MockGetGenericFundsTransferRequestListRPCImpl } from './MockGetTransferRequestResultsRPCImpl';
import { MockRequestGenericFundsTransferRPCImpl } from './MockRequestGenericFundsTransferRPCImpl';
import { MockRequestGetGenericFundsTransferDetailsRPC } from './MockRequestGetGenericFundsTransferDetailsRPC';
import { MockSubmitGenericFundsTransferRequestRPCImpl } from './MockSubmitGenericFundsTransferRequestRPCImpl';
import { MockValidateAccountDetailsRPCImpl } from './MockValidateAccountDetailsRPCImpl';
import { MockWithdrawGenericFundsTransferRequestRPCImpl } from './MockWithdrawGenericFundsTransferRequestRPCImpl';

const MOCK = false;

export const useGetSystemAccountsClient = (apiClient: APIClient): GetSystemAccountsRPC => {
    if (MOCK) {
        return new MockGetSystemAccountsRPCImpl();
    } else {
        return new GetSystemAccountsRPCClientImpl(apiClient);
    }
};

export const useValidateAccountDetailsRPCClient = (
    apiClient: APIClient
): ValidateAccountDetailsRPC => {
    if (MOCK) {
        return new MockValidateAccountDetailsRPCImpl();
    } else {
        return new ValidateAccountDetailsRPCClientImpl(apiClient);
    }
};

export const useGetGenericFundsTransferRequestListRPCClient = (
    apiClient: APIClient
): GetGenericFundsTransferRequestListRPC => {
    if (MOCK) {
        return new MockGetGenericFundsTransferRequestListRPCImpl();
    } else {
        return new GetGenericFundsTransferRequestListRPCClientImpl(apiClient);
    }
};

export const useRequestGenericFundsTransferRPCClient = (
    apiClient: APIClient
): RequestGenericFundsTransferRPC => {
    if (MOCK) {
        return new MockRequestGenericFundsTransferRPCImpl();
    } else {
        return new RequestGenericFundsTransferRPCClientImpl(apiClient);
    }
};

export const useGetGenericFundsTransferDetailsRPClient = (
    apiClient: APIClient
): GetGenericFundsTransferDetailsRPC => {
    if (MOCK) {
        return new MockRequestGetGenericFundsTransferDetailsRPC();
    } else {
        return new GetGenericFundsTransferDetailsRPCClientImpl(apiClient);
    }
};

export const useSubmitGenericFundsTransferRequestRPCClient = (
    apiClient: APIClient
): SubmitGenericFundsTransferRequestRPC => {
    if (MOCK) {
        return new MockSubmitGenericFundsTransferRequestRPCImpl();
    } else {
        return new SubmitGenericFundsTransferRequestRPCClientImpl(apiClient);
    }
};

export const useGetCheckerGenericFundsTransferSummaryRPCClient = (
    apiClient: APIClient
): GetCheckerGenericFundsTransferSummaryRPC => {
    if (MOCK) {
        return new MockGetCheckerGenericFundsTransferSummaryRPCImpl();
    } else {
        return new GetCheckerGenericFundsTransferSummaryRPCClientImpl(apiClient);
    }
};

export const useCheckGenericFundsTransferRequestRPCClient = (
    apiClient: APIClient
): CheckGenericFundsTransferRequestRPC => {
    if (MOCK) {
        return new MockCheckGenericFundsTransferRequestRPCImpl();
    } else {
        return new CheckGenericFundsTransferRequestRPCClientImpl(apiClient);
    }
};

export const useWithdrawGenericFundsTransferRequestRPCClient = (
    apiClient: APIClient
): WithdrawGenericFundsTransferRequestRPC => {
    if (MOCK) {
        return new MockWithdrawGenericFundsTransferRequestRPCImpl();
    } else {
        return new WithdrawGenericFundsTransferRequestRPCClientImpl(apiClient);
    }
};
