import { Stack, SxProps } from '@mui/material';
import { Breadcrumb } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';

interface BreadcrumbProps {
    currentLabel: string;
    sx?: SxProps;
}

export const BreadcrumbComponent = observer(
    ({ currentLabel, sx }: BreadcrumbProps): React.ReactElement => {
        const breadcrumbStore = useBreadcrumbStore();
        const navigate = useNavigate();
        if (breadcrumbStore.links.length) {
            return (
                <Stack
                    sx={{
                        padding: '32px 32px 0px 32px',
                        boxSizing: 'border-box',
                        width: '100%',
                        ...sx
                    }}>
                    <Breadcrumb
                        links={breadcrumbStore.getBreadcrumbData((path): void => {
                            navigate(path);
                        })}
                        currentLabel={currentLabel}
                    />
                </Stack>
            );
        } else {
            return <></>;
        }
    }
);
