import { SxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsRowComponent } from './DetailsRowComponent';

interface CommentComponentProps {
    text: string;
    sx?: SxProps;
}

export const CommentComponent = ({ text, sx }: CommentComponentProps): React.ReactElement => {
    const { t } = useTranslation();

    return <DetailsRowComponent label={t('common.comment')} text={text} sx={{ ...sx }} />;
};
