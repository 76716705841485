import { DownloadUnclaimedFundsRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useDownloadUnclaimedFundsRequestRPCClient } from '../rpcs/RPC';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum DownloadUnclaimedFundsRequestStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestToDownloadIsInProgress = 'REQUEST_TO_DOWNLOAD_IS_IN_PROGRESS',
    InvalidRequestType = 'INVALID_REQUEST_TYPE'
}

export const DownloadUnclaimedFundsRequestStore = types
    .model({
        error: types.maybeNull(
            types.union(
                types.enumeration<DownloadUnclaimedFundsRequestStoreErrors>(
                    'DownloadUnclaimedFundsRequestStoreErrors',
                    Object.values(DownloadUnclaimedFundsRequestStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        downloadUnclaimedFundsRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new DownloadUnclaimedFundsRequestRPC.Request(
                    new LeoUUID(requestId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    DownloadUnclaimedFundsRequestRPC.Response,
                    DownloadUnclaimedFundsRequestRPC.Errors.Errors
                > = yield useDownloadUnclaimedFundsRequestRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestId:
                            store.error = DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestId;
                            break;
                        case DownloadUnclaimedFundsRequestStoreErrors.RequestToDownloadIsInProgress:
                            store.error =
                                DownloadUnclaimedFundsRequestStoreErrors.RequestToDownloadIsInProgress;
                            break;
                        case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestType:
                            store.error =
                                DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestType;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in DownloadUnclaimedFundsRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in DownloadUnclaimedFundsRequestRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in DownloadUnclaimedFundsRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in DownloadUnclaimedFundsRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createDownloadUnclaimedFundsRequestStore = (): Instance<
    typeof DownloadUnclaimedFundsRequestStore
> => {
    return DownloadUnclaimedFundsRequestStore.create();
};
