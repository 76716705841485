import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';

export enum TransactionSearchByDropdown {
    TransactionId = 'TRANSACTION_ID',
    AccountId = 'ACCOUNT_ID',
    MobileNumber = 'MOBILE_NUMBER',
    Name = 'NAME'
}

const getSearchByValue = (value: string): string | undefined => {
    const { t } = useTranslation();
    switch (value) {
        case TransactionSearchByDropdown.TransactionId:
            return t('common.transactionId');
        case TransactionSearchByDropdown.AccountId:
            return t('common.accountId');
        case TransactionSearchByDropdown.MobileNumber:
            return t('common.mobileNumber');
        case TransactionSearchByDropdown.Name:
            return t('common.name');
    }
};

export const getTransactionSearchByOptions = (): DropdownItem[] => {
    const values = Object.values(TransactionSearchByDropdown);
    const options = values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value);
        return { name: dropdownValue ?? '', value };
    });
    return options;
};
