import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { useNavigate } from 'react-router-dom';
import { useGenericFundsTransferRequestListStore } from '../store/hooks';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { RequestStatus } from '@resolut-tech/bcn-rpcs';
import { getAmountString, getTableStyleOverrides } from '../../common/utils/UIUtils';
import { RequestsListSearchBar } from '../../common/components/RequestsListSearchBar';
import { getRequestTransferSearchByOptions } from '../utils/UIUtils';
import { MoneyRequestFilterOptions } from '../../common/utils/TableFilterUtils';
import { Instance } from 'mobx-state-tree';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { useLoggerStore } from '../../../../log/hooks';
import { TransferRequestErrors } from '../store/RequestListStore';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const TransferRequest = (): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const palette = usePalette();
    const store = useGenericFundsTransferRequestListStore();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(t('common.transferRequests'), window.location.pathname);
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '182px'
            },
            {
                id: 'requestedBy',
                name: t('common.requestedBy'),
                width: '160px'
            },
            {
                id: 'evaluatedBy',
                name: t('common.evaluatedBy'),
                width: '182px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '182px'
            },
            {
                id: 'fromAccount',
                name: t('genericFundsTransfer.fromAccount'),
                width: '182px',
                ellipsisContent: true
            },
            {
                id: 'toAccount',
                name: t('genericFundsTransfer.toAccount'),
                width: '182px',
                ellipsisContent: true
            },
            {
                id: 'RequestStatus',
                name: t('common.requestStatus'),
                width: '182px'
            },
            { id: 'action', name: '', width: '56px' } // The column is left blank for the arrow at the end of the row in the table
        ];
    };

    const getStatus = (status: RequestStatus.RequestStatus): StatusType => {
        switch (status) {
            case RequestStatus.RequestStatus.APPROVED:
                return StatusType.APPROVED;
            case RequestStatus.RequestStatus.DENIED:
                return StatusType.DENIED;
            case RequestStatus.RequestStatus.PENDING:
                return StatusType.PENDING;
            case RequestStatus.RequestStatus.WITHDRAWN:
                return StatusType.WITHDRAWN;
            case RequestStatus.RequestStatus.DISCARDED:
                return StatusType.DISCARDED;
        }
    };

    const getData = async (
        option: TableOptions<Instance<typeof MoneyRequestFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchRequestList(
            option.filter?.searchText ?? '',
            option.page ? option.page - 1 : 0,
            store.itemsPerPage(),
            option.filter?.requestStatus,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case TransferRequestErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.transferRequest.map((request) => {
            return [
                {
                    data: request.requestedAt
                },
                {
                    data: request.requestedBy
                },
                {
                    data: request.evaluatedBy ?? '-'
                },
                {
                    data: getAmountString(request.amount)
                },
                {
                    data: request.fromAccountId ?? '-'
                },
                {
                    data: request.toAccountId ?? '-'
                },
                {
                    data: (
                        <StatusComponent status={getStatus(request.status)} variant="status-cell" />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('common.transferRequests')}
                subtitle={t('genericFundsTransfer.requestFundsTransferSubTitle')}
            />
            <Table
                name={'TransactionTable'}
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('common.searchingForRequests') }
                }}
                filterOption={{
                    initialFilterValue: store.filterOptions,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <RequestsListSearchBar
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={3}
                                searchByOptions={getRequestTransferSearchByOptions(t)}
                                searchFieldPlaceHolder={t('genericFundsTransfer.searchText')}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    if (store.transferRequest && store.transferRequest[index]) {
                        navigate(
                            `/generic-funds-transfer/transfer-requests/details?transferDetails=${store.transferRequest[index].requestId}`
                        );
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected Transfer Request in the list with length ${
                                store.transferRequest && store.transferRequest.length
                            } is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </Stack>
    );
};
