import { RequestStatus, SortOrder } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

// This model is used to store the filter options of Refund Request table and Transfer Request table.
export const MoneyRequestFilterOptions = types.model({
    requestStatus: types.string,
    searchText: types.string
});

export const getMoneyRequestInitialFilter = (): Instance<typeof MoneyRequestFilterOptions> => {
    return MoneyRequestFilterOptions.create({
        requestStatus: RequestStatus.RequestStatus.PENDING,
        searchText: ''
    });
};

// This model is used to store the filter options of Audit Logs table.
export const DateRangeFilterOptions = types.model({
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date)
});

export const getDateRangeInitialFilter = (): Instance<typeof DateRangeFilterOptions> => {
    return DateRangeFilterOptions.create();
};

export const getSortOrder = (sortOrder?: 'asc' | 'desc'): SortOrder.SortOrder => {
    if (sortOrder === 'asc') {
        return SortOrder.SortOrder.ASCENDING;
    }
    // The default sort order will be DESCENDING.
    return SortOrder.SortOrder.DESCENDING;
};
