import { GetAgentDetailsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import {
    AgentBasicDetailsModel,
    getAgentBasicDetailsModel
} from '../models/AgentBasicDetailsModel';
import {
    AgentRequestDetailsModel,
    getAgentRequestDetailsModel
} from '../models/AgentRequestDetailsModel';
import { useGetAgentDetailsRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AgentBasicDetailsStoreErrors {
    InvalidAgentId = 'INVALID_AGENT_ID'
}

export const AgentBasicDetailsStore = types
    .model({
        basicDetails: types.maybeNull(AgentBasicDetailsModel),
        requestDetails: types.maybeNull(AgentRequestDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AgentBasicDetailsStoreErrors>(
                    'AgentBasicDetailsStoreErrors',
                    Object.values(AgentBasicDetailsStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        fetchAgentDetails: flow(function* (agentId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const id = new LeoUUID(agentId);
                const request = new GetAgentDetailsRPC.Request(id);
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetAgentDetailsRPC.Response,
                    GetAgentDetailsRPC.Errors.Errors
                > = yield useGetAgentDetailsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.basicDetails = getAgentBasicDetailsModel(response.basicDetails);
                    store.requestDetails = response.requestDetails
                        ? getAgentRequestDetailsModel(response.requestDetails)
                        : null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case AgentBasicDetailsStoreErrors.InvalidAgentId:
                            store.error = AgentBasicDetailsStoreErrors.InvalidAgentId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetAgentDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetAgentDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = AgentBasicDetailsStoreErrors.InvalidAgentId;
                            break;
                        default:
                            loggerStore.error(
                                `Unknown error: ${error} occurred in GetAgentDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in GetAgentDetailsRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createAgentBasicDetailsStore = (): Instance<typeof AgentBasicDetailsStore> => {
    return AgentBasicDetailsStore.create();
};
