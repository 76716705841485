import { BOUserComment } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import { LeoTimestamp } from '@surya-digital/leo-ts-runtime';
import { getFormattedTimeDateWithComma } from '../utils/DateUtils';

export const WithdrawRequestModel = types.model({
    userId: types.string,
    name: types.string,
    profilePhoto: types.maybeNull(types.string),
    withdrawnAt: types.string,
    requestedAt: types.string,
    withdrawComment: types.string,
    requestComment: types.string
});

export const getWithdrawRequestModel = (
    boUserComment: BOUserComment,
    requestComment: string,
    requestedAt: LeoTimestamp
): Instance<typeof WithdrawRequestModel> => {
    return WithdrawRequestModel.create({
        userId: boUserComment.userId.uuid,
        name: getFullName(boUserComment.name),
        profilePhoto: boUserComment.profilePhoto && getImageUrl(boUserComment.profilePhoto),
        withdrawnAt: getFormattedTimeDateWithComma(new Date(boUserComment.requestedAt.timestamp)),
        requestedAt: getFormattedTimeDateWithComma(new Date(requestedAt.timestamp)),
        withdrawComment: boUserComment.comment.text,
        requestComment
    });
};
