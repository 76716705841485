import { Box, Stack, Typography } from '@mui/material';
import { Checkbox, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { AmountTextField } from '../../common/components/amount-text-field/AmountTextField';

interface AdditionalFeeFieldProps {
    isFeeCharged: boolean;
    onSelectionChanged: (value: boolean) => void;
    onFeeChanged: (value: string) => void;
    fee: string;
    currency: string;
    errorText: string | null;
}

export const AdditionalFeeField = ({
    isFeeCharged,
    onSelectionChanged,
    onFeeChanged,
    fee,
    currency,
    errorText
}: AdditionalFeeFieldProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Stack spacing="12px">
            <Stack direction="row">
                <Stack>
                    <Box sx={{ width: '250px' }}>
                        <Checkbox
                            checked={isFeeCharged}
                            label={t('transaction.chargeAdditionalFee')}
                            onChange={onSelectionChanged}
                            style={{ marginLeft: '10px' }}
                        />
                    </Box>
                    <Typography
                        sx={{
                            ...typography.body3,
                            color: palette.label[200],
                            paddingLeft: '32px'
                        }}>
                        {t('transaction.chargeAdditionalFeeDescription')}
                    </Typography>
                </Stack>
                <AmountTextField
                    name="additionalFee"
                    value={fee}
                    onAmountChange={(value): void => {
                        if (onFeeChanged) {
                            onFeeChanged(value);
                        }
                    }}
                    isDisabled={!isFeeCharged}
                    label={t('common.enterFee')}
                    inputAdornmentText={currency}
                    inputAdornmentPlacement="right"
                    error={errorText ? true : false}
                    style={{ width: '160px', marginLeft: '16px' }}
                    isDecimalAllowed={true}
                />
            </Stack>
            {errorText && (
                <Stack direction="row" spacing="9px">
                    <AlertCircle height="24px" width="24px" color={palette.error[300]} />
                    <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                        {errorText}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};
