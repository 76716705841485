import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { Stepper } from '../components/Stepper';
import { AccountDetails } from '../components/AccountDetails';
import { TransferDetailsComponent } from '../components/TransferDetailsComponent';
import { useRequestFundTransferStore } from '../store/hooks';
import { LoadingState } from '../../common/components/LoadingState';

const enum Steps {
    Step1 = 0,
    Step2 = 1
}

export const GenericFundsTransferRequest = (): React.ReactElement => {
    const { t } = useTranslation();
    const store = useRequestFundTransferStore();
    const [activeStep, setActiveStep] = useState<Steps>(Steps.Step1);
    const [loading, setLoading] = useState(false);

    const fetchCountrySystemDetails = async (): Promise<void> => {
        setLoading(true);
        await store.fetchSystemCountryList();
        setLoading(false);
    };

    useEffect(() => {
        store.resetStore();
        fetchCountrySystemDetails();
    }, []);

    return loading ? (
        <LoadingState />
    ) : (
        <Stack alignItems={'center'}>
            <PageHeader
                title={t('common.requestFundsTransfer')}
                subtitle={t('genericFundsTransfer.requestFundsTransferSubTitle')}
            />
            <Stack px={'24px'} pt={'32px'} spacing={'32px'} width={'720px'}>
                <Stepper
                    activeStep={activeStep}
                    steps={[
                        t('genericFundsTransfer.enterAccountDetails'),
                        t('genericFundsTransfer.enterAmount')
                    ]}
                />
                {activeStep === Steps.Step1 ? (
                    <AccountDetails onNext={(): void => setActiveStep(Steps.Step2)} />
                ) : (
                    <TransferDetailsComponent onBack={(): void => setActiveStep(Steps.Step1)} />
                )}
            </Stack>
        </Stack>
    );
};
