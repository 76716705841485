import { LeoErrors } from './../../home/common/errors/LeoErrors';
import {
    BackOfficeConfirmSignInOTPRPC,
    Otp,
    BOPlatform,
    BackOfficeResendSignInOTPRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoUUID, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { useConfirmSignInOTPRPCClient, useResendSignInOTPRPCClient } from '../rpcs/RPC';
import {
    getPasswordPolicyModel,
    PasswordPolicyModel
} from '../../common/models/PasswordPolicyModel';
import { SignInStore } from './SignInStore';
import { getAPIClient } from '../../networking/store/NetworkingStore';
import { getFormattedTimeDateWithComma } from '../../home/common/utils/DateUtils';
import { getLoggerStore } from '../../../log/hooks';
import { LoggerStore } from '../../../log/LoggerStore';
import { NetworkingError } from '../../error/store/ErrorStore';
import { getFormattedPhoneNumber } from '../../home/common/utils/UIUtils';

export enum OTPEntryErrors {
    IncorrectOTP = 'INCORRECT_OTP',
    OTPExpired = 'OTP_EXPIRED',
    CouldNotSendOTP = 'COULD_NOT_SEND_OTP',
    IncorrectOTPId = 'INCORRECT_OTP_ID',
    TooManyOTPAttempts = 'TOO_MANY_OTP_ATTEMPTS',
    WaitForResend = 'WAIT_FOR_RESEND'
}

const getStoreError = (
    error: BackOfficeConfirmSignInOTPRPC.Errors.Errors | BackOfficeResendSignInOTPRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): OTPEntryErrors | CommonAuthErrors | LeoErrors | NetworkingError => {
    switch (error.code) {
        case CommonAuthErrors.InactiveState:
            return CommonAuthErrors.InactiveState;
        case OTPEntryErrors.IncorrectOTPId:
            return NetworkingError.InternalError;
        case OTPEntryErrors.OTPExpired:
            return OTPEntryErrors.OTPExpired;
        case OTPEntryErrors.IncorrectOTP:
            return OTPEntryErrors.IncorrectOTP;
        case OTPEntryErrors.TooManyOTPAttempts:
            return OTPEntryErrors.TooManyOTPAttempts;
        case CommonAuthErrors.SignInTemporarilyBlocked:
            return CommonAuthErrors.SignInTemporarilyBlocked;
        case CommonAuthErrors.BoUserDisabled:
            return CommonAuthErrors.BoUserDisabled;
        case CommonAuthErrors.TooManyIncorrectAttempts:
            return CommonAuthErrors.TooManyIncorrectAttempts;
        case OTPEntryErrors.WaitForResend:
            return OTPEntryErrors.WaitForResend;
        case OTPEntryErrors.CouldNotSendOTP:
            return OTPEntryErrors.CouldNotSendOTP;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in OTPEntryStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): LeoErrors | CommonAuthErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidOTPError:
            return LeoErrors.InvalidOTPError;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in OTPEntryStore`);
            return NetworkingError.InternalError;
    }
};

export const OTPEntryStore = types
    .model({
        otp: types.string,
        otpId: types.string,
        nextResendAt: types.Date,
        numberOfResendsLeft: types.maybeNull(types.number),
        numberOfValidAttemptsLeft: types.maybeNull(types.number),
        otpValidatedToken: types.maybeNull(types.string),
        passwordPolicy: types.maybeNull(PasswordPolicyModel),
        waitForNextResendAt: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<OTPEntryErrors>('OTPEntryErrors', Object.values(OTPEntryErrors)),
                types.enumeration<CommonAuthErrors>(
                    'CommonAuthErrors',
                    Object.values(CommonAuthErrors)
                ),
                types.enumeration<LeoErrors>('LeoErrors', Object.values(LeoErrors)),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        completeSignInProcess(): void {
            const signInStore = getParent<typeof SignInStore>(store);
            signInStore.completeSignInProcess();
        },
        resetStore(): void {
            store.otp = '';
            store.otpId = '';
            store.nextResendAt = new Date();
            store.numberOfResendsLeft = null;
            store.otpValidatedToken = null;
            store.error = null;
        },
        setInitialOTPState(): void {
            const signInStore = getParent<typeof SignInStore>(store);
            const initialOtpId = signInStore.otpId();
            const initialNextResendAt = signInStore.otpNextResendAt();
            if (initialOtpId && initialNextResendAt) {
                store.otpId = initialOtpId.uuid;
                store.nextResendAt = initialNextResendAt;
            }
        },
        setOTP(value: string): void {
            const loggerStore = getLoggerStore(store);
            store.otp = value;
            try {
                new Otp(store.otp);
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in OTPEntryStore`);
                    store.error = NetworkingError.InternalError;
                }
            }
        },
        removeError(): void {
            store.error = null;
        },
        confirmSignInOTP: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const _otpId = new LeoUUID(store.otpId);
            if (_otpId) {
                try {
                    const _otp = new Otp(store.otp);
                    const request = new BackOfficeConfirmSignInOTPRPC.Request(
                        _otpId,
                        _otp,
                        BOPlatform.BOPlatform.WEB
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        BackOfficeConfirmSignInOTPRPC.Response,
                        BackOfficeConfirmSignInOTPRPC.Errors.Errors
                    > = yield useConfirmSignInOTPRPCClient(apiClient).execute(request);

                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        const responseResult = response.result;
                        if (
                            responseResult instanceof
                            BackOfficeConfirmSignInOTPRPC.ResponseEnums.Result.LoggedInWeb
                        ) {
                            // if logged-in-web is received as response, we would proceed to log-in the user
                        } else if (
                            responseResult instanceof
                            BackOfficeConfirmSignInOTPRPC.ResponseEnums.Result.LoggedInMobile
                        ) {
                            // this is a bad response from web, since we cannot recover from it, we will throw an Internal error
                            store.error = NetworkingError.InternalError;
                        } else if (
                            responseResult instanceof
                            BackOfficeConfirmSignInOTPRPC.ResponseEnums.Result.ForceResetPassword
                        ) {
                            store.otpValidatedToken = responseResult.otpValidatedToken.uuid;
                            const _passwordPolicy = responseResult.passwordPolicy;
                            if (_passwordPolicy) {
                                store.passwordPolicy = getPasswordPolicyModel(_passwordPolicy);
                            }
                        }
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                        if (error instanceof BackOfficeConfirmSignInOTPRPC.Errors.IncorrectOtp) {
                            store.numberOfValidAttemptsLeft = error.numberOfValidationAttemptsLeft;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in BackOfficeConfirmSignInOTPRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        store.error = getLeoError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error: ${error} occurred in BackOfficeConfirmSignInOTPRPC`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                }
            } else {
                // this is a developer error and should not occur, since we cannot recover from this error hence Internal Error is thrown here
                store.error = NetworkingError.InternalError;
            }
        }),
        resendSignInOTP: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const _otpId = new LeoUUID(store.otpId);
            if (_otpId) {
                const request = new BackOfficeResendSignInOTPRPC.Request(_otpId);
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    BackOfficeResendSignInOTPRPC.Response,
                    BackOfficeResendSignInOTPRPC.Errors.Errors
                > = yield useResendSignInOTPRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.otpId = _otpId.uuid;
                    store.nextResendAt = new Date(response.nextResendAt.timestamp);
                    store.numberOfResendsLeft = response.numberOfResendsLeft;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                    if (error instanceof BackOfficeResendSignInOTPRPC.Errors.WaitForResend) {
                        store.waitForNextResendAt = getFormattedTimeDateWithComma(
                            new Date(error.nextResendAt.timestamp)
                        );
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in BackOfficeResendSignInOTPRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } else {
                // this is a developer error and should not occur, since we cannot recover from this error hence Internal Error is thrown here
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views((store) => ({
        phoneNumberWithCountryCode(): string | undefined {
            const loggerStore = getLoggerStore(store);
            const signInStore = getParent<typeof SignInStore>(store);
            const phoneNumber = getFormattedPhoneNumber(signInStore.phoneNumber());
            if (signInStore.phoneCode()) {
                return `${signInStore.phoneCode()} ${phoneNumber}`;
            } else {
                loggerStore.debug(
                    'Could not find phoneCode for the selected country in OTPEntryStore'
                );
            }
        },
        otpMaxLength(): number {
            // Ideally this should not be hardcoded in the app. Since this is private to the generated RPC code, hence it is kept here
            return 6;
        }
    }));

export const createOTPEntryStore = (): Instance<typeof OTPEntryStore> => {
    return OTPEntryStore.create({
        otp: '',
        otpId: '',
        nextResendAt: new Date()
    });
};
