import {
    BitmapImageType,
    BOUserComment,
    Comment,
    GetTransactionValidationRuleRequestDetailsRPC,
    FileDetails,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RuleDetail,
    UserName,
    BOName
} from '@resolut-tech/bcn-rpcs';
import { FileDetailsEnums } from '@resolut-tech/bcn-rpcs/build/back-office/fileDetails';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetTransactionValidationRuleRequestDetailsRPCImpl extends GetTransactionValidationRuleRequestDetailsRPC {
    execute(
        _request: GetTransactionValidationRuleRequestDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetTransactionValidationRuleRequestDetailsRPC.Response,
            GetTransactionValidationRuleRequestDetailsRPC.Errors.Errors
        >
    > {
        const boUserComment = new BOUserComment(
            new LeoUUID(),
            new UserName(new BOName('Wade'), new BOName('Cooper')),
            new MultiResolutionBitmapImage(
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                )
            ),
            new LeoTimestamp('2022-09-30T02:03:59Z'),
            new Comment('The transaction rules have changed.')
        );

        const currentRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );

        const newRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );
        const response = new GetTransactionValidationRuleRequestDetailsRPC.Response(
            new RuleDetail(new LeoUUID(), boUserComment, newRuleFile, currentRuleFile)
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetTransactionValidationRuleRequestDetailsRPC.Response,
                GetTransactionValidationRuleRequestDetailsRPC.Errors.Errors
            >
        >;
    }
}
