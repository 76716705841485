import { Stack } from '@mui/material';
import { AccountStatus } from '@resolut-tech/bcn-rpcs';
import {
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    EllipsisTypography
} from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyField } from '../../common/components/CopyField';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { getAmountString } from '../../common/utils/UIUtils';
import { BCNUserAccountDetailsModel } from '../models/BCNUserAccountDetailModel';
import { getAccountColumnCount } from '../utils/UIUtils';

interface AccountTableProp {
    accountDetails: Instance<typeof BCNUserAccountDetailsModel>[];
}

export const AccountTable = ({ accountDetails }: AccountTableProp): React.ReactElement => {
    const { t } = useTranslation();
    const columnCount = getAccountColumnCount(accountDetails[0]);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'accountName',
                name: t('bcnUser.accountName'),
                width: '200px'
            },
            {
                id: 'accountId',
                name: t('common.walletId'),
                width: '240px'
            },
            {
                id: 'availableBalance',
                name: t('bcnUser.availableBalance'),
                width: '200px'
            },
            {
                id: 'accountStatus',
                name: t('bcnUser.accountStatus'),
                width: '132px'
            },
            {
                id: 'accountType',
                name: t('common.accountType'),
                width: '132px'
            }
        ].slice(0, columnCount);
    };

    const getUserStatus = (status: AccountStatus.AccountStatus): StatusType => {
        switch (status) {
            case AccountStatus.AccountStatus.ACTIVE:
                return StatusType.ACTIVE;
            case AccountStatus.AccountStatus.DEACTIVATED:
                return StatusType.DEACTIVATED;
        }
    };

    const getData = async (
        _option: TableOptions<undefined>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        setTotalItems(accountDetails.length);
        return accountDetails.map((accountDetail) => {
            return [
                { data: accountDetail.name },
                {
                    data: (
                        <Stack direction="row" alignItems="center" width="240px" spacing="8px">
                            <EllipsisTypography>{accountDetail.id}</EllipsisTypography>
                            <CopyField text={accountDetail.id} />
                        </Stack>
                    )
                },
                {
                    data: accountDetail.balance ? getAmountString(accountDetail.balance) : ''
                },
                {
                    data: accountDetail.status ? (
                        <StatusComponent
                            variant="status-cell"
                            status={getUserStatus(accountDetail.status)}
                        />
                    ) : (
                        ''
                    )
                },
                {
                    data: accountDetail.isDefaultAccount ? (
                        <StatusComponent variant="status-cell" status={StatusType.DEFAULT} />
                    ) : (
                        ''
                    )
                }
            ].slice(0, columnCount);
        });
    };

    return (
        <Table
            styleOverrides={{
                borderRadius: '4px',
                border: 'none'
            }}
            name="BCNUserAccountTable"
            headers={getHeaders()}
            onTableOptionsChange={getData}
            paginationOption={{
                itemsPerPage: accountDetails.length,
                getPageIndicatorText(startItem, endItem, totalItems): string {
                    return t('common.paginationIndicationText', {
                        startItem,
                        endItem,
                        totalItems
                    });
                }
            }}
            viewOverrides={{
                empty: { message: t('common.noResultsFound') }
            }}
        />
    );
};
