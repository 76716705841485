import { DeleteProfileImageRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { types, Instance, flow } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useDeleteProfileImageRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum DeleteProfileImageErrors {
    NoProfileImage = 'NO_PROFILE_IMAGE'
}

export const DeleteProfileImageStore = types
    .model({
        error: types.maybeNull(
            types.union(
                types.enumeration<DeleteProfileImageErrors>(
                    'DeleteProfileImageErrors',
                    Object.values(DeleteProfileImageErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        deleteProfileImage: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new DeleteProfileImageRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                DeleteProfileImageRPC.Response,
                DeleteProfileImageRPC.Errors.Errors
            > = yield useDeleteProfileImageRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                return;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case DeleteProfileImageErrors.NoProfileImage:
                        store.error = DeleteProfileImageErrors.NoProfileImage;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in DeleteProfileImageRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in DeleteProfileImageRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createDeleteProfileImageStore = (): Instance<typeof DeleteProfileImageStore> => {
    return DeleteProfileImageStore.create();
};
