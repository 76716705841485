import { WithdrawRefundRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class WithdrawRefundRequestRPCImpl extends WithdrawRefundRequestRPC {
    execute(
        _request: WithdrawRefundRequestRPC.Request
    ): Promise<
        LeoRPCResult<WithdrawRefundRequestRPC.Response, WithdrawRefundRequestRPC.Errors.Errors>
    > {
        const response = new WithdrawRefundRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<WithdrawRefundRequestRPC.Response, WithdrawRefundRequestRPC.Errors.Errors>
        >;
    }
}
