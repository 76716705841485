import { Dialog, Stack } from '@mui/material';
import { Button, Icon, usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface PreviewPhotoDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    photoUrl: string;
}

export const PreviewPhotoDialog = ({
    isDialogOpen,
    onClose,
    photoUrl
}: PreviewPhotoDialogProps): React.ReactElement => {
    const palette = usePalette();

    return (
        <Dialog
            open={isDialogOpen}
            sx={{
                '.MuiDialog-paper': {
                    width: '560px',
                    height: '560px'
                }
            }}>
            <Stack
                flexDirection="row"
                justifyContent="flex-end"
                paddingTop="4px"
                paddingRight="4px">
                <Button
                    name="cross"
                    size="small"
                    icon={<Icon type="cross" color={palette.primary[300]} width={16} height={16} />}
                    iconPosition="left"
                    variant="plain-color"
                    onClick={onClose}
                />
            </Stack>
            <Stack alignItems="center" padding="80px">
                <img src={photoUrl} style={{ width: '400px' }} />
            </Stack>
        </Dialog>
    );
};
