import { Box, Stack } from '@mui/material';
import { Card, LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { observer } from 'mobx-react';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { DownloadFileErrorDialog } from '../../common/components/DownloadFileError';
import { Status } from '../../common/enums/StatusEnum';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { ViewFeeRulesChangesComponent } from '../components/ViewFeeRulesChangesComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    useEvaluateFeeRulesStore,
    useFeeRulesRequestDetailsStore,
    useUpdateFeeRulesStore
} from '../store/hooks';
import { EvaluateFeeRulesErrors } from '../store/EvaluateFeeRulesStore';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { FileDownloadActionButtons } from '../../common/components/rules/FileDownloadActionButtons';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { PendingRuleRequestDetailsSectionComponent } from '../../common/components/rules/PendingRuleRequestDetailsSectionComponent';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { UpdateFeeRulesErrors } from '../store/UpdateFeeRulesStore';
import { ErrorState } from '../../common/components/ErrorState';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { FeeRulesRequestDetailsStoreErrors } from '../store/FeeRulesRequestDetailsStore';

export const FeeRulesHistoryRequestDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const feeRulesRequestDetailsStore = useFeeRulesRequestDetailsStore();
    const evaluateFeeRulesStore = useEvaluateFeeRulesStore();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get('requestId');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [isDownloadFileErrorDialogOpen, setIsDownloadFileErrorDialogOpen] = useState(false);
    const [fileType, setFileType] = useState<'old' | 'new'>();
    const userStore = useUserStore();
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState<boolean>(false);
    const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState<boolean>(false);
    const updateFeeRuleStore = useUpdateFeeRulesStore();
    const loggerStore = useLoggerStore();
    const navigate = useNavigate();

    const fetchFeeRuleRequestDetails = async (): Promise<void> => {
        if (requestId) {
            setIsLoadingData(true);
            await feeRulesRequestDetailsStore.fetchFeeRuleDetails(requestId);
            setIsLoadingData(false);
            if (feeRulesRequestDetailsStore.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            loggerStore.debug('requestId cannot be null while fetching feeRuleRequestDetails');
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            navigate('/fee-rules/history', { replace: true });
        }
    };

    useEffect(() => {
        fetchFeeRuleRequestDetails();
    }, []);

    const showViewFeeRulesChanges = Boolean(
        feeRulesRequestDetailsStore.oldRuleFile && feeRulesRequestDetailsStore.newRuleFile
    );

    const showWithdrawActionButton = (): boolean => {
        return (
            userStore.userId ===
            feeRulesRequestDetailsStore.requestDetails?.requesterComment?.userId
        );
    };

    const showApproveDenyActionButton = (): boolean => {
        return userStore.privileges.includes(UserPrivileges.CheckFeeRuleRequest);
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const onButtonClick = (status: Status): void => {
        evaluateFeeRulesStore.setStatus(status);
        setIsRequestCheckDialogOpen(true);
    };

    const getRequestDialogTitle = (): string => {
        if (evaluateFeeRulesStore.status === Status.APPROVED) {
            return t('common.approveRequest');
        } else {
            return t('common.denyRequest');
        }
    };

    const onClickApproveOrDenyUpdate = async (comment: string): Promise<void> => {
        evaluateFeeRulesStore.setComment(comment);
        setIsRequestCheckDialogOpen(false);
        setIsScreenBlockingLoading(true);
        const _requestId = feeRulesRequestDetailsStore.requestId;
        if (_requestId) {
            await evaluateFeeRulesStore.evaluatePendingRequest(_requestId);
        } else {
            loggerStore.debug(
                'Cannot find requestId in EvaluateFeeRulesStore while checking a request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        if (evaluateFeeRulesStore.error) {
            setErrorDialogTitle(
                evaluateFeeRulesStore.status === Status.APPROVED
                    ? t('common.approveRequest')
                    : t('common.denyRequest')
            );
            switch (evaluateFeeRulesStore.error) {
                case EvaluateFeeRulesErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case EvaluateFeeRulesErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case EvaluateFeeRulesErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case EvaluateFeeRulesErrors.RequestDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case EvaluateFeeRulesErrors.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    break;
                case EvaluateFeeRulesErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request is success, we will fetch the fee rule request details again.
            fetchFeeRuleRequestDetails();
        }
        evaluateFeeRulesStore.resetComment();
        setIsScreenBlockingLoading(false);
    };

    const RequestCheckDialogBox = (): React.ReactElement => {
        return (
            <RequestDialog
                isDialogOpen={isRequestCheckDialogOpen}
                title={getRequestDialogTitle()}
                buttonTitle={
                    evaluateFeeRulesStore.status === Status.APPROVED
                        ? t('common.approveRequest')
                        : t('common.denyRequest')
                }
                onSubmit={async (comment: string): Promise<void> => {
                    await onClickApproveOrDenyUpdate(comment);
                }}
                onClose={(): void => {
                    setIsRequestCheckDialogOpen(false);
                    evaluateFeeRulesStore.resetComment();
                }}
            />
        );
    };

    const onDownloadButtonClick = async (
        documentId: string,
        fileName: RulesDownloadFileNameEnum
    ): Promise<void> => {
        setIsFileDownloading(true);
        await updateFeeRuleStore.downloadFileStore.downloadFile(documentId, fileName);
        setIsFileDownloading(false);
        if (updateFeeRuleStore.downloadFileStore.error) {
            setIsDownloadFileErrorDialogOpen(true);
        }
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    setErrorDialogTitle(null);
                    updateFeeRuleStore.resetStore();
                    evaluateFeeRulesStore.resetStore();
                    feeRulesRequestDetailsStore.removeError();
                }}
            />
        );
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setIsScreenBlockingLoading(true);
        updateFeeRuleStore.setComment(comment);
        const _requestId = feeRulesRequestDetailsStore.requestId;
        if (_requestId) {
            await updateFeeRuleStore.withdrawFeeRuleRequest(_requestId);
        } else {
            loggerStore.debug('Cannot find requestId in UpdateFeeRuleStore');
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        if (updateFeeRuleStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (updateFeeRuleStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case UpdateFeeRulesErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been withdrawn, we will fetch the fee rule request details again.
            fetchFeeRuleRequestDetails();
        }
        setIsScreenBlockingLoading(false);
        updateFeeRuleStore.resetComment();
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    return (
        <Stack>
            <LoadingIndicator isLoading={isScreenBlockingLoading} color="white" />
            <DownloadFileErrorDialog
                isErrorDialogOpen={isDownloadFileErrorDialogOpen}
                onClose={(): void => setIsDownloadFileErrorDialogOpen(false)}
                storeError={feeRulesRequestDetailsStore.downloadFileStore.error}
            />
            <PageHeader
                title={t('common.requestDetails')}
                subtitle={t('common.requestDetailsSubtitle')}
            />
            <BreadcrumbComponent currentLabel={t('common.requestDetails')} />
            {feeRulesRequestDetailsStore.error ===
            FeeRulesRequestDetailsStoreErrors.InvalidRequestId ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : isLoadingData ? (
                <LoadingState />
            ) : (
                <Stack margin="32px" spacing="32px" direction="column">
                    {feeRulesRequestDetailsStore.requestDetails &&
                        feeRulesRequestDetailsStore.requestDetails.status ===
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING &&
                        feeRulesRequestDetailsStore.requestDetails.requesterComment &&
                        feeRulesRequestDetailsStore.newRuleFile && (
                            <PendingRuleRequestDetailsSectionComponent
                                isLoading={isFileDownloading}
                                boUserComment={
                                    feeRulesRequestDetailsStore.requestDetails.requesterComment
                                }
                                newRuleFile={feeRulesRequestDetailsStore.newRuleFile}
                                currentRuleFile={feeRulesRequestDetailsStore.oldRuleFile}
                                newRuleFileName={RulesDownloadFileNameEnum.NewFeeRulesFile}
                                currentRuleFileName={RulesDownloadFileNameEnum.CurrentFeeRulesFile}
                                onDownloadClick={(documentId, fileName): Promise<void> =>
                                    onDownloadButtonClick(documentId, fileName)
                                }
                                showWithdrawActionButton={showWithdrawActionButton()}
                                onWithdrawClick={onWithdrawClick}
                                onDenyClick={(): void => {
                                    onButtonClick(Status.DENIED);
                                }}
                                onApproveClick={(): void => {
                                    onButtonClick(Status.APPROVED);
                                }}
                                showApproveDenyActionButton={showApproveDenyActionButton()}
                            />
                        )}
                    {feeRulesRequestDetailsStore.requestDetails &&
                        feeRulesRequestDetailsStore.requestDetails.status !==
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING && (
                            <Card>
                                <RequestDetailsSection
                                    requestDetails={feeRulesRequestDetailsStore.requestDetails}>
                                    <FileDownloadActionButtons
                                        onDownloadButtonClick={(
                                            documentId,
                                            fileName
                                        ): Promise<void> =>
                                            onDownloadButtonClick(documentId, fileName)
                                        }
                                        isLoading={isFileDownloading}
                                        fileType={fileType}
                                        setFileType={setFileType}
                                        oldRuleFile={feeRulesRequestDetailsStore.oldRuleFile}
                                        newRuleFile={feeRulesRequestDetailsStore.newRuleFile}
                                        oldRuleFileName={RulesDownloadFileNameEnum.OldFeeRulesFile}
                                        newRuleFileName={RulesDownloadFileNameEnum.NewFeeRulesFile}
                                    />
                                </RequestDetailsSection>
                            </Card>
                        )}
                    {showViewFeeRulesChanges && feeRulesRequestDetailsStore.requestId && (
                        <Box margin="32px">
                            <ViewFeeRulesChangesComponent
                                store={feeRulesRequestDetailsStore.feeDeterminationRulesDiffStore}
                                requestId={feeRulesRequestDetailsStore.requestId}
                            />
                        </Box>
                    )}
                    {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
                    {errorDialogMessage && ErrorDialogBox()}
                    {isRequestCheckDialogOpen && RequestCheckDialogBox()}
                </Stack>
            )}
        </Stack>
    );
});
