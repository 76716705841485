import { Amount, Currency, GetRefundAmountRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetRefundAmountRPCImpl extends GetRefundAmountRPC {
    execute(
        _request: GetRefundAmountRPC.Request
    ): Promise<LeoRPCResult<GetRefundAmountRPC.Response, GetRefundAmountRPC.Errors.Errors>> {
        const response = new GetRefundAmountRPC.Response(new Amount(50000000, new Currency('MWK')));
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetRefundAmountRPC.Response, GetRefundAmountRPC.Errors.Errors>
        >;
    }
}
