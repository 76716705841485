import {
    BOName,
    BOUserComment,
    GetBCNUserRequestHistoryRPC,
    RequestDetails,
    UserName,
    UserRequest,
    UserStatus,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetBCNUserRequestHistoryRPCImpl extends GetBCNUserRequestHistoryRPC {
    execute(
        _request: GetBCNUserRequestHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetBCNUserRequestHistoryRPC.Response,
            GetBCNUserRequestHistoryRPC.Errors.Errors
        >
    > {
        const userRequest: UserRequest[] = [
            new UserRequest(
                UserStatus.UserStatus.ACTIVE,
                UserStatus.UserStatus.DEACTIVATED,
                new RequestDetails.Pending(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Jane'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user is no longer using this profile.')
                    )
                )
            ),
            new UserRequest(
                UserStatus.UserStatus.DEACTIVATED,
                UserStatus.UserStatus.ARCHIVED,
                new RequestDetails.Pending(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Jane'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user is no longer using this profile.')
                    )
                )
            ),
            new UserRequest(
                UserStatus.UserStatus.DEACTIVATED,
                UserStatus.UserStatus.ACTIVE,
                new RequestDetails.Approved(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Wade'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to activate the account back.')
                    ),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Jane'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to activate the account back.')
                    )
                )
            ),
            new UserRequest(
                UserStatus.UserStatus.DEACTIVATED,
                UserStatus.UserStatus.ACTIVE,
                new RequestDetails.Denied(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Esther'), new BOName('Howard')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to deactivate the account temporarily.')
                    ),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Jane'), new BOName('Warren')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to deactivate the account temporarily.')
                    )
                )
            ),
            new UserRequest(
                UserStatus.UserStatus.ACTIVE,
                UserStatus.UserStatus.DEACTIVATED,
                new RequestDetails.Withdrawn(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Brooklyn'), new BOName('Simmons')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to deactivate the account temporarily.')
                    ),
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to deactivate the account temporarily.')
                )
            ),
            new UserRequest(
                UserStatus.UserStatus.DEACTIVATED,
                UserStatus.UserStatus.ACTIVE,
                new RequestDetails.Discarded(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID(),
                        new UserName(new BOName('Cameron'), new BOName('Williamson')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('The user has requested to activate the account temporarily.')
                    )
                )
            )
        ];
        const response = new GetBCNUserRequestHistoryRPC.Response(userRequest);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBCNUserRequestHistoryRPC.Response,
                GetBCNUserRequestHistoryRPC.Errors.Errors
            >
        >;
    }
}
