export const downloadFile = (url: string): void => {
    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );
    document.body.removeChild(link);
};

export const generateCSVFile = (csvData: string, fileName: string): File => {
    return new File([csvData], fileName, {
        type: 'text/csv'
    });
};
