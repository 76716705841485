import { Box, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface EmptyStateProps {
    image: string;
    text: string;
}

export function EmptyStateComponent({ image, text }: EmptyStateProps): React.ReactElement {
    const palette = usePalette();
    const typography = useTypography();

    const StyledCenterBox = styled(Box)(() => ({
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column'
    }));

    const CircularBackground = styled(Box)(() => ({
        width: '120px',
        height: '120px',
        backgroundColor: palette.background[400],
        borderRadius: '250px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }));

    return (
        <StyledCenterBox>
            <CircularBackground>
                <img src={image} alt="Empty state" />
            </CircularBackground>
            <Typography sx={{ color: palette.label[200], ...typography.small2, mt: '16px' }}>
                {text}
            </Typography>
        </StyledCenterBox>
    );
}
