import { Stack } from '@mui/material';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateAccountDetailsStore } from '../store/hooks';
import { AccountType, AccountDetailsModel } from '../store/ValidateAccountDetailsStore';
import { AccountDetailsCard } from './AccountDetailsCard';

export const FromAndToAccountDetailsSection = (): React.ReactElement => {
    const { t } = useTranslation();
    const store = useValidateAccountDetailsStore();
    const senderAccountType =
        store.senderAccountType === 'System' ? AccountType.System : AccountType.User;
    const receiverAccountType =
        store.receiverAccountType === 'System' ? AccountType.System : AccountType.User;

    const getAccountDetailsCard = (
        accountDetails: Instance<typeof AccountDetailsModel | null>,
        title: string,
        accountType: AccountType
    ): React.ReactElement => {
        if (accountDetails?.systemAccountDetails) {
            return (
                <AccountDetailsCard
                    title={title}
                    accountType={accountType}
                    country={accountDetails?.systemAccountDetails.countryName}
                    systemAccountName={accountDetails?.systemAccountDetails.accountName}
                    currency={accountDetails?.currency ?? ''}
                />
            );
        } else if (accountDetails?.userAccountDetails) {
            return (
                <AccountDetailsCard
                    title={title}
                    accountType={accountType}
                    userPhotoUrl={accountDetails?.userAccountDetails.profileImageUrl ?? undefined}
                    userName={accountDetails?.userAccountDetails.name}
                    mobileNumber={accountDetails?.userAccountDetails.phoneNumber}
                    accountId={accountDetails?.userAccountDetails.accountId}
                    currency={accountDetails?.currency ?? ''}
                />
            );
        }
        return <></>;
    };

    return (
        <Stack spacing="16px" direction="row">
            {getAccountDetailsCard(
                store.senderAccountDetails,
                t('genericFundsTransfer.fromAccountDetails'),
                senderAccountType
            )}
            {getAccountDetailsCard(
                store.receiverAccountDetails,
                t('genericFundsTransfer.toAccountDetails'),
                receiverAccountType
            )}
        </Stack>
    );
};
