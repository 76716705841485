import { Box, Slider, Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { BlobData, getCroppedImage } from '../utils/ProfileImageCropUtils';
import { MinusCircle } from '../../../../assets/icons/MinusCircle';
import { PlusCircle } from '../../../../assets/icons/PlusCircle';
import { useLoggerStore } from '../../../../log/hooks';
import { Dialog } from '../../../common/components/Dialog';
import { Button } from '../../../common/components/Button';

interface UpdateUserPhotoDialogProps {
    isDialogOpen: boolean;
    onCancelButtonClick: () => void;
    onSelectPhotoButtonClick: () => void;
    imageUrl: string;
    imageType: string;
    setFinalCroppedImage: (image: BlobData) => void;
}

export const UpdateUserPhotoDialog = ({
    isDialogOpen,
    onCancelButtonClick,
    onSelectPhotoButtonClick,
    imageUrl,
    imageType,
    setFinalCroppedImage
}: UpdateUserPhotoDialogProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();
    const [zoom, setZoom] = useState<number>(1);
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [isMinusButtonDisable, setIsMinusDisableButton] = useState(false);
    const [isPlusButtonDisable, setIsPlusDisableButton] = useState(false);
    const loggerStore = useLoggerStore();
    // To zoom in and out with 0.5 step, This is done to ensure it matches the zoom's round value when it reaches to 3 and we can disable to buttons.
    const step = 0.5;
    const sliderMinValue = 1;
    const sliderMaxValue = 3;

    const renderCroppedImage = async (): Promise<void> => {
        try {
            if (imageUrl && croppedAreaPixels) {
                const croppedImage = await getCroppedImage(imageUrl, croppedAreaPixels, imageType);
                if (croppedImage) {
                    setFinalCroppedImage(croppedImage);
                } else {
                    loggerStore.debug('CroppedImage not found in renderCroppedImage function');
                }
            } else {
                loggerStore.debug(
                    'imageURL or croppedAreaPixels not present in renderCroppedImage'
                );
            }
        } catch (error) {
            loggerStore.error(`Unknown error: ${error} occurred in UpdateUserPhotoDialog`);
        }
    };

    useEffect(() => {
        renderCroppedImage();
    }, [imageUrl, croppedAreaPixels]);

    useEffect(() => {
        // This comparison is done to ensure disabling the buttons on the slider
        if (zoom === sliderMinValue) {
            setIsMinusDisableButton(true);
        } else if (zoom === sliderMaxValue) {
            setIsPlusDisableButton(true);
        } else {
            setIsMinusDisableButton(false);
            setIsPlusDisableButton(false);
        }
    }, [zoom]);

    return (
        <Dialog
            open={isDialogOpen}
            onClose={onCancelButtonClick}
            title={t('profileSetting.updateUserPhoto')}
            primaryButtonText={t('common.next')}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onCancelButtonClick}
            onPrimaryButtonClick={async (): Promise<void> => {
                onSelectPhotoButtonClick();
            }}
            disableBackdropClick={false}>
            <Stack alignItems="center" height="350px">
                <Typography sx={{ ...typography.body2, color: palette.label[200] }}>
                    {t('profileSetting.updateUserPhotoSubtitle')}
                </Typography>
                <Box
                    sx={{
                        marginTop: '20px',
                        height: '240px',
                        width: '520px',
                        backgroundColor: palette.background[200]
                    }}>
                    <Box
                        sx={{
                            padding: '20px 160px 20px 160px',
                            position: 'absolute',
                            width: '200px',
                            height: '200px'
                        }}>
                        <Cropper
                            image={imageUrl ?? undefined}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={(
                                _croppedArea: Area,
                                _croppedAreaPixels: Area
                            ): void => {
                                setCroppedAreaPixels(_croppedAreaPixels);
                            }}
                            onZoomChange={setZoom}
                        />
                    </Box>
                </Box>
                <Stack direction="row" marginTop="20px" alignItems="center">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '324px',
                            height: '48px',
                            backgroundColor: palette.background[200],
                            borderRadius: '8px',
                            alignItems: 'center'
                        }}>
                        <Button
                            style={{
                                width: '24px',
                                height: '24px',
                                margin: '12px',
                                backgroundColor: palette.background[200]
                            }}
                            name="minusCircle"
                            size="medium"
                            icon={<MinusCircle />}
                            iconPosition="left"
                            isDisabled={isMinusButtonDisable}
                            variant="plain-color"
                            onClick={(): void => {
                                if (zoom > sliderMinValue) {
                                    setZoom(zoom - step);
                                } else {
                                    setIsMinusDisableButton(true);
                                }
                            }}
                        />
                        <Box width="8px" />
                        <Box
                            sx={{
                                width: '350px'
                            }}>
                            <Slider
                                value={zoom}
                                min={sliderMinValue}
                                max={sliderMaxValue}
                                step={step}
                                aria-labelledby="Zoom"
                                onChange={(e, _zoom): void => setZoom(Number(_zoom))}
                                sx={{ padding: '22px 0px', top: '2px' }}
                            />
                        </Box>
                        <Box width="8px" />
                        <Button
                            style={{
                                width: '24px',
                                height: '24px',
                                margin: '12px',
                                backgroundColor: palette.background[200]
                            }}
                            name="minusCircle"
                            size="medium"
                            icon={<PlusCircle />}
                            iconPosition="left"
                            isDisabled={isPlusButtonDisable}
                            variant="plain-color"
                            onClick={(): void => {
                                if (zoom < sliderMaxValue) {
                                    setZoom(zoom + step);
                                } else {
                                    setIsPlusDisableButton(true);
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Dialog>
    );
};
