import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import { useUnclaimedTransactionDetailsStore } from '../store/hooks';
import { UnclaimedFundsTransactionDetailSection } from '../components/UnclaimedFundsTransactionDetailSection';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const CBSTransactionDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParam] = useSearchParams();
    const unclaimedFundId = searchParam.get('unclaimedFundId');
    const store = useUnclaimedTransactionDetailsStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    const fetchUnclaimedTransactionDetails = async (): Promise<void> => {
        if (unclaimedFundId) {
            setLoading(true);
            await store.getUnclaimedTransactionDetails(unclaimedFundId);
            setLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            navigate('/unclaimed-funds/search', { replace: true });
        }
    };

    useEffect(() => {
        store.resetStore();
        fetchUnclaimedTransactionDetails();
    }, []);

    return (
        <Stack direction="column">
            <PageHeader
                title={t('unclaimedFunds.unclaimedFundDetails')}
                subtitle={t('unclaimedFunds.unclaimedFundDetailsSubtitle')}
            />
            {store.error ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : loading ? (
                <LoadingState />
            ) : (
                <>
                    <BreadcrumbComponent currentLabel={t('unclaimedFunds.unclaimedFundDetails')} />
                    <Stack sx={{ padding: '32px', boxSizing: 'border-box', width: '100%' }}>
                        <UnclaimedFundsTransactionDetailSection
                            transactionDetails={store.transactionDetails}
                        />
                    </Stack>
                </>
            )}
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetStore();
                }}
            />
        </Stack>
    );
});
