import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { useConfirmAuthCodeRPCClient } from './../rpcs/RPC';
import { ConfirmAuthCodeRPC } from '@resolut-tech/bcn-rpcs';
import { SignInStore } from './SignInStore';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../log/hooks';
import { LoggerStore } from '../../../log/LoggerStore';
import { NetworkingError } from '../../error/store/ErrorStore';

export enum AuthCodeEntryErrors {
    InvalidAuthCode = 'INVALID_AUTH_CODE',
    CouldNotSendOtp = 'COULD_NOT_SEND_OTP',
    IncorrectAuthCodeLength = 'INCORRECT_AUTH_CODE_LENGTH',
    InvalidPasswordValidatedToken = 'INVALID_PASSWORD_VALIDATED_TOKEN',
    PasswordValidatedTokenExpired = 'PASSWORD_VALIDATED_TOKEN_EXPIRED',
    TooManyRequests = 'TOO_MANY_REQUESTS',
    TooManyIncorrectAuthCodeAttempts = 'TOO_MANY_INCORRECT_AUTH_CODE_ATTEMPTS'
}

const getStoreError = (
    error: ConfirmAuthCodeRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): AuthCodeEntryErrors | CommonAuthErrors | NetworkingError => {
    switch (error.code) {
        case CommonAuthErrors.BoUserDisabled:
            return CommonAuthErrors.BoUserDisabled;
        case AuthCodeEntryErrors.CouldNotSendOtp:
            return AuthCodeEntryErrors.CouldNotSendOtp;
        case CommonAuthErrors.InactiveState:
            return CommonAuthErrors.InactiveState;
        case AuthCodeEntryErrors.InvalidAuthCode:
            return AuthCodeEntryErrors.InvalidAuthCode;
        case AuthCodeEntryErrors.InvalidPasswordValidatedToken:
        case AuthCodeEntryErrors.PasswordValidatedTokenExpired:
            return NetworkingError.InternalError;
        case CommonAuthErrors.SignInTemporarilyBlocked:
            return CommonAuthErrors.SignInTemporarilyBlocked;
        case AuthCodeEntryErrors.TooManyIncorrectAuthCodeAttempts:
            return AuthCodeEntryErrors.TooManyIncorrectAuthCodeAttempts;
        case AuthCodeEntryErrors.TooManyRequests:
            return AuthCodeEntryErrors.TooManyRequests;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in AuthCodeEntryStore`);
            return NetworkingError.InternalError;
    }
};

export const AuthCodeEntryStore = types
    .model({
        authCode: types.string,
        otpId: types.maybeNull(types.string),
        nextResendAt: types.maybeNull(types.Date),
        expiresAt: types.maybeNull(types.Date),
        error: types.maybeNull(
            types.union(
                types.enumeration<AuthCodeEntryErrors>(
                    'AuthCodeEntryErrors',
                    Object.values(AuthCodeEntryErrors)
                ),
                types.enumeration<CommonAuthErrors>(
                    'CommonAuthErrors',
                    Object.values(CommonAuthErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetStore(): void {
            store.authCode = '';
            store.otpId = null;
            store.nextResendAt = null;
            store.expiresAt = null;
            store.error = null;
        },
        removeError(): void {
            store.error = null;
        },
        setAuthCode(authCode: string): void {
            store.authCode = authCode;
            const signInStore = getParent<typeof SignInStore>(store);
            const passwordValidatedToken = signInStore.passwordValidatedToken();
            if (passwordValidatedToken) {
                try {
                    new ConfirmAuthCodeRPC.Request(passwordValidatedToken, authCode);
                } catch (error) {
                    store.error = AuthCodeEntryErrors.IncorrectAuthCodeLength;
                }
            } else {
                // If password validated token is not present, the user should be taken to the initial screen.
                // This is handled by Sign-In-Router
            }
        },
        confirmAuthCode: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const _authCode = store.authCode;
            const signInStore = getParent<typeof SignInStore>(store);
            const passwordValidatedToken = signInStore.passwordValidatedToken();
            if (passwordValidatedToken) {
                const request: ConfirmAuthCodeRPC.Request = new ConfirmAuthCodeRPC.Request(
                    passwordValidatedToken,
                    _authCode
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    ConfirmAuthCodeRPC.Response,
                    ConfirmAuthCodeRPC.Errors.Errors
                > = yield useConfirmAuthCodeRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.otpId = response.otpId.uuid;
                    store.nextResendAt = new Date(response.nextResendAt.timestamp);
                    store.expiresAt = new Date(response.expiresAt.timestamp);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error occurred in ConfirmAuthCodeRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } else {
                // If password validated token is not present, the user should be taken to the initial screen.
                // This is handled by AuthProtectedRouter
                loggerStore.debug('passwordValidatedToken not found in AuthCodeEntryStore');
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views((store) => ({
        authCodeLHS(): string | null {
            const signInStore = getParent<typeof SignInStore>(store);
            return signInStore.authCodeLHS();
        },
        authCodeMaxLength(): number {
            // Ideally this should not be hardcoded in the app. Since this is private to the generated RPC code, hence it is kept here
            return 4;
        }
    }));

export const createAuthCodeEntryStore = (): Instance<typeof AuthCodeEntryStore> => {
    return AuthCodeEntryStore.create({
        authCode: ''
    });
};
