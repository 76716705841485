import { useGetAgentsRPCClient } from './../rpcs/RPC';
import {
    UserSearchBy,
    CountryCode,
    GetAgentsRPC,
    ItemsPerPage,
    PageIndex,
    SearchText,
    UserStatus,
    GetAgentsPaginationResponse
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { RPCTypeErrors } from '../../common/errors/RPCTypeErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AgentSearchStoreErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const AgentSearchModel = types.model({
    id: types.string,
    name: types.string,
    country: types.string,
    phoneNumber: types.string,
    status: types.enumeration<UserStatus.UserStatus>(
        'BCNUserStatus',
        Object.values(UserStatus.UserStatus)
    )
});

const getAgentSearchModel = (
    countries: CountryViewModel[],
    paginationResponse: GetAgentsPaginationResponse
): Instance<typeof AgentSearchModel> => {
    return AgentSearchModel.create({
        id: paginationResponse.agentId.uuid,
        name: paginationResponse.name,
        country: countries.filter((_country: CountryViewModel) => {
            return _country.countryCode === paginationResponse.countryCode.code;
        })[0].name,
        phoneNumber: paginationResponse.phoneNumber.phoneNumber,
        status: paginationResponse.agentStatus
    });
};

export const AgentFilterOptions = types.model({
    searchBy: types.string,
    searchText: types.string,
    selectedAgentStatus: types.string,
    selectedCountry: types.maybe(types.string)
});

const getPhoneNumberSearchText = (
    filter: Instance<typeof AgentFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === UserSearchBy.UserSearchBy.MOBILE_NUMBER) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

const getNameSearchText = (
    filter: Instance<typeof AgentFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === UserSearchBy.UserSearchBy.NAME) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

export const AgentSearchStore = types
    .model({
        // The initial filter options for Agent search table will be dependent on `t` function.
        // The filter options is set as maybe and then updated from the UI.
        filterOptions: types.maybe(AgentFilterOptions),
        isTableIdle: types.boolean,
        countryListStore: CountryListStore,
        totalItems: types.number,
        agents: types.array(AgentSearchModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AgentSearchStoreErrors>(
                    'AgentSearchStoreErrors',
                    Object.values(AgentSearchStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        updateFilterOptions(filterOptions: Instance<typeof AgentFilterOptions>): void {
            store.isTableIdle = false;
            store.filterOptions = filterOptions;
        },
        resetError(): void {
            store.error = null;
        },
        getAgents: flow(function* (
            filter: Instance<typeof AgentFilterOptions> | undefined,
            pageIndex: number,
            itemsPerPage: number
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetAgentsRPC.Request(
                    getNameSearchText(filter),
                    getPhoneNumberSearchText(filter),
                    filter?.selectedCountry ? new CountryCode(filter?.selectedCountry) : null,
                    filter?.selectedAgentStatus === AnyEnum.ANY
                        ? null
                        : filter?.selectedAgentStatus
                        ? UserStatus.fromDTO({ case: filter.selectedAgentStatus })
                        : null,
                    new ItemsPerPage(itemsPerPage),
                    new PageIndex(pageIndex)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<GetAgentsRPC.Response, GetAgentsRPC.Errors.Errors> =
                    yield useGetAgentsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const agentList = response.getAgentsPaginationResponse.map(
                        (paginationResponse: GetAgentsPaginationResponse) => {
                            return getAgentSearchModel(
                                store.countryListStore.countryList(),
                                paginationResponse
                            );
                        }
                    );
                    store.totalItems = response.totalItems;
                    store.agents = cast(agentList);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case AgentSearchStoreErrors.InvalidPageIndex:
                            store.error = AgentSearchStoreErrors.InvalidPageIndex;
                            break;
                        default:
                            loggerStore.error(`Unhandled error: ${error} occurred in GetAgentsRPC`);
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown Error occurred in GetAgentsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case RPCTypeErrors.InvalidPageIndexError:
                            break;
                        case RPCTypeErrors.InvalidItemsPerPageError:
                            break;
                        default:
                            loggerStore.error(`Unhandled error: ${error} occurred in GetAgentsRPC`);
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in GetAgentsRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .views((store) => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10,
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        }
    }));

export const createAgentSearchStore = (): Instance<typeof AgentSearchStore> => {
    return AgentSearchStore.create({
        countryListStore: createCountryListStore(),
        agents: [],
        totalItems: 0,
        isTableIdle: true
    });
};
