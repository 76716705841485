import { Amount, Currency, GetCheckerRefundSummaryRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCheckerRefundSummaryRPCImpl extends GetCheckerRefundSummaryRPC {
    execute(
        _request: GetCheckerRefundSummaryRPC.Request
    ): Promise<
        LeoRPCResult<GetCheckerRefundSummaryRPC.Response, GetCheckerRefundSummaryRPC.Errors.Errors>
    > {
        const response = new GetCheckerRefundSummaryRPC.Response(
            new Amount(50000000, new Currency('MWK')),
            new Amount(50000, new Currency('MWK')),
            new Amount(50000, new Currency('MWK')),
            new Amount(50050000, new Currency('MWK'))
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCheckerRefundSummaryRPC.Response,
                GetCheckerRefundSummaryRPC.Errors.Errors
            >
        >;
    }
}
