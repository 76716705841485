import { Stack } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface RequestTransferButtonsProps {
    onPrimaryButtonClicked: () => void;
    onSecondaryButtonClicked: () => void;
    isPrimaryButtonDisabled: boolean;
}

export const RequestTransferButtons = ({
    onPrimaryButtonClicked,
    onSecondaryButtonClicked,
    isPrimaryButtonDisabled
}: RequestTransferButtonsProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Stack spacing="10px" sx={{ alignSelf: 'flex-end', paddingBottom: '16px' }} direction="row">
            <Button
                name={'cancelRequestTransfer'}
                title={t('common.goBack')}
                size="small"
                variant="plain-color"
                color="primary"
                style={{ width: '90px', height: '40px', backgroundColor: palette.background[300] }}
                onClick={onSecondaryButtonClicked}
            />
            <Button
                name={'requestTransfer'}
                title={t('common.requestTransfer')}
                isDisabled={isPrimaryButtonDisabled}
                size="small"
                variant={'filled'}
                color="primary"
                style={{ width: '144px', height: '40px' }}
                onClick={onPrimaryButtonClicked}
            />
        </Stack>
    );
};
