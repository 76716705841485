import { GetAgentRequestsPaginationResponse, RequestStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';

export const AgentRequestModel = types.model({
    requestedAt: types.string,
    requestStatus: types.enumeration<RequestStatus.RequestStatus>(
        'RequestStatus',
        Object.values(RequestStatus.RequestStatus)
    ),
    agentId: types.string,
    requestedBy: types.string,
    requestedFor: types.string,
    evaluatedBy: types.maybeNull(types.string)
});

export const getAgentRequestModel = (
    agentRequest: GetAgentRequestsPaginationResponse
): Instance<typeof AgentRequestModel> => {
    return AgentRequestModel.create({
        requestedAt: getFormattedTimeDateWithComma(new Date(agentRequest.requestedAt.timestamp)),
        requestStatus: agentRequest.requestStatus,
        agentId: agentRequest.agentId.uuid,
        requestedBy: getPGFullName(agentRequest.requestedBy),
        requestedFor: getPGFullName(agentRequest.requestedFor),
        evaluatedBy: agentRequest.evaluatedBy && getPGFullName(agentRequest.evaluatedBy)
    });
};
