import { Stack, Typography } from '@mui/material';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAmountString } from '../../common/utils/UIUtils';
import { useCheckAgentStatusUpdateRequestStore } from '../store/hooks';
import { Dialog } from '../../../common/components/Dialog';

interface AgentRequestDialogProps {
    title: string;
    primaryButtonText: string;
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onSubmitRequest: () => Promise<void>;
    onProceedButtonClick: () => Promise<void>;
    countdown: number;
    isShowNonZeroCommissionAccountDetails: boolean;
}

export const AgentRequestDialog = observer(
    ({
        title,
        isDialogOpen,
        onDialogClose,
        primaryButtonText,
        onSubmitRequest,
        onProceedButtonClick,
        countdown,
        isShowNonZeroCommissionAccountDetails
    }: AgentRequestDialogProps): React.ReactElement => {
        const { t } = useTranslation();
        const typography = useTypography();
        const palette = usePalette();
        const store = useCheckAgentStatusUpdateRequestStore();

        const getCommentComponent = (): React.ReactElement => {
            return (
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={store.comment ?? undefined}
                    onTextChange={store.setComment}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            );
        };

        const getNonZeroCommissionAccountDetails = (): React.ReactElement => {
            return (
                <Stack>
                    <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                        {t('agencyBanking.nonZeroCommissionAccountDetailsTitle')}
                    </Typography>
                    <Stack direction="column" marginTop="20px">
                        <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                            {t('agencyBanking.commissionBalance')}
                        </Typography>
                        {store.totalCommissionBalance && (
                            <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                                {getAmountString(store.totalCommissionBalance)}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            );
        };

        const getPrimaryButtonText = (): string => {
            if (isShowNonZeroCommissionAccountDetails) {
                if (countdown === 0) {
                    return t('common.proceed');
                } else {
                    return t('common.proceedWithTimer', { timer: countdown });
                }
            } else {
                return primaryButtonText;
            }
        };

        const isPrimaryButtonDisabled = (): boolean => {
            return store.comment?.trim().length === 0 || store.comment === null;
        };

        return (
            <Dialog
                open={isDialogOpen}
                title={title}
                primaryButtonText={getPrimaryButtonText()}
                onPrimaryButtonClick={
                    isShowNonZeroCommissionAccountDetails ? onProceedButtonClick : onSubmitRequest
                }
                isPrimaryButtonDisabled={
                    isShowNonZeroCommissionAccountDetails
                        ? countdown > 0
                        : isPrimaryButtonDisabled()
                }
                secondaryButtonText={t('common.cancel')}
                onSecondaryButtonClick={(): void => {
                    store.resetStore();
                    onDialogClose();
                }}
                disableBackdropClick>
                {isShowNonZeroCommissionAccountDetails
                    ? getNonZeroCommissionAccountDetails()
                    : getCommentComponent()}
            </Dialog>
        );
    }
);
