import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const StyledCenterContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}));

export const LoadingState = (): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <StyledCenterContainer>
            <CircularProgress sx={{ color: palette.primary[300], height: '32px', width: '32px' }} />
            <Typography sx={{ ...typography.body2, color: palette.label[200], marginTop: '10px' }}>
                {t('common.loading')}
            </Typography>
        </StyledCenterContainer>
    );
};
