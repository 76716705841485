import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTypography, usePalette, ButtonVariant, Section } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../assets/icons/Download';
import { Button } from '../../../common/components/Button';

interface FileDownloadComponentProps {
    variant: 'template' | 'current';
    buttonVariant: ButtonVariant;
    onDownloadButtonClick: () => void;
    isLoading: boolean;
}

export function FileDownloadComponent({
    variant,
    buttonVariant,
    onDownloadButtonClick,
    isLoading
}: FileDownloadComponentProps): React.ReactElement {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    return (
        <Section
            title={variant === 'current' ? t('common.currentFile') : t('common.templateFile')}
            allowViewChange={false}
            headerHeight="50px"
            content={[
                <Stack key="fileDownloadComponent" sx={{ padding: '16px' }}>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {variant === 'current'
                            ? t('common.downloadCurrentFileToEditUpdateAndUpload')
                            : t('common.downloadTemplateFileToEditUpdateAndUpload')}
                    </Typography>
                    <Button
                        isLoading={isLoading}
                        style={{ marginTop: '8px', width: '131px' }} // TODO: Fix extra button padding. Ideally this should be 128px, it has been noticed that the current button is adding extra internal padding of 3px which should be fixed
                        name="download"
                        size="small"
                        icon={<Download />}
                        iconPosition="left"
                        title={t('common.downloadFile')}
                        variant={buttonVariant}
                        onClick={onDownloadButtonClick}
                    />
                </Stack>
            ]}
        />
    );
}
