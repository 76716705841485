import { types } from 'mobx-state-tree';
import { AmountModel } from '../../common/models/AmountModel';

export const UnclaimedFundsListModel = types.model({
    unclaimedFundId: types.string,
    createdAt: types.string,
    userName: types.string,
    userPhoneNumber: types.string,
    amount: AmountModel,
    country: types.string
});
