import { PasswordPolicy } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../utils/StringUtils';

export const PasswordPolicyModel = types.model({
    title: types.string,
    description: types.array(types.string)
});

export const getPasswordPolicyModel = (
    passwordPolicy: PasswordPolicy
): Instance<typeof PasswordPolicyModel> => {
    return PasswordPolicyModel.create({
        title: getTranslatedString(passwordPolicy.title),
        description: passwordPolicy.description.map((desc) => getTranslatedString(desc))
    });
};
