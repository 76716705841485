import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface WithdrawActionSectionProps {
    onWithdrawClick: () => void;
}

export const WithdrawActionSection = ({
    onWithdrawClick
}: WithdrawActionSectionProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px',
                borderTop: `1px solid ${palette.outline[200]}`,
                justifyContent: 'flex-end'
            }}>
            <Button
                name="secondary-button"
                variant="outlined-neutral"
                size="small"
                title={t('common.withdrawRequest')}
                onClick={onWithdrawClick}
            />
        </Box>
    );
};
