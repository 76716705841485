import { Stack } from '@mui/material';
import { LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { ErrorState } from '../../common/components/ErrorState';
import { LoadingState } from '../../common/components/LoadingState';
import { PageHeader } from '../../common/components/PageHeader';
import { ProfileBasicDetails } from '../components/ProfileBasicDetails';
import { ProfileManagementSection } from '../components/ProfileManagementSection';
import { useProfileDetailsStore } from '../store/hooks';

export const ProfileSetting = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const store = useProfileDetailsStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    const fetchProfileBasicDetails = async (): Promise<void> => {
        setLoading(true);
        await store.getProfileDetails();
        setLoading(false);
        if (store.error === NetworkingError.InternalError) {
            setIsErrorDialogOpen(true);
        }
    };

    useEffect(() => {
        fetchProfileBasicDetails();
    }, []);

    const renderUserDetails = (): React.ReactElement => {
        if (loading) {
            return <LoadingState />;
        } else if (store.error) {
            return <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />;
        } else {
            return (
                <Stack
                    sx={{ padding: '32px', boxSizing: 'border-box', width: '100%' }}
                    spacing="32px">
                    <ProfileBasicDetails setIsLoading={setIsLoading} />
                    <ProfileManagementSection setIsLoading={setIsLoading} />
                </Stack>
            );
        }
    };

    return (
        <Stack direction="column">
            <LoadingIndicator isLoading={isLoading} />
            <PageHeader
                title={t('common.profileSettings')}
                subtitle={t('profileSetting.profileSettingSubtitle')}
            />
            {renderUserDetails()}
            <ErrorDialog
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    store.removeError();
                }}
            />
        </Stack>
    );
});
