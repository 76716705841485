import { LeoPhoneNumber, LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    ABRequestId,
    AgentListTransactionStatus,
    AgentTransaction,
    AgentTransactionSearchBy,
    AgentTransactionType,
    AgentTransactionUserDetails,
    Amount,
    BOName,
    Currency,
    GetAgentTransactionListRPC,
    UserName
} from '@resolut-tech/bcn-rpcs';

const agentTransactionList: AgentTransaction[] = [
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.UNCOLLECTED,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.CASH_IN,
        AgentListTransactionStatus.AgentListTransactionStatus.SUCCEEDED,
        null,
        new LeoUUID(),
        null,
        new AgentTransactionUserDetails(
            new UserName(new BOName('Gedeon'), new BOName('Adanlin')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Arlene'), new BOName('McCoy')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.CASH_OUT,
        AgentListTransactionStatus.AgentListTransactionStatus.SUCCEEDED,
        null,
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        null,
        null,
        new AgentTransactionUserDetails(
            new UserName(new BOName('Germain'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.AWAITING_RECIPIENT_COLLECTION,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.MOVED_OUT_OF_YAFIKA,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.MOVED_TO_UNCLAIMED_FUNDS,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.PROCESSING,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.REFUND_TO_SENDER_BLOCKED,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.REVERSED,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    ),
    new AgentTransaction(
        new LeoTimestamp(),
        new Amount(50000000, new Currency('MWK')),
        AgentTransactionType.AgentTransactionType.MONEY_TRANSFER,
        AgentListTransactionStatus.AgentListTransactionStatus.SUCCESSFULLY_REFUNDED_TO_SENDER,
        new ABRequestId('Ik76n7xC'),
        new LeoUUID(),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Wade'), new BOName('Warren')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eric'), new BOName('Gbozo')),
            new LeoPhoneNumber('+265995118354')
        ),
        new AgentTransactionUserDetails(
            new UserName(new BOName('Eleanor'), new BOName('Pena')),
            new LeoPhoneNumber('+265995118354')
        )
    )
];

export class MockGetAgentTransactionListRPCImpl extends GetAgentTransactionListRPC {
    execute(
        request: GetAgentTransactionListRPC.Request
    ): Promise<
        LeoRPCResult<GetAgentTransactionListRPC.Response, GetAgentTransactionListRPC.Errors.Errors>
    > {
        let list: AgentTransaction[] = agentTransactionList;

        const searchText = request.searchText?.text.toLowerCase() ?? '';
        if (
            request.searchBy ===
            AgentTransactionSearchBy.AgentTransactionSearchBy.AGENT_MOBILE_NUMBER
        ) {
            list = list.filter(
                (t) =>
                    t.senderAgentDetails?.phoneNumber?.phoneNumber
                        .toLowerCase()
                        .includes(searchText) ??
                    t.recipientAgentDetails?.phoneNumber?.phoneNumber
                        .toLowerCase()
                        .includes(searchText)
            );
        }
        if (
            request.searchBy ===
            AgentTransactionSearchBy.AgentTransactionSearchBy.RECIPIENT_MOBILE_NUMBER
        ) {
            list = list.filter((t) =>
                t.recipientDetails?.phoneNumber?.phoneNumber.toLowerCase().includes(searchText)
            );
        }
        if (
            request.searchBy ===
            AgentTransactionSearchBy.AgentTransactionSearchBy.SENDER_MOBILE_NUMBER
        ) {
            list = list.filter((t) =>
                t.senderDetails?.phoneNumber?.phoneNumber.toLowerCase().includes(searchText)
            );
        }
        if (request.searchBy === AgentTransactionSearchBy.AgentTransactionSearchBy.REQUEST_ID) {
            list = list.filter((t) => t.requestId?.id.includes(searchText));
        }
        if (request.searchBy === AgentTransactionSearchBy.AgentTransactionSearchBy.TRANSACTION_ID) {
            list = list.filter((t) => t.transactionId.uuid.includes(searchText));
        }
        if (request.transactionStatus) {
            list = list.filter((t) => {
                return t.transactionStatus === request.transactionStatus;
            });
        }

        if (request.transactionType) {
            list = list.filter((t) => {
                return t.transactionType === request.transactionType;
            });
        }
        const response = new GetAgentTransactionListRPC.Response(list.length, list);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetAgentTransactionListRPC.Response,
                GetAgentTransactionListRPC.Errors.Errors
            >
        >;
    }
}
