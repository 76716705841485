import { Box, Stack, styled, Typography } from '@mui/material';
import { EllipsisTypography, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { CopyButton } from '../../../../assets/icons/CopyButton';
import { StatusComponent } from './StatusComponent';
import {
    UserRoleChipsCell,
    DiffHeaderCell,
    PrefixIconCell,
    SectionRowCellEnum,
    SectionRowCellType,
    UserStatusChipCell,
    StatusIcon,
    ProfileImageCell
} from '../types/SectionRowComponentTypes';
import { UserAvatar } from './UserAvatar';
import { DiffViewRow } from '../../bo-users/components/DiffViewRow';
import { UpdateDeletePhotoActionSection } from './UpdateDeletePhotoActionSection';
import { UserRoleChipsCellComponent } from '../../bo-users/components/UserRoleChipsCellComponent';
import { UserStatusDiffCellComponent } from '../../bo-users/components/UserStatusDiffCellComponent';
import { DiffHeaderCellComponent } from '../../bo-users/components/DiffHeaderCellComponent';
import { KYCDocumentCellActionSection } from './KYCDocumentCellActionSection';
import { getPrefixIcon } from '../utils/UIUtils';
import { useTranslation } from 'react-i18next';
import { PreviewPhotoDialog } from './dialog/PreviewPhotoDialog';
import noFilePresent from '../../../../assets/no-file-present-placeHolder.svg';
import { useLoggerStore } from '../../../../log/hooks';
import { Button } from '../../../common/components/Button';

export interface CopyIconProp {
    text: string;
}

const CopyIconButton = ({ text }: CopyIconProp): React.ReactElement => {
    return (
        <Button
            style={{ width: '32px', height: '32px', marginLeft: '8px' }}
            name="copy"
            size="small"
            icon={<CopyButton height="16px" width="16px" />}
            iconPosition="left"
            variant="outlined-color"
            onClick={(): void => {
                navigator.clipboard.writeText(text);
            }}
        />
    );
};

const ViewPhotoButton = ({ imageUrl }: { imageUrl?: string }): React.ReactElement => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const loggerStore = useLoggerStore();

    if (imageUrl) {
        return (
            <>
                <PreviewPhotoDialog
                    isDialogOpen={isDialogOpen}
                    onClose={function (): void {
                        setIsDialogOpen(false);
                    }}
                    photoUrl={imageUrl}
                />
                <Button
                    style={{ width: '94px' }}
                    name="viewPhoto"
                    size="small"
                    title={t('common.viewPhoto')}
                    variant="outlined-color"
                    onClick={(): void => {
                        setIsDialogOpen(true);
                    }}
                />
            </>
        );
    } else {
        loggerStore.debug('Cannot find selected imageURL while creating ViewPhotoButton component');
        return <></>;
    }
};

export const SectionCellContainer = styled(Box)({
    display: 'flex',
    padding: '12px 0px 12px 16px'
});

export const SectionRowComponent = (cell: SectionRowCellType): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    const StyledTypography = styled(Typography)({
        ...(cell.type === SectionRowCellEnum.Bold ? typography.sh2 : typography.body2),
        color: palette.label[300]
    });

    const StyledEllipsisTypography = styled(EllipsisTypography)({
        ...(cell.type === SectionRowCellEnum.Bold ? typography.sh2 : typography.body2),
        color: palette.label[300],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    });

    const getPrefixIconCell = (data: PrefixIconCell): React.ReactElement => {
        return (
            <SectionCellContainer gap={1}>
                <img src={getPrefixIcon(data.statusIcon as StatusIcon)} />
                <StyledTypography>{data.text}</StyledTypography>
            </SectionCellContainer>
        );
    };

    const getBoldCell = (text: string): React.ReactElement => {
        return (
            <SectionCellContainer>
                <StyledTypography sx={{ height: '24px' }}>{text}</StyledTypography>
            </SectionCellContainer>
        );
    };

    const getUnstyledCell = (
        text: string,
        diffText: string,
        showDiff = false,
        showEllipsis = false,
        maxWidth = '100vh',
        isSectionComponent = false
    ): React.ReactElement => {
        if (text === diffText || !showDiff) {
            if (showEllipsis) {
                return (
                    <SectionCellContainer>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth
                            }}>
                            <StyledEllipsisTypography>{text}</StyledEllipsisTypography>
                        </Box>
                    </SectionCellContainer>
                );
            } else {
                return (
                    <SectionCellContainer>
                        <StyledTypography>{text}</StyledTypography>
                    </SectionCellContainer>
                );
            }
        }
        return (
            <DiffViewRow
                currentDetails={text}
                updatedDetails={diffText}
                isSectionComponent={isSectionComponent}
            />
        );
    };

    const getTimeCell = (text: string): React.ReactElement => {
        return (
            <SectionCellContainer>
                <StyledTypography>{text}</StyledTypography>
            </SectionCellContainer>
        );
    };

    const getCopyCell = (text: string): React.ReactElement => {
        return (
            <Stack direction="row" alignItems="center">
                <SectionCellContainer>
                    <StyledTypography>{text}</StyledTypography>
                </SectionCellContainer>
                <CopyIconButton text={text} />
            </Stack>
        );
    };

    const getUserStatusChipCell = (data: UserStatusChipCell): React.ReactElement => {
        if (data.updatedStatus !== undefined && data.updatedStatus !== data.status) {
            return (
                <UserStatusDiffCellComponent
                    status={data.status}
                    updatedStatus={data.updatedStatus}
                />
            );
        } else {
            return (
                <SectionCellContainer>
                    <StatusComponent variant="status-cell" status={data.status} />
                </SectionCellContainer>
            );
        }
    };

    const getProfileImageCell = ({
        imageUrl,
        showViewPhotoButton,
        onUpdateClick,
        onDeleteClick
    }: ProfileImageCell): React.ReactElement => {
        return (
            <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{ margin: '8px 8px 8px 16px' }}>
                <UserAvatar src={imageUrl} size="large" />
                {showViewPhotoButton && <ViewPhotoButton imageUrl={imageUrl} />}
                {onUpdateClick && onDeleteClick && (
                    <UpdateDeletePhotoActionSection
                        onUpdateClick={onUpdateClick}
                        onDeleteClick={onDeleteClick}
                        isDeleteButtonDisabled={!Boolean(imageUrl)}
                    />
                )}
            </Stack>
        );
    };

    const getDocumentCell = (
        fileUrl: string | null,
        fileType: string | null,
        onDownloadClick: () => void,
        onViewPhotoClick: () => void
    ): React.ReactElement => {
        return (
            <KYCDocumentCellActionSection
                fileUrl={fileUrl}
                fileType={fileType}
                onDownloadClick={onDownloadClick}
                onViewPhotoClick={onViewPhotoClick}
            />
        );
    };

    const getImageCell = (
        imageUrl: string | null,
        onViewImageClick: () => void,
        imageWidth: string,
        imageHeight: string
    ): React.ReactElement => {
        const { t } = useTranslation();
        return (
            <Stack
                direction="row"
                alignItems="center"
                sx={{ margin: '8px 8px 8px 16px' }}
                spacing="12px">
                <img
                    src={imageUrl ?? noFilePresent}
                    style={{ width: imageWidth, height: imageHeight }}
                />
                {imageUrl && (
                    <Button
                        style={{ width: '94px' }}
                        name="viewPhoto"
                        size="small"
                        title={t('common.viewPhoto')}
                        variant="outlined-color"
                        onClick={onViewImageClick}
                    />
                )}
            </Stack>
        );
    };

    const getUserRoleChipsCell = (data: UserRoleChipsCell): React.ReactElement => {
        return <UserRoleChipsCellComponent data={data} />;
    };

    const getDiffHeader = (data: DiffHeaderCell): React.ReactElement => {
        return <DiffHeaderCellComponent data={data} />;
    };

    const renderRowComponent = (): React.ReactElement => {
        switch (cell.type) {
            case SectionRowCellEnum.PrefixIcon:
                return getPrefixIconCell(cell);
            case SectionRowCellEnum.Bold:
                return getBoldCell(cell.text);
            case SectionRowCellEnum.Unstyled:
                return getUnstyledCell(
                    cell.text ?? '-',
                    cell.diffText ?? '-',
                    cell.showDiff,
                    cell.showEllipsis,
                    cell.maxWidth,
                    cell.isSectionComponent
                );
            case SectionRowCellEnum.Copy:
                return getCopyCell(cell.text);
            case SectionRowCellEnum.UserStatusChip:
                return getUserStatusChipCell(cell);
            case SectionRowCellEnum.ProfileImage:
                return getProfileImageCell(cell);
            case SectionRowCellEnum.UserRoleChips:
                return getUserRoleChipsCell(cell);
            case SectionRowCellEnum.DiffHeader:
                return getDiffHeader(cell);
            case SectionRowCellEnum.Document:
                return getDocumentCell(
                    cell.fileUrl ?? null,
                    cell.fileType ?? null,
                    cell.onDownloadClick,
                    cell.onViewPhotoClick
                );
            case SectionRowCellEnum.Image:
                return getImageCell(
                    cell.imageUrl,
                    cell.onViewImageClick,
                    cell.imageWidth,
                    cell.imageHeight
                );
            case SectionRowCellEnum.Time:
                return getTimeCell(cell.text);
        }
    };

    return (
        <Stack direction="row" alignItems="center">
            {renderRowComponent()}
        </Stack>
    );
};
