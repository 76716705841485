import { BOGender, Gender, PGAmount, RequestStatus, UserStatus } from '@resolut-tech/bcn-rpcs';
import { Instance } from 'mobx-state-tree';
import { TFunction } from 'react-i18next';
import { AmountModel } from '../models/AmountModel';
import { StatusType } from '../components/StatusComponent';
import {
    ColorPalette,
    DropdownItem,
    PaginatedViewStyleOverrides
} from '@surya-digital/leo-reactjs-ui';
import { AnyEnum } from '../enums/AnyEnum';
import { TransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';
import { AgentTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/agentTransactionDetail';
import { StatusIcon } from '../types/SectionRowComponentTypes';
import successIcon from '../../../../assets/check-circle.svg';
import pendingIcon from '../../../../assets/clock-8.svg';
import reversedIcon from '../../../../assets/undo-error.svg';
import unCollectedIcon from '../../../../assets/rotate-ccw.svg';
import blockedIcon from '../../../../assets/x-circle.svg';
import refundedIcon from '../../../../assets/undo-success.svg';
import unclaimedIcon from '../../../../assets/coins.svg';
import movedOutIcon from '../../../../assets/redo.svg';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { CBSIdWithCountry } from '../models/CountryCbsIdListModel';

export const getUserStatus = (status: UserStatus.UserStatus): StatusType => {
    switch (status) {
        case UserStatus.UserStatus.ACTIVE:
            return StatusType.ACTIVE;
        case UserStatus.UserStatus.DEACTIVATED:
            return StatusType.DEACTIVATED;
        case UserStatus.UserStatus.ARCHIVED:
            return StatusType.ARCHIVED;
    }
};

export const getRequestStatus = (status: RequestStatus.RequestStatus): StatusType => {
    switch (status) {
        case RequestStatus.RequestStatus.APPROVED:
            return StatusType.APPROVED;
        case RequestStatus.RequestStatus.DENIED:
            return StatusType.DENIED;
        case RequestStatus.RequestStatus.WITHDRAWN:
            return StatusType.WITHDRAWN;
        case RequestStatus.RequestStatus.DISCARDED:
            return StatusType.DISCARDED;
        case RequestStatus.RequestStatus.PENDING:
            return StatusType.PENDING;
    }
};

export const getGenderTranslation = (
    t: TFunction,
    gender?: BOGender.BOGender | Gender.Gender
): string => {
    switch (gender) {
        case BOGender.BOGender.MALE:
        case Gender.Gender.MALE:
            return t('common.male');
        case BOGender.BOGender.FEMALE:
        case Gender.Gender.FEMALE:
            return t('common.female');
        case BOGender.BOGender.OTHER:
            return t('common.other');
        default:
            return ''; // This is used inside section cell which cannot be null, hence empty string is returned.
    }
};

export const getFormattedStringFromNumber = (number: number): string => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    })
        .format(number)
        .toString();
};

// In getAmountString, we are not dividing by 10000 because When the AmountModel is created the calculation is already taken care of.
export const getAmountString = ({ currency, number }: Instance<typeof AmountModel>): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    })
        .format(number)
        .toString();
};

// In getPGAmountString, we are dividing by 10000 to since the back-end sends the PGAmount value multiplied by 10000.
export const getPGAmountString = ({ currencyCode, amount }: PGAmount): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 4
    })
        .format(amount / 10000)
        .toString();
};

// formatAmountValue is used to format the amount in FDR and TLV, since the amount sent by the server is multiplied by 10^4.
export const formatAmountValue = (amount?: number | null): number | null => {
    if (!amount) return null;
    return amount / 10000;
};

export const parseAmount = (value: string): string => {
    if (value === '') return '';
    const amount = Number(value);
    if (isNaN(amount)) return value;
    return (amount * 10000).toString();
};

const getUserStatusValue = (value: UserStatus.UserStatus, t: TFunction): string => {
    const values = UserStatus.UserStatus;
    switch (value) {
        case values.ACTIVE:
            return t('common.active');
        case values.DEACTIVATED:
            return t('common.deactivated');
        case values.ARCHIVED:
            return t('common.archived');
    }
};

export const getUserStatusOptions = (t: TFunction): DropdownItem[] => {
    let values = Object.keys(UserStatus.UserStatus);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value): DropdownItem => {
        const dropdownValue = getUserStatusValue(value as UserStatus.UserStatus, t);
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getTableStyleOverrides = (palette: ColorPalette): PaginatedViewStyleOverrides => ({
    borderRadius: '8px',
    background: palette.background['400'],
    margin: '32px',
    width: 'calc(100vw - 304px)',
    filterPadding: '0px'
});

export const getPhoneNumberWithCountryCode = (phoneCode: string, phoneNumber: string): string => {
    return `${phoneCode}${phoneNumber}`;
};

export const getPrefixIcon = (value: StatusIcon): string => {
    const transactionDetailPrefixIcon =
        TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon;
    const agentTransactionDetailPrefixIcon =
        AgentTransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon;
    switch (value) {
        case transactionDetailPrefixIcon.SUCCEEDED:
        case agentTransactionDetailPrefixIcon.SUCCEEDED:
            return successIcon;
        case transactionDetailPrefixIcon.PENDING:
        case transactionDetailPrefixIcon.AWAITING_RECIPIENT_COLLECTION:
        case agentTransactionDetailPrefixIcon.AWAITING_RECIPIENT_COLLECTION:
        case transactionDetailPrefixIcon.PROCESSING:
        case agentTransactionDetailPrefixIcon.PROCESSING:
            return pendingIcon;
        case transactionDetailPrefixIcon.REVERSED:
        case agentTransactionDetailPrefixIcon.REVERSED:
            return reversedIcon;
        case transactionDetailPrefixIcon.MOVED_OUT_OF_YAFIKA:
        case agentTransactionDetailPrefixIcon.MOVED_OUT_OF_YAFIKA:
            return movedOutIcon;
        case transactionDetailPrefixIcon.MOVED_TO_UNCLAIMED_FUNDS:
        case agentTransactionDetailPrefixIcon.MOVED_TO_UNCLAIMED_FUNDS:
            return unclaimedIcon;
        case transactionDetailPrefixIcon.REFUND_TO_SENDER_BLOCKED:
        case agentTransactionDetailPrefixIcon.REFUND_TO_SENDER_BLOCKED:
            return blockedIcon;
        case transactionDetailPrefixIcon.SUCCESSFULLY_REFUNDED_TO_SENDER:
        case agentTransactionDetailPrefixIcon.SUCCESSFULLY_REFUNDED_TO_SENDER:
            return refundedIcon;
        case transactionDetailPrefixIcon.UNCOLLECTED:
        case agentTransactionDetailPrefixIcon.UNCOLLECTED:
            return unCollectedIcon;
    }
};

// These functions are used in Fee and Transaction Rules hence minimumFactionDigits is 4
export const getRuleAmountStringWithoutCurrency = (number: number): string => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
    })
        .format(number)
        .toString();
};

export const getRuleFormattedAmountStringWithoutCurrency = (number: number): string => {
    const _number = number / 10000;
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4
    })
        .format(_number)
        .toString();
};

export const parseRuleDecimalAmount = (value: string): string => {
    if (value === '') return '';
    const _value = value.replace(',', '');
    const amount = Number(_value);
    if (isNaN(amount)) return value;
    const DECIMAL_PLACES = 4;
    const _amount = amount / 10000;
    const _updatedAmount =
        Math.round((_amount + Number.EPSILON) * 10 ** DECIMAL_PLACES) / 10 ** DECIMAL_PLACES;
    return getRuleAmountStringWithoutCurrency(_updatedAmount);
};

export const parseFormattedString = (value: string): string => {
    if (value === '') return '';
    const _value = value.replace(',', '');
    const amount = Number(_value);
    if (isNaN(amount)) return value;
    return getFormattedStringFromNumber(amount);
};

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
    // Parse the phone number string
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    // Check if the number is valid and set it to international format
    if (parsedNumber && parsedNumber.isValid()) {
        const formattedNumber = parsedNumber.formatInternational();
        return formattedNumber;
    } else {
        // Return the original input if the phone number is invalid or an error occurs
        return phoneNumber;
    }
};

export const getCountryNameFromCbsId = (
    list: CBSIdWithCountry[],
    selectedValue: string
): string => {
    const cbsId = list.find((val) => selectedValue.includes(val.cbsId));
    return cbsId?.country ?? '';
};

export const getCbsIdFromCountryName = (
    list: CBSIdWithCountry[],
    selectedValue: string
): string => {
    const cbsId = list.find((val) => selectedValue.includes(val.country));
    return cbsId?.cbsId ?? '';
};
