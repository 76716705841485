import { FileAttributes } from '@resolut-tech/bcn-rpcs';
import { FileAttributesEnums } from '@resolut-tech/bcn-rpcs/build/document/fileAttributes';
import axios from 'axios';
import { Instance } from 'mobx-state-tree';
import { LoggerStore } from '../../../../log/LoggerStore';
import { sha256 } from 'js-sha256';

export enum FileUploadError {
    MaxFileSizeExceeded = 'MaxFileSizeExceeded',
    MinFileSizeDidNotExceed = 'MinFileSizeDidNotExceed',
    CouldNotFetchUploadURL = 'CouldNotFetchUploadURL',
    InvalidFileFormat = 'InvalidFileFormat',
    UploadFailed = 'UploadFailed',
    UploadedFileIdNotFound = 'UploadedFileIdNotFound',
    InvalidFileName = 'InvalidFileName',
    InvalidFileSHA = 'InvalidFileSHA',
    InternalError = 'InternalError'
}

export const getFileAttribute = async (
    file: File,
    fileExtension: FileAttributesEnums.FileExtension.FileExtension,
    isRulesUpload: boolean,
    loggerStore: Instance<typeof LoggerStore>
): Promise<FileAttributes> => {
    if (isRulesUpload) {
        if (file.type !== 'text/csv' && file.type !== 'application/csv') {
            return Promise.reject(FileUploadError.InvalidFileFormat);
        }
    }
    const arrayBuffer = await file.arrayBuffer();
    const _sha256 = sha256(arrayBuffer);
    try {
        const fileAttribute = new FileAttributes(_sha256, file.name, fileExtension, file.size);
        return fileAttribute;
    } catch (error) {
        loggerStore.error(`Creation of File Attribute resulted in error: ${error}`);
        // Ideally the below validations should come from the RPC generated code itself. Since the RPC throws a common `InvalidFileAttributesError` error,
        // it becomes difficult for us to identify the exact issue. Hence the validations are done in the store as well.
        if (_sha256.length !== 64) {
            return Promise.reject(FileUploadError.InvalidFileSHA);
        } else if (file.name.length < 1 || file.name.length > 500) {
            return Promise.reject(FileUploadError.InvalidFileName);
        } else if (file.size > 5000000) {
            return Promise.reject(FileUploadError.MaxFileSizeExceeded);
        } else if (file.size < 100) {
            return Promise.reject(FileUploadError.MinFileSizeDidNotExceed);
        } else {
            return Promise.reject(FileUploadError.InternalError);
        }
    }
};

export const uploadFileToS3 = async (url: URL, file: File): Promise<void> => {
    try {
        await axios.put(url.href, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        return Promise.reject(FileUploadError.InternalError);
    }
};
