import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { Divider, Stack, styled, SxProps, Typography } from '@mui/material';
import { AmountDetailsRow } from './AmountDetailsRow';

interface TransferAmountDetailsProps {
    title?: string;
    label: string;
    text: string | null;
    sx?: SxProps;
    children?: React.ReactElement;
}
export const TransferAmountDetails = ({
    title,
    label,
    text,
    sx,
    children
}: TransferAmountDetailsProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    const StyledDivider = styled(Divider)(() => ({
        borderBottom: `2px dashed ${palette.outline[300]}`
    }));

    return (
        <Stack spacing="12px" sx={{ ...sx }}>
            <StyledDivider />
            {title && (
                <Typography sx={{ ...typography.small1, color: palette.label[300] }}>
                    {title}
                </Typography>
            )}
            <AmountDetailsRow label={label} text={text}>
                {children}
            </AmountDetailsRow>
            <StyledDivider />
        </Stack>
    );
};
