import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface RefundSummaryRowProps {
    label: string;
    text: string;
}

export const RefundSummaryRow = ({ label, text }: RefundSummaryRowProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    const StyledBox = styled(Box)(() => ({
        width: '120px',
        height: '24px',
        paddingRight: '16px'
    }));

    return (
        <Stack>
            <Stack direction="row" sx={{ padding: '12px 0px' }}>
                <StyledBox>
                    <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                        {label}
                    </Typography>
                </StyledBox>
                <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                    {text}
                </Typography>
            </Stack>
            <Divider />
        </Stack>
    );
};
