import { Table, TableHeader, TableRowItems, usePalette } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeeDeterminationRuleModel } from '../models/FeeDeterminationRuleModel';
import {
    getCountryNameFromCbsId,
    getRuleFormattedAmountStringWithoutCurrency
} from '../../common/utils/UIUtils';
import { useViewFeeRulesStore } from '../store/hooks';

interface ViewCurrentFeeDeterminationRulesProps {
    rules: Instance<typeof FeeDeterminationRuleModel>[];
}

export const ViewCurrentFeeDeterminationRules = ({
    rules
}: ViewCurrentFeeDeterminationRulesProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useViewFeeRulesStore();

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'slNo',
                name: t('rulesCSVHeaders.slNo'),
                width: '80px'
            },
            {
                id: 'transactionType',
                name: t('rulesCSVHeaders.feeRules.transactionType'),
                width: '200px'
            },
            {
                id: 'amountRangeLow',
                name: t('rulesCSVHeaders.feeRules.amountRangeLow'),
                width: '144px',
                align: 'right'
            },
            {
                id: 'amountRangeHigh',
                name: t('rulesCSVHeaders.feeRules.amountRangeHigh'),
                width: '144px',
                align: 'right'
            },
            {
                id: 'cbsId',
                name: t('common.cbs'),
                width: '120px',
                ellipsisContent: true
            },
            {
                id: 'feeType',
                name: t('rulesCSVHeaders.feeRules.feeType'),
                width: '160px'
            },
            {
                id: 'percentage',
                name: t('rulesCSVHeaders.feeRules.percentage'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'upperBound',
                name: t('rulesCSVHeaders.feeRules.upperBound'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'lowerBound',
                name: t('rulesCSVHeaders.feeRules.lowerBound'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'flatFee',
                name: t('rulesCSVHeaders.feeRules.flatFee'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'bankFeePercentage',
                name: t('rulesCSVHeaders.feeRules.bankFeePercentage'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'counterPartyFeePercentage',
                name: t('rulesCSVHeaders.feeRules.counterPartyFeePercentage'),
                width: '120px',
                align: 'right'
            },
            {
                id: 'agentCommissionPercentage',
                name: t('rulesCSVHeaders.feeRules.agentCommissionPercentage'),
                width: '120px',
                align: 'right'
            }
        ];
    };

    const getTableContents = async (): Promise<string | TableRowItems> => {
        return rules.map((rule, index) => {
            return [
                { data: index + 1 },
                { data: rule.transactionType },
                {
                    data: rule.amountRangeLow
                        ? getRuleFormattedAmountStringWithoutCurrency(rule.amountRangeLow)
                        : '-',
                    align: 'right'
                },
                {
                    data: rule.amountRangeHigh
                        ? getRuleFormattedAmountStringWithoutCurrency(rule.amountRangeHigh)
                        : '-',
                    align: 'right'
                },
                {
                    data: getCountryNameFromCbsId(store.getCbsIdWithCountryList(), rule.cbsId)
                },
                { data: rule.feeType },
                { data: rule.percentage ?? '-', align: 'right' },
                {
                    data: rule.upperBound
                        ? getRuleFormattedAmountStringWithoutCurrency(rule.upperBound)
                        : '-',
                    align: 'right'
                },
                {
                    data: rule.lowerBound
                        ? getRuleFormattedAmountStringWithoutCurrency(rule.lowerBound)
                        : '-',
                    align: 'right'
                },
                {
                    data: rule.flatFee
                        ? getRuleFormattedAmountStringWithoutCurrency(rule.flatFee)
                        : '-',
                    align: 'right'
                },
                { data: rule.bankFeePercentage ?? '-', align: 'right' },
                { data: rule.counterPartyFeePercentage ?? '-', align: 'right' },
                { data: rule.agentCommissionPercentage ?? '-', align: 'right' }
            ];
        });
    };

    return (
        <Table
            name="ViewCurrentFeeDeterminationRulesTable"
            styleOverrides={{ background: palette.background['400'], width: 'calc(100vw - 304px)' }}
            headers={getHeaders()}
            onTableOptionsChange={getTableContents}
            viewOverrides={{ empty: { message: t('common.noCurrentRulesFound') } }}
        />
    );
};
