interface Time {
    hours: number;
    minutes: number;
    seconds: number;
}

// This function calculates hours, minutes and seconds between the passed date and the current date
export const getTimeFrom = (date: Date): Time => {
    const currentDate = new Date();

    const d = Math.abs(date.valueOf() - currentDate.valueOf());
    const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    const days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    const minutes = Math.floor(
        d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    const seconds = Math.floor(
        d / 1000 -
            weekdays * 7 * 24 * 60 * 60 -
            days * 24 * 60 * 60 -
            hours * 60 * 60 -
            minutes * 60
    );
    return { hours, minutes, seconds };
};

// This function returns the minutes and seconds into a {minutes}:{seconds} format
export const getMinutesAndSecondsString = (time: Time): string => {
    let minuteString = '00';
    let secondsString = '00';
    if (time.minutes > 9) {
        minuteString = `${time.minutes}`;
    } else {
        minuteString = `0${time.minutes}`;
    }
    if (time.seconds > 9) {
        secondsString = `${time.seconds}`;
    } else {
        secondsString = `0${time.seconds}`;
    }
    return `${minuteString}:${secondsString}`;
};
