import React from 'react';
import { useTypography, usePalette } from '@surya-digital/leo-reactjs-ui';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { getDateString } from '../../common/utils/DateUtils';
import { parseFormattedString } from '../../common/utils/UIUtils';

export interface GraphData {
    date: Date;
    value: number;
}

export interface ReportsGraphProps {
    title: string;
    seriesName: string;
    dataList: GraphData[];
}

export const ReportsGraphComponent = ({
    seriesName,
    dataList
}: ReportsGraphProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();

    const getFormattedValue = (value: number, name: string): string => {
        if (name === '') {
            return parseFormattedString(value.toString());
        } else {
            return `${value} ` + name;
        }
    };

    const chartData: ApexOptions = {
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 2,
            colors: palette.primary[300],
            strokeWidth: 0,
            hover: {
                sizeOffset: 2
            }
        },
        stroke: {
            width: 1
        },
        colors: [palette.primary[300]],
        chart: {
            type: 'line',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            type: 'datetime',
            tickPlacement: 'between',
            labels: {
                format: 'MMM dd',
                rotate: 0,
                showDuplicates: false
            },
            axisBorder: {
                color: palette.label[200],
                show: true
            },
            axisTicks: {
                show: true
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            axisTicks: {
                width: 5
            }
        },
        grid: {
            borderColor: palette.outline[300],
            strokeDashArray: 10,
            xaxis: {
                lines: {
                    show: false
                }
            },
            padding: {
                left: 20,
                right: 40
            }
        },
        tooltip: {
            custom: ({ _, seriesIndex, dataPointIndex, w }) => {
                const date = w.globals.initialSeries[seriesIndex].data[dataPointIndex].x;
                const value = w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
                const formattedDate = getDateString(date);
                //since shadows are not present in Leo hence we have hardcoded RGBA value here for  box-shadow.
                return (
                    `<div style="
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        width: 120px;
                        height: 72px;
                        border: 1px solid ${palette.outline[100]};
                        box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
                        border-radius: 8px;
                    ">` +
                    `<div style="
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 8px;
                        background: ${palette.background[300]};
                    ">` +
                    `<div style="
                        font-family: ${typography.small3.fontFamily};
                        letter-spacing: ${typography.small3.letterSpacing};
                        font-weight: ${typography.small3.fontWeight};
                        font-size: ${typography.small3.fontSize};
                        line-height: ${typography.small3.lineHeight};
                        color: ${palette.label[300]};
                    ">` +
                    formattedDate +
                    '</div>' +
                    '</div>' +
                    `<div style="
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        height: 36px;
                        padding: 8px;
                        background: ${palette.background[400]};
                    ">` +
                    `<div style="
                        font-family: ${typography.body3.fontFamily};
                        letter-spacing: ${typography.body3.letterSpacing};
                        font-weight: ${typography.body3.fontWeight};
                        font-size: ${typography.body3.fontSize};
                        line-height: ${typography.body3.lineHeight};
                        color: ${palette.label[300]};
                    ">` +
                    `${getFormattedValue(value, seriesName)}` +
                    '</div>' +
                    '</div>' +
                    '</div>'
                );
            }
        },
        series: [
            {
                name: 't',
                data: dataList.map((data) => ({ x: data.date, y: data.value }))
            }
        ]
    };

    return <ReactApexChart height="308px" options={chartData} series={chartData.series} />;
};
