import {
    BOName,
    BOUserComment,
    GetUnclaimedFundsRequestSummaryRPC,
    RequestDetails,
    UserName,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetUnclaimedFundsRequestSummaryRPCImpl extends GetUnclaimedFundsRequestSummaryRPC {
    execute(
        _request: GetUnclaimedFundsRequestSummaryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetUnclaimedFundsRequestSummaryRPC.Response,
            GetUnclaimedFundsRequestSummaryRPC.Errors.Errors
        >
    > {
        const response = new GetUnclaimedFundsRequestSummaryRPC.Response(
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID('a0bf3a95-e385-4d0c-92bd-92961f13f5fa'),
                    new UserName(new BOName('Jane'), new BOName('Cooper')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('Money needs to be sent to CBS account.')
                )
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetUnclaimedFundsRequestSummaryRPC.Response,
                GetUnclaimedFundsRequestSummaryRPC.Errors.Errors
            >
        >;
    }
}
