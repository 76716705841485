import { Stack, SxProps, Typography } from '@mui/material';
import { Checkbox, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface AmountDetailsRowProps {
    label: string;
    text: string | null;
    showCheckbox?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    onSelectionChanged?: (value: boolean) => void;
    sx?: SxProps;
    children?: React.ReactElement;
}

export const AmountDetailsRow = ({
    label,
    text,
    showCheckbox = false,
    isSelected = false,
    isDisabled = false,
    onSelectionChanged,
    sx,
    children
}: AmountDetailsRowProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Stack direction="row" sx={{ justifyContent: 'space-between', ...sx }}>
            {showCheckbox ? (
                <Checkbox
                    checked={isSelected}
                    label={label}
                    isDisabled={isDisabled}
                    onChange={onSelectionChanged}
                    style={{ paddingLeft: '10px' }}
                />
            ) : (
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {label}
                    </Typography>
                    {children}
                </Stack>
            )}
            {text && (
                <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                    {text}
                </Typography>
            )}
        </Stack>
    );
};
