/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
// Search is not made for the receiver's mobile number or the credited account id if the nullinh-coalesing is used while filtering the Transactions list.
import { LeoPhoneNumber, LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BackOfficeTransaction,
    GetTransactionSearchResultsRPC,
    TransactionType
} from '@resolut-tech/bcn-rpcs';

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];

const TransactionList: BackOfficeTransaction[] = [
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('70127105-fa9a-4993-9f4f-eeb03d4599a7'),
        TransactionType.TransactionType.BILL_PAYMENT,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('9ae9a224-a55d-492c-8562-3067e949b81f'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('75cb3775-8093-4797-bea6-3d0f5958acb8')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('d1d0c0f2-b5d7-4a43-81ae-6835e995b315'),
        TransactionType.TransactionType.WITHIN_YAFIKA,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('28a59492-36f4-4b73-8375-a0569ef8e990'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('5aec7805-247d-4ffa-aa76-d40a8afb8d71')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('cea7ad84-baba-4263-abdc-80c293dc62a4'),
        TransactionType.TransactionType.WALLET_TO_WALLET,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('b5e3a712-fb87-4906-8d3b-606162e8674f'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('28b22f53-2649-46f0-a526-b2d5b781c526')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('98bcb50f-d97e-4acc-b1ea-f718e630d166'),
        TransactionType.TransactionType.YAFIKA_TO_EXTERNAL,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('a8413337-108a-49e0-9f94-d8b984cbaacd'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('5cae341b-5831-4732-8b02-bae2f619be72')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('ac806b1c-7a8e-4645-9c4e-1322370fac24'),
        TransactionType.TransactionType.LOAD_WALLET,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('9a051938-3f88-4569-9356-544a4d022dc0'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('f211bd47-1ae3-4c56-8d16-7da8e5731248')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('efe47f65-6eb9-4ec7-a74e-fed60831acd6'),
        TransactionType.TransactionType.EXTERNAL_TO_YAFIKA,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('22824c93-481c-414c-ae41-5fa580f446ee'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('dd9e6c5d-ba47-4ed8-8e0a-8d027a75e5e6')
    ),
    new BackOfficeTransaction(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID('f2f66eea-7ae9-438e-a387-3430bba5755c'),
        TransactionType.TransactionType.BILL_PAYMENT,
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265995118354'),
        new LeoUUID('1c8ffd2b-83a0-4151-849c-1c4422fdb913'),
        `${firstName[getRandomInt(4)]} ${lastName[getRandomInt(4)]}`,
        new LeoPhoneNumber('+265888912552'),
        new LeoUUID('fea54b2a-50f0-49c2-b2b4-2ce7eb7ecb4d')
    )
];

export class MockGetTransactionsSearchResultsRPCImpl extends GetTransactionSearchResultsRPC {
    execute(
        _request: GetTransactionSearchResultsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetTransactionSearchResultsRPC.Response,
            GetTransactionSearchResultsRPC.Errors.Errors
        >
    > {
        const response = new GetTransactionSearchResultsRPC.Response(
            TransactionList.length,
            TransactionList
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetTransactionSearchResultsRPC.Response,
                GetTransactionSearchResultsRPC.Errors.Errors
            >
        >;
    }
}
