import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import { AmountModel } from '../../common/models/AmountModel';
import { SectionRowCellEnum } from '../../common/types/SectionRowComponentTypes';
import { getAmountString } from '../../common/utils/UIUtils';

export const AmountMoveOutOfYafikaDetails = ({
    amount
}: {
    amount: Instance<typeof AmountModel>;
}): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box width="342px">
            <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                {t('agencyBanking.amountTransferringToOperatingAccount')}
            </Typography>

            <SectionRowComponentWithLabel
                variant="dialog"
                label={t('common.amount')}
                cell={{ type: SectionRowCellEnum.Unstyled, text: getAmountString(amount) }}
            />
        </Box>
    );
};
