import * as React from 'react';

export const Clock = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2.50016C5.8579 2.50016 2.50004 5.85803 2.50004 10.0002C2.50004 14.1423 5.8579 17.5002 10 17.5002C14.1422 17.5002 17.5 14.1423 17.5 10.0002C17.5 5.85803 14.1422 2.50016 10 2.50016ZM0.833374 10.0002C0.833374 4.93755 4.93743 0.833496 10 0.833496C15.0626 0.833496 19.1667 4.93755 19.1667 10.0002C19.1667 15.0628 15.0626 19.1668 10 19.1668C4.93743 19.1668 0.833374 15.0628 0.833374 10.0002Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99998 4.1665C10.4602 4.1665 10.8333 4.5396 10.8333 4.99984V9.99984C10.8333 10.3155 10.655 10.604 10.3727 10.7452L7.03932 12.4119C6.62767 12.6177 6.12711 12.4508 5.92129 12.0392C5.71547 11.6275 5.88232 11.127 6.29397 10.9211L9.16665 9.48481V4.99984C9.16665 4.5396 9.53974 4.1665 9.99998 4.1665Z"
                fill="currentColor"
            />
        </svg>
    );
};
