import { Instance, types } from 'mobx-state-tree';
import { CBSRequestDetailsStore, createCBSRequestDetailsStore } from './CBSRequestDetailsStore';
import {
    CheckCBSTransferRequestStore,
    createCheckCBSTransferRequestStore
} from './CheckCBSTransferRequestStore';
import {
    createDownloadUnclaimedFundsRequestStore,
    DownloadUnclaimedFundsRequestStore
} from './DownloadUnclaimedFundsRequestStore';
import { createRequestCBSTransferStore, RequestCBSTransferStore } from './RequestCBSTransferStore';
import { createUnclaimedFundsListStore, UnclaimedFundsListStore } from './UnclaimedFundsListStore';
import {
    createUnclaimedFundsRequestHistoryStore,
    UnclaimedFundsRequestHistoryStore
} from './UnclaimedFundsRequestHistoryStore';
import {
    createUnclaimedTransactionDetailsStore,
    UnclaimedTransactionDetailsStore
} from './UnclaimedTransactionDetailsStore';
import {
    createWithdrawCBSTransferStore,
    WithdrawCBSTransferStore
} from './WithdrawCBSTransferStore';

export const UnclaimedFundsStore = types.model({
    unclaimedFundsListStore: UnclaimedFundsListStore,
    requestCBSTransferStore: RequestCBSTransferStore,
    unclaimedTransactionDetailsStore: UnclaimedTransactionDetailsStore,
    cbsRequestDetailsStore: CBSRequestDetailsStore,
    downloadUnclaimedFundsRequestStore: DownloadUnclaimedFundsRequestStore,
    withdrawCBSTransferStore: WithdrawCBSTransferStore,
    unclaimedFundsRequestHistoryStore: UnclaimedFundsRequestHistoryStore,
    checkCBSTransferRequestStore: CheckCBSTransferRequestStore
});

export const createUnclaimedFundsStore = (): Instance<typeof UnclaimedFundsStore> => {
    return UnclaimedFundsStore.create({
        unclaimedFundsListStore: createUnclaimedFundsListStore(),
        requestCBSTransferStore: createRequestCBSTransferStore(),
        unclaimedTransactionDetailsStore: createUnclaimedTransactionDetailsStore(),
        cbsRequestDetailsStore: createCBSRequestDetailsStore(),
        downloadUnclaimedFundsRequestStore: createDownloadUnclaimedFundsRequestStore(),
        withdrawCBSTransferStore: createWithdrawCBSTransferStore(),
        unclaimedFundsRequestHistoryStore: createUnclaimedFundsRequestHistoryStore(),
        checkCBSTransferRequestStore: createCheckCBSTransferRequestStore()
    });
};
