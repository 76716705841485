import { RequestDetails, IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

import { BOUserCommentModel, getBOUserCommentModel } from './BOUserCommentModel';
import { getWithdrawRequestModel, WithdrawRequestModel } from './WithdrawRequestModel';

export const RequestDetailsModel = types.model({
    status: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    ),
    evaluatorComment: types.maybeNull(BOUserCommentModel),
    requesterComment: types.maybeNull(BOUserCommentModel),
    withdrawalComment: types.maybeNull(WithdrawRequestModel),
    requestId: types.string
});

export const getRequestDetailsModel = (
    requestDetails: RequestDetails.RequestDetails
): Instance<typeof RequestDetailsModel> => {
    let evaluatorComment: Instance<typeof BOUserCommentModel> | null = null;
    let requesterComment: Instance<typeof BOUserCommentModel> | null = null;
    let withdrawalComment: Instance<typeof WithdrawRequestModel> | null = null;
    let status = IntermediateRequestStatus.IntermediateRequestStatus.PENDING;
    if (
        requestDetails instanceof RequestDetails.Approved ||
        requestDetails instanceof RequestDetails.Denied
    ) {
        status =
            requestDetails instanceof RequestDetails.Approved
                ? IntermediateRequestStatus.IntermediateRequestStatus.APPROVED
                : IntermediateRequestStatus.IntermediateRequestStatus.DENIED;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof RequestDetails.Pending) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.PENDING;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof RequestDetails.Discarded) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
        evaluatorComment = requestDetails.checkerComment
            ? getBOUserCommentModel(requestDetails.checkerComment)
            : null;
    }
    if (requestDetails instanceof RequestDetails.Withdrawn) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN;
        withdrawalComment = getWithdrawRequestModel(
            requestDetails.withdrawalComment,
            requestDetails.makerComment.text,
            requestDetails.requestRaisedAt
        );
    }

    if (requestDetails instanceof RequestDetails.Processing) {
        status = IntermediateRequestStatus.IntermediateRequestStatus.PROCESSING;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }

    return RequestDetailsModel.create({
        status,
        withdrawalComment,
        requesterComment,
        evaluatorComment,
        requestId: requestDetails.requestId.uuid
    });
};
