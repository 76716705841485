import { Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { useRefundHistoryStore } from '../store/hooks';
import { RefundHistoryErrors } from '../store/RefundHistoryStore';
import { TransactionRefundSummary } from './TransactionRefundSummary';

export const RefundHistorySection = ({
    transactionId
}: {
    transactionId: string | null;
}): React.ReactElement => {
    const { t } = useTranslation();
    const store = useRefundHistoryStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function fetchRefundHistoryRequests(): Promise<string | React.ReactElement[]> {
        if (transactionId) {
            await store.fetchRefundHistory(transactionId!);
            if (store.error) {
                switch (store.error) {
                    case RefundHistoryErrors.InvalidTransactionID:
                        return t('common.somethingWentWrongProcessingRequest');
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        return t('common.somethingWentWrongProcessingRequest');
                    default:
                        return t('common.somethingWentWrongProcessingRequest');
                }
            } else if (store.requests.length > 0) {
                return [
                    <Stack key="refundHistoryRequests">
                        {store.requests.map((request, index) => {
                            return (
                                <RequestDetailsSection
                                    key={index}
                                    requestDetails={request.requestDetails!}
                                    isSectionItem={true}>
                                    <TransactionRefundSummary
                                        baseAmount={request.refundSummary.baseAmount}
                                        bankFee={request.refundSummary.bankFee}
                                        additionalFee={request.refundSummary.additionalFee}
                                    />
                                </RequestDetailsSection>
                            );
                        })}
                    </Stack>
                ];
            } else {
                return [];
            }
        } else {
            return t('common.errorMessage');
        }
    }

    return (
        <>
            <Section
                title={t('transaction.refundHistory')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchRefundHistoryRequests}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </>
    );
};
