import {
    CheckFeeDeterminationRuleRequestRPC,
    CheckFeeDeterminationRuleRequestRPCClientImpl,
    GetBOPresignedDocumentUploadURLForFeeRulesRPC,
    GetBOPresignedDocumentUploadURLForFeeRulesRPCClientImpl,
    GetBOPresignedDownloadURLForFeeRulesRPC,
    GetBOPresignedDownloadURLForFeeRulesRPCClientImpl,
    GetBOPresignedDownloadURLForFeeRulesTemplateRPC,
    GetBOPresignedDownloadURLForFeeRulesTemplateRPCClientImpl,
    GetCountryAndCBSIdListForFDRRPC,
    GetCountryAndCBSIdListForFDRRPCClientImpl,
    GetCurrentFeeDeterminationRuleDetailsFileRPC,
    GetCurrentFeeDeterminationRuleDetailsFileRPCClientImpl,
    GetCurrentFeeDeterminationRuleDetailsRPC,
    GetCurrentFeeDeterminationRuleDetailsRPCClientImpl,
    GetFeeDeterminationRuleDiffRPC,
    GetFeeDeterminationRuleDiffRPCClientImpl,
    GetFeeDeterminationRuleHistoryRequestDetailsRPC,
    GetFeeDeterminationRuleHistoryRequestDetailsRPCClientImpl,
    GetFeeDeterminationRuleRequestDetailsRPC,
    GetFeeDeterminationRuleRequestDetailsRPCClientImpl,
    GetFeeDeterminationRuleRequestHistoryRPC,
    GetFeeDeterminationRuleRequestHistoryRPCClientImpl,
    RequestFeeDeterminationRuleUpdateRPC,
    RequestFeeDeterminationRuleUpdateRPCClientImpl,
    WithdrawFeeDeterminationRuleRequestRPC,
    WithdrawFeeDeterminationRuleRequestRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockGetBOPresignedDownloadURLForFeeRulesRPCClientImpl } from './MockGetBOPresignedDownloadURLForFeeRulesRPCImpl';
import { MockWithdrawFeeDeterminationRuleRequestRPCImpl } from './MockWithdrawFeeDeterminationRuleRequestRPCImpl';
import { MockGetBOPresignedDocumentUploadURLForFeeRulesRPCImpl } from './MockGetBOPresignedDocumentUploadURLForFeeRulesRPCImpl';
import { MockGetBOPresignedDownloadURLForFeeRulesTemplateRPCImpl } from './MockGetBOPresignedDownloadURLForFeeRulesTemplateRPCImpl';
import { APIClient } from '@surya-digital/tedwig';
import { MockGetFeeDeterminationRuleRequestDetailsRPCImpl } from './MockGetFeeDeterminationRuleRequestDetailsRPCImpl';
import { MockRequestFeeDeterminationRuleUpdateRPCImpl } from './MockRequestFeeDeterminationRuleUpdateRPCImpl';
import { MockGetFeeDeterminationRuleDiffRPCImpl } from './MockGetFeeDeterminationRuleDiffRPCImpl';
import { MockCheckFeeDeterminationRuleRequestRPCImpl } from './MockCheckFeeDeterminationRuleRequestRPCImpl';
import { MockGetCurrentFeeDeterminationRuleDetailsFileRPCImpl } from './MockGetCurrentFeeDeterminationRuleDetailsFileRPCImpl';
import { MockGetCurrentFeeDeterminationRuleDetailsRPCImpl } from './MockGetCurrentFeeDeterminationRuleDetailsRPCImpl';
import { MockGetFeeDeterminationRuleHistoryRequestDetailsRPCImpl } from './MockGetFeeDeterminationRuleHistoryRequestDetailsRPCIMpl';
import { MockGetFeeDeterminationRuleRequestHistoryRPCImpl } from './MockGetFeeDeterminationRuleRequestHistoryRPCImpl';
import { MockGetCountryAndCBSIdListForFDRRPCImpl } from './MockGetCountryAndCBSIdListForFDRRPCImpl';

const MOCK = false;

export const useRequestFeeRuleUpdateRPCClient = (
    apiClient: APIClient
): RequestFeeDeterminationRuleUpdateRPC => {
    if (MOCK) {
        return new MockRequestFeeDeterminationRuleUpdateRPCImpl();
    } else {
        return new RequestFeeDeterminationRuleUpdateRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDownloadURLForFeeRulesRPCClient = (
    apiClient: APIClient
): GetBOPresignedDownloadURLForFeeRulesRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDownloadURLForFeeRulesRPCClientImpl();
    } else {
        return new GetBOPresignedDownloadURLForFeeRulesRPCClientImpl(apiClient);
    }
};

export const useWithdrawFeeRulesRequestRPCClient = (
    apiClient: APIClient
): WithdrawFeeDeterminationRuleRequestRPC => {
    if (MOCK) {
        return new MockWithdrawFeeDeterminationRuleRequestRPCImpl();
    } else {
        return new WithdrawFeeDeterminationRuleRequestRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDocumentUploadURLForFeeRulesRPCClient = (
    apiClient: APIClient
): GetBOPresignedDocumentUploadURLForFeeRulesRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDocumentUploadURLForFeeRulesRPCImpl();
    } else {
        return new GetBOPresignedDocumentUploadURLForFeeRulesRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDownloadURLForFeeRulesTemplateRPCClient = (
    apiClient: APIClient
): GetBOPresignedDownloadURLForFeeRulesTemplateRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDownloadURLForFeeRulesTemplateRPCImpl();
    } else {
        return new GetBOPresignedDownloadURLForFeeRulesTemplateRPCClientImpl(apiClient);
    }
};

export const useGetFeeRuleRequestHistoryRPCClient = (
    apiClient: APIClient
): GetFeeDeterminationRuleRequestHistoryRPC => {
    if (MOCK) {
        return new MockGetFeeDeterminationRuleRequestHistoryRPCImpl();
    } else {
        return new GetFeeDeterminationRuleRequestHistoryRPCClientImpl(apiClient);
    }
};

export const useGetFeeDeterminationRuleDiffRPCClient = (
    apiClient: APIClient
): GetFeeDeterminationRuleDiffRPC => {
    if (MOCK) {
        return new MockGetFeeDeterminationRuleDiffRPCImpl();
    } else {
        return new GetFeeDeterminationRuleDiffRPCClientImpl(apiClient);
    }
};

export const useGetFeeDeterminationRuleRequestDetailsRPCClient = (
    apiClient: APIClient
): GetFeeDeterminationRuleRequestDetailsRPC => {
    if (MOCK) {
        return new MockGetFeeDeterminationRuleRequestDetailsRPCImpl();
    } else {
        return new GetFeeDeterminationRuleRequestDetailsRPCClientImpl(apiClient);
    }
};

export const useCheckFeeRuleRequestRPCClient = (
    apiClient: APIClient
): CheckFeeDeterminationRuleRequestRPC => {
    if (MOCK) {
        return new MockCheckFeeDeterminationRuleRequestRPCImpl();
    } else {
        return new CheckFeeDeterminationRuleRequestRPCClientImpl(apiClient);
    }
};

export const useGetCurrentFeeRuleDetailsFileRPCClient = (
    apiClient: APIClient
): GetCurrentFeeDeterminationRuleDetailsFileRPC => {
    if (MOCK) {
        return new MockGetCurrentFeeDeterminationRuleDetailsFileRPCImpl();
    } else {
        return new GetCurrentFeeDeterminationRuleDetailsFileRPCClientImpl(apiClient);
    }
};

export const useGetCurrentFeeDeterminationRuleDetailsRPCClient = (
    apiClient: APIClient
): GetCurrentFeeDeterminationRuleDetailsRPC => {
    if (MOCK) {
        return new MockGetCurrentFeeDeterminationRuleDetailsRPCImpl();
    } else {
        return new GetCurrentFeeDeterminationRuleDetailsRPCClientImpl(apiClient);
    }
};

export const useGetFeeDeterminationRuleHistoryRequestDetailsRPCClient = (
    apiClient: APIClient
): GetFeeDeterminationRuleHistoryRequestDetailsRPC => {
    if (MOCK) {
        return new MockGetFeeDeterminationRuleHistoryRequestDetailsRPCImpl();
    } else {
        return new GetFeeDeterminationRuleHistoryRequestDetailsRPCClientImpl(apiClient);
    }
};

export const useGetCountryAndCBSIdListForFDRRPCClient = (
    apiClient: APIClient
): GetCountryAndCBSIdListForFDRRPC => {
    if (MOCK) {
        return new MockGetCountryAndCBSIdListForFDRRPCImpl();
    } else {
        return new GetCountryAndCBSIdListForFDRRPCClientImpl(apiClient);
    }
};
