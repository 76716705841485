import {
    GetTransactionValidationRuleRequestHistoryPaginationResponse,
    GetTransactionValidationRuleRequestHistoryRPC,
    PGUserName,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const requestList: GetTransactionValidationRuleRequestHistoryPaginationResponse[] = [
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.APPROVED,
        new PGUserName('Eleanor', 'Pena'),
        new PGUserName('Arlene', 'McCoy'),
        new LeoUUID()
    ),
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.APPROVED,
        new PGUserName('Eleanor', 'Pena'),
        new PGUserName('Arlene', 'McCoy'),
        new LeoUUID()
    ),
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.PENDING,
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.PENDING,
        new PGUserName('Eleanor', 'Pena'),
        null,
        new LeoUUID()
    ),
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.DISCARDED,
        new PGUserName('Eleanor', 'Pena'),
        new PGUserName('Arlene', 'McCoy'),
        new LeoUUID()
    ),
    new GetTransactionValidationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.DENIED,
        new PGUserName('Eleanor', 'Pena'),
        new PGUserName('Arlene', 'McCoy'),
        new LeoUUID()
    )
];

export class MockGetTransactionValidationRuleRequestHistoryRPCImpl extends GetTransactionValidationRuleRequestHistoryRPC {
    execute(
        request: GetTransactionValidationRuleRequestHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetTransactionValidationRuleRequestHistoryRPC.Response,
            GetTransactionValidationRuleRequestHistoryRPC.Errors.Errors
        >
    > {
        const list = requestList.filter((viewRequest) => {
            if (request.requestStatus) {
                return viewRequest.requestStatus === request.requestStatus;
            } else {
                return viewRequest;
            }
        });
        const response = new GetTransactionValidationRuleRequestHistoryRPC.Response(
            list,
            list.length
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetTransactionValidationRuleRequestHistoryRPC.Response,
                GetTransactionValidationRuleRequestHistoryRPC.Errors.Errors
            >
        >;
    }
}
