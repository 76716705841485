import { Amount, Currency, GetMakerUnclaimedTransactionInfoRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetMakerUnclaimedTransactionInfoRPCImpl extends GetMakerUnclaimedTransactionInfoRPC {
    execute(
        _request: GetMakerUnclaimedTransactionInfoRPC.Request
    ): Promise<
        LeoRPCResult<
            GetMakerUnclaimedTransactionInfoRPC.Response,
            GetMakerUnclaimedTransactionInfoRPC.Errors.Errors
        >
    > {
        const response = new GetMakerUnclaimedTransactionInfoRPC.Response(
            25,
            new Amount(50000000, new Currency('MWK'))
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetMakerUnclaimedTransactionInfoRPC.Response,
                GetMakerUnclaimedTransactionInfoRPC.Errors.Errors
            >
        >;
    }
}
