import { BackOfficeChangePasswordRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockBackOfficeChangePasswordRPC extends BackOfficeChangePasswordRPC {
    execute(
        _request: BackOfficeChangePasswordRPC.Request
    ): Promise<
        LeoRPCResult<
            BackOfficeChangePasswordRPC.Response,
            BackOfficeChangePasswordRPC.Errors.Errors
        >
    > {
        const response = new BackOfficeChangePasswordRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                BackOfficeChangePasswordRPC.Response,
                BackOfficeChangePasswordRPC.Errors.Errors
            >
        >;
    }
}
