import { Box, Stack, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { SectionRowCellType } from '../types/SectionRowComponentTypes';
import { SectionRowComponent } from './SectionRowComponent';

export interface SectionRowComponentWithLabelProp {
    label: string;
    cell: SectionRowCellType;
    variant?: 'section' | 'dialog'; // This component is also used inside a dialog, where some padding is different, hence variant is added.
}

export const SectionRowComponentWithLabel = ({
    label,
    cell,
    variant = 'section'
}: SectionRowComponentWithLabelProp): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const width = variant === 'dialog' ? '120px' : '240px';

    const StyledBox = styled(Box)(() => ({
        width,
        height: '24px',
        paddingRight: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        alignSelf: 'center'
    }));

    return (
        <Stack
            direction="row"
            sx={{
                paddingRight: variant === 'dialog' ? '0' : '16px',
                paddingLeft: variant === 'dialog' ? '0' : '16px',
                borderBottom: variant === 'dialog' ? `1px solid ${palette.outline[200]}` : 'none'
            }}>
            <StyledBox>
                <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                    {label}
                </Typography>
            </StyledBox>
            <Box width={`calc(100% -  ${width})`} alignSelf="center">
                <SectionRowComponent {...cell} />
            </Box>
        </Stack>
    );
};
