import styled from '@emotion/styled';
import { Divider, Stack, Typography } from '@mui/material';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AmountModel } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { Dialog } from '../../../common/components/Dialog';

interface MoveFundsOutOfYafikaRequestDialogProps {
    dialogTitle: string;
    comment: string;
    amount: Instance<typeof AmountModel>;
    isDialogOpen: boolean;
    onDialogClose: () => void;
    primaryButtonText: string;
    showWarningMessage: boolean;
    onPrimaryButtonClick: () => Promise<void>;
    setComment: (comment: string) => void;
}

export const MoveFundsOutOfYafikaRequestDialog = ({
    dialogTitle,
    primaryButtonText,
    comment,
    amount,
    isDialogOpen,
    onDialogClose,
    onPrimaryButtonClick,
    setComment,
    showWarningMessage
}: MoveFundsOutOfYafikaRequestDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    const StyledDivider = styled(Divider)(() => ({
        borderBottom: `2px dashed ${palette.outline[300]}`
    }));

    return (
        <Dialog
            open={isDialogOpen}
            title={dialogTitle}
            primaryButtonText={primaryButtonText}
            onPrimaryButtonClick={onPrimaryButtonClick}
            isPrimaryButtonDisabled={!comment}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onDialogClose}
            disableBackdropClick>
            <Stack spacing="20px">
                <Stack>
                    <Typography sx={{ ...typography.small1, color: palette.label[300] }}>
                        {showWarningMessage
                            ? t('agencyBanking.amountWillTransferToOperatingAccount')
                            : t('agencyBanking.amountTransferringToOperatingAccount')}
                    </Typography>
                    {showWarningMessage && (
                        <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                            {t('agencyBanking.amountWillTransferToOperatingAccountMessage')}
                        </Typography>
                    )}
                </Stack>
                <StyledDivider />
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {t('common.amount')}
                    </Typography>
                    <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                        {getAmountString(amount)}
                    </Typography>
                </Stack>
                <StyledDivider />
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={comment}
                    onTextChange={setComment}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            </Stack>
        </Dialog>
    );
};
