import { useRequestToReactivateOrDeactivateBCNUserRPCClient } from './../rpcs/RPC';
import {
    Comment,
    RequestToArchiveBCNUserWithBalanceRPC,
    RequestToArchiveBCNUserRPC,
    RequestToReactivateOrDeactivateBCNUserRPC,
    UserStatusTransition
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    useRequestToArchiveBCNUserRPCClient,
    useRequestToArchiveBCNUserWithBalanceRPCClient
} from '../rpcs/RPC';
import {
    getNonZeroAccountDetailsModel,
    NonZeroAccountDetailsModel
} from '../models/NonZeroAccountDetailsModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum UpdateBCNUserStateStoreErrors {
    InvalidComment = 'INVALID_COMMENT',
    InvalidUserId = 'INVALID_USER_ID',
    UserAlreadyArchived = 'USER_ALREADY_ARCHIVED',
    RequestAlreadyRaised = 'REQUEST_ALREADY_RAISED',
    UserAlreadyActivated = 'USER_ALREADY_ACTIVATED',
    UserAlreadyDeactivated = 'USER_ALREADY_DEACTIVATED',
    PendingTransactionsFound = 'PENDING_TRANSACTIONS_FOUND',
    UnableToArchiveUser = 'UNABLE_TO_ARCHIVE_USER',
    AgentAccountNotArchived = 'AGENT_ACCOUNT_NOT_ARCHIVED',
    CannotArchiveActiveBcnUser = 'CANNOT_ARCHIVE_ACTIVE_BCN_USER',
    AgentAccountActive = 'AGENT_ACCOUNT_ACTIVE'
}

// To convert error.code from string type to enum type
const getStoreError = (
    error:
        | RequestToReactivateOrDeactivateBCNUserRPC.Errors.Errors
        | RequestToArchiveBCNUserRPC.Errors.Errors
        | RequestToArchiveBCNUserWithBalanceRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): UpdateBCNUserStateStoreErrors | NetworkingError => {
    switch (error.code) {
        case UpdateBCNUserStateStoreErrors.InvalidUserId:
            return UpdateBCNUserStateStoreErrors.InvalidUserId;
        case UpdateBCNUserStateStoreErrors.UserAlreadyArchived:
            return UpdateBCNUserStateStoreErrors.UserAlreadyArchived;
        case UpdateBCNUserStateStoreErrors.RequestAlreadyRaised:
            return UpdateBCNUserStateStoreErrors.RequestAlreadyRaised;
        case UpdateBCNUserStateStoreErrors.UserAlreadyActivated:
            return UpdateBCNUserStateStoreErrors.UserAlreadyActivated;
        case UpdateBCNUserStateStoreErrors.UserAlreadyDeactivated:
            return UpdateBCNUserStateStoreErrors.UserAlreadyDeactivated;
        case UpdateBCNUserStateStoreErrors.PendingTransactionsFound:
            return UpdateBCNUserStateStoreErrors.PendingTransactionsFound;
        case UpdateBCNUserStateStoreErrors.UnableToArchiveUser:
            return UpdateBCNUserStateStoreErrors.UnableToArchiveUser;
        case UpdateBCNUserStateStoreErrors.AgentAccountNotArchived:
            return UpdateBCNUserStateStoreErrors.AgentAccountNotArchived;
        case UpdateBCNUserStateStoreErrors.CannotArchiveActiveBcnUser:
            return UpdateBCNUserStateStoreErrors.CannotArchiveActiveBcnUser;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in UpdateBCNUserStateStore`);
            return NetworkingError.InternalError;
    }
};

export const UpdateBCNUserStateStore = types
    .model({
        comment: types.maybeNull(types.string),
        nonZeroAccountDetails: types.array(NonZeroAccountDetailsModel),
        agentId: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<UpdateBCNUserStateStoreErrors>(
                    'UpdateBCNUserStateStoreErrors',
                    Object.values(UpdateBCNUserStateStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = null;
        },
        resetNonZeroAccountDetails(): void {
            store.nonZeroAccountDetails = cast([]);
        },
        removeError(): void {
            store.error = null;
            store.agentId = null;
        },
        raiseRequestToActivateBCNUser: flow(function* (userId: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestToReactivateOrDeactivateBCNUserRPC.Request(
                    new LeoUUID(userId),
                    UserStatusTransition.UserStatusTransition.REACTIVATE,
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToReactivateOrDeactivateBCNUserRPC.Response,
                    RequestToReactivateOrDeactivateBCNUserRPC.Errors.Errors
                > = yield useRequestToReactivateOrDeactivateBCNUserRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    if (
                        error instanceof
                        RequestToReactivateOrDeactivateBCNUserRPC.Errors.AgentAccountActive
                    ) {
                        store.agentId = error.agentId.uuid;
                        store.error = UpdateBCNUserStateStoreErrors.AgentAccountActive;
                    } else {
                        store.error = getStoreError(error, loggerStore);
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToReactivateOrDeactivateBCNUserRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case UpdateBCNUserStateStoreErrors.InvalidComment:
                            store.error = UpdateBCNUserStateStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToReactivateOrDeactivateBCNUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestToReactivateOrDeactivateBCNUserRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        raiseRequestToDeactivateBCNUser: flow(function* (userId: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestToReactivateOrDeactivateBCNUserRPC.Request(
                    new LeoUUID(userId),
                    UserStatusTransition.UserStatusTransition.DEACTIVATE,
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToReactivateOrDeactivateBCNUserRPC.Response,
                    RequestToReactivateOrDeactivateBCNUserRPC.Errors.Errors
                > = yield useRequestToReactivateOrDeactivateBCNUserRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    if (
                        error instanceof
                        RequestToReactivateOrDeactivateBCNUserRPC.Errors.AgentAccountActive
                    ) {
                        store.agentId = error.agentId.uuid;
                        store.error = UpdateBCNUserStateStoreErrors.AgentAccountActive;
                    } else {
                        store.error = getStoreError(error, loggerStore);
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToReactivateOrDeactivateBCNUserRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case UpdateBCNUserStateStoreErrors.InvalidComment:
                            store.error = UpdateBCNUserStateStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToReactivateOrDeactivateBCNUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestToReactivateOrDeactivateBCNUserRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        raiseRequestToArchiveBCNUser: flow(function* (userId: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestToArchiveBCNUserRPC.Request(
                    new LeoUUID(userId),
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToArchiveBCNUserRPC.Response,
                    RequestToArchiveBCNUserRPC.Errors.Errors
                > = yield useRequestToArchiveBCNUserRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    if (error instanceof RequestToArchiveBCNUserRPC.Errors.NonZeroAccount) {
                        const accountDetails = error.accounts.map((account) =>
                            getNonZeroAccountDetailsModel(account)
                        );
                        store.nonZeroAccountDetails = cast(accountDetails);
                    } else {
                        store.error = getStoreError(error, loggerStore);
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToArchiveBCNUserRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case UpdateBCNUserStateStoreErrors.InvalidComment:
                            store.error = UpdateBCNUserStateStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToArchiveBCNUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestToArchiveBCNUserRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        raiseRequestToArchiveBCNUserWithBalance: flow(function* (userId: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestToArchiveBCNUserWithBalanceRPC.Request(
                    new LeoUUID(userId),
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToArchiveBCNUserWithBalanceRPC.Response,
                    RequestToArchiveBCNUserWithBalanceRPC.Errors.Errors
                > = yield useRequestToArchiveBCNUserWithBalanceRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToArchiveBCNUserWithBalanceRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case UpdateBCNUserStateStoreErrors.InvalidComment:
                            store.error = UpdateBCNUserStateStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToArchiveBCNUserWithBalanceRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestToArchiveBCNUserWithBalanceRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createUpdateBCNUserStateStore = (): Instance<typeof UpdateBCNUserStateStore> => {
    return UpdateBCNUserStateStore.create();
};
