import {
    AgentTransactionRequestSearchBy,
    GetFundsTransferOutOfYafikaRequestsHistoryRPC,
    GetFundsTransferOutOfYafikaRequestsHistorySortOrder,
    IntermediateRequestStatus,
    ItemsPerPage,
    PageIndex,
    SearchText
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { useGetFundsTransferOutOfYafikaRequestsHistoryRPCClient } from '../rpcs/RPC';
import { AnyEnum } from '../../common/enums/AnyEnum';
import {
    AgentTransactionRequestModel,
    getAgentTransactionRequestModel
} from '../models/AgentTransactionRequestModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { GetFundsTransferOutOfYafikaRequestsHistorySortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getFundsTransferOutOfYafikaRequestsHistorySortOrder';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AgentTransactionRequestHistoryErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const AgentTransactionRequestHistoryFilterOptions = types.model({
    searchBy: types.string,
    searchText: types.string,
    requestStatus: types.string
});

const getRequestIdSearchText = (
    filter: Instance<typeof AgentTransactionRequestHistoryFilterOptions> | undefined
): SearchText | undefined => {
    if (
        filter &&
        filter.searchBy ===
            AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy.REQUEST_ID
    ) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

const getTransactionIdSearchText = (
    filter: Instance<typeof AgentTransactionRequestHistoryFilterOptions> | undefined
): SearchText | undefined => {
    if (
        filter &&
        filter.searchBy ===
            AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy.TRANSACTION_ID
    ) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

export const AgentTransactionRequestHistoryStore = types
    .model({
        // The initial filter options for agent transaction search table will be dependent on `t` function.
        // The filter options is set as maybe and then updated from the UI.
        filterOptions: types.maybe(AgentTransactionRequestHistoryFilterOptions),
        totalItems: types.number,
        agentTransactionRequests: types.array(AgentTransactionRequestModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AgentTransactionRequestHistoryErrors>(
                    'AgentTransactionRequestHistoryErrors',
                    Object.values(AgentTransactionRequestHistoryErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .views(() => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        updateFilterOptions(
            filterOptions: Instance<typeof AgentTransactionRequestHistoryFilterOptions>
        ): void {
            store.filterOptions = filterOptions;
        },
        getAgentTransactionRequestHistory: flow(function* (
            pageIndex: number,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetFundsTransferOutOfYafikaRequestsHistoryRPC.Request(
                [
                    new GetFundsTransferOutOfYafikaRequestsHistorySortOrder(
                        0,
                        GetFundsTransferOutOfYafikaRequestsHistorySortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                store.filterOptions?.requestStatus === AnyEnum.ANY
                    ? null
                    : store.filterOptions?.requestStatus
                    ? IntermediateRequestStatus.fromDTO({ case: store.filterOptions.requestStatus })
                    : null,
                getTransactionIdSearchText(store.filterOptions),
                getRequestIdSearchText(store.filterOptions),
                new ItemsPerPage(store.itemsPerPage()),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetFundsTransferOutOfYafikaRequestsHistoryRPC.Response,
                GetFundsTransferOutOfYafikaRequestsHistoryRPC.Errors.Errors
            > = yield useGetFundsTransferOutOfYafikaRequestsHistoryRPCClient(apiClient).execute(
                request
            );
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const agentTransactionRequests =
                    response.getFundsTransferOutOfYafikaRequestsHistoryPaginationResponse.map(
                        (agentTransactionRequest) =>
                            getAgentTransactionRequestModel(agentTransactionRequest)
                    );
                store.totalItems = response.totalItems;
                store.agentTransactionRequests = cast(agentTransactionRequests);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case AgentTransactionRequestHistoryErrors.InvalidPageIndex:
                        store.error = AgentTransactionRequestHistoryErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetFundsTransferOutOfYafikaRequestsHistoryRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetFundsTransferOutOfYafikaRequestsHistoryRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createAgentTransactionRequestHistoryStore = (): Instance<
    typeof AgentTransactionRequestHistoryStore
> => {
    return AgentTransactionRequestHistoryStore.create({
        totalItems: 0,
        agentTransactionRequests: []
    });
};
