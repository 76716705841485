import { Amount, RequestGenericFundsTransferRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { useRequestGenericFundsTransferRPCClient } from '../rpcs/RPC';
import { RequestFundTransferStore } from './RequestFundTransferStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RequestGenericFundsTransferErrors {
    CurrencyMismatch = 'CURRENCY_MISMATCH',
    AmountTooLess = 'AMOUNT_TOO_LESS',
    AmountTooLarge = 'AMOUNT_TOO_LARGE',
    InvalidLinkedTransactionId = 'INVALID_LINKED_TRANSACTION_ID',
    UnableToPerformExchange = 'UNABLE_TO_PERFORM_EXCHANGE',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    ReceivingAccountWouldCrossLimit = 'RECEIVING_ACCOUNT_WOULD_CROSS_LIMIT',
    FeeCannotBeChargedFromSystemAccount = 'FEE_CANNOT_BE_CHARGED_FROM_SYSTEM_ACCOUNT'
}

export const RequestGenericFundsTransferStore = types
    .model({
        senderAccountId: types.maybeNull(types.string),
        receiverAccountId: types.maybeNull(types.string),
        amountDebited: types.maybeNull(AmountModel),
        amountCredited: types.maybeNull(AmountModel),
        amount: types.maybeNull(AmountModel),
        fee: types.maybeNull(AmountModel),
        linkTransactionId: types.maybeNull(types.string),
        errorLimitAmount: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<RequestGenericFundsTransferErrors>(
                    'RequestGenericFundsTransferErrors',
                    Object.values(RequestGenericFundsTransferErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
            store.errorLimitAmount = null;
        },
        resetStore(): void {
            store.fee = null;
            store.amount = null;
            store.amountDebited = null;
            store.amountCredited = null;
            store.error = null;
            store.errorLimitAmount = null;
        },
        setUpStore(): void {
            const requestFundsTransferStore = getParent<typeof RequestFundTransferStore>(store);
            store.senderAccountId = requestFundsTransferStore.senderAccountId;
            store.receiverAccountId = requestFundsTransferStore.receiverAccountId;
        },
        requestGenericFundsTransfer: flow(function* (
            amount: Amount,
            linkTransactionId: string | null,
            transactionFee: Amount | null
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            let linkTransactionUUID = null;
            if (linkTransactionId) {
                try {
                    linkTransactionUUID = new LeoUUID(linkTransactionId);
                } catch (error) {
                    store.error = RequestGenericFundsTransferErrors.InvalidLinkedTransactionId;
                    return;
                }
            }
            const request = new RequestGenericFundsTransferRPC.Request(
                new LeoUUID(store.senderAccountId),
                new LeoUUID(store.receiverAccountId),
                amount,
                transactionFee,
                linkTransactionUUID
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                RequestGenericFundsTransferRPC.Response,
                RequestGenericFundsTransferRPC.Errors.Errors
            > = yield useRequestGenericFundsTransferRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.amountDebited = getAmountModel(response.transferAmount.amountDebited);
                store.amountCredited = getAmountModel(response.transferAmount.amountCredited);
                store.amount = getAmountModel(amount);
                store.fee = transactionFee && getAmountModel(transactionFee);
                store.linkTransactionId = linkTransactionId;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case RequestGenericFundsTransferErrors.CurrencyMismatch:
                        store.error = RequestGenericFundsTransferErrors.CurrencyMismatch;
                        break;
                    case RequestGenericFundsTransferErrors.AmountTooLess:
                        if (error instanceof RequestGenericFundsTransferRPC.Errors.AmountTooLess) {
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(error.minimumAmount)
                            );
                        }
                        store.error = RequestGenericFundsTransferErrors.AmountTooLess;
                        break;
                    case RequestGenericFundsTransferErrors.AmountTooLarge:
                        if (error instanceof RequestGenericFundsTransferRPC.Errors.AmountTooLarge) {
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(error.maximumAmount)
                            );
                        }
                        store.error = RequestGenericFundsTransferErrors.AmountTooLarge;
                        break;
                    case RequestGenericFundsTransferErrors.InvalidLinkedTransactionId:
                        store.error = RequestGenericFundsTransferErrors.InvalidLinkedTransactionId;
                        break;
                    case RequestGenericFundsTransferErrors.UnableToPerformExchange:
                        store.error = RequestGenericFundsTransferErrors.UnableToPerformExchange;
                        break;
                    case RequestGenericFundsTransferErrors.InsufficientBalance:
                        store.error = RequestGenericFundsTransferErrors.InsufficientBalance;
                        break;
                    case RequestGenericFundsTransferErrors.ReceivingAccountWouldCrossLimit:
                        store.error =
                            RequestGenericFundsTransferErrors.ReceivingAccountWouldCrossLimit;
                        break;
                    case RequestGenericFundsTransferErrors.FeeCannotBeChargedFromSystemAccount:
                        store.error =
                            RequestGenericFundsTransferErrors.FeeCannotBeChargedFromSystemAccount;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in RequestGenericFundsTransferRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in RequestGenericFundsTransferRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createRequestGenericFundsTransferStore = (): Instance<
    typeof RequestGenericFundsTransferStore
> => {
    return RequestGenericFundsTransferStore.create();
};
