import { LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    AccountDetails,
    BitmapImageType,
    BOName,
    Currency,
    LocalizedText,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    UserName,
    ValidateAccountDetailsRPC
} from '@resolut-tech/bcn-rpcs';

export class MockValidateAccountDetailsRPCImpl extends ValidateAccountDetailsRPC {
    execute(
        _request: ValidateAccountDetailsRPC.Request
    ): Promise<
        LeoRPCResult<ValidateAccountDetailsRPC.Response, ValidateAccountDetailsRPC.Errors.Errors>
    > {
        const response = new ValidateAccountDetailsRPC.Response(
            new AccountDetails.SystemAccount(
                new LocalizedText('Malavi'),
                'BILLER_AIRTEL_AIRTIME',
                new Currency('MWK')
            ),
            new AccountDetails.UserAccount(
                new UserName(new BOName('Esther'), new BOName('Howard')),
                new MultiResolutionBitmapImage(
                    new RemoteBitmapImage(
                        new URL(
                            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                        ),
                        BitmapImageType.BitmapImageType.PNG,
                        20,
                        20
                    ),
                    new RemoteBitmapImage(
                        new URL(
                            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                        ),
                        BitmapImageType.BitmapImageType.PNG,
                        20,
                        20
                    ),
                    new RemoteBitmapImage(
                        new URL(
                            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                        ),
                        BitmapImageType.BitmapImageType.PNG,
                        20,
                        20
                    ),
                    new RemoteBitmapImage(
                        new URL(
                            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                        ),
                        BitmapImageType.BitmapImageType.PNG,
                        20,
                        20
                    )
                ),
                new LeoPhoneNumber('+919999999999'),
                new LeoUUID(),
                new Currency('MWK')
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                ValidateAccountDetailsRPC.Response,
                ValidateAccountDetailsRPC.Errors.Errors
            >
        >;
    }
}
