import {
    CountryCode,
    FundStatus,
    GetUnclaimedFundListPaginationResponse,
    GetUnclaimedFundListRPC,
    PGAmount,
    PGUserName
} from '@resolut-tech/bcn-rpcs';
import { LeoPhoneNumber, LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const UnclaimedFunds: GetUnclaimedFundListPaginationResponse[] = Array(23).fill(
    new GetUnclaimedFundListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID(),
        new PGUserName('Wade', 'Warren'),
        new LeoPhoneNumber('+265995118354'),
        new PGAmount(5000000, 'MWK'),
        new CountryCode('25'),
        FundStatus.FundStatus.UNCLAIMED
    )
);

export class MockGetUnclaimedFundsListRPCImpl extends GetUnclaimedFundListRPC {
    execute(
        _request: GetUnclaimedFundListRPC.Request
    ): Promise<
        LeoRPCResult<GetUnclaimedFundListRPC.Response, GetUnclaimedFundListRPC.Errors.Errors>
    > {
        const response = new GetUnclaimedFundListRPC.Response(
            UnclaimedFunds,
            UnclaimedFunds.length
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetUnclaimedFundListRPC.Response, GetUnclaimedFundListRPC.Errors.Errors>
        >;
    }
}
