import { Stack, Typography } from '@mui/material';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { DialogLoadingState } from '../../common/components/DialogLoadingState';
import { getPhoneNumberWithCountryCode } from '../../common/utils/UIUtils';
import { BOUserBasicDetailsModel } from '../store/BOUserDetailStore';
import {
    useBORoleStore,
    useRequestToAddEditBOUserStore,
    useValidateBOUserDetailsStore
} from '../store/hooks';
import { ValidateBOUserErrors } from '../store/ValidateBOUserErrors';
import { BasicDetailFields } from './BasicDetailFields';
import { BOUserRolesSelector } from './BOUserRolesSelector';
import { Dialog } from '../../../common/components/Dialog';

interface AddEditBOUserDialogProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    setIsErrorDialogOpen: () => void;
    userInfo?: {
        userId: string;
        userDetails: Instance<typeof BOUserBasicDetailsModel>;
    };
    onSuccess: () => void;
}

export const AddEditBOUserDialog = observer(
    ({
        isDialogOpen,
        onDialogClose,
        setIsErrorDialogOpen,
        // If userInfo is present it is safe to assume that edit workflow is initiated since only an existing user will have a valid user-id and user-details.
        userInfo,
        onSuccess
    }: AddEditBOUserDialogProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const store = useBORoleStore();
        const addEditBOUserStore = useRequestToAddEditBOUserStore();
        const validateBOUserStore = useValidateBOUserDetailsStore();
        const [isLoading, setIsLoading] = useState(true);
        const [country, setCountry] = useState<CountryViewModel | null>(null);
        const [countries, setCountries] = useState<CountryViewModel[] | null>(null);
        const [isUserDetailsVerified, setIsUserDetailsVerified] = useState(false);
        const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
        const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState<string | null>(
            null
        );

        useEffect(() => {
            // This is done to ensure that once the  dialog is open, the Prefilled Data is removed and the store is reset.
            if (isDialogOpen) {
                addEditBOUserStore.resetStore();
                validateBOUserStore.resetStore();
            }
        }, [isDialogOpen]);

        const getCommentComponent = (): React.ReactElement => {
            return (
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={addEditBOUserStore.comment ?? ''}
                    onTextChange={addEditBOUserStore.setComment}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            );
        };

        const getCountryList = async (): Promise<void> => {
            await validateBOUserStore.getCountries();
            setCountries(validateBOUserStore.countryList());
        };

        const getUserRoles = async (): Promise<void> => {
            await store.getBORoles();
        };

        const isUserDetailsValid = (): boolean => {
            return Boolean(
                validateBOUserStore.firstName &&
                    validateBOUserStore.phoneNumber &&
                    validateBOUserStore.emailId &&
                    validateBOUserStore.country?.countryCode &&
                    validateBOUserStore.gender &&
                    validateBOUserStore.roles.length &&
                    !emailErrorMessage
            );
        };

        const getInitialDetails = async (): Promise<void> => {
            setIsLoading(true);
            if (!store.workflowDependentRoles.length) {
                await getUserRoles();
            }
            await getCountryList();
            setIsLoading(false);
            if (store.error) {
                setIsErrorDialogOpen();
                onDialogClose();
            }
        };

        const getPrimaryButtonText = (): string | null => {
            if (isLoading) {
                return null;
            } else if (isUserDetailsVerified) {
                return t('common.submitRequest');
            } else if (userInfo) {
                return t('common.requestUpdate');
            } else {
                return t('boUser.addBOUser');
            }
        };

        useEffect(() => {
            getInitialDetails();
        }, []);

        const onClose = (): void => {
            addEditBOUserStore.resetStore();
            validateBOUserStore.resetStore();
            setCountry(null);
            setCountries(null);
            setIsUserDetailsVerified(false);
            setEmailErrorMessage(null);
            setMobileNumberErrorMessage(null);
            onDialogClose();
        };

        const onValidateRequest = async (): Promise<void> => {
            setIsLoading(true);
            await validateBOUserStore.validateBOUserDetails(userInfo?.userId ?? null);
            setIsLoading(false);
            if (!validateBOUserStore.error) {
                addEditBOUserStore.setUpStore();
                setIsUserDetailsVerified(true);
            } else {
                switch (validateBOUserStore.error) {
                    case ValidateBOUserErrors.PhoneNumberAlreadyExists:
                        setMobileNumberErrorMessage(t('common.mobileNumberAlreadyExists'));
                        break;
                    case ValidateBOUserErrors.InvalidPhoneNumber:
                        setMobileNumberErrorMessage(t('common.invalidMobileNumber'));
                        break;
                    case ValidateBOUserErrors.EmailIdAlreadyExists:
                        setEmailErrorMessage(t('common.emailAlreadyExists'));
                        break;
                    case ValidateBOUserErrors.InvalidEmailId:
                        setEmailErrorMessage(t('common.invalidEmail'));
                        break;
                    case ValidateBOUserErrors.InvalidCountryPhoneCode:
                        setMobileNumberErrorMessage(t('common.InvalidCountryPhoneCode'));
                        break;
                    default:
                        setIsErrorDialogOpen();
                        onClose();
                }
            }
        };

        const submitRequest = async (): Promise<void> => {
            setIsLoading(true);
            if (userInfo) {
                await addEditBOUserStore.requestBOUserUpdate(userInfo.userId);
            } else {
                await addEditBOUserStore.requestToAddBOUser();
            }
            setIsLoading(false);
            if (!addEditBOUserStore.error) {
                onClose();
                onSuccess();
            } else {
                setIsUserDetailsVerified(false);
                switch (addEditBOUserStore.error) {
                    case ValidateBOUserErrors.PhoneNumberAlreadyExists:
                        setMobileNumberErrorMessage(t('common.mobileNumberAlreadyExists'));
                        break;
                    case ValidateBOUserErrors.InvalidPhoneNumber:
                        setMobileNumberErrorMessage(t('common.invalidMobileNumber'));
                        break;
                    case ValidateBOUserErrors.EmailIdAlreadyExists:
                        setEmailErrorMessage(t('common.emailAlreadyExists'));
                        break;
                    case ValidateBOUserErrors.InvalidEmailId:
                        setEmailErrorMessage(t('common.invalidEmail'));
                        break;
                    case ValidateBOUserErrors.InvalidCountryPhoneCode:
                        setMobileNumberErrorMessage(t('common.InvalidCountryPhoneCode'));
                        break;
                    default:
                        setIsErrorDialogOpen();
                        onClose();
                }
            }
        };

        const isSubmitRequestButtonEnable = (): boolean => {
            return (
                addEditBOUserStore.comment?.trim().length === 0 ||
                addEditBOUserStore.comment === null
            );
        };

        const isUserRolesChanged = (): boolean => {
            const userRolesDiffMap = new Map();

            // Add all the current user roles ID to userRolesDiffMap
            userInfo?.userDetails.roles.map((role) => {
                userRolesDiffMap.set(role.id, role.id);
            });
            validateBOUserStore?.roles.map((role) => {
                // Remove user role ID which exist in userRolesDiffMap.
                if (userRolesDiffMap.has(role.id)) {
                    userRolesDiffMap.delete(role.id);
                } else {
                    // Add user role ID which does not exist to userRolesDiffMap.
                    userRolesDiffMap.set(role.id, role.id);
                }
            });

            return userRolesDiffMap.size !== 0;
        };

        const isPrimaryButtonDisabled = (): boolean => {
            // Update BO user validations.
            if (userInfo && country) {
                let isUserDetailsUpdated = false;

                if (
                    validateBOUserStore.firstName !== userInfo.userDetails.firstName ||
                    validateBOUserStore.lastName !== userInfo.userDetails.lastName ||
                    validateBOUserStore.otherNames !== userInfo.userDetails.otherNames ||
                    validateBOUserStore.emailId !== userInfo.userDetails.emailId ||
                    (validateBOUserStore.phoneNumber &&
                        getPhoneNumberWithCountryCode(
                            country.phoneCode,
                            validateBOUserStore.phoneNumber
                        ) !== userInfo.userDetails.phoneNumber) ||
                    validateBOUserStore.gender !== userInfo.userDetails.gender ||
                    isUserRolesChanged()
                ) {
                    isUserDetailsUpdated = true;
                }

                if (!isUserDetailsUpdated) return true;
                return isUserDetailsVerified
                    ? isSubmitRequestButtonEnable()
                    : !isUserDetailsValid();
            }

            // New BO user validations.
            return isUserDetailsVerified ? isSubmitRequestButtonEnable() : !isUserDetailsValid();
        };

        return (
            <Dialog
                open={isDialogOpen}
                title={userInfo ? t('common.requestUpdate') : t('boUser.requestAddBOUser')}
                primaryButtonText={getPrimaryButtonText() ?? undefined}
                onPrimaryButtonClick={isUserDetailsVerified ? submitRequest : onValidateRequest}
                isPrimaryButtonDisabled={
                    isUserDetailsVerified
                        ? !Boolean(addEditBOUserStore.comment)
                        : isPrimaryButtonDisabled()
                }
                secondaryButtonText={isLoading ? undefined : t('common.cancel')}
                onSecondaryButtonClick={onClose}
                disableBackdropClick>
                {isLoading ? (
                    <DialogLoadingState label={t('common.loadingMessage')} />
                ) : isUserDetailsVerified ? (
                    getCommentComponent()
                ) : (
                    <Stack>
                        <BasicDetailFields
                            country={country}
                            countries={countries}
                            onCountryChange={setCountry}
                            emailErrorMessage={emailErrorMessage}
                            mobileNumberErrorMessage={mobileNumberErrorMessage}
                            onEmailChange={(): void => {
                                setEmailErrorMessage(null);
                            }}
                            onInvalidEmail={setEmailErrorMessage}
                            onMobileNumberChange={(): void => {
                                setMobileNumberErrorMessage(null);
                            }}
                            userDetails={userInfo?.userDetails}
                        />
                        <Typography
                            sx={{
                                ...typography.small2,
                                color: palette.label[300],
                                paddingBottom: '12px'
                            }}>
                            {t('boUser.userRoles')}
                        </Typography>
                        <BOUserRolesSelector
                            workflowDependentRoles={store.workflowDependentRoles}
                            selectedBORoles={validateBOUserStore.roles}
                            onBORolesChange={(updatedUserRoles): void => {
                                validateBOUserStore.setRoles(updatedUserRoles);
                            }}
                        />
                    </Stack>
                )}
            </Dialog>
        );
    }
);
