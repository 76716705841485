import { CheckAgentStatusUpdateRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckAgentStatusUpdateRequestRPCImpl extends CheckAgentStatusUpdateRequestRPC {
    execute(
        _request: CheckAgentStatusUpdateRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckAgentStatusUpdateRequestRPC.Response,
            CheckAgentStatusUpdateRequestRPC.Errors.Errors
        >
    > {
        const response = new CheckAgentStatusUpdateRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckAgentStatusUpdateRequestRPC.Response,
                CheckAgentStatusUpdateRequestRPC.Errors.Errors
            >
        >;
    }
}
