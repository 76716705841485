import { Box, CircularProgress, Typography } from '@mui/material';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFeeRulesStore } from '../../../fee-rules/store/UploadFeeRulesStore';
import { UploadTransactionRulesStore } from '../../../transaction-rules/store/UploadTransactionRulesStore';
import { useLoggerStore } from '../../../../../log/hooks';
import { Dialog } from '../../../../common/components/Dialog';

interface EditRulesUpdateRequestDialogProps {
    title: string;
    isDialogOpen: boolean;
    onDialogClose: () => void;
    setStoreComment(comment: string): void;
    csvFile: File;
    uploadStore: Instance<typeof UploadFeeRulesStore | typeof UploadTransactionRulesStore>;
    onUploadRuleSubmitFile: (recordId: LeoUUID) => Promise<void>;
    setErrorDialogLabel: React.Dispatch<React.SetStateAction<string | null>>;
    validatingRulesMessage: string;
}

export const EditRulesUpdateRequestDialog = ({
    title,
    isDialogOpen,
    onDialogClose,
    setStoreComment,
    csvFile,
    uploadStore,
    onUploadRuleSubmitFile,
    setErrorDialogLabel,
    validatingRulesMessage
}: EditRulesUpdateRequestDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const [comment, setComment] = useState<string | null>(null);
    const [isRuleUpdating, setIsRuleUpdating] = useState(false);
    const loggerStore = useLoggerStore();

    useEffect(() => {
        return () => {
            setComment(null);
            setIsRuleUpdating(false);
        };
    }, []);

    const getRuleUpdatingComponent = (): React.ReactElement => {
        return (
            <Box
                style={{
                    height: '104px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <CircularProgress
                    size="32px"
                    sx={{
                        color: palette.primary[300],
                        marginTop: '16px'
                    }}
                />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.primary[300],
                        marginTop: '16px'
                    }}>
                    {validatingRulesMessage}
                </Typography>
            </Box>
        );
    };

    const renderDialogContents = (): React.ReactElement => {
        if (isRuleUpdating) {
            return getRuleUpdatingComponent();
        }
        return (
            <Box>
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={comment ?? undefined}
                    onTextChange={(inputValue: string): void => {
                        setComment(inputValue);
                    }}
                    label={t('common.addComment')}
                />
            </Box>
        );
    };

    const handleFileUpload = async (_file: File): Promise<void> => {
        if (!comment) {
            loggerStore.debug('Comment should not be empty while handling file upload');
            return;
        }

        setStoreComment(comment);
        uploadStore.resetError();
        await uploadStore.uploadFile(_file);
        if (uploadStore.error) {
            setErrorDialogLabel(t('common.somethingWentWrong'));
        } else if (uploadStore.recordId) {
            await onUploadRuleSubmitFile(new LeoUUID(uploadStore.recordId));
        }
    };

    const onPrimaryButtonClick = async (): Promise<void> => {
        setIsRuleUpdating(true);
        await handleFileUpload(csvFile);
        setIsRuleUpdating(false);
        onDialogClose();
    };

    return (
        <Dialog
            open={isDialogOpen}
            title={title}
            primaryButtonText={!isRuleUpdating ? t('common.updateRules') : undefined}
            isPrimaryButtonDisabled={!comment}
            onPrimaryButtonClick={onPrimaryButtonClick}
            secondaryButtonText={!isRuleUpdating ? t('common.cancel') : undefined}
            onSecondaryButtonClick={onDialogClose}
            disableBackdropClick={true}>
            {renderDialogContents()}
        </Dialog>
    );
};
