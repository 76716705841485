import * as React from 'react';

export const ChevronsRight = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.19526 4.19526C8.45561 3.93491 8.87772 3.93491 9.13807 4.19526L12.4714 7.5286C12.7318 7.78894 12.7318 8.21106 12.4714 8.4714L9.13807 11.8047C8.87772 12.0651 8.45561 12.0651 8.19526 11.8047C7.93491 11.5444 7.93491 11.1223 8.19526 10.8619L11.0572 8L8.19526 5.13807C7.93491 4.87772 7.93491 4.45561 8.19526 4.19526Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5312 4.19526C3.79155 3.93491 4.21366 3.93491 4.47401 4.19526L7.80734 7.5286C8.06769 7.78894 8.06769 8.21106 7.80734 8.4714L4.47401 11.8047C4.21366 12.0651 3.79155 12.0651 3.5312 11.8047C3.27085 11.5444 3.27085 11.1223 3.5312 10.8619L6.39313 8L3.5312 5.13807C3.27085 4.87772 3.27085 4.45561 3.5312 4.19526Z"
                fill="currentColor"
            />
        </svg>
    );
};
