import { ValidateBOUserDetailsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockValidateBOUserDetailsRPCImpl extends ValidateBOUserDetailsRPC {
    execute(
        _request: ValidateBOUserDetailsRPC.Request
    ): Promise<
        LeoRPCResult<ValidateBOUserDetailsRPC.Response, ValidateBOUserDetailsRPC.Errors.Errors>
    > {
        const response = new ValidateBOUserDetailsRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<ValidateBOUserDetailsRPC.Response, ValidateBOUserDetailsRPC.Errors.Errors>
        >;
    }
}
