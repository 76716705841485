import { Box, styled, SxProps, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum RequestType {
    NEW_USER,
    UPDATED_USER
}

// RequestType variant is used in Cards.
// Request-Type-cell variant is used in TableCell.
type RequestTypeComponentVariant = 'requestType' | 'request-Type-cell';

interface StatusProps {
    variant: RequestTypeComponentVariant;
    type: RequestType;
    sx?: SxProps;
}

export function RequestTypeComponent({ variant, type, sx }: StatusProps): React.ReactElement {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    function getBackgroundColor(): string {
        switch (type) {
            case RequestType.NEW_USER:
                return palette.success[300];
            case RequestType.UPDATED_USER:
                return palette.informative[300];
        }
    }

    const StyledStatusBox = styled(Box)(() => ({
        borderRadius: '250px',
        padding: variant === 'requestType' ? '8px 12px' : '6px 10px',
        backgroundColor: getBackgroundColor(),
        textTransform: 'uppercase',
        width: 'fit-content',
        height: 'fit-content'
    }));

    function getTitle(): string {
        switch (type) {
            case RequestType.NEW_USER:
                return t('boUser.newUser');
            case RequestType.UPDATED_USER:
                return t('boUser.updatedUser');
        }
    }

    return (
        <StyledStatusBox sx={{ ...sx }}>
            <Typography
                sx={{
                    ...(variant === 'requestType' ? typography.overline1 : typography.overline2),
                    color: 'white'
                }}>
                {getTitle()}
            </Typography>
        </StyledStatusBox>
    );
}
