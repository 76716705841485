import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    useGetCountryAndCBSIdListForTVRRPCClient,
    useGetCurrentTransactionValidationRuleDetailsFileRPCClient,
    useGetCurrentTransactionValidationRuleDetailsRPCClient
} from '../rpcs/RPC';
import {
    GetCountryAndCBSIdListForTVRRPC,
    GetCurrentTransactionValidationRuleDetailsFileRPC,
    GetCurrentTransactionValidationRuleDetailsFileRPCClientImpl,
    GetCurrentTransactionValidationRuleDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    createDownloadTransactionRulesStore,
    DownloadTransactionRulesStore
} from './DownloadTransactionRulesStore';
import {
    getTransactionValidationRuleModel,
    TransactionValidationRuleModel
} from '../models/TransactionValidationRuleModel';
import { getLoggerStore } from '../../../../log/hooks';
import {
    CBSIdWithCountry,
    CountryCbsIdListModel,
    getCountryCbsIdListModel
} from '../../common/models/CountryCbsIdListModel';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ViewTransactionRulesStore = types
    .model('ViewTransactionRules', {
        currentFileDocumentId: types.maybeNull(types.string),
        downloadFileStore: DownloadTransactionRulesStore,
        currentTransactionValidationRules: types.maybeNull(
            types.array(TransactionValidationRuleModel)
        ),
        countryCbsIdList: types.array(CountryCbsIdListModel),
        error: types.maybeNull(
            types.enumeration<NetworkingError>('NetworkingError', Object.values(NetworkingError))
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchCurrentTransactionValidationRuleDetailsFile: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request =
                new GetCurrentTransactionValidationRuleDetailsFileRPCClientImpl.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetCurrentTransactionValidationRuleDetailsFileRPC.Response,
                never
            > = yield useGetCurrentTransactionValidationRuleDetailsFileRPCClient(apiClient).execute(
                request
            );
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.currentFileDocumentId = response.currentFileRecordId?.uuid ?? null;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetCurrentTransactionValidationRuleDetailsFileRPC`
                );
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetCurrentTransactionValidationRuleDetailsFileRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        fetchCurrentTransactionValidationDetails: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new GetCurrentTransactionValidationRuleDetailsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetCurrentTransactionValidationRuleDetailsRPC.Response,
                GetCurrentTransactionValidationRuleDetailsRPC.Errors.Errors
            > = yield useGetCurrentTransactionValidationRuleDetailsRPCClient(apiClient).execute(
                request
            );
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.currentTransactionValidationRules =
                    response.transactionValidationRules &&
                    cast(
                        response.transactionValidationRules.map((transactionRule) =>
                            getTransactionValidationRuleModel(transactionRule)
                        )
                    );
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetCurrentTransactionValidationRuleDetailsRPC`
                );
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetCurrentTransactionValidationRuleDetailsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        fetchCountryAndCBSIdList: flow(function* () {
            if (store.countryCbsIdList.length) {
                return;
            } else {
                const loggerStore = getLoggerStore(store);
                const request = new GetCountryAndCBSIdListForTVRRPC.Request();
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetCountryAndCBSIdListForTVRRPC.Response,
                    GetCountryAndCBSIdListForTVRRPC.Errors.Errors
                > = yield useGetCountryAndCBSIdListForTVRRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const list = response.countryCBSIds.map((countryCbsIdList) => {
                        return getCountryCbsIdListModel(countryCbsIdList);
                    });
                    store.countryCbsIdList = cast(list);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in GetCountryAndCBSIdListForTVRRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .views((store) => ({
        getCbsIdWithCountryList(): CBSIdWithCountry[] {
            return store.countryCbsIdList.map((list) => {
                return {
                    country: list.name,
                    cbsId: list.cbsId
                };
            });
        },
        getCbsIdCountryList(): string[] {
            return store.countryCbsIdList.map((country) => country.name);
        }
    }));

export const createViewTransactionRulesStore = (): Instance<typeof ViewTransactionRulesStore> => {
    return ViewTransactionRulesStore.create({
        downloadFileStore: createDownloadTransactionRulesStore(),
        countryCbsIdList: []
    });
};
