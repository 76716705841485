import React from 'react';
import { ErrorComponent } from '../components/ErrorComponent';
import slash from '../../../assets/slash.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PageNotFound = (): React.ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ErrorComponent
            title={t('errors.pageNotFound')}
            description={t('errors.pageNotFoundDescription')}
            errorImage={<img src={slash} />}
            onGoToHomeClick={(): void => {
                navigate('/');
            }}
        />
    );
};
