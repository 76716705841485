import { RootStore, useRootStore } from '../modules/store/root/store/RootStore';
import { LoggerStore } from './LoggerStore';
import { IAnyStateTreeNode, Instance, getRoot } from 'mobx-state-tree';

export const useLoggerStore = (): Instance<typeof LoggerStore> => {
    return useRootStore().loggerStore;
};

// This function is required since there are stores which add log statements but the above function cannot be used in a store since hooks can only be used in functional components
export const getLoggerStore = (store: IAnyStateTreeNode): Instance<typeof LoggerStore> => {
    const rootStore = getRoot<typeof RootStore>(store);
    return rootStore.loggerStore;
};
