import { getPGFullName } from './../../../../utils/StringUtils';
import { LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    GetBOUsersPaginationResponse,
    GetBOUsersRPC,
    PGUserName,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const paginationResponseList: GetBOUsersPaginationResponse[] = [
    new GetBOUsersPaginationResponse(
        new PGUserName('Jane Cooper'),
        new LeoPhoneNumber('+265995118354'),
        'jane.cooper@email.com',
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID('70127105-fa9a-4993-9f4f-eeb03d4599a7')
    ),
    new GetBOUsersPaginationResponse(
        new PGUserName('Wade Warren'),
        new LeoPhoneNumber('+265995118354'),
        'wade.warren@email.com',
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID('d1d0c0f2-b5d7-4a43-81ae-6835e995b315')
    ),
    new GetBOUsersPaginationResponse(
        new PGUserName('Esther Howard'),
        new LeoPhoneNumber('+265995118354'),
        'esther.howard@email.com',
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('cea7ad84-baba-4263-abdc-80c293dc62a4')
    ),
    new GetBOUsersPaginationResponse(
        new PGUserName('Cameron Williamson'),
        new LeoPhoneNumber('+265995118354'),
        'cameron.williamson@email.com',
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID('98bcb50f-d97e-4acc-b1ea-f718e630d166')
    ),

    new GetBOUsersPaginationResponse(
        new PGUserName('Brooklyn Simmons'),
        new LeoPhoneNumber('+265995118354'),
        'brooklyn.simmons@email.com',
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('efe47f65-6eb9-4ec7-a74e-fed60831acd6')
    ),
    new GetBOUsersPaginationResponse(
        new PGUserName('Leslie Alexander'),
        new LeoPhoneNumber('+265995118354'),
        'leslie.alexander@email.com',
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID('f2f66eea-7ae9-438e-a387-3430bba5755c')
    ),
    new GetBOUsersPaginationResponse(
        new PGUserName('Jenny Wilson'),
        new LeoPhoneNumber('+265995118354'),
        'jenny.wilson@email.com',
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('ac806b1c-7a8e-4645-9c4e-1322370fac24')
    )
];

export class MockGetBOUsersRPCImpl extends GetBOUsersRPC {
    execute(
        request: GetBOUsersRPC.Request
    ): Promise<LeoRPCResult<GetBOUsersRPC.Response, GetBOUsersRPC.Errors.Errors>> {
        let list: GetBOUsersPaginationResponse[] = paginationResponseList;
        const emailIdSearchText = request.emailIdSearchText?.text;
        const phoneNumberSearchText = request.phoneNumberSearchText?.text;
        const nameSearchText = request.nameSearchText?.text;
        const requestUserStatus = request.userStatus;

        if (emailIdSearchText) {
            list = list.filter((t) => t.emailId.toLowerCase().includes(emailIdSearchText));
        }
        if (nameSearchText) {
            list = list.filter((t) => getPGFullName(t.name).toLowerCase().includes(nameSearchText));
        }
        if (phoneNumberSearchText) {
            list = list.filter((t) =>
                t.phoneNumber?.phoneNumber.toLowerCase().includes(phoneNumberSearchText)
            );
        }
        if (requestUserStatus) {
            list = list.filter((t) => {
                return t.userStatus === requestUserStatus;
            });
        }
        const response = new GetBOUsersRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetBOUsersRPC.Response, GetBOUsersRPC.Errors.Errors>
        >;
    }
}
