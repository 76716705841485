import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { AuthLayout } from './modules/auth/layouts/AuthLayout';
import { EnterAuthCode } from './modules/auth/pages/EnterAuthCode';
import { EnterMobileNumber } from './modules/auth/pages/EnterMobileNumber';
import { EnterOTP } from './modules/auth/pages/EnterOTP';
import { SetNewPasswordEntryScreen } from './modules/auth/pages/SetNewPasswordEntryScreen';
import { HomeLayout } from './modules/home/layouts/HomeLayout';
import { ReportsGrid } from './modules/home/reports/pages/ReportsGrid';
import { ReportDetails } from './modules/home/reports/pages/ReportDetails';
import { BOUsers } from './modules/home/bo-users/pages/BOUsers';
import { GenericFundsTransferRequest } from './modules/home/generic-funds-transfer/pages/GenericFundsTransferRequest';
import { TransactionSearch } from './modules/home/transaction/pages/TransactionSearch';
import { TransactionDetails } from './modules/home/transaction/pages/TransactionDetails';
import { TransferRequest } from './modules/home/generic-funds-transfer/pages/TransferRequest';
import { TransferDetails } from './modules/home/generic-funds-transfer/pages/TransferDetails';
import { BCNUsers } from './modules/home/bcn-users/pages/BCNUsers';
import { BCNUserDetails } from './modules/home/bcn-users/pages/BCNUserDetails';
import { BOUserDetails } from './modules/home/bo-users/pages/BOUserDetails';
import { AuditLogs } from './modules/home/audit-logs/pages/AuditLogs';
import { observer } from 'mobx-react';
import { UserPrivileges } from './modules/home/user/UserPrivileges';
import { RootPage } from './modules/store/root/pages/RootPage';
import { RefundRequests } from './modules/home/transaction/pages/RefundRequests';
import { ProfileSetting } from './modules/home/profile/pages/ProfileSetting';
import { InternalServerError } from './modules/error/pages/InternalServerError';
import { PageNotFound } from './modules/error/pages/PageNotFound';
import { ErrorLayout } from './modules/error/layout/ErrorLayout';
import { useUserStore } from './modules/home/store/hooks';
import { BCNUserViewRequest } from './modules/home/bcn-users/pages/BCNUserViewRequest';
import { BOUserRequests } from './modules/home/bo-users/pages/BOUserRequests';
import { AgentTransactionList } from './modules/home/agent-banking/pages/AgentTransactionList';
import { AgentTransactionDetails } from './modules/home/agent-banking/pages/AgentTransactionDetails';
import { CBSTransferFunds } from './modules/home/unclaimed-funds/pages/CBSTransferFunds';
import { CBSTransactionDetails } from './modules/home/unclaimed-funds/pages/CBSTransactionDetails';
import { AgentTransactionRequestHistory } from './modules/home/agent-banking/pages/AgentTransactionRequestHistory';
import { CBSRequestDetails } from './modules/home/unclaimed-funds/pages/CBSRequestDetails';
import { ApproveCBSTransfer } from './modules/home/unclaimed-funds/pages/ApproveCBSTransfer';
import { AgentRequestHistory } from './modules/home/agent-banking/pages/AgentRequestsHistory';
import { ViewHistory } from './modules/home/unclaimed-funds/pages/ViewHistory';
import { ViewHistoryRequestDetails } from './modules/home/unclaimed-funds/pages/ViewHistoryRequestDetails';
import { Agents } from './modules/home/agent-banking/pages/Agents';
import { AgentDetails } from './modules/home/agent-banking/pages/AgentDetails';
import { UpdateFeeRules } from './modules/home/fee-rules/pages/UpdateFeeRules';
import { ViewFeeRules } from './modules/home/fee-rules/pages/ViewFeeRules';
import { ViewTransactionRules } from './modules/home/transaction-rules/pages/ViewTransactionRules';
import { UpdateTransactionRules } from './modules/home/transaction-rules/pages/UpdateTransactionRules';
import { ViewTransactionRulesHistory } from './modules/home/transaction-rules/pages/ViewTransactionRulesHistory';
import { EditFeeRules } from './modules/home/fee-rules/pages/EditFeeRules';
import { ApproveFeeRules } from './modules/home/fee-rules/pages/ApproveFeeRules';
import { ViewFeeRulesHistory } from './modules/home/fee-rules/pages/ViewFeeRulesHistory';
import { FeeRulesHistoryRequestDetails } from './modules/home/fee-rules/pages/FeeRulesHistoryRequestDetails';
import { TransactionRuleHistoryRequestDetails } from './modules/home/transaction-rules/pages/TransactionRuleHistoryRequestDetails';
import { ApproveTransactionRules } from './modules/home/transaction-rules/pages/ApproveTransactionRules';
import { EditTransactionRules } from './modules/home/transaction-rules/pages/EditTransactionRules';

const authRoutes = [
    {
        path: '/auth',
        element: <AuthLayout />,
        children: [
            { path: '/auth', element: <Navigate to="/auth/enter-mobile-number" /> },
            { path: '/auth/enter-mobile-number', element: <EnterMobileNumber /> },
            { path: '/auth/confirm-auth-code', element: <EnterAuthCode /> },
            { path: '/auth/enter-otp', element: <EnterOTP /> },
            { path: '/auth/set-new-password', element: <SetNewPasswordEntryScreen /> }
        ]
    }
];

export const homeRoutes = (userPrivileges: string[]): RouteObject[] => {
    const transactionRoutes = (): RouteObject[] => {
        if (userPrivileges.includes(UserPrivileges.ViewTransaction)) {
            const transactionChildren: RouteObject[] = [];
            const transactionPath = '/transaction';
            const transactionSearch: RouteObject = {
                path: `${transactionPath}/search`,
                element: <TransactionSearch />
            };
            transactionChildren.push(transactionSearch);

            const transactionDetail: RouteObject = {
                path: `${transactionPath}/search/details`,
                element: <TransactionDetails />
            };

            const refundRequests: RouteObject = {
                path: `${transactionPath}/refund-requests`,
                element: <RefundRequests />
            };

            transactionChildren.push(transactionDetail);
            transactionChildren.push(refundRequests);

            return [
                {
                    path: transactionPath,
                    children: transactionChildren
                }
            ];
        } else {
            return [];
        }
    };

    const reportsRoutes = (): RouteObject[] => {
        if (userPrivileges.includes(UserPrivileges.ViewReports)) {
            const reportsChildren: RouteObject[] = [];
            const reportsPath = '/reports';

            const reportCardsRoute: RouteObject = {
                path: `${reportsPath}`,
                element: <ReportsGrid />
            };

            const reportDetailsRoute: RouteObject = {
                path: `${reportsPath}/details`,
                element: <ReportDetails />
            };

            reportsChildren.push(reportCardsRoute);
            reportsChildren.push(reportDetailsRoute);

            return [
                {
                    path: reportsPath,
                    children: reportsChildren
                }
            ];
        } else {
            return [];
        }
    };

    const auditLogsRoutes = (): RouteObject[] => {
        const auditLogsChildren: RouteObject[] = [];
        const auditLogsPath = '/audit-logs';

        if (userPrivileges.includes(UserPrivileges.ViewAuditLogs)) {
            const auditLogsRoute: RouteObject = {
                path: `${auditLogsPath}`,
                element: <AuditLogs />
            };

            auditLogsChildren.push(auditLogsRoute);

            return [
                {
                    path: auditLogsPath,
                    children: auditLogsChildren
                }
            ];
        } else {
            return [];
        }
    };

    const feeRulesRoutes = (): RouteObject[] => {
        const feeRulesChildren: RouteObject[] = [];
        const feeRulesPath = '/fee-rules';

        if (userPrivileges.includes(UserPrivileges.ViewFeeRule)) {
            const viewFeeRulesRoute: RouteObject = {
                path: `${feeRulesPath}/view`,
                element: <ViewFeeRules />
            };
            feeRulesChildren.push(viewFeeRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.RequestFeeRuleUpdate)) {
            const updateFeeRulesRoute: RouteObject = {
                path: `${feeRulesPath}/update`,
                element: <UpdateFeeRules />
            };
            const editFeeRulesRoute: RouteObject = {
                path: `${feeRulesPath}/update/edit`,
                element: <EditFeeRules />
            };
            feeRulesChildren.push(updateFeeRulesRoute);
            feeRulesChildren.push(editFeeRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.CheckFeeRuleRequest)) {
            const approveFeeRulesRoute: RouteObject = {
                path: `${feeRulesPath}/approve`,
                element: <ApproveFeeRules />
            };
            feeRulesChildren.push(approveFeeRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.ViewFeeRuleHistory)) {
            const viewFeeRulesHistoryRoute: RouteObject = {
                path: `${feeRulesPath}/history`,
                element: <ViewFeeRulesHistory />
            };
            const viewFeeRuleHistoryDetailsRoute: RouteObject = {
                path: `${feeRulesPath}/history/details`,
                element: <FeeRulesHistoryRequestDetails />
            };
            feeRulesChildren.push(viewFeeRulesHistoryRoute);
            feeRulesChildren.push(viewFeeRuleHistoryDetailsRoute);
        }

        if (feeRulesChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: feeRulesPath,
                    children: feeRulesChildren
                }
            ];
        }
    };

    const unclaimedFundsRoutes = (): RouteObject[] => {
        const unclaimedFundsChildren: RouteObject[] = [];
        const cbsTransferFundsPath = '/unclaimed-funds';

        if (userPrivileges.includes(UserPrivileges.ViewUnclaimedFunds)) {
            const requestUnclaimedFundsListRoute: RouteObject = {
                path: `${cbsTransferFundsPath}/search`,
                element: <CBSTransferFunds />
            };
            unclaimedFundsChildren.push(requestUnclaimedFundsListRoute);

            const unclaimedFundsRequestDetailsRoute: RouteObject = {
                path: `${cbsTransferFundsPath}/search/details`,
                element: <CBSRequestDetails />
            };
            unclaimedFundsChildren.push(unclaimedFundsRequestDetailsRoute);

            const cbsTransactionDetail: RouteObject = {
                path: `${cbsTransferFundsPath}/search/transaction-details`,
                element: <CBSTransactionDetails />
            };
            unclaimedFundsChildren.push(cbsTransactionDetail);

            const unclaimedFundsViewHistoryRoute: RouteObject = {
                path: `${cbsTransferFundsPath}/view-history`,
                element: <ViewHistory />
            };
            unclaimedFundsChildren.push(unclaimedFundsViewHistoryRoute);

            const unclaimedFundsViewHistoryRequestDetailsRoute: RouteObject = {
                path: `${cbsTransferFundsPath}/view-history/details`,
                element: <ViewHistoryRequestDetails />
            };
            unclaimedFundsChildren.push(unclaimedFundsViewHistoryRequestDetailsRoute);
        }

        if (userPrivileges.includes(UserPrivileges.CheckCBSTransferRequest)) {
            const approveRequestsRoute: RouteObject = {
                path: `${cbsTransferFundsPath}/approve-cbs-transfer`,
                element: <ApproveCBSTransfer />
            };
            unclaimedFundsChildren.push(approveRequestsRoute);
        }

        if (unclaimedFundsChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: cbsTransferFundsPath,
                    children: unclaimedFundsChildren
                }
            ];
        }
    };

    const boUsersRoutes = (): RouteObject[] => {
        const boUsersChildren: RouteObject[] = [];
        const boUsersPath = '/bo';

        if (userPrivileges.includes(UserPrivileges.ViewBOUser)) {
            const boUsersRoute: RouteObject = {
                path: `${boUsersPath}/users`,
                element: <BOUsers />
            };

            const boUserDetailRoute: RouteObject = {
                path: `${boUsersPath}/users/details`,
                element: <BOUserDetails />
            };

            const boUserViewRequestsRoute: RouteObject = {
                path: `${boUsersPath}/view-requests`,
                element: <BOUserRequests />
            };

            boUsersChildren.push(boUsersRoute);
            boUsersChildren.push(boUserDetailRoute);
            boUsersChildren.push(boUserViewRequestsRoute);
        }

        if (boUsersChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: boUsersPath,
                    children: boUsersChildren
                }
            ];
        }
    };

    const bcnUsersRoutes = (): RouteObject[] => {
        const bcnUsersChildren: RouteObject[] = [];
        const bcnUsersPath = '/yafika-mobile';

        if (userPrivileges.includes(UserPrivileges.ViewBCNUser)) {
            const bcnUsersRoute: RouteObject = {
                path: `${bcnUsersPath}/users`,
                element: <BCNUsers />
            };

            const bcnUserDetailsRoute: RouteObject = {
                path: `${bcnUsersPath}/users/details`,
                element: <BCNUserDetails />
            };

            const bcnUserViewRequestRoute: RouteObject = {
                path: `${bcnUsersPath}/view-requests`,
                element: <BCNUserViewRequest />
            };

            bcnUsersChildren.push(bcnUsersRoute);
            bcnUsersChildren.push(bcnUserDetailsRoute);
            bcnUsersChildren.push(bcnUserViewRequestRoute);
        }
        if (bcnUsersChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: bcnUsersPath,
                    children: bcnUsersChildren
                }
            ];
        }
    };

    const genericFundsTransferRoutes = (): RouteObject[] => {
        const genericFundsTransferChildren: RouteObject[] = [];
        const genericFundsTransferPath = '/generic-funds-transfer';

        if (userPrivileges.includes(UserPrivileges.ViewFundsTransfer)) {
            const transferRequestRoute: RouteObject = {
                path: `${genericFundsTransferPath}/transfer-requests`,
                element: <TransferRequest />
            };
            genericFundsTransferChildren.push(transferRequestRoute);

            const transferDetail: RouteObject = {
                path: `${genericFundsTransferPath}/transfer-requests/details`,
                element: <TransferDetails />
            };
            genericFundsTransferChildren.push(transferDetail);
        }

        if (userPrivileges.includes(UserPrivileges.RequestFundsTransfer)) {
            const requestFundsTransferRoute: RouteObject = {
                path: `${genericFundsTransferPath}/request`,
                element: <GenericFundsTransferRequest />
            };
            genericFundsTransferChildren.push(requestFundsTransferRoute);
        }

        if (genericFundsTransferChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: genericFundsTransferPath,
                    children: genericFundsTransferChildren
                }
            ];
        }
    };

    const transactionRulesRoutes = (): RouteObject[] => {
        const transactionRulesChildren: RouteObject[] = [];
        const transactionRulesPath = '/transaction-rules';

        if (userPrivileges.includes(UserPrivileges.ViewTransactionRule)) {
            const viewTransactionRulesRoute: RouteObject = {
                path: `${transactionRulesPath}/view`,
                element: <ViewTransactionRules />
            };
            transactionRulesChildren.push(viewTransactionRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.RequestTransactionRuleUpdate)) {
            const updateTransactionRulesRoute: RouteObject = {
                path: `${transactionRulesPath}/update`,
                element: <UpdateTransactionRules />
            };
            const editTransactionRulesRoute: RouteObject = {
                path: `${transactionRulesPath}/update/edit`,
                element: <EditTransactionRules />
            };
            transactionRulesChildren.push(updateTransactionRulesRoute);
            transactionRulesChildren.push(editTransactionRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.CheckTransactionRuleRequest)) {
            const approveTransactionRulesRoute: RouteObject = {
                path: `${transactionRulesPath}/approve`,
                element: <ApproveTransactionRules />
            };
            transactionRulesChildren.push(approveTransactionRulesRoute);
        }

        if (userPrivileges.includes(UserPrivileges.ViewTransactionRuleHistory)) {
            const viewTransactionRulesHistoryRoute: RouteObject = {
                path: `${transactionRulesPath}/history`,
                element: <ViewTransactionRulesHistory />
            };
            transactionRulesChildren.push(viewTransactionRulesHistoryRoute);
            const viewTransactionRuleHistoryDetailsRoute: RouteObject = {
                path: `${transactionRulesPath}/history/details`,
                element: <TransactionRuleHistoryRequestDetails />
            };
            transactionRulesChildren.push(viewTransactionRuleHistoryDetailsRoute);
        }

        if (transactionRulesChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: transactionRulesPath,
                    children: transactionRulesChildren
                }
            ];
        }
    };

    const agencyBankingRoutes = (): RouteObject[] => {
        const agencyBankingChildren: RouteObject[] = [];
        const agencyBankingPath = '/agency-banking';

        if (userPrivileges.includes(UserPrivileges.ViewAgentTransaction)) {
            const agentTransactionsRoute: RouteObject = {
                path: `${agencyBankingPath}/transactions`,
                element: <AgentTransactionList />
            };
            agencyBankingChildren.push(agentTransactionsRoute);

            const agentTransactionDetail: RouteObject = {
                path: `${agencyBankingPath}/transactions/details`,
                element: <AgentTransactionDetails />
            };
            agencyBankingChildren.push(agentTransactionDetail);

            const agentTransactionRequestHistory: RouteObject = {
                path: `${agencyBankingPath}/transaction-request-history`,
                element: <AgentTransactionRequestHistory />
            };
            agencyBankingChildren.push(agentTransactionRequestHistory);
        }

        if (userPrivileges.includes(UserPrivileges.ViewAgent)) {
            const agentUsers: RouteObject = {
                path: `${agencyBankingPath}/agents`,
                element: <Agents />
            };
            agencyBankingChildren.push(agentUsers);

            const agentDetails: RouteObject = {
                path: `${agencyBankingPath}/agents/details`,
                element: <AgentDetails />
            };
            agencyBankingChildren.push(agentDetails);

            const agentRequestsHistory: RouteObject = {
                path: `${agencyBankingPath}/agent-requests-history`,
                element: <AgentRequestHistory />
            };
            agencyBankingChildren.push(agentRequestsHistory);
        }

        if (agencyBankingChildren.length === 0) {
            return [];
        } else {
            return [
                {
                    path: agencyBankingPath,
                    children: agencyBankingChildren
                }
            ];
        }
    };

    const profileSettingRoutes = (): RouteObject[] => {
        const profileSettingChildren: RouteObject[] = [];
        const profileSettingPath = '/profile-settings';

        const profileSettingRoute: RouteObject = {
            path: `${profileSettingPath}`,
            element: <ProfileSetting />
        };

        profileSettingChildren.push(profileSettingRoute);

        return [
            {
                path: profileSettingPath,
                children: profileSettingChildren
            }
        ];
    };

    const homeRoutesChildren: RouteObject[] = [];
    homeRoutesChildren.push(...transactionRoutes());
    homeRoutesChildren.push(...feeRulesRoutes());
    homeRoutesChildren.push(...transactionRulesRoutes());
    homeRoutesChildren.push(...genericFundsTransferRoutes());
    homeRoutesChildren.push(...unclaimedFundsRoutes());
    homeRoutesChildren.push(...bcnUsersRoutes());
    homeRoutesChildren.push(...boUsersRoutes());
    homeRoutesChildren.push(...agencyBankingRoutes());
    homeRoutesChildren.push(...reportsRoutes());
    homeRoutesChildren.push(...auditLogsRoutes());
    homeRoutesChildren.push(...profileSettingRoutes());

    return [
        {
            path: '/',
            element: <HomeLayout />,
            children: [...homeRoutesChildren]
        }
    ];
};

const getRoutes = (userPrivileges: string[]): RouteObject[] => {
    const errorRoutes = (): RouteObject[] => {
        const pageNotFound: RouteObject = {
            path: '/404',
            element: <PageNotFound />
        };
        const internalServerError: RouteObject = {
            path: '/500',
            element: <InternalServerError />
        };
        const pathNotFound: RouteObject = {
            path: '*',
            element: <PageNotFound />
        };

        return [
            {
                path: '/',
                element: <ErrorLayout />,
                children: [pageNotFound, internalServerError, pathNotFound]
            }
        ];
    };

    return [
        {
            path: '/',
            element: <RootPage />
        },
        ...errorRoutes(),
        ...authRoutes,
        ...homeRoutes(userPrivileges)
    ];
};

export const Router = observer((): React.ReactElement | null => {
    const userPrivileges = useUserStore().privileges;

    const routes = getRoutes(userPrivileges);
    return useRoutes(routes);
});
