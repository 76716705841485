import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    GetFeeDeterminationRuleRequestHistoryPaginationResponse,
    GetFeeDeterminationRuleRequestHistoryRPC,
    PGUserName,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';

const requestList: GetFeeDeterminationRuleRequestHistoryPaginationResponse[] = [
    new GetFeeDeterminationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.PENDING,
        new PGUserName('Jane', 'Cooper'),
        null,
        new LeoUUID()
    ),
    new GetFeeDeterminationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.APPROVED,
        new PGUserName('Jane', 'Cooper'),
        null,
        new LeoUUID()
    ),
    new GetFeeDeterminationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.DENIED,
        new PGUserName('Jane', 'Cooper'),
        null,
        new LeoUUID()
    ),
    new GetFeeDeterminationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.WITHDRAWN,
        new PGUserName('Jane', 'Cooper'),
        null,
        new LeoUUID()
    ),
    new GetFeeDeterminationRuleRequestHistoryPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        RequestStatus.RequestStatus.DISCARDED,
        new PGUserName('Jane', 'Cooper'),
        null,
        new LeoUUID()
    )
];

export class MockGetFeeDeterminationRuleRequestHistoryRPCImpl extends GetFeeDeterminationRuleRequestHistoryRPC {
    execute(
        request: GetFeeDeterminationRuleRequestHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetFeeDeterminationRuleRequestHistoryRPC.Response,
            GetFeeDeterminationRuleRequestHistoryRPC.Errors.Errors
        >
    > {
        const list = requestList.filter((viewRequest) => {
            if (request.requestStatus) {
                return viewRequest.requestStatus === request.requestStatus;
            } else {
                return viewRequest;
            }
        });
        const response = new GetFeeDeterminationRuleRequestHistoryRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetFeeDeterminationRuleRequestHistoryRPC.Response,
                GetFeeDeterminationRuleRequestHistoryRPC.Errors.Errors
            >
        >;
    }
}
