import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { EvaluateFeeRulesStore } from './EvaluateFeeRulesStore';
import { UpdateFeeRulesStore } from './UpdateFeeRulesStore';
import { ViewFeeRulesStore } from './ViewFeeRulesStore';
import { ViewFeeRulesHistoryStore } from './ViewFeeRulesHistoryStore';
import { FeeRulesRequestDetailsStore } from './FeeRulesRequestDetailsStore';

export const useUpdateFeeRulesStore = (): Instance<typeof UpdateFeeRulesStore> => {
    return useRootStore().homeStore.feeRules.updateFeeRulesStore;
};

export const useEvaluateFeeRulesStore = (): Instance<typeof EvaluateFeeRulesStore> => {
    return useRootStore().homeStore.feeRules.evaluateFeeRulesStore;
};

export const useViewFeeRulesStore = (): Instance<typeof ViewFeeRulesStore> => {
    return useRootStore().homeStore.feeRules.viewFeeRulesStore;
};

export const useViewFeeRulesHistoryStore = (): Instance<typeof ViewFeeRulesHistoryStore> => {
    return useRootStore().homeStore.feeRules.viewFeeRulesHistoryStore;
};
export const useFeeRulesRequestDetailsStore = (): Instance<typeof FeeRulesRequestDetailsStore> => {
    return useRootStore().homeStore.feeRules.feeRulesRequestDetailsStore;
};
