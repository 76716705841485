import React, { useEffect, useState } from 'react';
import { DropdownInputField, DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { getRequestStatusOptionsWithAny } from '../../common/utils/StatusUIUtils';

interface AgentRequestsHistoryFilterProps {
    statusFilter: string;
    setStatusFilter: (status: string) => void;
}

export const AgentRequestsHistoryFilter = ({
    statusFilter,
    setStatusFilter
}: AgentRequestsHistoryFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const [requestStatus, setRequestStatus] = useState(statusFilter);

    useEffect(() => {
        setRequestStatus(statusFilter);
    }, [statusFilter]);

    const onRequestStatusDropdownChange = (selected: DropdownItem): void => {
        setStatusFilter(selected.value);
    };

    return (
        <TableFilterContainer>
            <DropdownInputField
                style={{ width: '132px' }}
                name="RequestStatusDropdown"
                value={requestStatus}
                onSelect={onRequestStatusDropdownChange}
                label={t('common.requestStatus')}
                options={getRequestStatusOptionsWithAny()}
            />
        </TableFilterContainer>
    );
};
