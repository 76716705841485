import { DownloadFeeRulesStore, createDownloadFeeRuleStore } from './DownloadFeeRulesStore';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    useGetCountryAndCBSIdListForFDRRPCClient,
    useGetCurrentFeeDeterminationRuleDetailsRPCClient,
    useGetCurrentFeeRuleDetailsFileRPCClient
} from '../rpcs/RPC';
import {
    GetCountryAndCBSIdListForFDRRPC,
    GetCurrentFeeDeterminationRuleDetailsFileRPC,
    GetCurrentFeeDeterminationRuleDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    FeeDeterminationRuleModel,
    getFeeDeterminationRuleModel
} from '../models/FeeDeterminationRuleModel';
import { getLoggerStore } from '../../../../log/hooks';
import {
    CBSIdWithCountry,
    CountryCbsIdListModel,
    getCountryCbsIdListModel
} from '../../common/models/CountryCbsIdListModel';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ViewFeeRulesStore = types
    .model('ViewFeeRules', {
        currentFileDocumentId: types.maybeNull(types.string),
        downloadFileStore: DownloadFeeRulesStore,
        currentFeeDeterminationRules: types.maybeNull(types.array(FeeDeterminationRuleModel)),
        countryCbsIdList: types.array(CountryCbsIdListModel),
        error: types.maybeNull(
            types.enumeration<NetworkingError>('NetworkingError', Object.values(NetworkingError))
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchCurrentFeeDeterminationRuleDetailsFile: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new GetCurrentFeeDeterminationRuleDetailsFileRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetCurrentFeeDeterminationRuleDetailsFileRPC.Response,
                never
            > = yield useGetCurrentFeeRuleDetailsFileRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.currentFileDocumentId = response.currentFileRecordId?.uuid ?? null;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetCurrentFeeDeterminationRuleDetailsFileRPC`
                );
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetCurrentFeeDeterminationRuleDetailsFileRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        fetchCurrentFeeDeterminationRuleDetails: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new GetCurrentFeeDeterminationRuleDetailsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetCurrentFeeDeterminationRuleDetailsRPC.Response,
                GetCurrentFeeDeterminationRuleDetailsRPC.Errors.Errors
            > = yield useGetCurrentFeeDeterminationRuleDetailsRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.currentFeeDeterminationRules =
                    response.feeDeterminationRules &&
                    cast(
                        response.feeDeterminationRules.map((feeDeterminationRule) =>
                            getFeeDeterminationRuleModel(feeDeterminationRule)
                        )
                    );
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetCurrentFeeDeterminationRuleDetailsRPC`
                );
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetCurrentFeeDeterminationRuleDetailsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        fetchCountryAndCBSIdList: flow(function* () {
            if (store.countryCbsIdList.length) {
                return;
            } else {
                const loggerStore = getLoggerStore(store);
                const request = new GetCountryAndCBSIdListForFDRRPC.Request();
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetCountryAndCBSIdListForFDRRPC.Response,
                    GetCountryAndCBSIdListForFDRRPC.Errors.Errors
                > = yield useGetCountryAndCBSIdListForFDRRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const list = response.countryCBSIds.map((countryCbsIdList) => {
                        return getCountryCbsIdListModel(countryCbsIdList);
                    });
                    store.countryCbsIdList = cast(list);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in GetCountryAndCBSIdListForFDRRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .views((store) => ({
        getCbsIdWithCountryList(): CBSIdWithCountry[] {
            return store.countryCbsIdList.map((list) => {
                return {
                    country: list.name,
                    cbsId: list.cbsId
                };
            });
        },
        getCbsIdCountryList(): string[] {
            return store.countryCbsIdList.map((country) => country.name);
        }
    }));

export const createViewFeeRulesStore = (): Instance<typeof ViewFeeRulesStore> => {
    return ViewFeeRulesStore.create({
        downloadFileStore: createDownloadFeeRuleStore(),
        countryCbsIdList: []
    });
};
