import { LeoErrors } from './../../common/errors/LeoErrors';
import { useBackOfficeChangePasswordRPCClient } from './../rpcs/RPC';
import { BackOfficeChangePasswordRPC, Password } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { PasswordPolicyModel } from '../../../common/models/PasswordPolicyModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { ProfileStore } from './ProfileStore';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ChangePasswordErrors {
    TooManyRequests = 'TOO_MANY_REQUESTS',
    IncorrectOldPassword = 'INCORRECT_OLD_PASSWORD',
    InsecurePassword = 'INSECURE_PASSWORD',
    ReusedPassword = 'REUSED_PASSWORD',
    PasswordMismatch = 'PASSWORD_MISMATCH',
    InvalidPasswordError = 'INVALID_PASSWORD_ERROR',
    TooManyIncorrectAttempts = 'TOO_MANY_INCORRECT_ATTEMPTS'
}

const getStoreError = (
    error: BackOfficeChangePasswordRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): ChangePasswordErrors | NetworkingError => {
    switch (error.code) {
        case ChangePasswordErrors.TooManyRequests:
            return ChangePasswordErrors.TooManyRequests;
        case ChangePasswordErrors.InsecurePassword:
            return ChangePasswordErrors.InsecurePassword;
        case ChangePasswordErrors.PasswordMismatch:
            return ChangePasswordErrors.PasswordMismatch;
        case ChangePasswordErrors.InvalidPasswordError:
            return ChangePasswordErrors.InvalidPasswordError;
        case ChangePasswordErrors.ReusedPassword:
            return ChangePasswordErrors.ReusedPassword;
        case ChangePasswordErrors.TooManyIncorrectAttempts:
            return ChangePasswordErrors.TooManyIncorrectAttempts;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in BackOfficeChangePasswordRPC`);
            return NetworkingError.InternalError;
    }
};

export const ChangePasswordStore = types
    .model({
        oldPassword: types.maybeNull(types.string),
        newPassword: types.maybeNull(types.string),
        confirmNewPassword: types.maybeNull(types.string),
        numberOfValidAttemptsLeft: types.maybeNull(types.number),
        error: types.maybeNull(
            types.union(
                types.enumeration<ChangePasswordErrors>(
                    'ChangePasswordErrors',
                    Object.values(ChangePasswordErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetStore(): void {
            store.oldPassword = null;
            store.newPassword = null;
            store.confirmNewPassword = null;
            store.error = null;
            store.numberOfValidAttemptsLeft = null;
        },
        setNewPassword(newPassword: string): void {
            store.newPassword = newPassword;
        },
        setOldPassword(oldPassword: string): void {
            store.oldPassword = oldPassword;
        },
        setConfirmNewPassword(confirmNewPassword: string): void {
            store.confirmNewPassword = confirmNewPassword;
        },
        removeError(): void {
            store.error = null;
        },
        submitChangePassword: flow(function* () {
            const loggerStore = getLoggerStore(store);
            if (store.newPassword === store.confirmNewPassword) {
                try {
                    store.error = null;
                    // Since we provide the input so oldPassword, newPassword cannot be null and will be present. Hence it is safe to force cast here
                    const oldPassword = new Password(store.oldPassword!);
                    const newPassword = new Password(store.newPassword!);
                    const request: BackOfficeChangePasswordRPC.Request =
                        new BackOfficeChangePasswordRPC.Request(oldPassword, newPassword);
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        BackOfficeChangePasswordRPC.Response,
                        BackOfficeChangePasswordRPC.Errors.Errors
                    > = yield useBackOfficeChangePasswordRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (
                            error instanceof BackOfficeChangePasswordRPC.Errors.IncorrectOldPassword
                        ) {
                            store.error = ChangePasswordErrors.IncorrectOldPassword;
                            store.numberOfValidAttemptsLeft = error.numberOfPasswordAttemptsLeft;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in BackOfficeChangePasswordRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        switch (error.name) {
                            case LeoErrors.InvalidPasswordError:
                                store.error = ChangePasswordErrors.InvalidPasswordError;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in BackOfficeChangePasswordRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error: ${error} occurred in BackOfficeChangePasswordRPC`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                }
            } else {
                store.error = ChangePasswordErrors.PasswordMismatch;
            }
        })
    }))
    .views((store) => ({
        getPasswordPolicy: (): Instance<typeof PasswordPolicyModel> | null => {
            const profileStore = getParent<typeof ProfileStore>(store);
            return profileStore.passwordPolicy();
        }
    }));

export const createChangePasswordStore = (): Instance<typeof ChangePasswordStore> => {
    return ChangePasswordStore.create();
};
