import { Box, Stack, SxProps } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { CommentComponent } from './CommentComponent';
import { StatusType } from './StatusComponent';
import { UserActionStatusHeader } from './UserActionStatusHeader';

interface UserCommentComponentProps {
    title: string;
    subtitle: string;
    status: StatusType | null;
    profileImageUrl: string | null;
    comment: string;
    children: React.ReactNode;
    showDivider?: boolean;
    sx?: SxProps;
}

export const UserCommentComponent = ({
    title,
    subtitle,
    status,
    profileImageUrl,
    comment,
    children,
    showDivider,
    sx
}: UserCommentComponentProps): React.ReactElement => {
    const palette = usePalette();

    return (
        <Stack>
            <UserActionStatusHeader
                title={title}
                subtitle={subtitle}
                status={status ?? undefined}
                profilePhotoUrl={profileImageUrl}
                sx={{ ...sx }}
            />
            <Box
                sx={{
                    paddingLeft: showDivider && status === StatusType.PENDING ? '' : '80px',
                    borderTop: showDivider ? `1px solid ${palette.outline[200]}` : ''
                }}>
                <CommentComponent
                    text={comment}
                    sx={{
                        marginTop: showDivider ? '32px' : '',
                        paddingLeft: showDivider && status === StatusType.PENDING ? '80px' : '',
                        marginBottom: showDivider ? '16px' : '0px'
                    }}
                />
                {children}
            </Box>
        </Stack>
    );
};
