import { WithdrawFeeDeterminationRuleRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockWithdrawFeeDeterminationRuleRequestRPCImpl extends WithdrawFeeDeterminationRuleRequestRPC {
    execute(
        _request: WithdrawFeeDeterminationRuleRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            WithdrawFeeDeterminationRuleRequestRPC.Response,
            WithdrawFeeDeterminationRuleRequestRPC.Errors.Errors
        >
    > {
        const response = new WithdrawFeeDeterminationRuleRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                WithdrawFeeDeterminationRuleRequestRPC.Response,
                WithdrawFeeDeterminationRuleRequestRPC.Errors.Errors
            >
        >;
    }
}
