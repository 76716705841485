import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignInStore } from '../stores/hooks';

interface AuthProtectedRouterProps {
    children: React.ReactNode;
}

export const AuthProtectedRouter = ({ children }: AuthProtectedRouterProps): React.ReactElement => {
    const signInStore = useSignInStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const unauthenticatedPath = '/auth/enter-mobile-number';

    const validateAuthCodePath = (): void => {
        if (signInStore.passwordValidatedToken() && signInStore.authCodeLHS()) {
            // since the previous screen's data is present, we can let the user proceed.
        } else {
            navigate('/');
        }
    };

    const validateOTPPath = (): void => {
        if (
            signInStore.passwordValidatedToken() &&
            signInStore.authCodeLHS() &&
            signInStore.otpId() &&
            signInStore.otpNextResendAt()
        ) {
            // since the previous screen's data is present, we can let the user proceed.
        } else {
            navigate('/');
        }
    };

    const validateNewPasswordPath = (): void => {
        if (
            signInStore.passwordValidatedToken() &&
            signInStore.authCodeLHS() &&
            signInStore.otpId() &&
            signInStore.otpNextResendAt() &&
            signInStore.otpValidatedToken()
        ) {
            // since the previous screen's data is present, we can let the user proceed.
        } else {
            navigate('/');
        }
    };

    const validateAuthPath = (): void => {
        if (pathname === '/auth/confirm-auth-code') {
            validateAuthCodePath();
        } else if (pathname === '/auth/enter-otp') {
            validateOTPPath();
        } else if (pathname === '/auth/set-new-password') {
            validateNewPasswordPath();
        }
    };

    useEffect(() => {
        if (pathname !== unauthenticatedPath) {
            validateAuthPath();
        }
    }, [signInStore]);

    return <>{children}</>;
};
