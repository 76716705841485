import { Instance, types } from 'mobx-state-tree';
import { BOUserDetailStore, createBOUserDetailStore } from './BOUserDetailStore';
import { BOUserSearchStore, createBOUserSearchStore } from './BOUserSearchStore';
import { createBORoleStore, BORoleStore } from './BORoleStore';
import {
    BOUserProfileManagementStore,
    createBOUserProfileManagementStore
} from './BOUserProfileManagementStore';
import { BOUserRequestsStore, createBOUserRequestsStore } from './BOUserRequestsStore';
import {
    BOUserRequestHistoryStore,
    createBoUserRequestHistoryStore
} from './BOUserRequestHistoryStore';
import {
    BOUserRequestSummaryStore,
    createBOUserRequestSummaryStore
} from './BOUserRequestSummaryStore';
import {
    CheckBOUserChangeRequestStore,
    createCheckBOUserChangeRequestStore
} from './CheckBOUserChangeRequestStore';
import {
    BOUserWithdrawRequestStore,
    createBOUserWithdrawRequestStore
} from './BOUserWithdrawRequestStore';
import {
    createNewBOUserRequestDetailsStore,
    NewBOUserRequestDetailsStore
} from './NewBOUserDetailsStore';
import {
    createValidateBOUserDetailsStore,
    ValidateBOUserDetailsStore
} from './ValidateBOUserDetailsStore';
import {
    createRequestToAddEditBOUserStore,
    RequestToAddEditBOUserStore
} from './RequestToAddEditBOUserStore';
import { createNewBOUserCheckStoreStore, NewBOUserCheckStore } from './NewBOUserCheckStore';

export const BOUserStore = types.model({
    boUserSearchStore: BOUserSearchStore,
    boUserDetailsStore: BOUserDetailStore,
    boRoleStore: BORoleStore,
    boUserProfileManagementStore: BOUserProfileManagementStore,
    boUserRequestsStore: BOUserRequestsStore,
    boUserRequestHistoryStore: BOUserRequestHistoryStore,
    boUserRequestSummaryStore: BOUserRequestSummaryStore,
    checkBOUserChangeRequestStore: CheckBOUserChangeRequestStore,
    boUserWithdrawRequestStore: BOUserWithdrawRequestStore,
    newBOUserRequestDetailsStore: NewBOUserRequestDetailsStore,
    requestToAddEdtBOUserStore: RequestToAddEditBOUserStore,
    validateBOUsersDetailsStore: ValidateBOUserDetailsStore,
    newBOUserCheckStore: NewBOUserCheckStore
});

export const createBOUserStore = (): Instance<typeof BOUserStore> => {
    return BOUserStore.create({
        boUserSearchStore: createBOUserSearchStore(),
        boUserDetailsStore: createBOUserDetailStore(),
        boRoleStore: createBORoleStore(),
        boUserProfileManagementStore: createBOUserProfileManagementStore(),
        boUserRequestsStore: createBOUserRequestsStore(),
        boUserRequestHistoryStore: createBoUserRequestHistoryStore(),
        boUserRequestSummaryStore: createBOUserRequestSummaryStore(),
        checkBOUserChangeRequestStore: createCheckBOUserChangeRequestStore(),
        boUserWithdrawRequestStore: createBOUserWithdrawRequestStore(),
        newBOUserRequestDetailsStore: createNewBOUserRequestDetailsStore(),
        requestToAddEdtBOUserStore: createRequestToAddEditBOUserStore(),
        validateBOUsersDetailsStore: createValidateBOUserDetailsStore(),
        newBOUserCheckStore: createNewBOUserCheckStoreStore()
    });
};
