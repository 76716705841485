import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { GetTransactionValidationRuleHistoryRequestDetailsRPC } from '@resolut-tech/bcn-rpcs';
import { useGetTransactionValidationRuleHistoryRequestDetailsRPCClient } from '../rpcs/RPC';
import {
    FileDetailsModel,
    getCurrentRuleFileModel,
    getNewRuleFileModel
} from '../../common/models/RulesModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import {
    getRequestDetailsModel,
    RequestDetailsModel
} from '../../common/models/RequestDetailsModel';
import {
    createTransactionValidationRulesDiffStore,
    TransactionValidationRulesDiffStore
} from './TransactionValidationRulesDiffStore';
import {
    createDownloadTransactionRulesStore,
    DownloadTransactionRulesStore
} from './DownloadTransactionRulesStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum TransactionRuleRequestDetailsStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID'
}

export const TransactionRuleRequestDetailsStore = types
    .model('TransactionRuleRequestDetailsStore', {
        requestId: types.maybeNull(types.string),
        requestDetails: types.maybeNull(RequestDetailsModel),
        newRuleFile: types.maybeNull(FileDetailsModel),
        oldRuleFile: types.maybeNull(FileDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<TransactionRuleRequestDetailsStoreErrors>(
                    'TransactionRuleRequestDetailsStoreErrors',
                    Object.values(TransactionRuleRequestDetailsStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        ),
        downloadFileStore: DownloadTransactionRulesStore,
        transactionValidationRulesDiffStore: TransactionValidationRulesDiffStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.requestId = null;
            store.requestDetails = null;
            store.newRuleFile = null;
            store.oldRuleFile = null;
            store.error = null;
        },
        removeError(): void {
            store.error = null;
        },
        fetchTransactionRuleDetails: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetTransactionValidationRuleHistoryRequestDetailsRPC.Request(
                    new LeoUUID(requestId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetTransactionValidationRuleHistoryRequestDetailsRPC.Response,
                    GetTransactionValidationRuleHistoryRequestDetailsRPC.Errors.Errors
                > = yield useGetTransactionValidationRuleHistoryRequestDetailsRPCClient(
                    apiClient
                ).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.requestId = response.requestDetails.requestId.uuid;
                    store.requestDetails = getRequestDetailsModel(response.requestDetails);
                    store.newRuleFile = getNewRuleFileModel(response.newRuleFile);
                    store.oldRuleFile = getCurrentRuleFileModel(response.oldRuleFile);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case TransactionRuleRequestDetailsStoreErrors.InvalidRequestId:
                            store.error = TransactionRuleRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionValidationRuleHistoryRequestDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetTransactionValidationRuleHistoryRequestDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = TransactionRuleRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionValidationRuleHistoryRequestDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetTransactionValidationRuleHistoryRequestDetailsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createTransactionRuleRequestDetailsStore = (): Instance<
    typeof TransactionRuleRequestDetailsStore
> => {
    return TransactionRuleRequestDetailsStore.create({
        downloadFileStore: createDownloadTransactionRulesStore(),
        transactionValidationRulesDiffStore: createTransactionValidationRulesDiffStore()
    });
};
