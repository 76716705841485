import { RequestBOUserArchiveRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestBOUserArchiveRPCImpl extends RequestBOUserArchiveRPC {
    execute(
        _request: RequestBOUserArchiveRPC.Request
    ): Promise<
        LeoRPCResult<RequestBOUserArchiveRPC.Response, RequestBOUserArchiveRPC.Errors.Errors>
    > {
        const response = new RequestBOUserArchiveRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<RequestBOUserArchiveRPC.Response, RequestBOUserArchiveRPC.Errors.Errors>
        >;
    }
}
