import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BitmapImageType,
    BOName,
    BOUserComment,
    Comment,
    GetBOUserDetailRequestsHistoryRPC,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RequestDetails,
    UserName
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';

export class MockGetBOUserDetailRequestsHistoryRPCImpl extends GetBOUserDetailRequestsHistoryRPC {
    execute(
        _request: GetBOUserDetailRequestsHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetBOUserDetailRequestsHistoryRPC.Response,
            GetBOUserDetailRequestsHistoryRPC.Errors.Errors
        >
    > {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );

        const requests = [
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            ),
            new RequestDetails.Approved(
                new LeoUUID('7a1de96b-d2f7-4ba5-827b-564a6906171a'),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            ),
            new RequestDetails.Denied(
                new LeoUUID('9dbaf522-d69e-473a-a318-83c70565f7a1'),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            ),
            new RequestDetails.Withdrawn(
                new LeoUUID('a3e12cbe-2407-4959-a5a3-e385cb00b0b9'),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                ),
                new LeoTimestamp(),
                new Comment('The user had requested for the changes')
            ),
            new RequestDetails.Discarded(
                new LeoUUID('4073e1fe-8d86-4beb-b17c-2ede8252d893'),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            ),
            new RequestDetails.Approved(
                new LeoUUID('130f7a5b-a8a7-42b8-a2ac-fc97e1e88499'),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    profilePicture,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            )
        ];

        const response = new GetBOUserDetailRequestsHistoryRPC.Response(requests);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOUserDetailRequestsHistoryRPC.Response,
                GetBOUserDetailRequestsHistoryRPC.Errors.Errors
            >
        >;
    }
}
