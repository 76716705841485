import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import {
    useBCNUserCheckRequestStore,
    useBCNUserDetailsStore,
    useBCNUserWithdrawRequestStore
} from '../store/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BasicBCNUserDetailsSection } from '../components/BasicBCNUserDetailsSection';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import { KYCDocumentHistorySection } from '../components/KYCDocumentHistorySection';
import { BCNUserAccountDetails } from '../components/BCNAccountDetails';
import { UserPrivileges } from '../../user/UserPrivileges';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import { LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getUserStatus } from '../../common/utils/UIUtils';
import { BCNUserUpdateRequestDetailsEnums } from '@resolut-tech/bcn-rpcs/build/back-office/bCNUserUpdateRequestDetails';
import { ProcessingRequestDetailsCard } from '../components/ProcessingRequestDetailsCard';
import { useUserStore } from '../../store/hooks';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { Status } from '../../common/enums/StatusEnum';
import { ProfileManagementSection } from '../components/ProfileManagementSection';
import { BCNUserDetailsStoreErrors } from '../store/BCNUserDetailsStore';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import { ArchiveNonZeroAccountDetailsDialog } from '../components/ArchiveNonZeroAccountDetailsDialog';
import { useCountdownTimer } from '../utils/useCountdownTimer';
import { BCNUserCheckRequestStoreErrors } from '../store/BCNUserCheckRequestStore';
import { BCNRequestHistorySection } from '../components/BCNRequestHistorySection';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const BCNUserDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const store = useBCNUserDetailsStore();
    const withdrawRequestStore = useBCNUserWithdrawRequestStore();
    const checkRequestStore = useBCNUserCheckRequestStore();
    const userStore = useUserStore();
    const loggerStore = useLoggerStore();
    const navigate = useNavigate();
    const [isArchiveNonZeroAccountDetailsDialogOpen, setIsArchiveNonZeroAccountDetailsDialogOpen] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const [countdown, setCountdown] = useCountdownTimer(store.getProceedCountdownTimerValue());
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [isCheckDialogOpen, setIsCheckDialogOpen] = useState(false);
    const [dialogBoxTitle, setDialogBoxTitle] = useState<string | null>(null);
    const [searchParam] = useSearchParams();
    const userId = searchParam.get('userId');

    const getDialogHeadingStatus = (status: UserStatus.UserStatus): string => {
        switch (status) {
            case UserStatus.UserStatus.ACTIVE:
                return t('common.reactivation');
            case UserStatus.UserStatus.DEACTIVATED:
                return t('common.deactivation');
            case UserStatus.UserStatus.ARCHIVED:
                return t('common.archive');
        }
    };

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckBCNUserUpdateRequest
    );

    const showRequestSection =
        userStore.privileges.includes(UserPrivileges.RequestArchiveBCNUser) ||
        userStore.privileges.includes(UserPrivileges.RequestActivateDeactivateBCNUser);

    const fetchBCNUserDetails = async (): Promise<void> => {
        if (userId) {
            setLoading(true);
            await store.fetchBCNUserDetails(userId);
            setLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            navigate('/yafika-mobile/users', { replace: true });
        }
    };

    useEffect(() => {
        fetchBCNUserDetails();
    }, []);

    const onApproveClick = (): void => {
        if (store.requestDetails) {
            checkRequestStore.setStatus(Status.APPROVED);
            setDialogBoxTitle(
                t('common.approveStatusRequest', {
                    status: getDialogHeadingStatus(store.requestDetails.updatedUserStatus)
                })
            );
            setIsCheckDialogOpen(true);
        }
    };

    const onDenyClick = (): void => {
        if (store.requestDetails) {
            checkRequestStore.setStatus(Status.DENIED);
            setDialogBoxTitle(
                t('common.denyStatusRequest', {
                    status: getDialogHeadingStatus(store.requestDetails.updatedUserStatus)
                })
            );
            setIsCheckDialogOpen(true);
        }
    };

    const onWithdrawClick = (): void => {
        setDialogBoxTitle(t('common.withdrawRequest'));
        setIsWithdrawRequestDialogOpen(true);
    };

    const showWithdrawSection = (): boolean => {
        return userStore.userId === store.requestDetails?.makerComment.userId;
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        if (store.requestDetails?.requestId) {
            await withdrawRequestStore.withdrawRequest(store.requestDetails.requestId, comment);
        } else {
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
            loggerStore.debug(
                'Cannot find requestId in BCNUserDetailsStore while withdrawing the request'
            );
        }
        setScreenBlockingLoading(false);
        if (withdrawRequestStore.error) {
            switch (withdrawRequestStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
            }
        } else {
            // Since the request has been withdrawn, we will fetch the user-details again.
            fetchBCNUserDetails();
        }
    };

    const onClickApproveOrDenyUpdate = async (comment: string): Promise<void> => {
        setIsCheckDialogOpen(false);
        setScreenBlockingLoading(true);
        const requestId = store.requestDetails?.requestId;
        if (requestId) {
            await checkRequestStore.checkPendingRequest(requestId, comment);
            if (
                checkRequestStore.status === Status.APPROVED &&
                checkRequestStore.nonZeroAccountDetails.length > 0
            ) {
                setCountdown(store.getProceedCountdownTimerValue());
                setIsArchiveNonZeroAccountDetailsDialogOpen(true);
            } else if (checkRequestStore.error) {
                switch (checkRequestStore.error) {
                    case BCNUserCheckRequestStoreErrors.InvalidRequestId:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.CannotCheckSelfRequest:
                        setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyDiscarded:
                        setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.AgentAccountActive:
                        setErrorDialogMessage(
                            t('common.agentAccountActive', {
                                id: checkRequestStore.agentId
                            })
                        );
                        setIsErrorDialogOpen(true);
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.PendingTransactionsFound:
                        setErrorDialogMessage(t('common.pendingTransactionsFound'));
                        setIsErrorDialogOpen(true);
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                }
            } else {
                fetchBCNUserDetails();
            }
        } else {
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
            loggerStore.debug(
                'Cannot find requestId to CheckBCNUserDetails while checking the request'
            );
        }
        if (!checkRequestStore.nonZeroAccountDetails.length) checkRequestStore.resetComment();
        setScreenBlockingLoading(false);
    };

    const onSubmitToArchiveUserWithBalance = async (): Promise<void> => {
        setIsArchiveNonZeroAccountDetailsDialogOpen(false);
        setScreenBlockingLoading(true);
        if (store.requestDetails?.requestId) {
            await checkRequestStore.checkToArchivePendingRequestWithBalance(
                store.requestDetails.requestId
            );
            if (checkRequestStore.error) {
                switch (checkRequestStore.error) {
                    case BCNUserCheckRequestStoreErrors.InvalidRequestId:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.PendingTransactionsFound:
                        setErrorDialogMessage(t('bcnUser.pendingTransactionsFound'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.UnableToArchiveUser:
                        setErrorDialogMessage(t('bcnUser.unableToArchiveUser'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.CannotCheckSelfRequest:
                        setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyDiscarded:
                        setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                        setIsErrorDialogOpen(true);
                        break;
                    case BCNUserCheckRequestStoreErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        setIsErrorDialogOpen(true);
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                }
            } else {
                fetchBCNUserDetails();
            }
        } else {
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
            loggerStore.debug('Cannot check a request to archive a BCN user without requestId');
        }
        checkRequestStore.resetComment();
        setScreenBlockingLoading(false);
    };

    const isArchiveProcessingState = (): boolean =>
        store.requestDetails?.status ===
            BCNUserUpdateRequestDetailsEnums.Status.Status.PROCESSING &&
        store.requestDetails.updatedUserStatus === UserStatus.UserStatus.ARCHIVED;

    return (
        <>
            <ArchiveNonZeroAccountDetailsDialog
                title={t('common.approveStatusRequest', {
                    status: t('common.archive')
                })}
                isArchiveNonZeroAccountDetailsDialogOpen={isArchiveNonZeroAccountDetailsDialogOpen}
                nonZeroAccountDetails={checkRequestStore.nonZeroAccountDetails}
                onSubmitToArchiveUserWithBalance={onSubmitToArchiveUserWithBalance}
                onArchiveNonZeroAccountDetailsDialogClose={(): void => {
                    checkRequestStore.resetComment();
                    setIsArchiveNonZeroAccountDetailsDialogOpen(false);
                }}
                countdown={countdown}
                totalBalance={checkRequestStore.totalBalance}
            />
            <Stack direction="column">
                {screenBlockingLoading && <LoadingIndicator isLoading={screenBlockingLoading} />}
                <PageHeader
                    title={t('bcnUser.bcnUserDetails')}
                    subtitle={t('bcnUser.viewBcnUserDetails')}
                />
                {store.error === BCNUserDetailsStoreErrors.InvalidBCNUserId ? (
                    <ErrorState errorMessage={t('bcnUser.invalidBCNUserId')} />
                ) : loading ? (
                    <LoadingState />
                ) : (
                    <Stack
                        spacing="32px"
                        sx={{ padding: '32px', boxSizing: 'border-box', width: '100%' }}>
                        <BreadcrumbComponent
                            currentLabel={t('bcnUser.bcnUserDetails')}
                            sx={{ padding: '0px' }}
                        />
                        {store.requestDetails && (
                            <>
                                {store.requestDetails.status ===
                                BCNUserUpdateRequestDetailsEnums.Status.Status.PENDING ? (
                                    <>
                                        <PendingRequestCard
                                            comment={store.requestDetails.makerComment}
                                            onApproveClick={onApproveClick}
                                            onDenyClick={onDenyClick}
                                            onWithdrawClick={onWithdrawClick}
                                            showApproveAndDenySection={showApproveAndDenySection}
                                            showWithdrawSection={showWithdrawSection()}>
                                            <Grid
                                                container
                                                sx={{ marginLeft: '40px', marginTop: '16px' }}>
                                                <Grid item xs={6}>
                                                    <Stack
                                                        sx={{
                                                            ...typography.small2,
                                                            color: palette.label[300]
                                                        }}>
                                                        {t('bcnUser.currentUserStatus')}
                                                    </Stack>
                                                    <StatusComponent
                                                        variant="status-cell"
                                                        status={getUserStatus(
                                                            store.requestDetails.currentUserStatus
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Stack
                                                        sx={{
                                                            ...typography.small2,
                                                            color: palette.label[300]
                                                        }}>
                                                        {t('bcnUser.updatedUserStatus')}
                                                    </Stack>
                                                    <StatusComponent
                                                        variant="status-cell"
                                                        status={getUserStatus(
                                                            store.requestDetails.updatedUserStatus
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </PendingRequestCard>
                                        <WithdrawDialog
                                            isDialogOpen={isWithdrawRequestDialogOpen}
                                            onClose={(): void => {
                                                withdrawRequestStore.resetComment();
                                                setIsWithdrawRequestDialogOpen(false);
                                            }}
                                            onSubmit={onDialogWithdrawButtonClick}
                                        />
                                        <RequestDialog
                                            isDialogOpen={isCheckDialogOpen}
                                            title={dialogBoxTitle}
                                            buttonTitle={
                                                checkRequestStore.status === Status.APPROVED
                                                    ? t('bcnUser.approveRequest')
                                                    : t('bcnUser.denyRequest')
                                            }
                                            onSubmit={async (comment: string): Promise<void> => {
                                                await onClickApproveOrDenyUpdate(comment);
                                            }}
                                            onClose={(): void => {
                                                setIsCheckDialogOpen(false);
                                                checkRequestStore.resetComment();
                                            }}
                                        />
                                    </>
                                ) : (
                                    <ProcessingRequestDetailsCard
                                        makerComment={store.requestDetails.makerComment}
                                        // Checker comment is always present when the request is in `Processing` state.
                                        checkerComment={store.requestDetails.checkerComment!}
                                        currentUserStatus={getUserStatus(
                                            store.requestDetails.currentUserStatus
                                        )}
                                        updatedUserStatus={getUserStatus(
                                            store.requestDetails.updatedUserStatus
                                        )}
                                    />
                                )}
                            </>
                        )}
                        {store.basicDetails && userId && (
                            <BasicBCNUserDetailsSection user={store.basicDetails} userId={userId} />
                        )}
                        {store.accountDetails && (
                            <BCNUserAccountDetails accountDetails={store.accountDetails} />
                        )}
                        {store.kycDocumentHistoryDetails && (
                            <KYCDocumentHistorySection
                                kycDocumentHistoryDetails={store.kycDocumentHistoryDetails}
                            />
                        )}
                        {store.basicDetails &&
                            showRequestSection &&
                            store.basicDetails.status !== UserStatus.UserStatus.ARCHIVED &&
                            !isArchiveProcessingState() && (
                                <ProfileManagementSection
                                    userId={userId!}
                                    userStatus={store.basicDetails.status}
                                    setScreenLoading={setScreenBlockingLoading}
                                    getUpdatedData={(): Promise<void> => fetchBCNUserDetails()}
                                />
                            )}
                        {store.basicDetails && userId && (
                            <BCNRequestHistorySection userId={userId} />
                        )}
                        <ErrorDialog
                            title={dialogBoxTitle}
                            errorMessage={errorDialogMessage}
                            isErrorDialogOpen={isErrorDialogOpen}
                            onClose={(): void => {
                                setIsErrorDialogOpen(false);
                                setDialogBoxTitle(null);
                                store.resetError();
                                withdrawRequestStore.removeError();
                                checkRequestStore.removeError();
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </>
    );
});
