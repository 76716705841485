import { NetworkingErrorStore } from './../../error/store/ErrorStore';
import { Instance } from 'mobx-state-tree';
import { NetworkingStore } from './NetworkingStore';
import { useRootStore } from '../../store/root/store/RootStore';

export const useNetworkingStore = (): Instance<typeof NetworkingStore> => {
    return useRootStore().networkingStore;
};

export const useNetworkingErrorStore = (): Instance<typeof NetworkingErrorStore> => {
    return useNetworkingStore().errorStore;
};
