import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    Country,
    CountryCode,
    GetSystemAccountsRPC,
    LocalizedText,
    SystemAccountCountryDetail,
    SystemAccountDetail
} from '@resolut-tech/bcn-rpcs';

export class MockGetSystemAccountsRPCImpl extends GetSystemAccountsRPC {
    execute(
        _request: GetSystemAccountsRPC.Request
    ): Promise<LeoRPCResult<GetSystemAccountsRPC.Response, GetSystemAccountsRPC.Errors.Errors>> {
        const response = new GetSystemAccountsRPC.Response([
            new SystemAccountCountryDetail(
                new Country(new LocalizedText('Malawi'), new CountryCode('MW'), '+265'),
                [
                    new SystemAccountDetail(new LeoUUID(), 'BILLER_AIRTEL_AI'),
                    new SystemAccountDetail(new LeoUUID(), 'BILLER_DSTV_SUBSCRIPTION')
                ]
            ),
            new SystemAccountCountryDetail(
                new Country(new LocalizedText('Zambia'), new CountryCode('ZM'), '+260'),
                [
                    new SystemAccountDetail(new LeoUUID(), 'BILLER_SRWB_PREPAID'),
                    new SystemAccountDetail(new LeoUUID(), 'BILLER_GOTV')
                ]
            ),
            new SystemAccountCountryDetail(
                new Country(new LocalizedText('South Africa'), new CountryCode('ZA'), '+27'),
                [
                    new SystemAccountDetail(new LeoUUID(), 'BILLER_NRWB_PREPAID'),
                    new SystemAccountDetail(new LeoUUID(), 'BCN_TO_EXTERNAL_FDH_BANK')
                ]
            )
        ]);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetSystemAccountsRPC.Response, GetSystemAccountsRPC.Errors.Errors>
        >;
    }
}
