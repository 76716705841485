import { DownloadFeeRulesStore, createDownloadFeeRuleStore } from './DownloadFeeRulesStore';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    Comment,
    CheckFeeDeterminationRuleRequestRPC,
    CheckerResponse,
    GetFeeDeterminationRuleRequestDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import {
    useCheckFeeRuleRequestRPCClient,
    useGetFeeDeterminationRuleRequestDetailsRPCClient
} from '../rpcs/RPC';
import {
    FileDetailsModel,
    getCurrentRuleFileModel,
    getNewRuleFileModel,
    RuleDetailModel
} from '../../common/models/RulesModel';
import { getBOUserCommentModel } from '../../common/models/BOUserCommentModel';
import { Status } from '../../common/enums/StatusEnum';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import {
    createFeeDeterminationRulesDiffStore,
    FeeDeterminationRulesDiffStore
} from './FeeDeterminationRulesDiffStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum EvaluateFeeRulesErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    RequestDiscarded = 'REQUEST_DISCARDED',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    InvalidComment = 'INVALID_COMMENT'
}

export const EvaluateFeeRulesStore = types
    .model('EvaluateFeeRules', {
        requestId: types.maybeNull(types.string),
        feeRuleDetail: types.maybeNull(RuleDetailModel),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        comment: types.maybeNull(types.string),
        newRuleFile: types.maybeNull(FileDetailsModel),
        oldRuleFile: types.maybeNull(FileDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<EvaluateFeeRulesErrors>(
                    'EvaluateFeeRulesErrors',
                    Object.values(EvaluateFeeRulesErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        ),
        downloadFileStore: DownloadFeeRulesStore,
        feeDeterminationRulesDiffStore: FeeDeterminationRulesDiffStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.requestId = null;
            store.feeRuleDetail = null;
            store.comment = null;
            store.newRuleFile = null;
            store.oldRuleFile = null;
            store.status = null;
            store.error = null;
        },
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = '';
        },
        removeError(): void {
            store.error = null;
        },
        fetchPendingFeeRuleDetail: flow(function* () {
            store.error = null;
            const request = new GetFeeDeterminationRuleRequestDetailsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<GetFeeDeterminationRuleRequestDetailsRPC.Response, never> =
                yield useGetFeeDeterminationRuleRequestDetailsRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const feeRule = response.feeRuleDetail;
                if (feeRule) {
                    store.requestId = feeRule.requestId.uuid;
                    const boUserComment = getBOUserCommentModel(feeRule.boUserComment);
                    const newRuleFile = getNewRuleFileModel(feeRule.newRuleFile);
                    const currentRuleFile = getCurrentRuleFileModel(feeRule.currentRuleFile);
                    const feeRuleDetail = { boUserComment, newRuleFile, currentRuleFile };
                    store.feeRuleDetail = feeRuleDetail;
                }
            } else {
                store.feeRuleDetail = null;
            }
        }),
        evaluatePendingRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                if (store.comment && requestId && store.status) {
                    const request = new CheckFeeDeterminationRuleRequestRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckFeeDeterminationRuleRequestRPC.Response,
                        CheckFeeDeterminationRuleRequestRPC.Errors.Errors
                    > = yield useCheckFeeRuleRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case EvaluateFeeRulesErrors.InvalidRequestId:
                                store.error = EvaluateFeeRulesErrors.InvalidRequestId;
                                break;
                            case EvaluateFeeRulesErrors.RequestAlreadyChecked:
                                store.error = EvaluateFeeRulesErrors.RequestAlreadyChecked;
                                break;
                            case EvaluateFeeRulesErrors.RequestDiscarded:
                                store.error = EvaluateFeeRulesErrors.RequestDiscarded;
                                break;
                            case EvaluateFeeRulesErrors.CannotCheckSelfRequest:
                                store.error = EvaluateFeeRulesErrors.CannotCheckSelfRequest;
                                break;
                            case EvaluateFeeRulesErrors.RequestAlreadyWithdrawn:
                                store.error = EvaluateFeeRulesErrors.RequestAlreadyWithdrawn;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckFeeDeterminationRuleRequestRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckFeeDeterminationRuleRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `store.comment, store.requestId, and store.status cannot be null as they are set before calling evaluatePendingRequest`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = EvaluateFeeRulesErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = EvaluateFeeRulesErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckFeeDeterminationRuleRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckFeeDeterminationRuleRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createEvaluateFeeRulesStore = (): Instance<typeof EvaluateFeeRulesStore> => {
    return EvaluateFeeRulesStore.create({
        downloadFileStore: createDownloadFeeRuleStore(),
        feeDeterminationRulesDiffStore: createFeeDeterminationRulesDiffStore()
    });
};
