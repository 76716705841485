import { Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusType } from '../../common/components/StatusComponent';
import { useLinkedTransactionsStore } from '../store/hooks';
import { LinkTransactionSummary } from './LinkTransactionSummary';

export interface LinkedTransactionSectionProps {
    transactionId: string | null;
}

export const LinkedTransactionsSection = ({
    transactionId
}: LinkedTransactionSectionProps): React.ReactElement => {
    const store = useLinkedTransactionsStore();
    const { t } = useTranslation();

    async function fetchLinkedTransactions(): Promise<string | React.ReactElement[]> {
        if (transactionId) {
            await store.fetchLinkedTransactions(transactionId!);
            if (store.error) {
                return t('common.errorMessage');
            } else {
                if (store.transactions.length > 0) {
                    return [
                        <Stack key="linkedTransactions">
                            {store.transactions.map((transaction, index) => {
                                return (
                                    <LinkTransactionSummary
                                        key={index}
                                        title={transaction.title}
                                        subtitle={transaction.occurredAt}
                                        amount={transaction.amount}
                                        profilePhotoUrl={transaction.imageUrl}
                                        status={
                                            transaction.isRefunded ? StatusType.REFUNDED : undefined
                                        }
                                    />
                                );
                            })}
                        </Stack>
                    ];
                } else {
                    return [];
                }
            }
        } else {
            return t('common.errorMessage');
        }
    }

    return (
        <Section
            title={t('transaction.linkedTransactions')}
            viewOverrides={{
                empty: { message: t('common.noDataFound') },
                loading: { message: t('common.loadingMessage') }
            }}
            onExpand={fetchLinkedTransactions}
            expandIndicator={t('common.showMore')}
            collapseIndicator={t('common.showLess')}
        />
    );
};
