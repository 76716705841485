import {
    BOName,
    BOUserComment,
    GetTransactionValidationRuleHistoryRequestDetailsRPC,
    UserName,
    Comment,
    RequestDetails
} from '@resolut-tech/bcn-rpcs';
import {
    FileDetails,
    FileDetailsEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/fileDetails';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetTransactionValidationRuleHistoryRequestDetailsRPCImpl extends GetTransactionValidationRuleHistoryRequestDetailsRPC {
    execute(
        _request: GetTransactionValidationRuleHistoryRequestDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetTransactionValidationRuleHistoryRequestDetailsRPC.Response,
            GetTransactionValidationRuleHistoryRequestDetailsRPC.Errors.Errors
        >
    > {
        const boUserComment = new BOUserComment(
            new LeoUUID(),
            new UserName(new BOName('Wade Warren')),
            null,
            new LeoTimestamp('2022-09-30T02:03:59Z'),
            new Comment('The fee rules have changed.')
        );

        const currentRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );

        const newRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );
        const response = new GetTransactionValidationRuleHistoryRequestDetailsRPC.Response(
            new RequestDetails.Pending(new LeoUUID(), boUserComment),
            newRuleFile,
            currentRuleFile
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetTransactionValidationRuleHistoryRequestDetailsRPC.Response,
                GetTransactionValidationRuleHistoryRequestDetailsRPC.Errors.Errors
            >
        >;
    }
}
