import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { useBOUserRequestStore } from '../store/hooks';
import { useNavigate } from 'react-router-dom';
import { BORequestsFilter } from '../components/BORequestsFilter';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getStatusTypeFromRequestStatus } from '../../common/utils/StatusUIUtils';
import { RequestTypeComponent } from '../components/BOUserRequestTypeComponent';
import { getTypeFromRequestType } from '../utils/RequestTypeUtils';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { Instance } from 'mobx-state-tree';
import { BOUserRequestsErrors, BOUserRequestsFilterOptions } from '../store/BOUserRequestsStore';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const BOUserRequests = (): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useBOUserRequestStore();
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(t('common.viewRequests'), window.location.pathname);
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '160px'
            },
            {
                id: 'requestedBy',
                name: t('common.requestedBy'),
                width: '160px'
            },
            {
                id: 'requestedFor',
                name: t('common.requestedFor'),
                width: '160px'
            },
            {
                id: 'evaluatedBy',
                name: t('common.evaluatedBy'),
                width: '160px'
            },
            {
                id: 'requestType',
                name: t('boUser.requestType'),
                width: '160px'
            },
            {
                id: 'requestStatus',
                name: t('common.requestStatus'),
                width: '160px',
                ellipsisContent: true
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<typeof BOUserRequestsFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.getBOUsersRequests(
            option.page ? option.page - 1 : 0, // This is done since the server expects the page number to start with 0
            store.itemsPerPage(),
            option.filter?.status,
            option.filter?.type,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case BOUserRequestsErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.requests.map((request) => {
            return [
                { data: request.requestedAt },
                { data: request.requestedBy },
                { data: request.requestedFor },
                { data: request.evaluatedBy },
                {
                    data: (
                        <RequestTypeComponent
                            variant="request-Type-cell"
                            type={getTypeFromRequestType(request.requestType)}
                        />
                    )
                },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getStatusTypeFromRequestStatus(request.requestStatus)}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('common.viewRequests')}
                subtitle={t('boUser.requestsPageSubtitle')}
            />
            <Table
                name={'BOUsersRequestTable'}
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                filterOption={{
                    initialFilterValue: store.filterOptions,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <BORequestsFilter
                                initialFilter={store.getBOUsersRequestInitialFilter()}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        );
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('common.searchingForRequests') }
                }}
                onRowClick={(_row, index): void => {
                    const selectedBOUser = store.requests[index];
                    if (selectedBOUser.userId) {
                        navigate(`/bo/users/details?userId=${selectedBOUser.userId}`);
                    } else {
                        navigate(`/bo/users/details?requestId=${selectedBOUser.requestId}`);
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </Stack>
    );
};
