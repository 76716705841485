import { Box, Stack } from '@mui/material';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestDetailsModel } from '../models/RequestDetailsModel';
import { StatusType } from './StatusComponent';
import { UserCommentComponent } from './UserCommentComponent';
import { WithdrawUserCommentComponent } from './WithdrawUserCommentComponent';

interface RequestDetailsSectionProps {
    requestDetails: Instance<typeof RequestDetailsModel>;
    children?: React.ReactElement | null;
    isSectionItem?: boolean; // isSectionItem is set to true if RequestDetailsSection is used inside Section Component
}

export const RequestDetailsSection = ({
    requestDetails,
    children,
    isSectionItem = false
}: RequestDetailsSectionProps): React.ReactElement => {
    const { t } = useTranslation();
    // borderStyling is added when the component is used outside the Section component.
    const borderStyling = !isSectionItem
        ? {
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px'
          }
        : undefined;

    const RequestorUserComment = (): React.ReactElement => {
        let requestStatus: StatusType | null = null;
        if (requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.PENDING) {
            requestStatus = StatusType.PENDING;
        } else if (
            requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED
        ) {
            requestStatus = StatusType.DISCARDED;
        } else {
            requestStatus = null;
        }
        return (
            <>
                {requestDetails.requesterComment && (
                    <UserCommentComponent
                        // borderStyling is used only when the requestStatus is pending or discarded.
                        sx={requestStatus !== null ? borderStyling : undefined}
                        title={t('common.requestRaisedBy', {
                            name: requestDetails.requesterComment.name
                        })}
                        subtitle={requestDetails.requesterComment.requestedAt}
                        status={requestStatus}
                        profileImageUrl={requestDetails.requesterComment.profilePhoto}
                        comment={requestDetails.requesterComment.comment}
                        showDivider={
                            !isSectionItem &&
                            (requestStatus === StatusType.PENDING ||
                                requestStatus === StatusType.DISCARDED)
                        }>
                        {children}
                    </UserCommentComponent>
                )}
            </>
        );
    };

    const getApproveDenySection = (): React.ReactElement => {
        return (
            <>
                {requestDetails.evaluatorComment?.name && (
                    <UserCommentComponent
                        sx={borderStyling}
                        title={
                            requestDetails.status ===
                            IntermediateRequestStatus.IntermediateRequestStatus.APPROVED
                                ? t('common.requestApprovedBy', {
                                      name: requestDetails.evaluatorComment.name
                                  })
                                : t('common.requestDeniedBy', {
                                      name: requestDetails.evaluatorComment.name
                                  })
                        }
                        subtitle={requestDetails.evaluatorComment.requestedAt}
                        status={
                            requestDetails.status ===
                            IntermediateRequestStatus.IntermediateRequestStatus.APPROVED
                                ? StatusType.APPROVED
                                : StatusType.DENIED
                        }
                        profileImageUrl={requestDetails.evaluatorComment.profilePhoto}
                        comment={requestDetails.evaluatorComment.comment}
                        showDivider={!isSectionItem}>
                        {!isSectionItem && <Box height={'16px'} />}
                        <RequestorUserComment />
                        {!isSectionItem ? <Box height={'48px'} /> : <Box height={'16px'} />}
                    </UserCommentComponent>
                )}
            </>
        );
    };

    const getDiscardedSection = (): React.ReactElement => {
        if (requestDetails.evaluatorComment && requestDetails.requesterComment) {
            return (
                <UserCommentComponent
                    sx={borderStyling}
                    title={t('common.requestApprovedBy', {
                        name: requestDetails.evaluatorComment.name
                    })}
                    subtitle={requestDetails.evaluatorComment.requestedAt}
                    status={StatusType.DISCARDED}
                    profileImageUrl={requestDetails.evaluatorComment.profilePhoto}
                    comment={requestDetails.evaluatorComment.comment}
                    showDivider={!isSectionItem}>
                    {!isSectionItem && <Box height={'16px'} />}
                    <UserCommentComponent
                        title={t('common.requestRaisedBy', {
                            name: requestDetails.requesterComment.name
                        })}
                        subtitle={requestDetails.requesterComment.requestedAt}
                        status={null}
                        profileImageUrl={requestDetails.requesterComment.profilePhoto}
                        comment={requestDetails.requesterComment.comment}
                        showDivider={false}>
                        {children}
                    </UserCommentComponent>
                    {!isSectionItem ? <Box height={'48px'} /> : <Box height={'16px'} />}
                </UserCommentComponent>
            );
        } else {
            return (
                <Stack>
                    <RequestorUserComment />
                    <Box height={'16px'} />
                </Stack>
            );
        }
    };

    const getWithdrawSection = (): React.ReactElement => {
        return (
            <>
                {requestDetails.withdrawalComment && (
                    <Stack>
                        <WithdrawUserCommentComponent
                            sx={borderStyling}
                            withdrawalComment={requestDetails.withdrawalComment}
                            showDivider={!isSectionItem}>
                            {children}
                        </WithdrawUserCommentComponent>
                        <Box height={'16px'} />
                    </Stack>
                )}
            </>
        );
    };

    const getProcessingSection = (): React.ReactElement => {
        return (
            <>
                {requestDetails.evaluatorComment && (
                    <UserCommentComponent
                        sx={borderStyling}
                        title={t('common.requestApprovedBy', {
                            name: requestDetails.evaluatorComment.name
                        })}
                        subtitle={requestDetails.evaluatorComment.requestedAt}
                        status={StatusType.PROCESSING}
                        profileImageUrl={requestDetails.evaluatorComment.profilePhoto}
                        comment={requestDetails.evaluatorComment.comment}
                        showDivider={!isSectionItem}>
                        {!isSectionItem && <Box height={'16px'} />}
                        <RequestorUserComment />
                        {!isSectionItem ? <Box height={'48px'} /> : <Box height={'16px'} />}
                    </UserCommentComponent>
                )}
            </>
        );
    };

    if (requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.PENDING) {
        return (
            <Stack>
                <RequestorUserComment />
                <Box height={'16px'} />
            </Stack>
        );
    } else if (
        requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.APPROVED ||
        requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.DENIED
    ) {
        return getApproveDenySection();
    } else if (
        requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED
    ) {
        return getDiscardedSection();
    } else if (
        requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN &&
        requestDetails.withdrawalComment
    ) {
        return getWithdrawSection();
    } else if (
        requestDetails.status === IntermediateRequestStatus.IntermediateRequestStatus.PROCESSING &&
        requestDetails.evaluatorComment
    ) {
        return getProcessingSection();
    } else {
        return <></>;
    }
};
