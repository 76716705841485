import {
    AgentListTransactionStatus,
    AgentTransaction,
    AgentTransactionType,
    AgentTransactionUserDetails
} from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';

const AgentTransactionUserDetailsModel = types.model({
    name: types.maybeNull(types.string),
    phoneNumber: types.string
});

const getAgentTransactionUserDetailsModel = ({
    name,
    phoneNumber
}: AgentTransactionUserDetails): Instance<typeof AgentTransactionUserDetailsModel> => {
    return AgentTransactionUserDetailsModel.create({
        name: name && getFullName(name),
        phoneNumber: phoneNumber?.phoneNumber
    });
};

export const AgentTransactionModel = types.model({
    createdAt: types.string,
    amount: AmountModel,
    transactionType: types.enumeration<AgentTransactionType.AgentTransactionType>(
        'AgentTransactionType',
        Object.values(AgentTransactionType.AgentTransactionType)
    ),
    transactionStatus: types.enumeration<AgentListTransactionStatus.AgentListTransactionStatus>(
        'AgentListTransactionStatus',
        Object.values(AgentListTransactionStatus.AgentListTransactionStatus)
    ),
    requestId: types.maybeNull(types.string),
    transactionId: types.string,
    senderDetails: types.maybeNull(AgentTransactionUserDetailsModel),
    senderAgentDetails: types.maybeNull(AgentTransactionUserDetailsModel),
    recipientDetails: types.maybeNull(AgentTransactionUserDetailsModel),
    recipientAgentDetails: types.maybeNull(AgentTransactionUserDetailsModel)
});

export const getAgentTransactionModel = (
    agentTransaction: AgentTransaction
): Instance<typeof AgentTransactionModel> => {
    return AgentTransactionModel.create({
        createdAt: getFormattedTimeDateWithComma(new Date(agentTransaction.createdAt.timestamp)),
        amount: getAmountModel(agentTransaction.amount),
        transactionType: agentTransaction.transactionType,
        transactionStatus: agentTransaction.transactionStatus,
        requestId: agentTransaction.requestId?.id,
        transactionId: agentTransaction.transactionId.uuid,
        senderDetails:
            agentTransaction.senderDetails &&
            getAgentTransactionUserDetailsModel(agentTransaction.senderDetails),
        senderAgentDetails:
            agentTransaction.senderAgentDetails &&
            getAgentTransactionUserDetailsModel(agentTransaction.senderAgentDetails),
        recipientDetails:
            agentTransaction.recipientDetails &&
            getAgentTransactionUserDetailsModel(agentTransaction.recipientDetails),
        recipientAgentDetails:
            agentTransaction.recipientAgentDetails &&
            getAgentTransactionUserDetailsModel(agentTransaction.recipientAgentDetails)
    });
};
