import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { BCNUserCheckRequestStore } from './BCNUserCheckRequestStore';
import { BCNUserDetailsStore } from './BCNUserDetailsStore';
import { BCNUserKycDocumentDetailsStore } from './BCNUserKycDocumentDetailsStore';
import { BCNUserRequestHistoryStore } from './BCNUserRequestHistoryStore';
import { BCNUserSearchStore } from './BCNUserSearchStore';
import { BCNUserViewRequestsStore } from './BCNUserViewRequestsStore';
import { BCNUserWithdrawRequestStore } from './BCNUserWithdrawRequestStore';
import { UpdateBCNUserStateStore } from './UpdateBCNUserStateStore';

export const useBCNUserSearchStore = (): Instance<typeof BCNUserSearchStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserSearchStore;
};

export const useBCNUserDetailsStore = (): Instance<typeof BCNUserDetailsStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserDetailsStore;
};

export const useBCNUserWithdrawRequestStore = (): Instance<typeof BCNUserWithdrawRequestStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserWithdrawRequestStore;
};

export const useBCNUserCheckRequestStore = (): Instance<typeof BCNUserCheckRequestStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserCheckRequestStore;
};

export const useBCNUserViewRequestsStore = (): Instance<typeof BCNUserViewRequestsStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserViewRequestsStore;
};

export const useUpdateBCNUserStateStore = (): Instance<typeof UpdateBCNUserStateStore> => {
    return useRootStore().homeStore.bcnUsers.updateUserStateStore;
};

export const useBCNUserRequestHistoryStore = (): Instance<typeof BCNUserRequestHistoryStore> => {
    return useRootStore().homeStore.bcnUsers.bcnUserRequestHistoryStore;
};

export const useBCNUserKycDocumentDetailsStore = (): Instance<
    typeof BCNUserKycDocumentDetailsStore
> => {
    return useRootStore().homeStore.bcnUsers.bcnUserKycDocumentDetailsStore;
};
