import { GetSystemAccountsRPC, SystemAccountCountryDetail } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useGetSystemAccountsClient } from '../rpcs/RPC';
import {
    createRequestGenericFundsTransferStore,
    RequestGenericFundsTransferStore
} from './RequestGenericFundsTransferStore';
import {
    createSubmitGenericFundsTransferRequestStore,
    SubmitGenericFundsTransferRequestStore
} from './SubmitGenericFundsTransferStore';
import {
    AccountType,
    createValidateAccountDetailsStore,
    ValidateAccountDetailsStore
} from './ValidateAccountDetailsStore';

export const SystemAccountDetailModel = types.model({
    displayName: types.string,
    accountId: types.string
});

export const GFTCountryModel = types.model({
    displayName: types.string,
    countryCode: types.string
});

export const SystemAccountCountryDetailModel = types.model({
    country: GFTCountryModel,
    systemAccountDetails: types.array(SystemAccountDetailModel)
});

export const RequestFundTransferStore = types
    .model({
        systemAccountCountryDetailsList: types.array(SystemAccountCountryDetailModel),
        senderAccountType: types.maybeNull(types.string),
        receiverAccountType: types.maybeNull(types.string),
        senderCountrySystemDetails: types.maybeNull(SystemAccountCountryDetailModel),
        receiverCountrySystemDetails: types.maybeNull(SystemAccountCountryDetailModel),
        senderAccountId: types.maybeNull(types.string),
        receiverAccountId: types.maybeNull(types.string),
        validateAccountDetailsStore: ValidateAccountDetailsStore,
        requestGenericFundsTransferStore: RequestGenericFundsTransferStore,
        submitGenericFundsTransferRequestStore: SubmitGenericFundsTransferRequestStore
    })
    .actions((store) => ({
        resetStore: (): void => {
            store.senderAccountId = null;
            store.senderAccountType = null;
            store.senderCountrySystemDetails = null;
            store.receiverAccountId = null;
            store.receiverAccountType = null;
            store.receiverCountrySystemDetails = null;
        },
        setSenderDetails: (
            accountId: string,
            accountType: AccountType | null,
            countrySystemDetails: Instance<typeof SystemAccountCountryDetailModel> | null
        ): void => {
            store.senderAccountType = accountType;
            store.senderAccountId = accountId;
            store.senderCountrySystemDetails = countrySystemDetails
                ? SystemAccountCountryDetailModel.create({
                      country: GFTCountryModel.create({
                          displayName: countrySystemDetails.country.displayName,
                          countryCode: countrySystemDetails.country.countryCode
                      }),
                      systemAccountDetails: countrySystemDetails.systemAccountDetails.map(
                          (systemDetail) => {
                              return SystemAccountDetailModel.create({
                                  displayName: systemDetail.displayName,
                                  accountId: systemDetail.accountId
                              });
                          }
                      )
                  })
                : null;
        },
        setReceiverDetails: (
            accountId: string,
            accountType: AccountType | null,
            countrySystemDetails: Instance<typeof SystemAccountCountryDetailModel> | null
        ): void => {
            store.receiverAccountType = accountType;
            store.receiverAccountId = accountId;
            store.receiverCountrySystemDetails = countrySystemDetails
                ? SystemAccountCountryDetailModel.create({
                      country: GFTCountryModel.create({
                          displayName: countrySystemDetails.country.displayName,
                          countryCode: countrySystemDetails.country.countryCode
                      }),
                      systemAccountDetails: countrySystemDetails.systemAccountDetails.map(
                          (systemDetail) => {
                              return SystemAccountDetailModel.create({
                                  displayName: systemDetail.displayName,
                                  accountId: systemDetail.accountId
                              });
                          }
                      )
                  })
                : null;
        },
        fetchSystemCountryList: flow(function* () {
            const request = new GetSystemAccountsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetSystemAccountsRPC.Response,
                GetSystemAccountsRPC.Errors.Errors
            > = yield useGetSystemAccountsClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const systemCountriesList = response.systemAccounts.map(
                    (systemCountryDetail: SystemAccountCountryDetail) => {
                        return {
                            country: {
                                displayName: getTranslatedString(
                                    systemCountryDetail.countryName.displayName
                                ),
                                countryCode: systemCountryDetail.countryName.code.code
                            },
                            systemAccountDetails: systemCountryDetail.systemAccountDetails.map(
                                (detail) => {
                                    return {
                                        displayName: detail.id,
                                        accountId: detail.accountId.uuid
                                    };
                                }
                            )
                        };
                    }
                );
                store.systemAccountCountryDetailsList = cast(systemCountriesList);
            }
        })
    }));

export const createRequestFundTransferStore = (): Instance<typeof RequestFundTransferStore> => {
    return RequestFundTransferStore.create({
        systemAccountCountryDetailsList: [],
        validateAccountDetailsStore: createValidateAccountDetailsStore(),
        requestGenericFundsTransferStore: createRequestGenericFundsTransferStore(),
        submitGenericFundsTransferRequestStore: createSubmitGenericFundsTransferRequestStore()
    });
};
