import { Box, CircularProgress, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface DialogLoadingStateProps {
    label: string;
}

export const DialogLoadingState = ({ label }: DialogLoadingStateProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box
            style={{
                height: '104px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            <CircularProgress
                size="32px"
                sx={{
                    color: palette.primary[300],
                    marginTop: '16px'
                }}
            />
            <Typography
                sx={{
                    ...typography.body2,
                    color: palette.primary[300],
                    marginTop: '16px'
                }}>
                {label}
            </Typography>
        </Box>
    );
};
