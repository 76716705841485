import { GetBOPresignedDocumentUploadURLForFeeRulesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetBOPresignedDocumentUploadURLForFeeRulesRPCImpl extends GetBOPresignedDocumentUploadURLForFeeRulesRPC {
    execute(
        _request: GetBOPresignedDocumentUploadURLForFeeRulesRPC.Request
    ): Promise<
        LeoRPCResult<
            GetBOPresignedDocumentUploadURLForFeeRulesRPC.Response,
            GetBOPresignedDocumentUploadURLForFeeRulesRPC.Errors.Errors
        >
    > {
        const response = new GetBOPresignedDocumentUploadURLForFeeRulesRPC.Response(
            new URL('https://www.google.com'),
            new LeoUUID()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOPresignedDocumentUploadURLForFeeRulesRPC.Response,
                GetBOPresignedDocumentUploadURLForFeeRulesRPC.Errors.Errors
            >
        >;
    }
}
