import { Instance, types } from 'mobx-state-tree';
import { createTransferDetailsStore, TransferDetailsStore } from './TransferDetailsStore';
import { CheckRequestStore, createCheckRequestStore } from './CheckRequestStore';
import {
    createRequestFundTransferStore,
    RequestFundTransferStore
} from './RequestFundTransferStore';
import { createRequestListStore, RequestListStore } from './RequestListStore';
import { createWithdrawRequestStore, WithdrawRequestStore } from './WithdrawRequestStore';

export const GenericFundTransferStore = types
    .model({
        requestListStore: RequestListStore,
        requestFundTransferStore: RequestFundTransferStore,
        transferDetailsStore: TransferDetailsStore,
        checkRequestStore: CheckRequestStore,
        withdrawGenericFundsTransferRequestStore: WithdrawRequestStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.requestListStore = createRequestListStore();
            store.requestFundTransferStore = createRequestFundTransferStore();
            store.transferDetailsStore = createTransferDetailsStore();
            store.checkRequestStore = createCheckRequestStore();
            store.withdrawGenericFundsTransferRequestStore = createWithdrawRequestStore();
        }
    }));

export const createGenericFundTransferStore = (): Instance<typeof GenericFundTransferStore> => {
    return GenericFundTransferStore.create({
        requestFundTransferStore: createRequestFundTransferStore(),
        requestListStore: createRequestListStore(),
        transferDetailsStore: createTransferDetailsStore(),
        checkRequestStore: createCheckRequestStore(),
        withdrawGenericFundsTransferRequestStore: createWithdrawRequestStore()
    });
};
