import { Stack, Typography } from '@mui/material';
import { LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { getGenderTranslation } from '../../common/utils/UIUtils';
import { useBCNUserKycDocumentDetailsStore } from '../store/hooks';
import { downloadFile } from '../../common/utils/FileUtils';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { PreviewPhotoDialog } from '../../common/components/dialog/PreviewPhotoDialog';
import { KYCSectionRowComponentWithLabel } from '../../common/components/KYCSectionRowComponentWithLabel';
import { useLoggerStore } from '../../../../log/hooks';
import { Dialog } from '../../../common/components/Dialog';

interface KycDocumentDetailsDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    kycRequestId: string;
    kycRequestedAt: string;
}

export const KYCDocumentDetails = ({
    isDialogOpen,
    onClose,
    kycRequestId,
    kycRequestedAt
}: KycDocumentDetailsDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const store = useBCNUserKycDocumentDetailsStore();
    const [loading, setLoading] = useState(false);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string | null>(null);
    const typography = useTypography();
    const palette = usePalette();
    const loggerStore = useLoggerStore();
    const [showGenericErrorDialog, setShowShowGenericErrorDialogDialog] = useState<boolean>(false);

    const fetchKycDocumentDetails = async (): Promise<void> => {
        setLoading(true);
        await store.fetchKycDocumentDetails(kycRequestId);
        setLoading(false);
    };

    useEffect(() => {
        fetchKycDocumentDetails();
    }, []);

    const kYCDetailSectionCells = (): { label: string; cell: SectionRowCellType }[] => {
        if (store.kycDetails) {
            return [
                {
                    label: t('common.userPhoto'),
                    cell: {
                        type: SectionRowCellEnum.ProfileImage,
                        imageUrl: store.kycDetails.profilePhoto ?? ''
                    }
                },
                {
                    label: t('common.firstName'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.userPersonalDetails.firstName
                    }
                },
                {
                    label: t('common.lastName'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.userPersonalDetails.lastName ?? ''
                    }
                },
                {
                    label: t('common.otherNames'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.userPersonalDetails.otherName ?? ''
                    }
                },
                {
                    label: t('common.emailId'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.emailId ?? '-',
                        showEllipsis: true,
                        maxWidth: '30vh'
                    }
                },
                {
                    label: t('common.dateOfBirth'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.userPersonalDetails.dateOfBirth
                    }
                },
                {
                    label: t('common.gender'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: getGenderTranslation(
                            t,
                            store.kycDetails.kycData.userPersonalDetails.gender
                        )
                    }
                },
                {
                    label: t('common.nationalIdNo'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.userPersonalDetails.nationalId
                    }
                },
                {
                    label: t('bcnUser.nationalIdIssueDate'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.nationalIdIssueDate
                    }
                },
                {
                    label: t('bcnUser.nationalIdExpiryDate'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.nationalIdExpiryDate
                    }
                },
                {
                    label: t('common.placeBirth'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.placeOfBirth
                    }
                },
                {
                    label: t('common.residentialAddress'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.residentAddress
                    }
                },
                {
                    label: t('common.postalAddress'),
                    cell: {
                        type: SectionRowCellEnum.Unstyled,
                        text: store.kycDetails.kycData.postalAddress
                    }
                },
                {
                    label: t('bcnUser.nationalIdFront'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl: store.kycDetails.nationalIdFront.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.nationalIdFront.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.nationalIdFront.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.nationalIdFront.documentUrl) {
                                downloadFile(
                                    store.kycDetails?.nationalIdFront.documentUrl.toString()
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find nationalIdFront url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                },
                {
                    label: t('bcnUser.nationalIdBack'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl: store.kycDetails.nationalIdBack.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.nationalIdBack.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.nationalIdBack.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.nationalIdBack.documentUrl) {
                                downloadFile(
                                    store.kycDetails?.nationalIdBack.documentUrl.toString()!
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find nationalIdBack url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                },
                {
                    label: t('bcnUser.signaturePhoto'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl: store.kycDetails.signaturePhoto.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.signaturePhoto.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.signaturePhoto.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.signaturePhoto.documentUrl) {
                                downloadFile(
                                    store.kycDetails?.signaturePhoto.documentUrl.toString()!
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find signaturePhoto url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                },
                {
                    label: t('bcnUser.passportPhotoFront'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl:
                            store.kycDetails.passportPhotoFront?.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.passportPhotoFront?.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.passportPhotoFront?.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.passportPhotoFront) {
                                downloadFile(
                                    store.kycDetails?.passportPhotoFront.documentUrl.toString()
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find passportPhotoFront url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                },
                {
                    label: t('bcnUser.passportPhotoBack'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl: store.kycDetails.passportPhotoBack?.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.passportPhotoBack?.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.passportPhotoBack?.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.passportPhotoBack) {
                                downloadFile(
                                    store.kycDetails.passportPhotoBack.documentUrl.toString()
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find passportPhotoBack url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                },
                {
                    label: t('bcnUser.proofOfResidence'),
                    cell: {
                        type: SectionRowCellEnum.Document,
                        fileUrl: store.kycDetails.proofOfResidence.documentUrl.toString() ?? null,
                        fileType: store.kycDetails.proofOfResidence.documentType ?? null,
                        onViewPhotoClick: (): void => {
                            setSelectedPhotoUrl(
                                store.kycDetails?.proofOfResidence.documentUrl.toString() ?? null
                            );
                        },
                        onDownloadClick: (): void => {
                            if (store.kycDetails?.proofOfResidence.documentUrl) {
                                downloadFile(
                                    store.kycDetails?.proofOfResidence.documentUrl.toString()!
                                );
                            } else {
                                loggerStore.debug(
                                    'Cannot find proofOfResidence url when download button is tapped'
                                );
                                setShowShowGenericErrorDialogDialog(true);
                            }
                        }
                    }
                }
            ];
        } else {
            loggerStore.debug('Cannot find KYC Details in BCN User KycDocument Details Store ');
            return [];
        }
    };

    const getKycDetailSubComponents = (): React.ReactElement[] => {
        return kYCDetailSectionCells().map(({ label, cell }) => (
            <KYCSectionRowComponentWithLabel key={label} label={label} cell={cell} />
        ));
    };

    const showImagePreview = (): React.ReactElement => {
        if (selectedPhotoUrl) {
            return (
                <PreviewPhotoDialog
                    isDialogOpen={selectedPhotoUrl ? true : false}
                    onClose={function (): void {
                        setSelectedPhotoUrl(null);
                    }}
                    photoUrl={selectedPhotoUrl}
                />
            );
        } else {
            loggerStore.debug('Cannot find selected photo URL while showing image preview');
            return <></>;
        }
    };

    const onErrorDialogClose = (): void => {
        store.removeError();
        close();
    };

    const genericErrorDialog = (): React.ReactElement => {
        return (
            <ErrorDialog
                isErrorDialogOpen={showGenericErrorDialog}
                onClose={(): void => {
                    setShowShowGenericErrorDialogDialog(false);
                }}
            />
        );
    };

    if (loading) {
        return <LoadingIndicator isLoading={loading} />;
    } else if (store.error) {
        return (
            <ErrorDialog isErrorDialogOpen={Boolean(store.error)} onClose={onErrorDialogClose} />
        );
    } else {
        return (
            <>
                <Dialog
                    open={isDialogOpen}
                    contentPadding="0px"
                    title={t('bcnUser.kycDocumentHistory')}
                    secondaryButtonText={t('common.close')}
                    onSecondaryButtonClick={onClose}
                    disableBackdropClick>
                    <>
                        <Stack
                            direction="row"
                            padding="8px 12px"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                backgroundColor: palette.background[300],
                                position: 'sticky',
                                top: 0,
                                zIndex: '1'
                            }}>
                            <Typography
                                sx={{
                                    ...typography.small3,
                                    color: palette.label[200],
                                    marginLeft: '4px'
                                }}>
                                {t('bcnUser.updatedOn')}
                                {` ${'-'} ${kycRequestedAt}`}
                            </Typography>
                        </Stack>
                        <Stack direction="column" sx={{}}>
                            {getKycDetailSubComponents()}
                        </Stack>
                    </>
                </Dialog>
                {selectedPhotoUrl && showImagePreview()}
                {showGenericErrorDialog && genericErrorDialog()}
            </>
        );
    }
};
