import { RequestToAddBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestToAddBOUserRPCImpl extends RequestToAddBOUserRPC {
    execute(
        _request: RequestToAddBOUserRPC.Request
    ): Promise<LeoRPCResult<RequestToAddBOUserRPC.Response, RequestToAddBOUserRPC.Errors.Errors>> {
        const response = new RequestToAddBOUserRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<RequestToAddBOUserRPC.Response, RequestToAddBOUserRPC.Errors.Errors>
        >;
    }
}
