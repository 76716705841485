import { LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    CountryCode,
    GetAgentsPaginationResponse,
    GetAgentsRPC,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const paginationResponseList: GetAgentsPaginationResponse[] = [
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('27'),
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('26'),
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID()
    ),
    new GetAgentsPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID()
    )
];

export class MockGetAgentsRPCImpl extends GetAgentsRPC {
    execute(
        request: GetAgentsRPC.Request
    ): Promise<LeoRPCResult<GetAgentsRPC.Response, GetAgentsRPC.Errors.Errors>> {
        let list: GetAgentsPaginationResponse[] = paginationResponseList;
        const phoneNumberSearchText = request.phoneNumberSearchText?.text;
        const nameSearchText = request.nameSearchText?.text;
        const requestCountryCode = request.countryCode;
        const requestAgentStatus = request.agentStatus;
        if (nameSearchText) {
            list = list.filter((agent) => agent.name.toLowerCase().includes(nameSearchText));
        }
        if (phoneNumberSearchText) {
            list = list.filter((agent) =>
                agent.phoneNumber.phoneNumber.toLowerCase().includes(phoneNumberSearchText)
            );
        }
        if (requestAgentStatus) {
            list = list.filter((agent) => {
                return agent.agentStatus === requestAgentStatus;
            });
        }
        if (requestCountryCode) {
            list = list.filter((agent) => {
                return agent.countryCode.code === requestCountryCode.code;
            });
        }
        const response = new GetAgentsRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetAgentsRPC.Response, GetAgentsRPC.Errors.Errors>
        >;
    }
}
