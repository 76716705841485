import { Password, RegenerateAuthCodesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { types, Instance, flow } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { downloadFile } from '../../common/utils/FileUtils';
import { useRegenerateAuthCodesRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RegenerateAuthCodesErrors {
    TooManyRequests = 'TOO_MANY_REQUESTS',
    CouldNotGenerateCodes = 'COULD_NOT_GENERATE_CODES',
    TooManyIncorrectAttempts = 'TOO_MANY_INCORRECT_ATTEMPTS',
    IncorrectPassword = 'INCORRECT_PASSWORD',
    InvalidPassWord = 'INVALID_PASSWORD'
}

export const RegenerateAuthCodesStore = types
    .model({
        password: types.maybeNull(types.string),
        numberOfValidAttemptsLeft: types.maybeNull(types.number),
        error: types.maybeNull(
            types.union(
                types.enumeration<RegenerateAuthCodesErrors>(
                    'RegenerateAuthCodesErrors',
                    Object.values(RegenerateAuthCodesErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetStore(): void {
            store.error = null;
            store.password = null;
            store.numberOfValidAttemptsLeft = null;
        },
        setPassword(value: string): void {
            store.password = value;
        },
        downloadFile: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.password) {
                    const _password = new Password(store.password);
                    const request = new RegenerateAuthCodesRPC.Request(_password);
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RegenerateAuthCodesRPC.Response,
                        RegenerateAuthCodesRPC.Errors.Errors
                    > = yield useRegenerateAuthCodesRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        downloadFile(response.documentURL.toString());
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case RegenerateAuthCodesErrors.TooManyRequests:
                                store.error = RegenerateAuthCodesErrors.TooManyRequests;
                                break;
                            case RegenerateAuthCodesErrors.CouldNotGenerateCodes:
                                store.error = RegenerateAuthCodesErrors.CouldNotGenerateCodes;
                                break;
                            case RegenerateAuthCodesErrors.IncorrectPassword:
                                if (
                                    error instanceof RegenerateAuthCodesRPC.Errors.IncorrectPassword
                                ) {
                                    store.error = RegenerateAuthCodesErrors.IncorrectPassword;
                                    store.numberOfValidAttemptsLeft =
                                        error.numberOfPasswordAttemptsLeft;
                                }
                                break;
                            case RegenerateAuthCodesErrors.TooManyIncorrectAttempts:
                                store.error = RegenerateAuthCodesErrors.TooManyIncorrectAttempts;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} in RegenerateAuthCodesRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RegenerateAuthCodesRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Cannot find password in RegenerateAuthCodesStore`);
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidPasswordError:
                            store.error = RegenerateAuthCodesErrors.InvalidPassWord;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RegenerateAuthCodesRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Unknown error occurred in RegenerateAuthCodesRPC: ${error}`);
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        removeError(): void {
            store.error = null;
        }
    }));

export const createRegenerateAuthCodesStore = (): Instance<typeof RegenerateAuthCodesStore> => {
    return RegenerateAuthCodesStore.create();
};
