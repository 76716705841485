import {
    BOUserSearchBy,
    GetBOUsersRPC,
    ItemsPerPage,
    PageIndex,
    SearchText,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { useGetBOUsersRPCClient } from '../rpcs/RPC';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { BORoleModel } from '../models/BORoleModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BOUserSearchErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const BOSearchUser = types.model({
    id: types.string,
    name: types.string,
    email: types.string,
    phoneNumber: types.string,
    status: types.enumeration<UserStatus.UserStatus>(
        'BOUserStatus',
        Object.values(UserStatus.UserStatus)
    )
});

export const BOUserFilterOptions = types.model({
    searchBy: types.string,
    searchText: types.string,
    selectedUserStatus: types.string,
    selectedBORoles: types.array(BORoleModel)
});

const getPhoneNumberSearchText = (
    filter: Instance<typeof BOUserFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === BOUserSearchBy.BOUserSearchBy.PHONE_NUMBER) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

const getEmailIdSearchText = (
    filter: Instance<typeof BOUserFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === BOUserSearchBy.BOUserSearchBy.EMAIL_ID) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

const getNameSearchText = (
    filter: Instance<typeof BOUserFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === BOUserSearchBy.BOUserSearchBy.NAME) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

export const BOUserSearchStore = types
    .model({
        // The initial filter options for BO user search table will be dependent on `t` function.
        // The filter options is set as maybe and then updated from the UI.
        filterOptions: types.maybe(BOUserFilterOptions),
        totalItems: types.number,
        users: types.array(BOSearchUser),
        error: types.maybeNull(
            types.union(
                types.enumeration<BOUserSearchErrors>(
                    'BOUserSearchErrors',
                    Object.values(BOUserSearchErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        updateFilterOptions(filterOptions: Instance<typeof BOUserFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        removeError(): void {
            store.error = null;
        },
        fetchBOUserSearch: flow(function* (
            filter: Instance<typeof BOUserFilterOptions> | undefined,
            pageIndex: number,
            itemsPerPage: number
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetBOUsersRPC.Request(
                getNameSearchText(filter),
                getPhoneNumberSearchText(filter),
                getEmailIdSearchText(filter),
                filter?.selectedUserStatus === AnyEnum.ANY
                    ? null
                    : filter?.selectedUserStatus
                    ? UserStatus.fromDTO({ case: filter.selectedUserStatus })
                    : null,
                filter?.selectedBORoles ? filter.selectedBORoles.map(({ id }) => id) : [],
                new ItemsPerPage(itemsPerPage),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<GetBOUsersRPC.Response, GetBOUsersRPC.Errors.Errors> =
                yield useGetBOUsersRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const boUsers = response.getBOUsersPaginationResponse.map(
                    (boUserPaginationResponse) => {
                        return {
                            id: boUserPaginationResponse.userId.uuid,
                            name: getPGFullName(boUserPaginationResponse.name),
                            email: boUserPaginationResponse.emailId,
                            phoneNumber: boUserPaginationResponse.phoneNumber.phoneNumber,
                            status: boUserPaginationResponse.userStatus
                        };
                    }
                );
                store.totalItems = response.totalItems;
                store.users = cast(boUsers);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case BOUserSearchErrors.InvalidPageIndex:
                        store.error = BOUserSearchErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(`Unhandled error: ${error} occurred in GetBOUsersRPC`);
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(`Unknown error occurred in GetBOUsersRPC with result: ${result}`);
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10
    }));

export const createBOUserSearchStore = (): Instance<typeof BOUserSearchStore> => {
    return BOUserSearchStore.create({
        totalItems: 0,
        users: []
    });
};
