import { Amount, Currency } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

export const AmountModel = types.model({
    number: types.number,
    currency: types.string
});

export const getAmountModel = (amount: Amount): Instance<typeof AmountModel> => {
    return AmountModel.create({
        number: amount.amount / 10 ** 4,
        currency: amount.currency.currencyCode
    });
};

export const getAmountType = (amount: number, currency: string): Amount => {
    // Math.floor is used as sometimes when we multiply the amount with 10000 there is decimal value.
    // For Eg: If the amount is 1.12 then the result of the calculation `amount * 10 ** 4` will be  11200.000000000002.
    // To remove the unnecessary decimal value we need to use Math.floor()
    return new Amount(Math.floor(amount * 10 ** 4), new Currency(currency));
};
