import { AgentTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/agentTransactionDetail';
import { TransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';
import { Instance } from 'mobx-state-tree';
import { BORoleDiffModel } from '../../bo-users/models/BORoleDiffModel';
import { StatusType } from '../components/StatusComponent';

export enum SectionRowCellEnum {
    Bold,
    PrefixIcon,
    Copy,
    Unstyled,
    UserStatusChip,
    ProfileImage,
    UserRoleChips,
    DiffHeader,
    Document,
    Image,
    Time
}

export type StatusIcon =
    | TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon
    | AgentTransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon;

interface BoldCell {
    type: SectionRowCellEnum.Bold;
    text: string;
}

export interface PrefixIconCell {
    type: SectionRowCellEnum.PrefixIcon;
    text: string;
    statusIcon: StatusIcon;
}

interface CopyCell {
    type: SectionRowCellEnum.Copy;
    text: string;
}

interface UnstyledCell {
    type: SectionRowCellEnum.Unstyled;
    showDiff?: boolean;
    text: string | null;
    diffText?: string | null;
    showEllipsis?: boolean;
    maxWidth?: string;
    isSectionComponent?: boolean;
}

interface TimeCell {
    type: SectionRowCellEnum.Time;
    text: string;
}

export interface ProfileImageCell {
    type: SectionRowCellEnum.ProfileImage;
    imageUrl: string;
    showViewPhotoButton?: boolean;
    onUpdateClick?: () => void;
    onDeleteClick?: () => void;
}

interface ImageCell {
    type: SectionRowCellEnum.Image;
    imageUrl: string | null;
    onViewImageClick: () => void;
    imageWidth: string;
    imageHeight: string;
}

interface DocumentCell {
    type: SectionRowCellEnum.Document;
    fileUrl: string | null;
    fileType: string | null;
    onDownloadClick: () => void;
    onViewPhotoClick: () => void;
}

export interface UserStatusChipCell {
    type: SectionRowCellEnum.UserStatusChip;
    status: StatusType;
    updatedStatus?: StatusType;
}

export interface UserRoleChipsCell {
    type: SectionRowCellEnum.UserRoleChips;
    roles: string[];
    updatedRoles?: Instance<typeof BORoleDiffModel>[];
}

export interface DiffHeaderCell {
    type: SectionRowCellEnum.DiffHeader;
    currentDetailsLabel: string;
    updatedDetailsLabel: string;
}

export type SectionRowCellType =
    | BoldCell
    | PrefixIconCell
    | CopyCell
    | UnstyledCell
    | UserStatusChipCell
    | ProfileImageCell
    | UserRoleChipsCell
    | DiffHeaderCell
    | DocumentCell
    | ImageCell
    | TimeCell;
