import { Typography, styled, Box } from '@mui/material';
import {
    LoadingIndicator,
    useTypography,
    usePalette,
    TextInputField
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from '../../../assets/icons/AlertCircle';
import { NetworkingError } from '../../error/store/ErrorStore';
import { LeoErrors } from '../../home/common/errors/LeoErrors';
import { getFormattedTimeDateWithComma } from '../../home/common/utils/DateUtils';
import { AuthErrorDialog } from '../components/AuthErrorDialog';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { useOTPEntryStore } from '../stores/hooks';
import { OTPEntryErrors } from '../stores/OTPEntryStore';
import { getMinutesAndSecondsString, getTimeFrom } from '../utils/TimeUtils';
import { Button } from '../../common/components/Button';

export const EnterOTP = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const typography = useTypography();
    const palette = usePalette();
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [otpError, setOTPError] = useState(false);
    const [resendButtonLabel, setResendButtonLabel] = useState<string>(t('signIn.resendOTP'));
    const [disableVerifyButton, setDisableVerifyButton] = useState(true);
    const [disableResendButton, setDisableResendButton] = useState(true);
    const store = useOTPEntryStore();

    useEffect(() => {
        // this is done to ensure that store is reset when screen is presented
        store.resetStore();
    }, []);

    useEffect(() => {
        // Since we have to setup the initial OTP data before rendering the screen, hence initialOTPState is being set
        store.setInitialOTPState();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (store.nextResendAt) {
                if (disableResendButton) {
                    if (store.nextResendAt > new Date()) {
                        const time = getTimeFrom(store.nextResendAt);
                        setResendButtonLabel(
                            t('signIn.resendOTPIn', {
                                time: getMinutesAndSecondsString(time)
                            })
                        );
                    } else {
                        setDisableResendButton(false);
                        setResendButtonLabel(t('signIn.resendOTP'));
                    }
                }
            } else {
                setResendButtonLabel(t('signIn.resendOTP'));
            }
        }, 1000);
        // If resend button is enabled, we no longer require the timer to show the time remaining for requesting the next OTP
        if (!disableResendButton) {
            setResendButtonLabel(t('signIn.resendOTP'));
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [disableResendButton, store.nextResendAt]);

    useEffect(() => {
        if (isDialogOpen === false) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            store.removeError();
        }
    }, [isDialogOpen]);

    const buttonMarginTop = (): string => {
        if (otpError) {
            if (store.numberOfResendsLeft) {
                return '120px';
            }
            return '140px';
        } else if (store.numberOfResendsLeft) {
            return '140px';
        }
        return '160px';
    };

    const onOTPTextChange = (value: string): void => {
        setOTPError(false);
        store.removeError();
        store.setOTP(value);
        setDisableVerifyButton(store.error === LeoErrors.InvalidOTPError);
    };

    const otpErrorHelperText = (): string | undefined => {
        switch (store.error) {
            case OTPEntryErrors.IncorrectOTP:
                return t('signIn.invalidOTP', { attempts: store.numberOfValidAttemptsLeft });
            case OTPEntryErrors.OTPExpired:
                return t('signIn.otpExpired');
            case OTPEntryErrors.CouldNotSendOTP:
                return t('signIn.couldNotSendOTP');
            case OTPEntryErrors.TooManyOTPAttempts:
                return t('signIn.tooManyIncorrectAttempts');
            case OTPEntryErrors.WaitForResend:
                return t('signIn.waitForOTPResend', {
                    time: getFormattedTimeDateWithComma(store.nextResendAt)
                });
        }
        return undefined;
    };

    const handleOTPError = (): void => {
        if (store.error) {
            switch (store.error) {
                case OTPEntryErrors.IncorrectOTP:
                case OTPEntryErrors.OTPExpired:
                case OTPEntryErrors.CouldNotSendOTP:
                case OTPEntryErrors.WaitForResend:
                    setOTPError(true);
                    break;
                case NetworkingError.InternalError:
                    setIsDialogOpen(true);
                    break;
                default:
                    setIsDialogOpen(true);
                    break;
            }
        }
    };

    const otpTextField = (): React.ReactElement => {
        return (
            <TextInputField
                name="otp"
                value={store.otp}
                type="number"
                onTextChange={onOTPTextChange}
                label={t('signIn.enterOTP')}
                helperText={otpErrorHelperText()}
                helperIcon={otpError ? <AlertCircle /> : undefined}
                error={otpError}
                inputProps={{
                    maxLength: store.otpMaxLength()
                }}
                style={{
                    width: '360px',
                    marginTop: '24px'
                }}
            />
        );
    };

    const onResendOTPClick = async (): Promise<void> => {
        setLoading(true);
        await store.resendSignInOTP();
        if (store.error) {
            handleOTPError();
        }
        setDisableResendButton(true);
        setLoading(false);
    };

    const resendOTPButton = (): React.ReactElement => {
        return (
            <Box sx={{ width: '360px', height: '40px', marginTop: '24px' }}>
                <Button
                    name="resendOTP"
                    variant="outlined-neutral"
                    size="medium"
                    title={resendButtonLabel}
                    isDisabled={disableResendButton}
                    color="primary"
                    onClick={async (): Promise<void> => {
                        await onResendOTPClick();
                    }}
                    style={{
                        width: '360px'
                    }}
                />
            </Box>
        );
    };

    const OtpLeftTypography = styled(Typography)({
        color: palette.label[200],
        ...typography.body3,
        width: '360px',
        height: '20px',
        textAlign: 'center',
        marginTop: '12px'
    });

    const onVerifyOTPClick = async (): Promise<void> => {
        setLoading(true);
        await store.confirmSignInOTP();
        if (store.error) {
            handleOTPError();
        } else if (store.otpValidatedToken) {
            navigate('/auth/set-new-password', { replace: true });
        } else {
            store.completeSignInProcess();
            navigate('/', { replace: true });
        }
        setLoading(false);
    };

    const verifyAuthButton = (): React.ReactElement => {
        return (
            <Box sx={{ width: '360px', height: '56px', marginTop: buttonMarginTop() }}>
                <Button
                    name="verifyOTP"
                    variant="filled"
                    size="large"
                    title={t('signIn.verifyOTP')}
                    isDisabled={disableVerifyButton}
                    color="primary"
                    onClick={async (): Promise<void> => {
                        await onVerifyOTPClick();
                    }}
                    style={{
                        width: '360px'
                    }}
                />
            </Box>
        );
    };

    const errorDialog = (): React.ReactElement => {
        let error: CommonAuthErrors | NetworkingError | undefined;
        if (store.error) {
            error = store.error as CommonAuthErrors | NetworkingError;
        }
        return <AuthErrorDialog open={isDialogOpen} error={error} setOpen={setIsDialogOpen} />;
    };

    return (
        <>
            <Typography
                sx={{
                    color: palette.label[200],
                    ...typography.body2,
                    width: '360px',
                    height: '24px',
                    textAlign: 'center'
                }}>
                {t('signIn.enterOTPSentToPhoneNumber', {
                    phoneNumber: `${
                        store.phoneNumberWithCountryCode() && store.phoneNumberWithCountryCode()
                    }`
                })}
            </Typography>
            {otpTextField()}
            {resendOTPButton()}
            <OtpLeftTypography hidden={!Boolean(store.numberOfResendsLeft?.toString())}>
                {t('signIn.otpsLeft', {
                    numberOfOTPLeft: store.numberOfResendsLeft
                })}
            </OtpLeftTypography>
            {verifyAuthButton()}
            {errorDialog()}
            <LoadingIndicator isLoading={loading} color="white" />
        </>
    );
});
