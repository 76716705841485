import React, { useState } from 'react';
import {
    AutoCompleteInputField,
    AutoCompleteItem,
    DropdownInputField,
    DropdownItem,
    Section,
    TextInputField
} from '@surya-digital/leo-reactjs-ui';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRequestFundTransferStore } from '../store/hooks';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { AccountType } from '../store/ValidateAccountDetailsStore';
import { Instance } from 'mobx-state-tree';
import { SystemAccountCountryDetailModel } from '../store/RequestFundTransferStore';

interface SelectAccountProps {
    title: string;
    errorMessage: string | null;
    systemWalletErrorMessage: string | null;
    accountId: string | null;
    onAccountSelected: (value: string | null) => void;
    accountType: AccountType | null;
    onAccountTypeSelected: (value: AccountType) => void;
    country: Instance<typeof SystemAccountCountryDetailModel> | null;
    onCountrySelected: (value: Instance<typeof SystemAccountCountryDetailModel> | null) => void;
}

export const SelectAccount = ({
    title,
    errorMessage,
    systemWalletErrorMessage,
    accountId,
    onAccountSelected,
    accountType,
    onAccountTypeSelected,
    country,
    onCountrySelected
}: SelectAccountProps): React.ReactElement => {
    const { t } = useTranslation();
    const store = useRequestFundTransferStore();
    const countries = store.systemAccountCountryDetailsList;
    const [selectedCountry, setSelectedCountry] = useState<null | string>(
        country?.country.displayName ?? null
    );

    const resetFields = (): void => {
        onCountrySelected(null);
        onAccountSelected(null);
        setSelectedCountry(null);
    };

    const getCountryDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    name: countryIterator.country.displayName,
                    value: countryIterator.country.countryCode
                };
            });
        } else {
            return [];
        }
    };

    const getSystemAccountsDropdownItems = (): AutoCompleteItem[] => {
        if (country) {
            return country.systemAccountDetails.map((accountIterator) => {
                return {
                    id: accountIterator.accountId,
                    label: accountIterator.displayName
                };
            });
        } else {
            return [];
        }
    };

    const getSystemAccount = (): AutoCompleteItem | undefined => {
        const systemAccounts = getSystemAccountsDropdownItems();
        return systemAccounts.find((account) => {
            return account.id === accountId;
        });
    };

    const getSystemAccountInputFields = (): React.ReactElement => {
        return (
            <Stack direction="row" spacing="16px">
                <DropdownInputField
                    name="selectCountry"
                    label={t('common.selectCountry')}
                    value={country?.country.countryCode}
                    options={getCountryDropdownItems()}
                    style={{ width: '320px' }}
                    onSelect={(value): void => {
                        const selectedCountrySystemDetail = countries.find((element) => {
                            return element.country.countryCode === value.value;
                        });
                        onCountrySelected(selectedCountrySystemDetail ?? null);
                        onAccountSelected(null);
                        setSelectedCountry(value.value);
                    }}
                />
                {country && (
                    <AutoCompleteInputField
                        id="selectSystemAccount"
                        key={selectedCountry}
                        label={t('genericFundsTransfer.selectSystemAccount')}
                        initialValue={getSystemAccount()}
                        options={getSystemAccountsDropdownItems()}
                        style={{ width: '320px' }}
                        onSelect={(value): void => {
                            onAccountSelected(value?.id ?? null);
                        }}
                        error={systemWalletErrorMessage ? true : false}
                        helperText={systemWalletErrorMessage ?? undefined}
                        helperIcon={<AlertCircle />}
                    />
                )}
            </Stack>
        );
    };

    const getUserAccountInputFields = (): React.ReactElement => {
        return (
            <TextInputField
                name={'enterUserAccountId'}
                label={t('genericFundsTransfer.enterUserAccountId')}
                value={accountId ?? ''}
                style={{ width: '320px' }}
                onTextChange={(value): void => {
                    onAccountSelected(value);
                }}
                type="text"
                error={errorMessage ? true : false}
                helperText={errorMessage ?? ''}
                helperIcon={<AlertCircle />}
            />
        );
    };

    const getAccountInputFields = (): React.ReactElement => {
        if (accountType === AccountType.System) {
            return getSystemAccountInputFields();
        }
        if (accountType === AccountType.User) {
            return getUserAccountInputFields();
        } else {
            return <></>;
        }
    };

    return (
        <Section
            styleOverrides={{ width: '720px' }}
            title={title}
            allowViewChange={false}
            content={[
                <Stack spacing={'16px'} key="accountDetails" padding="16px">
                    <Stack>
                        <DropdownInputField
                            name={'selectAccountType'}
                            label={t('genericFundsTransfer.selectAccountType')}
                            value={accountType ?? ''}
                            options={[
                                {
                                    name: t('genericFundsTransfer.systemAccount'),
                                    value: AccountType.System
                                },
                                {
                                    name: t('genericFundsTransfer.userAccount'),
                                    value: AccountType.User
                                }
                            ]}
                            style={{ width: '320px' }}
                            onSelect={(value): void => {
                                resetFields();
                                value.value === AccountType.System
                                    ? onAccountTypeSelected(AccountType.System)
                                    : onAccountTypeSelected(AccountType.User);
                            }}
                        />
                    </Stack>
                    {getAccountInputFields()}
                </Stack>
            ]}
        />
    );
};
