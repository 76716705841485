import { CheckNewBOUserRequestRPC, CheckerResponse, Comment } from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { Status } from '../../common/enums/StatusEnum';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { downloadFile } from '../../common/utils/FileUtils';
import { useCheckNewBOUserRequestRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum NewBOUserCheckStoreError {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    IncorrectRequestType = 'INCORRECT_REQUEST_TYPE',
    CannotAssignInactiveRole = 'CANNOT_ASSIGN_INACTIVE_ROLE',
    InvalidComment = 'INVALID_COMMENT',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    CouldNotFindAuthCodeUrl = 'COULD_NOT_FIND_AUTH_CODE_URL',
    AgentCannotBeAnAgentManager = 'AGENT_CANNOT_BE_AN_AGENT_MANAGER',
    UserAppliedToBeAnAgent = 'USER_APPLIED_TO_BE_AN_AGENT',
    CouldNotGenerateCodes = 'COULD_NOT_GENERATE_CODES',
    EmailIdAlreadyExists = 'EMAIL_ID_ALREADY_EXISTS',
    PhoneNumberAlreadyExists = 'PHONE_NUMBER_ALREADY_EXISTS'
}

const getStoreError = (
    error: CheckNewBOUserRequestRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): NewBOUserCheckStoreError | NetworkingError => {
    switch (error.code) {
        case NewBOUserCheckStoreError.InvalidRequestId:
            return NewBOUserCheckStoreError.InvalidRequestId;
        case NewBOUserCheckStoreError.RequestAlreadyChecked:
            return NewBOUserCheckStoreError.RequestAlreadyChecked;
        case NewBOUserCheckStoreError.CannotAssignInactiveRole:
            return NewBOUserCheckStoreError.CannotAssignInactiveRole;
        case NewBOUserCheckStoreError.CannotCheckSelfRequest:
            return NewBOUserCheckStoreError.CannotCheckSelfRequest;
        case NewBOUserCheckStoreError.IncorrectRequestType:
            return NewBOUserCheckStoreError.IncorrectRequestType;
        case NewBOUserCheckStoreError.RequestAlreadyWithdrawn:
            return NewBOUserCheckStoreError.RequestAlreadyWithdrawn;
        case NewBOUserCheckStoreError.AgentCannotBeAnAgentManager:
            return NewBOUserCheckStoreError.AgentCannotBeAnAgentManager;
        case NewBOUserCheckStoreError.UserAppliedToBeAnAgent:
            return NewBOUserCheckStoreError.UserAppliedToBeAnAgent;
        case NewBOUserCheckStoreError.CouldNotGenerateCodes:
            return NewBOUserCheckStoreError.CouldNotGenerateCodes;
        case NewBOUserCheckStoreError.EmailIdAlreadyExists:
            return NewBOUserCheckStoreError.EmailIdAlreadyExists;
        case NewBOUserCheckStoreError.PhoneNumberAlreadyExists:
            return NewBOUserCheckStoreError.PhoneNumberAlreadyExists;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in CheckNewBOUserRequestRPC`);
            return NetworkingError.InternalError;
    }
};

export const NewBOUserCheckStore = types
    .model({
        requestId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        authCodeUrl: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<NewBOUserCheckStoreError>(
                    'NewBOUserCheckStoreError',
                    Object.values(NewBOUserCheckStoreError)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetComment(): void {
            store.comment = null;
        },
        removeError(): void {
            store.error = null;
        },
        resetStore(): void {
            store.comment = null;
            store.error = null;
            store.requestId = null;
            store.status = null;
        },
        checkRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            store.comment = comment;
            try {
                if (store.comment && store.requestId && store.status) {
                    const request = new CheckNewBOUserRequestRPC.Request(
                        new LeoUUID(store.requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckNewBOUserRequestRPC.Response,
                        CheckNewBOUserRequestRPC.Errors.Errors
                    > = yield useCheckNewBOUserRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        if (response.authCodeDocumentURL) {
                            store.authCodeUrl = response.authCodeDocumentURL.toString();
                        } else if (store.status === Status.APPROVED) {
                            store.error = NewBOUserCheckStoreError.CouldNotFindAuthCodeUrl;
                        }
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckNewBOUserRequestRPC with result: ${result}`
                        );
                    }
                } else {
                    loggerStore.debug(
                        `comment, status or requestId is empty in CheckNewBOUserRequestRPC`
                    );
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = NewBOUserCheckStoreError.InvalidRequestId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = NewBOUserCheckStoreError.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckNewBOUserRequestRPC`
                            );
                            break;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckNewBOUserRequestRPC`
                    );
                }
            }
        }),
        downloadAuthCode: flow(function* () {
            if (store.authCodeUrl) {
                downloadFile(store.authCodeUrl);
            } else {
                store.error = NewBOUserCheckStoreError.CouldNotFindAuthCodeUrl;
            }
        })
    }));

export const createNewBOUserCheckStoreStore = (): Instance<typeof NewBOUserCheckStore> => {
    return NewBOUserCheckStore.create();
};
