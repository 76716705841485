import { useGetBCNUserListRPCClient } from './../rpcs/RPC';
import {
    UserSearchBy,
    CountryCode,
    GetBCNUserListPaginationResponse,
    GetBCNUserListRPC,
    ItemsPerPage,
    PageIndex,
    SearchText,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserSearchErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const BCNSearchUser = types.model({
    id: types.string,
    name: types.string,
    country: types.string,
    phoneNumber: types.string,
    status: types.enumeration<UserStatus.UserStatus>(
        'BCNUserStatus',
        Object.values(UserStatus.UserStatus)
    )
});

const getBCNSearchUserModel = (
    countries: CountryViewModel[],
    paginationResponse: GetBCNUserListPaginationResponse
): Instance<typeof BCNSearchUser> => {
    return BCNSearchUser.create({
        id: paginationResponse.userId.uuid,
        name: paginationResponse.name,
        country: countries.filter((_country: CountryViewModel) => {
            return _country.countryCode === paginationResponse.countryCode.code;
        })[0].name,
        phoneNumber: paginationResponse.phoneNumber.phoneNumber,
        status: paginationResponse.userStatus
    });
};

const getPhoneNumberSearchText = (
    filter: Instance<typeof BCNUserFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === UserSearchBy.UserSearchBy.MOBILE_NUMBER) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

const getNameSearchText = (
    filter: Instance<typeof BCNUserFilterOptions> | undefined
): SearchText | undefined => {
    if (filter && filter.searchBy === UserSearchBy.UserSearchBy.NAME) {
        try {
            return new SearchText(filter.searchText);
        } catch {
            return undefined;
        }
    }
};

export const BCNUserFilterOptions = types.model({
    searchBy: types.string,
    searchText: types.string,
    selectedUserStatus: types.string,
    selectedCountry: types.maybe(types.string)
});

export const BCNUserSearchStore = types
    .model({
        // The initial filter options for BO user search table will be dependent on `t` function.
        // The filter options is set as maybe and then updated from the UI.
        filterOptions: types.maybe(BCNUserFilterOptions),
        countryListStore: CountryListStore,
        totalItems: types.number,
        users: types.array(BCNSearchUser),
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserSearchErrors>(
                    'BCNUserSearchErrors',
                    Object.values(BCNUserSearchErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        updateFilterOptions(filterOptions: Instance<typeof BCNUserFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        removeError(): void {
            store.error = null;
        },
        fetchBCNUserSearch: flow(function* (
            filter: Instance<typeof BCNUserFilterOptions> | undefined,
            pageIndex: number,
            itemsPerPage: number
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetBCNUserListRPC.Request(
                getNameSearchText(filter),
                getPhoneNumberSearchText(filter),
                filter?.selectedCountry ? new CountryCode(filter?.selectedCountry) : null,
                filter?.selectedUserStatus === AnyEnum.ANY
                    ? null
                    : filter?.selectedUserStatus
                    ? UserStatus.fromDTO({ case: filter.selectedUserStatus })
                    : null,
                new ItemsPerPage(itemsPerPage),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetBCNUserListRPC.Response,
                GetBCNUserListRPC.Errors.Errors
            > = yield useGetBCNUserListRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const bcnUsers = response.getBCNUserListPaginationResponse.map(
                    (paginationResponse: GetBCNUserListPaginationResponse) => {
                        return getBCNSearchUserModel(
                            store.countryListStore.countryList(),
                            paginationResponse
                        );
                    }
                );
                store.totalItems = response.totalItems;
                store.users = cast(bcnUsers);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case BCNUserSearchErrors.InvalidPageIndex:
                        store.error = BCNUserSearchErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetBCNUserListRPC `
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetBCNUserListRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views((store) => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10,
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        }
    }));

export const createBCNUserSearchStore = (): Instance<typeof BCNUserSearchStore> => {
    return BCNUserSearchStore.create({
        countryListStore: createCountryListStore(),
        users: [],
        totalItems: 0
    });
};
