import { Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../assets/icons/Download';
import { Button } from '../../../common/components/Button';
import { Dialog } from '../../../common/components/Dialog';

interface AddBOUserDownloadAuthCodeDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    title: string;
    buttonTitle?: string | null;
    email: string;
    onDownloadClick: () => void;
}

export const AddBOUserDownloadAuthCodeDialog = ({
    isDialogOpen,
    onClose,
    email,
    title,
    onDownloadClick
}: AddBOUserDownloadAuthCodeDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Dialog
            open={isDialogOpen}
            title={title}
            secondaryButtonText={t('common.close')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <>
                <Typography sx={{ ...typography.small2, color: palette.informative[300] }}>
                    {t('boUser.mailWithTemporaryPasswordHasBeenSend', {
                        email
                    })}
                </Typography>
                <Typography sx={{ ...typography.sh3, color: palette.label[300], mt: '20px' }}>
                    {t('signIn.authCode')}
                </Typography>
                <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                    {t('boUser.authCodeSubtitle')}
                </Typography>
                <Button
                    style={{ width: '163px', marginTop: '12px' }}
                    name="download"
                    size="small"
                    icon={<Download />}
                    iconPosition="left"
                    title={t('boUser.downloadAuthCode')}
                    variant="outlined-color"
                    onClick={onDownloadClick}
                />
            </>
        </Dialog>
    );
};
