import { RequestCBSTransferForUnclaimedFundsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestCBSTransferForUnclaimedFundsRPCImpl extends RequestCBSTransferForUnclaimedFundsRPC {
    execute(
        _request: RequestCBSTransferForUnclaimedFundsRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestCBSTransferForUnclaimedFundsRPC.Response,
            RequestCBSTransferForUnclaimedFundsRPC.Errors.Errors
        >
    > {
        const response = new RequestCBSTransferForUnclaimedFundsRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestCBSTransferForUnclaimedFundsRPC.Response,
                RequestCBSTransferForUnclaimedFundsRPC.Errors.Errors
            >
        >;
    }
}
