import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useTypography, usePalette, ButtonIcon } from '@surya-digital/leo-reactjs-ui';
import { Button } from '../../../common/components/Button';

export const HEADER_HEIGHT = '96px';

interface ActionButton {
    title?: string;
    onClick?: () => void;
    icon?: ButtonIcon;
    helperText?: string;
    isDisabled?: boolean;
}

export interface ActionElement {
    primaryButton?: ActionButton;
    secondaryButton?: ActionButton;
    dropdown?: React.ReactElement;
}

export interface PageHeaderProps {
    title: string;
    subtitle: string;
    actionElement?: ActionElement;
}

const StyledStack = styled(Stack)(() => ({
    padding: '16px 32px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white'
}));

export function PageHeader({
    title,
    subtitle,
    actionElement
}: PageHeaderProps): React.ReactElement {
    const typography = useTypography();
    const palette = usePalette();

    const primaryButton = (): React.ReactElement => {
        return (
            <Box>
                <Button
                    name="primaryButton"
                    variant="filled"
                    size="large"
                    isDisabled={actionElement?.primaryButton?.isDisabled}
                    title={actionElement!.primaryButton!.title}
                    onClick={(): void => {
                        if (actionElement?.primaryButton?.onClick) {
                            actionElement?.primaryButton.onClick();
                        }
                    }}
                    icon={actionElement?.primaryButton?.icon}
                    iconPosition="left"
                />
                {actionElement?.primaryButton?.helperText && (
                    <Typography
                        sx={{
                            ...typography.caption1,
                            color: palette.label['200'],
                            marginTop: '4px',
                            textAlign: 'center'
                        }}>
                        {actionElement?.primaryButton?.helperText}
                    </Typography>
                )}
            </Box>
        );
    };

    const secondaryButton = (): React.ReactElement => {
        return (
            <Box textAlign="center">
                <Button
                    name="secondaryButton"
                    variant="outlined-color"
                    size="large"
                    isDisabled={actionElement?.secondaryButton?.isDisabled}
                    title={actionElement!.secondaryButton!.title}
                    onClick={(): void => {
                        if (actionElement?.secondaryButton?.onClick) {
                            actionElement.secondaryButton.onClick();
                        }
                    }}
                    icon={actionElement?.secondaryButton?.icon}
                    iconPosition="left"
                />
                {actionElement?.secondaryButton?.helperText && (
                    <Typography
                        sx={{
                            ...typography.caption1,
                            color: palette.label['200'],
                            marginTop: '4px',
                            textAlign: 'center'
                        }}>
                        {actionElement?.secondaryButton?.helperText}
                    </Typography>
                )}
            </Box>
        );
    };

    const RenderActionButtons = (): React.ReactElement => {
        const hasPrimaryButton =
            actionElement?.primaryButton !== undefined &&
            actionElement?.primaryButton.title !== undefined;
        const hasSecondaryButton =
            actionElement?.secondaryButton !== undefined &&
            actionElement?.secondaryButton.title !== undefined;
        const hasDropdown = actionElement?.dropdown !== undefined;

        return (
            <Stack direction="row">
                {hasSecondaryButton ? secondaryButton() : <></>}
                {hasSecondaryButton && hasPrimaryButton && <Box width="12px" />}
                {hasPrimaryButton ? primaryButton() : <></>}
                {hasDropdown ? actionElement?.dropdown : <></>}
            </Stack>
        );
    };

    return (
        <StyledStack direction="row">
            <Stack>
                <Typography sx={{ ...typography.sh1, color: palette.label[300], mb: '4px' }}>
                    {title}
                </Typography>
                <Typography sx={{ ...typography.body1, color: palette.label[200] }}>
                    {subtitle}
                </Typography>
            </Stack>
            <RenderActionButtons />
        </StyledStack>
    );
}
