import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import {
    EllipsisTypography,
    TextAreaInputField,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploadComponent } from '../file-upload/FileUploadComponent';
import successIcon from '../../../../../assets/check-circle.svg';
import errorIcon from '../../../../../assets/alert-circle.svg';
import { UploadFeeRulesStore } from '../../../fee-rules/store/UploadFeeRulesStore';
import { UploadTransactionRulesStore } from '../../../transaction-rules/store/UploadTransactionRulesStore';
import { FileUploadError } from '../../utils/FileUploadUtils';
import { Dialog } from '../../../../common/components/Dialog';

export interface UpdateRuleRequestProps {
    title: string;
    errorLabel: string | null;
    commentErrorLabel: string | null;
    showUploadFileDialog: boolean;
    store: Instance<typeof UploadFeeRulesStore | typeof UploadTransactionRulesStore>;
    onCommentChange: (comment: string) => void;
    onUploadFileDialogClose: () => void;
    onUpdateRuleSubmitFile: (recordId: LeoUUID) => Promise<void>;
    onRuleFileUploadAgain: () => void;
    isRuleUpdated: boolean;
}

export const UpdateRuleRequestComponent = ({
    title,
    errorLabel,
    commentErrorLabel,
    showUploadFileDialog,
    store,
    onCommentChange,
    onUploadFileDialogClose,
    onUpdateRuleSubmitFile,
    onRuleFileUploadAgain,
    isRuleUpdated
}: UpdateRuleRequestProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    const [comment, setComment] = useState<string | null>();
    const [fileName, setFileName] = useState<string | null>();
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false);
    const [fileUploadErrorLabel, setFileUploadErrorLabel] = useState<string | null>();
    const [isRuleUpdating, setIsRuleUpdating] = useState(false);
    const [fileId, setFileId] = useState<LeoUUID | null>();

    const getPrimaryButtonTitle = (): string | null => {
        if (isRuleUpdated) {
            return null;
        } else if (errorLabel) {
            return t('common.uploadAgain');
        } else if (isRuleUpdating) {
            return null;
        } else {
            return t('common.submitFile');
        }
    };

    const getSecondaryButtonTitle = (): string | null => {
        if (isRuleUpdated) {
            return t('common.close');
        } else if (errorLabel) {
            return t('common.cancel');
        } else if (isRuleUpdating) {
            return null;
        } else {
            return t('common.cancel');
        }
    };

    const getFileUploadErrorLabel = (error: FileUploadError): string => {
        switch (error) {
            case FileUploadError.MaxFileSizeExceeded:
                return t('common.maxFileSizeExceeded');
            case FileUploadError.MinFileSizeDidNotExceed:
                return t('common.minSizeLimitDidNotExceed');
            case FileUploadError.InvalidFileName:
                return t('common.invalidFileName');
            case FileUploadError.InvalidFileFormat:
                return t('common.invalidFileFormat');
            default:
                return t('common.uploadFail');
        }
    };

    const isFileUploadErrorLabel = (): boolean => {
        if (fileUploadErrorLabel) {
            return true;
        } else {
            return false;
        }
    };

    const onFileUploadError = (error: FileUploadError): void => {
        setIsFileUploadComplete(true);
        setFileUploadErrorLabel(getFileUploadErrorLabel(error));
    };

    const onFileUploadSuccess = (_fileId: LeoUUID): void => {
        setFileId(_fileId);
        setIsFileUploadComplete(true);
    };

    const onFileUploadBegin = (_fileName: string): void => {
        setFileUploadErrorLabel(null);
        setIsFileUploadComplete(false);
        setFileName(_fileName);
    };

    const getFileUploadLoadingComponent = (): React.ReactElement => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '50%'
                }}>
                <CircularProgress
                    size="20px"
                    sx={{
                        color: palette.primary[300]
                    }}
                />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.primary[300],
                        marginLeft: '4px',
                        whiteSpace: 'nowrap'
                    }}>
                    {t('common.uploadingFile')}
                </Typography>
            </Box>
        );
    };

    const getFileUploadSuccessfulComponent = (): React.ReactElement => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '50%'
                }}>
                <img src={successIcon} style={{ height: '20px', width: '20px' }} />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.success[300],
                        marginLeft: '4px',
                        whiteSpace: 'nowrap'
                    }}>
                    {t('common.uploadSuccessful')}
                </Typography>
            </Box>
        );
    };

    const getFileUploadErrorComponent = (): React.ReactElement => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '50%'
                }}>
                <img src={errorIcon} style={{ height: '20px', width: '20px' }} />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.error[300],
                        marginLeft: '4px',
                        whiteSpace: 'nowrap'
                    }}>
                    {fileUploadErrorLabel}
                </Typography>
            </Box>
        );
    };

    const getRuleUpdatingComponent = (): React.ReactElement => {
        return (
            <Box
                style={{
                    height: '104px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <CircularProgress
                    size="32px"
                    sx={{
                        color: palette.primary[300],
                        marginTop: '16px'
                    }}
                />
                <Typography
                    sx={{ ...typography.body2, color: palette.primary[300], marginTop: '16px' }}>
                    {t('common.validatingFile', { fileName })}
                </Typography>
            </Box>
        );
    };

    const getRuleUpdatedSuccessfullyComponent = (): React.ReactElement => {
        return (
            <Box
                style={{
                    height: '104px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <img
                    src={successIcon}
                    style={{ height: '32px', width: '32px', marginTop: '16px' }}
                />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.success[300],
                        marginTop: '16px'
                    }}>
                    {t('common.fileSuccessfullyValidated')}
                </Typography>
            </Box>
        );
    };

    const getRuleUpdateErrorComponent = (): React.ReactElement => {
        return (
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                <Typography
                    sx={{
                        ...typography.small2,
                        color: palette.label[300]
                    }}>
                    {t('common.errorsFoundWhileParsing')}
                </Typography>
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.error[300],
                        whiteSpace: 'pre-line'
                    }}>
                    {errorLabel}
                </Typography>
            </Box>
        );
    };

    const isRuleInValidationState = (): boolean => {
        if (isRuleUpdating || isRuleUpdated || errorLabel) {
            return true;
        } else {
            return false;
        }
    };

    const getRuleValidatingStateComponent = (): React.ReactElement => {
        // Since the validation can be in one of the following states hence the conditions have been added
        if (isRuleUpdating && !(isRuleUpdated || errorLabel)) {
            return getRuleUpdatingComponent();
        } else if (isRuleUpdated) {
            return getRuleUpdatedSuccessfullyComponent();
        } else if (errorLabel) {
            return getRuleUpdateErrorComponent();
        } else {
            return <></>;
        }
    };

    const getFileUploadRuleComponent = (): React.ReactElement => {
        return (
            <Box style={{ marginBottom: '20px' }}>
                <FileUploadComponent
                    onFileUploadBegin={onFileUploadBegin}
                    onFileUploadError={onFileUploadError}
                    onFileUploadSuccess={onFileUploadSuccess}
                    store={store}
                />
            </Box>
        );
    };

    const getFileDetailsComponent = (): React.ReactElement => {
        return (
            <Box style={{ marginBottom: '20px' }}>
                <Typography sx={{ ...typography.small2 }}>{t('common.fileDetails')}</Typography>
                <Stack sx={{ marginTop: '8px', display: 'flex' }}>
                    {fileName && <EllipsisTypography>{fileName}</EllipsisTypography>}
                    {!isFileUploadComplete && getFileUploadLoadingComponent()}
                    {isFileUploadComplete &&
                        !isFileUploadErrorLabel() &&
                        getFileUploadSuccessfulComponent()}
                    {fileUploadErrorLabel && getFileUploadErrorComponent()}
                </Stack>
            </Box>
        );
    };

    const resetComponentStates = (): void => {
        setFileName(null);
        setFileId(null);
        setIsRuleUpdating(false);
        setComment(null);
        setFileUploadErrorLabel(null);
        setIsFileUploadComplete(false);
    };

    return (
        <Dialog
            open={showUploadFileDialog}
            title={title}
            primaryButtonText={getPrimaryButtonTitle() ?? undefined}
            isPrimaryButtonDisabled={!(fileId && comment) || isRuleUpdating}
            onPrimaryButtonClick={async (): Promise<void> => {
                const primaryButtonTitle = getPrimaryButtonTitle();
                if (primaryButtonTitle === t('common.submitFile')) {
                    if (fileId) {
                        setIsRuleUpdating(true);
                        await onUpdateRuleSubmitFile(fileId);
                        setIsRuleUpdating(false);
                    } else {
                        // this is a developer error since button will only be enabled when fileId is present.
                    }
                } else if (primaryButtonTitle === t('common.uploadAgain')) {
                    onRuleFileUploadAgain();
                    resetComponentStates();
                }
            }}
            secondaryButtonText={getSecondaryButtonTitle() ?? undefined}
            onSecondaryButtonClick={(): void => {
                onUploadFileDialogClose();
                resetComponentStates();
            }}
            disableBackdropClick={true}>
            {isRuleInValidationState() ? (
                getRuleValidatingStateComponent()
            ) : (
                <Box>
                    {(!fileName || isFileUploadErrorLabel()) && getFileUploadRuleComponent()}
                    {fileName && getFileDetailsComponent()}
                    <TextAreaInputField
                        name="comment"
                        isRequired
                        numberOfRows={3}
                        value={comment ?? undefined}
                        onTextChange={(inputValue: string): void => {
                            setComment(inputValue);
                            onCommentChange(inputValue);
                        }}
                        error={commentErrorLabel ? true : false}
                        helperText={commentErrorLabel ?? undefined}
                        label={t('common.addComment')}
                    />
                </Box>
            )}
        </Dialog>
    );
};
