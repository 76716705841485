import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { GetLinkedTransactionsRPC, LinkedTransaction } from '@resolut-tech/bcn-rpcs';
import { useGetLinkedTransactionsClient } from '../../transaction/rpcs/RPC';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getLocalizedImageUrl } from '../../../../utils/UiUtils';
import { AmountModel, getAmountModel } from '../models/AmountModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getFormattedTimeDateWithComma } from '../utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';

export enum LinkedTransactionsErrors {
    InvalidTransactionID = 'INVALID_TRANSACTION_ID'
}

const LinkedTransactionModel = types.model({
    title: types.string,
    isRefunded: types.boolean,
    amount: AmountModel,
    occurredAt: types.string,
    imageUrl: types.maybeNull(types.string)
});

export const LinkedTransactionsStore = types
    .model('LinkedTransaction', {
        transactions: types.array(LinkedTransactionModel),
        error: types.maybeNull(
            types.enumeration<LinkedTransactionsErrors>(
                'LinkedTransactionsErrors',
                Object.values(LinkedTransactionsErrors)
            )
        )
    })
    .actions((store) => ({
        fetchLinkedTransactions: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            const request = new GetLinkedTransactionsRPC.Request(new LeoUUID(transactionId));
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetLinkedTransactionsRPC.Response,
                GetLinkedTransactionsRPC.Errors.Errors
            > = yield useGetLinkedTransactionsClient(apiClient).execute(request);
            store.error = null;
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const linkedTransactions = response.linkedTransactions.map(
                    (transaction: LinkedTransaction) => {
                        return {
                            title: getTranslatedString(transaction.title),
                            isRefunded: transaction.isRefunded,
                            amount: getAmountModel(transaction.amount),
                            occurredAt: getFormattedTimeDateWithComma(
                                new Date(transaction.occurredAt.timestamp)
                            ),
                            imageUrl: transaction.image
                                ? getLocalizedImageUrl(transaction.image)
                                : null
                        };
                    }
                );
                store.transactions = cast(linkedTransactions);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case LinkedTransactionsErrors.InvalidTransactionID:
                        store.error = LinkedTransactionsErrors.InvalidTransactionID;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetLinkedTransactionsRPC`
                        );
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetLinkedTransactionsRPC with result: ${result}`
                );
            }
        })
    }));

export const createLinkedTransactionsStore = (): Instance<typeof LinkedTransactionsStore> => {
    return LinkedTransactionsStore.create({
        transactions: []
    });
};
