import { GetCurrentTransactionValidationRuleDetailsFileRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCurrentTransactionValidationRuleDetailsFileRPCImpl extends GetCurrentTransactionValidationRuleDetailsFileRPC {
    execute(
        _request: GetCurrentTransactionValidationRuleDetailsFileRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCurrentTransactionValidationRuleDetailsFileRPC.Response,
            GetCurrentTransactionValidationRuleDetailsFileRPC.Errors.Errors
        >
    > {
        const response = new GetCurrentTransactionValidationRuleDetailsFileRPC.Response(
            new LeoUUID()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCurrentTransactionValidationRuleDetailsFileRPC.Response,
                GetCurrentTransactionValidationRuleDetailsFileRPC.Errors.Errors
            >
        >;
    }
}
