import { BOGender, GetProfileDetailsRPC, BORole } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import {
    getPasswordPolicyModel,
    PasswordPolicyModel
} from '../../../common/models/PasswordPolicyModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useGetProfileDetailsRPClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ProfileDetailsModel = types.model({
    profilePhoto: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    otherNames: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    emailId: types.maybeNull(types.string),
    gender: types.maybeNull(
        types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender))
    ),
    roles: types.array(types.string),
    passwordPolicy: types.maybeNull(PasswordPolicyModel)
});

const getProfileDetailsModel = (
    details: GetProfileDetailsRPC.Response
): Instance<typeof ProfileDetailsModel> => {
    return ProfileDetailsModel.create({
        profilePhoto: details.profileImage && getImageUrl(details.profileImage),
        firstName: details.userName.firstName.text,
        lastName: details.userName.lastName?.text,
        otherNames: details.otherName?.text,
        phoneNumber: details.phoneNumber.phoneNumber,
        emailId: details.emailId.emailId,
        gender: details.gender,
        roles: details.userRoles.map(
            (boRole: BORole) =>
                `${getTranslatedString(boRole.workflow)} ${getTranslatedString(boRole.name)}`
        ),
        passwordPolicy: getPasswordPolicyModel(details.passwordPolicy)
    });
};

export const ProfileDetailsStore = types
    .model({
        details: ProfileDetailsModel,
        error: types.maybeNull(
            types.enumeration<NetworkingError>('NetworkingError', Object.values(NetworkingError))
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        getProfileDetails: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new GetProfileDetailsRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetProfileDetailsRPC.Response,
                GetProfileDetailsRPC.Errors.Errors
            > = yield useGetProfileDetailsRPClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.details = getProfileDetailsModel(response);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(`Unhandled error: ${error} occurred in GetProfileDetailsRPC`);
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetProfileDetailsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        deletePhoto(): void {
            store.details.profilePhoto = null;
        },
        updatePhoto(photoUrl: string): void {
            store.details.profilePhoto = photoUrl;
        }
    }));

export const createProfileDetailsStore = (): Instance<typeof ProfileDetailsStore> => {
    return ProfileDetailsStore.create({
        details: ProfileDetailsModel.create({
            roles: []
        })
    });
};
