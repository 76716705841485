import {
    GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse,
    IntermediateRequestStatus
} from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getPGAmountString } from '../../common/utils/UIUtils';

export const AgentTransactionRequestModel = types.model({
    createdAt: types.string,
    amount: types.string,
    requestedBy: types.string,
    evaluatedBy: types.maybeNull(types.string),
    requestId: types.maybeNull(types.string),
    transactionId: types.string,
    requestStatus: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    )
});

export const getAgentTransactionRequestModel = (
    agentTransactionRequest: GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse
): Instance<typeof AgentTransactionRequestModel> => {
    return AgentTransactionRequestModel.create({
        createdAt: getFormattedTimeDateWithComma(
            new Date(agentTransactionRequest.requestedAt.timestamp)
        ),
        amount: getPGAmountString(agentTransactionRequest.amount),
        requestedBy: getPGFullName(agentTransactionRequest.requestedBy),
        evaluatedBy:
            agentTransactionRequest.evaluatedBy &&
            getPGFullName(agentTransactionRequest.evaluatedBy),
        requestId: agentTransactionRequest.requestId?.id,
        transactionId: agentTransactionRequest.transactionId.uuid,
        requestStatus: agentTransactionRequest.requestStatus
    });
};
