import { Divider, Stack, styled, Typography } from '@mui/material';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { DialogLoadingState } from '../../common/components/DialogLoadingState';
import { getAmountString } from '../../common/utils/UIUtils';
import { useRequestCBSTransferStore } from '../store/hooks';
import { RequestCBSTransferErrors } from '../store/RequestCBSTransferStore';
import { Dialog } from '../../../common/components/Dialog';

interface RequestCBSTransferDialogProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onSuccess: () => void;
    setIsErrorDialogMessage: (error: string) => void;
}

export const RequestCBSTransferDialog = observer(
    ({
        isDialogOpen,
        onDialogClose,
        onSuccess,
        setIsErrorDialogMessage
    }: RequestCBSTransferDialogProps): React.ReactElement => {
        const { t } = useTranslation();
        const typography = useTypography();
        const palette = usePalette();
        const store = useRequestCBSTransferStore();
        const [isLoading, setIsLoading] = useState(false);
        const [isCommentSubmitted, setIsCommentSubmitted] = useState(false);

        // This is done to ensure that when Request CBS Transfer Dialog is opened the text input area is cleared
        useEffect(() => {
            store.resetStore();
        }, [isDialogOpen]);

        const getCommentComponent = (): React.ReactElement => {
            return (
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={store.comment ?? undefined}
                    onTextChange={(inputValue: string): void => {
                        store.setComment(inputValue);
                    }}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            );
        };

        const StyledDivider = styled(Divider)(() => ({
            borderBottom: `2px dashed ${palette.outline[300]}`
        }));

        const getSummaryComponent = (): React.ReactElement => {
            return (
                <>
                    <StyledDivider />
                    <Typography
                        sx={{ ...typography.small1, color: palette.label[300], marginTop: '12px' }}>
                        {t('unclaimedFunds.requestCBSTransferSummary')}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" marginTop="12px">
                        <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                            {t('common.noOfTransactions')}
                        </Typography>
                        <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                            {store.totalTransaction}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        marginBottom="12px"
                        marginTop="6px">
                        <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                            {t('common.totalAmount')}
                        </Typography>
                        <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                            {store.totalAmount ? getAmountString(store.totalAmount) : ''}
                        </Typography>
                    </Stack>
                    <StyledDivider />
                </>
            );
        };

        const getPrimaryButtonText = (): string | null => {
            if (isLoading) {
                return null;
            } else if (isCommentSubmitted) {
                return t('common.proceed');
            } else {
                return t('common.requestTransfer');
            }
        };

        const isPrimaryButtonDisabled = (): boolean => {
            return store.comment?.trim().length === 0 || store.comment === null;
        };

        const submitComment = async (): Promise<void> => {
            setIsLoading(true);
            store.setupStore();
            await store.getMakerUnclaimedTransactionInfo();
            setIsLoading(false);
            if (!store.error) {
                setIsCommentSubmitted(true);
            } else {
                switch (store.error) {
                    case RequestCBSTransferErrors.InvalidDateRange:
                        setIsErrorDialogMessage(t('common.invalidDateRange'));
                        break;
                    case RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable:
                        setIsErrorDialogMessage(
                            t('unclaimedFunds.noUnclaimedTransactionsAvailable')
                        );
                        break;
                    case NetworkingError.InternalError:
                        setIsErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setIsErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            }
        };

        const onProceedButtonClick = async (): Promise<void> => {
            setIsLoading(true);
            await store.requestCBSTransferForUnclaimedFunds();
            setIsLoading(false);
            if (!store.error) {
                store.resetStore();
                onSuccess();
                onDialogClose();
            } else {
                switch (store.error) {
                    case RequestCBSTransferErrors.InvalidDateRange:
                        setIsErrorDialogMessage(t('common.invalidDateRange'));
                        break;
                    case RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable:
                        setIsErrorDialogMessage(
                            t('unclaimedFunds.noUnclaimedTransactionsAvailable')
                        );
                        break;
                    case RequestCBSTransferErrors.ProcessingRequestFound:
                        setIsErrorDialogMessage(t('unclaimedFunds.ProcessingRequestFound'));
                        break;
                    case RequestCBSTransferErrors.InvalidComment:
                        setIsErrorDialogMessage(t('common.invalidComment'));
                        break;
                    case NetworkingError.InternalError:
                        setIsErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setIsErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            }
        };

        return (
            <Dialog
                open={isDialogOpen}
                title={t('unclaimedFunds.requestCBSTransfer')}
                primaryButtonText={getPrimaryButtonText() ?? undefined}
                onPrimaryButtonClick={isCommentSubmitted ? onProceedButtonClick : submitComment}
                isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
                secondaryButtonText={t('common.cancel')}
                onSecondaryButtonClick={(): void => {
                    store.resetStore();
                    onDialogClose();
                }}
                disableBackdropClick>
                {isLoading ? (
                    <DialogLoadingState label={t('common.loadingMessage')} />
                ) : isCommentSubmitted ? (
                    getSummaryComponent()
                ) : (
                    getCommentComponent()
                )}
            </Dialog>
        );
    }
);
