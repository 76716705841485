import {
    AgentListTransactionStatus,
    AgentTransactionRequestSearchBy,
    AgentTransactionSearchBy,
    AgentTransactionType,
    UserSearchBy
} from '@resolut-tech/bcn-rpcs';
import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { TFunction } from 'react-i18next';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { Instance } from 'mobx-state-tree';
import { AgentTransactionSearchFilterOptions } from '../store/AgentTransactionSearchStore';
import { StatusType } from '../../common/components/StatusComponent';
import { AgentTransactionRequestHistoryFilterOptions } from '../store/AgentTransactionRequestHistoryStore';
import { RequestStatus } from '../../common/enums/RequestStatus';
import { AgentFilterOptions } from '../store/AgentSearchStore';

const getAgentTransactionSearchByValue = (
    value: AgentTransactionSearchBy.AgentTransactionSearchBy,
    t: TFunction
): string => {
    const values = AgentTransactionSearchBy.AgentTransactionSearchBy;
    switch (value) {
        case values.REQUEST_ID:
            return t('agencyBanking.requestId');
        case values.TRANSACTION_ID:
            return t('common.transactionId');
        case values.SENDER_MOBILE_NUMBER:
            return t('common.tableHeader.sendersMobileNumber');
        case values.RECIPIENT_MOBILE_NUMBER:
            return t('common.tableHeader.receiversMobileNumber');
        case values.AGENT_MOBILE_NUMBER:
            return t('agencyBanking.agentsMobileNumber');
    }
};

export const getAgentTransactionSearchByOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(AgentTransactionSearchBy.AgentTransactionSearchBy);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getAgentTransactionSearchByValue(
            value as AgentTransactionSearchBy.AgentTransactionSearchBy,
            t
        );
        return { name: dropdownValue, value };
    });
};

export const getTransactionStatus = (
    status: AgentListTransactionStatus.AgentListTransactionStatus
): StatusType => {
    switch (status) {
        case AgentListTransactionStatus.AgentListTransactionStatus.SUCCEEDED:
            return StatusType.SUCCEEDED;
        case AgentListTransactionStatus.AgentListTransactionStatus.UNCOLLECTED:
            return StatusType.UNCOLLECTED;
        case AgentListTransactionStatus.AgentListTransactionStatus.AWAITING_RECIPIENT_COLLECTION:
            return StatusType.AWAITING_RECIPIENT_COLLECTION;
        case AgentListTransactionStatus.AgentListTransactionStatus.MOVED_OUT_OF_YAFIKA:
            return StatusType.MOVED_OUT_OF_YAFIKA;
        case AgentListTransactionStatus.AgentListTransactionStatus.MOVED_TO_UNCLAIMED_FUNDS:
            return StatusType.MOVED_TO_UNCLAIMED_FUNDS;
        case AgentListTransactionStatus.AgentListTransactionStatus.PROCESSING:
            return StatusType.PROCESSING;
        case AgentListTransactionStatus.AgentListTransactionStatus.REFUND_TO_SENDER_BLOCKED:
            return StatusType.REFUND_TO_SENDER_BLOCKED;
        case AgentListTransactionStatus.AgentListTransactionStatus.REVERSED:
            return StatusType.REVERSED;
        case AgentListTransactionStatus.AgentListTransactionStatus.SUCCESSFULLY_REFUNDED_TO_SENDER:
            return StatusType.REFUNDED;
    }
};

export const getTransactionTypeValue = (
    type: AgentTransactionType.AgentTransactionType,
    t: TFunction
): string => {
    switch (type) {
        case AgentTransactionType.AgentTransactionType.MONEY_TRANSFER:
            return t('agencyBanking.transactionTypeMoneyTransfer');
        case AgentTransactionType.AgentTransactionType.CASH_IN:
            return t('agencyBanking.transactionTypeCashIn');
        case AgentTransactionType.AgentTransactionType.CASH_OUT:
            return t('agencyBanking.transactionTypeCashOut');
        case AgentTransactionType.AgentTransactionType.COMMISSION:
            return t('agencyBanking.transactionTypeCommission');
    }
};

const getAgentListTransactionStatusTranslation = (
    transactionType: AgentListTransactionStatus.AgentListTransactionStatus,
    t: TFunction
): string => {
    switch (transactionType) {
        case AgentListTransactionStatus.AgentListTransactionStatus.SUCCEEDED:
            return t('agencyBanking.success');
        case AgentListTransactionStatus.AgentListTransactionStatus.UNCOLLECTED:
            return t('agencyBanking.uncollected');
        case AgentListTransactionStatus.AgentListTransactionStatus.AWAITING_RECIPIENT_COLLECTION:
            return t('agencyBanking.awaitingRecipientCollection');
        case AgentListTransactionStatus.AgentListTransactionStatus.MOVED_OUT_OF_YAFIKA:
            return t('agencyBanking.movedToRegulator');
        case AgentListTransactionStatus.AgentListTransactionStatus.MOVED_TO_UNCLAIMED_FUNDS:
            return t('agencyBanking.movedToUnclaimedFunds');
        case AgentListTransactionStatus.AgentListTransactionStatus.PROCESSING:
            return t('common.processing');
        case AgentListTransactionStatus.AgentListTransactionStatus.REFUND_TO_SENDER_BLOCKED:
            return t('agencyBanking.refundToSenderBlocked');
        case AgentListTransactionStatus.AgentListTransactionStatus.REVERSED:
            return t('agencyBanking.reversed');
        case AgentListTransactionStatus.AgentListTransactionStatus.SUCCESSFULLY_REFUNDED_TO_SENDER:
            return t('common.refunded');
    }
};

export const getAgentListTransactionStatusOptions = (t: TFunction): DropdownItem[] => {
    let values = Object.keys(AgentListTransactionStatus.AgentListTransactionStatus);
    const anyValue = Object.keys(AnyEnum);
    values = [...anyValue, ...values];
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getAgentListTransactionStatusTranslation(
            value as AgentListTransactionStatus.AgentListTransactionStatus,
            t
        );
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getAgentListTransactionTypeOptions = (t: TFunction): DropdownItem[] => {
    let values = Object.keys(AgentTransactionType.AgentTransactionType);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getTransactionTypeValue(
            value as AgentTransactionType.AgentTransactionType,
            t
        );
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getAgentTransactionListInitialFilter = (
    t: TFunction
): Instance<typeof AgentTransactionSearchFilterOptions> => {
    const searchByOptions = getAgentTransactionSearchByOptions(t);

    return AgentTransactionSearchFilterOptions.create({
        searchBy: searchByOptions[0].value,
        searchText: '',
        transactionStatus: '',
        transactionType: ''
    });
};

const getAgentTransactionRequestSearchByValue = (
    value: AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy,
    t: TFunction
): string => {
    const values = AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy;
    switch (value) {
        case values.REQUEST_ID:
            return t('agencyBanking.requestId');
        case values.TRANSACTION_ID:
            return t('common.transactionId');
    }
};

export const getAgentTransactionRequestSearchByOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getAgentTransactionRequestSearchByValue(
            value as AgentTransactionRequestSearchBy.AgentTransactionRequestSearchBy,
            t
        );
        return { name: dropdownValue, value };
    });
};

export const getAgentTransactionRequestsHistoryInitialFilter = (
    t: TFunction
): Instance<typeof AgentTransactionRequestHistoryFilterOptions> => {
    const searchByOptions = getAgentTransactionRequestSearchByOptions(t);

    return AgentTransactionRequestHistoryFilterOptions.create({
        searchBy: searchByOptions[0].value,
        searchText: '',
        requestStatus: RequestStatus.PENDING
    });
};

const getSearchByValue = (value: UserSearchBy.UserSearchBy, t: TFunction): string | undefined => {
    const values = UserSearchBy.UserSearchBy;
    switch (value) {
        case values.MOBILE_NUMBER:
            return t('common.mobileNumber');
        case values.NAME:
            return t('common.name');
    }
};

export const getAgentSearchByOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(UserSearchBy.UserSearchBy);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value as UserSearchBy.UserSearchBy, t);
        return { name: dropdownValue ?? '', value };
    });
};

export const getAgentInitialFilter = (t: TFunction): Instance<typeof AgentFilterOptions> => {
    const searchByOptions = getAgentSearchByOptions(t);

    return AgentFilterOptions.create({
        searchBy: searchByOptions[0].value,
        searchText: '',
        selectedAgentStatus: ''
    });
};
