import {
    AuditLog,
    BitmapImageType,
    BOName,
    GetAuditLogsRPC,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    UserName
} from '@resolut-tech/bcn-rpcs';
import { LeoPhoneNumber, LeoRPCResult, LeoTimestamp } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const profilePicture = new MultiResolutionBitmapImage(
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    )
);

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];
const description = [
    'Provided From and To Wallet Details for request - 8b4edbb4-008c-4123-be95-8f7ebab056d0',
    'Provided amount details',
    'Approved Fund Transfer request - 48466b92-9696-4b46-a677-a8ed61340a01',
    'Viewed Fund Transfer request',
    'Viewed Fund Transfer request list'
];

const AuditLogList: AuditLog[] = [];

for (let i = 0; i < 250; i++) {
    AuditLogList.push(
        new AuditLog(
            new UserName(
                new BOName(firstName[getRandomInt(4)]),
                new BOName(lastName[getRandomInt(4)])
            ),
            new LeoPhoneNumber('+265995118354'),
            profilePicture,
            'GENERIC_FUNDS_TRANSFER',
            description[getRandomInt(4)],
            new LeoTimestamp('2022-11-30T02:03:59Z')
        )
    );
}

export class MockAuditLogsRPCImpl extends GetAuditLogsRPC {
    execute(
        _request: GetAuditLogsRPC.Request
    ): Promise<LeoRPCResult<GetAuditLogsRPC.Response, GetAuditLogsRPC.Errors.Errors>> {
        const response = new GetAuditLogsRPC.Response(AuditLogList.length, AuditLogList);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetAuditLogsRPC.Response, GetAuditLogsRPC.Errors.Errors>
        >;
    }
}
