import { Stack, Typography } from '@mui/material';
import { Section, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '../../../../../assets/icons/Edit';
import { Button } from '../../../../common/components/Button';

interface EditRuleFileSectionComponentProps {
    onEditButtonClick: () => void;
    subtitle: string;
}

export function EditRuleFileSectionComponent({
    onEditButtonClick,
    subtitle
}: EditRuleFileSectionComponentProps): React.ReactElement {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    return (
        <Section
            title={t('common.editRules')}
            allowViewChange={false}
            headerHeight="50px"
            content={[
                <Stack key="editRuleFileSectionComponent" sx={{ padding: '16px' }}>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {subtitle}
                    </Typography>

                    <Button
                        style={{ marginTop: '8px', width: '105px' }}
                        name="edit"
                        size="small"
                        icon={<Edit />}
                        iconPosition="left"
                        title={t('common.editRules')}
                        variant="filled"
                        onClick={onEditButtonClick}
                    />
                </Stack>
            ]}
        />
    );
}
