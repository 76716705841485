import { BCNAccountDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';

export const NonZeroAccountDetailsModel = types.model({
    id: types.string,
    name: types.string,
    balance: types.maybeNull(AmountModel)
});

export const getNonZeroAccountDetailsModel = (
    accountDetails: BCNAccountDetails
): Instance<typeof NonZeroAccountDetailsModel> => {
    return NonZeroAccountDetailsModel.create({
        id: accountDetails.id.uuid,
        name: accountDetails.name,
        balance: accountDetails.balance ? getAmountModel(accountDetails.balance) : null
    });
};
