import { Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewFeeRulesStore } from '../store/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import {
    FeeDeterminationRulesDiffStore,
    FeeDeterminationRulesDiffStoreErrors
} from '../store/FeeDeterminationRulesDiffStore';
import { ViewFeeDeterminationRulesDiff } from './ViewFeeDeterminationRulesDiff';

export const ViewFeeRulesChangesComponent = ({
    requestId,
    store
}: {
    requestId: string;
    store: Instance<typeof FeeDeterminationRulesDiffStore>;
}): React.ReactElement => {
    const { t } = useTranslation();
    const viewFeeRulesStore = useViewFeeRulesStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function fetchFeeDeterminationRuleDiff(): Promise<string | React.ReactElement[]> {
        await store.fetchFeeDeterminationRulesDiff(requestId).then(() => {
            return viewFeeRulesStore.fetchCountryAndCBSIdList();
        });
        if (store.error || viewFeeRulesStore.error) {
            switch (store.error) {
                case FeeDeterminationRulesDiffStoreErrors.OldRulesNotFound:
                case FeeDeterminationRulesDiffStoreErrors.InvalidRequestId:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        } else {
            return [
                <Stack key="feeDeterminationRuleDiffView">
                    <ViewFeeDeterminationRulesDiff
                        oldFeeDeterminationRules={store.oldFeeDeterminationRules}
                        updatedFeeDeterminationRules={store.updatedFeeDeterminationRules}
                    />
                </Stack>
            ];
        }
    }

    return (
        <>
            <Section
                title={t('common.viewChanges')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchFeeDeterminationRuleDiff}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </>
    );
};
