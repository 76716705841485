import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { observer } from 'mobx-react';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { useViewFeeRulesHistoryStore } from '../store/hooks';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { SingleDropdownFilter } from '../../common/components/SingleDropdownFilter';
import { useNavigate } from 'react-router-dom';
import { Instance } from 'mobx-state-tree';
import { RuleHistoryDetailsModel } from '../../common/models/RulesModel';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getStatusTypeFromRequestStatus } from '../../common/utils/StatusUIUtils';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { useLoggerStore } from '../../../../log/hooks';
import { ViewFeeRulesHistoryStoreErrors } from '../store/ViewFeeRulesHistoryStore';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const ViewFeeRulesHistory = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useViewFeeRulesHistoryStore();
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(t('feeRules.viewRulesHistory'), window.location.pathname);
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '160px'
            },
            {
                id: 'requestedBy',
                name: t('common.requestedBy'),
                width: '160px'
            },
            {
                id: 'evaluatedBy',
                name: t('common.evaluatedBy'),
                width: '182px'
            },
            {
                id: 'requestStatus',
                name: t('common.requestStatus'),
                width: '182px'
            },
            { id: 'action', name: '', width: '80px', align: 'right' } // The column is left blank for the arrow at the end of the row in the table
        ];
    };

    const getRulesHistoryTableRowItems = (
        ruleRequestDetails: Instance<typeof RuleHistoryDetailsModel>[]
    ): TableRowItems => {
        return ruleRequestDetails.map((requestDetail) => {
            return [
                {
                    data: requestDetail.requestedAt
                },
                {
                    data: requestDetail.requestedBy
                },
                { data: requestDetail.checkedBy ?? '-' },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getStatusTypeFromRequestStatus(requestDetail.requestStatus)}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const getData = async (
        option: TableOptions<string>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateRequestStatus(option.filter);
        await store.fetchFeeRuleHistoryRequests(
            option.page ? option.page - 1 : 0, // This is done since the first page is sent as page 0 to server
            option.filter,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case ViewFeeRulesHistoryStoreErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return getRulesHistoryTableRowItems(store.ruleHistoryRequests);
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('feeRules.viewRulesHistory')}
                subtitle={t('feeRules.viewRulesHistorySubtitle')}
            />
            <Table
                name="FeeRuleHistoryTable"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{ itemsPerPage: store.itemsPerPage() }}
                viewOverrides={{
                    empty: { message: t('common.noHistoryFound') },
                    loading: { message: t('common.searchingForRequests') }
                }}
                filterOption={{
                    initialFilterValue: store.requestStatus,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return <SingleDropdownFilter filter={filter} setFilter={setFilter} />;
                    }
                }}
                onRowClick={(_row, index): void => {
                    const selectedRequest = store.ruleHistoryRequests[index];
                    if (selectedRequest) {
                        navigate(
                            `/fee-rules/history/details?requestId=${selectedRequest.requestId}`
                        );
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected request in the list with length ${store.ruleHistoryRequests.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </Stack>
    );
});
