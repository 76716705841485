import { types, Instance } from 'mobx-state-tree';
import {
    createEvaluateTransactionRulesStore,
    EvaluateTransactionRulesStore
} from './EvaluateTransactionRulesStore';
import {
    createTransactionRuleRequestDetailsStore,
    TransactionRuleRequestDetailsStore
} from './TransactionRuleRequestDetailsStore';

import {
    createUpdateTransactionRulesStore,
    UpdateTransactionRulesStore
} from './UpdateTransactionRulesStore';
import {
    createViewTransactionRulesHistoryStore,
    ViewTransactionRulesHistoryStore
} from './ViewTransactionRulesHistoryStore';

import {
    createViewTransactionRulesStore,
    ViewTransactionRulesStore
} from './ViewTransactionRulesStore';

export const TransactionRulesStore = types.model({
    viewTransactionRulesHistoryStore: ViewTransactionRulesHistoryStore,
    evaluateTransactionRulesStore: EvaluateTransactionRulesStore,
    updateTransactionRulesStore: UpdateTransactionRulesStore,
    viewTransactionRulesStore: ViewTransactionRulesStore,
    transactionRuleRequestDetailsStore: TransactionRuleRequestDetailsStore
});

export const createTransactionRulesStore = (): Instance<typeof TransactionRulesStore> => {
    return TransactionRulesStore.create({
        viewTransactionRulesHistoryStore: createViewTransactionRulesHistoryStore(),
        evaluateTransactionRulesStore: createEvaluateTransactionRulesStore(),
        viewTransactionRulesStore: createViewTransactionRulesStore(),
        updateTransactionRulesStore: createUpdateTransactionRulesStore(),
        transactionRuleRequestDetailsStore: createTransactionRuleRequestDetailsStore()
    });
};
