export enum LeoErrors {
    InvalidLeoUUIDError = 'InvalidLeoUUIDError',
    InvalidCommentError = 'InvalidCommentError',
    InvalidPasswordError = 'InvalidPasswordError',
    InvalidLeoPhoneNumberError = 'InvalidLeoPhoneNumberError',
    InvalidOTPError = 'InvalidOtpError',
    InvalidLeoDateError = 'InvalidLeoDateError',
    InvalidDateRangeError = 'InvalidDateRangeError',
    InvalidAmountError = 'InvalidAmountError',
    InvalidSearchTextError = 'InvalidSearchTextError'
}
