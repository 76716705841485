import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../../common/enums/StatusEnum';
import { CheckRequestDetailsStore } from '../store/CheckRequestDetailsStore';
import { RefundDialog } from './RefundDialog';
import { useLoggerStore } from '../../../../log/hooks';

interface RefundRequestCheckerDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmitResponse: (comment: string) => void;
    checkRequestDetailsStore: Instance<typeof CheckRequestDetailsStore>;
}
export const RefundRequestCheckerDialog = ({
    isDialogOpen,
    onClose,
    onSubmitResponse,
    checkRequestDetailsStore
}: RefundRequestCheckerDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const loggerStore = useLoggerStore();

    const RefundRequestDialog = (): React.ReactElement => {
        if (checkRequestDetailsStore.baseAmount) {
            return (
                <RefundDialog
                    isMakerFlow={false}
                    title={
                        checkRequestDetailsStore.status === Status.APPROVED
                            ? t('transaction.approveRefundRequest')
                            : t('transaction.denyRefundRequest')
                    }
                    primaryButtonText={
                        checkRequestDetailsStore.status === Status.APPROVED
                            ? t('common.approveRequest')
                            : t('common.denyRequest')
                    }
                    onSubmit={onSubmitResponse}
                    baseAmount={checkRequestDetailsStore.baseAmount}
                    bankFee={checkRequestDetailsStore.bankFee}
                    totalAmount={checkRequestDetailsStore.totalAmount}
                    isDialogOpen={isDialogOpen}
                    onClose={onClose}
                    showWarningChip={false}
                    appliedAdditionalFee={checkRequestDetailsStore.additionalFee}
                />
            );
        } else {
            loggerStore.debug(
                'Could not find baseAmount in CheckRequestDetailsStore while creating RefundRequestDialog'
            );
            return <></>;
        }
    };
    return RefundRequestDialog();
};
