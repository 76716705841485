import { SignInBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../networking/MockExecuteHelper';

export class MockSignInBOUserRPCImpl extends SignInBOUserRPC {
    execute(
        _request: SignInBOUserRPC.Request
    ): Promise<LeoRPCResult<SignInBOUserRPC.Response, SignInBOUserRPC.Errors.Errors>> {
        const response = new SignInBOUserRPC.Response(new LeoUUID(), '3649');
        return mockExecute({ response }) as Promise<
            LeoRPCResult<SignInBOUserRPC.Response, SignInBOUserRPC.Errors.Errors>
        >;
    }
}
