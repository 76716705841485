import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    BitmapImageType,
    BOName,
    BOUserComment,
    Comment,
    GetAgentTransactionHistoryRPC,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RequestDetails,
    UserName
} from '@resolut-tech/bcn-rpcs';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetAgentTransactionHistoryRPCImpl extends GetAgentTransactionHistoryRPC {
    execute(
        _request: GetAgentTransactionHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetAgentTransactionHistoryRPC.Response,
            GetAgentTransactionHistoryRPC.Errors.Errors
        >
    > {
        const history = [
            new RequestDetails.Approved(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Rosie'), new BOName('Downey')),
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    ),
                    new LeoTimestamp('2022-09-30T02:03:59Z'),
                    new Comment('The amount must be sent back to the sender.')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    ),
                    new LeoTimestamp('2022-09-30T02:03:59Z'),
                    new Comment(
                        'The sender wants the amount back as there was no response from the recipient.'
                    )
                )
            ),
            new RequestDetails.Discarded(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    ),
                    new LeoTimestamp('2022-09-30T02:03:59Z'),
                    new Comment(
                        'The sender wants the amount back as there was no response from the recipient.'
                    )
                )
            )
        ];
        const response = new GetAgentTransactionHistoryRPC.Response(history);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetAgentTransactionHistoryRPC.Response,
                GetAgentTransactionHistoryRPC.Errors.Errors
            >
        >;
    }
}
