import { GetBCNUserDetailsRPC } from '@resolut-tech/bcn-rpcs';

import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useGetBCNUserDetailsRPCClient } from '../rpcs/RPC';
import {
    BCNBasicUserDetailsModel,
    getBCNBasicUserDetailsModel
} from '../models/BCNBasicUserDetailModel';
import {
    BCNUserAccountDetailsModel,
    getBCNUserAccountDetailsModel
} from '../models/BCNUserAccountDetailModel';
import {
    BCNUserUpdateRequestDetailsModel,
    getBCNUserUpdateRequestDetailsModel
} from '../models/BCNUserUpdateRequestDetailsModel';
import {
    getKYCDocumentHistoryDetailsModel,
    KYCDocumentHistoryDetailsModel
} from '../models/KYCDocumentHistoryDetailsModel';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserDetailsStoreErrors {
    InvalidBCNUserId = 'INVALID_BCN_USER_ID'
}

export const BCNUserDetailsStore = types
    .model({
        basicDetails: types.maybeNull(BCNBasicUserDetailsModel),
        accountDetails: types.maybeNull(types.array(BCNUserAccountDetailsModel)),
        kycDocumentHistoryDetails: types.maybeNull(types.array(KYCDocumentHistoryDetailsModel)),
        requestDetails: types.maybeNull(BCNUserUpdateRequestDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserDetailsStoreErrors>(
                    'BCNUserDetailsStoreErrors',
                    Object.values(BCNUserDetailsStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        fetchBCNUserDetails: flow(function* (userId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const id = new LeoUUID(userId);
                const request = new GetBCNUserDetailsRPC.Request(id);
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetBCNUserDetailsRPC.Response,
                    GetBCNUserDetailsRPC.Errors.Errors
                > = yield useGetBCNUserDetailsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.basicDetails = getBCNBasicUserDetailsModel(response.basicDetails);
                    const accountDetails = response.accountDetails.map((accountDetail) => {
                        return getBCNUserAccountDetailsModel(accountDetail);
                    });
                    store.accountDetails = cast(accountDetails);
                    const kycDetails = response.kycDocumentHistoryDetails.map((kycDetail) => {
                        return getKYCDocumentHistoryDetailsModel(kycDetail);
                    });
                    store.requestDetails = response.requestDetails
                        ? getBCNUserUpdateRequestDetailsModel(response.requestDetails)
                        : null;
                    store.kycDocumentHistoryDetails = cast(kycDetails);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BCNUserDetailsStoreErrors.InvalidBCNUserId:
                            store.error = BCNUserDetailsStoreErrors.InvalidBCNUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBCNUserDetailsRPC `
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetBCNUserDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BCNUserDetailsStoreErrors.InvalidBCNUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in fetchBCNUserDetails`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in fetchBCNUserDetails`);
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .actions(() => ({
        getProceedCountdownTimerValue: (): number => 5
    }));

export const createBCNUserDetailsStore = (): Instance<typeof BCNUserDetailsStore> => {
    return BCNUserDetailsStore.create();
};
