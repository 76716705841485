import { Box, Stack } from '@mui/material';
import { RequestStatus } from '@resolut-tech/bcn-rpcs';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CBSRequestDetailsModel } from '../models/CBSRequestDetailsModel';
import { StatusType } from './StatusComponent';
import { UserCommentComponent } from './UserCommentComponent';
import { WithdrawUserCommentComponent } from './WithdrawUserCommentComponent';

interface RequestDetailsSectionProps {
    requestDetails: Instance<typeof CBSRequestDetailsModel>;
    children?: React.ReactElement | null;
}
export const UnclaimedRequestDetailsSection = ({
    requestDetails,
    children
}: RequestDetailsSectionProps): React.ReactElement => {
    const { t } = useTranslation();
    const borderStyling = {
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px'
    };

    const RequestorUserComment = (): React.ReactElement => {
        let requestStatus: StatusType | null = null;
        if (requestDetails.status === RequestStatus.RequestStatus.PENDING) {
            requestStatus = StatusType.PENDING;
        } else if (requestDetails.status === RequestStatus.RequestStatus.DISCARDED) {
            requestStatus = StatusType.DISCARDED;
        } else {
            requestStatus = null;
        }
        return (
            <>
                {requestDetails.requesterComment && (
                    <UserCommentComponent
                        title={t('common.requestRaisedBy', {
                            name: requestDetails.requesterComment.name
                        })}
                        subtitle={requestDetails.requesterComment.requestedAt}
                        status={requestStatus}
                        profileImageUrl={requestDetails.requesterComment.profilePhoto}
                        comment={requestDetails.requesterComment.comment}
                        showDivider={
                            requestStatus === StatusType.PENDING ||
                            requestStatus === StatusType.DISCARDED
                        }>
                        {children}
                    </UserCommentComponent>
                )}
            </>
        );
    };

    const getApproveDenySection = (): React.ReactElement => {
        return (
            <>
                {requestDetails.evaluatorComment?.name && (
                    <UserCommentComponent
                        sx={borderStyling}
                        title={
                            requestDetails.status === RequestStatus.RequestStatus.APPROVED
                                ? t('common.requestApprovedBy', {
                                      name: requestDetails.evaluatorComment.name
                                  })
                                : t('common.requestDeniedBy', {
                                      name: requestDetails.evaluatorComment.name
                                  })
                        }
                        subtitle={requestDetails.evaluatorComment.requestedAt}
                        status={
                            requestDetails.status === RequestStatus.RequestStatus.APPROVED
                                ? StatusType.APPROVED
                                : StatusType.DENIED
                        }
                        profileImageUrl={requestDetails.evaluatorComment.profilePhoto ?? null}
                        comment={requestDetails.evaluatorComment.comment}
                        showDivider={true}>
                        <RequestorUserComment />
                        <Box height={'32px'} />
                    </UserCommentComponent>
                )}
            </>
        );
    };

    const getDiscardedSection = (): React.ReactElement => {
        if (requestDetails.evaluatorComment && requestDetails.requesterComment) {
            return (
                <UserCommentComponent
                    sx={borderStyling}
                    title={t('common.requestApprovedBy', {
                        name: requestDetails.evaluatorComment.name
                    })}
                    subtitle={requestDetails.evaluatorComment.requestedAt}
                    status={StatusType.DISCARDED}
                    profileImageUrl={requestDetails.evaluatorComment.profilePhoto}
                    comment={requestDetails.evaluatorComment.comment}
                    showDivider={true}>
                    <UserCommentComponent
                        title={t('common.requestRaisedBy', {
                            name: requestDetails.requesterComment.name
                        })}
                        subtitle={requestDetails.requesterComment.requestedAt}
                        status={null}
                        profileImageUrl={requestDetails.requesterComment.profilePhoto}
                        comment={requestDetails.requesterComment.comment}
                        showDivider={false}>
                        {children}
                    </UserCommentComponent>
                    <Box height={'32px'} />
                </UserCommentComponent>
            );
        } else {
            return (
                <Stack>
                    <RequestorUserComment />
                    <Box height={'32px'} />
                </Stack>
            );
        }
    };

    const getWithdrawSection = (): React.ReactElement => {
        return (
            <>
                {requestDetails.withdrawalComment && (
                    <WithdrawUserCommentComponent
                        sx={borderStyling}
                        withdrawalComment={requestDetails.withdrawalComment}
                        showDivider={true}>
                        {children}
                    </WithdrawUserCommentComponent>
                )}
            </>
        );
    };

    if (requestDetails.status === RequestStatus.RequestStatus.PENDING) {
        return (
            <Stack>
                <RequestorUserComment />
                <Box height={'32px'} />
            </Stack>
        );
    } else if (
        requestDetails.status === RequestStatus.RequestStatus.APPROVED ||
        requestDetails.status === RequestStatus.RequestStatus.DENIED
    ) {
        return getApproveDenySection();
    } else if (requestDetails.status === RequestStatus.RequestStatus.DISCARDED) {
        return getDiscardedSection();
    } else if (
        requestDetails.status === RequestStatus.RequestStatus.WITHDRAWN &&
        requestDetails.withdrawalComment
    ) {
        return getWithdrawSection();
    } else {
        return <></>;
    }
};
