import { Box, Stack, Typography } from '@mui/material';
import { Button, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../../../assets/icons/Filter';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { BORoleModel } from '../models/BORoleModel';
import { useBORoleStore } from '../store/hooks';
import { BOUserRolesSelector } from './BOUserRolesSelector';
import { Dialog } from '../../../common/components/Dialog';

interface FilterByRolesComponentProps {
    selectedBORoles: Instance<typeof BORoleModel>[];
    onBORolesChange: (userRoles: Instance<typeof BORoleModel>[]) => void;
}

export const FilterByRolesComponent = observer(
    ({ selectedBORoles, onBORolesChange }: FilterByRolesComponentProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const store = useBORoleStore();
        const [isLoading, setIsLoading] = useState(false);
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [_selectedBORoles, _setSelectedBORoles] =
            useState<Instance<typeof BORoleModel>[]>(selectedBORoles);

        const getUserRoles = async (): Promise<void> => {
            setIsLoading(true);
            await store.getBORoles();
            setIsLoading(false);
        };

        useEffect(() => {
            getUserRoles();
        }, []);

        useEffect(() => {
            _setSelectedBORoles(selectedBORoles);
        }, [selectedBORoles]);

        return (
            <>
                {store.error ? (
                    <ErrorDialog
                        isErrorDialogOpen={Boolean(store.error)}
                        onClose={(): void => {
                            store.removeError();
                        }}
                    />
                ) : (
                    <Dialog
                        open={isDialogOpen}
                        contentPadding="0px"
                        onClose={(): void => setIsDialogOpen(false)}
                        title={t('boUser.filterByRoles')}
                        primaryButtonText={t('boUser.selectRoles')}
                        onPrimaryButtonClick={async (): Promise<void> => {
                            onBORolesChange(_selectedBORoles);
                            setIsDialogOpen(false);
                        }}
                        isPrimaryButtonDisabled={
                            JSON.stringify(selectedBORoles) === JSON.stringify(_selectedBORoles)
                        }
                        secondaryButtonText={t('common.cancel')}
                        onSecondaryButtonClick={(): void => {
                            _setSelectedBORoles(selectedBORoles);
                            setIsDialogOpen(false);
                        }}>
                        <>
                            <Stack
                                direction="row"
                                padding="8px 12px"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    backgroundColor: palette.background[300],
                                    position: 'sticky',
                                    top: 0
                                }}>
                                <Typography
                                    sx={{ ...typography.small3, color: palette.label[200] }}>
                                    {t('boUser.rolesSelected', {
                                        selectedRolesCount: _selectedBORoles.length
                                    })}
                                </Typography>
                                <Button
                                    name="clearSelectionButton"
                                    variant="outlined-color"
                                    color="error"
                                    size="small"
                                    title={t('boUser.clearSelection')}
                                    onClick={(): void => {
                                        _setSelectedBORoles([]);
                                    }}
                                    isDisabled={!Boolean(_selectedBORoles.length)}
                                />
                            </Stack>
                            <Box
                                boxSizing="border-box"
                                // The max-height is set as 350px instead of 352px because the dividers top and bottom of the content take 2px.
                                maxHeight="350px"
                                overflow="auto"
                                padding="20px">
                                <BOUserRolesSelector
                                    workflowDependentRoles={store.workflowDependentRoles}
                                    selectedBORoles={_selectedBORoles}
                                    onBORolesChange={(updatedUserRoles): void => {
                                        _setSelectedBORoles(updatedUserRoles);
                                    }}
                                />
                            </Box>
                        </>
                    </Dialog>
                )}
                <Button
                    isLoading={isLoading}
                    name="filterByRolesButton"
                    variant="outlined-color"
                    size="medium"
                    title={`${t('boUser.filterByRoles')} ${
                        _selectedBORoles.length > 0 ? `(${_selectedBORoles.length})` : ''
                    }`}
                    onClick={(): void => {
                        setIsDialogOpen(true);
                    }}
                    style={{ width: _selectedBORoles.length > 0 ? '185px' : '153px' }}
                    icon={<Filter />}
                    iconPosition="left"
                    isDisabled={!Boolean(store.workflowDependentRoles.length)}
                />
            </>
        );
    }
);
