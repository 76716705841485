import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTypography, usePalette, Section } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { Upload } from '../../../../../assets/icons/Upload';
import { Download } from '../../../../../assets/icons/Download';
import { Button } from '../../../../common/components/Button';

interface UploadRuleSectionComponentProps {
    onUploadButtonClick: () => void;
    onDownloadButtonClick: () => void;
    isFileDownloading: boolean;
    subtitle: string;
}

export function UploadRuleSectionComponent({
    onUploadButtonClick,
    onDownloadButtonClick,
    isFileDownloading,
    subtitle
}: UploadRuleSectionComponentProps): React.ReactElement {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    return (
        <Section
            title={t('common.uploadFile')}
            allowViewChange={false}
            headerHeight="50px"
            content={[
                <Stack key="uploadRuleSectionComponent" sx={{ padding: '16px' }}>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {subtitle}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Button
                            style={{ marginTop: '8px' }}
                            name="upload"
                            size="small"
                            icon={<Upload />}
                            iconPosition="left"
                            title={t('common.uploadFile')}
                            variant="filled"
                            onClick={onUploadButtonClick}
                        />
                        <Button
                            isLoading={isFileDownloading}
                            style={{ marginTop: '8px', width: '131px' }} // TODO: Fix extra button padding. Ideally this should be 128px, it has been noticed that the current button is adding extra internal padding of 3px which should be fixed
                            name="download"
                            size="small"
                            icon={<Download />}
                            iconPosition="left"
                            title={t('common.downloadFile')}
                            variant="outlined-color"
                            onClick={onDownloadButtonClick}
                        />
                    </Stack>
                </Stack>
            ]}
        />
    );
}
