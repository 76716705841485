import { WithdrawCBSTransferForUnclaimedFundsRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockWithdrawCBSTransferForUnclaimedFundsRequestRPCImpl extends WithdrawCBSTransferForUnclaimedFundsRequestRPC {
    execute(
        _request: WithdrawCBSTransferForUnclaimedFundsRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            WithdrawCBSTransferForUnclaimedFundsRequestRPC.Response,
            WithdrawCBSTransferForUnclaimedFundsRequestRPC.Errors.Errors
        >
    > {
        const response = new WithdrawCBSTransferForUnclaimedFundsRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                WithdrawCBSTransferForUnclaimedFundsRequestRPC.Response,
                WithdrawCBSTransferForUnclaimedFundsRequestRPC.Errors.Errors
            >
        >;
    }
}
