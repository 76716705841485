import { TransactionValidationRule } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

export const TransactionValidationRuleModel = types.model({
    monetaryLimit: types.number,
    periodicLimit: types.number,
    timeWindowInSeconds: types.number,
    cbsId: types.string
});

export const getTransactionValidationRuleModel = (
    transactionValidationRule: TransactionValidationRule
): Instance<typeof TransactionValidationRuleModel> => {
    return TransactionValidationRuleModel.create({
        monetaryLimit: transactionValidationRule.monetaryLimit ?? 0,
        periodicLimit: transactionValidationRule.periodicLimit ?? 0,
        timeWindowInSeconds: transactionValidationRule.timeWindowInSeconds,
        cbsId: transactionValidationRule.cbsId
    });
};
