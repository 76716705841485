import {
    BOName,
    BOUserComment,
    GetAgentDetailsRequestHistoryRPC,
    RequestDetails,
    UserName,
    UserRequest,
    UserStatus,
    Comment,
    AgentRequest,
    Amount,
    Currency
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const agentRequests: AgentRequest[] = [
    new AgentRequest(
        null,
        new UserRequest(
            UserStatus.UserStatus.ACTIVE,
            UserStatus.UserStatus.DEACTIVATED,
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Jane'), new BOName('Cooper')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user is no longer using this profile.')
                )
            )
        )
    ),
    new AgentRequest(
        new Amount(2000000, new Currency('MWK')),
        new UserRequest(
            UserStatus.UserStatus.DEACTIVATED,
            UserStatus.UserStatus.ARCHIVED,
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Jane'), new BOName('Cooper')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user is no longer using this profile.')
                )
            )
        )
    ),
    new AgentRequest(
        null,
        new UserRequest(
            UserStatus.UserStatus.DEACTIVATED,
            UserStatus.UserStatus.ACTIVE,
            new RequestDetails.Approved(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Cooper')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to activate the account back.')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Jane'), new BOName('Cooper')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to activate the account back.')
                )
            )
        )
    ),
    new AgentRequest(
        null,
        new UserRequest(
            UserStatus.UserStatus.DEACTIVATED,
            UserStatus.UserStatus.ACTIVE,
            new RequestDetails.Denied(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Esther'), new BOName('Howard')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to deactivate the account temporarily.')
                ),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Jane'), new BOName('Warren')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to deactivate the account temporarily.')
                )
            )
        )
    ),
    new AgentRequest(
        null,
        new UserRequest(
            UserStatus.UserStatus.ACTIVE,
            UserStatus.UserStatus.DEACTIVATED,
            new RequestDetails.Withdrawn(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Brooklyn'), new BOName('Simmons')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to deactivate the account temporarily.')
                ),
                new LeoTimestamp(new Date().toISOString()),
                new Comment('The user has requested to deactivate the account temporarily.')
            )
        )
    ),
    new AgentRequest(
        null,
        new UserRequest(
            UserStatus.UserStatus.DEACTIVATED,
            UserStatus.UserStatus.ACTIVE,
            new RequestDetails.Discarded(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Cameron'), new BOName('Williamson')),
                    null,
                    new LeoTimestamp(new Date().toISOString()),
                    new Comment('The user has requested to activate the account temporarily.')
                )
            )
        )
    )
];

export class MockGetAgentDetailsRequestHistoryRPCImpl extends GetAgentDetailsRequestHistoryRPC {
    execute(
        _request: GetAgentDetailsRequestHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetAgentDetailsRequestHistoryRPC.Response,
            GetAgentDetailsRequestHistoryRPC.Errors.Errors
        >
    > {
        const response = new GetAgentDetailsRequestHistoryRPC.Response(agentRequests);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetAgentDetailsRequestHistoryRPC.Response,
                GetAgentDetailsRequestHistoryRPC.Errors.Errors
            >
        >;
    }
}
