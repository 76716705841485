import {
    CountryCBSId,
    CountryCode,
    GetCountryAndCBSIdListForTVRRPC,
    LocalizedText
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCountryAndCBSIdListForTVRRPCImpl extends GetCountryAndCBSIdListForTVRRPC {
    execute(
        _request: GetCountryAndCBSIdListForTVRRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCountryAndCBSIdListForTVRRPC.Response,
            GetCountryAndCBSIdListForTVRRPC.Errors.Errors
        >
    > {
        const cbsCountryId: CountryCBSId[] = [
            new CountryCBSId(
                new LocalizedText('Malawi'),
                new CountryCode('25'),
                new LeoUUID('0d400819-f3a0-4b24-8fac-4399f8d804e0')
            ),
            new CountryCBSId(
                new LocalizedText('India'),
                new CountryCode('91'),
                new LeoUUID('4944bd58-30f8-4c02-bf22-1ef52cdc7763')
            ),
            new CountryCBSId(
                new LocalizedText('Zambia'),
                new CountryCode('26'),
                new LeoUUID('4c41fab4-164b-44d8-9522-4e541d6949cf')
            )
        ];
        const response = new GetCountryAndCBSIdListForTVRRPC.Response(cbsCountryId);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCountryAndCBSIdListForTVRRPC.Response,
                GetCountryAndCBSIdListForTVRRPC.Errors.Errors
            >
        >;
    }
}
