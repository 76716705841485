import { Avatar } from '@mui/material';
import React from 'react';
import placeholderPhoto from '../../../../assets/placeholder-photo.svg';

interface UserAvatarProps {
    src?: string | null;
    size: 'extra-small' | 'small' | 'medium' | 'large';
}

export const UserAvatar = ({ src, size }: UserAvatarProps): React.ReactElement => {
    const getSize = (): string => {
        switch (size) {
            case 'extra-small':
                return '36px';
            case 'small':
                return '48px';
            case 'medium':
                return '64px';
            case 'large':
                return '80px';
        }
    };

    return (
        <Avatar
            sx={{
                width: getSize(),
                height: getSize(),
                '.MuiAvatar-fallback': {
                    background: `url(${placeholderPhoto}) center no-repeat`,
                    backgroundSize: '100% 100%',
                    width: '100%',
                    height: '100%',
                    path: {
                        display: 'none'
                    }
                }
            }}
            src={src && src.trim().length > 0 ? src : placeholderPhoto}
        />
    );
};
