import { Instance } from 'mobx-state-tree';
import { AuthCodeEntryStore } from './AuthCodeEntryStore';
import { MobileNumberEntryStore } from './MobileNumberEntryStore';
import { NewPasswordEntryStore } from './NewPasswordEntryStore';
import { SignInStore } from './SignInStore';
import { useRootStore } from '../../store/root/store/RootStore';
import { OTPEntryStore } from './OTPEntryStore';

// To avoid cyclic dependency caused by importing stores, store hooks have been added to a different file

export const useSignInStore = (): Instance<typeof SignInStore> => {
    return useRootStore().signInStore;
};

export const useMobileNumberEntryStore = (): Instance<typeof MobileNumberEntryStore> => {
    return useRootStore().signInStore.mobileNumberEntry;
};

export const useAuthCodeEntryStore = (): Instance<typeof AuthCodeEntryStore> => {
    return useRootStore().signInStore.authCodeEntry;
};

export const useOTPEntryStore = (): Instance<typeof OTPEntryStore> => {
    return useRootStore().signInStore.otpEntry;
};

export const useNewPasswordEntryStore = (): Instance<typeof NewPasswordEntryStore> => {
    return useRootStore().signInStore.newPasswordEntryStore;
};
