import { Box, Stack } from '@mui/material';
import { Card, LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { observer } from 'mobx-react';
import { LoadingState } from '../../common/components/LoadingState';
import { DownloadFileErrorDialog } from '../../common/components/DownloadFileError';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { useSearchParams } from 'react-router-dom';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { ViewTransactionRulesChangesComponent } from '../components/ViewTransactionRulesChangesComponent';
import { useUserStore } from '../../store/hooks';
import { PendingRuleRequestDetailsSectionComponent } from '../../common/components/rules/PendingRuleRequestDetailsSectionComponent';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { Status } from '../../common/enums/StatusEnum';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { UserPrivileges } from '../../user/UserPrivileges';
import { EvaluateTransactionRulesErrors } from '../store/EvaluateTransactionRulesStore';
import {
    useEvaluateTransactionRulesStore,
    useTransactionRuleRequestDetailsStore,
    useUpdateTransactionRulesStore
} from '../store/hooks';
import { FileDownloadActionButtons } from '../../common/components/rules/FileDownloadActionButtons';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { UpdateTransactionRulesErrors } from '../store/UpdateTransactionRulesStore';
import { ErrorState } from '../../common/components/ErrorState';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { TransactionRuleRequestDetailsStoreErrors } from '../store/TransactionRuleRequestDetailsStore';

export const TransactionRuleHistoryRequestDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const transactionRuleRequestDetailsStore = useTransactionRuleRequestDetailsStore();
    const evaluateTransactionRulesStore = useEvaluateTransactionRulesStore();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get('requestId');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [isDownloadFileErrorDialogOpen, setIsDownloadFileErrorDialogOpen] = useState(false);
    const [fileType, setFileType] = useState<'old' | 'new'>();
    const userStore = useUserStore();
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState<boolean>(false);
    const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState<boolean>(false);
    const updateTransactionRulesStore = useUpdateTransactionRulesStore();
    const loggerStore = useLoggerStore();

    const fetchTransactionRuleRequestDetails = async (): Promise<void> => {
        if (requestId) {
            setIsLoadingData(true);
            await transactionRuleRequestDetailsStore.fetchTransactionRuleDetails(requestId);
            setIsLoadingData(false);
            if (
                transactionRuleRequestDetailsStore.error ===
                TransactionRuleRequestDetailsStoreErrors.InvalidRequestId
            ) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            loggerStore.debug(
                'requestId cannot be null while fetching transaction rule request details'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
    };

    useEffect(() => {
        fetchTransactionRuleRequestDetails();
    }, []);

    const showViewFeeRulesChanges = Boolean(
        transactionRuleRequestDetailsStore.oldRuleFile &&
            transactionRuleRequestDetailsStore.newRuleFile
    );

    const showWithdrawActionButton = (): boolean => {
        return (
            userStore.userId ===
            transactionRuleRequestDetailsStore.requestDetails?.requesterComment?.userId
        );
    };

    const showApproveDenyActionButton = (): boolean => {
        return userStore.privileges.includes(UserPrivileges.CheckTransactionRuleRequest);
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const onButtonClick = (status: Status): void => {
        evaluateTransactionRulesStore.setStatus(status);
        setIsRequestCheckDialogOpen(true);
    };

    const getRequestDialogTitle = (): string => {
        if (evaluateTransactionRulesStore.status === Status.APPROVED) {
            return t('common.approveRequest');
        } else {
            return t('common.denyRequest');
        }
    };

    const onClickApproveOrDenyUpdate = async (comment: string): Promise<void> => {
        evaluateTransactionRulesStore.setComment(comment);
        setIsRequestCheckDialogOpen(false);
        setIsScreenBlockingLoading(true);
        const _requestId = transactionRuleRequestDetailsStore.requestId;
        if (_requestId) {
            await evaluateTransactionRulesStore.evaluatePendingRequest(_requestId);
        } else {
            loggerStore.debug(
                'Cannot find requestId in transactionRuleRequestDetailsStore while checking the request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        if (evaluateTransactionRulesStore.error) {
            setErrorDialogTitle(
                evaluateTransactionRulesStore.status === Status.APPROVED
                    ? t('common.approveRequest')
                    : t('common.denyRequest')
            );
            switch (evaluateTransactionRulesStore.error) {
                case EvaluateTransactionRulesErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case EvaluateTransactionRulesErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrong'));
                    break;
                case EvaluateTransactionRulesErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case EvaluateTransactionRulesErrors.RequestDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case EvaluateTransactionRulesErrors.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    break;
                case EvaluateTransactionRulesErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request is success, we will fetch the transaction rule request details again.
            fetchTransactionRuleRequestDetails();
        }
        evaluateTransactionRulesStore.resetComment();
        setIsScreenBlockingLoading(false);
    };

    const RequestCheckDialogBox = (): React.ReactElement => {
        return (
            <RequestDialog
                isDialogOpen={isRequestCheckDialogOpen}
                title={getRequestDialogTitle()}
                buttonTitle={
                    evaluateTransactionRulesStore.status === Status.APPROVED
                        ? t('common.approveRequest')
                        : t('common.denyRequest')
                }
                onSubmit={async (comment: string): Promise<void> => {
                    await onClickApproveOrDenyUpdate(comment);
                }}
                onClose={(): void => {
                    setIsRequestCheckDialogOpen(false);
                    evaluateTransactionRulesStore.resetComment();
                }}
            />
        );
    };

    const onDownloadButtonClick = async (
        documentId: string,
        fileName: RulesDownloadFileNameEnum
    ): Promise<void> => {
        setIsFileDownloading(true);
        await updateTransactionRulesStore.downloadFileStore.downloadFile(documentId, fileName);
        setIsFileDownloading(false);
        if (updateTransactionRulesStore.downloadFileStore.error) {
            setIsDownloadFileErrorDialogOpen(true);
        }
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    setErrorDialogTitle(null);
                    evaluateTransactionRulesStore.resetStore();
                    updateTransactionRulesStore.resetStore();
                }}
            />
        );
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setIsScreenBlockingLoading(true);
        updateTransactionRulesStore.setComment(comment);
        const _requestId = transactionRuleRequestDetailsStore.requestId;
        if (_requestId) {
            await updateTransactionRulesStore.withdrawTransactionRuleRequest(_requestId);
        } else {
            loggerStore.debug(
                'Cannot find requestId in transactionRuleRequestDetailsStore while withdrawing the request'
            );
            setErrorDialogMessage(t('common.somethingWentWrong'));
        }
        if (updateTransactionRulesStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (updateTransactionRulesStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case UpdateTransactionRulesErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been withdrawn, we will fetch the transaction rule request details again.
            fetchTransactionRuleRequestDetails();
        }
        setIsScreenBlockingLoading(false);
        updateTransactionRulesStore.resetError();
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    return (
        <Stack>
            <LoadingIndicator isLoading={isScreenBlockingLoading} color="white" />
            <DownloadFileErrorDialog
                isErrorDialogOpen={isDownloadFileErrorDialogOpen}
                onClose={(): void => setIsDownloadFileErrorDialogOpen(false)}
                storeError={transactionRuleRequestDetailsStore.downloadFileStore.error}
            />
            <PageHeader
                title={t('common.requestDetails')}
                subtitle={t('common.requestDetailsSubtitle')}
            />
            <BreadcrumbComponent currentLabel={t('common.requestDetails')} />
            {transactionRuleRequestDetailsStore.error ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : isLoadingData ? (
                <LoadingState />
            ) : (
                <Stack margin="32px" spacing="32px" direction="column">
                    {transactionRuleRequestDetailsStore.requestDetails &&
                        transactionRuleRequestDetailsStore.requestDetails.status ===
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING &&
                        transactionRuleRequestDetailsStore.requestDetails.requesterComment &&
                        transactionRuleRequestDetailsStore.newRuleFile && (
                            <PendingRuleRequestDetailsSectionComponent
                                isLoading={isFileDownloading}
                                boUserComment={
                                    transactionRuleRequestDetailsStore.requestDetails
                                        .requesterComment
                                }
                                newRuleFile={transactionRuleRequestDetailsStore.newRuleFile}
                                currentRuleFile={transactionRuleRequestDetailsStore.oldRuleFile}
                                newRuleFileName={RulesDownloadFileNameEnum.NewTransactionRulesFile}
                                currentRuleFileName={
                                    RulesDownloadFileNameEnum.CurrentTransactionRulesFile
                                }
                                onDownloadClick={(documentId, fileName): Promise<void> =>
                                    onDownloadButtonClick(documentId, fileName)
                                }
                                showWithdrawActionButton={showWithdrawActionButton()}
                                onWithdrawClick={onWithdrawClick}
                                onDenyClick={(): void => {
                                    onButtonClick(Status.DENIED);
                                }}
                                onApproveClick={(): void => {
                                    onButtonClick(Status.APPROVED);
                                }}
                                showApproveDenyActionButton={showApproveDenyActionButton()}
                            />
                        )}
                    {transactionRuleRequestDetailsStore.requestDetails &&
                        transactionRuleRequestDetailsStore.requestDetails.status !==
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING && (
                            <Card style={{ minWidth: '400px' }}>
                                <RequestDetailsSection
                                    requestDetails={
                                        transactionRuleRequestDetailsStore.requestDetails
                                    }>
                                    <FileDownloadActionButtons
                                        onDownloadButtonClick={(
                                            documentId,
                                            fileName
                                        ): Promise<void> =>
                                            onDownloadButtonClick(documentId, fileName)
                                        }
                                        isLoading={isFileDownloading}
                                        fileType={fileType}
                                        setFileType={setFileType}
                                        oldRuleFile={transactionRuleRequestDetailsStore.oldRuleFile}
                                        newRuleFile={transactionRuleRequestDetailsStore.newRuleFile}
                                        oldRuleFileName={
                                            RulesDownloadFileNameEnum.OldTransactionRulesFile
                                        }
                                        newRuleFileName={
                                            RulesDownloadFileNameEnum.NewTransactionRulesFile
                                        }
                                    />
                                </RequestDetailsSection>
                            </Card>
                        )}
                    {showViewFeeRulesChanges && requestId && (
                        <Box margin="32px">
                            <ViewTransactionRulesChangesComponent
                                store={
                                    transactionRuleRequestDetailsStore.transactionValidationRulesDiffStore
                                }
                                requestId={requestId}
                            />
                        </Box>
                    )}
                </Stack>
            )}
            {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
            {errorDialogMessage && ErrorDialogBox()}
            {isRequestCheckDialogOpen && RequestCheckDialogBox()}
        </Stack>
    );
});
