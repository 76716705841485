import { ConfirmAuthCodeRPC } from '@resolut-tech/bcn-rpcs';

import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../networking/MockExecuteHelper';

export class MockConfirmAuthCodeRPCImpl extends ConfirmAuthCodeRPC {
    execute(
        _request: ConfirmAuthCodeRPC.Request
    ): Promise<LeoRPCResult<ConfirmAuthCodeRPC.Response, ConfirmAuthCodeRPC.Errors.Errors>> {
        const response = new ConfirmAuthCodeRPC.Response(
            new LeoUUID(),
            new LeoTimestamp('2022-09-30T02:03:59Z'),
            new LeoTimestamp('2022-09-30T02:03:59Z')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<ConfirmAuthCodeRPC.Response, ConfirmAuthCodeRPC.Errors.Errors>
        >;
    }
}
