import { Stack, SxProps, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../assets/icons/Download';
import { RequestStatus } from '../../common/enums/RequestStatus';
import { AmountModel } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { TransactionSummaryRow } from './TransactionSummaryRow';
import { Button } from '../../../common/components/Button';

interface TransactionSummaryProps {
    onDownloadClick: () => void;
    totalAmount: Instance<typeof AmountModel>;
    status: string;
    totalNoOfTransaction: number;
    sx?: SxProps;
}

export const TransactionSummary = ({
    onDownloadClick,
    totalAmount,
    totalNoOfTransaction,
    status,
    sx
}: TransactionSummaryProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();

    return (
        <Stack sx={{ width: '342px', ...sx }}>
            <Typography
                sx={{ ...typography.small2, color: palette.label[300], paddingBottom: '8px' }}>
                {t('unclaimedFunds.summaryOfAmountTransferringToCBSAccount')}
            </Typography>
            <TransactionSummaryRow
                label={t('common.noOfTransactions')}
                text={totalNoOfTransaction?.toString()}
            />
            <TransactionSummaryRow
                label={t('common.totalAmount')}
                text={getAmountString(totalAmount)}
            />
            {(status === RequestStatus.APPROVED || status === RequestStatus.PENDING) && (
                <Button
                    style={{ width: '177px', marginTop: '16px' }}
                    name="download"
                    size="small"
                    icon={<Download />}
                    iconPosition="left"
                    title={t('common.downloadRequestsZip')}
                    variant="outlined-color"
                    onClick={onDownloadClick}
                />
            )}
        </Stack>
    );
};
