import { Box, Divider, Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CommissionBalanceSummaryProps {
    commissionBalance: string;
}

export const CommissionBalanceSummary = ({
    commissionBalance
}: CommissionBalanceSummaryProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();

    return (
        <Stack sx={{ paddingBottom: '16px', width: '342px' }}>
            <Typography
                sx={{ ...typography.small2, color: palette.label[300], paddingBottom: '8px' }}>
                {t('agencyBanking.commissionBalanceSummary')}
            </Typography>
            <Stack>
                <Stack direction="row" sx={{ padding: '12px 0px' }}>
                    <Box sx={{ width: '120px', height: '24px', paddingRight: '16px' }}>
                        <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                            {t('agencyBanking.commission')}
                        </Typography>
                    </Box>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {commissionBalance}
                    </Typography>
                </Stack>
                <Divider />
            </Stack>
        </Stack>
    );
};
