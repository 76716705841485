import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import noFile from '../../../../assets/no-file.svg';
import { PageHeader } from '../../common/components/PageHeader';
import { FileDownloadComponent } from '../../common/components/FileDownloadComponent';
import { EmptyStateComponent } from '../../common/components/EmptyStateComponent';
import { observer } from 'mobx-react';
import { LoadingState } from '../../common/components/LoadingState';
import { useViewTransactionRulesStore } from '../store/hooks';
import { DownloadFileErrorDialog } from '../../common/components/DownloadFileError';
import { ViewCurrentTransactionRulesComponent } from '../components/ViewCurrentTransactionRulesComponent';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const ViewTransactionRules = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const store = useViewTransactionRulesStore();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [isScreenLoading, setIsScreenLoading] = useState<boolean>(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
    const [showInternalErrorDialog, setShowInternalErrorDialog] = useState<boolean>(false);

    const fetchCurrentTransactionDetailsFile = async (): Promise<void> => {
        setIsLoading(true);
        await store.fetchCurrentTransactionValidationRuleDetailsFile();
        setIsLoading(false);
        if (store.error === NetworkingError.InternalError) {
            setShowInternalErrorDialog(true);
        }
    };

    useEffect(() => {
        fetchCurrentTransactionDetailsFile();
    }, []);

    const onDownloadButtonClick = async (): Promise<void> => {
        setIsScreenLoading(true);
        await store.downloadFileStore.downloadFile(
            store.currentFileDocumentId,
            RulesDownloadFileNameEnum.CurrentTransactionRulesFile
        );
        setIsScreenLoading(false);
        if (store.downloadFileStore.error) {
            setIsErrorDialogOpen(true);
        }
    };

    return (
        <Stack>
            <DownloadFileErrorDialog
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => setIsErrorDialogOpen(false)}
                storeError={store.downloadFileStore.error}
            />
            <PageHeader
                title={t('transactionRules.viewRules')}
                subtitle={t('transactionRules.viewRulesSubtitle')}
            />
            {isLoading ? (
                <LoadingState />
            ) : (
                <>
                    {store.currentFileDocumentId && store.currentFileDocumentId.length > 0 ? (
                        <Stack padding="32px" spacing="32px">
                            <FileDownloadComponent
                                isLoading={isScreenLoading}
                                variant="current"
                                onDownloadButtonClick={onDownloadButtonClick}
                                buttonVariant="filled"
                            />
                            <ViewCurrentTransactionRulesComponent />
                        </Stack>
                    ) : (
                        <EmptyStateComponent
                            text={t('common.noCurrentRulesFound')}
                            image={noFile}
                        />
                    )}
                </>
            )}
            <ErrorDialog
                isErrorDialogOpen={showInternalErrorDialog}
                onClose={(): void => {
                    setShowInternalErrorDialog(false);
                    store.removeError();
                }}
            />
        </Stack>
    );
});
