import styled from '@emotion/styled';
import { Typography, Grid, Box } from '@mui/material';
import { Button, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipGroup } from '../../common/components/ChipGroup';
import { SectionCellContainer } from '../../common/components/SectionRowComponent';
import { UserRoleChipsCell } from '../../common/types/SectionRowComponentTypes';
import { RoleDiffComponent } from './RoleDiffComponent';
import { Dialog } from '../../../common/components/Dialog';

interface UserRoleChipsCellComponentProps {
    data: UserRoleChipsCell;
}

export const UserRoleChipsCellComponent = ({
    data
}: UserRoleChipsCellComponentProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const HeaderTypography = styled(Typography)({
        ...typography.small3,
        color: palette.label[200]
    });

    const GridItem = styled(Grid)({
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${palette.outline[200]}`
    });

    if (data.updatedRoles && data.updatedRoles.length) {
        return (
            <SectionCellContainer flexDirection="column" gap={1} sx={{ paddingY: '8px' }}>
                <Dialog
                    open={isDialogOpen}
                    onClose={(): void => setIsDialogOpen(false)}
                    title={t('boUser.viewRolesChanged')}
                    secondaryButtonText={t('common.close')}
                    onSecondaryButtonClick={(): void => setIsDialogOpen(false)}>
                    <Grid container flexGrow={1}>
                        <GridItem item xs={6}>
                            <HeaderTypography>{t('boUser.currentDetails')}</HeaderTypography>
                        </GridItem>
                        <GridItem item xs={6}>
                            <HeaderTypography>{t('boUser.updatedDetails')}</HeaderTypography>
                        </GridItem>
                        <>
                            {data.updatedRoles.map((role, index) => (
                                <RoleDiffComponent key={index} role={role} />
                            ))}
                        </>
                    </Grid>
                </Dialog>
                <Typography sx={{ ...typography.small3, color: palette.warning[300] }}>
                    {t('boUser.userRolesChanged')}
                </Typography>
                <Button
                    name="viewRolesChangedButton"
                    variant="outlined-color"
                    size="small"
                    title={t('boUser.viewRolesChanged')}
                    onClick={(): void => {
                        setIsDialogOpen(true);
                    }}
                />
            </SectionCellContainer>
        );
    }
    return (
        <SectionCellContainer sx={{ paddingY: '8px' }}>
            <Box display="flex" flexWrap="wrap" gap="8px">
                <ChipGroup labels={data.roles} />
            </Box>
        </SectionCellContainer>
    );
};
