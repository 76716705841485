import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    RequestBOUserForceResetPasswordRPC,
    Comment,
    RequestBOUserRegenerateAuthCodesRPC,
    RequestBOUserArchiveRPC,
    RequestToReactivateOrDeactivateBOUserRPC,
    UserStatusTransition
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    useRequestBoUseArchiveRPCClient,
    useRequestBOUserRegenerateAuthCodesRPCClient,
    useRequestRequestBOUserForceResetPasswordRPCClient,
    useRequestToReactivateOrDeactivateBOUserRPCClient
} from '../rpcs/RPC';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BOProfileManagementErrors {
    InvalidBoUserId = 'INVALID_BO_USER_ID',
    UserAlreadyArchived = 'USER_ALREADY_ARCHIVED',
    UserAlreadyInactive = 'USER_ALREADY_INACTIVE',
    UserAlreadyActive = 'USER_ALREADY_ACTIVE',
    UserHasPendingRequests = 'USER_HAS_ACTIVE_PENDING_REQUESTS',
    InternalError = 'INTERNAL_ERROR',
    UserAlreadyDeactivated = 'USER_ALREADY_DEACTIVATED',
    InvalidComment = 'INVALID_COMMENT',
    CannotDeactivateSelfProfile = 'CANNOT_DEACTIVATE_SELF_PROFILE',
    CannotArchiveActiveBoUser = 'CANNOT_ARCHIVE_ACTIVE_BO_USER',
    CannotArchiveSelfProfile = 'CANNOT_ARCHIVE_SELF_PROFILE',
    CannotChangeSelfPassword = 'CANNOT_CHANGE_SELF_PASSWORD',
    CannotRequestSelfAuthCodes = 'CANNOT_REQUEST_SELF_AUTH_CODES'
}

const BOUserPendingRequests = types.model({ name: types.string, count: types.number });

export const BOUserProfileManagementStore = types
    .model({
        pendingRequests: types.maybeNull(types.array(BOUserPendingRequests)),
        error: types.maybeNull(
            types.union(
                types.enumeration<BOProfileManagementErrors>(
                    'BOProfileManagementErrors',
                    Object.values(BOProfileManagementErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        requestForceResetPassword: flow(function* (userId: string, comment: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestBOUserForceResetPasswordRPC.Request(
                    new LeoUUID(userId),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestBOUserForceResetPasswordRPC.Response,
                    RequestBOUserForceResetPasswordRPC.Errors.Errors
                > = yield useRequestRequestBOUserForceResetPasswordRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOProfileManagementErrors.InvalidBoUserId:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        case BOProfileManagementErrors.UserAlreadyInactive:
                            store.error = BOProfileManagementErrors.UserAlreadyInactive;
                            break;
                        case BOProfileManagementErrors.UserAlreadyArchived:
                            store.error = BOProfileManagementErrors.UserAlreadyArchived;
                            break;
                        case BOProfileManagementErrors.CannotChangeSelfPassword:
                            store.error = BOProfileManagementErrors.CannotChangeSelfPassword;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestBOUserForceResetPasswordRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in  RequestBOUserForceResetPasswordRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (_error) {
                if (_error instanceof Error) {
                    switch (_error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BOProfileManagementErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${_error} occurred in RequestBOUserForceResetPasswordRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${_error} occurred in RequestBOUserForceResetPasswordRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        requestRegenerateAuthCodes: flow(function* (userId: string, comment: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestBOUserRegenerateAuthCodesRPC.Request(
                    new LeoUUID(userId),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestBOUserRegenerateAuthCodesRPC.Response,
                    RequestBOUserRegenerateAuthCodesRPC.Errors.Errors
                > = yield useRequestBOUserRegenerateAuthCodesRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOProfileManagementErrors.InvalidBoUserId:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        case BOProfileManagementErrors.UserAlreadyInactive:
                            store.error = BOProfileManagementErrors.UserAlreadyInactive;
                            break;
                        case BOProfileManagementErrors.UserAlreadyArchived:
                            store.error = BOProfileManagementErrors.UserAlreadyArchived;
                            break;
                        case BOProfileManagementErrors.CannotRequestSelfAuthCodes:
                            store.error = BOProfileManagementErrors.CannotRequestSelfAuthCodes;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestBOUserRegenerateAuthCodesRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestBOUserRegenerateAuthCodesRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (_error) {
                if (_error instanceof Error) {
                    switch (_error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BOProfileManagementErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${_error} occurred in RequestBOUserRegenerateAuthCodesRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${_error} occurred in RequestBOUserRegenerateAuthCodesRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        requestToReactivateOrDeactivateBOUser: flow(function* (
            userId: string,
            comment: string,
            userStatusTransition: UserStatusTransition.UserStatusTransition
        ) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestToReactivateOrDeactivateBOUserRPC.Request(
                    new LeoUUID(userId),
                    new Comment(comment),
                    userStatusTransition
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToReactivateOrDeactivateBOUserRPC.Response,
                    RequestToReactivateOrDeactivateBOUserRPC.Errors.Errors
                > = yield useRequestToReactivateOrDeactivateBOUserRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOProfileManagementErrors.InvalidBoUserId:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        case BOProfileManagementErrors.UserAlreadyArchived:
                            store.error = BOProfileManagementErrors.UserAlreadyArchived;
                            break;
                        case BOProfileManagementErrors.UserAlreadyActive:
                            store.error = BOProfileManagementErrors.UserAlreadyActive;
                            break;
                        case BOProfileManagementErrors.UserAlreadyDeactivated:
                            store.error = BOProfileManagementErrors.UserAlreadyDeactivated;
                            break;
                        case BOProfileManagementErrors.CannotDeactivateSelfProfile:
                            store.error = BOProfileManagementErrors.CannotDeactivateSelfProfile;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToReactivateOrDeactivateBOUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in  RequestToReactivateOrDeactivateBOUserRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (_error) {
                if (_error instanceof Error) {
                    switch (_error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BOProfileManagementErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${_error} occurred in RequestToReactivateOrDeactivateBOUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${_error} occurred in RequestToReactivateOrDeactivateBOUserRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        requestArchiveUser: flow(function* (userId: string, comment: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                const request = new RequestBOUserArchiveRPC.Request(
                    new LeoUUID(userId),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestBOUserArchiveRPC.Response,
                    RequestBOUserArchiveRPC.Errors.Errors
                > = yield useRequestBoUseArchiveRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOProfileManagementErrors.InvalidBoUserId:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        case BOProfileManagementErrors.UserHasPendingRequests:
                            if (
                                error instanceof
                                RequestBOUserArchiveRPC.Errors.UserHasActivePendingRequests
                            ) {
                                store.pendingRequests = cast([]);
                                error.pendingRequests.map((_request) => {
                                    store.pendingRequests?.push({
                                        name: getTranslatedString(_request.requestName),
                                        count: _request.count
                                    });
                                });
                            }
                            store.error = BOProfileManagementErrors.UserHasPendingRequests;
                            break;
                        case BOProfileManagementErrors.UserAlreadyArchived:
                            store.error = BOProfileManagementErrors.UserAlreadyArchived;
                            break;
                        case BOProfileManagementErrors.CannotArchiveActiveBoUser:
                            store.error = BOProfileManagementErrors.CannotArchiveActiveBoUser;
                            break;
                        case BOProfileManagementErrors.CannotArchiveSelfProfile:
                            store.error = BOProfileManagementErrors.CannotArchiveSelfProfile;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestBOUserArchiveRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in  RequestBOUserArchiveRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (_error) {
                store.error = BOProfileManagementErrors.InternalError;
                if (_error instanceof Error) {
                    switch (_error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BOProfileManagementErrors.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOProfileManagementErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${_error} occurred in RequestBOUserArchiveRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${_error} occurred in RequestBOUserArchiveRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        resetError(): void {
            store.error = null;
            store.pendingRequests = null;
        }
    }));

export const createBOUserProfileManagementStore = (): Instance<
    typeof BOUserProfileManagementStore
> => {
    return BOUserProfileManagementStore.create();
};
