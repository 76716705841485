import {
    RequestStatus,
    PGUserName,
    GetBOUserChangeRequestsPaginationResponse,
    BOUserChangeRequestType,
    GetBOUserChangeRequestsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const requestList: GetBOUserChangeRequestsPaginationResponse[] = [
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        new LeoUUID('fcef2f0e-71a7-4f20-8b38-8576c8ed2d48'),
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade', 'Warren'),
        new PGUserName('Wade', 'Warren'),
        null,
        RequestStatus.RequestStatus.PENDING,
        BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER
    ),
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        null,
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        null,
        RequestStatus.RequestStatus.PENDING,
        BOUserChangeRequestType.BOUserChangeRequestType.NEW_USER
    ),
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        new LeoUUID('fcef2f0e-71a7-4f20-8b38-8576c8ed2d48'),
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        RequestStatus.RequestStatus.APPROVED,
        BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER
    ),
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        new LeoUUID('fcef2f0e-71a7-4f20-8b38-8576c8ed2d48'),
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        RequestStatus.RequestStatus.DENIED,
        BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER
    ),
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        new LeoUUID('fcef2f0e-71a7-4f20-8b38-8576c8ed2d48'),
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        null,
        RequestStatus.RequestStatus.WITHDRAWN,
        BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER
    ),
    new GetBOUserChangeRequestsPaginationResponse(
        new LeoUUID(),
        new LeoUUID('fcef2f0e-71a7-4f20-8b38-8576c8ed2d48'),
        new LeoTimestamp('2022-09-30T02:03:59Z'),
        new PGUserName('Wade Warren'),
        new PGUserName('Wade Warren'),
        null,
        RequestStatus.RequestStatus.DISCARDED,
        BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER
    )
];

export class MockGetBOUserChangeRequestsRPCImpl extends GetBOUserChangeRequestsRPC {
    execute(
        request: GetBOUserChangeRequestsRPC.Request
    ): Promise<
        LeoRPCResult<GetBOUserChangeRequestsRPC.Response, GetBOUserChangeRequestsRPC.Errors.Errors>
    > {
        let list = requestList;
        if (request.requestStatus) {
            list = list.filter((obj) => obj.requestStatus === request.requestStatus);
        }
        if (request.requestType) {
            list = list.filter((obj) => obj.requestType === request.requestType);
        }
        const response = new GetBOUserChangeRequestsRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOUserChangeRequestsRPC.Response,
                GetBOUserChangeRequestsRPC.Errors.Errors
            >
        >;
    }
}
