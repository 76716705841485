import { TransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';
import { Section } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { TransactionDetailView } from '../store/TransactionDetailStore';

export interface TransactionDetailsSectionProp {
    transactionDetails: TransactionDetailView[];
}

const transformCell = (view: TransactionDetailView): SectionRowCellType => {
    const { cellType } = view;
    if (cellType instanceof TransactionDetailEnums.CellType.Bold) {
        return { type: SectionRowCellEnum.Bold, text: cellType.text };
    }
    if (cellType instanceof TransactionDetailEnums.CellType.Copy) {
        return { type: SectionRowCellEnum.Copy, text: cellType.text };
    }
    if (cellType instanceof TransactionDetailEnums.CellType.PrefixIcon) {
        return {
            type: SectionRowCellEnum.PrefixIcon,
            text: cellType.text,
            statusIcon: cellType.statusIcon
        };
    }
    if (cellType instanceof TransactionDetailEnums.CellType.Time) {
        const time = cellType.time.timestamp;
        return {
            type: SectionRowCellEnum.Time,
            text: getFormattedTimeDateWithComma(new Date(time))
        };
    }
    if (cellType instanceof TransactionDetailEnums.CellType.ProfilePhoto) {
        return {
            type: SectionRowCellEnum.ProfileImage,
            imageUrl: cellType.image.toString(),
            showViewPhotoButton: true
        };
    }
    return { type: SectionRowCellEnum.Unstyled, text: cellType.text };
};

const getDetailCells = (transactionDetails: TransactionDetailView[]): React.ReactElement[] => {
    const detailCells: React.ReactElement[] = [];
    transactionDetails.forEach((item) => {
        detailCells.push(
            <SectionRowComponentWithLabel
                label={item.label}
                cell={transformCell(item)}
                key={item.label}
            />
        );
    });
    return detailCells;
};

export const TransactionDetailsSection = ({
    transactionDetails
}: TransactionDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Section
            title={t('common.transactionDetailsTitle')}
            allowViewChange={false}
            content={getDetailCells(transactionDetails)}
            headerHeight="52px"
        />
    );
};
