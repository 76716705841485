import {
    BitmapImageType,
    BOGender,
    BOName,
    BORoleDiff,
    BORoleDiffStatus,
    BOUserComment,
    BOUserProfileDiff,
    BOUserRequestSummary,
    Comment,
    GetBOUserRequestSummaryRPC,
    MultiResolutionBitmapImage,
    NewBOUserBasicDetails,
    RemoteBitmapImage,
    RequestStatus,
    UserName,
    BORole
} from '@resolut-tech/bcn-rpcs';
import {
    LeoEmailId,
    LeoPhoneNumber,
    LeoRPCResult,
    LeoTimestamp,
    LeoUUID
} from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { LocalizedText } from '@resolut-tech/bcn-rpcs/build/assets/localizedText';

export class MockGetBOUserRequestSummaryImpl extends GetBOUserRequestSummaryRPC {
    execute(
        request: GetBOUserRequestSummaryRPC.Request
    ): Promise<
        LeoRPCResult<GetBOUserRequestSummaryRPC.Response, GetBOUserRequestSummaryRPC.Errors.Errors>
    > {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );

        const getSummary = ():
            | BOUserRequestSummary.AddUser
            | BOUserRequestSummary.EditUser
            | BOUserRequestSummary.ForceResetPassword
            | BOUserRequestSummary.RegenerateAuthCode
            | BOUserRequestSummary.ArchiveUser => {
            if (request.requestId.uuid === '7a1de96b-d2f7-4ba5-827b-564a6906171a') {
                return new BOUserRequestSummary.AddUser(
                    new NewBOUserBasicDetails(
                        new UserName(new BOName('Wade'), new BOName('Warren')),
                        new BOName('Wade'),
                        new LeoPhoneNumber('+265995118354'),
                        new LeoEmailId('wade.warren@email.com'),
                        new LocalizedText('Malawi'),
                        BOGender.BOGender.MALE,
                        [
                            new BORole(
                                'Fee Determination Rules Viewer',
                                new LocalizedText('Viewer'),
                                new LocalizedText('Fee Determination Rules'),
                                new LocalizedText('View Rules, View History, Download Rules')
                            ),
                            new BORole(
                                'Transactions Refund Maker',
                                new LocalizedText('Refund Maker'),
                                new LocalizedText('Transactions'),
                                new LocalizedText(
                                    'View List, View Details, View History, Raise Request'
                                )
                            )
                        ]
                    )
                );
            } else if (request.requestId.uuid === '9dbaf522-d69e-473a-a318-83c70565f7a1') {
                return new BOUserRequestSummary.EditUser(
                    [new BOUserProfileDiff(new LocalizedText('First Name'), 'Wade', 'Warren')],
                    [
                        new BORoleDiff(
                            new LocalizedText('Viewer'),
                            new LocalizedText('Fee Determination Rules'),
                            BORoleDiffStatus.BORoleDiffStatus.REMOVED
                        ),
                        new BORoleDiff(
                            new LocalizedText('Maker'),
                            new LocalizedText('Fee Determination Rules'),
                            BORoleDiffStatus.BORoleDiffStatus.ADDED
                        ),
                        new BORoleDiff(
                            new LocalizedText('Refund Maker'),
                            new LocalizedText('Transactions')
                        ),
                        new BORoleDiff(
                            new LocalizedText('Checker'),
                            new LocalizedText('BCN User Management')
                        )
                    ]
                );
            } else if (request.requestId.uuid === 'a3e12cbe-2407-4959-a5a3-e385cb00b0b9') {
                return new BOUserRequestSummary.ForceResetPassword();
            } else if (request.requestId.uuid === '4073e1fe-8d86-4beb-b17c-2ede8252d893') {
                return new BOUserRequestSummary.RegenerateAuthCode();
            } else if (request.requestId.uuid === '130f7a5b-a8a7-42b8-a2ac-fc97e1e88499') {
                return new BOUserRequestSummary.ArchiveUser();
            } else {
                return new BOUserRequestSummary.EditUser(
                    [new BOUserProfileDiff(new LocalizedText('First Name'), 'Wade', 'Warren')],
                    null
                );
            }
        };

        const response = new GetBOUserRequestSummaryRPC.Response(
            getSummary(),
            RequestStatus.RequestStatus.APPROVED,
            new BOUserComment(
                new LeoUUID(),
                new UserName(new BOName('Wade'), new BOName('Warren')),
                profilePicture,
                new LeoTimestamp(),
                new Comment('The user had requested for the changes')
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOUserRequestSummaryRPC.Response,
                GetBOUserRequestSummaryRPC.Errors.Errors
            >
        >;
    }
}
