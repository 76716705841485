import { datadogLogs } from '@datadog/browser-logs';
import { types, Instance, getRoot } from 'mobx-state-tree';
import { RootStore } from '../modules/store/root/store/RootStore';

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
    });
}

export const LoggerStore = types.model().views((store) => ({
    getUserIdentity(): string {
        const rootStore = getRoot<typeof RootStore>(store);
        const signInStore = rootStore.signInStore;
        const phoneNumber = signInStore.phoneNumber();
        const userStore = rootStore.homeStore.user;
        const userId = userStore.userId;

        if (phoneNumber) {
            return `Ph ${phoneNumber}`;
        } else {
            return `Id ${userId}`;
        }
    },
    getLogConfiguration(): object {
        return {
            service: 'back-office-fe',
            env: process.env.REACT_APP_ENV,
            version: process.env.REACT_APP_VERSION,
            userIdentity: this.getUserIdentity()
        };
    },
    debug(message: string): void {
        if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
            datadogLogs.logger.debug(message, this.getLogConfiguration());
        } else {
            console.debug.bind(console);
        }
    },
    error(message: string): void {
        if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
            datadogLogs.logger.error(message, this.getLogConfiguration());
        } else {
            console.error.bind(console);
        }
    }
}));

export const createLoggerStore = (): Instance<typeof LoggerStore> => {
    return LoggerStore.create();
};
