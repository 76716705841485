import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { GetAgentTransactionHistoryRPC } from '@resolut-tech/bcn-rpcs';
import { useGetAgentTransactionHistoryRPCClient } from '../rpcs/RPC';
import {
    getRequestDetailsModel,
    RequestDetailsModel
} from '../../common/models/RequestDetailsModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AgentTransactionHistoryErrors {
    InvalidTransactionID = 'INVALID_TRANSACTION_ID'
}

export const AgentTransactionHistoryStore = types
    .model('AgentTransactionHistory', {
        requests: types.array(RequestDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AgentTransactionHistoryErrors>(
                    'AgentTransactionHistoryErrors',
                    Object.values(AgentTransactionHistoryErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        fetchAgentTransactionHistory: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetAgentTransactionHistoryRPC.Request(
                    new LeoUUID(transactionId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetAgentTransactionHistoryRPC.Response,
                    GetAgentTransactionHistoryRPC.Errors.Errors
                > = yield useGetAgentTransactionHistoryRPCClient(apiClient).execute(request);
                store.error = null;
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.requests = cast(
                        response.history.map((requestDetail) =>
                            getRequestDetailsModel(requestDetail)
                        )
                    );
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case AgentTransactionHistoryErrors.InvalidTransactionID:
                            store.error = AgentTransactionHistoryErrors.InvalidTransactionID;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetAgentTransactionHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetAgentTransactionHistoryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = AgentTransactionHistoryErrors.InvalidTransactionID;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetAgentTransactionHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetAgentTransactionHistoryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createAgentTransactionHistoryStore = (): Instance<
    typeof AgentTransactionHistoryStore
> => {
    return AgentTransactionHistoryStore.create({
        requests: []
    });
};
