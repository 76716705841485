import { BOGender, BOUserSearchBy } from '@resolut-tech/bcn-rpcs';
import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { TFunction } from 'react-i18next';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { Instance } from 'mobx-state-tree';
import { BOUserFilterOptions } from '../store/BOUserSearchStore';
import { getGenderTranslation } from '../../common/utils/UIUtils';

const getSearchByValue = (value: BOUserSearchBy.BOUserSearchBy, t: TFunction): string => {
    const values = BOUserSearchBy.BOUserSearchBy;
    switch (value) {
        case values.EMAIL_ID:
            return t('common.emailId');
        case values.PHONE_NUMBER:
            return t('common.mobileNumber');
        case values.NAME:
            return t('common.name');
    }
};

export const getBOUserSearchByOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(BOUserSearchBy.BOUserSearchBy);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value as BOUserSearchBy.BOUserSearchBy, t);
        return { name: dropdownValue, value };
    });
};

export const getInitialFilter = (t: TFunction): Instance<typeof BOUserFilterOptions> => {
    const searchByOptions = getBOUserSearchByOptions(t);

    return BOUserFilterOptions.create({
        searchBy: searchByOptions[0].value,
        searchText: '',
        selectedBORoles: [],
        selectedUserStatus: AnyEnum.ANY
    });
};

export const getBORoleName = (workflow: string, name: string): string => {
    return `${workflow} ${name}`;
};

export const getBOUserGenderOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(BOGender.BOGender);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getGenderTranslation(t, value as BOGender.BOGender);
        return { name: dropdownValue, value };
    });
};
