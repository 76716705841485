import { Box, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum BOUserRequestChipType {
    ForceResetPassword,
    ReGenerateAuthCodes
}

interface BOUserRequestChipProps {
    type: BOUserRequestChipType;
}

export function BOUserRequestChip({ type }: BOUserRequestChipProps): React.ReactElement {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    const StyledStatusBox = styled(Box)(() => ({
        borderRadius: '250px',
        padding: '6px 10px',
        backgroundColor: palette.warning[300],
        textTransform: 'uppercase',
        width: 'fit-content'
    }));

    function getTitle(): string {
        switch (type) {
            case BOUserRequestChipType.ForceResetPassword:
                return t('boUser.forceResetPassword');
            case BOUserRequestChipType.ReGenerateAuthCodes:
                return t('boUser.reGenerateAuthCodes');
        }
    }

    return (
        <StyledStatusBox>
            <Typography
                sx={{
                    ...typography.overline2,
                    color: 'white'
                }}>
                {getTitle()}
            </Typography>
        </StyledStatusBox>
    );
}
