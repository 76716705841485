import {
    BOGender,
    BOUserBasicDetails,
    BOUserRequestDetails,
    BOUserRequestType,
    GetBOUserDetailsRPC,
    GetBOUserDetailRequestsHistoryRPC,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';
import {
    getRequestDetailsModel,
    RequestDetailsModel
} from '../../common/models/RequestDetailsModel';
import { getBORoleModel, BORoleModel } from '../models/BORoleModel';
import {
    useGetBOUserDetailsRPCClient,
    useGetBOUserDetailRequestsHistoryRPCClient
} from '../rpcs/RPC';
import { getBORoleDiffModel, BORoleDiffModel } from '../models/BORoleDiffModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BOUserDetailErrors {
    InvalidBoUserId = 'INVALID_BO_USER_ID'
}

export const BOUserBasicDetailsModel = types.model({
    profilePhoto: types.maybeNull(types.string),
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherNames: types.maybeNull(types.string),
    status: types.enumeration<UserStatus.UserStatus>(
        'BOUserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    phoneNumber: types.string,
    emailId: types.string,
    countryName: types.string,
    gender: types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender)),
    joinDate: types.string,
    roles: types.array(BORoleModel)
});

const getBOUserBasicDetailsModel = (
    details: BOUserBasicDetails
): Instance<typeof BOUserBasicDetailsModel> => {
    return BOUserBasicDetailsModel.create({
        profilePhoto: details.profilePhoto && getImageUrl(details.profilePhoto),
        firstName: details.username.firstName.text,
        lastName: details.username.lastName?.text,
        otherNames: details.otherNames?.text,
        status: details.status,
        phoneNumber: details.phoneNumber.phoneNumber,
        emailId: details.emailId.emailId,
        countryName: getTranslatedString(details.countryName),
        gender: details.gender,
        joinDate: getFormattedTimeDateWithComma(new Date(details.joinDate.timestamp)),
        roles: details.roles.map((role) => getBORoleModel(role))
    });
};

export const BOUserRequestDetailsModel = types.model({
    requestId: types.string,
    requestType: types.maybeNull(
        types.enumeration<BOUserRequestType.BOUserRequestType>(
            'BOUserRequestType',
            Object.values(BOUserRequestType.BOUserRequestType)
        )
    ),
    comment: BOUserCommentModel,
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherNames: types.maybeNull(types.string),
    gender: types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender)),
    phoneNumber: types.string,
    emailId: types.string,
    roles: types.maybeNull(types.array(BORoleDiffModel))
});

const getBOUserRequestDetailsModel = (
    requestDetails: BOUserRequestDetails
): Instance<typeof BOUserRequestDetailsModel> => {
    return BOUserRequestDetailsModel.create({
        requestId: requestDetails.requestId.uuid,
        requestType: requestDetails.requestType,
        comment: getBOUserCommentModel(requestDetails.comment),
        firstName: requestDetails.firstName.text,
        lastName: requestDetails.lastName?.text,
        otherNames: requestDetails.otherNames?.text,
        gender: requestDetails.gender,
        phoneNumber: requestDetails.phoneNumber.phoneNumber,
        emailId: requestDetails.emailId.emailId,
        roles: requestDetails.roles && requestDetails.roles.map((role) => getBORoleDiffModel(role))
    });
};

export const BOUserDetailStore = types
    .model('BOUserDetail', {
        basicDetails: types.maybeNull(BOUserBasicDetailsModel),
        requestDetails: types.maybeNull(BOUserRequestDetailsModel),
        requestHistory: types.array(RequestDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<BOUserDetailErrors>(
                    'BOUserDetailErrors',
                    Object.values(BOUserDetailErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        getBOUserBasicDetails: flow(function* (userId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetBOUserDetailsRPC.Request(new LeoUUID(userId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetBOUserDetailsRPC.Response,
                    GetBOUserDetailsRPC.Errors.InvalidBoUserId
                > = yield useGetBOUserDetailsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.basicDetails = getBOUserBasicDetailsModel(response.details);
                    store.requestDetails =
                        response.requestDetails &&
                        getBOUserRequestDetailsModel(response.requestDetails);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOUserDetailErrors.InvalidBoUserId:
                            store.error = BOUserDetailErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBOUserDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetBOUserDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOUserDetailErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBOUserDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in GetBOUserDetailsRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        getBOUserDetailRequestsHistory: flow(function* (userId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetBOUserDetailRequestsHistoryRPC.Request(new LeoUUID(userId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetBOUserDetailRequestsHistoryRPC.Response,
                    GetBOUserDetailRequestsHistoryRPC.Errors.InvalidBoUserId
                > = yield useGetBOUserDetailRequestsHistoryRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.requestHistory = cast(
                        response.requests.map((requestDetails) =>
                            getRequestDetailsModel(requestDetails)
                        )
                    );
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOUserDetailErrors.InvalidBoUserId:
                            store.error = BOUserDetailErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBOUserDetailRequestsHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetBOUserDetailRequestsHistoryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BOUserDetailErrors.InvalidBoUserId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBOUserDetailRequestsHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetBOUserDetailRequestsHistoryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createBOUserDetailStore = (): Instance<typeof BOUserDetailStore> => {
    return BOUserDetailStore.create();
};
