import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Alert, Stack, styled } from '@mui/material';
import { LoadingIndicator, usePalette } from '@surya-digital/leo-reactjs-ui';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { GetTransactionDetailsRPC } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionDetailsRPC';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TransactionDetailsSection } from '../components/TransactionDetailsSection';
import {
    useCheckRequestDetailsStore,
    useRefundWithdrawRequestStore,
    useRequestTransactionRefundStore,
    useTransactionDetailStore
} from '../store/hooks';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import { RefundHistorySection } from '../components/RefundHistorySection';
import { LinkedTransactionsSection } from '../components/LinkedTransactionSection';
import { RefundRequestDialog } from '../components/RefundRequestDialog';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestTransactionRefundErrors } from '../store/RequestTransactionRefundStore';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { Status } from '../../common/enums/StatusEnum';
import { RefundRequestCheckerDialog } from '../components/RefundRequestCheckerDialog';
import { CheckRequestDetailsErrors } from '../store/CheckRequestDetailsStore';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ProcessingCard } from '../../common/components/ProcessingCard';
import { RefundWithdrawStoreError } from '../store/RefundWithdrawRequestStore';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const TransactionDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useTransactionDetailStore();
    const requestRefundStore = useRequestTransactionRefundStore();
    const userStore = useUserStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParam] = useSearchParams();
    const transactionId = searchParam.get('transactionId');
    const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [additionalFeeErrorText, setAdditionalFeeErrorText] = useState<string | null>(null);
    const checkRequestDetailsStore = useCheckRequestDetailsStore();
    const [isCheckerRefundDialogOpen, setIsCheckerRefundDialogOpen] = useState(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState<boolean>(false);
    const withdrawRequestStore = useRefundWithdrawRequestStore();
    const [showRefundRequestButton, setShowRefundRequestButton] = useState<boolean>(false);
    const loggerStore = useLoggerStore();

    const StyledStack = styled(Stack)(() => ({
        padding: '32px',
        boxSizing: 'border-box',
        width: '100%'
    }));

    const isRequestTransactionPrivilegePresent = userStore.privileges.includes(
        UserPrivileges.RequestTransactionRefund
    );

    const fetchTransactionDetails = async (): Promise<void> => {
        if (transactionId) {
            setLoading(true);
            await store.fetchTransactionDetails(transactionId);
            const isRefundRequest =
                store.refundStatus !==
                    GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus
                        .NOT_REFUNDABLE &&
                store.refundStatus !==
                    GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus
                        .ALREADY_REFUNDED &&
                isRequestTransactionPrivilegePresent;
            setShowRefundRequestButton(isRefundRequest);
            setLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            navigate('/transaction/search', { replace: true });
        }
    };

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckTransactionRefundRequest
    );

    const requestFundsTransferPrivilege = userStore.privileges.includes(
        UserPrivileges.RequestTransactionRefund
    );

    const showWithdrawSection = (): boolean => {
        if (requestFundsTransferPrivilege) {
            if (userStore.userId === store.requestDetails?.requesterComment?.userId) {
                return true;
            }
        }
        return false;
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const handleRequestRefundStoreErrors = (
        errors: RequestTransactionRefundErrors | NetworkingError
    ): void => {
        setErrorDialogTitle(t('transaction.requestRefund'));
        switch (errors) {
            case RequestTransactionRefundErrors.InvalidTransactionId:
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.UnableToPerformExchange:
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.InsufficientBalance:
                setErrorMessage(t('common.insufficientBalance'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.TransactionAlreadyRefunded:
                setErrorMessage(t('transaction.transactionAlreadyRefunded'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.RefundUnderProcess:
                setErrorMessage(t('transaction.refundUnderProcess'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.AmountTooLess:
                if (requestRefundStore.errorLimitAmount) {
                    setErrorMessage(
                        t('common.amountTooLess', {
                            amount: requestRefundStore.errorLimitAmount
                        })
                    );
                    setIsErrorDialogOpen(true);
                    break;
                } else {
                    loggerStore.debug('Could not find errorLimitAmount in RefundRequestStore');
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                }
            case RequestTransactionRefundErrors.AmountTooLarge:
                if (requestRefundStore.errorLimitAmount) {
                    setErrorMessage(
                        t('common.amountTooLarge', {
                            amount: requestRefundStore.errorLimitAmount
                        })
                    );
                    setIsErrorDialogOpen(true);
                    break;
                } else {
                    loggerStore.debug('Could not find errorLimitAmount in RefundRequestStore');
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                }
            case RequestTransactionRefundErrors.RefundPeriodExpired:
                setErrorMessage(t('transaction.refundPeriodExpired'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.SenderAccountInactive:
                setErrorMessage(t('common.senderAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.RecipientAccountInactive:
                setErrorMessage(t('common.receiverAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.SenderProfileDisabled:
                setErrorMessage(t('common.senderProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.RecipientProfileDisabled:
                setErrorMessage(t('common.receiverProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.ReceivingAccountWouldCrossLimit:
                setErrorMessage(t('common.receivingAccountWillCrossLimit'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.AdditionalFeeAmountTooLarge:
                if (requestRefundStore.errorLimitAmount) {
                    setAdditionalFeeErrorText(
                        t('transaction.maximumAllowedAdditionalFee', {
                            amount: requestRefundStore.errorLimitAmount
                        })
                    );
                } else {
                    loggerStore.debug('Could not find errorLimitAmount in RefundRequestStore');
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                }
                break;
            case RequestTransactionRefundErrors.AdditionalFeeCannotBeMoreThanBaseAmount:
                setAdditionalFeeErrorText(t('transaction.additionalFeeMoreThanBaseAmount'));
                setIsRefundDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.AdditionalFeeAmountTooLess:
                if (requestRefundStore.errorLimitAmount) {
                    setAdditionalFeeErrorText(
                        t('transaction.minimumAllowedAdditionalFee', {
                            amount: requestRefundStore.errorLimitAmount
                        })
                    );
                } else {
                    loggerStore.debug('Could not find errorLimitAmount in RefundRequestStore');
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                }
                break;
            case RequestTransactionRefundErrors.SenderProfileArchived:
                setErrorMessage(t('common.senderProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.RecipientProfileArchived:
                setErrorMessage(t('common.receiverProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.UnsupportedTransactionType:
                setIsErrorDialogOpen(true);
                break;
            case RequestTransactionRefundErrors.InvalidAmountError:
                setAdditionalFeeErrorText(t('common.invalidAmount'));
                break;
            case RequestTransactionRefundErrors.CurrencyMismatch:
                setIsErrorDialogOpen(true);
                break;
            case NetworkingError.InternalError:
                setIsErrorDialogOpen(true);
                break;
            default:
                setIsErrorDialogOpen(true);
        }
    };

    const onRequestRefund = async (): Promise<void> => {
        if (transactionId) {
            setIsDataLoading(true);
            await requestRefundStore.getRefundTransactionDetails(transactionId);
            setIsDataLoading(false);
        } else {
            loggerStore.debug('Could not find transactionId in RefundRequestStore');
            setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
        }
        if (requestRefundStore.error) {
            handleRequestRefundStoreErrors(requestRefundStore.error);
        } else {
            setIsRefundDialogOpen(true);
        }
    };

    const onSubmitRefundRequest = async (comment: string): Promise<void> => {
        setIsRefundDialogOpen(false);
        requestRefundStore.setComment(comment);
        if (transactionId) {
            setIsDataLoading(true);
            await requestRefundStore.requestTransactionRefund(transactionId);
            setIsDataLoading(false);
        } else {
            loggerStore.debug('Could not find transactionId while submitting refund request');
            setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
        }
        if (requestRefundStore.error) {
            setIsRefundDialogOpen(true);
            handleRequestRefundStoreErrors(requestRefundStore.error);
        } else {
            fetchTransactionDetails();
            requestRefundStore.resetFeeValues();
        }
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setIsDataLoading(true);
        if (store.requestDetails?.requestId) {
            await withdrawRequestStore.withdrawRequest(store.requestDetails.requestId, comment);
        } else {
            loggerStore.debug('Cannot find requestId in TransactionDetailsStore ');
            setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
        }
        setIsDataLoading(false);
        if (withdrawRequestStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (withdrawRequestStore.error) {
                case RefundWithdrawStoreError.InvalidComment:
                    setErrorMessage('common.invalidComment');
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorMessage(t('common.canOnlyWithdrawSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorMessage(t('common.requestAlreadyDiscarded'));
                    setIsErrorDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setIsErrorDialogOpen(true);
                    break;
            }
        } else {
            // Since the request has been withdrawn, we will fetch the Transaction details again.
            fetchTransactionDetails();
        }
    };

    const handleCheckRequestDetailsStoreErrors = (
        errors: CheckRequestDetailsErrors | NetworkingError
    ): void => {
        setErrorDialogTitle(
            checkRequestDetailsStore.status === Status.APPROVED
                ? t('transaction.approveRefundRequest')
                : t('transaction.denyRefundRequest')
        );
        switch (errors) {
            case CheckRequestDetailsErrors.InvalidRequestId:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.UnableToPerformExchange:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RecipientProfileDisabled:
                setErrorMessage(t('common.receiverProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.SenderProfileDisabled:
                setErrorMessage(t('common.senderProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.InsufficientBalance:
                setErrorMessage(t('common.insufficientBalance'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.ReceivingAccountWouldCrossLimit:
                setErrorMessage(t('common.receivingAccountWillCrossLimit'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RecipientAccountInactive:
                setErrorMessage(t('common.receiverAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RefundPeriodExpired:
                setErrorMessage(t('transaction.refundPeriodExpired'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RequestAlreadyChecked:
                setErrorMessage(t('common.requestAlreadyChecked'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.SenderAccountInactive:
                setErrorMessage(t('common.senderAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.InvalidExchangeRateId:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.UnableToRefund:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RequestAlreadyWithdrawn:
                setErrorMessage(t('common.requestAlreadyWithdrawn'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RequestAlreadyDiscarded:
                setErrorMessage(t('common.requestAlreadyDiscarded'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RequestUnderProcess:
                setErrorMessage(t('common.requestUnderProcess'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.CannotCheckSelfRequest:
                setErrorMessage(t('common.cannotCheckSelfRequest'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.SenderProfileArchived:
                setErrorMessage(t('common.senderProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestDetailsErrors.RecipientProfileArchived:
                setErrorMessage(t('common.receiverProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case NetworkingError.InternalError:
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
                break;
            default:
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
                break;
        }
    };

    const getRefundRequestDetails = async (): Promise<void> => {
        checkRequestDetailsStore.removeError();
        if (store.requestDetails?.requestId) {
            setIsDataLoading(true);
            await checkRequestDetailsStore.getRefundRequestDetails(store.requestDetails.requestId);
            setIsDataLoading(false);
        } else {
            loggerStore.debug('Cannot find requestId in TransactionDetailsStore');
            setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
        }
        if (checkRequestDetailsStore.error) {
            handleCheckRequestDetailsStoreErrors(checkRequestDetailsStore.error);
        } else {
            setIsCheckerRefundDialogOpen(true);
        }
    };

    const onApproveClick = (): void => {
        checkRequestDetailsStore.setStatus(Status.APPROVED);
        getRefundRequestDetails();
    };

    const onDenyClick = (): void => {
        checkRequestDetailsStore.setStatus(Status.DENIED);
        getRefundRequestDetails();
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    withdrawRequestStore.resetComment();
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    const onSubmitCheckerResponse = async (comment: string): Promise<void> => {
        setIsCheckerRefundDialogOpen(false);
        checkRequestDetailsStore.setComment(comment);
        if (store.requestDetails?.requestId) {
            setIsDataLoading(true);
            await checkRequestDetailsStore.checkRefundRequest(store.requestDetails.requestId);
            setIsDataLoading(false);
        } else {
            loggerStore.debug('Cannot find requestId in TransactionDetailsStore');
            setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
            setIsErrorDialogOpen(true);
        }
        if (checkRequestDetailsStore.error) {
            handleCheckRequestDetailsStoreErrors(checkRequestDetailsStore.error);
        } else {
            // Since the request has been successfully submitted , we will fetch the Transaction details again.
            fetchTransactionDetails();
        }
    };

    useEffect(() => {
        fetchTransactionDetails();
    }, []);

    useEffect(() => {
        if (!isErrorDialogOpen) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            requestRefundStore.removeError();
        }
    }, [isErrorDialogOpen]);

    const refundButtonDisabled =
        store.refundStatus ===
        GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus.EXPIRED;

    const alreadyRefunded =
        store.refundStatus ===
        GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus.ALREADY_REFUNDED;

    const StyledWarning = styled(Alert)({
        marginTop: '32px',
        marginLeft: '32px',
        marginRight: '32px',
        color: palette.warning['300'],
        backgroundColor: palette.warning['100'],
        borderRadius: '8px',
        border: `1px solid ${palette.warning['200']}`
    });

    const getPageHeaderActionElement = (): ActionElement | undefined => {
        if (store.error) {
            // This is done to ensure that if in a situation transaction details resulted in a store error, then refund button should not be shown.
            return undefined;
        } else {
            if (requestFundsTransferPrivilege) {
                return {
                    secondaryButton: showRefundRequestButton
                        ? {
                              title: t('transaction.requestRefund'),
                              helperText: refundButtonDisabled
                                  ? t('transaction.refundDateExpired')
                                  : undefined,
                              isDisabled: refundButtonDisabled,
                              onClick: onRequestRefund
                          }
                        : undefined
                };
            }
            return undefined;
        }
    };

    const showPendingCard = Boolean(
        store.requestDetails?.status === IntermediateRequestStatus.IntermediateRequestStatus.PENDING
    );

    return (
        <Stack direction="column">
            <PageHeader
                title={t('common.transactionDetailsTitle')}
                subtitle={t('common.transactionDetailsSubtitle')}
                actionElement={getPageHeaderActionElement()}
            />
            {requestRefundStore.baseAmount &&
                requestRefundStore.additionalFeeCurrency &&
                transactionId && (
                    <RefundRequestDialog
                        isDialogOpen={isRefundDialogOpen}
                        onClose={(): void => {
                            setIsRefundDialogOpen(false);
                        }}
                        transactionId={transactionId}
                        onSubmitRequest={onSubmitRefundRequest}
                        amountFieldErrorText={additionalFeeErrorText}
                        setAmountFieldErrorText={setAdditionalFeeErrorText}
                        handleRequestRefundStoreErrors={handleRequestRefundStoreErrors}
                    />
                )}
            {checkRequestDetailsStore.baseAmount && (
                <RefundRequestCheckerDialog
                    isDialogOpen={isCheckerRefundDialogOpen}
                    onClose={(): void => {
                        setIsCheckerRefundDialogOpen(false);
                    }}
                    onSubmitResponse={onSubmitCheckerResponse}
                    checkRequestDetailsStore={checkRequestDetailsStore}
                />
            )}
            {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
            <LoadingIndicator isLoading={isDataLoading} />
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorMessage}
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    setErrorMessage(null);
                    requestRefundStore.removeError();
                    withdrawRequestStore.removeError();
                    store.removeError();
                }}
            />
            {store.error ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : loading ? (
                <LoadingState />
            ) : (
                <Stack>
                    <BreadcrumbComponent currentLabel={t('common.transactionDetailsTitle')} />
                    {alreadyRefunded && (
                        <StyledWarning
                            icon={
                                <AlertCircle
                                    color={palette.warning['300']}
                                    height="24px"
                                    width="24px"
                                />
                            }>
                            {t('transaction.transactionAlreadyRefunded')}
                        </StyledWarning>
                    )}
                    {showPendingCard && store.requestDetails?.requesterComment && (
                        <Stack sx={{ padding: '32px 32px 0px 32px' }}>
                            <PendingRequestCard
                                onApproveClick={onApproveClick}
                                onDenyClick={onDenyClick}
                                onWithdrawClick={onWithdrawClick}
                                showApproveAndDenySection={showApproveAndDenySection}
                                showWithdrawSection={showWithdrawSection()}
                                comment={store.requestDetails.requesterComment}>
                                <></>
                            </PendingRequestCard>
                        </Stack>
                    )}
                    {store.isProcessing &&
                        store.requestDetails?.requesterComment &&
                        store.requestDetails.evaluatorComment && (
                            <Stack sx={{ padding: '32px 32px 0px 32px' }}>
                                <ProcessingCard
                                    message={t('transaction.amountCurrentlyBeingRefunded')}
                                    makerComment={store.requestDetails.requesterComment}
                                    checkerComment={store.requestDetails.evaluatorComment}
                                />
                            </Stack>
                        )}
                    <StyledStack spacing="32px">
                        <TransactionDetailsSection transactionDetails={store.details} />
                        <LinkedTransactionsSection transactionId={transactionId} />
                        <RefundHistorySection transactionId={transactionId} />
                    </StyledStack>
                </Stack>
            )}
        </Stack>
    );
});
