import {
    GetBOPresignedDownloadURLForTransactionRulesRPC,
    GetBOPresignedDownloadURLForTransactionRulesTemplateRPC
} from '@resolut-tech/bcn-rpcs';
import { DownloadFileErrors } from '../../common/errors/DownloadFileErrors';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, Instance, flow } from 'mobx-state-tree';
import { downloadFile } from '../../common/utils/FileUtils';
import {
    useGetBOPresignedDownloadURLForTransactionRulesRPCClient,
    useGetBOPresignedDownloadURLForTransactionRulesTemplateRPCClient
} from '../rpcs/RPC';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { getLoggerStore } from '../../../../log/hooks';

export const DownloadTransactionRulesStore = types
    .model({
        error: types.maybeNull(
            types.enumeration<DownloadFileErrors>(
                'DownloadFileErrors',
                Object.values(DownloadFileErrors)
            )
        )
    })
    .actions((store) => ({
        downloadTemplateFile: flow(function* (fileName: RulesDownloadFileNameEnum) {
            const loggerStore = getLoggerStore(store);
            const request = new GetBOPresignedDownloadURLForTransactionRulesTemplateRPC.Request(
                fileName
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetBOPresignedDownloadURLForTransactionRulesTemplateRPC.Response,
                GetBOPresignedDownloadURLForTransactionRulesTemplateRPC.Errors.Errors
            > = yield useGetBOPresignedDownloadURLForTransactionRulesTemplateRPCClient(
                apiClient
            ).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                downloadFile(response.documentURL.toString());
            } else if (result instanceof LeoRPCResult.Error) {
                store.error = DownloadFileErrors.RecordNotFound;
            } else {
                store.error = DownloadFileErrors.RecordNotFound;
                loggerStore.error(
                    `Unknown error occurred in GetBOPresignedDownloadURLForTransactionRulesTemplateRPC with result: ${result}`
                );
            }
        }),
        downloadFile: flow(function* (
            documentId: string | null,
            fileName: RulesDownloadFileNameEnum
        ) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            if (documentId) {
                try {
                    const request = new GetBOPresignedDownloadURLForTransactionRulesRPC.Request(
                        new LeoUUID(documentId),
                        fileName
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        GetBOPresignedDownloadURLForTransactionRulesRPC.Response,
                        GetBOPresignedDownloadURLForTransactionRulesRPC.Errors.Errors
                    > = yield useGetBOPresignedDownloadURLForTransactionRulesRPCClient(
                        apiClient
                    ).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        downloadFile(response.documentURL.toString());
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case DownloadFileErrors.RecordNotFound:
                                store.error = DownloadFileErrors.RecordNotFound;
                                break;
                            case DownloadFileErrors.CouldNotGenerateFile:
                                store.error = DownloadFileErrors.CouldNotGenerateFile;
                                break;
                            default:
                                store.error = DownloadFileErrors.CouldNotGenerateFile;
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in GetBOPresignedDownloadURLForTransactionRulesRPC`
                                );
                        }
                    } else {
                        store.error = DownloadFileErrors.CouldNotGenerateFile;
                        loggerStore.error(
                            `Unknown error occurred in GetBOPresignedDownloadURLForTransactionRulesRPC with result: ${result}`
                        );
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        switch (error.name) {
                            case LeoErrors.InvalidLeoUUIDError:
                                store.error = DownloadFileErrors.CouldNotGenerateFile;
                                break;
                            default:
                                store.error = DownloadFileErrors.CouldNotGenerateFile;
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in GetBOPresignedDownloadURLForTransactionRulesRPC`
                                );
                        }
                    } else {
                        store.error = DownloadFileErrors.CouldNotGenerateFile;
                        loggerStore.error(
                            `Unknown error: ${error} occurred in GetBOPresignedDownloadURLForTransactionRulesRPC`
                        );
                    }
                }
            } else {
                loggerStore.debug('Cannot find documentId in DownloadTransactionRulesStore');
                store.error = DownloadFileErrors.RecordNotFound;
            }
        })
    }));

export const createDownloadTransactionRulesStore = (): Instance<
    typeof DownloadTransactionRulesStore
> => {
    return DownloadTransactionRulesStore.create();
};
