import * as React from 'react';

export const Smartphone = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83337 2.49967C5.37314 2.49967 5.00004 2.87277 5.00004 3.33301V16.6663C5.00004 17.1266 5.37314 17.4997 5.83337 17.4997H14.1667C14.6269 17.4997 15 17.1266 15 16.6663V3.33301C15 2.87277 14.6269 2.49967 14.1667 2.49967H5.83337ZM3.33337 3.33301C3.33337 1.9523 4.45266 0.833008 5.83337 0.833008H14.1667C15.5474 0.833008 16.6667 1.9523 16.6667 3.33301V16.6663C16.6667 18.0471 15.5474 19.1663 14.1667 19.1663H5.83337C4.45266 19.1663 3.33337 18.0471 3.33337 16.6663V3.33301Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.16663 15.0003C9.16663 14.5401 9.53972 14.167 9.99996 14.167H10.0083C10.4685 14.167 10.8416 14.5401 10.8416 15.0003C10.8416 15.4606 10.4685 15.8337 10.0083 15.8337H9.99996C9.53972 15.8337 9.16663 15.4606 9.16663 15.0003Z"
                fill="currentColor"
            />
        </svg>
    );
};
