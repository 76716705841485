import { Stack, Typography } from '@mui/material';
import { ReportType } from '@resolut-tech/bcn-rpcs';
import {
    DateRangePickerInput,
    DropdownInputField,
    DropdownItem,
    LoadingIndicator,
    Table,
    TableHeader,
    TableOptions,
    TableReloadHandle,
    TableRowItems,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageHeader } from '../../common/components/PageHeader';
import { ReportsGraphComponent } from '../components/ReportGraph';
import {
    getFormattedData,
    getGraphHeaderTitle,
    getGraphSubHeaderTitle,
    getGraphTitle
} from '../utils/UIUtils';
import { useReportStore } from '../store/hooks';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { observer } from 'mobx-react';
import { ReportFilter } from '../components/ReportFilter';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { ReportDataErrors } from '../store/ReportStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { getDateString } from '../../common/utils/DateUtils';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ReportDetails = observer((): React.ReactElement => {
    const [searchParam] = useSearchParams();
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const store = useReportStore();
    const tableRef = useRef<TableReloadHandle>();
    const reportType = searchParam.get('reportType');
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<CountryViewModel[] | null>(null);
    const [dateRange, setDateRange] = useState<DateRangePickerInput | null>(null);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const loggerStore = useLoggerStore();

    useEffect(() => {
        store.resetStore();
        const type = reportType as ReportType.ReportType;
        if (type) {
            //since type is valid hence we will render the report data
            store.resetDate();
            store.setReportType(type);
        } else {
            navigate('home/reports'); //since we don't have a valid report type hence we are navigating to previous screen
        }
    }, []);

    const getReportGraphSeriesName = (): string => {
        const type = reportType as ReportType.ReportType;
        if (type) {
            switch (type) {
                case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
                    return t('reports.transactions');
                case ReportType.ReportType.USER_ACQUISITIONS:
                    return t('reports.users');
                case ReportType.ReportType.REVENUE:
                case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
                case ReportType.ReportType.USER_BALANCE:
                    return '';
            }
        } else {
            return '';
        }
    };

    const handleCountrySelection = async (value: DropdownItem): Promise<void> => {
        const _country = countries?.find((countryIterator) => {
            return countryIterator.countryCode === value.value;
        });
        if (_country) {
            setLoading(true);
            store.setCountryCode(_country.countryCode);
            tableRef.current?.reload();
            setLoading(false);
        }
    };

    const getTableHeaders = (): TableHeader => {
        return [
            {
                id: 'Date',
                name: 'Date',
                width: '182px'
            },
            {
                id: 'date id',
                name: getGraphHeaderTitle(t, reportType as ReportType.ReportType),
                width: '160px'
            }
        ];
    };

    const handleStoreError = (): void => {
        if (store.error) {
            setIsErrorDialogOpen(true);
            switch (store.error) {
                case ReportDataErrors.CouldNotGenerateFile:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case ReportDataErrors.InvalidDateRange:
                    setErrorDialogMessage(t('common.invalidDateRange'));
                    break;
                case ReportDataErrors.LargeDateRange:
                    setErrorDialogMessage(t('reports.selectedRangeTooLarge'));
                    break;
                case LeoErrors.InvalidLeoDateError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case ReportDataErrors.NoRecordsFound:
                    setErrorDialogMessage(t('common.noRecordsFound'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
            }
        } else {
            setIsErrorDialogOpen(false);
            setErrorDialogMessage(null);
        }
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={t('common.somethingWentWrong')}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    store.removeError();
                }}
            />
        );
    };

    const getTableData = async (
        options: TableOptions<DateRangePickerInput>,
        _setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (!countries) {
            setLoading(true);
            await store.fetchCountries();
            setCountries(store.countryList());
            if (!store.countryCode) {
                store.setCountryCode(store.countryList()[0].countryCode);
            }
        }
        if (store.countryCode) {
            setLoading(true);
            if (options.filter) {
                setDateRange(options.filter);
            } else {
                setDateRange(null);
            }
            await store.fetchReportData(store.countryCode, dateRange ?? undefined);
            setLoading(false);
            if (store.reportData && store.reportData?.length > 0) {
                return store.reportData?.map((dataOfTable) => {
                    return [
                        { data: getDateString(dataOfTable.date) },
                        {
                            data: getFormattedData(
                                dataOfTable.value,
                                reportType as ReportType.ReportType
                            )
                        }
                    ];
                });
            } else if (store.error) {
                switch (store.error) {
                    case ReportDataErrors.InvalidDateRange:
                        return t('common.invalidDateRange');
                    case ReportDataErrors.LargeDateRange:
                        return t('reports.selectedRangeTooLargeDownloadFile');
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        return t('common.somethingWentWrongProcessingRequest');
                    default:
                        // other errors are not thrown by the RPC hence they are not handled
                        return t('common.somethingWentWrongProcessingRequest');
                }
            } else {
                return [];
            }
        } else {
            loggerStore.debug('Could not find selected country in ReportStore');
            return [];
        }
    };

    const countryDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    name: `${countryIterator.name}`,
                    value: countryIterator.countryCode
                };
            });
        }
        return [];
    };

    const countryDropdown = (): React.ReactElement => {
        if (store.countryCode) {
            return (
                <DropdownInputField
                    style={{ width: '360px' }}
                    name={'countryDropdown'}
                    label={t('common.selectCountry')}
                    value={store.countryCode}
                    options={countryDropdownItems()}
                    onSelect={handleCountrySelection}
                />
            );
        } else {
            return <></>;
        }
    };

    return (
        <Stack sx={{ bgcolor: palette.background[300] }}>
            <LoadingIndicator isLoading={loading} color="white" />
            {isErrorDialogOpen && ErrorDialogBox()}
            <PageHeader
                title={getGraphHeaderTitle(t, reportType as ReportType.ReportType)}
                subtitle={getGraphSubHeaderTitle(t, reportType as ReportType.ReportType)}
                actionElement={{ dropdown: countryDropdown() }}
            />
            <Stack justifyContent="center" alignItems="center" p="32px" spacing={'32px'}>
                <BreadcrumbComponent
                    currentLabel={getGraphHeaderTitle(t, reportType as ReportType.ReportType)}
                    sx={{ padding: '0px' }}
                />
                <Stack
                    width="100%"
                    bgcolor={palette.background[400]}
                    boxSizing="border-box"
                    border={`1px solid ${palette.outline[100]}`}
                    borderRadius="8px">
                    <Stack
                        p="16px"
                        borderBottom={`1px solid ${palette.outline[200]}`}
                        boxSizing="border-box">
                        <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                            {getGraphTitle(t, reportType as ReportType.ReportType)}
                        </Typography>
                    </Stack>
                    <ReportsGraphComponent
                        title={getGraphTitle(t, reportType as ReportType.ReportType)}
                        seriesName={getReportGraphSeriesName()}
                        dataList={
                            store.reportData?.map((data) => {
                                return { date: data.date, value: data.value };
                            }) ?? []
                        }
                    />
                </Stack>
                <Table
                    name="ReportsTable"
                    ref={tableRef as RefObject<TableReloadHandle>}
                    styleOverrides={getTableStyleOverrides(palette)}
                    filterOption={{
                        // Since Reports table doesn't have details screen there is no need to retain the filter options in store.
                        initialFilterValue: {
                            startDate: null,
                            endDate: null
                        } as DateRangePickerInput,
                        filterComponent(filter, setFilter): React.ReactElement {
                            return (
                                <ReportFilter
                                    filter={filter}
                                    setFilter={setFilter}
                                    setReportDateRange={setDateRange}
                                    reportType={reportType}
                                    onDownloadClick={async (): Promise<void> => {
                                        await store.fetchFileId(dateRange ?? undefined);
                                        handleStoreError();
                                    }}
                                />
                            );
                        }
                    }}
                    headers={getTableHeaders()}
                    viewOverrides={{
                        empty: { message: t('common.noResultsFound') }
                    }}
                    onTableOptionsChange={getTableData}
                />
            </Stack>
        </Stack>
    );
});
