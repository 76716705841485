import { Stack } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface ApproveDenyUnclaimedFundsActionSectionProps {
    onDenyClick: () => void;
    onApproveClick: () => void;
    onViewDetailsButtonClick?: () => void;
}

export function ApproveDenyUnclaimedFundsActionSection({
    onDenyClick,
    onApproveClick,
    onViewDetailsButtonClick
}: ApproveDenyUnclaimedFundsActionSectionProps): React.ReactElement {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Stack
            direction="row"
            padding="16px"
            borderTop={`1px solid ${palette.outline[200]}`}
            justifyContent={onViewDetailsButtonClick ? 'space-between' : 'flex-end'}>
            {onViewDetailsButtonClick && (
                <Button
                    name="secondary-button"
                    variant="outlined-color"
                    size="small"
                    title={t('common.viewDetails')}
                    onClick={onViewDetailsButtonClick}
                    style={{
                        width: '100px'
                    }}
                />
            )}
            <Stack direction="row">
                <Button
                    name="secondary-button"
                    variant="outlined-neutral"
                    size="small"
                    title={t('common.deny')}
                    onClick={onDenyClick}
                    style={{
                        marginRight: '8px',
                        width: '80px'
                    }}
                />
                <Button
                    name="secondary-button"
                    variant="outlined-neutral"
                    size="small"
                    title={t('common.approve')}
                    onClick={onApproveClick}
                    style={{
                        width: '80px'
                    }}
                />
            </Stack>
        </Stack>
    );
}
