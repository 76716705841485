import {
    BOPendingRequestCount,
    CheckBOUserChangeRequestRPC,
    CheckerResponse,
    CheckRegenerateAuthCodeRequestRPC,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance, cast } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { Status } from '../../common/enums/StatusEnum';
import { LeoErrors } from '../../common/errors/LeoErrors';
import {
    useCheckBOUserChangeRequestRPCClient,
    useCheckRegenerateAuthCodeRequestRPCClient
} from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum CheckBOUserChangeRequestStoreError {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    InvalidComment = 'INVALID_COMMENT',
    UserHasActivePendingRequests = 'USER_HAS_ACTIVE_PENDING_REQUESTS',
    CannotAssignInactiveRole = 'CANNOT_ASSIGN_INACTIVE_ROLE',
    CannotCheckSelfProfile = 'CANNOT_CHECK_SELF_PROFILE',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    IncorrectRequestType = 'INCORRECT_REQUEST_TYPE',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    EmailIdAlreadyExists = 'EMAIL_ID_ALREADY_EXISTS',
    PhoneNumberAlreadyExists = 'PHONE_NUMBER_ALREADY_EXISTS',
    AgentCannotBeAnAgentManager = 'AGENT_CANNOT_BE_AN_AGENT_MANAGER',
    UserAppliedToBeAnAgent = 'USER_APPLIED_TO_BE_AN_AGENT',
    CouldNotGenerateCodes = 'COULD_NOT_GENERATE_CODES'
}

const getStoreError = (
    error: CheckBOUserChangeRequestRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): CheckBOUserChangeRequestStoreError | NetworkingError => {
    switch (error.code) {
        case CheckBOUserChangeRequestStoreError.InvalidRequestId:
            return CheckBOUserChangeRequestStoreError.InvalidRequestId;
        case CheckBOUserChangeRequestStoreError.RequestAlreadyChecked:
            return CheckBOUserChangeRequestStoreError.RequestAlreadyChecked;
        case CheckBOUserChangeRequestStoreError.CannotAssignInactiveRole:
            return CheckBOUserChangeRequestStoreError.CannotAssignInactiveRole;
        case CheckBOUserChangeRequestStoreError.CannotCheckSelfProfile:
            return CheckBOUserChangeRequestStoreError.CannotCheckSelfProfile;
        case CheckBOUserChangeRequestStoreError.CannotCheckSelfRequest:
            return CheckBOUserChangeRequestStoreError.CannotCheckSelfRequest;
        case CheckBOUserChangeRequestStoreError.RequestAlreadyDiscarded:
            return CheckBOUserChangeRequestStoreError.RequestAlreadyDiscarded;
        case CheckBOUserChangeRequestStoreError.RequestAlreadyWithdrawn:
            return CheckBOUserChangeRequestStoreError.RequestAlreadyWithdrawn;
        case CheckBOUserChangeRequestStoreError.EmailIdAlreadyExists:
            return CheckBOUserChangeRequestStoreError.EmailIdAlreadyExists;
        case CheckBOUserChangeRequestStoreError.PhoneNumberAlreadyExists:
            return CheckBOUserChangeRequestStoreError.PhoneNumberAlreadyExists;
        case CheckBOUserChangeRequestStoreError.IncorrectRequestType:
            return CheckBOUserChangeRequestStoreError.IncorrectRequestType;
        case CheckBOUserChangeRequestStoreError.UserHasActivePendingRequests:
            return CheckBOUserChangeRequestStoreError.UserHasActivePendingRequests;
        case CheckBOUserChangeRequestStoreError.AgentCannotBeAnAgentManager:
            return CheckBOUserChangeRequestStoreError.AgentCannotBeAnAgentManager;
        case CheckBOUserChangeRequestStoreError.UserAppliedToBeAnAgent:
            return CheckBOUserChangeRequestStoreError.UserAppliedToBeAnAgent;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in CheckNewBOUserRequestRPC`);
            return NetworkingError.InternalError;
    }
};

const PendingRequestModal = types.model({
    requestName: types.string,
    count: types.number
});

const getPendingRequestModal = (
    request: BOPendingRequestCount
): Instance<typeof PendingRequestModal> => {
    return PendingRequestModal.create({
        requestName: getTranslatedString(request.requestName),
        count: request.count
    });
};

export const CheckBOUserChangeRequestStore = types
    .model({
        requestId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        authCodeURL: types.maybeNull(types.string),
        pendingRequest: types.maybeNull(types.array(PendingRequestModal)),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckBOUserChangeRequestStoreError>(
                    'CheckBOUserChangeRequestStoreError',
                    Object.values(CheckBOUserChangeRequestStoreError)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetComment(): void {
            store.comment = null;
        },
        removeError(): void {
            store.error = null;
        },
        resetStore(): void {
            store.comment = null;
            store.error = null;
            store.requestId = null;
            store.status = null;
            store.pendingRequest = null;
        },
        checkRegenerateAuthCodeRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            store.comment = comment;
            try {
                if (store.comment && store.requestId && store.status) {
                    const request = new CheckRegenerateAuthCodeRequestRPC.Request(
                        new LeoUUID(store.requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckRegenerateAuthCodeRequestRPC.Response,
                        CheckRegenerateAuthCodeRequestRPC.Errors.Errors
                    > = yield useCheckRegenerateAuthCodeRequestRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        if (response.authCodeDocumentURL?.toString()) {
                            store.authCodeURL = response.authCodeDocumentURL.toString();
                        } else {
                            if (store.status === CheckerResponseEnums.Status.Status.APPROVED) {
                                loggerStore.debug(
                                    'authCodeDocumentURL not returned while check request for regenerate auth code was approved'
                                );
                                store.error = NetworkingError.InternalError;
                            } else {
                                // authCodeDocumentURL is not expected if the request was denied
                            }
                        }
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case CheckBOUserChangeRequestStoreError.InvalidRequestId:
                                store.error = CheckBOUserChangeRequestStoreError.InvalidRequestId;
                                break;
                            case CheckBOUserChangeRequestStoreError.RequestAlreadyChecked:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.RequestAlreadyChecked;
                                break;
                            case CheckBOUserChangeRequestStoreError.CannotCheckSelfProfile:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.CannotCheckSelfProfile;
                                break;
                            case CheckBOUserChangeRequestStoreError.CannotCheckSelfRequest:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.CannotCheckSelfRequest;
                                break;
                            case CheckBOUserChangeRequestStoreError.CouldNotGenerateCodes:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.CouldNotGenerateCodes;
                                break;
                            case CheckBOUserChangeRequestStoreError.IncorrectRequestType:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.IncorrectRequestType;
                                break;
                            case CheckBOUserChangeRequestStoreError.RequestAlreadyDiscarded:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.RequestAlreadyDiscarded;
                                break;
                            case CheckBOUserChangeRequestStoreError.RequestAlreadyWithdrawn:
                                store.error =
                                    CheckBOUserChangeRequestStoreError.RequestAlreadyWithdrawn;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckRegenerateAuthCodeRequestRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckRegenerateAuthCodeRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `comment, status or requestId are empty in CheckRegenerateAuthCodeRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckBOUserChangeRequestStoreError.InvalidRequestId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = CheckBOUserChangeRequestStoreError.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckRegenerateAuthCodeRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckRegenerateAuthCodeRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        checkPendingRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            store.comment = comment;
            try {
                if (store.comment && store.requestId && store.status) {
                    const request = new CheckBOUserChangeRequestRPC.Request(
                        new LeoUUID(store.requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckBOUserChangeRequestRPC.Response,
                        CheckBOUserChangeRequestRPC.Errors.Errors
                    > = yield useCheckBOUserChangeRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return result;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (
                            error instanceof
                            CheckBOUserChangeRequestRPC.Errors.UserHasActivePendingRequests
                        ) {
                            const getPendingRequests = error.pendingRequests.map(
                                (pendingRequest: BOPendingRequestCount) => {
                                    return getPendingRequestModal(pendingRequest);
                                }
                            );
                            store.pendingRequest = cast(getPendingRequests);
                            store.error =
                                CheckBOUserChangeRequestStoreError.UserHasActivePendingRequests;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckBOUserChangeRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `status, comment or requestId are empty in CheckBOUserChangeRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckBOUserChangeRequestStoreError.InvalidRequestId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = CheckBOUserChangeRequestStoreError.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckBOUserChangeRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckBOUserChangeRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createCheckBOUserChangeRequestStore = (): Instance<
    typeof CheckBOUserChangeRequestStore
> => {
    return CheckBOUserChangeRequestStore.create();
};
