import { AgentRequestDetails, UserStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';

export const AgentRequestDetailsModel = types.model({
    requestId: types.string,
    currentUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    updatedUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    makerComment: BOUserCommentModel
});

export const getAgentRequestDetailsModel = (
    requestDetails: AgentRequestDetails
): Instance<typeof AgentRequestDetailsModel> => {
    return AgentRequestDetailsModel.create({
        requestId: requestDetails.requestId.uuid,
        currentUserStatus: requestDetails.currentUserStatus,
        updatedUserStatus: requestDetails.updatedUserStatus,
        makerComment: getBOUserCommentModel(requestDetails.makerComment)
    });
};
