import { createUpdateBCNUserStateStore, UpdateBCNUserStateStore } from './UpdateBCNUserStateStore';
import {
    BCNUserCheckRequestStore,
    createBCNUserCheckRequestStore
} from './BCNUserCheckRequestStore';
import {
    BCNUserWithdrawRequestStore,
    createBCNUserWithdrawRequestStore
} from './BCNUserWithdrawRequestStore';
import { Instance, types } from 'mobx-state-tree';
import { BCNUserDetailsStore, createBCNUserDetailsStore } from './BCNUserDetailsStore';
import { BCNUserSearchStore, createBCNUserSearchStore } from './BCNUserSearchStore';
import {
    BCNUserViewRequestsStore,
    createBCNUserViewRequestsStore
} from './BCNUserViewRequestsStore';
import {
    BCNUserRequestHistoryStore,
    createBCNUserRequestHistoryStore
} from './BCNUserRequestHistoryStore';
import {
    BCNUserKycDocumentDetailsStore,
    createBCNUserKycDocumentDetailsStore
} from './BCNUserKycDocumentDetailsStore';

export const BCNUserStore = types.model({
    bcnUserSearchStore: BCNUserSearchStore,
    bcnUserDetailsStore: BCNUserDetailsStore,
    bcnUserWithdrawRequestStore: BCNUserWithdrawRequestStore,
    bcnUserCheckRequestStore: BCNUserCheckRequestStore,
    bcnUserViewRequestsStore: BCNUserViewRequestsStore,
    updateUserStateStore: UpdateBCNUserStateStore,
    bcnUserRequestHistoryStore: BCNUserRequestHistoryStore,
    bcnUserKycDocumentDetailsStore: BCNUserKycDocumentDetailsStore
});

export const createBCNUserStore = (): Instance<typeof BCNUserStore> => {
    return BCNUserStore.create({
        bcnUserSearchStore: createBCNUserSearchStore(),
        bcnUserDetailsStore: createBCNUserDetailsStore(),
        bcnUserWithdrawRequestStore: createBCNUserWithdrawRequestStore(),
        bcnUserCheckRequestStore: createBCNUserCheckRequestStore(),
        bcnUserViewRequestsStore: createBCNUserViewRequestsStore(),
        updateUserStateStore: createUpdateBCNUserStateStore(),
        bcnUserRequestHistoryStore: createBCNUserRequestHistoryStore(),
        bcnUserKycDocumentDetailsStore: createBCNUserKycDocumentDetailsStore()
    });
};
