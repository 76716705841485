import { Table, TableHeader, TableRowItems, usePalette } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionValidationRuleModel } from '../models/TransactionValidationRuleModel';
import {
    getCountryNameFromCbsId,
    getFormattedStringFromNumber,
    getRuleFormattedAmountStringWithoutCurrency
} from '../../common/utils/UIUtils';
import { useViewTransactionRulesStore } from '../store/hooks';

interface ViewCurrentTransactionValidationRulesProps {
    rules: Instance<typeof TransactionValidationRuleModel>[];
}

export const ViewCurrentTransactionValidationRules = ({
    rules
}: ViewCurrentTransactionValidationRulesProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useViewTransactionRulesStore();

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'slNo',
                name: t('rulesCSVHeaders.slNo'),
                width: '80px'
            },
            {
                id: 'monetaryLimit',
                name: t('rulesCSVHeaders.transactionRules.monetaryLimit'),
                width: '200px'
            },
            {
                id: 'periodicLimit',
                name: t('rulesCSVHeaders.transactionRules.periodicLimit'),
                width: '144px'
            },
            {
                id: 'timeWindowInSeconds',
                name: t('rulesCSVHeaders.transactionRules.timeWindowInSeconds'),
                width: '144px'
            },
            {
                id: 'cbsId',
                name: t('common.cbs'),
                width: '280px',
                ellipsisContent: true
            }
        ];
    };

    const getTableContents = async (): Promise<string | TableRowItems> => {
        return rules.map((rule, index) => {
            return [
                { data: index + 1 },
                { data: getRuleFormattedAmountStringWithoutCurrency(rule.monetaryLimit) },
                { data: getFormattedStringFromNumber(rule.periodicLimit) },
                { data: getFormattedStringFromNumber(rule.timeWindowInSeconds) },
                { data: getCountryNameFromCbsId(store.getCbsIdWithCountryList(), rule.cbsId) }
            ];
        });
    };

    return (
        <Table
            name="ViewCurrentTransactionValidationRulesTable"
            styleOverrides={{ background: palette.background['400'], width: 'calc(100vw - 304px)' }}
            headers={getHeaders()}
            onTableOptionsChange={getTableContents}
            viewOverrides={{ empty: { message: t('common.noCurrentRulesFound') } }}
        />
    );
};
