import { CheckMoveFundsOutOfYafikaRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckMoveFundsOutOfYafikaRequestRPCImpl extends CheckMoveFundsOutOfYafikaRequestRPC {
    execute(
        _request: CheckMoveFundsOutOfYafikaRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckMoveFundsOutOfYafikaRequestRPC.Response,
            CheckMoveFundsOutOfYafikaRequestRPC.Errors.Errors
        >
    > {
        const response = new CheckMoveFundsOutOfYafikaRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckMoveFundsOutOfYafikaRequestRPC.Response,
                CheckMoveFundsOutOfYafikaRequestRPC.Errors.Errors
            >
        >;
    }
}
