import { FeeDeterminationRule } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';

export const FeeDeterminationRuleModel = types.model({
    transactionType: types.string,
    feeType: types.string,
    amountRangeLow: types.maybeNull(types.number),
    amountRangeHigh: types.maybeNull(types.number),
    bankFeePercentage: types.maybeNull(types.number),
    counterPartyFeePercentage: types.maybeNull(types.number),
    agentCommissionPercentage: types.maybeNull(types.number),
    flatFee: types.maybeNull(types.number),
    percentage: types.maybeNull(types.number),
    lowerBound: types.maybeNull(types.number),
    upperBound: types.maybeNull(types.number),
    cbsId: types.string
});

export const getFeeDeterminationRuleModel = (
    feeDeterminationRule: FeeDeterminationRule
): Instance<typeof FeeDeterminationRuleModel> => {
    return FeeDeterminationRuleModel.create({
        transactionType: feeDeterminationRule.transactionType,
        feeType: feeDeterminationRule.feeType,
        amountRangeLow: feeDeterminationRule.amountRangeLow,
        amountRangeHigh: feeDeterminationRule.amountRangeHigh,
        bankFeePercentage: feeDeterminationRule.bankFeePercentage,
        counterPartyFeePercentage: feeDeterminationRule.counterPartyFeePercentage,
        agentCommissionPercentage: feeDeterminationRule.agentCommissionPercentage,
        flatFee: feeDeterminationRule.flatFee,
        percentage: feeDeterminationRule.percentage,
        lowerBound: feeDeterminationRule.lowerBound,
        upperBound: feeDeterminationRule.upperBound,
        cbsId: feeDeterminationRule.cbsId
    });
};
