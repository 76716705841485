import {
    TransactionValidationRule,
    GetCurrentTransactionValidationRuleDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCurrentTransactionValidationRuleDetailsRPCClientImpl extends GetCurrentTransactionValidationRuleDetailsRPC {
    execute(
        _request: GetCurrentTransactionValidationRuleDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCurrentTransactionValidationRuleDetailsRPC.Response,
            GetCurrentTransactionValidationRuleDetailsRPC.Errors.Errors
        >
    > {
        const transactionValidationRule = [
            new TransactionValidationRule(0, 3000, 2200, 'e751127b-df82-4bd2-894d-7096be687ed1'),
            new TransactionValidationRule(
                20000000,
                0,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                30000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                40000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            )
        ];

        const response = new GetCurrentTransactionValidationRuleDetailsRPC.Response(
            transactionValidationRule
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCurrentTransactionValidationRuleDetailsRPC.Response,
                GetCurrentTransactionValidationRuleDetailsRPC.Errors.Errors
            >
        >;
    }
}
