import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../assets/icons/Download';
import noFilePresent from '../../../../assets/no-file-present-placeHolder.svg';
import filePlaceHolder from '../../../../assets/file-placeHolder.svg';
import {
    getKYCDocumentFileSupportedTypes,
    getKYCDocumentImageSupportedTypes
} from '../../bcn-users/utils/UIUtils';
import { Button } from '../../../common/components/Button';

interface KYCDocumentCellActionSectionProps {
    fileUrl: string | null;
    fileType: string | null;
    onDownloadClick: () => void;
    onViewPhotoClick: () => void;
}

export function KYCDocumentCellActionSection({
    fileUrl,
    fileType,
    onDownloadClick,
    onViewPhotoClick
}: KYCDocumentCellActionSectionProps): React.ReactElement {
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{ margin: '8px 8px 8px 16px' }}
            spacing="12px">
            <img
                src={
                    fileType && getKYCDocumentImageSupportedTypes().includes(fileType)
                        ? fileUrl
                        : fileType && getKYCDocumentFileSupportedTypes().includes(fileType)
                        ? filePlaceHolder
                        : noFilePresent
                }
                style={{ width: '80px', height: '80px' }}
            />
            {fileType && getKYCDocumentImageSupportedTypes().includes(fileType) && (
                <Button
                    style={{ width: '94px' }}
                    name="viewPhoto"
                    size="small"
                    title={t('common.viewPhoto')}
                    variant="plain-color"
                    onClick={onViewPhotoClick}
                />
            )}
            {fileType && getKYCDocumentFileSupportedTypes().includes(fileType) && (
                <Button
                    style={{ width: '128px' }}
                    name="download"
                    size="small"
                    icon={<Download />}
                    iconPosition="left"
                    title={t('common.downloadFile')}
                    variant="plain-color"
                    onClick={onDownloadClick}
                />
            )}
        </Stack>
    );
}
