import React from 'react';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import { Outlet } from 'react-router-dom';
import { Stack, styled } from '@mui/material';

export const ErrorLayout = (): React.ReactElement => {
    const palette = usePalette();

    const RootContainer = styled(Stack)({
        backgroundColor: palette.background[300],
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
    });

    return (
        <RootContainer>
            <Outlet />
        </RootContainer>
    );
};
