import { flow, Instance, types } from 'mobx-state-tree';
import { RequestToMoveFundsOutOfYafikaRPC, Comment } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useRequestToMoveFundsOutOfYafikaRPCClient } from '../rpcs/RPC';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RequestToMoveFundsOutOfYafikaErrors {
    FundsAlreadyRefunded = 'FUNDS_ALREADY_REFUNDED',
    FundsAlreadyMoved = 'FUNDS_ALREADY_MOVED',
    InvalidTransactionId = 'INVALID_TRANSACTION_ID',
    UnsupportedTransactionType = 'UNSUPPORTED_TRANSACTION_TYPE',
    InvalidComment = 'INVALID_COMMENT'
}

export const RequestMoveFundsOutOfYafikaStore = types
    .model({
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<RequestToMoveFundsOutOfYafikaErrors>(
                    'RequestToMoveFundsOutOfYafikaErrors',
                    Object.values(RequestToMoveFundsOutOfYafikaErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setComment: (comment: string): void => {
            store.comment = comment;
        },
        resetStore: (): void => {
            store.comment = null;
            store.error = null;
        },
        requestToMoveFundsOutOfYafika: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const { comment } = store;
            if (!comment) return;

            try {
                const request = new RequestToMoveFundsOutOfYafikaRPC.Request(
                    new LeoUUID(transactionId),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result = yield useRequestToMoveFundsOutOfYafikaRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyMoved:
                            store.error = RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyMoved;
                            break;
                        case RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyRefunded:
                            store.error = RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyRefunded;
                            break;
                        case RequestToMoveFundsOutOfYafikaErrors.UnsupportedTransactionType:
                            store.error =
                                RequestToMoveFundsOutOfYafikaErrors.UnsupportedTransactionType;
                            break;
                        case RequestToMoveFundsOutOfYafikaErrors.InvalidTransactionId:
                            store.error = RequestToMoveFundsOutOfYafikaErrors.InvalidTransactionId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToMoveFundsOutOfYafikaRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToMoveFundsOutOfYafikaRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = RequestToMoveFundsOutOfYafikaErrors.InvalidTransactionId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = RequestToMoveFundsOutOfYafikaErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToMoveFundsOutOfYafikaRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestToMoveFundsOutOfYafikaRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createRequestMoveFundsOutOfYafikaStore = (): Instance<
    typeof RequestMoveFundsOutOfYafikaStore
> => {
    return RequestMoveFundsOutOfYafikaStore.create();
};
