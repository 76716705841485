import { Grid, Stack } from '@mui/material';
import {
    Button,
    DropdownInputField,
    DropdownItem,
    SearchField
} from '@surya-digital/leo-reactjs-ui';
import { clone, Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { getUserStatusOptions } from '../../common/utils/UIUtils';
import { BORoleModel } from '../models/BORoleModel';
import { BOUserFilterOptions } from '../store/BOUserSearchStore';
import { getBOUserSearchByOptions, getInitialFilter } from '../utils/UIUtils';
import { FilterByRolesComponent } from './FilterByRolesComponent';

interface BOUserFilterProps {
    filter: Instance<typeof BOUserFilterOptions>;
    setFilter: (newFilter: Instance<typeof BOUserFilterOptions>) => void;
    minimumSearchTextLength: number;
}

export const BOUserFilter = ({
    filter,
    setFilter,
    minimumSearchTextLength
}: BOUserFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getInitialFilter(t);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const searchByOptions = getBOUserSearchByOptions(t);
    const [searchValue, setSearchValue] = useState(filter.searchText);
    const [singleSelectSearchBy, setSingleSelectSearchBy] = useState(filter.searchBy);
    const [singleSelectUserStatus, setSingleSelectUserStatus] = useState(filter.selectedUserStatus);
    const [selectedBORoles, setSelectedBORoles] = useState<Instance<typeof BORoleModel>[]>(
        filter.selectedBORoles
    );
    const [disableSearch, setDisableSearch] = useState(true);

    useEffect(() => {
        setSearchValue(filter.searchText);
        setSingleSelectSearchBy(filter.searchBy);
        setSingleSelectUserStatus(filter.selectedUserStatus);
        setSelectedBORoles(filter.selectedBORoles);
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setDisableSearch(false);
        setSearchValue(inputValue);
    };

    const onSearchClear = (): void => {
        setSearchValue('');
        setDisableSearch(false);
    };

    const onSearchByDropdownChange = (selected: DropdownItem): void => {
        setSingleSelectSearchBy(selected.value);
        setDisableSearch(false);
        onSearchClear();
    };

    const onUserStatusDropdownChange = (selected: DropdownItem): void => {
        setDisableSearch(false);
        setSingleSelectUserStatus(selected.value);
    };

    const onBORolesChange = (updatedUserRoles: Instance<typeof BORoleModel>[]): void => {
        setDisableSearch(false);
        setSelectedBORoles(updatedUserRoles);
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchByDropdown"
                        value={singleSelectSearchBy}
                        onSelect={onSearchByDropdownChange}
                        label={t('common.searchBy')}
                        options={searchByOptions}
                    />
                </Grid>
                <Grid item xs={8}>
                    <SearchField
                        name="searchField"
                        value={searchValue}
                        isDisabled={false}
                        onTextChange={onSearchChange}
                        onClear={onSearchClear}
                        allowClearField={true}
                        placeholder={`${t('common.searchUsing')} ${
                            searchByOptions.find((item) => item.value === singleSelectSearchBy)!
                                .name
                        }`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="userStatusDropdown"
                        value={singleSelectUserStatus}
                        onSelect={onUserStatusDropdownChange}
                        label={t('common.selectUserStatus')}
                        options={getUserStatusOptions(t)}
                    />
                </Grid>
                <Grid item xs={8} display="flex" justifyContent="space-between" alignItems="center">
                    <FilterByRolesComponent
                        selectedBORoles={selectedBORoles}
                        onBORolesChange={onBORolesChange}
                    />
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                            name="plain-color"
                            variant="plain-color"
                            color="error"
                            size="medium"
                            title={t('common.reset')}
                            isDisabled={isInitialFilter}
                            onClick={(): void => {
                                setDisableSearch(true);
                                setFilter(initialFilter);
                            }}
                        />
                        <Button
                            name="filled"
                            variant="filled"
                            size="medium"
                            isDisabled={
                                (searchValue &&
                                    searchValue.trim().length < minimumSearchTextLength) ||
                                disableSearch ||
                                searchValue?.trim().length === 0
                            }
                            title={t('common.search')}
                            onClick={(): void => {
                                setDisableSearch(true);

                                setFilter(
                                    BOUserFilterOptions.create({
                                        searchBy: singleSelectSearchBy,
                                        searchText: searchValue,
                                        selectedUserStatus: singleSelectUserStatus,

                                        // When `selectedBORoles` is used directly to create `BOUserFilterOptions` model.
                                        // An error was thrown with the below message.
                                        // Cannot add an object to a state tree if it is already part of the same or another state tree.
                                        // Tried to assign an object to '/selectedBORoles/0', but it lives already at '/homeStore/boUsers/boRoleStore/workflowDependentRoles/0/roles/0'
                                        // It appears that the node's reference is creating a cyclic dependencies in the tree and hence the error was thrown.
                                        // We can use `clone` method from mobx-state-tree, which creates a copy of Node identifiers with new reference.
                                        selectedBORoles: selectedBORoles.map((role) => clone(role))
                                    })
                                );
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
