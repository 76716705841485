import React, { useState } from 'react';
import {
    DialogContent,
    styled,
    Dialog as MuiDialog,
    Typography,
    IconButton,
    DialogActions,
    DialogTitle
} from '@mui/material';
import { Button } from './Button';
import { DialogProps, Icon, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';

const TitleContainer = styled(DialogTitle)(() => ({
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledIconButton = styled(IconButton)(() => ({
    height: '24px',
    width: '24px',
    padding: '0px'
}));

/**
 * Creates a Dialog
 * @param open - If true, component is shown
 * @param onClose - Callback fired when the component requests to be closed
 * @param title - Title of the dialog
 * @param children - Body of the dialog
 * @param primaryButtonText - Title of the primary button
 * @param secondaryButtonText - Title of the secondary button
 * @param onPrimaryButtonClick - Callback fired when the primary button is clicked
 * @param onSecondaryButtonClick - Callback fired when the secondary button is clicked
 * @param isPrimaryButtonDisabled - Determines if the primary button is disabled or not
 * @param isSecondaryButtonDisabled - Determines if the secondary button is disabled or not
 * @param primaryButtonColor - Prop of type React.CSSProperties if further customization of primary button is required
 * @param disableBackdropClick - If true, backdrop click is disabled
 * @param isCloseIconPresent - If true, close icon is shown
 * @param style - Prop of type React.CSSProperties if further customization of dialog is required
 * @param dialogWidth - The width of the dialog.
 * @returns Dialog component
 */

export function Dialog({
    open,
    onClose,
    title,
    children,
    contentPadding = '20px',
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    isPrimaryButtonDisabled,
    isSecondaryButtonDisabled,
    primaryButtonColor,
    disableBackdropClick,
    isCloseIconPresent,
    style,
    dialogWidth = '560px'
}: DialogProps): React.ReactElement {
    const typeCastedChildren = children as React.ReactElement;
    const palette = usePalette();
    const typography = useTypography();
    const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false);
    const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] = useState(false);

    const FooterContainer = styled(DialogActions)(() => ({
        padding: '20px',
        display: 'flex',
        // When the children is present, DialogContent component adds top and bottom dividers.
        // Hence we need to add border-top only when children is not provided.
        borderTop: children ? 'none' : `1px solid ${palette.outline[200]}`
    }));

    return (
        <MuiDialog
            fullWidth
            open={open}
            onClose={(): void => {
                if (!disableBackdropClick && onClose) {
                    onClose();
                }
            }}
            sx={{
                '& .MuiPaper-root': {
                    width: dialogWidth,
                    maxHeight: '536px',
                    borderRadius: '8px'
                },
                ...style
            }}>
            {title && (
                <TitleContainer>
                    <Typography sx={{ ...typography.sh3, paddingRight: '20px' }}>
                        {title}
                    </Typography>
                    {isCloseIconPresent && onClose && (
                        <StyledIconButton onClick={(): void => onClose()}>
                            <Icon
                                type="cross"
                                color={palette.outline[400]}
                                width={24}
                                height={24}
                            />
                        </StyledIconButton>
                    )}
                </TitleContainer>
            )}
            {typeCastedChildren && (
                <DialogContent dividers sx={{ padding: contentPadding }}>
                    {typeCastedChildren}
                </DialogContent>
            )}
            {(secondaryButtonText || primaryButtonText) && (
                <FooterContainer>
                    {secondaryButtonText && (
                        <Button
                            name="secondary-button"
                            variant="outlined-neutral"
                            size="small"
                            title={secondaryButtonText}
                            isDisabled={isSecondaryButtonDisabled}
                            isLoading={isSecondaryButtonLoading}
                            onClick={async (): Promise<void> => {
                                if (onSecondaryButtonClick) {
                                    setIsSecondaryButtonLoading(true);
                                    await onSecondaryButtonClick();
                                    setIsSecondaryButtonLoading(false);
                                }
                            }}
                            style={{
                                marginRight: '3px'
                            }}
                        />
                    )}
                    {primaryButtonText && (
                        <Button
                            name="primary-button"
                            variant="filled"
                            size="small"
                            title={primaryButtonText}
                            isLoading={isPrimaryButtonLoading}
                            isDisabled={isPrimaryButtonDisabled}
                            color={primaryButtonColor}
                            onClick={async (): Promise<void> => {
                                if (onPrimaryButtonClick) {
                                    setIsPrimaryButtonLoading(true);
                                    await onPrimaryButtonClick();
                                    setIsPrimaryButtonLoading(false);
                                }
                            }}
                        />
                    )}
                </FooterContainer>
            )}
        </MuiDialog>
    );
}
