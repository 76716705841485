import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import {
    getFormattedPhoneNumber,
    getGenderTranslation,
    getUserStatus
} from '../../common/utils/UIUtils';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { AgentBasicDetailsModel } from '../models/AgentBasicDetailsModel';
import { PreviewPhotoDialog } from '../../common/components/dialog/PreviewPhotoDialog';
import { Stack } from '@mui/material';

export interface AgentBasicDetailsSectionProp {
    agentDetails: Instance<typeof AgentBasicDetailsModel>;
    agentId: string;
}

export const AgentBasicDetailsSection = ({
    agentDetails,
    agentId
}: AgentBasicDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();
    const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);

    const agentDetailSectionCells: { label: string; cell: SectionRowCellType }[] = [
        {
            label: t('common.agentPhoto'),
            cell: {
                imageUrl: agentDetails.profilePhoto ?? '',
                type: SectionRowCellEnum.ProfileImage
            }
        },
        {
            label: t('common.firstName'),
            cell: { text: agentDetails.firstName, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.lastName'),
            cell: { text: agentDetails.lastName ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.otherNames'),
            cell: { text: agentDetails.otherNames ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.userId'),
            cell: { text: agentDetails.userId, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('agencyBanking.agentId'),
            cell: { text: agentId, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('agencyBanking.agentStatus'),
            cell: {
                status: getUserStatus(agentDetails.status),
                type: SectionRowCellEnum.UserStatusChip
            }
        },
        {
            label: t('common.mobileNumber'),
            cell: {
                text: getFormattedPhoneNumber(agentDetails.phoneNumber),
                type: SectionRowCellEnum.Unstyled
            }
        },
        {
            label: t('common.emailId'),
            cell: {
                text: agentDetails.emailId ?? '-',
                type: SectionRowCellEnum.Unstyled,
                showEllipsis: true
            }
        },
        {
            label: t('common.dateOfBirth'),
            cell: { text: agentDetails.dateOfBirth, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.gender'),
            cell: {
                text: getGenderTranslation(t, agentDetails.gender),
                type: SectionRowCellEnum.Unstyled
            }
        },
        {
            label: t('agencyBanking.shopName'),
            cell: { text: agentDetails.shopName, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('agencyBanking.shopPhoto'),
            cell: {
                imageUrl: agentDetails.shopPhoto,
                type: SectionRowCellEnum.Image,
                onViewImageClick: (): void => {
                    setPreviewImageUrl(agentDetails.shopPhoto);
                },
                imageWidth: '80px',
                imageHeight: '80px'
            }
        },
        {
            label: t('common.nationalIdNo'),
            cell: { text: agentDetails.nationalId, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('agencyBanking.walletName'),
            cell: { text: agentDetails.walletName ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.walletId'),
            cell: { text: agentDetails.walletId, type: SectionRowCellEnum.Copy }
        }
    ];

    const showImagePreview = (photoUrl: string): React.ReactElement => {
        return (
            <PreviewPhotoDialog
                isDialogOpen={Boolean(previewImageUrl)}
                onClose={function (): void {
                    setPreviewImageUrl(null);
                }}
                photoUrl={photoUrl}
            />
        );
    };

    const getAgentDetailCells = (): React.ReactElement[] => {
        return agentDetailSectionCells.map(({ label, cell }) => (
            <SectionRowComponentWithLabel key={label} label={label} cell={cell} />
        ));
    };

    return (
        <Stack key="agentDetails">
            <Section
                title={t('common.basicDetails')}
                allowViewChange={false}
                content={getAgentDetailCells()}
            />
            {previewImageUrl && showImagePreview(previewImageUrl)}
        </Stack>
    );
};
