import React from 'react';
import { CircularProgress, Button as MuiButton, Typography, Stack } from '@mui/material';
import {
    ButtonProps,
    TypographyProperties,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';

export function Button({
    name,
    size,
    title,
    iconPosition,
    icon,
    variant,
    color = 'primary',
    isLoading,
    isDisabled,
    onClick,
    style
}: ButtonProps): JSX.Element {
    const typeCastedIcon = icon as React.ReactElement<React.SVGProps<SVGSVGElement>>;
    const palette = usePalette();
    const typography = useTypography();

    function buttonHeight(): number | undefined {
        switch (size) {
            case 'large':
                return 48;
            case 'medium':
                return 40;
            case 'small':
                return 32;
        }
    }

    function buttonVariant(): 'outlined' | 'text' | 'contained' | undefined {
        switch (variant) {
            case 'filled':
                return 'contained';
            case 'outlined-neutral':
            case 'outlined-color':
                return 'outlined';
            case 'plain-neutral':
            case 'plain-color':
                return 'text';
        }
    }

    function buttonBackgroundColor(): string | undefined {
        if (variant === 'filled') {
            if (isDisabled) {
                return palette.background[100];
            } else if (color === 'primary') {
                return palette.primary[300];
            } else if (color === 'error') {
                return palette.error[300];
            }
        }
        return 'white';
    }

    function buttonBorderColor(): string | undefined {
        if (isDisabled) {
            if (variant === 'outlined-color' || variant === 'outlined-neutral') {
                return palette.outline[300];
            }
        } else {
            if (variant === 'outlined-color') {
                if (color === 'primary') {
                    return palette.primary[200];
                } else if (color === 'error') {
                    return palette.error[200];
                }
            } else if (variant === 'outlined-neutral') {
                return palette.outline[300];
            }
        }
    }

    function buttonColor(): string | undefined {
        if (isDisabled) {
            return palette.label[200];
        } else {
            if (variant === 'outlined-neutral' || variant === 'plain-neutral') {
                return palette.label[300];
            } else if (variant === 'outlined-color' || variant === 'plain-color') {
                if (color === 'primary') {
                    return palette.primary[300];
                } else if (color === 'error') {
                    return palette.error[300];
                }
            }
        }
    }

    function buttonHoverColor(): string | undefined {
        if (isDisabled) {
            return undefined;
        } else {
            if (color === 'primary') {
                if (variant === 'outlined-neutral' || variant === 'plain-neutral') {
                    return palette.background[300];
                } else if (variant === 'outlined-color' || variant === 'plain-color') {
                    return palette.primary[100];
                }
                return palette.primary[400];
            } else if (color === 'error') {
                if (variant === 'outlined-neutral' || variant === 'plain-neutral') {
                    return palette.background[300];
                } else if (variant === 'outlined-color' || variant === 'plain-color') {
                    return palette.error[100];
                }
                return palette.error[400];
            }
        }
    }

    function iconColor(): string | undefined {
        if (isDisabled) {
            return palette.label[200];
        } else {
            switch (variant) {
                case 'filled':
                    return 'white';
                case 'outlined-neutral':
                case 'plain-neutral':
                    return palette.label[300];
                case 'outlined-color':
                case 'plain-color':
                    if (color === 'primary') {
                        return palette.primary[300];
                    } else if (color === 'error') {
                        return palette.error[300];
                    }
            }
        }
    }

    function iconSize(): { height: number; width: number } {
        switch (size) {
            case 'large':
                return { height: 24, width: 24 };
            case 'medium':
                return { height: 20, width: 20 };
            case 'small':
                return { height: 16, width: 16 };
        }
    }

    function buttonStartIcon(): React.ReactElement | undefined {
        if (!isLoading && iconPosition === 'left' && typeCastedIcon) {
            const buttonIcon = React.cloneElement(typeCastedIcon, {
                color: iconColor(),
                width: `${iconSize().width}px`,
                height: `${iconSize().height}px`
            });
            return buttonIcon;
        }
    }

    function buttonEndIcon(): React.ReactNode | undefined {
        if (!isLoading && iconPosition === 'right' && typeCastedIcon) {
            const buttonIcon = React.cloneElement(typeCastedIcon, {
                color: iconColor(),
                width: `${iconSize().width}px`,
                height: `${iconSize().height}px`
            });
            return buttonIcon;
        }
    }

    function buttonTitle(): string | undefined {
        if (!isLoading && title) {
            return title;
        }
    }

    function buttonTypographyProperty(): TypographyProperties {
        switch (size) {
            case 'large':
                return typography.button1;
            case 'medium':
                return typography.button2;
            case 'small':
                return typography.button3;
        }
    }

    function getBorderRadius(): string {
        switch (size) {
            case 'large':
                return '12px';
            case 'medium':
                return '10px';
            case 'small':
                return '8px';
        }
    }

    function buttonLoadingIndicatorColor(): string | undefined {
        if (isDisabled) {
            return palette.label[200];
        } else {
            switch (variant) {
                case 'filled':
                    return 'white';
                case 'outlined-color':
                case 'plain-color':
                    if (color === 'error') {
                        return palette.error[400];
                    } else if (color === 'primary') {
                        return palette.primary[400];
                    }
                    return undefined;
                case 'outlined-neutral':
                case 'plain-neutral':
                    return palette.label[300];
            }
        }
    }

    function getPadding(): string {
        switch (size) {
            case 'large':
                if (icon && !title) {
                    return '12px';
                }
                if (icon) {
                    return iconPosition === 'left' ? '12px 24px 12px 18px' : '12px 18px 12px 24px';
                }
                return '12px 24px';
            case 'medium':
                if (icon && !title) {
                    return '10px';
                }
                if (icon) {
                    return iconPosition === 'left' ? '10px 20px 10px 16px' : '10px 16px 10px 20px';
                }
                return '10px 20px';
            case 'small':
                if (icon && !title) {
                    return '8px';
                }
                if (icon) {
                    return iconPosition === 'left' ? '8px 16px 8px 14px' : '8px 14px 8px 16px';
                }
                return '8px 16px';
        }
    }

    function getGapBetweenIconLabel(): string {
        switch (size) {
            case 'large':
                return '8px';
            case 'medium':
                return '6px';
            case 'small':
                return '4px';
        }
    }

    return (
        <>
            <MuiButton
                disableElevation
                name={name}
                disabled={isDisabled}
                style={{
                    ...style,
                    // The typography size in browser doesn't match with the size in the figma.
                    // Example: For the buttonTitle `Download File`, In figma it takes a width of 78px. In the browser it takes 78.63px.
                    // Hence there is an overflow of 0.63px, when the width is set as per figma.
                    // To resolve this issue, we have added 1px buffer to width, when the width is specified in the style.
                    width: style?.width && `calc( ${style.width} + 1px)`
                }}
                sx={{
                    height: `${buttonHeight()}px`,
                    minWidth: '0px',
                    maxWidth: '360px',
                    backgroundColor: buttonBackgroundColor(),
                    borderRadius: getBorderRadius(),
                    padding: getPadding(),
                    border: 0,
                    outline: `1px solid ${buttonBorderColor()}`,
                    color: buttonColor(),
                    position: 'relative',
                    '&:hover': {
                        background: buttonHoverColor(),
                        border: 0,
                        outline: `1px solid ${buttonBorderColor()}`
                    },
                    '&.Mui-disabled': {
                        backgroundColor: buttonBackgroundColor(),
                        border: 0,
                        outline: `1px solid ${buttonBorderColor()}`,
                        color: buttonColor()
                    }
                }}
                variant={buttonVariant()}
                onClick={(): void => {
                    onClick();
                }}
                onKeyDown={(event): void => {
                    if (event.code === 'Enter' || event.code === 'Space') {
                        event.preventDefault();
                    }
                }}>
                {isLoading ? (
                    <CircularProgress
                        sx={{
                            color: buttonLoadingIndicatorColor()
                        }}
                        size={iconSize().width}
                    />
                ) : (
                    <>
                        {icon && iconPosition === 'left' && (
                            <Stack width={iconSize().width} height={iconSize().height}>
                                {buttonStartIcon()}
                            </Stack>
                        )}
                        {title && (
                            <Typography
                                sx={{
                                    textTransform: 'none',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'left',
                                    ...buttonTypographyProperty(),
                                    marginRight:
                                        icon && iconPosition === 'right'
                                            ? getGapBetweenIconLabel()
                                            : '0px',
                                    marginLeft:
                                        icon && iconPosition === 'left'
                                            ? getGapBetweenIconLabel()
                                            : '0px'
                                }}>
                                {buttonTitle()}
                            </Typography>
                        )}
                        {icon && iconPosition === 'right' && (
                            <Stack width={iconSize().width} height={iconSize().height}>
                                {buttonEndIcon()}
                            </Stack>
                        )}
                    </>
                )}
            </MuiButton>
        </>
    );
}
