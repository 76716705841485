import { Chip, styled } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface ChipGroupProps {
    labels: string[];
}

export const ChipGroup = ({ labels }: ChipGroupProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();

    const StyledChip = styled(Chip)({
        color: palette.primary[300],
        ...typography.body2,
        padding: '4px 12px',
        borderRadius: '250px',
        background: palette.primary[100],
        '.MuiChip-label': { padding: 0 }
    });

    const renderChips = (): React.ReactElement[] => {
        return labels.map((label) => {
            return <StyledChip key={label} label={label} />;
        });
    };

    return <>{renderChips()}</>;
};
