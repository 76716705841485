import { ColorPalette as LeoColorPalette, ColorPaletteShades } from '@surya-digital/leo-reactjs-ui';

const primaryPaletteShades: ColorPaletteShades = {
    100: '#F4F1FE',
    200: '#BEA9F9',
    300: '#6A39F1',
    400: '#320B9E',
    contrastText: '#FFFFFF'
};

const secondaryPaletteShades = {
    100: '#C4C4C4',
    200: '#C4C4C4',
    300: '#C4C4C4',
    400: '#C4C4C4',
    contrastText: '#C4C4C4'
};

const accentPaletteShades = {
    100: '#C4C4C4',
    200: '#C4C4C4',
    300: '#C4C4C4',
    400: '#C4C4C4',
    contrastText: '#C4C4C4'
};

const successPaletteShades: ColorPaletteShades = {
    100: '#EEFBF2',
    200: '#9CE5AE',
    300: '#2DAD4E',
    400: '#207D37',
    contrastText: '#FFFFFF'
};

const informativePaletteShades: ColorPaletteShades = {
    100: '#F0F8FC',
    200: '#A3D5EB',
    300: '#2C99CC',
    400: '#1F6F93',
    contrastText: '#FFFFFF'
};

const warningPaletteShades: ColorPaletteShades = {
    100: '#FFF7EB',
    200: '#FFCD87',
    300: '#E58600',
    400: '#A46000',
    contrastText: '#FFFFFF'
};

const errorPaletteShades: ColorPaletteShades = {
    100: '#FFF1F0',
    200: '#FFAAA5',
    300: '#FF3B30',
    400: '#D70B00',
    contrastText: '#FFFFFF'
};

const backgroundPaletteShades: ColorPaletteShades = {
    100: '#EEEEF3',
    200: '#F2F2F7',
    300: '#F6F6FB',
    400: '#FFFFFF',
    contrastText: '#000000'
};

const labelPaletteShades: ColorPaletteShades = {
    100: '#C5C5C7',
    200: '#8A8A8E',
    300: '#36383A',
    400: '#000000',
    contrastText: '#FFFFFF'
};

const outlinePaletteShades: ColorPaletteShades = {
    100: '#F2F2F7',
    200: '#E5E5EA',
    300: '#D1D1D6',
    400: '#8E8E93',
    contrastText: '#000000'
};

export const ColorPalette: LeoColorPalette = {
    primary: primaryPaletteShades,
    secondary: secondaryPaletteShades,
    accent: accentPaletteShades,
    success: successPaletteShades,
    informative: informativePaletteShades,
    warning: warningPaletteShades,
    error: errorPaletteShades,
    background: backgroundPaletteShades,
    label: labelPaletteShades,
    outline: outlinePaletteShades
};
