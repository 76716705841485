import { Stack, Typography } from '@mui/material';
import { Card, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsRowComponent } from '../../common/components/DetailsRowComponent';
import { getFormattedPhoneNumber } from '../../common/utils/UIUtils';
import { AccountType } from '../store/ValidateAccountDetailsStore';
import { UserPhotoRow } from './UserPhotoRow';

interface AccountDetailsCardProp {
    title: string;
    accountType: AccountType;
    country?: string;
    systemAccountName?: string;
    userPhotoUrl?: string;
    userName?: string;
    mobileNumber?: string;
    accountId?: string;
    currency: string;
}

export const AccountDetailsCard = ({
    title,
    accountType,
    country,
    systemAccountName,
    userPhotoUrl,
    userName,
    mobileNumber,
    accountId,
    currency
}: AccountDetailsCardProp): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Stack spacing="8px">
            <Typography sx={{ ...typography.small1, color: palette.label[300] }}>
                {title}
            </Typography>
            <Card
                style={{
                    padding: '16px',
                    width: '320px',
                    height: '408px',
                    backgroundColor: palette.background[300]
                }}>
                <DetailsRowComponent
                    label={t('common.accountType')}
                    text={
                        accountType === AccountType.System
                            ? t('genericFundsTransfer.systemAccount')
                            : t('genericFundsTransfer.userAccount')
                    }
                    sx={{ marginBottom: '8px' }}
                />
                {country && (
                    <DetailsRowComponent
                        label={t('common.country')}
                        text={country}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
                {systemAccountName && (
                    <DetailsRowComponent
                        label={t('genericFundsTransfer.systemAccountName')}
                        text={systemAccountName}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
                {accountType === AccountType.User && (
                    <UserPhotoRow label={t('common.userPhoto')} photoUrl={userPhotoUrl} />
                )}
                {userName && (
                    <DetailsRowComponent
                        label={t('common.userName')}
                        text={userName}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
                {mobileNumber && (
                    <DetailsRowComponent
                        label={t('common.userMobileNumber')}
                        text={getFormattedPhoneNumber(mobileNumber)}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
                {accountId && (
                    <DetailsRowComponent
                        label={t('common.userAccountId')}
                        text={accountId}
                        sx={{ marginBottom: '8px' }}
                    />
                )}
                <DetailsRowComponent label={t('common.currency')} text={currency} />
            </Card>
        </Stack>
    );
};
