import {
    CheckMoveFundsOutOfYafikaRequestRPC,
    CheckerResponse,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoUUID, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { Status } from '../../common/enums/StatusEnum';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { useCheckMoveFundsOutOfYafikaRequestRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum CheckMoveFundsOutOfYafikaRequestStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    FundsAlreadyRefunded = 'FUNDS_ALREADY_REFUNDED',
    FailedToMoveFundsOutOfYafika = 'FAILED_TO_MOVE_FUNDS_OUT_OF_YAFIKA',
    UnsupportedTransactionType = 'UNSUPPORTED_TRANSACTION_TYPE',
    TransactionMovedToUnclaimedFunds = 'TRANSACTION_MOVED_TO_UNCALIMED_FUNDS',
    InvalidComment = 'INVALID_COMMENT'
}

const getStoreError = ({
    code
}: CheckMoveFundsOutOfYafikaRequestRPC.Errors.Errors):
    | CheckMoveFundsOutOfYafikaRequestStoreErrors
    | undefined => {
    switch (code) {
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyChecked:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyChecked;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.UnsupportedTransactionType:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.UnsupportedTransactionType;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.FailedToMoveFundsOutOfYafika:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.FailedToMoveFundsOutOfYafika;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.FundsAlreadyRefunded:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.FundsAlreadyRefunded;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyDiscarded:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyDiscarded;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyWithdrawn:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyWithdrawn;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.CannotCheckSelfRequest:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.CannotCheckSelfRequest;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidRequestId:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidRequestId;
        case CheckMoveFundsOutOfYafikaRequestStoreErrors.TransactionMovedToUnclaimedFunds:
            return CheckMoveFundsOutOfYafikaRequestStoreErrors.TransactionMovedToUnclaimedFunds;
    }
};

export const CheckMoveFundsOutOfYafikaRequestStore = types
    .model({
        comment: types.maybeNull(types.string),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckMoveFundsOutOfYafikaRequestStoreErrors>(
                    'CheckMoveFundsOutOfYafikaRequestStoreErrors',
                    Object.values(CheckMoveFundsOutOfYafikaRequestStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = null;
        },
        removeError(): void {
            store.error = null;
        },
        checkPendingRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.comment && requestId && store.status) {
                    const apiClient = getAPIClient(store);
                    const request = new CheckMoveFundsOutOfYafikaRequestRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const result: LeoRPCResult<
                        CheckMoveFundsOutOfYafikaRequestRPC.Response,
                        CheckMoveFundsOutOfYafikaRequestRPC.Errors.Errors
                    > = yield useCheckMoveFundsOutOfYafikaRequestRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        const errorCode = getStoreError(error);
                        if (errorCode) store.error = errorCode;
                        else {
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckMoveFundsOutOfYafikaRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckMoveFundsOutOfYafikaRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `status, comment or requestId are not present in CheckMoveFundsOutOfYafikaRequestStore`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error =
                                CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidRequestId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error =
                                CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckMoveFundsOutOfYafikaRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                            break;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckMoveFundsOutOfYafikaRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createCheckMoveFundsOutOfYafikaRequestStore = (): Instance<
    typeof CheckMoveFundsOutOfYafikaRequestStore
> => {
    return CheckMoveFundsOutOfYafikaRequestStore.create();
};
