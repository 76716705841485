import {
    GetReportDetailsRPC,
    GetReportDetailsRPCClientImpl,
    GetReportFileRPC,
    GetReportFileRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';
import { MockGetReportDetailsRPCImpl } from './MockGetReportDetailsRPCImpl';
import { MockGetReportFileRPCImpl } from './MockGetReportFileRPCImpl';

const MOCK = false;

export const useGetReportDetailRPCClient = (apiClient: APIClient): GetReportDetailsRPC => {
    if (MOCK) {
        return new MockGetReportDetailsRPCImpl();
    } else {
        return new GetReportDetailsRPCClientImpl(apiClient);
    }
};

export const useGetReportFileRPCClient = (apiClient: APIClient): GetReportFileRPC => {
    if (MOCK) {
        return new MockGetReportFileRPCImpl();
    } else {
        return new GetReportFileRPCClientImpl(apiClient);
    }
};
