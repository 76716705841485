import {
    Comment,
    UserStatusTransition,
    RequestToArchiveAgentRPC,
    RequestToReactivateOrDeactivateAgentRPC,
    RequestToArchiveAgentWithBalanceRPC,
    AgentTransactionSearchBy
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import {
    useRequestToArchiveAgentRPCClient,
    useRequestToArchiveAgentWithBalanceRPCClient,
    useRequestToReactivateOrDeactivateAgentRPCClient
} from '../rpcs/RPC';
import { AgencyBankingStore } from './AgencyBankingStore';
import { AgentTransactionSearchFilterOptions } from './AgentTransactionSearchStore';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum UpdateAgentStateStoreErrors {
    InvalidComment = 'INVALID_COMMENT',
    InvalidAgentId = 'INVALID_AGENT_ID',
    AgentAlreadyArchived = 'AGENT_ALREADY_ARCHIVED',
    CannotArchiveActiveAgent = 'CANNOT_ARCHIVE_ACTIVE_AGENT',
    RequestAlreadyRaised = 'REQUEST_ALREADY_RAISED',
    AgentAlreadyActivated = 'AGENT_ALREADY_ACTIVATED',
    AgentAlreadyDeactivated = 'AGENT_ALREADY_DEACTIVATED',
    BcnUserAccountDisabled = 'BCN_USER_ACCOUNT_DISABLED'
}

// To convert error.code from string type to enum type
const getStoreError = (
    error:
        | RequestToReactivateOrDeactivateAgentRPC.Errors.Errors
        | RequestToArchiveAgentRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): UpdateAgentStateStoreErrors | NetworkingError => {
    switch (error.code) {
        case UpdateAgentStateStoreErrors.InvalidAgentId:
            return UpdateAgentStateStoreErrors.InvalidAgentId;
        case UpdateAgentStateStoreErrors.AgentAlreadyArchived:
            return UpdateAgentStateStoreErrors.AgentAlreadyArchived;
        case UpdateAgentStateStoreErrors.RequestAlreadyRaised:
            return UpdateAgentStateStoreErrors.RequestAlreadyRaised;
        case UpdateAgentStateStoreErrors.AgentAlreadyActivated:
            return UpdateAgentStateStoreErrors.AgentAlreadyActivated;
        case UpdateAgentStateStoreErrors.AgentAlreadyDeactivated:
            return UpdateAgentStateStoreErrors.AgentAlreadyDeactivated;
        case UpdateAgentStateStoreErrors.CannotArchiveActiveAgent:
            return UpdateAgentStateStoreErrors.CannotArchiveActiveAgent;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in UpdateAgentStateStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: unknown,
    loggerStore: Instance<typeof LoggerStore>
): UpdateAgentStateStoreErrors | NetworkingError => {
    if (error instanceof Error) {
        switch (error.name) {
            case UpdateAgentStateStoreErrors.InvalidComment:
                return UpdateAgentStateStoreErrors.InvalidComment;
            case LeoErrors.InvalidLeoUUIDError:
                return UpdateAgentStateStoreErrors.InvalidAgentId;
            default:
                loggerStore.error(`Unhandled error: ${error} occurred in UpdateAgentStateStore`);
                return NetworkingError.InternalError;
        }
    } else {
        loggerStore.error(`Unknown error: ${error} occurred in UpdateAgentStateStore`);
        return NetworkingError.InternalError;
    }
};

export const UpdateAgentStateStore = types
    .model({
        comment: types.maybeNull(types.string),
        balanceAmount: types.maybeNull(AmountModel),
        bcnUserId: types.maybeNull(types.string),
        pendingTransactionsFilter: types.maybeNull(AgentTransactionSearchFilterOptions),
        error: types.maybeNull(
            types.union(
                types.enumeration<UpdateAgentStateStoreErrors>(
                    'UpdateAgentStateStoreErrors',
                    Object.values(UpdateAgentStateStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = null;
        },
        removeError(): void {
            store.error = null;
            store.bcnUserId = null;
        },
        removeBalanceAmount(): void {
            store.balanceAmount = null;
        },
        removePendingTransactionsFilter(): void {
            store.pendingTransactionsFilter = null;
        },
        setTransactionsFilter(): void {
            const loggerStore = getLoggerStore(store);
            const agencyBankingStore = getParent<typeof AgencyBankingStore>(store);
            if (store.pendingTransactionsFilter) {
                agencyBankingStore.agentTransactionSearchStore.updateFilterOptions(
                    store.pendingTransactionsFilter
                );
                store.error = null;
                store.pendingTransactionsFilter = null;
            } else {
                loggerStore.debug(
                    `store.pendingTransactionsFilter cannot be null as setTransactionsFilter is called only after store.pendingTransactionsFilter is set.`
                );
                store.error = NetworkingError.InternalError;
            }
        },
        raiseRequestToActivateAgent: flow(function* (agentId: string) {
            const loggerStore = getLoggerStore(store);
            if (store.comment) {
                try {
                    const request = new RequestToReactivateOrDeactivateAgentRPC.Request(
                        new LeoUUID(agentId),
                        UserStatusTransition.UserStatusTransition.REACTIVATE,
                        new Comment(store.comment)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RequestToReactivateOrDeactivateAgentRPC.Response,
                        RequestToReactivateOrDeactivateAgentRPC.Errors.Errors
                    > = yield useRequestToReactivateOrDeactivateAgentRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (
                            error instanceof
                            RequestToReactivateOrDeactivateAgentRPC.Errors.BcnUserAccountDisabled
                        ) {
                            store.bcnUserId = error.bcnUserId.uuid;
                            store.error = UpdateAgentStateStoreErrors.BcnUserAccountDisabled;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RequestToReactivateOrDeactivateAgentRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = getLeoError(error, loggerStore);
                } finally {
                    store.comment = null;
                }
                store.comment = null;
            } else {
                loggerStore.debug(
                    `store.comment cannot be null as raiseRequestToActivateAgent is called only after store.comment is set.`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        raiseRequestToDeactivateAgent: flow(function* (agentId: string) {
            const loggerStore = getLoggerStore(store);
            if (store.comment) {
                try {
                    const request = new RequestToReactivateOrDeactivateAgentRPC.Request(
                        new LeoUUID(agentId),
                        UserStatusTransition.UserStatusTransition.DEACTIVATE,
                        new Comment(store.comment)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RequestToReactivateOrDeactivateAgentRPC.Response,
                        RequestToReactivateOrDeactivateAgentRPC.Errors.Errors
                    > = yield useRequestToReactivateOrDeactivateAgentRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (
                            error instanceof
                            RequestToReactivateOrDeactivateAgentRPC.Errors.BcnUserAccountDisabled
                        ) {
                            store.bcnUserId = error.bcnUserId.uuid;
                            store.error = UpdateAgentStateStoreErrors.BcnUserAccountDisabled;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RequestToReactivateOrDeactivateAgentRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = getLeoError(error, loggerStore);
                } finally {
                    store.comment = null;
                }
                store.comment = null;
            } else {
                loggerStore.debug(
                    `store.comment cannot be null as raiseRequestToDeactivateAgent is called only after store.comment is set.`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        raiseRequestToArchiveAgent: flow(function* (agentId: string) {
            const loggerStore = getLoggerStore(store);
            if (store.comment) {
                try {
                    const request = new RequestToArchiveAgentRPC.Request(
                        new LeoUUID(agentId),
                        new Comment(store.comment)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RequestToArchiveAgentRPC.Response,
                        RequestToArchiveAgentRPC.Errors.Errors
                    > = yield useRequestToArchiveAgentRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        store.comment = null;
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        if (
                            error instanceof
                            RequestToArchiveAgentRPC.Errors.NonZeroCommissionBalance
                        ) {
                            store.balanceAmount = getAmountModel(error.commissionBalance);
                        } else if (
                            error instanceof
                            RequestToArchiveAgentRPC.Errors.PendingTransactionsFound
                        ) {
                            store.pendingTransactionsFilter =
                                AgentTransactionSearchFilterOptions.create({
                                    searchBy:
                                        AgentTransactionSearchBy.AgentTransactionSearchBy
                                            .AGENT_MOBILE_NUMBER,
                                    searchText: error.phoneNumber.phoneNumber,
                                    transactionStatus: error.transactionStatus,
                                    transactionType: error.transactionType
                                });
                            store.comment = null;
                        } else {
                            store.error = getStoreError(error, loggerStore);
                            store.comment = null;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RequestToArchiveAgentRPC with result: ${result}`
                        );
                        store.comment = null;
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = getLeoError(error, loggerStore);
                    store.comment = null;
                }
            } else {
                loggerStore.debug(
                    `store.comment cannot be null as raiseRequestToArchiveAgent is called only after store.comment is set.`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        raiseRequestToArchiveAgentWithBalance: flow(function* (agentId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            store.balanceAmount = null;
            if (store.comment) {
                try {
                    const request = new RequestToArchiveAgentWithBalanceRPC.Request(
                        new LeoUUID(agentId),
                        new Comment(store.comment)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RequestToArchiveAgentWithBalanceRPC.Response,
                        RequestToArchiveAgentWithBalanceRPC.Errors.Errors
                    > = yield useRequestToArchiveAgentWithBalanceRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RequestToArchiveAgentWithBalanceRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = getLeoError(error, loggerStore);
                } finally {
                    store.comment = null;
                }
                store.comment = null;
            } else {
                loggerStore.debug(
                    `store.comment cannot be null as raiseRequestToArchiveAgentWithBalance is called only after store.comment is set.`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createUpdateAgentStateStore = (): Instance<typeof UpdateAgentStateStore> => {
    return UpdateAgentStateStore.create();
};
