import { BCNUserBasicDetails, BOGender, UserStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getDateString } from '../../common/utils/DateUtils';

export const BCNBasicUserDetailsModel = types.model({
    profilePhoto: types.maybeNull(types.string),
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherNames: types.maybeNull(types.string),
    status: types.enumeration<UserStatus.UserStatus>(
        'BCNUserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    phoneNumber: types.string,
    emailId: types.maybeNull(types.string),
    dateOfBirth: types.string,
    gender: types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender)),
    nationalId: types.string,
    joinDate: types.string
});

export const getBCNBasicUserDetailsModel = (
    userDetails: BCNUserBasicDetails
): Instance<typeof BCNBasicUserDetailsModel> => {
    return BCNBasicUserDetailsModel.create({
        profilePhoto: userDetails.profilePhoto && getImageUrl(userDetails.profilePhoto),
        firstName: userDetails.username.firstName.text,
        lastName: userDetails.username.lastName?.text,
        otherNames: userDetails.otherNames?.text,
        status: userDetails.status,
        phoneNumber: userDetails.phoneNumber.phoneNumber,
        emailId: userDetails.emailId?.emailId ?? null,
        dateOfBirth: getDateString(new Date(userDetails.dateOfBirth.date)),
        gender: userDetails.gender,
        nationalId: userDetails.nationalId,
        joinDate: getDateString(new Date(userDetails.joinDate.date))
    });
};
