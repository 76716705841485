import { Box, styled, Typography } from '@mui/material';
import { Card, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as BCNLogo } from '../../../assets/bcn-logo.svg';
import { NetworkingError } from '../../error/store/ErrorStore';
import { ErrorDialog } from '../../home/common/components/dialog/ErrorDialog';
import { useNetworkingErrorStore } from '../../networking/store/hooks';
import { AuthProtectedRouter } from '../protected-router/AuthProtectedRouter';

export const AuthLayout = observer((): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const errorStore = useNetworkingErrorStore();
    const [isInvalidRequestError, setIsInvalidRequestError] = useState(false);
    const [isTimeOutError, setIsTimeOutError] = useState(false);

    useEffect(() => {
        if (errorStore.error) {
            switch (errorStore.error) {
                case NetworkingError.InternalServerError:
                    navigate('/500');
                    break;
                case NetworkingError.PageNotFound:
                    navigate('/404');
                    break;
                case NetworkingError.RPCError:
                    navigate('/auth');
                    break;
                case NetworkingError.InvalidRequest:
                    setIsInvalidRequestError(true);
                    break;
                case NetworkingError.TimeOut:
                    setIsTimeOutError(true);
                    break;
            }
            errorStore.removeError();
        }
    }, [errorStore.error]);

    const RootContainer = styled(Box)({
        backgroundColor: palette.background[300],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    });

    return (
        <AuthProtectedRouter>
            <RootContainer>
                <Card
                    style={{
                        width: '600px',
                        paddingTop: '48px',
                        paddingBottom: '48px',
                        borderRadius: '12px',
                        alignItems: 'center'
                    }}>
                    <BCNLogo style={{ height: '52px', width: '52px' }} />
                    <Typography
                        sx={{ color: palette.label[300], marginTop: '24px', ...typography.h5 }}>
                        {t('signIn.projectTitle')}
                    </Typography>
                    <Outlet />
                    <ErrorDialog
                        title={t('common.somethingWentWrong')}
                        errorMessage={t('common.somethingWentWrongProcessingRequest')}
                        isErrorDialogOpen={isInvalidRequestError || isTimeOutError}
                        onClose={(): void => {
                            if (isTimeOutError) {
                                setIsTimeOutError(false);
                            } else {
                                setIsInvalidRequestError(false);
                            }
                            // This is done because it is very much possible that the page is stuck in a loading state while making the RPC call
                            // hence to break the loading state, the page is reloaded
                            window.location.reload();
                        }}
                    />
                </Card>
            </RootContainer>
        </AuthProtectedRouter>
    );
});
