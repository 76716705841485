import { Stack } from '@mui/material';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { PageHeader } from '../../common/components/PageHeader';
import { StatusComponent } from '../../common/components/StatusComponent';
import {
    getAmountString,
    getFormattedPhoneNumber,
    getTableStyleOverrides
} from '../../common/utils/UIUtils';
import { AgentTransactionListFilter } from '../components/AgentTransactionListFilter';
import {
    AgentTransactionSearchErrors,
    AgentTransactionSearchFilterOptions
} from '../store/AgentTransactionSearchStore';
import { useAgentTransactionSearchStore } from '../store/hooks';
import {
    getAgentTransactionListInitialFilter,
    getTransactionStatus,
    getTransactionTypeValue
} from '../utils/UIUtils';
import { useLoggerStore } from '../../../../log/hooks';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const AgentTransactionList = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const navigate = useNavigate();
    const store = useAgentTransactionSearchStore();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(
            t('agencyBanking.agentTransactions'),
            window.location.pathname
        );
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'timeDate',
                sortable: true,
                name: t('common.timeDate'),
                width: '182px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '160px'
            },
            {
                id: 'transactionType',
                name: t('common.tableHeader.transactionType'),
                width: '160px',
                ellipsisContent: true
            },
            {
                id: 'transactionStatus',
                name: t('agencyBanking.transactionStatus'),
                width: '201px'
            },
            {
                id: 'requestId',
                name: t('agencyBanking.requestId'),
                width: '160px'
            },
            {
                id: 'transactionId',
                name: t('common.transactionId'),
                width: '160px',
                ellipsisContent: true
            },
            {
                id: 'sendersName',
                name: t('common.tableHeader.sendersName'),
                width: '160px'
            },
            {
                id: 'sendersMobileNumber',
                name: t('common.tableHeader.sendersMobileNumber'),
                width: '164px',
                align: 'right'
            },
            {
                id: 'senderAgentName',
                name: t('agencyBanking.senderAgentName'),
                width: '160px'
            },
            {
                id: 'senderAgentMobileNumber',
                name: t('agencyBanking.senderAgentMobileNumber'),
                width: '189px',
                align: 'right'
            },
            {
                id: 'receiversName',
                name: t('common.tableHeader.receiversName'),
                width: '160px'
            },
            {
                id: 'receiversMobileNumber',
                name: t('common.tableHeader.receiversMobileNumber'),
                width: '170px',
                align: 'right'
            },
            {
                id: 'receiverAgentName',
                name: t('agencyBanking.receiverAgentName'),
                width: '160px'
            },
            {
                id: 'receiverAgentMobileNumber',
                name: t('agencyBanking.receiverAgentMobileNumber'),
                width: '202px',
                align: 'right'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<typeof AgentTransactionSearchFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchAgentTransactionSearch(
            option.page ? option.page - 1 : 0,
            store.itemsPerPage(),
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case AgentTransactionSearchErrors.InvalidSearchTextError:
                    return t('common.invalidSearchText');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.agentTransactions.map((agentTransaction) => {
            return [
                { data: agentTransaction.createdAt },
                { data: getAmountString(agentTransaction.amount) },
                { data: getTransactionTypeValue(agentTransaction.transactionType, t) },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getTransactionStatus(agentTransaction.transactionStatus)}
                        />
                    )
                },
                { data: agentTransaction.requestId ?? '-' },
                { data: agentTransaction.transactionId },
                { data: agentTransaction.senderDetails?.name ?? '-' },
                {
                    data:
                        (agentTransaction.senderDetails?.phoneNumber &&
                            getFormattedPhoneNumber(agentTransaction.senderDetails.phoneNumber)) ??
                        '-',
                    align: 'right'
                },
                { data: agentTransaction.senderAgentDetails?.name ?? '-' },
                {
                    data:
                        (agentTransaction.senderAgentDetails?.phoneNumber &&
                            getFormattedPhoneNumber(
                                agentTransaction.senderAgentDetails.phoneNumber
                            )) ??
                        '-',
                    align: 'right'
                },
                { data: agentTransaction.recipientDetails?.name ?? '-' },
                {
                    data:
                        (agentTransaction.recipientDetails?.phoneNumber &&
                            getFormattedPhoneNumber(
                                agentTransaction.recipientDetails.phoneNumber
                            )) ??
                        '-',
                    align: 'right'
                },
                { data: agentTransaction.recipientAgentDetails?.name ?? '-' },
                {
                    data:
                        (agentTransaction.recipientAgentDetails?.phoneNumber &&
                            getFormattedPhoneNumber(
                                agentTransaction.recipientAgentDetails.phoneNumber
                            )) ??
                        '-',
                    align: 'right'
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack>
            <PageHeader
                title={t('agencyBanking.agentTransactions')}
                subtitle={t('agencyBanking.agentTransactionListSubtitle')}
            />
            <Table
                name="AgentTransactionTable"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    // store.isTableIdle value is used to check if the table has to start with idle mode or not.
                    // Because when the table is filtered and the page is revisited the table should start loading the data, instead of showing idle view.
                    idle: store.isTableIdle
                        ? { message: t('agencyBanking.agentTransactionSearchIdleState') }
                        : undefined,
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('agencyBanking.transactionsSearchTableLoadingState') }
                }}
                filterOption={{
                    initialFilterValue:
                        (store.filterOptions as Instance<
                            typeof AgentTransactionSearchFilterOptions
                        >) ?? getAgentTransactionListInitialFilter(t),
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <AgentTransactionListFilter
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={store.minimumSearchTextLength()}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    const selectedAgentTransaction = store.agentTransactions[index];
                    if (selectedAgentTransaction) {
                        navigate(
                            `/agency-banking/transactions/details?transactionId=${selectedAgentTransaction.transactionId}`
                        );
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected Agent Transaction in the list with length ${store.agentTransactions.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </Stack>
    );
});
