import {
    BitmapImageType,
    BOName,
    BOUserComment,
    Comment,
    GetFeeDeterminationRuleHistoryRequestDetailsRPC,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RequestDetails,
    UserName
} from '@resolut-tech/bcn-rpcs';
import {
    FileDetails,
    FileDetailsEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/fileDetails';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetFeeDeterminationRuleHistoryRequestDetailsRPCImpl extends GetFeeDeterminationRuleHistoryRequestDetailsRPC {
    execute(
        _request: GetFeeDeterminationRuleHistoryRequestDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetFeeDeterminationRuleHistoryRequestDetailsRPC.Response,
            GetFeeDeterminationRuleHistoryRequestDetailsRPC.Errors.Errors
        >
    > {
        const boUserComment = new BOUserComment(
            new LeoUUID(),
            new UserName(new BOName('Wade Warren')),
            new MultiResolutionBitmapImage(
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                ),
                new RemoteBitmapImage(
                    new URL(
                        'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                    ),
                    BitmapImageType.BitmapImageType.PNG,
                    20,
                    20
                )
            ),
            new LeoTimestamp('2022-09-30T02:03:59Z'),
            new Comment('The fee rules have changed.')
        );

        const currentRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );

        const newRuleFile = new FileDetails(
            FileDetailsEnums.FileExtension.FileExtension.CSV,
            new LeoTimestamp(),
            new LeoUUID()
        );

        const response = new GetFeeDeterminationRuleHistoryRequestDetailsRPC.Response(
            new RequestDetails.Pending(new LeoUUID(), boUserComment),
            newRuleFile,
            currentRuleFile
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetFeeDeterminationRuleHistoryRequestDetailsRPC.Response,
                GetFeeDeterminationRuleHistoryRequestDetailsRPC.Errors.Errors
            >
        >;
    }
}
