import { cast, Instance, types } from 'mobx-state-tree';

export const LinksModel = types.model({
    label: types.string,
    path: types.string
});

export const BreadcrumbStore = types
    .model({
        links: types.array(LinksModel)
    })
    .actions((store) => ({
        setLink(label: string, path: string): void {
            store.links.push({ label, path });
        },
        setInitialLink(label: string, path: string): void {
            store.links = cast([]);
            store.links.push({ label, path });
        },
        resetLinks(): void {
            store.links = cast([]);
        },
        removeElementsTill(index: number): void {
            store.links.splice(index);
        },
        removeLastElement(): void {
            store.links.pop();
        }
    }))
    .views((store) => ({
        getBreadcrumbData(navigateTo: (path: string) => void): {
            label: string;
            onLabelClick: () => void;
        }[] {
            const breadcrumbData = store.links.map((link, index) => ({
                label: link.label,
                onLabelClick: (): void => {
                    navigateTo(link.path);
                    store.removeElementsTill(index);
                }
            }));
            return breadcrumbData;
        }
    }));

export const createBreadcrumbStore = (): Instance<typeof BreadcrumbStore> => {
    return BreadcrumbStore.create({
        links: []
    });
};
