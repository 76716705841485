import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import {
    GetFeeDeterminationRuleRequestHistoryPaginationResponse,
    GetFeeDeterminationRuleRequestHistoryRPC,
    GetFeeDeterminationRuleRequestHistorySortOrder,
    ItemsPerPage,
    PageIndex,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';
import { useGetFeeRuleRequestHistoryRPCClient } from '../rpcs/RPC';
import { DownloadFeeRulesStore, createDownloadFeeRuleStore } from './DownloadFeeRulesStore';
import { RuleHistoryDetailsModel } from '../../common/models/RulesModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { GetFeeDeterminationRuleRequestHistorySortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getFeeDeterminationRuleRequestHistorySortOrder';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ViewFeeRulesHistoryStoreErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const ViewFeeRulesHistoryStore = types
    .model({
        requestStatus: types.string,
        totalItems: types.number,
        ruleHistoryRequests: types.array(RuleHistoryDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<ViewFeeRulesHistoryStoreErrors>(
                    'ViewFeeRulesHistoryStoreErrors',
                    Object.values(ViewFeeRulesHistoryStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        ),
        downloadFileStore: DownloadFeeRulesStore
    })
    .views(() => ({
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        updateRequestStatus(requestStatus: string): void {
            store.requestStatus = requestStatus;
        },
        removeError(): void {
            store.error = null;
        },
        fetchFeeRuleHistoryRequests: flow(function* (
            pageIndex: number,
            dropdownValue?: string,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            let requestStatus = null;
            if (RequestStatus.isInstanceOf(dropdownValue)) {
                requestStatus = RequestStatus.fromDTO({
                    case: dropdownValue ?? store.requestStatus
                });
            }
            const request = new GetFeeDeterminationRuleRequestHistoryRPC.Request(
                [
                    new GetFeeDeterminationRuleRequestHistorySortOrder(
                        0,
                        GetFeeDeterminationRuleRequestHistorySortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                requestStatus,
                new ItemsPerPage(store.itemsPerPage()),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetFeeDeterminationRuleRequestHistoryRPC.Response,
                GetFeeDeterminationRuleRequestHistoryRPC.Errors.Errors
            > = yield useGetFeeRuleRequestHistoryRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const ruleRequestDetails =
                    response.getFeeDeterminationRuleRequestHistoryPaginationResponse.map(
                        (
                            paginationResponse: GetFeeDeterminationRuleRequestHistoryPaginationResponse
                        ) => {
                            return {
                                requestId: paginationResponse.requestId.uuid,
                                requestedAt: getFormattedTimeDateWithComma(
                                    new Date(paginationResponse.requestedAt.timestamp)
                                ),
                                requestStatus: paginationResponse.requestStatus,
                                checkedBy: paginationResponse.checkedBy
                                    ? getPGFullName(paginationResponse.checkedBy)
                                    : null,
                                requestedBy: getPGFullName(paginationResponse.requestedBy)
                            };
                        }
                    );
                store.totalItems = response.totalItems;
                store.ruleHistoryRequests = cast(ruleRequestDetails);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case ViewFeeRulesHistoryStoreErrors.InvalidPageIndex:
                        store.error = ViewFeeRulesHistoryStoreErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetFeeDeterminationRuleRequestHistoryRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetFeeDeterminationRuleRequestHistoryRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createViewFeeRulesHistoryStore = (): Instance<typeof ViewFeeRulesHistoryStore> => {
    return ViewFeeRulesHistoryStore.create({
        requestStatus: RequestStatus.RequestStatus.PENDING,
        ruleHistoryRequests: [],
        totalItems: 0,
        downloadFileStore: createDownloadFeeRuleStore()
    });
};
