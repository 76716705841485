import { Instance } from 'mobx-state-tree';
import { RequestFundTransferStore } from './RequestFundTransferStore';
import { RequestGenericFundsTransferStore } from './RequestGenericFundsTransferStore';
import { ValidateAccountDetailsStore } from './ValidateAccountDetailsStore';
import { RequestListStore } from './RequestListStore';
import { TransferDetailsStore } from './TransferDetailsStore';
import { SubmitGenericFundsTransferRequestStore } from './SubmitGenericFundsTransferStore';
import { CheckRequestStore } from './CheckRequestStore';
import { WithdrawRequestStore } from './WithdrawRequestStore';
import { useRootStore } from '../../../store/root/store/RootStore';

// To avoid cyclic dependency caused by importing stores, store hooks have been added to a different file

export const useRequestFundTransferStore = (): Instance<typeof RequestFundTransferStore> => {
    return useRootStore().homeStore.genericFundTransfer.requestFundTransferStore;
};

export const useValidateAccountDetailsStore = (): Instance<typeof ValidateAccountDetailsStore> => {
    return useRequestFundTransferStore().validateAccountDetailsStore;
};

export const useGenericFundsTransferRequestListStore = (): Instance<typeof RequestListStore> => {
    return useRootStore().homeStore.genericFundTransfer.requestListStore;
};

export const useRequestGenericFundsTransferStore = (): Instance<
    typeof RequestGenericFundsTransferStore
> => {
    return useRequestFundTransferStore().requestGenericFundsTransferStore;
};

export const useGenericFundsTransferDetailsStore = (): Instance<typeof TransferDetailsStore> => {
    return useRootStore().homeStore.genericFundTransfer.transferDetailsStore;
};

export const useSubmitGenericFundsTransferRequestStore = (): Instance<
    typeof SubmitGenericFundsTransferRequestStore
> => {
    return useRequestFundTransferStore().submitGenericFundsTransferRequestStore;
};

export const useCheckGenericFundsTransferRequestStore = (): Instance<typeof CheckRequestStore> => {
    return useRootStore().homeStore.genericFundTransfer.checkRequestStore;
};

export const useWithdrawGenericFundsTransferRequestStore = (): Instance<
    typeof WithdrawRequestStore
> => {
    return useRootStore().homeStore.genericFundTransfer.withdrawGenericFundsTransferRequestStore;
};
