import { Stack } from '@mui/material';
import { List, ListItems, PaginatedViewOptions, usePalette } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { PageHeader } from '../../common/components/PageHeader';
import { DateRangeFilterOptions } from '../../common/utils/TableFilterUtils';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { useAuditLogsStore } from '../../store/hooks';
import { AuditListData } from '../components/AuditListData';
import { AuditLogsFilter } from '../components/AuditLogsFilter';
import { AuditLogsErrors } from '../store/AuditLogsStore';

export const AuditLogs = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useAuditLogsStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    const getData = async (
        options: PaginatedViewOptions<Instance<typeof DateRangeFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<ListItems | string> => {
        if (options.filter) store.updateFilterOptions(options.filter);
        await store.getAuditLogs(
            options.filter ?? null,
            options.page ? options.page - 1 : 0,
            store.itemsPerPage()
        );
        if (store.error) {
            switch (store.error) {
                case AuditLogsErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.auditLogs.map((logs) => {
            return <AuditListData key="auditLogs" details={logs} />;
        });
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('auditLogs.auditLogsTitle')}
                subtitle={t('auditLogs.auditLogsSubtitle')}
            />
            <List
                name="AuditLogsList"
                styleOverrides={getTableStyleOverrides(palette)}
                filterOption={{
                    initialFilterValue: store.filterOptions,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return <AuditLogsFilter filter={filter} setFilter={setFilter} />;
                    }
                }}
                onListOptionsChange={getData}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('auditLogs.searchingForLogs') }
                }}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    position: 'BOTH',
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </Stack>
    );
});
