import React from 'react';
import { ListItemProp, SubListItemProp } from '@surya-digital/leo-reactjs-ui';
import { TFunction } from 'react-i18next';
import { BarChart } from '../../../../assets/icons/BarChart';
import { ClipboardList } from '../../../../assets/icons/ClipboardList';
import { List } from '../../../../assets/icons/List';
import { ArrowLeftRight } from '../../../../assets/icons/ArrowLeftRight';
import { Banknote } from '../../../../assets/icons/Banknote';
import { Contact } from '../../../../assets/icons/Contact';
import { Users } from '../../../../assets/icons/Users';
import { UserPrivileges } from '../../user/UserPrivileges';
import { Clock } from '../../../../assets/icons/Clock';
import { Coins } from '../../../../assets/icons/Coins';
import { Smartphone } from '../../../../assets/icons/Smartphone';

const getTransactionListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];
    if (userPrivileges.includes(UserPrivileges.ViewTransaction)) {
        children.push({
            label: t('common.transactionSearch'),
            path: '/transaction/search'
        });
    }
    if (userPrivileges.includes(UserPrivileges.ViewTransactionRefundRequests)) {
        children.push({ label: t('common.refundRequests'), path: '/transaction/refund-requests' });
    }
    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.transactions'),
            icon: <ArrowLeftRight />,
            subList: children
        };
    }
};

const getFeeRulesListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewFeeRule)) {
        children.push({ label: t('common.viewRules'), path: '/fee-rules/view' });
    }
    if (userPrivileges.includes(UserPrivileges.RequestFeeRuleUpdate)) {
        children.push({ label: t('common.updateRules'), path: '/fee-rules/update' });
    }
    if (userPrivileges.includes(UserPrivileges.CheckFeeRuleRequest)) {
        children.push({ label: t('common.approveUpdates'), path: '/fee-rules/approve' });
    }
    if (userPrivileges.includes(UserPrivileges.ViewFeeRuleHistory)) {
        children.push({ label: t('common.viewHistory'), path: '/fee-rules/history' });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.feeRules'),
            icon: <ClipboardList />,
            subList: children
        };
    }
};

const getTransactionRulesListData = (
    t: TFunction,
    userPrivileges: string[]
): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewTransactionRule)) {
        children.push({ label: t('common.viewRules'), path: '/transaction-rules/view' });
    }
    if (userPrivileges.includes(UserPrivileges.RequestTransactionRuleUpdate)) {
        children.push({ label: t('common.updateRules'), path: '/transaction-rules/update' });
    }
    if (userPrivileges.includes(UserPrivileges.CheckTransactionRuleRequest)) {
        children.push({
            label: t('common.approveUpdates'),
            path: '/transaction-rules/approve'
        });
    }
    if (userPrivileges.includes(UserPrivileges.ViewTransactionRuleHistory)) {
        children.push({ label: t('common.viewHistory'), path: '/transaction-rules/history' });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.transactionRules'),
            icon: <List />,
            subList: children
        };
    }
};

const getGenericFundsTransferListData = (
    t: TFunction,
    userPrivileges: string[]
): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.RequestFundsTransfer)) {
        children.push({
            label: t('common.requestFundsTransfer'),
            path: '/generic-funds-transfer/request'
        });
    }
    if (userPrivileges.includes(UserPrivileges.ViewFundsTransfer)) {
        children.push({
            label: t('common.transferRequests'),
            path: '/generic-funds-transfer/transfer-requests'
        });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.genericFundsTransfer'),
            icon: <Banknote />,
            subList: children
        };
    }
};

const getBCNUsersListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewBCNUser)) {
        children.push({ label: t('common.bcnUsers'), path: '/yafika-mobile/users' });
        children.push({ label: t('common.viewRequests'), path: '/yafika-mobile/view-requests' });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.bcnUsers'),
            icon: <Contact />,
            subList: children
        };
    }
};

const getUnclaimedFunds = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewUnclaimedFunds)) {
        children.push({
            label: t('unclaimedFunds.cbsTransferFunds'),
            path: '/unclaimed-funds/search'
        });
        if (userPrivileges.includes(UserPrivileges.CheckCBSTransferRequest)) {
            children.push({
                label: t('unclaimedFunds.approveCBSTransfer'),
                path: '/unclaimed-funds/approve-cbs-transfer'
            });
        }
        children.push({ label: t('common.viewHistory'), path: '/unclaimed-funds/view-history' });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.unclaimedFunds'),
            icon: <Coins width="20px" height="20px" />,
            subList: children
        };
    }
};

const getBOUsersListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewBOUser)) {
        children.push({ label: t('common.boUsers'), path: '/bo/users' });
        children.push({ label: t('common.viewRequests'), path: '/bo/view-requests' });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.boUsers'),
            icon: <Users />,
            subList: children
        };
    }
};

const getReportsListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    if (userPrivileges.includes(UserPrivileges.ViewReports)) {
        return {
            label: t('common.reports'),
            icon: <BarChart />,
            path: '/reports'
        };
    } else {
        return null;
    }
};

const getAuditLogsData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    if (userPrivileges.includes(UserPrivileges.ViewAuditLogs)) {
        return {
            label: t('auditLogs.auditLogsTitle'),
            icon: <Clock />,
            path: '/audit-logs'
        };
    } else {
        return null;
    }
};

const getAgencyBankingListData = (t: TFunction, userPrivileges: string[]): ListItemProp | null => {
    const children: SubListItemProp[] = [];

    if (userPrivileges.includes(UserPrivileges.ViewAgentTransaction)) {
        children.push({
            label: t('agencyBanking.agentTransactions'),
            path: '/agency-banking/transactions'
        });
        children.push({
            label: t('agencyBanking.transactionRequestHistory'),
            path: '/agency-banking/transaction-request-history'
        });
    }

    if (userPrivileges.includes(UserPrivileges.ViewAgent)) {
        children.push({
            label: t('agencyBanking.agents'),
            path: '/agency-banking/agents'
        });
        children.push({
            label: t('agencyBanking.agentRequestsHistory'),
            path: '/agency-banking/agent-requests-history'
        });
    }

    if (children.length === 0) {
        return null;
    } else {
        return {
            label: t('common.agencyBanking'),
            icon: <Smartphone />,
            subList: children
        };
    }
};

export const sidebarTopListData = (t: TFunction, userPrivileges: string[]): ListItemProp[] => {
    const listData: ListItemProp[] = [];
    const transactionListData = getTransactionListData(t, userPrivileges);
    if (transactionListData) {
        listData.push(transactionListData);
    }

    const feeRulesListData = getFeeRulesListData(t, userPrivileges);
    if (feeRulesListData) {
        listData.push(feeRulesListData);
    }

    const transactionRulesListData = getTransactionRulesListData(t, userPrivileges);
    if (transactionRulesListData) {
        listData.push(transactionRulesListData);
    }

    const genericFundsTransferListData = getGenericFundsTransferListData(t, userPrivileges);
    if (genericFundsTransferListData) {
        listData.push(genericFundsTransferListData);
    }

    const unclaimedFundsListData = getUnclaimedFunds(t, userPrivileges);
    if (unclaimedFundsListData) {
        listData.push(unclaimedFundsListData);
    }

    const bcnUsersListData = getBCNUsersListData(t, userPrivileges);
    if (bcnUsersListData) {
        listData.push(bcnUsersListData);
    }

    const boUsersListData = getBOUsersListData(t, userPrivileges);
    if (boUsersListData) {
        listData.push(boUsersListData);
    }

    const agencyBankingData = getAgencyBankingListData(t, userPrivileges);
    if (agencyBankingData) {
        listData.push(agencyBankingData);
    }

    const reportsListData = getReportsListData(t, userPrivileges);
    if (reportsListData) {
        listData.push(reportsListData);
    }

    const auditLogsData = getAuditLogsData(t, userPrivileges);
    if (auditLogsData) {
        listData.push(auditLogsData);
    }
    return listData;
};
