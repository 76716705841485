import { AccountDetails, ValidateAccountDetailsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useValidateAccountDetailsRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ValidateAccountDetailsErrors {
    InvalidSenderAccount = 'INVALID_SENDER_ACCOUNT',
    SenderAccountInactive = 'SENDER_ACCOUNT_INACTIVE',
    SenderProfileDisabled = 'SENDER_PROFILE_DISABLED',
    CannotTransferFundsToSameAccount = 'CANNOT_TRANSFER_FUNDS_TO_SAME_ACCOUNT',
    SystemToSystemTransactionNotAllowed = 'SYSTEM_TO_SYSTEM_TRANSACTION_NOT_ALLOWED',
    InvalidRecipientAccount = 'INVALID_RECIPIENT_ACCOUNT',
    RecipientAccountInactive = 'RECIPIENT_ACCOUNT_INACTIVE',
    RecipientProfileDisabled = 'RECIPIENT_PROFILE_DISABLED',
    RecipientProfileArchived = 'RECIPIENT_PROFILE_ARCHIVED',
    SenderProfileArchived = 'SENDER_PROFILE_ARCHIVED'
}
export enum AccountType {
    System = 'System',
    User = 'User'
}

export const SystemAccountModel = types.model({
    countryName: types.string,
    accountName: types.string
});

export const UserAccountModel = types.model({
    profileImageUrl: types.maybeNull(types.string),
    name: types.string,
    phoneNumber: types.string,
    accountId: types.string
});

export const AccountDetailsModel = types.model({
    systemAccountDetails: types.maybeNull(SystemAccountModel),
    userAccountDetails: types.maybeNull(UserAccountModel),
    currency: types.string
});

const getAccountDetails = (
    accountDetails: AccountDetails.AccountDetails
): [Instance<typeof AccountDetailsModel>, AccountType] => {
    if (accountDetails instanceof AccountDetails.UserAccount) {
        return [
            AccountDetailsModel.create({
                userAccountDetails: UserAccountModel.create({
                    profileImageUrl: accountDetails.profileImage
                        ? getImageUrl(accountDetails.profileImage)
                        : null,
                    name: `${accountDetails.userName.firstName.text} ${accountDetails.userName.lastName?.text}`,
                    phoneNumber: accountDetails.phoneNumber.phoneNumber,
                    accountId: accountDetails.accountId.uuid
                }),
                currency: accountDetails.currency.currencyCode
            }),
            AccountType.User
        ];
    } else {
        return [
            AccountDetailsModel.create({
                systemAccountDetails: SystemAccountModel.create({
                    countryName: getTranslatedString(accountDetails.countryName),
                    accountName: accountDetails.accountName
                }),
                currency: accountDetails.currency.currencyCode
            }),
            AccountType.System
        ];
    }
};

export const ValidateAccountDetailsStore = types
    .model({
        senderAccountDetails: types.maybeNull(AccountDetailsModel),
        receiverAccountDetails: types.maybeNull(AccountDetailsModel),
        senderAccountType: typeof AccountType,
        receiverAccountType: typeof AccountType,
        error: types.maybeNull(
            types.union(
                types.enumeration<ValidateAccountDetailsErrors>(
                    'ValidateAccountDetailsErrors',
                    Object.values(ValidateAccountDetailsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        resetStore(): void {
            store.senderAccountDetails = null;
            store.receiverAccountDetails = null;
            store.senderAccountType = '';
            store.receiverAccountType = '';
        },
        validateAccountDetails: flow(function* (
            senderAccountId: string,
            receiverAccountId: string
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const senderAccountUUID = new LeoUUID(senderAccountId);
                try {
                    const receiverAccountUUID = new LeoUUID(receiverAccountId);
                    const request = new ValidateAccountDetailsRPC.Request(
                        senderAccountUUID,
                        receiverAccountUUID
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        ValidateAccountDetailsRPC.Response,
                        ValidateAccountDetailsRPC.Errors.Errors
                    > = yield useValidateAccountDetailsRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        [store.senderAccountDetails, store.senderAccountType] = getAccountDetails(
                            response.senderAccountDetails
                        );
                        [store.receiverAccountDetails, store.receiverAccountType] =
                            getAccountDetails(response.recipientAccountDetails);
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case ValidateAccountDetailsErrors.InvalidSenderAccount:
                                store.error = ValidateAccountDetailsErrors.InvalidSenderAccount;
                                break;
                            case ValidateAccountDetailsErrors.SenderAccountInactive:
                                store.error = ValidateAccountDetailsErrors.SenderAccountInactive;
                                break;
                            case ValidateAccountDetailsErrors.SenderProfileDisabled:
                                store.error = ValidateAccountDetailsErrors.SenderProfileDisabled;
                                break;
                            case ValidateAccountDetailsErrors.CannotTransferFundsToSameAccount:
                                store.error =
                                    ValidateAccountDetailsErrors.CannotTransferFundsToSameAccount;
                                break;
                            case ValidateAccountDetailsErrors.SystemToSystemTransactionNotAllowed:
                                store.error =
                                    ValidateAccountDetailsErrors.SystemToSystemTransactionNotAllowed;
                                break;
                            case ValidateAccountDetailsErrors.InvalidRecipientAccount:
                                store.error = ValidateAccountDetailsErrors.InvalidRecipientAccount;
                                break;
                            case ValidateAccountDetailsErrors.RecipientAccountInactive:
                                store.error = ValidateAccountDetailsErrors.RecipientAccountInactive;
                                break;
                            case ValidateAccountDetailsErrors.RecipientProfileDisabled:
                                store.error = ValidateAccountDetailsErrors.RecipientProfileDisabled;
                                break;
                            case ValidateAccountDetailsErrors.RecipientProfileArchived:
                                store.error = ValidateAccountDetailsErrors.RecipientProfileArchived;
                                break;
                            case ValidateAccountDetailsErrors.SenderProfileArchived:
                                store.error = ValidateAccountDetailsErrors.SenderProfileArchived;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in ValidateAccountDetailsRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in ValidateAccountDetailsRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    store.error = ValidateAccountDetailsErrors.InvalidRecipientAccount;
                }
            } catch (error) {
                store.error = ValidateAccountDetailsErrors.InvalidSenderAccount;
            }
        })
    }));

export const createValidateAccountDetailsStore = (): Instance<
    typeof ValidateAccountDetailsStore
> => {
    return ValidateAccountDetailsStore.create();
};
