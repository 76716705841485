import { RequestToReactivateOrDeactivateBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestToReactivateOrDeactivateBOUserRPCClientImpl extends RequestToReactivateOrDeactivateBOUserRPC {
    execute(
        _request: RequestToReactivateOrDeactivateBOUserRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestToReactivateOrDeactivateBOUserRPC.Response,
            RequestToReactivateOrDeactivateBOUserRPC.Errors.Errors
        >
    > {
        const response = new RequestToReactivateOrDeactivateBOUserRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestToReactivateOrDeactivateBOUserRPC.Response,
                RequestToReactivateOrDeactivateBOUserRPC.Errors.Errors
            >
        >;
    }
}
