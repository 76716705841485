import { RequestToArchiveAgentRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestToArchiveAgentRPCImpl extends RequestToArchiveAgentRPC {
    execute(
        _request: RequestToArchiveAgentRPC.Request
    ): Promise<
        LeoRPCResult<RequestToArchiveAgentRPC.Response, RequestToArchiveAgentRPC.Errors.Errors>
    > {
        const response = new RequestToArchiveAgentRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<RequestToArchiveAgentRPC.Response, RequestToArchiveAgentRPC.Errors.Errors>
        >;
    }
}
