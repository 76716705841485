import { Amount, Currency, GetMakerRefundTransactionDetailsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetMakerRefundTransactionDetailsRPCImpl extends GetMakerRefundTransactionDetailsRPC {
    execute(
        _request: GetMakerRefundTransactionDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetMakerRefundTransactionDetailsRPC.Response,
            GetMakerRefundTransactionDetailsRPC.Errors.Errors
        >
    > {
        const response = new GetMakerRefundTransactionDetailsRPC.Response(
            new Amount(50000000, new Currency('MWK')),
            new Amount(50000, new Currency('MWK')),
            new Amount(50000000, new Currency('MWK')),
            new Currency('MWK')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetMakerRefundTransactionDetailsRPC.Response,
                GetMakerRefundTransactionDetailsRPC.Errors.Errors
            >
        >;
    }
}
