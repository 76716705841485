import { DateRange, GetReportDetailsRPC, ReportData } from '@resolut-tech/bcn-rpcs';
import { LeoDate, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const getData = (dateRange: DateRange | null): ReportData[] => {
    const data: ReportData[] = [];
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let start = new Date();
    let end = new Date();
    if (dateRange) {
        start = new Date(dateRange.startDate.date);
        end = new Date(dateRange.endDate.date);
    } else {
        start.setDate(start.getDate() - 30);
    }
    const diffDays = Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay));
    if (diffDays < 90) {
        for (let i = 0; i <= diffDays; i++) {
            const date = new Date(start);
            date.setDate(date.getDate() + i);
            data.push(
                new ReportData(
                    new LeoDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`),
                    Math.floor(Math.random() * i)
                )
            );
        }
    }
    return data;
};

export class MockGetReportDetailsRPCImpl extends GetReportDetailsRPC {
    execute(
        request: GetReportDetailsRPC.Request
    ): Promise<LeoRPCResult<GetReportDetailsRPC.Response, GetReportDetailsRPC.Errors.Errors>> {
        const data = getData(request.dateRange);
        if (data.length === 0) {
            return mockExecute({
                error: new GetReportDetailsRPC.Errors.LargeDateRange()
            }) as Promise<
                LeoRPCResult<GetReportDetailsRPC.Response, GetReportDetailsRPC.Errors.Errors>
            >;
        } else {
            const response = new GetReportDetailsRPC.Response(data);
            return mockExecute({ response }) as Promise<
                LeoRPCResult<GetReportDetailsRPC.Response, GetReportDetailsRPC.Errors.Errors>
            >;
        }
    }
}
