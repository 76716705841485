import { Area } from 'react-easy-crop';

export interface BlobData {
    url: string;
    blob: Blob;
}

interface Size {
    width: number;
    height: number;
}

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

const getRadianAngle = (degreeValue: number): number => {
    return (degreeValue * Math.PI) / 180;
};

const getBoundaryArea = (width: number, height: number, rotation: number): Size => {
    const rotationRadian = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotationRadian) * width) +
            Math.abs(Math.sin(rotationRadian) * height),
        height:
            Math.abs(Math.sin(rotationRadian) * width) + Math.abs(Math.cos(rotationRadian) * height)
    };
};

/**
 * @param imageSrc - Object URL of the image
 * @param pixelCrop - Area to be cropped, includes height, width, x-axis, y-axis
 * @param imageType - Image extension type
 * @param rotation - Rotate the image. In our case we are not using rotation so it is Zero
 * @param flip - Flip the image. In our case, we are not using flip so it is false for both horizontal and vertical */
export const getCroppedImage = async (
    imageSrc: string,
    pixelCrop: Area,
    imageType: string,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
): Promise<BlobData | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
        return null;
    }

    const rotationRadian = getRadianAngle(rotation);
    const { width: bBoxWidth, height: bBoxHeight } = getBoundaryArea(
        image.width,
        image.height,
        rotation
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
    context.translate(bBoxWidth / 2, bBoxHeight / 2);
    context.rotate(rotationRadian);
    context.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    context.translate(-image.width / 2, -image.height / 2);
    context.drawImage(image, 0, 0);
    const data = context.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    context.putImageData(data, 0, 0);
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            if (blob) resolve({ url: URL.createObjectURL(blob), blob });
            resolve(null);
        }, `image/${imageType}`);
    });
};

export const convertUrlToFile = (croppedImage: BlobData, image: File | null): File | null => {
    if (image) {
        const response = croppedImage;
        const data = response.blob;
        const imageType = image.type;
        const fileType = image.name.substring(image.name.lastIndexOf('.'));
        const imageName = image.name.replace(fileType, '');
        const metadata = {
            type: imageType
        };
        return new File([data], `${imageName}`, metadata);
    } else {
        return null;
    }
};
