import { RequestTransactionValidationRuleUpdateRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestTransactionValidationRuleUpdateRPCImpl extends RequestTransactionValidationRuleUpdateRPC {
    execute(
        _request: RequestTransactionValidationRuleUpdateRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestTransactionValidationRuleUpdateRPC.Response,
            RequestTransactionValidationRuleUpdateRPC.Errors.Errors
        >
    > {
        const response = new RequestTransactionValidationRuleUpdateRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestTransactionValidationRuleUpdateRPC.Response,
                RequestTransactionValidationRuleUpdateRPC.Errors.Errors
            >
        >;
    }
}
