import React from 'react';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { DiffViewRow } from './DiffViewRow';

interface UserStatusDiffCellComponentProps {
    status: StatusType;
    updatedStatus: StatusType;
    isSectionComponent?: boolean;
}

export const UserStatusDiffCellComponent = ({
    status,
    updatedStatus,
    isSectionComponent
}: UserStatusDiffCellComponentProps): React.ReactElement => {
    return (
        <DiffViewRow
            isSectionComponent={isSectionComponent}
            currentDetails={<StatusComponent variant="status-cell" status={status} />}
            updatedDetails={<StatusComponent variant="status-cell" status={updatedStatus} />}
        />
    );
};
