import { Box, Grid, styled } from '@mui/material';
import { EllipsisTypography, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface DiffViewPropsRow {
    currentDetails: string | React.ReactElement;
    updatedDetails: string | React.ReactElement;
    isSectionComponent?: boolean;
}

export const DiffViewRow = ({
    currentDetails,
    updatedDetails,
    isSectionComponent
}: DiffViewPropsRow): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    const StyledTypography = styled(EllipsisTypography)({
        ...typography.body2,
        color: palette.label[300],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    });

    const Container = styled(Box)({
        padding: '12px 16px',
        width: 'calc(100% - 28px)',
        maxWidth: '430px'
    });

    const renderTypography = (data: string): React.ReactElement => {
        return <StyledTypography>{data}</StyledTypography>;
    };

    const renderDetails = (details: string | React.ReactElement): React.ReactElement => {
        if (typeof details === 'string') {
            return renderTypography(details);
        }
        return details;
    };

    return (
        <Box sx={{ flexGrow: 1, width: isSectionComponent ? '50%' : '100%' }}>
            <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                    <Container
                        sx={{
                            backgroundColor: palette.error[100]
                        }}>
                        {renderDetails(currentDetails)}
                    </Container>
                </Grid>
                <Grid item xs={6}>
                    <Container sx={{ backgroundColor: palette.success[100] }}>
                        {renderDetails(updatedDetails)}
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};
