import React, { useEffect, useState } from 'react';
import { Typography, Box, styled, ListItem, List } from '@mui/material';
import {
    LoadingIndicator,
    PasswordInputField,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { NewPasswordEntryErrors } from '../stores/NewPasswordEntryStore';
import { useNewPasswordEntryStore } from '../stores/hooks';
import { useNavigate } from 'react-router-dom';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { AlertCircle } from '../../../assets/icons/AlertCircle';
import { AuthErrorDialog } from '../components/AuthErrorDialog';
import { LeoErrors } from '../../home/common/errors/LeoErrors';
import { NetworkingError } from '../../error/store/ErrorStore';
import { Button } from '../../common/components/Button';

export const SetNewPasswordEntryScreen = observer((): React.ReactElement => {
    const navigate = useNavigate();
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();
    const store = useNewPasswordEntryStore();
    const [loading, setLoading] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<boolean>(false);

    useEffect(() => {
        // this is done to ensure that store is reset when screen is presented
        store.resetStore();
    }, []);

    useEffect(() => {
        if (isDialogOpen === false) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            store.removeError();
        }
    }, [isDialogOpen]);

    const StyledButtonContainer = styled(Box)(() => ({
        width: '360px',
        height: '56px',
        marginTop: '24px'
    }));

    const newPasswordErrorHelperText = (): string | undefined => {
        switch (store.error) {
            case NewPasswordEntryErrors.InsecurePassword:
                return t('signIn.insecurePassword');
            case NewPasswordEntryErrors.ReusedPassword:
                return t('signIn.reusedPassword');
            case LeoErrors.InvalidPasswordError:
                return t('common.invalidPassword');
            case NewPasswordEntryErrors.PasswordMismatch:
                return t('signIn.passwordMismatch');
            default:
                return undefined;
        }
    };

    const onNewPasswordChange = (value: string): void => {
        setNewPasswordError(false);
        setConfirmNewPasswordError(false);
        store.removeError();
        store.setNewPassword(value);
    };

    const newPasswordTextField = (): React.ReactElement => {
        return (
            <PasswordInputField
                name="newPassword"
                value={store.newPassword}
                onTextChange={onNewPasswordChange}
                label={t('signIn.newPassword')}
                helperText={newPasswordErrorHelperText()}
                helperIcon={newPasswordError ? <AlertCircle /> : undefined}
                error={newPasswordError}
                style={{
                    width: '360px',
                    marginTop: '24px'
                }}
            />
        );
    };

    const confirmNewPasswordErrorHelperText = (): string | undefined => {
        if (store.error === NewPasswordEntryErrors.PasswordMismatch) {
            return t('signIn.passwordMismatch');
        }
    };

    const onConfirmNewPasswordChange = (value: string): void => {
        setConfirmNewPasswordError(false);
        setNewPasswordError(false);
        store.removeError();
        store.setConfirmNewPassword(value);
    };

    const confirmNewPasswordTextField = (): React.ReactElement => {
        return (
            <PasswordInputField
                name="confirmNewPassword"
                value={store.confirmNewPassword}
                onTextChange={onConfirmNewPasswordChange}
                label={t('signIn.confirmNewPassword')}
                helperIcon={confirmNewPasswordError ? <AlertCircle /> : undefined}
                helperText={confirmNewPasswordErrorHelperText()}
                error={confirmNewPasswordError}
                style={{
                    width: '360px',
                    marginTop: '24px'
                }}
            />
        );
    };

    const handleSetNewPasswordError = (): void => {
        switch (store.error) {
            case NewPasswordEntryErrors.PasswordMismatch:
                setConfirmNewPasswordError(true);
                setNewPasswordError(true);
                break;
            case NewPasswordEntryErrors.InsecurePassword:
            case LeoErrors.InvalidPasswordError:
            case NewPasswordEntryErrors.ReusedPassword:
                setNewPasswordError(true);
                break;
            case NetworkingError.InternalError:
                setIsDialogOpen(true);
                break;
            default:
                setIsDialogOpen(true);
                break;
        }
    };

    const onChangePasswordButtonTap = async (): Promise<void> => {
        setLoading(true);
        await store.submitResetPassword();
        if (store.error) {
            handleSetNewPasswordError();
        } else {
            store.completeSignInProcess();
            navigate('/', { replace: true });
        }
        setLoading(false);
    };

    const changePasswordButton = (): React.ReactElement => {
        return (
            <StyledButtonContainer>
                <Button
                    name="signIn"
                    variant="filled"
                    size="large"
                    title={t('signIn.changePassword')}
                    isDisabled={!Boolean(store.confirmNewPassword && store.newPassword)}
                    color="primary"
                    onClick={async (): Promise<void> => {
                        await onChangePasswordButtonTap();
                    }}
                    style={{
                        width: '360px'
                    }}
                />
            </StyledButtonContainer>
        );
    };

    const passwordPolicyTypography = (): React.ReactElement => {
        const passwordPolicy = store.getPasswordPolicy();
        if (passwordPolicy) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '24px',
                        width: '360px'
                    }}>
                    <Typography sx={{ ...typography.small2 }}>{passwordPolicy.title}</Typography>
                    {passwordPolicy.description.map((desc, index) => {
                        return (
                            <List
                                sx={{ listStyleType: 'disc', padding: 0, marginLeft: '20px' }}
                                key={index}>
                                <ListItem
                                    key={index}
                                    sx={{
                                        padding: 0,
                                        display: 'list-item',
                                        ...typography.body2
                                    }}>
                                    {desc}
                                </ListItem>
                            </List>
                        );
                    })}
                </Box>
            );
        } else {
            return <></>;
        }
    };

    const errorDialog = (): React.ReactElement => {
        let error: CommonAuthErrors | NetworkingError | undefined;
        if (store.error) {
            error = store.error as CommonAuthErrors | NetworkingError;
        }
        return <AuthErrorDialog open={isDialogOpen} error={error} setOpen={setIsDialogOpen} />;
    };

    return (
        <>
            <Typography sx={{ color: palette.label[200], ...typography.body2 }}>
                {t('signIn.setPassword')}
            </Typography>
            {newPasswordTextField()}
            {confirmNewPasswordTextField()}
            {passwordPolicyTypography()}
            {changePasswordButton()}
            {errorDialog()}
            <LoadingIndicator isLoading={loading} color="white" />
        </>
    );
});
