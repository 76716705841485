import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KYCDocumentHistoryDetailsModel } from '../models/KYCDocumentHistoryDetailsModel';
import { KYCDocumentDetails } from './KYCDocumentDetails';
import { useLoggerStore } from '../../../../log/hooks';

interface KYCDocumentHistoryTableProp {
    kycDocumentRequests: Instance<typeof KYCDocumentHistoryDetailsModel>[];
}

export const KYCDocumentHistoryTable = ({
    kycDocumentRequests
}: KYCDocumentHistoryTableProp): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const [kycRequestId, setKycRequestId] = useState<string>('');
    const [isKYCDocumentDetailsOpen, setIsKYCDocumentDetailsOpen] = useState<boolean>(false);
    const [kycRequestedAt, setKycRequestedAt] = useState<string>('');
    const loggerStore = useLoggerStore();

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'updatedOn',
                name: t('bcnUser.updatedOn'),
                width: '200px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        _option: TableOptions,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        setTotalItems(kycDocumentRequests.length);
        return kycDocumentRequests.map((kycDocumentRequest) => {
            return [
                { data: kycDocumentRequest.requestedAt },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const kycDocumentDetailsDialog = (): React.ReactElement => {
        return (
            <KYCDocumentDetails
                isDialogOpen={isKYCDocumentDetailsOpen}
                onClose={function (): void {
                    setIsKYCDocumentDetailsOpen(false);
                }}
                kycRequestId={kycRequestId}
                kycRequestedAt={kycRequestedAt}
            />
        );
    };

    return (
        <>
            <Table
                name="KYCDocumentHistoryTable"
                styleOverrides={{
                    borderRadius: '4px',
                    border: 'none'
                }}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: kycDocumentRequests.length,
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') }
                }}
                onRowClick={(_row, index): void => {
                    const selectedKycDocumentRequest = kycDocumentRequests[index];
                    if (selectedKycDocumentRequest) {
                        setKycRequestedAt(selectedKycDocumentRequest.requestedAt);
                        setKycRequestId(selectedKycDocumentRequest.requestId);
                        setIsKYCDocumentDetailsOpen(true);
                    } else {
                        loggerStore.debug(
                            `Cannot find index: ${index} for the selected kyc request in kycDocumentRequests`
                        );
                    }
                }}
            />
            {isKYCDocumentDetailsOpen && kycDocumentDetailsDialog()}
        </>
    );
};
