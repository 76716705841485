import { CountryInformation, GetCountryListRPC } from '@resolut-tech/bcn-rpcs';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { getTranslatedString } from '../../../utils/StringUtils';
import { useGetCountryListRPCClient } from './rpc/RPC';
import { getAPIClient } from '../../networking/store/NetworkingStore';

export const CountryModel = types.model({
    name: types.string,
    countryCode: types.string,
    phoneCode: types.string,
    flagURL: types.string
});

export interface CountryViewModel {
    name: string;
    countryCode: string;
    phoneCode: string;
    flagURL: string;
}

export const CountryListStore = types
    .model({
        countries: types.array(CountryModel)
    })
    .actions((store) => ({
        fetchCountries: flow(function* () {
            const request = new GetCountryListRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetCountryListRPC.Response,
                GetCountryListRPC.Errors.Errors
            > = yield useGetCountryListRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const countries = response.countries.map(
                    (country: CountryInformation): Instance<typeof CountryModel> => {
                        return CountryModel.create({
                            name: getTranslatedString(country.displayName),
                            countryCode: country.countryCode.code,
                            phoneCode: country.phoneCode,
                            flagURL: country.flag.light.mdpi.imageURL.toString()
                        });
                    }
                );
                store.countries = cast(countries);
            }
        })
    }))
    .views((store) => ({
        countryList(): CountryViewModel[] {
            return store.countries.map((countryModel) => {
                return {
                    name: countryModel.name,
                    countryCode: countryModel.countryCode,
                    phoneCode: countryModel.phoneCode,
                    flagURL: countryModel.flagURL
                };
            });
        },
        getCountryModel(viewModel: CountryViewModel): Instance<typeof CountryModel> | undefined {
            return store.countries.find((countryModel) => {
                if (countryModel.phoneCode === viewModel.phoneCode) {
                    return countryModel;
                }
            });
        }
    }));

export const createCountryListStore = (): Instance<typeof CountryListStore> => {
    return CountryListStore.create({
        countries: []
    });
};
