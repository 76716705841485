import { BORoleDiff, BORoleDiffStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';

export const BORoleDiffModel = types.model({
    name: types.string,
    workflow: types.string,
    status: types.maybeNull(
        types.enumeration<BORoleDiffStatus.BORoleDiffStatus>(
            'BORoleDiffStatus',
            Object.values(BORoleDiffStatus.BORoleDiffStatus)
        )
    )
});

export const getBORoleDiffModel = (roleDiff: BORoleDiff): Instance<typeof BORoleDiffModel> => {
    return BORoleDiffModel.create({
        name: getTranslatedString(roleDiff.name),
        workflow: getTranslatedString(roleDiff.workflow),
        status: roleDiff.status
    });
};
