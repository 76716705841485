import { Instance } from 'mobx-state-tree';
import { BORoleDiffModel } from '../models/BORoleDiffModel';
import React from 'react';
import { getBORoleName } from '../utils/UIUtils';
import { BORoleDiffStatus } from '@resolut-tech/bcn-rpcs';
import { ChipGroup } from '../../common/components/ChipGroup';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';

export const RoleDiffComponent = ({
    role
}: {
    role: Instance<typeof BORoleDiffModel>;
}): React.ReactElement => {
    const palette = usePalette();

    const GridItem = styled(Grid)({
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${palette.outline[200]}`
    });

    const AddGridItem = styled(GridItem)({
        backgroundColor: palette.success[100]
    });

    const RemoveGridItem = styled(GridItem)({
        backgroundColor: palette.error[100]
    });

    const roleName = getBORoleName(role.workflow, role.name);
    switch (role.status) {
        case BORoleDiffStatus.BORoleDiffStatus.ADDED:
            return (
                <>
                    <GridItem item xs={6}></GridItem>
                    <AddGridItem item xs={6}>
                        <ChipGroup labels={[roleName]} />
                    </AddGridItem>
                </>
            );
        case BORoleDiffStatus.BORoleDiffStatus.REMOVED:
            return (
                <>
                    <RemoveGridItem item xs={6}>
                        <ChipGroup labels={[roleName]} />
                    </RemoveGridItem>
                    <GridItem item xs={6}></GridItem>
                </>
            );
        default:
            return (
                <>
                    <GridItem item xs={6}>
                        <ChipGroup labels={[roleName]} />
                    </GridItem>
                    <GridItem item xs={6}>
                        <ChipGroup labels={[roleName]} />
                    </GridItem>
                </>
            );
    }
};
