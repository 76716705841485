import {
    GetRefundRequestListPaginationResponse,
    GetRefundRequestListRPC,
    PGUserName,
    IntermediateRequestStatus,
    PGAmount
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];

const RefundRequestList: GetRefundRequestListPaginationResponse[] = [
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('75cb3775-8093-4797-bea6-3d0f5958acb8')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('5aec7805-247d-4ffa-aa76-d40a8afb8d71')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('28b22f53-2649-46f0-a526-b2d5b781c526')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('5cae341b-5831-4732-8b02-bae2f619be72')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('f211bd47-1ae3-4c56-8d16-7da8e5731248')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('dd9e6c5d-ba47-4ed8-8e0a-8d027a75e5e6')
    ),
    new GetRefundRequestListPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGAmount(5000000, 'MWK'),
        new LeoUUID('fea54b2a-50f0-49c2-b2b4-2ce7eb7ecb4d')
    )
];

export class MockGetRefundRequestsRPCImpl extends GetRefundRequestListRPC {
    execute(
        request: GetRefundRequestListRPC.Request
    ): Promise<
        LeoRPCResult<GetRefundRequestListRPC.Response, GetRefundRequestListRPC.Errors.Errors>
    > {
        let list: GetRefundRequestListPaginationResponse[] = [];
        if (request.transactionIdSearchText) {
            list = RefundRequestList.filter((refundRequest) => {
                if (refundRequest.requestStatus) {
                    return (
                        (refundRequest.requestStatus === request.requestStatus ||
                            !request.requestStatus) &&
                        refundRequest.transactionId.uuid
                            .toLowerCase()
                            .includes(request.transactionIdSearchText?.text!)
                    );
                } else {
                    return refundRequest.transactionId.uuid
                        .toLowerCase()
                        .includes(request.transactionIdSearchText?.text!);
                }
            });
        } else {
            list = RefundRequestList.filter((refundRequest) => {
                if (request.requestStatus) {
                    return refundRequest.requestStatus === request.requestStatus;
                } else {
                    return refundRequest;
                }
            });
        }
        const response = new GetRefundRequestListRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetRefundRequestListRPC.Response, GetRefundRequestListRPC.Errors.Errors>
        >;
    }
}
