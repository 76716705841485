import { AccountStatus, BCNAccountDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';

export const BCNUserAccountDetailsModel = types.model({
    id: types.string,
    name: types.string,
    balance: types.maybeNull(AmountModel),
    status: types.maybeNull(
        types.enumeration<AccountStatus.AccountStatus>(
            'AccountStatus',
            Object.values(AccountStatus.AccountStatus)
        )
    ),
    isDefaultAccount: types.maybeNull(types.boolean)
});

export const getBCNUserAccountDetailsModel = (
    accountDetails: BCNAccountDetails
): Instance<typeof BCNUserAccountDetailsModel> => {
    return BCNUserAccountDetailsModel.create({
        id: accountDetails.id.uuid,
        name: accountDetails.name,
        balance: accountDetails.balance && getAmountModel(accountDetails.balance),
        status: accountDetails.status,
        isDefaultAccount: accountDetails.isDefaultAccount
    });
};
