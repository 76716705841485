import {
    BOName,
    BOUserComment,
    GetUnclaimedFundsRequestDetailsRPC,
    RequestDetails,
    UserName,
    Comment,
    UnclaimedFundsRequestDetails,
    Amount,
    Currency
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetUnclaimedFundsRequestDetailsRPCImpl extends GetUnclaimedFundsRequestDetailsRPC {
    execute(
        _request: GetUnclaimedFundsRequestDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetUnclaimedFundsRequestDetailsRPC.Response,
            GetUnclaimedFundsRequestDetailsRPC.Errors.Errors
        >
    > {
        const response = new GetUnclaimedFundsRequestDetailsRPC.Response(
            new UnclaimedFundsRequestDetails(
                25,
                new Amount(50000000, new Currency('MWK')),
                new RequestDetails.Pending(
                    new LeoUUID(),
                    new BOUserComment(
                        new LeoUUID('a0bf3a95-e385-4d0c-92bd-92961f13f5fa'),
                        new UserName(new BOName('Jane'), new BOName('Cooper')),
                        null,
                        new LeoTimestamp(new Date().toISOString()),
                        new Comment('Money needs to be sent to CBS account.')
                    )
                )
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetUnclaimedFundsRequestDetailsRPC.Response,
                GetUnclaimedFundsRequestDetailsRPC.Errors.Errors
            >
        >;
    }
}
