import { GetAuditLogsRPC, GetAuditLogsRPCClientImpl } from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';
import { MockAuditLogsRPCImpl } from './MockAuditLogsRPCImpl';

const MOCK = false;

export const useGetAuditLogsClient = (apiClient: APIClient): GetAuditLogsRPC => {
    if (MOCK) {
        return new MockAuditLogsRPCImpl();
    } else {
        return new GetAuditLogsRPCClientImpl(apiClient);
    }
};
