import { Comment, WithdrawGenericFundsTransferRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { useWithdrawGenericFundsTransferRequestRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { LeoErrors } from '../../common/errors/LeoErrors';

export enum WithdrawRequestStoreError {
    InvalidComment = 'INVALID_COMMENT'
}

export const WithdrawRequestStore = types
    .model({
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<WithdrawRequestErrors>(
                    'WithdrawRequestErrors',
                    Object.values(WithdrawRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                ),
                types.enumeration<WithdrawRequestStoreError>(
                    'WithdrawRequestStoreError',
                    Object.values(WithdrawRequestStoreError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        resetStore(): void {
            store.comment = null;
            store.error = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        withdrawRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const requestUUID = new LeoUUID(requestId);
                const request = new WithdrawGenericFundsTransferRequestRPC.Request(
                    requestUUID,
                    // withdrawRequest is called only after store.comment is set. Hence. store.comment is never null here.
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    WithdrawGenericFundsTransferRequestRPC.Response,
                    WithdrawGenericFundsTransferRequestRPC.Errors.Errors
                > = yield useWithdrawGenericFundsTransferRequestRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case WithdrawRequestErrors.InvalidRequestId:
                            store.error = WithdrawRequestErrors.InvalidRequestId;
                            break;
                        case WithdrawRequestErrors.RequestAlreadyChecked:
                            store.error = WithdrawRequestErrors.RequestAlreadyChecked;
                            break;
                        case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                            store.error = WithdrawRequestErrors.CanOnlyWithdrawSelfRequest;
                            break;
                        case WithdrawRequestErrors.RequestAlreadyDiscarded:
                            store.error = WithdrawRequestErrors.RequestAlreadyDiscarded;
                            break;
                        case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                            store.error = WithdrawRequestErrors.RequestAlreadyWithdrawn;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in WithdrawGenericFundsTransferRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in WithdrawGenericFundsTransferRequestRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = WithdrawRequestStoreError.InvalidComment;
                            break;
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = WithdrawRequestErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in WithdrawGenericFundsTransferRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in WithdrawGenericFundsTransferRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createWithdrawRequestStore = (): Instance<typeof WithdrawRequestStore> => {
    return WithdrawRequestStore.create();
};
