import { Grid, Stack } from '@mui/material';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { Section, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getUserStatus } from '../../common/utils/UIUtils';
import { AgentRequestHistoryStoreErrors } from '../store/AgentRequestHistorySectionStore';
import { useAgentRequestHistorySectionStore } from '../store/hooks';
import { CommissionBalanceSummary } from './CommissionBalanceSummary';

export const AgentRequestHistorySection = ({
    agentId
}: {
    agentId: string;
}): React.ReactElement => {
    const { t } = useTranslation();
    const store = useAgentRequestHistorySectionStore();
    const typography = useTypography();
    const palette = usePalette();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function getAgentRequestHistory(): Promise<string | React.ReactElement[]> {
        await store.fetchAgentRequestHistory(agentId);
        if (store.error) {
            switch (store.error) {
                case AgentRequestHistoryStoreErrors.InvalidAgentID:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.errorMessage');
                default:
                    return t('common.errorMessage');
            }
        } else if (store.requests.length === 0) {
            return [];
        } else {
            return [
                <Stack key="agentRequestHistory" spacing="16px">
                    {store.requests.map((request, index) => {
                        return (
                            <RequestDetailsSection
                                key={index}
                                requestDetails={request.requestDetails}
                                isSectionItem={true}>
                                <Stack>
                                    <Grid container sx={{ margin: '16px 0px 16px 0px' }}>
                                        <Grid item xs={6}>
                                            <Stack
                                                sx={{
                                                    ...typography.small2,
                                                    color: palette.label[300]
                                                }}>
                                                {request.requestDetails.status ===
                                                IntermediateRequestStatus.IntermediateRequestStatus
                                                    .PENDING
                                                    ? t('common.currentStatus')
                                                    : t('common.oldStatus')}
                                            </Stack>
                                            <StatusComponent
                                                variant="status-cell"
                                                status={getUserStatus(request.oldUserStatus)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack
                                                sx={{
                                                    ...typography.small2,
                                                    color: palette.label[300]
                                                }}>
                                                {t('common.updatedStatus')}
                                            </Stack>
                                            <StatusComponent
                                                variant="status-cell"
                                                status={getUserStatus(request.updatedUserStatus)}
                                            />
                                        </Grid>
                                    </Grid>
                                    {request.commissionBalance && (
                                        <CommissionBalanceSummary
                                            commissionBalance={request.commissionBalance}
                                        />
                                    )}
                                </Stack>
                            </RequestDetailsSection>
                        );
                    })}
                </Stack>
            ];
        }
    }

    return (
        <>
            <Section
                title={t('agencyBanking.agentRequestHistory')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={getAgentRequestHistory}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </>
    );
};
