import { Box, Stack, styled, Typography } from '@mui/material';
import { BOUserRequestType, UserStatus } from '@resolut-tech/bcn-rpcs';
import { LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorState } from '../../common/components/ErrorState';
import { LoadingState } from '../../common/components/LoadingState';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { BOUserBasicDetails } from '../components/BOUserBasicDetails';
import { BOUserRequestChip, BOUserRequestChipType } from '../components/BOUserRequestChip';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import {
    useBORoleStore,
    useBOUserDetailStore,
    useBOUserWithdrawRequestStore,
    useCheckBOUserChangeRequestStore,
    useNewBOUserCheckStore,
    useNewBOUserRequestDetailsStore,
    useRequestToAddEditBOUserStore,
    useValidateBOUserDetailsStore
} from '../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { BOUserProfileManagement } from '../components/BOUserProfileManagement';
import { useUserStore } from '../../store/hooks';
import { BORequestHistory } from '../components/BORequestHistory';
import { Status } from '../../common/enums/StatusEnum';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { CheckBOUserChangeRequestStoreError } from '../store/CheckBOUserChangeRequestStore';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { BOPendingRequestDialog } from '../components/BOPendingRequestDialog';
import { NewBOUserBasicDetails } from '../components/NewBOUserBasicDetails';
import { AddEditBOUserDialog } from '../components/AddEditBOUserDialog';
import { AddBOUserDownloadAuthCodeDialog } from '../components/AddBOUserDownloadAuthCodeDialog';
import { downloadFile } from '../../common/utils/FileUtils';
import { NewBOUserCheckStoreError } from '../store/NewBOUserCheckStore';
import { ApproveAuthCodeRequestDialog } from '../components/ApproveAuthCodeRequestDialog';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { ValidateBOUserErrors } from '../store/ValidateBOUserErrors';
import { RequestConfirmationDialog } from '../../common/components/dialog/RequestConfirmationDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { useLoggerStore } from '../../../../log/hooks';
import { NewBOUserDetailsStoreErrors } from '../store/NewBOUserDetailsStore';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { BOUserDetailErrors } from '../store/BOUserDetailStore';

const StyledStack = styled(Stack)(() => ({
    padding: '32px',
    boxSizing: 'border-box',
    width: '100%'
}));

export const BOUserDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();
    const store = useBOUserDetailStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParam] = useSearchParams();
    const userId = searchParam.get('userId');
    const requestId = searchParam.get('requestId');
    const userStore = useUserStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const checkRequestStore = useCheckBOUserChangeRequestStore();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isPendingRequestDialogOpen, setIsPendingRequestDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const [dialogBoxTitle, setDialogBoxTitle] = useState<string | null>(null);
    const withdrawRequestStore = useBOUserWithdrawRequestStore();
    const newBOUserRequestDetailsStore = useNewBOUserRequestDetailsStore();
    const [openEditBOUserDialog, setOpenEditBOUserDialog] = useState(false);
    const validateBOUserDetailsStore = useValidateBOUserDetailsStore();
    const [isDownloadAuthCodeDialogOpen, setIsDownloadAuthCodeDialogOpen] = useState(false);
    const newBOUserCheckStore = useNewBOUserCheckStore();
    const [isApproveAuthCodeDialogOpen, setIsApproveAuthCodeDialogOpen] = useState(false);
    const addEditBOUserStore = useRequestToAddEditBOUserStore();
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [forceResetPasswordComment, setForceResetPasswordComment] = useState<string | null>(null);
    const [confirmationDialogDescription, setConfirmationDialogDescription] = useState<
        string | null
    >(null);
    const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
    const [showEditButton, setShowEditButton] = useState<boolean>(false);
    const loggerStore = useLoggerStore();
    const boRoleStore = useBORoleStore();

    useEffect(() => {
        if (!isErrorDialogOpen) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            validateBOUserDetailsStore.removeError();
        }
    }, [isErrorDialogOpen]);

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckBOUserUpdateRequest
    );

    const shouldRequestProfileSection =
        userStore.privileges.includes(UserPrivileges.RequestArchiveBOUser) ||
        userStore.privileges.includes(UserPrivileges.RequestActivateDeactivateBOUser) ||
        userStore.privileges.includes(UserPrivileges.RequestForceResetPassword) ||
        userStore.privileges.includes(UserPrivileges.RequestRegenerateAuthCodes);

    const showWithdrawSection = (): boolean => {
        return (
            userStore.userId === store.requestDetails?.comment.userId ||
            userStore.userId ===
                newBOUserRequestDetailsStore.requestDetails?.requesterComment?.userId
        );
    };

    const onApproveClick = (): void => {
        if (userId) {
            checkRequestStore.setStatus(Status.APPROVED);
        } else {
            newBOUserCheckStore.setStatus(Status.APPROVED);
        }
        setIsRequestCheckDialogOpen(true);
    };

    const onDenyClick = (): void => {
        if (userId) {
            checkRequestStore.setStatus(Status.DENIED);
        } else {
            newBOUserCheckStore.setStatus(Status.DENIED);
        }
        setIsRequestCheckDialogOpen(true);
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const fetchBOUserDetails = async (): Promise<void> => {
        if (userId) {
            setLoading(true);
            await store.getBOUserBasicDetails(userId);
            const _shouldShowEditButton =
                userStore.privileges.includes(UserPrivileges.RequestAddEditBOUser) &&
                !requestId &&
                store.basicDetails?.status === UserStatus.UserStatus.ACTIVE;
            setShowEditButton(_shouldShowEditButton);
            setLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else if (requestId) {
            setLoading(true);
            await newBOUserRequestDetailsStore.fetchNewBOUserDetails(requestId);
            setLoading(false);
            if (newBOUserRequestDetailsStore.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            navigate('/bo/users', { replace: true });
        }
    };

    useEffect(() => {
        fetchBOUserDetails();
    }, []);

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setIsLoading(true);
        const isNewUser = newBOUserRequestDetailsStore.requestDetails?.requestId ? true : false;
        if (userId) {
            if (store.requestDetails?.requestId) {
                await withdrawRequestStore.withdrawRequest(store.requestDetails.requestId, comment);
            } else {
                loggerStore.debug(
                    'Cannot find requestId in BOUserDetailsStore while withdrawing the request'
                );
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            if (newBOUserRequestDetailsStore.requestDetails?.requestId) {
                await withdrawRequestStore.withdrawRequest(
                    newBOUserRequestDetailsStore.requestDetails.requestId,
                    comment
                );
            } else {
                loggerStore.debug(
                    'Cannot find requestId in NewBOUserDetailsStore while withdrawing the request'
                );
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        }
        setIsLoading(false);
        if (withdrawRequestStore.error) {
            switch (withdrawRequestStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
            }
        } else {
            if (isNewUser) {
                // If a request for adding new bo user is withdrawn, we should take the current user to the bo-users list
                navigate('/bo/users');
            } else {
                // Since the request has been withdrawn, we will fetch the user-details again.
                fetchBOUserDetails();
            }
        }
        withdrawRequestStore.removeError();
    };

    const onClickApproveOrDenyUpdate = async (comment: string): Promise<void> => {
        setIsRequestCheckDialogOpen(false);
        setIsLoading(true);
        if (userId) {
            if (store.requestDetails?.requestId) {
                if (
                    store.requestDetails.requestType ===
                    BOUserRequestType.BOUserRequestType.REGENERATE_AUTH_CODE
                ) {
                    await checkRequestStore.checkRegenerateAuthCodeRequest(
                        store.requestDetails.requestId,
                        comment
                    );
                } else {
                    await checkRequestStore.checkPendingRequest(
                        store.requestDetails.requestId,
                        comment
                    );
                }
            } else {
                loggerStore.debug(
                    'Cannot find requestId in BOUserDetailsStore while checking the request'
                );
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            if (newBOUserRequestDetailsStore.requestDetails?.requestId) {
                await newBOUserCheckStore.checkRequest(
                    newBOUserRequestDetailsStore.requestDetails.requestId,
                    comment
                );
            } else {
                loggerStore.debug(
                    'Cannot find requestId in NewBOUserDetailsStore while checking the request'
                );
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        }
        if (checkRequestStore.error) {
            setDialogBoxTitle(
                checkRequestStore.status === Status.APPROVED
                    ? t('common.approveUpdate')
                    : t('common.denyUpdate')
            );
            switch (checkRequestStore.error) {
                case CheckBOUserChangeRequestStoreError.InvalidRequestId:
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.CannotAssignInactiveRole:
                    setErrorDialogMessage(t('boUser.cannotAssignInactiveRole'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.CannotCheckSelfProfile:
                    setErrorDialogMessage(t('boUser.cannotCheckSelfProfile'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.EmailIdAlreadyExists:
                    setErrorDialogMessage(t('common.emailAlreadyExists'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.PhoneNumberAlreadyExists:
                    setErrorDialogMessage(t('common.mobileNumberAlreadyExists'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.AgentCannotBeAnAgentManager:
                    setErrorDialogMessage(t('boUser.agentCannotBeAnAgentManager'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.UserAppliedToBeAnAgent:
                    setErrorDialogMessage(t('boUser.userAppliedToBeAnAgent'));
                    setIsErrorDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.UserHasActivePendingRequests:
                    setIsPendingRequestDialogOpen(true);
                    break;
                case CheckBOUserChangeRequestStoreError.IncorrectRequestType:
                case CheckBOUserChangeRequestStoreError.CouldNotGenerateCodes:
                case NetworkingError.InternalError:
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setIsErrorDialogOpen(true);
            }
        } else if (newBOUserCheckStore.error) {
            setDialogBoxTitle(
                newBOUserCheckStore.status === Status.APPROVED
                    ? t('common.approveUpdate')
                    : t('common.denyUpdate')
            );
            switch (newBOUserCheckStore.error) {
                case NewBOUserCheckStoreError.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.CannotAssignInactiveRole:
                    setErrorDialogMessage(t('boUser.cannotAssignInactiveRole'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.AgentCannotBeAnAgentManager:
                    setErrorDialogMessage(t('boUser.agentCannotBeAnAgentManager'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.UserAppliedToBeAnAgent:
                    setErrorDialogMessage(t('boUser.userAppliedToBeAnAgent'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.IncorrectRequestType:
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.EmailIdAlreadyExists:
                    setErrorDialogMessage(t('common.emailAlreadyExists'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.PhoneNumberAlreadyExists:
                    setErrorDialogMessage(t('common.mobileNumberAlreadyExists'));
                    setIsErrorDialogOpen(true);
                    break;
                case NewBOUserCheckStoreError.CouldNotGenerateCodes:
                case NetworkingError.InternalError:
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setIsErrorDialogOpen(true);
            }
        } else {
            // Since newBOUserCheckStore is a success we have to further check if it was approved ot denied. If approved, we have to go to the auth code download section else we will go back to view request
            if (requestId) {
                if (newBOUserCheckStore.status === Status.APPROVED) {
                    setIsRequestCheckDialogOpen(false);
                    setIsDownloadAuthCodeDialogOpen(true);
                } else {
                    // Since the request has been denied, we will go back to view request and fetch the details again.
                    navigate('/bo/view-requests', { replace: true });
                }
            } else {
                if (
                    checkRequestStore.status === Status.APPROVED &&
                    store.requestDetails?.requestType ===
                        BOUserRequestType.BOUserRequestType.REGENERATE_AUTH_CODE
                ) {
                    // we need to show a download auth dialog when regenerate auth code request is approved
                    setIsRequestCheckDialogOpen(false);
                    setIsApproveAuthCodeDialogOpen(true);
                } else if (
                    checkRequestStore.status === Status.APPROVED &&
                    store.requestDetails?.requestType ===
                        BOUserRequestType.BOUserRequestType.FORCE_RESET_PASSWORD
                ) {
                    setIsOpenSuccessDialog(true);
                    fetchBOUserDetails();
                } else {
                    // Since the request has been approved or denied, we will fetch the user-details again.
                    fetchBOUserDetails();
                }
            }
        }
        checkRequestStore.resetStore();
        newBOUserCheckStore.resetStore();
        setIsLoading(false);
    };

    const renderRequestedForSection = (): React.ReactElement => {
        const { requestDetails } = store;
        if (!requestDetails || !requestDetails.requestType) return <></>;

        switch (requestDetails.requestType) {
            case BOUserRequestType.BOUserRequestType.REGENERATE_AUTH_CODE:
                return (
                    <div>
                        <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                            {t('common.requestedFor')}
                        </Typography>
                        <BOUserRequestChip type={BOUserRequestChipType.ReGenerateAuthCodes} />
                    </div>
                );
            case BOUserRequestType.BOUserRequestType.FORCE_RESET_PASSWORD:
                return (
                    <div>
                        <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                            {t('common.requestedFor')}
                        </Typography>
                        <BOUserRequestChip type={BOUserRequestChipType.ForceResetPassword} />
                    </div>
                );

            default:
                return <></>;
        }
    };

    const getApproveAuthCodeDialog = (): React.ReactElement => {
        if (checkRequestStore.authCodeURL) {
            return (
                <ApproveAuthCodeRequestDialog
                    isDialogOpen={isApproveAuthCodeDialogOpen}
                    onClose={(): void => {
                        setIsApproveAuthCodeDialogOpen(false);
                        fetchBOUserDetails();
                    }}
                    onDownloadClick={(): void => {
                        if (checkRequestStore.authCodeURL) {
                            downloadFile(checkRequestStore.authCodeURL);
                        } else {
                            loggerStore.debug(
                                'Cannot find authCodeURL when download button is clicked'
                            );
                            // we are not setting error dialog as true here as it is handle in store and we don't want to end-up in a situation were we see two dialogs
                        }
                    }}
                />
            );
        } else {
            return <></>;
        }
    };

    const SuccessDialogBox = (): React.ReactElement => {
        return (
            <SuccessDialog
                title={t('common.approveRequest')}
                successMessage={t('boUser.mailHasBeenSentWithTheTemporaryPassword')}
                isDialogOpen={isOpenSuccessDialog}
                onCancel={(): void => {
                    setIsOpenSuccessDialog(false);
                }}
            />
        );
    };

    const getDownloadAuthCodeDialog = (): React.ReactElement => {
        if (newBOUserRequestDetailsStore.newBOUserDetails?.emailId) {
            return (
                <AddBOUserDownloadAuthCodeDialog
                    isDialogOpen={isDownloadAuthCodeDialogOpen}
                    onClose={(): void => {
                        setIsDownloadAuthCodeDialogOpen(false);
                        navigate('/bo/view-requests', { replace: true });
                    }}
                    title={t('boUser.approveAddBOUserRequest')}
                    email={newBOUserRequestDetailsStore.newBOUserDetails?.emailId}
                    onDownloadClick={(): void => {
                        if (newBOUserCheckStore.authCodeUrl) {
                            downloadFile(newBOUserCheckStore.authCodeUrl);
                        } else {
                            loggerStore.debug(
                                'Cannot find authCodeURL when download button is clicked'
                            );
                            setIsErrorDialogOpen(true);
                        }
                    }}
                />
            );
        } else {
            loggerStore.debug(
                'Cannot find emailId in NewBOUserRequestDetailsStore while creating DownloadAuthCodeDialog'
            );
            return <></>;
        }
    };

    const getNewBOUserRequestDetailsSection = (): React.ReactElement => {
        if (newBOUserRequestDetailsStore.requestDetails) {
            if (
                newBOUserRequestDetailsStore.requestDetails.evaluatorComment ||
                newBOUserRequestDetailsStore.requestDetails.withdrawalComment
            ) {
                return (
                    <Box sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
                        <RequestDetailsSection
                            requestDetails={newBOUserRequestDetailsStore.requestDetails}
                        />
                    </Box>
                );
            } else if (newBOUserRequestDetailsStore.requestDetails.requesterComment) {
                return (
                    <PendingRequestCard
                        onApproveClick={onApproveClick}
                        onDenyClick={onDenyClick}
                        onWithdrawClick={onWithdrawClick}
                        showApproveAndDenySection={showApproveAndDenySection}
                        showWithdrawSection={showWithdrawSection()}
                        comment={newBOUserRequestDetailsStore.requestDetails?.requesterComment}
                    />
                );
            }
        }
        return <></>;
    };

    const getErrorString = (): string => {
        const error = store.error ?? newBOUserRequestDetailsStore.error;
        switch (error) {
            case NewBOUserDetailsStoreErrors.RequestAlreadyChecked:
                return t('common.requestAlreadyChecked');
            default:
                return t('common.somethingWentWrongProcessingRequest');
        }
    };

    const renderUserDetails = (): React.ReactElement => {
        if (loading) {
            return <LoadingState />;
        }
        if (
            store.error === BOUserDetailErrors.InvalidBoUserId ||
            (newBOUserRequestDetailsStore.error &&
                newBOUserRequestDetailsStore.error !== NetworkingError.InternalError)
        ) {
            return <ErrorState errorMessage={getErrorString()} />;
        }

        return (
            <StyledStack spacing="32px">
                {store.requestDetails && userId && (
                    <PendingRequestCard
                        comment={store.requestDetails.comment}
                        onApproveClick={onApproveClick}
                        onDenyClick={onDenyClick}
                        onWithdrawClick={onWithdrawClick}
                        showApproveAndDenySection={showApproveAndDenySection}
                        showWithdrawSection={showWithdrawSection()}>
                        {renderRequestedForSection()}
                    </PendingRequestCard>
                )}
                {store.basicDetails && userId && (
                    <>
                        <BOUserBasicDetails
                            userId={userId}
                            details={store.basicDetails}
                            requestDetails={store.requestDetails}
                        />
                        {shouldRequestProfileSection &&
                            store.basicDetails.status !== UserStatus.UserStatus.ARCHIVED && (
                                <BOUserProfileManagement
                                    userIsActive={
                                        store.basicDetails.status === UserStatus.UserStatus.ACTIVE
                                    }
                                    userId={userId}
                                    setIsLoading={setIsLoading}
                                    onSuccessfulRequest={(): void => {
                                        // since the request has been raised successfully, we will fetch the user details to show the request summary
                                        fetchBOUserDetails();
                                    }}
                                />
                            )}
                        <BORequestHistory userId={userId} setIsLoading={setIsLoading} />
                    </>
                )}
                {requestId && (
                    <>
                        {newBOUserRequestDetailsStore.requestDetails &&
                            getNewBOUserRequestDetailsSection()}
                        {newBOUserRequestDetailsStore.newBOUserDetails && (
                            <NewBOUserBasicDetails
                                newBOUser={newBOUserRequestDetailsStore.newBOUserDetails}
                            />
                        )}
                    </>
                )}
            </StyledStack>
        );
    };

    const getErrorMessage = (): string => {
        let error;
        if (validateBOUserDetailsStore.error) {
            error = validateBOUserDetailsStore.error;
        } else if (addEditBOUserStore.error) {
            error = addEditBOUserStore.error;
        } else {
            error = boRoleStore.error;
        }
        switch (error) {
            case ValidateBOUserErrors.UserAlreadyArchived:
                return t('boUser.userAlreadyArchived');
            case ValidateBOUserErrors.UserAlreadyInactive:
                return t('boUser.userAlreadyInactive');
            case ValidateBOUserErrors.AgentCannotBeAnAgentManager:
                return t('boUser.agentCannotBeAnAgentManager');
            case ValidateBOUserErrors.UserAppliedToBeAnAgent:
                return t('boUser.userAppliedToBeAnAgent');
            case ValidateBOUserErrors.CannotUpdateSelfProfile:
                return t('boUser.cannotUpdateSelfProfile');
            case ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId:
                return t('boUser.requestAlreadyRaisedWithSameEmailId');
            case ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber:
                return t('boUser.requestAlreadyRaisedWithSamePhoneNumber');
            case NetworkingError.InternalError:
                return t('common.somethingWentWrongProcessingRequest');
            default:
                return t('common.somethingWentWrongProcessingRequest');
        }
    };

    const EditBOUserDialogBox = (): React.ReactElement => {
        return (
            <>
                {store.basicDetails && userId && (
                    <AddEditBOUserDialog
                        isDialogOpen={openEditBOUserDialog}
                        onDialogClose={(): void => {
                            setOpenEditBOUserDialog(false);
                        }}
                        userInfo={{ userId, userDetails: store.basicDetails }}
                        setIsErrorDialogOpen={(): void => {
                            setDialogBoxTitle(t('common.requestUpdate'));
                            setErrorDialogMessage(getErrorMessage());
                            setIsErrorDialogOpen(true);
                        }}
                        onSuccess={(): void => {
                            setOpenEditBOUserDialog(false);
                            fetchBOUserDetails();
                        }}
                    />
                )}
            </>
        );
    };

    const getPageHeaderActionElement = (): ActionElement | undefined => {
        return {
            primaryButton: showEditButton
                ? {
                      title: t('boUser.editUser'),
                      onClick: (): void => {
                          setOpenEditBOUserDialog(true);
                      }
                  }
                : undefined
        };
    };

    const getRequestCheckDialogTitle = (): string => {
        if (userId) {
            if (checkRequestStore.status === Status.APPROVED) {
                return t('common.approveUpdate');
            } else {
                return t('common.denyUpdate');
            }
        } else {
            if (newBOUserCheckStore.status === Status.APPROVED) {
                return t('boUser.approveAddBOUserRequest');
            } else {
                return t('boUser.denyAddBOUserRequest');
            }
        }
    };

    const getRequestCheckDialogButtonTitle = (): string => {
        if (userId) {
            if (checkRequestStore.status === Status.APPROVED) {
                return t('common.approveUpdate');
            } else {
                return t('common.denyUpdate');
            }
        } else {
            if (newBOUserCheckStore.status === Status.APPROVED) {
                return t('bcnUser.approveRequest');
            } else {
                return t('bcnUser.denyRequest');
            }
        }
    };

    const getForceResetPasswordConfirmation = (): React.ReactElement => {
        return (
            <RequestConfirmationDialog
                isDialogOpen={isConfirmationDialogOpen}
                onClose={(): void => {
                    setForceResetPasswordComment(null);
                    setIsConfirmationDialogOpen(false);
                }}
                onSubmit={(_comment): void => {
                    setIsConfirmationDialogOpen(false);
                    onClickApproveOrDenyUpdate(_comment);
                }}
                title={
                    checkRequestStore.status === Status.APPROVED
                        ? t('common.approveRequest')
                        : t('common.denyRequest')
                }
                description={confirmationDialogDescription}
                comment={forceResetPasswordComment}
                primaryButtonText={t('common.approveRequest')}
            />
        );
    };

    const RequestCheckDialogBox = (): React.ReactElement => {
        return (
            <RequestDialog
                isDialogOpen={isRequestCheckDialogOpen}
                title={getRequestCheckDialogTitle()}
                buttonTitle={getRequestCheckDialogButtonTitle()}
                onSubmit={async (comment: string): Promise<void> => {
                    if (
                        checkRequestStore.status === Status.APPROVED &&
                        store.requestDetails?.requestType ===
                            BOUserRequestType.BOUserRequestType.FORCE_RESET_PASSWORD
                    ) {
                        setIsRequestCheckDialogOpen(false);
                        setIsConfirmationDialogOpen(true);
                        setForceResetPasswordComment(comment);
                        setConfirmationDialogDescription(
                            t('boUser.forceResetPasswordConfirmationDescription')
                        );
                    } else {
                        await onClickApproveOrDenyUpdate(comment);
                    }
                }}
                onClose={(): void => {
                    setIsRequestCheckDialogOpen(false);
                    checkRequestStore.resetComment();
                }}
            />
        );
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    withdrawRequestStore.resetComment();
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={dialogBoxTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    checkRequestStore.removeError();
                    newBOUserCheckStore.removeError();
                    withdrawRequestStore.removeError();
                    boRoleStore.removeError();
                    validateBOUserDetailsStore.removeError();
                    addEditBOUserStore.removeError();
                    store.removeError();
                }}
            />
        );
    };

    const PendingRequestDialogBox = (): React.ReactElement => {
        return (
            <BOPendingRequestDialog
                title={
                    checkRequestStore.status === Status.APPROVED
                        ? t('boUser.approveArchiveRequest')
                        : t('boUser.denyArchiveRequest')
                }
                isDialogOpen={isPendingRequestDialogOpen}
                onClose={function (): void {
                    setIsPendingRequestDialogOpen(false);
                }}
                checkRequestStore={checkRequestStore}
            />
        );
    };

    return (
        <Stack direction="column" alignItems={'center'}>
            <LoadingIndicator isLoading={isLoading} />
            <PageHeader
                title={t('boUser.boUserDetailsTitle')}
                subtitle={t('boUser.boUserDetailsSubtitle')}
                actionElement={getPageHeaderActionElement()}
            />
            <BreadcrumbComponent currentLabel={t('boUser.boUserDetailsTitle')} />
            {renderUserDetails()}
            {isErrorDialogOpen && ErrorDialogBox()}
            {isRequestCheckDialogOpen && RequestCheckDialogBox()}
            {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
            {isPendingRequestDialogOpen && PendingRequestDialogBox()}
            {openEditBOUserDialog && EditBOUserDialogBox()}
            {isDownloadAuthCodeDialogOpen && getDownloadAuthCodeDialog()}
            {isApproveAuthCodeDialogOpen && getApproveAuthCodeDialog()}
            {isConfirmationDialogOpen && getForceResetPasswordConfirmation()}
            {isOpenSuccessDialog && SuccessDialogBox()}
        </Stack>
    );
});
