import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface ApproveDenyActionSectionProps {
    onDenyClick: () => void;
    onApproveClick: () => void;
}

export function ApproveDenyActionSection({
    onDenyClick,
    onApproveClick
}: ApproveDenyActionSectionProps): React.ReactElement {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px',
                borderTop: `1px solid ${palette.outline[200]}`,
                justifyContent: 'flex-end'
            }}>
            <Button
                name="secondary-button"
                variant="outlined-neutral"
                size="small"
                title={t('common.deny')}
                onClick={onDenyClick}
                style={{
                    marginRight: '8px',
                    width: '80px'
                }}
            />
            <Button
                name="secondary-button"
                variant="outlined-neutral"
                size="small"
                title={t('common.approve')}
                onClick={onApproveClick}
                style={{
                    width: '80px'
                }}
            />
        </Box>
    );
}
