import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { ChangePasswordStore } from './ChangePasswordStore';
import { ChangeProfileImageStore } from './ChangeProfileImageStore';
import { DeleteProfileImageStore } from './DeleteProfileImageStore';
import { ProfileDetailsStore } from './ProfileDetailsStore';
import { RegenerateAuthCodesStore } from './RegenerateAuthCodesStore';

export const useProfileDetailsStore = (): Instance<typeof ProfileDetailsStore> => {
    return useRootStore().homeStore.profile.profileDetailsStore;
};

export const useChangePasswordStore = (): Instance<typeof ChangePasswordStore> => {
    return useRootStore().homeStore.profile.changePasswordStore;
};

export const useRegenerateAuthCodesStore = (): Instance<typeof RegenerateAuthCodesStore> => {
    return useRootStore().homeStore.profile.regenerateAuthCodesStore;
};

export const useDeleteProfileImageStore = (): Instance<typeof DeleteProfileImageStore> => {
    return useRootStore().homeStore.profile.deleteProfileImageStore;
};

export const useChangeProfileImageStore = (): Instance<typeof ChangeProfileImageStore> => {
    return useRootStore().homeStore.profile.changeProfileImageStore;
};
