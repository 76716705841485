import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import { getFormattedPhoneNumber, getGenderTranslation } from '../../common/utils/UIUtils';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { NewBOUserDetailsModel } from '../store/NewBOUserDetailsStore';
import { getBORoleName } from '../utils/UIUtils';

export interface NewBOUserBasicDetailsSectionProp {
    newBOUser: Instance<typeof NewBOUserDetailsModel>;
}

export const NewBOUserBasicDetails = ({
    newBOUser
}: NewBOUserBasicDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();

    const newBOUserDetailSectionCells: { label: string; cell: SectionRowCellType }[] = [
        {
            label: t('common.firstName'),
            cell: { text: newBOUser.firstName, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.lastName'),
            cell: { text: newBOUser.lastName ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.otherNames'),
            cell: { text: newBOUser.otherNames ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.mobileNumber'),
            cell: {
                text: getFormattedPhoneNumber(newBOUser.phoneNumber),
                type: SectionRowCellEnum.Unstyled
            }
        },

        {
            label: t('common.emailId'),
            cell: {
                text: newBOUser.emailId ?? '-',
                type: SectionRowCellEnum.Unstyled,
                showEllipsis: true
            }
        },

        {
            label: t('common.country'),
            cell: { text: newBOUser.countryName, type: SectionRowCellEnum.Unstyled }
        },

        {
            label: t('common.gender'),
            cell: {
                text: getGenderTranslation(t, newBOUser.gender),
                type: SectionRowCellEnum.Unstyled
            }
        },
        {
            label: t('boUser.userRoles'),
            cell: {
                type: SectionRowCellEnum.UserRoleChips,
                roles: newBOUser.roles.map(({ workflow, name }) => getBORoleName(workflow, name))
            }
        }
    ];

    const getDetailCells = (): React.ReactElement[] => {
        return newBOUserDetailSectionCells.map(({ label, cell }) => (
            <SectionRowComponentWithLabel key={label} label={label} cell={cell} />
        ));
    };

    return (
        <Section
            title={t('common.basicDetails')}
            allowViewChange={false}
            content={getDetailCells()}
        />
    );
};
