import { Grid } from '@mui/material';
import { DropdownInputField, DropdownItem } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequestStatusOptionsWithAny } from '../utils/StatusUIUtils';
import { TableFilterContainer } from './TableFilterContainer';

interface RulesSearchBarProps {
    filter: string;
    setFilter: (filter: string) => void;
}

export const SingleDropdownFilter = ({
    filter,
    setFilter
}: RulesSearchBarProps): React.ReactElement => {
    const { t } = useTranslation();
    const options = getRequestStatusOptionsWithAny();
    const [dropdownData, setDropdownData] = useState(filter);

    const onChangeDropdownInput = (inputValue: DropdownItem): void => {
        setDropdownData(inputValue.value);
        setFilter(inputValue.value);
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchDropdown"
                        value={dropdownData}
                        onSelect={onChangeDropdownInput}
                        label={t('common.requestStatus')}
                        options={options}
                    />
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
