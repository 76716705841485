import { flow, Instance, types } from 'mobx-state-tree';
import {
    BOGender,
    BOUserRequestSummary,
    GetBOUserRequestSummaryRPC,
    RequestStatus,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useGetBOUserRequestsSummaryRPCClient } from '../rpcs/RPC';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';
import { getBORoleModel, BORoleModel } from '../models/BORoleModel';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { getBORoleDiffModel, BORoleDiffModel } from '../models/BORoleDiffModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BOUserRequestSummaryErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestIsPending = 'REQUEST_IS_PENDING'
}

export enum RequestType {
    Add = 'Add',
    Edit = 'Edit',
    Reactivate = 'Reactivate',
    Deactivate = 'Deactivate',
    Archive = 'Archive',
    ForceResetPassword = 'ForceResetPassword',
    RegenerateAuthCode = 'RegenerateAuthCode'
}

export const BOUserRequestSummaryAddUser = types.model({
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherName: types.maybeNull(types.string),
    phoneNumber: types.string,
    emailId: types.string,
    country: types.string,
    gender: types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender)),
    roles: types.array(BORoleModel)
});

export const BOUserProfileChanges = types.model({
    label: types.string,
    oldValue: types.maybeNull(types.string),
    newValue: types.maybeNull(types.string)
});

export const BOUserRequestSummaryEdit = types.model({
    profileChanges: types.maybeNull(types.array(BOUserProfileChanges)),
    roles: types.maybeNull(types.array(BORoleDiffModel))
});

export const BOUserRequestSummaryStore = types
    .model({
        comment: types.maybeNull(BOUserCommentModel),
        requestStatus: types.enumeration<RequestStatus.RequestStatus>(
            'RequestStatus',
            Object.values(RequestStatus.RequestStatus)
        ),
        newUser: types.maybeNull(BOUserRequestSummaryAddUser),
        editUser: types.maybeNull(BOUserRequestSummaryEdit),
        oldStatus: types.maybeNull(
            types.enumeration<UserStatus.UserStatus>(
                'UserStatus',
                Object.values(UserStatus.UserStatus)
            )
        ),
        requestType: types.maybeNull(
            types.enumeration<RequestType>('RequestType', Object.values(RequestType))
        ),
        error: types.maybeNull(
            types.union(
                types.enumeration<BOUserRequestSummaryErrors>(
                    'BOUserRequestSummaryErrors',
                    Object.values(BOUserRequestSummaryErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        fetchBOUserRequestSummary: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetBOUserRequestSummaryRPC.Request(new LeoUUID(requestId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetBOUserRequestSummaryRPC.Response,
                    GetBOUserRequestSummaryRPC.Errors.Errors
                > = yield useGetBOUserRequestsSummaryRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.comment = getBOUserCommentModel(response.comment);
                    store.requestStatus = response.requestStatus;

                    if (response.requestSummary instanceof BOUserRequestSummary.AddUser) {
                        store.newUser = BOUserRequestSummaryAddUser.create({
                            firstName:
                                response.requestSummary.boUserBasicDetails.username.firstName.text,
                            lastName:
                                response.requestSummary.boUserBasicDetails.username.lastName?.text,
                            otherName: response.requestSummary.boUserBasicDetails.otherNames?.text,
                            phoneNumber:
                                response.requestSummary.boUserBasicDetails.phoneNumber.phoneNumber,
                            emailId: response.requestSummary.boUserBasicDetails.emailId.emailId,
                            country: getTranslatedString(
                                response.requestSummary.boUserBasicDetails.countryName
                            ),
                            gender: response.requestSummary.boUserBasicDetails.gender,
                            roles: response.requestSummary.boUserBasicDetails.roles.map((role) =>
                                getBORoleModel(role)
                            )
                        });
                        store.requestType = RequestType.Add;
                    } else if (response.requestSummary instanceof BOUserRequestSummary.EditUser) {
                        store.editUser = BOUserRequestSummaryEdit.create({
                            profileChanges: response.requestSummary.profileChanges?.map((change) =>
                                BOUserProfileChanges.create({
                                    label: getTranslatedString(change.label),
                                    oldValue: change.oldValue,
                                    newValue: change.newValue
                                })
                            ),
                            roles: response.requestSummary.roles?.map((role) =>
                                getBORoleDiffModel(role)
                            )
                        });
                        store.requestType = RequestType.Edit;
                    } else if (
                        response.requestSummary instanceof BOUserRequestSummary.RegenerateAuthCode
                    ) {
                        store.requestType = RequestType.RegenerateAuthCode;
                    } else if (
                        response.requestSummary instanceof BOUserRequestSummary.ForceResetPassword
                    ) {
                        store.requestType = RequestType.ForceResetPassword;
                    } else if (
                        response.requestSummary instanceof BOUserRequestSummary.DeactivateUser
                    ) {
                        store.oldStatus = UserStatus.UserStatus.ACTIVE;
                        store.requestType = RequestType.Deactivate;
                    } else if (
                        response.requestSummary instanceof BOUserRequestSummary.ReactivateUser
                    ) {
                        store.oldStatus = UserStatus.UserStatus.DEACTIVATED;
                        store.requestType = RequestType.Reactivate;
                    } else {
                        store.oldStatus = UserStatus.UserStatus.DEACTIVATED;
                        store.requestType = RequestType.Archive;
                    }
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BOUserRequestSummaryErrors.InvalidRequestId:
                            store.error = BOUserRequestSummaryErrors.InvalidRequestId;
                            break;
                        case BOUserRequestSummaryErrors.RequestIsPending:
                            store.error = BOUserRequestSummaryErrors.RequestIsPending;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBOUserRequestSummaryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetBOUserRequestSummaryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetBOUserRequestSummaryRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        resetError(): void {
            store.error = null;
        }
    }));

export const createBOUserRequestSummaryStore = (): Instance<typeof BOUserRequestSummaryStore> => {
    return BOUserRequestSummaryStore.create({
        requestStatus: RequestStatus.RequestStatus.PENDING // default Value
    });
};
