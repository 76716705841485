import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useCountdownTimer = (
    countdown: number
): [number, Dispatch<SetStateAction<number>>] => {
    const [countdownValue, setCountdownValue] = useState<number>(0);

    useEffect(() => {
        setCountdownValue(countdown);
    }, [countdown]);

    useEffect(() => {
        if (countdownValue === 0) {
            setCountdownValue(0);
        }
        if (!countdownValue) return;
        const intervalId = setInterval(() => {
            setCountdownValue(countdownValue - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countdownValue]);

    return [countdownValue, setCountdownValue];
};
