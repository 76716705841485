import { BackOfficeChangeProfileImageRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, Instance, flow } from 'mobx-state-tree';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useBackOfficeChangeProfileImageRPCClient } from '../rpcs/RPC';
import { createUploadProfileImageStore, UploadProfileImageStore } from './UploadProfileImageStore';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum ChangeProfileImageStoreErrors {
    InvalidProfileImageId = 'INVALID_PROFILE_IMAGE_ID',
    IncorrectProfileImageResolution = 'INCORRECT_PROFILE_IMAGE_RESOLUTION'
}

const getStoreError = (
    error: BackOfficeChangeProfileImageRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): ChangeProfileImageStoreErrors | NetworkingError => {
    switch (error.code) {
        case ChangeProfileImageStoreErrors.InvalidProfileImageId:
            return ChangeProfileImageStoreErrors.InvalidProfileImageId;
        case ChangeProfileImageStoreErrors.IncorrectProfileImageResolution:
            return ChangeProfileImageStoreErrors.IncorrectProfileImageResolution;
        default:
            loggerStore.error(
                `Unhandled error: ${error} occurred in BackOfficeChangeProfileImageRPC`
            );
            return NetworkingError.InternalError;
    }
};

export const ChangeProfileImageStore = types
    .model({
        profileImage: types.maybeNull(types.string),
        uploadProfileImageStore: UploadProfileImageStore,
        updateFileId: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<ChangeProfileImageStoreErrors>(
                    'ChangeProfileImageStoreErrors',
                    Object.values(ChangeProfileImageStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        setFileId(_fileId: string): void {
            store.updateFileId = _fileId;
        },
        updateProfileImage: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.updateFileId) {
                    const request = new BackOfficeChangeProfileImageRPC.Request(
                        new LeoUUID(store.updateFileId)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        BackOfficeChangeProfileImageRPC.Response,
                        BackOfficeChangeProfileImageRPC.Errors.Errors
                    > = yield useBackOfficeChangeProfileImageRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        store.profileImage = response && getImageUrl(response.profileImage);
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in BackOfficeChangeProfileImageRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in BackOfficeChangeProfileImageRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        fileType(): string[] {
            return ['jpeg', 'png', 'jpg', 'JPG', 'PNG', 'JPEG'];
        },
        handleFileSize(size: number): boolean {
            let isValidSize: boolean;
            const fileSize = size;
            //minSize is 100kb
            const minSize = 100000;
            //maxSize is 5MB
            const MaxSize = 5000000;
            if (fileSize < minSize) {
                isValidSize = false;
            } else if (fileSize > MaxSize) {
                isValidSize = false;
            } else {
                isValidSize = true;
            }
            return isValidSize;
        }
    }));

export const createChangeProfileImageStore = (): Instance<typeof ChangeProfileImageStore> => {
    return ChangeProfileImageStore.create({
        uploadProfileImageStore: createUploadProfileImageStore()
    });
};
