import {
    BOUserComment,
    UserName,
    BOName,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    BitmapImageType,
    GetAgentTransactionDetailsRPC,
    Comment,
    Currency,
    Amount,
    AgentTransactionDetail,
    LocalizedText,
    AgentTransactionRequestDetails
} from '@resolut-tech/bcn-rpcs';
import { AgentTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/agentTransactionDetail';
import { LeoUUID, LeoTimestamp, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const multiResolutionBitmapImage = new MultiResolutionBitmapImage(
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    )
);

const boUserComment = new BOUserComment(
    new LeoUUID(),
    new UserName(new BOName('Wade'), new BOName('Warren')),
    multiResolutionBitmapImage,
    new LeoTimestamp('2022-09-30T02:03:59Z'),
    new Comment('The transaction was done by mistake by the user.')
);

const transactionDetails = [
    new AgentTransactionDetail(
        new LocalizedText('Transaction Amount'),
        new AgentTransactionDetailEnums.CellType.Bold('MWK 5,000')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Transaction Status'),
        new AgentTransactionDetailEnums.CellType.PrefixIcon(
            AgentTransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
            'Success'
        )
    ),
    new AgentTransactionDetail(
        new LocalizedText('Time, Date'),
        new AgentTransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Transaction Type'),
        new AgentTransactionDetailEnums.CellType.Unstyled('Payments')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Request ID'),
        new AgentTransactionDetailEnums.CellType.Copy('Ik76n7xC')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Transaction ID'),
        new AgentTransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Sender Photo'),
        new AgentTransactionDetailEnums.CellType.ProfilePhoto(
            new URL(
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxAz3EiKBkATAw_FkIECzJPGQXdFt0jO9_GgNFg6_VMYTS0rtCrK98E2e56YRPJbq0TU&usqp=CAU'
            )
        )
    ),
    new AgentTransactionDetail(
        new LocalizedText('Sender Details'),
        new AgentTransactionDetailEnums.CellType.Unstyled('Esther Howard, +260-6534982309')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Sender Address'),
        new AgentTransactionDetailEnums.CellType.Unstyled(
            'Jackson Boulevard, P O Box 562, Blantyre, Malawi'
        )
    ),
    new AgentTransactionDetail(
        new LocalizedText('Sender National ID'),
        new AgentTransactionDetailEnums.CellType.Unstyled('LBNOMN09')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Sender Agent'),
        new AgentTransactionDetailEnums.CellType.Unstyled('Eric Gbozo, +260-6534982309')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Receiver Details'),
        new AgentTransactionDetailEnums.CellType.Unstyled('Jane Cooper, +260-65349738271')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Receiver National ID'),
        new AgentTransactionDetailEnums.CellType.Unstyled('NFKDLE02')
    ),
    new AgentTransactionDetail(
        new LocalizedText('Receiver Agent Details'),
        new AgentTransactionDetailEnums.CellType.Unstyled('Melchior Houessou, +260-65349738271')
    )
];

export class MockGetAgentTransactionDetailsRPCImpl extends GetAgentTransactionDetailsRPC {
    execute(
        _request: GetAgentTransactionDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetAgentTransactionDetailsRPC.Response,
            GetAgentTransactionDetailsRPC.Errors.Errors
        >
    > {
        const response = new GetAgentTransactionDetailsRPC.Response(
            transactionDetails,
            new Amount(50000000, new Currency('MWK')),
            true,
            new AgentTransactionRequestDetails.Pending(new LeoUUID(), boUserComment)
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetAgentTransactionDetailsRPC.Response,
                GetAgentTransactionDetailsRPC.Errors.Errors
            >
        >;
    }
}
