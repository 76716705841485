import { SubmitResetPasswordRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../networking/MockExecuteHelper';

export class MockSubmitResetPasswordRPCImpl extends SubmitResetPasswordRPC {
    execute(
        _request: SubmitResetPasswordRPC.Request
    ): Promise<
        LeoRPCResult<SubmitResetPasswordRPC.Response, SubmitResetPasswordRPC.Errors.Errors>
    > {
        const response = new SubmitResetPasswordRPC.Response(
            new SubmitResetPasswordRPC.ResponseEnums.Result.LoggedInWeb()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<SubmitResetPasswordRPC.Response, SubmitResetPasswordRPC.Errors.Errors>
        >;
    }
}
