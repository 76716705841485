import { diffLines, formatLines } from 'unidiff';
import { File } from 'gitdiff-parser';
import { parseDiff } from './ReactDiffViewParser';

interface DiffRow<RuleModel> {
    oldRule?: RuleModel;
    updatedRule?: RuleModel;
}

// parseRules is a generic function which takes in oldRules, updatedRules, helper functions to convert rule model to string and string to rule model.
export const parseRules = <RuleModel>(
    oldRules: RuleModel[],
    updatedRules: RuleModel[],
    ruleModelToString: (rule: RuleModel) => string,
    stringToRuleModel: (rule: string) => RuleModel
): DiffRow<RuleModel>[] => {
    // Here the rule model array is converted to string where each model is joined by `,` and at the end all the rules are joined by `\n` new line character.
    // Both oldRules and updatedRules are converted to string so that we can compare both rules line by line.
    const oldRulesString = oldRules.map((rule) => ruleModelToString(rule)).join('\n');
    const updatedRulesString = updatedRules.map((rule) => ruleModelToString(rule)).join('\n');
    // When the rules strings are compare line by line, we get a diffText file which contains changes.
    // Here context describes how many lines of context should be included. In our case all the lines are needed. Hence setting the context as old rules array length or updated rules array length whichever is maximum.
    const diffText = formatLines(diffLines(oldRulesString, updatedRulesString), {
        context: Math.max(oldRules.length, updatedRules.length)
    });
    // parseDiff convert the diffText and creates an array of changes which can of the following types: 'delete', 'insert', 'normal'.
    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' }) as File[];

    // Return an empty array to indicate there are no changes.
    if (!diff.hunks.length) return [];
    const [{ changes }] = diff.hunks;

    const diffRows: DiffRow<RuleModel>[] = [];
    for (let i = 0; i < changes.length; i++) {
        const change = changes[i];
        switch (change.type) {
            // When change type='delete', There can two scenarios
            // 1. If the next change type is 'insert', then the change is considered as 'modify'.
            // 2. Next change type is 'normal' or next change can be `undefined` [if the current change is the last element in array], then the change is considered as 'delete'.
            case 'delete':
                const nextChange = changes[i + 1];
                if (nextChange && nextChange.type === 'insert') {
                    diffRows.push({
                        oldRule: stringToRuleModel(change.content),
                        updatedRule: stringToRuleModel(nextChange.content)
                    });
                    // Since the next change is included, it will be skipped in the next iteration.
                    i++;
                } else {
                    diffRows.push({
                        oldRule: stringToRuleModel(change.content)
                    });
                }
                break;
            // When change type='insert', then the change is considered as 'insert'.
            case 'insert':
                diffRows.push({
                    updatedRule: stringToRuleModel(change.content)
                });
                break;
            // When change type='normal', then the change is considered as 'view'.
            case 'normal':
                diffRows.push({
                    oldRule: stringToRuleModel(change.content),
                    updatedRule: stringToRuleModel(change.content)
                });
        }
    }
    return diffRows;
};
