import {
    GetUnclaimedTransactionDetailsRPC,
    LocalizedText,
    UnclaimedTransactionDetail
} from '@resolut-tech/bcn-rpcs';
import { UnclaimedTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/unclaimedTransactionDetail';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const getTransactionDetails = (): UnclaimedTransactionDetail[] => {
    return [
        new UnclaimedTransactionDetail(
            new LocalizedText('Transaction Amount'),
            new UnclaimedTransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Time, Date'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Transaction Type'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('Money Transfer')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Transaction ID'),
            new UnclaimedTransactionDetailEnums.CellType.Copy(
                '123e4567-e89b-12d3-a456-426614174000'
            )
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Transaction Status'),
            new UnclaimedTransactionDetailEnums.CellType.PrefixIcon(
                UnclaimedTransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.MOVED_TO_UNCLAIMED_FUNDS,
                'Moved to unclaimed funds'
            )
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Sender Photo'),
            new UnclaimedTransactionDetailEnums.CellType.ProfilePhoto(
                new URL(
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxAz3EiKBkATAw_FkIECzJPGQXdFt0jO9_GgNFg6_VMYTS0rtCrK98E2e56YRPJbq0TU&usqp=CAU'
                )
            )
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Sender Details'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('Esther Howard, +260-6534982309')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Sender National ID'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('NFKDLE02')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Receiver Details'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('Bessie Cooper, +260-6538927680')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Receiver National ID'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('LOAS84HD')
        ),
        new UnclaimedTransactionDetail(
            new LocalizedText('Agent Details'),
            new UnclaimedTransactionDetailEnums.CellType.Unstyled('Devon Lane, +260-6535477381')
        )
    ];
};
export class MockGetUnclaimedTransactionDetailsRPCImpl extends GetUnclaimedTransactionDetailsRPC {
    execute(
        _request: GetUnclaimedTransactionDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetUnclaimedTransactionDetailsRPC.Response,
            GetUnclaimedTransactionDetailsRPC.Errors.Errors
        >
    > {
        const response = new GetUnclaimedTransactionDetailsRPC.Response(getTransactionDetails());
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetUnclaimedTransactionDetailsRPC.Response,
                GetUnclaimedTransactionDetailsRPC.Errors.Errors
            >
        >;
    }
}
