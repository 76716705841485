import { RequestFeeDeterminationRuleUpdateRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestFeeDeterminationRuleUpdateRPCImpl extends RequestFeeDeterminationRuleUpdateRPC {
    execute(
        _request: RequestFeeDeterminationRuleUpdateRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestFeeDeterminationRuleUpdateRPC.Response,
            RequestFeeDeterminationRuleUpdateRPC.Errors.Errors
        >
    > {
        const response = new RequestFeeDeterminationRuleUpdateRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestFeeDeterminationRuleUpdateRPC.Response,
                RequestFeeDeterminationRuleUpdateRPC.Errors.Errors
            >
        >;
    }
}
