import React, { useEffect, useState } from 'react';
import { Typography, Box, styled, Stack } from '@mui/material';
import {
    DropdownInputField,
    DropdownItem,
    LoadingIndicator,
    PasswordInputField,
    TextInputField,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { MobileNumberEntryErrors } from '../stores/MobileNumberEntryStore';
import { observer } from 'mobx-react';
import { useMobileNumberEntryStore } from '../stores/hooks';
import { useNavigate } from 'react-router-dom';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { AuthErrorDialog } from '../components/AuthErrorDialog';
import { AlertCircle } from '../../../assets/icons/AlertCircle';
import { CountryViewModel } from '../../store/country-list/CountryListStore';
import { LeoErrors } from '../../home/common/errors/LeoErrors';
import { NetworkingError } from '../../error/store/ErrorStore';
import { Button } from '../../common/components/Button';

export const EnterMobileNumber = observer((): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();
    const store = useMobileNumberEntryStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [country, setCountry] = useState<CountryViewModel | null>(null);
    const [countries, setCountries] = useState<CountryViewModel[] | null>(null);

    const fetchCountryList = async (): Promise<void> => {
        await store.fetchCountries();
        setCountries(store.countryList());
    };

    useEffect(() => {
        fetchCountryList();
    }, []);

    useEffect(() => {
        // this is done to ensure that store is reset when screen is presented
        store.resetStore();
    }, []);

    useEffect(() => {
        if (isDialogOpen === false) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            store.removeError();
        }
    }, [isDialogOpen]);

    const StyledButtonContainer = styled(Box)(() => ({
        width: '360px',
        height: '56px',
        marginTop: phoneNumberError || passwordError ? '60px' : '80px'
    }));

    const handleCountrySelection = (value: DropdownItem): void => {
        const _country = countries?.find((countryIterator) => {
            return countryIterator.name === value.value;
        });
        if (_country) {
            store.setCountry(_country);
            setCountry(_country);
            store.setPhoneNumber('');
        }
    };

    const phoneNumberErrorHelperText = (): string | undefined => {
        switch (store.error) {
            case MobileNumberEntryErrors.InvalidCredentials:
                return t('signIn.invalidCredentials');
            case LeoErrors.InvalidLeoPhoneNumberError:
                return t('signIn.invalidMobileNumber');
            default:
                return undefined;
        }
    };

    const passwordErrorHelperText = (): string | undefined => {
        switch (store.error) {
            case MobileNumberEntryErrors.InvalidCredentials:
                return t('signIn.invalidCredentials');
            case LeoErrors.InvalidPasswordError:
                return t('common.invalidPassword');
            default:
                return undefined;
        }
    };

    const authCountryDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    name: `${countryIterator.name} (${countryIterator.phoneCode})`,
                    value: countryIterator.name,
                    image: (
                        <img
                            src={countryIterator.flagURL}
                            style={{ height: '15px', width: '20px', marginRight: '8px' }}
                        />
                    )
                };
            });
        }
        return [];
    };

    const selectCountryDropdown = (): React.ReactElement => {
        return (
            <Box sx={{ width: '360px', height: '48px' }}>
                <DropdownInputField
                    name="selectCountry"
                    value={store.country?.name}
                    onSelect={handleCountrySelection}
                    label={t('common.selectCountry')}
                    options={authCountryDropdownItems()}
                />
            </Box>
        );
    };

    const onPhoneNumberTextChange = (value: string): void => {
        setPhoneNumberError(false);
        if (store.error === MobileNumberEntryErrors.InvalidCredentials) {
            setPasswordError(false);
        }
        store.removeError();
        store.setPhoneNumber(value);
    };

    const phoneNumberTextField = (): React.ReactElement => {
        return (
            <TextInputField
                name="phone-number"
                value={store.phoneNumber}
                type="number"
                onTextChange={onPhoneNumberTextChange}
                label={t('signIn.enterMobileNumber')}
                inputAdornmentPlacement="left"
                inputAdornmentText={country?.phoneCode}
                helperText={phoneNumberErrorHelperText()}
                helperIcon={phoneNumberError ? <AlertCircle /> : undefined}
                error={phoneNumberError}
                style={{
                    width: '360px'
                }}
            />
        );
    };

    const onPasswordChange = (value: string): void => {
        setPasswordError(false);
        if (store.error === MobileNumberEntryErrors.InvalidCredentials) {
            setPhoneNumberError(false);
        }
        store.removeError();
        store.setPassword(value);
    };

    const passwordTextField = (): React.ReactElement => {
        return (
            <PasswordInputField
                name="password"
                value={store.password}
                onTextChange={onPasswordChange}
                label={t('signIn.enterPassword')}
                helperText={passwordErrorHelperText()}
                helperIcon={passwordError ? <AlertCircle /> : undefined}
                error={passwordError}
                style={{
                    width: '360px'
                }}
            />
        );
    };

    const handleSignInUserError = (): void => {
        switch (store.error) {
            case LeoErrors.InvalidLeoPhoneNumberError:
                setPhoneNumberError(true);
                break;
            case MobileNumberEntryErrors.InvalidCredentials:
                setPhoneNumberError(true);
                setPasswordError(true);
                break;
            case LeoErrors.InvalidPasswordError:
                setPasswordError(true);
                break;
            case CommonAuthErrors.BoUserDisabled:
            case CommonAuthErrors.SignInTemporarilyBlocked:
            case NetworkingError.InternalError:
                setIsDialogOpen(true);
                break;
            default:
                setIsDialogOpen(true);
                break;
        }
    };

    const onSignInButtonTap = async (): Promise<void> => {
        setLoading(true);
        await store.signInBoUser();
        handleSignInUserError();
        setLoading(false);
        const passwordValidatedToken = store.passwordValidatedToken;
        if (passwordValidatedToken) {
            navigate('/auth/confirm-auth-code', { replace: true });
        }
    };

    const signInButton = (): React.ReactElement => {
        return (
            <StyledButtonContainer>
                <Button
                    name="signIn"
                    variant="filled"
                    size="large"
                    title={t('signIn.signIn')}
                    isDisabled={!Boolean(store.country && store.phoneNumber && store.password)}
                    color="primary"
                    onClick={async (): Promise<void> => {
                        await onSignInButtonTap();
                    }}
                    style={{
                        width: '360px'
                    }}
                />
            </StyledButtonContainer>
        );
    };

    const errorDialog = (): React.ReactElement => {
        let error: CommonAuthErrors | NetworkingError | undefined;
        if (store.error) {
            error = store.error as CommonAuthErrors | NetworkingError;
        }
        return <AuthErrorDialog open={isDialogOpen} error={error} setOpen={setIsDialogOpen} />;
    };

    return (
        <>
            <Typography
                sx={{ color: palette.label[200], ...typography.body2, marginBottom: '24px' }}>
                {t('signIn.signIntoAccount')}
            </Typography>
            <Stack spacing="24px">
                {selectCountryDropdown()}
                {phoneNumberTextField()}
                {passwordTextField()}
            </Stack>
            {signInButton()}
            {errorDialog()}
            <LoadingIndicator isLoading={loading} color="white" />
        </>
    );
});
