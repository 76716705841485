import {
    Box,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionValidationRuleModel } from '../models/TransactionValidationRuleModel';
import {
    stringToTransactionValidationRuleModel,
    transactionValidationRuleModelToString
} from '../utils/RuleDiffUtils';
import {
    RuleDiffOrientation,
    RuleDiffRowType,
    RuleDiffTableContentCell
} from '../../common/utils/rule-diff-utils/RuleDiffTableContentCell';
import { parseRules } from '../../common/utils/rule-diff-utils/RuleDiffUtils';
import { RuleDiffOrientationSelector } from '../../common/utils/rule-diff-utils/RulesDiffOrientationSelector';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import {
    getCountryNameFromCbsId,
    getFormattedStringFromNumber,
    getRuleFormattedAmountStringWithoutCurrency
} from '../../common/utils/UIUtils';
import { useLoggerStore } from '../../../../log/hooks';
import { useViewTransactionRulesStore } from '../store/hooks';

interface ViewTransactionValidationRulesDiffProps {
    oldTransactionValidationRules: Instance<typeof TransactionValidationRuleModel>[];
    updatedTransactionValidationRules: Instance<typeof TransactionValidationRuleModel>[];
}

export const ViewTransactionValidationRulesDiff = ({
    oldTransactionValidationRules,
    updatedTransactionValidationRules
}: ViewTransactionValidationRulesDiffProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const loggerStore = useLoggerStore();
    const [orientation, setOrientation] = useState<RuleDiffOrientation>(
        RuleDiffOrientation.Unified
    );
    const store = useViewTransactionRulesStore();

    const renderTableHeader = (): React.ReactElement => {
        const HeaderCell = styled(TableCell)({
            boxSizing: 'border-box',
            padding: '16px',
            borderTop: `1px solid ${palette.outline[200]}`,
            borderBottom: `1px solid ${palette.outline[200]}`,
            ...typography.small3,
            height: '56px',
            color: palette.label[200]
        });

        return (
            <TableHead>
                <HeaderCell>{t('rulesCSVHeaders.slNo')}</HeaderCell>
                <HeaderCell>{t('rulesCSVHeaders.transactionRules.monetaryLimit')}</HeaderCell>
                <HeaderCell align="right">
                    {t('rulesCSVHeaders.transactionRules.periodicLimit')}
                </HeaderCell>
                <HeaderCell align="right">
                    {t('rulesCSVHeaders.transactionRules.timeWindowInSeconds')}
                </HeaderCell>
                <HeaderCell>{t('common.cbs')}</HeaderCell>
            </TableHead>
        );
    };

    const renderTransactionValidationRuleRow = (
        type: RuleDiffRowType,
        index: number,
        oldTransactionValidationRule?: Instance<typeof TransactionValidationRuleModel>,
        updatedTransactionValidationRule?: Instance<typeof TransactionValidationRuleModel>
    ): React.ReactElement => {
        if (!oldTransactionValidationRule && !updatedTransactionValidationRule) {
            loggerStore.debug(
                'Both oldTransactionValidationRule and updatedTransactionValidationRule is empty'
            );
            return <></>;
        }

        const getFormattedAmountString = (
            number: number | null | undefined
        ): string | undefined => {
            if (number) {
                return getRuleFormattedAmountStringWithoutCurrency(number);
            } else {
                // This is done since all the values in transaction-rules are mandatory hence number validation can only fail for `0`
                return '0';
            }
        };

        const getFormattedString = (number: number | null | undefined): string | undefined => {
            if (number) {
                return getFormattedStringFromNumber(number);
            } else {
                // This is done since all the values in transaction-rules are mandatory hence number validation can only fail for `0`
                return '0';
            }
        };

        return (
            <TableRow key={`transactionValidationRuleDiffViewRow-${index}`}>
                {/* Index column will not have a diff view, hence type prop is not set. */}
                <RuleDiffTableContentCell minWidth="80px" currentValue={(index + 1).toString()} />
                <RuleDiffTableContentCell
                    minWidth="200px"
                    type={type}
                    orientation={orientation}
                    currentValue={getFormattedAmountString(
                        oldTransactionValidationRule?.monetaryLimit
                    )}
                    updatedValue={getFormattedAmountString(
                        updatedTransactionValidationRule?.monetaryLimit
                    )}
                />
                <RuleDiffTableContentCell
                    minWidth="144px"
                    type={type}
                    align="right"
                    orientation={orientation}
                    currentValue={getFormattedString(oldTransactionValidationRule?.periodicLimit)}
                    updatedValue={getFormattedString(
                        updatedTransactionValidationRule?.periodicLimit
                    )}
                />
                <RuleDiffTableContentCell
                    minWidth="144px"
                    type={type}
                    align="right"
                    orientation={orientation}
                    currentValue={getFormattedString(
                        oldTransactionValidationRule?.timeWindowInSeconds
                    )}
                    updatedValue={getFormattedString(
                        updatedTransactionValidationRule?.timeWindowInSeconds
                    )}
                />
                <RuleDiffTableContentCell
                    minWidth="200px"
                    type={type}
                    orientation={orientation}
                    currentValue={
                        oldTransactionValidationRule?.cbsId &&
                        getCountryNameFromCbsId(
                            store.getCbsIdWithCountryList(),
                            oldTransactionValidationRule.cbsId
                        )
                    }
                    updatedValue={
                        updatedTransactionValidationRule?.cbsId &&
                        getCountryNameFromCbsId(
                            store.getCbsIdWithCountryList(),
                            updatedTransactionValidationRule.cbsId
                        )
                    }
                />
            </TableRow>
        );
    };

    const renderDiffView = (): React.ReactElement => {
        // To render the diff view for transaction validation rules, first we have to parse the rules to identify the types of changes made to the rules.
        // parseRules is a generic function which takes in old transaction validation  and updated transaction validation ,
        // helper function to convert TransactionValidationRuleModel to string and helper function to convert string to TransactionValidationRuleModel.
        const transactionValidationDiffRows = parseRules(
            oldTransactionValidationRules,
            updatedTransactionValidationRules,
            transactionValidationRuleModelToString,
            stringToTransactionValidationRuleModel
        );

        if (!transactionValidationDiffRows.length) {
            return (
                <Box sx={{ textAlign: 'center', padding: '16px' }}>
                    <AlertCircle width={32} height={32} color={palette.error[300]} />
                    <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                        {t('common.noChangesFound')}
                    </Typography>
                </Box>
            );
        }

        const tableRows = transactionValidationDiffRows.map(({ oldRule, updatedRule }, index) => {
            let rowType: RuleDiffRowType;
            if (!oldRule) {
                // When only updatedRule is present, then the change is considered as RuleDiffRowType.Insert.
                rowType = RuleDiffRowType.Insert;
            } else if (!updatedRule) {
                // When only oldRule is present, then the change is considered as RuleDiffRowType.Delete.
                rowType = RuleDiffRowType.Delete;
            } else if (
                transactionValidationRuleModelToString(oldRule) !==
                transactionValidationRuleModelToString(updatedRule)
            ) {
                // When both oldRule and updatedRule is present and do not match with each other, then the change is considered as RuleDiffRowType.Modify.
                rowType = RuleDiffRowType.Modify;
            } else {
                // The change is considered as RuleDiffRowType.View, if not other cases where matched.
                rowType = RuleDiffRowType.View;
            }
            return renderTransactionValidationRuleRow(rowType, index, oldRule, updatedRule);
        });

        return (
            <>
                <RuleDiffOrientationSelector
                    orientation={orientation}
                    setOrientation={setOrientation}
                />
                <TableContainer sx={{ width: 'calc(100vw - 304px)' }}>
                    <Table>
                        {renderTableHeader()}
                        <TableBody>{tableRows}</TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    return <Stack>{renderDiffView()}</Stack>;
};
