import { CheckToArchiveBCNUserUpdateRequestWithBalanceRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckToArchiveBCNUserUpdateRequestWithBalanceRPCImpl extends CheckToArchiveBCNUserUpdateRequestWithBalanceRPC {
    execute(
        _request: CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Response,
            CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Errors.Errors
        >
    > {
        const response = new CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Response,
                CheckToArchiveBCNUserUpdateRequestWithBalanceRPC.Errors.Errors
            >
        >;
    }
}
