import { Box, styled, Typography } from '@mui/material';
import { Card, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../../assets/icons/Download';
import { Button } from '../../../../common/components/Button';

export type FileStatus = 'current' | 'new';

interface FileDownloadCardProps {
    fileName: string;
    timestamp: string;
    status: FileStatus;
    onDownloadClick: () => void;
    isLoading: boolean;
    title?: string;
}

export const FileDownloadCard = ({
    fileName,
    timestamp,
    status,
    onDownloadClick,
    isLoading,
    title
}: FileDownloadCardProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    const StyledDot = styled(Box)(() => ({
        backgroundColor: palette.primary[300],
        height: '12px',
        width: '12px',
        borderRadius: '50%',
        display: 'flex'
    }));

    const StyledFileName = styled(Typography)(() => ({
        ...typography.body2,
        color: palette.label[300],
        marginTop: '8px',
        marginBottom: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }));

    return (
        <Card
            style={{
                width: '280px',
                backgroundColor: palette.background[300],
                padding: '16px',
                marginRight: '16px'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                    {title ??
                        (status === 'current'
                            ? t('common.currentFile')
                            : t('feeRules.newlyUploadedFile'))}
                </Typography>
                {status === 'new' && <StyledDot />}
            </Box>
            <StyledFileName>{fileName}</StyledFileName>
            <Typography
                sx={{ ...typography.body3, color: palette.label[200], marginBottom: '8px' }}>
                {timestamp}
            </Typography>
            <Button
                isLoading={isLoading}
                name="outlined-color"
                variant="outlined-color"
                size="small"
                iconPosition="left"
                icon={<Download />}
                title={t('common.downloadFile')}
                onClick={onDownloadClick}
                style={{
                    marginTop: '8px',
                    width: '131px' // TODO: Fix extra button padding. Ideally this should be 128px, it has been noticed that the current button is adding extra internal padding of 3px which should be fixed
                }}
            />
        </Card>
    );
};
