import {
    createNetworkingErrorStore,
    NetworkingError,
    NetworkingErrorStore
} from './../../error/store/ErrorStore';
import { getRoot, IAnyStateTreeNode, Instance, types } from 'mobx-state-tree';
import { APIClient, AxiosAPIClient, Headers } from '@surya-digital/tedwig';
import { RootStore } from '../../store/root/store/RootStore';
import { getLoggerStore } from '../../../log/hooks';

let apiClient: APIClient;
export const NetworkingStore = types
    .model({
        errorStore: NetworkingErrorStore
    })
    .views((store) => ({
        useAPIClient(): APIClient {
            if (!process.env.REACT_APP_API_CLIENT_BASE_URL) {
                throw Error('API CLient base URL not found');
            }
            if (!apiClient) {
                apiClient = new AxiosAPIClient({
                    baseURL: new URL(process.env.REACT_APP_API_CLIENT_BASE_URL),
                    timeoutMS: 30000,
                    defaultHeaders: new Headers([
                        {
                            name: 'content-type',
                            value: 'application/json;charset=UTF-8'
                        }
                    ]),
                    defaultErrorInterceptor: (
                        error: Error,
                        statusCode: number | undefined
                    ): void => {
                        const loggerStore = getLoggerStore(store);
                        loggerStore.debug(`Intercepted Error: ${error} in Networking Store`);
                        if (statusCode) {
                            if (statusCode === 404) {
                                store.errorStore.setError(NetworkingError.PageNotFound);
                            } else if (statusCode === 200) {
                                if (error.message) {
                                    if (error.message === NetworkingError.InvalidRequest) {
                                        store.errorStore.setError(NetworkingError.InvalidRequest);
                                    } else {
                                        store.errorStore.setError(NetworkingError.RPCError);
                                    }
                                } else {
                                    store.errorStore.setError(NetworkingError.RPCError);
                                }
                            } else if (statusCode === 408) {
                                store.errorStore.setError(NetworkingError.TimeOut);
                            } else {
                                store.errorStore.setError(NetworkingError.InternalServerError);
                            }
                        }
                    }
                });
            }
            return apiClient;
        }
    }));

export const createNetworkingStore = (): Instance<typeof NetworkingStore> => {
    return NetworkingStore.create({
        errorStore: createNetworkingErrorStore()
    });
};

// This helper function takes in a store ( which can be a node in the MST ) and returns the shared instance of APIClient
export const getAPIClient = (store: IAnyStateTreeNode): APIClient => {
    const rootStore = getRoot<typeof RootStore>(store);
    return rootStore.networkingStore.useAPIClient();
};
