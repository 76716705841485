import { TextAreaInputField } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../../common/components/Dialog';

interface RequestDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmit: (comment: string) => void;
    title: string | null;
    buttonTitle?: string | null;
}

export const RequestDialog = ({
    isDialogOpen,
    onClose,
    onSubmit,
    title,
    buttonTitle
}: RequestDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const [comment, setComment] = useState<string | null>(null);

    return (
        <Dialog
            open={isDialogOpen}
            title={title ?? ''}
            primaryButtonText={buttonTitle ?? t('common.submitRequest')}
            secondaryButtonText={t('common.cancel')}
            isPrimaryButtonDisabled={!comment || comment?.trim().length === 0}
            onPrimaryButtonClick={async (): Promise<void> => {
                // Since the primary button is enabled only when comment is present, comment can never be null here.
                onSubmit(comment!);
                setComment(null);
            }}
            onSecondaryButtonClick={(): void => {
                onClose();
                setComment(null);
            }}
            disableBackdropClick
            dialogWidth="560px">
            <TextAreaInputField
                name="comment"
                isRequired
                numberOfRows={3}
                value={comment ?? ''}
                onTextChange={setComment}
                label={t('common.addComment')}
                style={{ width: '520px' }}
            />
        </Dialog>
    );
};
