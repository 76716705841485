import React from 'react';
import styled from '@emotion/styled';
import { Typography, Box, Grid } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { SectionCellContainer } from '../../common/components/SectionRowComponent';
import { DiffHeaderCell } from '../../common/types/SectionRowComponentTypes';

interface DiffHeaderCellComponentProps {
    data: DiffHeaderCell;
}

export const DiffHeaderCellComponent = ({
    data
}: DiffHeaderCellComponentProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const HeaderTypography = styled(Typography)({
        ...typography.small3,
        color: palette.label[200]
    });

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                    <SectionCellContainer>
                        <HeaderTypography>{data.currentDetailsLabel}</HeaderTypography>
                    </SectionCellContainer>
                </Grid>
                <Grid item xs={6}>
                    <SectionCellContainer>
                        <HeaderTypography>{data.updatedDetailsLabel}</HeaderTypography>
                    </SectionCellContainer>
                </Grid>
            </Grid>
        </Box>
    );
};
