import { Instance, types } from 'mobx-state-tree';

export enum NetworkingError {
    PageNotFound = 'PAGE_NOT_FOUND',
    InternalServerError = 'INTERNAL_SERVER_ERROR',
    RPCError = 'RPC_ERROR',
    InvalidRequest = 'INVALID_REQUEST',
    // Internal Error is used to indicate that an unexpected or unhandled error has occurred in the store. We display an error dialog in the UI to notify the users about the error.
    InternalError = 'INTERNAL_ERROR',
    TimeOut = 'TIME_OUT'
}

export const NetworkingErrorStore = types
    .model({
        error: types.maybeNull(
            types.enumeration<NetworkingError>('NetworkingError', Object.values(NetworkingError))
        )
    })
    .actions((store) => ({
        setError(networkError: NetworkingError): void {
            store.error = networkError;
        },
        removeError(): void {
            store.error = null;
        }
    }));

export const createNetworkingErrorStore = (): Instance<typeof NetworkingErrorStore> => {
    return NetworkingErrorStore.create();
};
