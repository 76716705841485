// The rule is disabled since privileges for three different kinds of users are created but only one of them is used.
/* eslint-disable no-unused-vars */
import { GetUserPrivilegesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { UserPrivileges } from '../../../home/user/UserPrivileges';

const getViewerPrivileges = (): string[] => {
    return [
        // Fee-Rules
        UserPrivileges.ViewFeeRule,
        UserPrivileges.ViewFeeRuleHistory,
        UserPrivileges.DownloadFeeRule,
        // Transaction-Rules
        UserPrivileges.ViewTransactionRule,
        UserPrivileges.ViewTransactionRuleHistory,
        UserPrivileges.DownloadTransactionRule,
        // Transaction-Search
        UserPrivileges.ViewTransaction,
        UserPrivileges.ViewTransactionRefundRequests,
        // Generic Funds Transfer
        UserPrivileges.ViewFundsTransfer,
        // Unclaimed Funds
        UserPrivileges.ViewUnclaimedFunds,
        // BO User Management
        UserPrivileges.ViewBOUser,
        // BCN User Management
        UserPrivileges.ViewBCNUser,
        // Reports
        UserPrivileges.ViewReports,
        UserPrivileges.DownloadReports,
        // Audit Logs
        UserPrivileges.ViewAuditLogs,
        // Agency Banking
        UserPrivileges.ViewAgentTransaction,
        UserPrivileges.ViewAgent
    ];
};

const getMakerPrivileges = (): string[] => {
    return [
        // Fee-Rules
        UserPrivileges.ViewFeeRule,
        UserPrivileges.ViewFeeRuleHistory,
        UserPrivileges.DownloadFeeRule,
        UserPrivileges.RequestFeeRuleUpdate,
        // Transaction-Rules
        UserPrivileges.ViewTransactionRule,
        UserPrivileges.ViewTransactionRuleHistory,
        UserPrivileges.DownloadTransactionRule,
        UserPrivileges.RequestTransactionRuleUpdate,
        // Transaction-Search
        UserPrivileges.ViewTransaction,
        UserPrivileges.ViewTransactionRefundRequests,
        UserPrivileges.RequestTransactionRefund,
        // Generic Funds Transfer
        UserPrivileges.ViewFundsTransfer,
        UserPrivileges.RequestFundsTransfer,
        // Unclaimed Funds
        UserPrivileges.ViewUnclaimedFunds,
        UserPrivileges.RequestCBSTransfer,
        // BO User Management
        UserPrivileges.ViewBOUser,
        UserPrivileges.RequestAddEditBOUser,
        UserPrivileges.RequestArchiveBOUser,
        UserPrivileges.RequestForceResetPassword,
        UserPrivileges.RequestRegenerateAuthCodes,
        UserPrivileges.RequestActivateDeactivateBOUser,
        // BCN User Management
        UserPrivileges.ViewBCNUser,
        UserPrivileges.RequestArchiveBCNUser,
        UserPrivileges.RequestActivateDeactivateBCNUser,
        // Reports
        UserPrivileges.ViewReports,
        UserPrivileges.DownloadReports,
        // Audit Logs
        UserPrivileges.ViewAuditLogs,
        // Agency Banking
        UserPrivileges.ViewAgent,
        UserPrivileges.ViewAgentTransaction,
        UserPrivileges.RequestActivateDeactivateAgent,
        UserPrivileges.RequestArchiveAgent,
        UserPrivileges.RequestMoveFundsOutOfYafika
    ];
};

const getCheckerPrivileges = (): string[] => {
    return [
        // Fee-Rules
        UserPrivileges.ViewFeeRule,
        UserPrivileges.ViewFeeRuleHistory,
        UserPrivileges.DownloadFeeRule,
        UserPrivileges.CheckFeeRuleRequest,
        // Transaction-Rules
        UserPrivileges.ViewTransactionRule,
        UserPrivileges.ViewTransactionRuleHistory,
        UserPrivileges.DownloadTransactionRule,
        UserPrivileges.CheckTransactionRuleRequest,
        // Transaction-Search
        UserPrivileges.ViewTransaction,
        UserPrivileges.ViewTransactionRefundRequests,
        UserPrivileges.CheckTransactionRefundRequest,
        // Generic Funds Transfer
        UserPrivileges.ViewFundsTransfer,
        UserPrivileges.CheckFundsTransferRequest,
        // Unclaimed Funds
        UserPrivileges.ViewUnclaimedFunds,
        UserPrivileges.CheckCBSTransferRequest,
        // BO User Management
        UserPrivileges.ViewBOUser,
        UserPrivileges.CheckBOUserUpdateRequest,
        UserPrivileges.DownloadBOAuthCodes,
        // BCN User Management
        UserPrivileges.ViewBCNUser,
        UserPrivileges.CheckBCNUserUpdateRequest,
        // Reports
        UserPrivileges.ViewReports,
        UserPrivileges.DownloadReports,
        // Audit Logs
        UserPrivileges.ViewAuditLogs,
        // Agency Banking
        UserPrivileges.ViewAgent,
        UserPrivileges.ViewAgentTransaction,
        UserPrivileges.CheckAgentUpdateRequest,
        UserPrivileges.ViewAgentTransaction,
        UserPrivileges.ViewAgent,
        UserPrivileges.CheckMoveFundsOutOfYafikaRequest
    ];
};

export class MockGetUserPrivilegesRPCImpl extends GetUserPrivilegesRPC {
    execute(
        _request: GetUserPrivilegesRPC.Request
    ): Promise<LeoRPCResult<GetUserPrivilegesRPC.Response, GetUserPrivilegesRPC.Errors.Errors>> {
        const response = new GetUserPrivilegesRPC.Response(new LeoUUID(), getMakerPrivileges());
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetUserPrivilegesRPC.Response, GetUserPrivilegesRPC.Errors.Errors>
        >;
    }
}
