import { GetCurrentFeeDeterminationRuleDetailsFileRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCurrentFeeDeterminationRuleDetailsFileRPCImpl extends GetCurrentFeeDeterminationRuleDetailsFileRPC {
    execute(
        _request: GetCurrentFeeDeterminationRuleDetailsFileRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCurrentFeeDeterminationRuleDetailsFileRPC.Response,
            GetCurrentFeeDeterminationRuleDetailsFileRPC.Errors.Errors
        >
    > {
        const response = new GetCurrentFeeDeterminationRuleDetailsFileRPC.Response(new LeoUUID());
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCurrentFeeDeterminationRuleDetailsFileRPC.Response,
                GetCurrentFeeDeterminationRuleDetailsFileRPC.Errors.Errors
            >
        >;
    }
}
