import { ReportType } from '@resolut-tech/bcn-rpcs';
import { TFunction } from 'i18next';
import { TFuncKey } from 'react-i18next';
import { parseFormattedString } from '../../common/utils/UIUtils';

export const getGraphTitle = (t: TFunction, reportType: ReportType.ReportType): TFuncKey => {
    switch (reportType) {
        case ReportType.ReportType.USER_ACQUISITIONS:
            return t('reports.totalUserAcquisitionsGraph');

        case ReportType.ReportType.USER_BALANCE:
            return t('reports.totalUserBalanceGraph');

        case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
            return t('reports.totalAmountOfTransactionsGraph');

        case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
            return t('reports.totalNoOfTransactionsGraph');

        case ReportType.ReportType.REVENUE:
            return t('reports.totalRevenueGeneratedGraph');
    }
};

export const getGraphHeaderTitle = (t: TFunction, reportType: ReportType.ReportType): string => {
    switch (reportType) {
        case ReportType.ReportType.USER_ACQUISITIONS:
            return t('reports.userAcquisitions');

        case ReportType.ReportType.USER_BALANCE:
            return t('reports.userBalance');

        case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
            return t('reports.amountOfTransactions');

        case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
            return t('common.noOfTransactions');

        case ReportType.ReportType.REVENUE:
            return t('reports.revenueGenerated');
    }
};

export const getGraphSubHeaderTitle = (t: TFunction, reportType: ReportType.ReportType): string => {
    switch (reportType) {
        case ReportType.ReportType.USER_ACQUISITIONS:
            return t('reports.viewUserAcquisitions');

        case ReportType.ReportType.USER_BALANCE:
            return t('reports.viewBalance');

        case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
            return t('reports.viewAmountOfTransactions');

        case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
            return t('reports.viewTransactions');

        case ReportType.ReportType.REVENUE:
            return t('reports.viewRevenueGenerated');
    }
};

export const getGraphTableTitle = (t: TFunction, reportType: ReportType.ReportType): string => {
    switch (reportType) {
        case ReportType.ReportType.USER_ACQUISITIONS:
            return t('reports.totalUserAcquisitionsTable');

        case ReportType.ReportType.USER_BALANCE:
            return t('reports.totalUserBalanceTable');

        case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
            return t('reports.totalAmountOfTransactionsTable');

        case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
            return t('reports.totalNoOfTransactionsTable');

        case ReportType.ReportType.REVENUE:
            return t('reports.totalRevenueGeneratedTable');
    }
};

export const getFormattedData = (value: number, reportType: ReportType.ReportType): string => {
    let formattedValue;
    if (value < 10) {
        formattedValue = '0' + value.toString();
    } else {
        formattedValue = value.toString();
    }
    switch (reportType) {
        case ReportType.ReportType.USER_ACQUISITIONS:
        case ReportType.ReportType.NUMBER_OF_TRANSACTIONS:
        case ReportType.ReportType.AMOUNT_OF_TRANSACTIONS:
            return `${formattedValue}`;
        case ReportType.ReportType.USER_BALANCE:
        case ReportType.ReportType.REVENUE:
            return `${parseFormattedString(formattedValue)}`;
    }
};
