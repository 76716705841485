import { Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RefundSummaryRow } from './RefundSummaryRow';
import { AmountModel } from '../../common/models/AmountModel';
import { Instance } from 'mobx-state-tree';
import { getAmountString } from '../../common/utils/UIUtils';

interface TransactionSummaryProps {
    baseAmount: Instance<typeof AmountModel>;
    bankFee: Instance<typeof AmountModel> | null;
    additionalFee: Instance<typeof AmountModel> | null;
}

export const TransactionRefundSummary = ({
    baseAmount,
    bankFee,
    additionalFee
}: TransactionSummaryProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();
    const { t } = useTranslation();

    return (
        <Stack sx={{ py: '16px', width: '342px' }}>
            <Typography
                sx={{ ...typography.small2, color: palette.label[300], paddingBottom: '8px' }}>
                {t('transaction.transactionRefundSummary')}
            </Typography>
            <RefundSummaryRow
                label={t('transaction.baseAmount')}
                text={getAmountString(baseAmount)}
            />
            {bankFee && (
                <RefundSummaryRow
                    label={t('transaction.bankFee')}
                    text={getAmountString(bankFee)}
                />
            )}
            {additionalFee && (
                <RefundSummaryRow
                    label={t('transaction.additionalFee')}
                    text={getAmountString(additionalFee)}
                />
            )}
        </Stack>
    );
};
