import { LeoErrors } from './../../common/errors/LeoErrors';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { GetTransactionDetailsRPC } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionDetailsRPC';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useGetTransactionDetailClient } from '../rpcs/RPC';
import {
    TransactionDetail,
    TransactionDetailEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';
import { LocalizedText } from '@resolut-tech/bcn-rpcs';
import { getTranslatedString } from '../../../../utils/StringUtils';
import {
    createLinkedTransactionsStore,
    LinkedTransactionsStore
} from '../../common/store/LinkedTransactionsStore';
import { createRefundHistoryStore, RefundHistoryStore } from './RefundHistoryStore';
import {
    getRequestDetailsModel,
    RequestDetailsModel
} from '../../common/models/RequestDetailsModel';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum TransactionDetailErrors {
    InvalidTransactionId = 'INVALID_TRANSACTION_ID'
}

export interface TransactionDetailView {
    label: string;
    cellType: TransactionDetailEnums.CellType.CellType;
}

// The LocalizedText that we get from the rpc is converted to en string while parsing the response data to TransactionDetailView type from TransactionDetail type.
// localizedLabel variable holds this LocalizedText so that the value can be used while converting the data back to the type TransactionDetail.
let localizedLabel: LocalizedText;

// types.custom needs fromSnapshot and toSnapshot methods are mandatory for using types.custom.
// Since CellType.CellType enum is a complex types which is not a just string enum. It would be difficult to create model for it. Hence creating a custom type.
export const TransactionDetailType = types.custom<TransactionDetail, TransactionDetailView>({
    name: 'TransactionDetailType',
    fromSnapshot(snapshot: TransactionDetail): TransactionDetailView {
        localizedLabel = snapshot.label;
        return {
            label: getTranslatedString(snapshot.label),
            cellType: snapshot.cellType
        };
    },
    toSnapshot(value: TransactionDetailView): TransactionDetail {
        return new TransactionDetail(localizedLabel, value.cellType);
    },
    isTargetType(value: TransactionDetail | TransactionDetail): boolean {
        return !(value instanceof TransactionDetail);
    },
    getValidationMessage(_snapshot: TransactionDetail): string {
        return '';
    }
});

export const TransactionDetailStore = types
    .model('TransactionDetail', {
        linkedTransactionStore: LinkedTransactionsStore,
        refundHistoryStore: RefundHistoryStore,
        refundStatus: typeof GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus,
        details: types.array(TransactionDetailType),
        requestDetails: types.maybeNull(RequestDetailsModel),
        isProcessing: types.boolean,
        error: types.maybeNull(
            types.union(
                types.enumeration<TransactionDetailErrors>(
                    'TransactionDetailErrors',
                    Object.values(TransactionDetailErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchTransactionDetails: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetTransactionDetailsRPC.Request(new LeoUUID(transactionId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetTransactionDetailsRPC.Response,
                    GetTransactionDetailsRPC.Errors.InvalidTransactionId
                > = yield useGetTransactionDetailClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.refundStatus = response.refundStatus;
                    store.details = cast(response.transactionDetails);
                    store.requestDetails = response.requestDetails
                        ? getRequestDetailsModel(response.requestDetails)
                        : null;
                    store.isProcessing = response.isProcessing;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case TransactionDetailErrors.InvalidTransactionId:
                            store.error = TransactionDetailErrors.InvalidTransactionId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetTransactionDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = TransactionDetailErrors.InvalidTransactionId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetTransactionDetailsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createTransactionDetailStore = (): Instance<typeof TransactionDetailStore> => {
    return TransactionDetailStore.create({
        linkedTransactionStore: createLinkedTransactionsStore(),
        refundHistoryStore: createRefundHistoryStore(),
        refundStatus:
            GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus.NOT_REFUNDABLE,
        details: [],
        isProcessing: false
    });
};
