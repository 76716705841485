import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import {
    getFormattedPhoneNumber,
    getGenderTranslation,
    getUserStatus
} from '../../common/utils/UIUtils';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { BCNBasicUserDetailsModel } from '../models/BCNBasicUserDetailModel';

export interface BasicBCNUserDetailsSectionProp {
    user: Instance<typeof BCNBasicUserDetailsModel>;
    userId: string;
}

export const BasicBCNUserDetailsSection = ({
    user,
    userId
}: BasicBCNUserDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();

    const userDetailSectionCells: { label: string; cell: SectionRowCellType }[] = [
        {
            label: t('common.userPhoto'),
            cell: { imageUrl: user.profilePhoto ?? '', type: SectionRowCellEnum.ProfileImage }
        },
        {
            label: t('common.firstName'),
            cell: { text: user.firstName, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.lastName'),
            cell: { text: user.lastName ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.otherNames'),
            cell: { text: user.otherNames ?? '-', type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.userId'),
            cell: { text: userId ?? '-', type: SectionRowCellEnum.Copy }
        },
        {
            label: t('common.userStatus'),
            cell: { status: getUserStatus(user.status), type: SectionRowCellEnum.UserStatusChip }
        },
        {
            label: t('common.mobileNumber'),
            cell: {
                text: getFormattedPhoneNumber(user.phoneNumber),
                type: SectionRowCellEnum.Unstyled
            }
        },
        {
            label: t('common.emailId'),
            cell: {
                text: user.emailId ?? '-',
                type: SectionRowCellEnum.Unstyled,
                showEllipsis: true
            }
        },
        {
            label: t('common.dateOfBirth'),
            cell: { text: user.dateOfBirth, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.gender'),
            cell: {
                text: getGenderTranslation(t, user.gender),
                type: SectionRowCellEnum.Unstyled
            }
        },
        {
            label: t('common.nationalIdNo'),
            cell: { text: user.nationalId, type: SectionRowCellEnum.Unstyled }
        },
        {
            label: t('common.joinDate'),
            cell: { text: user.joinDate, type: SectionRowCellEnum.Unstyled }
        }
    ];

    const getDetailCells = (): React.ReactElement[] => {
        return userDetailSectionCells.map(({ label, cell }) => (
            <SectionRowComponentWithLabel key={label} label={label} cell={cell} />
        ));
    };

    return (
        <Section
            title={t('common.basicDetails')}
            allowViewChange={false}
            content={getDetailCells()}
        />
    );
};
