import { Box, Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { AmountModel } from '../../common/models/AmountModel';
import { AgentTransactionHistoryErrors } from '../store/AgentTransactionHistoryStore';
import { useAgentTransactionHistoryStore } from '../store/hooks';
import { AmountMoveOutOfYafikaDetails } from './AmountMoveOutOfYafikaDetails';

export const AgentTransactionHistorySection = ({
    transactionId,
    amount
}: {
    transactionId: string;
    amount: Instance<typeof AmountModel>;
}): React.ReactElement => {
    const { t } = useTranslation();
    const store = useAgentTransactionHistoryStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function fetchAgentTransactionHistory(): Promise<string | React.ReactElement[]> {
        await store.fetchAgentTransactionHistory(transactionId!);
        if (store.error) {
            switch (store.error) {
                case AgentTransactionHistoryErrors.InvalidTransactionID:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.errorMessage');
                default:
                    return t('common.errorMessage');
            }
        } else if (store.requests.length === 0) {
            return [];
        } else {
            return [
                <Stack key="agentTransactionHistoryRequests">
                    {store.requests.map((request) => {
                        return (
                            <RequestDetailsSection
                                key={request.requestId}
                                requestDetails={request}
                                isSectionItem={true}>
                                <Box sx={{ py: '16px' }}>
                                    <AmountMoveOutOfYafikaDetails amount={amount} />
                                </Box>
                            </RequestDetailsSection>
                        );
                    })}
                </Stack>
            ];
        }
    }

    return (
        <>
            <Section
                title={t('agencyBanking.history')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchAgentTransactionHistory}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </>
    );
};
