import {
    BOGender,
    BOName,
    BOUserComment,
    GetNewBOUserDetailsRPC,
    LocalizedText,
    NewBOUserBasicDetails,
    UserName,
    Comment,
    BORole,
    NewBOUserRequestDetails
} from '@resolut-tech/bcn-rpcs';
import {
    LeoEmailId,
    LeoPhoneNumber,
    LeoRPCResult,
    LeoTimestamp,
    LeoUUID
} from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetNewBOUserDetailsRPCImpl extends GetNewBOUserDetailsRPC {
    execute(
        _request: GetNewBOUserDetailsRPC.Request
    ): Promise<
        LeoRPCResult<GetNewBOUserDetailsRPC.Response, GetNewBOUserDetailsRPC.Errors.Errors>
    > {
        const newBOUserBasicDetails = new NewBOUserBasicDetails(
            new UserName(new BOName('Esther'), new BOName('Howardy')),
            new BOName('Ester'),
            new LeoPhoneNumber('+265995118354'),
            new LeoEmailId('esther.howard@email.com'),
            new LocalizedText('Malawi'),
            BOGender.BOGender.MALE,
            [
                new BORole(
                    'Reports Viewer',
                    new LocalizedText('Viewer'),
                    new LocalizedText('Reports'),
                    new LocalizedText('View Reports, Download Reports')
                ),
                new BORole(
                    'Generic Funds Transfer Maker',
                    new LocalizedText('Maker'),
                    new LocalizedText('Generic Funds Transfer'),
                    new LocalizedText('View Requests, View Details, Raise Request')
                ),
                new BORole(
                    'Fee Determination Rules Checker',
                    new LocalizedText('Checker'),
                    new LocalizedText('Fee Determination Rules'),
                    new LocalizedText('View Rules, View History, Download Rules, Evaluate Request')
                ),
                new BORole(
                    'Transaction Validation Rules Checker',
                    new LocalizedText('Checker'),
                    new LocalizedText('Transaction Validation Rules'),
                    new LocalizedText('View Rules, View History, Download Rules, Evaluate Request')
                )
            ]
        );
        const requestDetails = new NewBOUserRequestDetails.Pending(
            new LeoUUID(),
            new BOUserComment(
                new LeoUUID(),
                new UserName(new BOName('Wade'), new BOName('Warren')),
                null,
                new LeoTimestamp('2022-09-30T02:03:59Z'),
                new Comment('This user is a newly joined employee')
            )
        );

        const response = new GetNewBOUserDetailsRPC.Response(newBOUserBasicDetails, requestDetails);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetNewBOUserDetailsRPC.Response, GetNewBOUserDetailsRPC.Errors.Errors>
        >;
    }
}
