import { GetBOPresignedDownloadURLForFeeRulesTemplateRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetBOPresignedDownloadURLForFeeRulesTemplateRPCImpl extends GetBOPresignedDownloadURLForFeeRulesTemplateRPC {
    execute(
        _request: GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Request
    ): Promise<
        LeoRPCResult<
            GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Response,
            GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Errors.Errors
        >
    > {
        const response = new GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Response(
            new URL('https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Response,
                GetBOPresignedDownloadURLForFeeRulesTemplateRPC.Errors.Errors
            >
        >;
    }
}
