import React, { useState } from 'react';
import { Button, Section } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { useBOUserRequestHistoryStore, useBOUserRequestSummaryStore } from '../store/hooks';
import { Stack } from '@mui/material';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { BORequestSummaryDialog } from './BORequestSummaryDialog';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { useLoggerStore } from '../../../../log/hooks';
import { BOUserRequestHistoryErrors } from '../store/BOUserRequestHistoryStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const BORequestHistory = ({
    userId,
    setIsLoading
}: {
    userId: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
    const { t } = useTranslation();
    const store = useBOUserRequestHistoryStore();
    const requestSummaryStore = useBOUserRequestSummaryStore();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const loggerStore = useLoggerStore();

    const fetchBOUserRequestHistory = async (): Promise<string | React.ReactElement[]> => {
        if (userId) {
            await store.fetchBOUserRequestHistory(userId);
            if (store.error) {
                switch (store.error) {
                    case BOUserRequestHistoryErrors.InvalidBoUserId:
                        return t('common.errorMessage');
                    case NetworkingError.InternalError:
                        setIsErrorDialogOpen(true);
                        return t('common.somethingWentWrongProcessingRequest');
                    default:
                        return t('common.errorMessage');
                }
            } else {
                if (store.requests.length > 0) {
                    return [
                        <Stack key="requestHistory">
                            {store.requests.map((request) => {
                                return (
                                    <RequestDetailsSection
                                        key={request.requestDetails?.requestId}
                                        requestDetails={request.requestDetails!}
                                        isSectionItem={true}>
                                        {request.requestDetails!.status !==
                                        IntermediateRequestStatus.IntermediateRequestStatus
                                            .PENDING ? (
                                            <Button
                                                name="requestSummary"
                                                style={{ marginTop: '16px', marginBottom: '16px' }}
                                                title={t('boUser.viewRequestSummary')}
                                                size="small"
                                                variant="outlined-color"
                                                onClick={async (): Promise<void> => {
                                                    setIsLoading(true);
                                                    if (request.requestDetails?.requestId) {
                                                        await requestSummaryStore.fetchBOUserRequestSummary(
                                                            request.requestDetails.requestId
                                                        );
                                                    } else {
                                                        loggerStore.debug(
                                                            'Cannot find requestId in BOUserRequestSummaryStore'
                                                        );
                                                    }
                                                    setIsLoading(false);
                                                    if (store.error || requestSummaryStore.error) {
                                                        setIsErrorDialogOpen(true);
                                                    } else {
                                                        setIsDialogOpen(true);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </RequestDetailsSection>
                                );
                            })}
                        </Stack>
                    ];
                } else {
                    // This is done to show `No Results Found` when an empty array is returned from the server
                    return [];
                }
            }
        } else {
            return t('common.errorMessage');
        }
    };

    return (
        <>
            <BORequestSummaryDialog
                isDialogOpen={isDialogOpen}
                onClose={(): void => setIsDialogOpen(false)}
            />
            <ErrorDialog
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    requestSummaryStore.resetError();
                    store.removeError();
                }}
                errorMessage={t('common.somethingWentWrongProcessingRequest')}
            />
            <Section
                title={t('boUser.requestHistory')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchBOUserRequestHistory}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
        </>
    );
};
