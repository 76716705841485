import { CheckCBSTransferRequestForUnclaimedFundsRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckCBSTransferRequestForUnclaimedFundsRPCImpl extends CheckCBSTransferRequestForUnclaimedFundsRPC {
    execute(
        _request: CheckCBSTransferRequestForUnclaimedFundsRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckCBSTransferRequestForUnclaimedFundsRPC.Response,
            CheckCBSTransferRequestForUnclaimedFundsRPC.Errors.Errors
        >
    > {
        const response = new CheckCBSTransferRequestForUnclaimedFundsRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckCBSTransferRequestForUnclaimedFundsRPC.Response,
                CheckCBSTransferRequestForUnclaimedFundsRPC.Errors.Errors
            >
        >;
    }
}
