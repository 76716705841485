import { SignOutStore } from './../sign-out/store/SignOutStore';
import { TransactionStore } from './../transaction/store/TransactionStore';
import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../store/root/store/RootStore';
import { HomeStore } from './HomeStore';
import { AuditLogsStore } from '../audit-logs/store/AuditLogsStore';
import { UserStore } from '../user/UserStore';

export const useHomeStore = (): Instance<typeof HomeStore> => {
    return useRootStore().homeStore;
};

export const useUserStore = (): Instance<typeof UserStore> => {
    return useRootStore().homeStore.user;
};

export const useTransactionStore = (): Instance<typeof TransactionStore> => {
    return useRootStore().homeStore.transaction;
};

export const useAuditLogsStore = (): Instance<typeof AuditLogsStore> => {
    return useRootStore().homeStore.auditLogs;
};

export const useSignOutStore = (): Instance<typeof SignOutStore> => {
    return useRootStore().homeStore.signOut;
};
