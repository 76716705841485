import { Grid } from '@mui/material';
import { DropdownInputField, DropdownItem, TextInputField } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AmountTextField } from '../../common/components/amount-text-field/AmountTextField';
import { TransactionRulesRowData } from '../pages/EditTransactionRules';
import { useViewTransactionRulesStore } from '../store/hooks';
import { Dialog } from '../../../common/components/Dialog';

interface InsertTransactionRulesRowDialogProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onInsertButtonClick: (row: TransactionRulesRowData) => void;
}

export const InsertTransactionRulesRowDialog = ({
    isDialogOpen,
    onDialogClose,
    onInsertButtonClick
}: InsertTransactionRulesRowDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const [monetaryLimit, setMonetaryLimit] = useState('');
    const [periodicLimit, setPeriodicLimit] = useState('');
    const [timeWindowInSeconds, setTimeWindowInSeconds] = useState('');
    const [cbsId, setCbsId] = useState('');
    const store = useViewTransactionRulesStore();

    const reset = (): void => {
        setMonetaryLimit('');
        setPeriodicLimit('');
        setTimeWindowInSeconds('');
        setCbsId('');
    };

    const onClose = (): void => {
        reset();
        onDialogClose();
    };

    const getCBSIdCountryDropdownItems = (): DropdownItem[] => {
        const list = store.countryCbsIdList;
        return list.map((country): DropdownItem => {
            return {
                name: country.name,
                value: country.name
            };
        });
    };

    return (
        <Dialog
            open={isDialogOpen}
            title={t('common.insertRow')}
            primaryButtonText={t('common.insertRow')}
            onPrimaryButtonClick={async (): Promise<void> => {
                onInsertButtonClick({
                    monetaryLimit,
                    periodicLimit,
                    timeWindowInSeconds,
                    cbsId
                });
                onClose();
            }}
            isPrimaryButtonDisabled={Boolean(
                !monetaryLimit || !periodicLimit || !timeWindowInSeconds || !cbsId
            )}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Grid container spacing="20px">
                <Grid item xs={6}>
                    <AmountTextField
                        name="monetaryLimit"
                        value={monetaryLimit}
                        onAmountChange={(value: string): void => {
                            setMonetaryLimit(value);
                        }}
                        isRequired={true}
                        label={t('rulesCSVHeaders.transactionRules.monetaryLimit')}
                        isDecimalAllowed={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="periodicLimit"
                        value={periodicLimit}
                        type="text"
                        onTextChange={(value: string): void => {
                            setPeriodicLimit(value);
                        }}
                        isRequired={true}
                        label={t('rulesCSVHeaders.transactionRules.periodicLimit')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="timeWindowInSeconds"
                        value={timeWindowInSeconds}
                        type="text"
                        onTextChange={(value: string): void => {
                            setTimeWindowInSeconds(value);
                        }}
                        isRequired={true}
                        label={t('rulesCSVHeaders.transactionRules.timeWindowInSeconds')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DropdownInputField
                        isRequired={true}
                        name="cbsId"
                        value={cbsId}
                        onSelect={(item: DropdownItem): void => {
                            setCbsId(item.value);
                        }}
                        label={t('common.cbs')}
                        options={getCBSIdCountryDropdownItems()}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};
