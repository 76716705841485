import * as React from 'react';

export const BarChart = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 7.5C15.4602 7.5 15.8333 7.8731 15.8333 8.33333V16.6667C15.8333 17.1269 15.4602 17.5 15 17.5C14.5398 17.5 14.1667 17.1269 14.1667 16.6667V8.33333C14.1667 7.8731 14.5398 7.5 15 7.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99999 2.5C10.4602 2.5 10.8333 2.8731 10.8333 3.33333V16.6667C10.8333 17.1269 10.4602 17.5 9.99999 17.5C9.53975 17.5 9.16666 17.1269 9.16666 16.6667V3.33333C9.16666 2.8731 9.53975 2.5 9.99999 2.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.99999 10.8335C5.46023 10.8335 5.83332 11.2066 5.83332 11.6668V16.6668C5.83332 17.1271 5.46023 17.5002 4.99999 17.5002C4.53975 17.5002 4.16666 17.1271 4.16666 16.6668V11.6668C4.16666 11.2066 4.53975 10.8335 4.99999 10.8335Z"
                fill="currentColor"
            />
        </svg>
    );
};
