import {
    GetTransactionValidationRuleDiffRPC,
    TransactionValidationRule
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetTransactionValidationRuleDiffRPCClientImpl extends GetTransactionValidationRuleDiffRPC {
    execute(
        _request: GetTransactionValidationRuleDiffRPC.Request
    ): Promise<
        LeoRPCResult<
            GetTransactionValidationRuleDiffRPC.Response,
            GetTransactionValidationRuleDiffRPC.Errors.Errors
        >
    > {
        const oldTransactionValidationRules = [
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            )
        ];
        const updatedTransactionValidationRules = [
            new TransactionValidationRule(
                40000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                5600,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                3000,
                2200,
                'e751727b-df82-4bd2-894d-7096be687ed1'
            ),
            new TransactionValidationRule(
                50000000,
                1000,
                2200,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            )
        ];
        const response = new GetTransactionValidationRuleDiffRPC.Response(
            oldTransactionValidationRules,
            updatedTransactionValidationRules
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetTransactionValidationRuleDiffRPC.Response,
                GetTransactionValidationRuleDiffRPC.Errors.Errors
            >
        >;
    }
}
