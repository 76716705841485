import { Divider, Radio, Stack, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BORoleModel } from '../models/BORoleModel';
import { WorkflowDependentRolesModel } from '../models/WorkflowDependentRolesModel';

interface BOUserRoleRadioGroupProps {
    workflowDependentRole: Instance<typeof WorkflowDependentRolesModel>;
    userRole: Instance<typeof BORoleModel> | null;
    onUserRoleChange: (workflow: string, userRole: Instance<typeof BORoleModel> | null) => void;
}

const BOUserRoleRadioGroup = ({
    workflowDependentRole,
    userRole,
    onUserRoleChange
}: BOUserRoleRadioGroupProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    // This is used as the value for none radio button as radio button only works with string.
    // On change if the value is 'none', null is sent to store.
    const noneValue = 'none';

    const StyledRadio = styled(Radio)({
        color: palette.primary[300],
        padding: 0
    });

    const StyledContainer = styled(Stack)({
        padding: '12px 0'
    });

    const StyledLabelTypography = styled(Typography)({
        ...typography.body2,
        color: palette.label[300]
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        const selectedUserRole =
            (value === noneValue
                ? null
                : workflowDependentRole.roles.find((role) => role.id === value)) ?? null;

        onUserRoleChange(workflowDependentRole.workflow, selectedUserRole);
    };

    const renderNoneRadioButton = (): React.ReactElement => (
        <Stack direction="row" spacing="10px">
            <StyledRadio value={noneValue} checked={!Boolean(userRole)} onChange={handleChange} />
            <Stack>
                <StyledLabelTypography>{t('boUser.none')}</StyledLabelTypography>
            </Stack>
        </Stack>
    );

    const renderRadioButton = (role: Instance<typeof BORoleModel>): React.ReactElement => {
        return (
            <Stack key={role.id} direction="row" spacing="10px">
                <div>
                    <StyledRadio
                        value={role.id}
                        checked={userRole?.id === role.id}
                        onChange={handleChange}
                    />
                </div>
                <Stack>
                    <StyledLabelTypography>{role.name}</StyledLabelTypography>
                    <Typography sx={{ ...typography.body3, color: palette.label[200] }}>
                        {role.description}
                    </Typography>
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack direction="row" spacing={1}>
            <StyledContainer width="160px">
                <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                    {workflowDependentRole.workflow}
                </Typography>
            </StyledContainer>
            <StyledContainer spacing="12px" width="calc(100% - 160px)" alignItems="flex-start">
                {renderNoneRadioButton()}
                {workflowDependentRole.roles.map((role) => renderRadioButton(role))}
            </StyledContainer>
        </Stack>
    );
};

interface BOUserRolesSelectorProps {
    workflowDependentRoles: Instance<typeof WorkflowDependentRolesModel>[];
    selectedBORoles: Instance<typeof BORoleModel>[];
    onBORolesChange: (userRoles: Instance<typeof BORoleModel>[]) => void;
}

export const BOUserRolesSelector = ({
    workflowDependentRoles,
    selectedBORoles,
    onBORolesChange
}: BOUserRolesSelectorProps): React.ReactElement => {
    const onUserRoleChange = (
        workflow: string,
        userRole: Instance<typeof BORoleModel> | null
    ): void => {
        const removeWorkflowRoles = selectedBORoles.filter((role) => role.workflow !== workflow);
        if (userRole) {
            onBORolesChange([...removeWorkflowRoles, userRole]);
        } else onBORolesChange(removeWorkflowRoles);
    };

    const renderCategory = (): React.ReactElement[] => {
        return workflowDependentRoles.map((workflowDependentRole) => {
            return (
                <React.Fragment key={workflowDependentRole.workflow}>
                    <BOUserRoleRadioGroup
                        workflowDependentRole={workflowDependentRole}
                        userRole={
                            selectedBORoles.find(
                                (role) => role.workflow === workflowDependentRole.workflow
                            ) ?? null
                        }
                        onUserRoleChange={onUserRoleChange}
                    />
                    <Divider />
                </React.Fragment>
            );
        });
    };

    return <div>{renderCategory()}</div>;
};
