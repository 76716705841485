import { Section } from '@surya-digital/leo-reactjs-ui';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import { getFormattedPhoneNumber, getGenderTranslation } from '../../common/utils/UIUtils';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import {
    useChangeProfileImageStore,
    useDeleteProfileImageStore,
    useProfileDetailsStore
} from '../store/hooks';
import { UpdatePhotoErrorDialog } from './UpdatePhotoErrorDialog';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { DeletePhotoDialog } from './DeletePhotoDialog';
import { BlobData, convertUrlToFile } from '../utils/ProfileImageCropUtils';
import { UpdateUserPhotoDialog } from './UpdateUserPhotoDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { observer } from 'mobx-react';
import { Stack } from '@mui/material';
import { useLoggerStore } from '../../../../log/hooks';

interface ProfileBasicDetailsSectionProp {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProfileBasicDetails = observer(
    ({ setIsLoading }: ProfileBasicDetailsSectionProp): React.ReactElement => {
        const { t } = useTranslation();
        const [isDeletePhotoDialogOpen, setIsDeletePhotoDialogOpen] = useState<boolean>(false);
        const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
        const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState<boolean>(false);
        const [isUpdateUserPhotoDialogOpen, setIsUpdateUserPhotoDialogOpen] =
            useState<boolean>(false);
        const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
        const inputFile = useRef<HTMLInputElement>(null);
        const deleteProfileImageStore = useDeleteProfileImageStore();
        const updateProfileImageStore = useChangeProfileImageStore();
        const [croppedImage, setCroppedImage] = useState<BlobData | null>(null);
        const [imageUrl, setImageUrl] = useState<string>('');
        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const [selectedPhotoName, setSelectedPhotoName] = useState<string>('');
        const [selectedPhotoType, setSelectedPhotoType] = useState<string>('');
        const [isUploadSizeError, setIsUploadSizeError] = useState<boolean>(false);
        const profileDetailsStore = useProfileDetailsStore();
        const loggerStore = useLoggerStore();

        useEffect(() => {
            if (selectedPhoto) {
                // URL.createObjectURL is added to show the image locally in the cropper
                const image = URL.createObjectURL(selectedPhoto);
                setImageUrl(image);
                setIsUpdateUserPhotoDialogOpen(true);
            }

            // Clear the files selected by the input element.
            if (inputFile.current) {
                if (!selectedPhoto) inputFile.current.value = '';
            }
        }, [selectedPhoto]);

        const triggerSelectPhoto = (): void => {
            if (inputFile && inputFile.current) {
                inputFile.current.value = '';
                inputFile.current.click();
            }
        };

        const handleDeletePhoto = async (): Promise<void> => {
            setIsDeletePhotoDialogOpen(false);
            setIsLoading(true);
            await deleteProfileImageStore.deleteProfileImage();
            setIsLoading(false);
            if (deleteProfileImageStore.error) {
                setIsErrorDialogOpen(true);
            } else {
                profileDetailsStore.deletePhoto();
            }
        };

        const SuccessDialogBox = (): React.ReactElement => {
            return (
                <SuccessDialog
                    title={t('profileSetting.updateUserPhoto')}
                    successMessage={t('profileSetting.profileManagement.photoUploadedSuccessfully')}
                    isDialogOpen={isSuccessDialogOpen}
                    onCancel={function (): void {
                        setIsSuccessDialogOpen(false);
                    }}
                    isCloseIconPresent={false}
                />
            );
        };

        const uploadImage = async (image: File): Promise<string> => {
            await updateProfileImageStore.uploadProfileImageStore.uploadFile(image);
            if (updateProfileImageStore.uploadProfileImageStore.recordId) {
                return updateProfileImageStore.uploadProfileImageStore.recordId;
            } else {
                return Promise.reject();
            }
        };

        const onUploadProfileImage = async (): Promise<void> => {
            setIsUpdateUserPhotoDialogOpen(false);
            if (croppedImage) {
                setIsLoading(true);
                const imageDetails = convertUrlToFile(croppedImage, selectedPhoto);
                updateProfileImageStore.uploadProfileImageStore.resetError();
                if (imageDetails) {
                    const selectedImage = imageDetails;
                    await uploadImage(selectedImage).then((recordId) => {
                        updateProfileImageStore.setFileId(recordId);
                        return updateProfileImageStore.updateProfileImage();
                    });
                } else {
                    setIsErrorDialogOpen(true);
                }
                if (
                    updateProfileImageStore.uploadProfileImageStore.error ||
                    updateProfileImageStore.error
                ) {
                    setErrorMessage(t('common.uploadFail'));
                } else {
                    if (updateProfileImageStore.profileImage) {
                        profileDetailsStore.updatePhoto(updateProfileImageStore.profileImage);
                        setIsSuccessDialogOpen(true);
                    } else {
                        setIsErrorDialogOpen(true);
                    }
                }
                setIsLoading(false);
            } else {
                setIsErrorDialogOpen(true);
            }
        };

        const FileSizeErrorDialogBox = (): React.ReactElement => {
            return (
                <ErrorDialog
                    isErrorDialogOpen={isUploadSizeError}
                    onClose={function (): void {
                        setIsUploadSizeError(false);
                    }}
                    title={t('profileSetting.updateUserPhoto')}
                    errorMessage={t('common.uploadSizeErrorMessage')}
                    primaryButtonText={t('profileSetting.selectPhoto')}
                    onPrimaryButtonClick={async (): Promise<void> => {
                        setIsUploadSizeError(false);
                        triggerSelectPhoto();
                    }}
                    secondaryButtonText={t('common.cancel')}
                />
            );
        };

        const UpdateUserPhotoDialogBox = (): React.ReactElement => {
            if (isUpdateUserPhotoDialogOpen) {
                return (
                    <UpdateUserPhotoDialog
                        isDialogOpen={isUpdateUserPhotoDialogOpen}
                        onCancelButtonClick={function (): void {
                            setSelectedPhoto(null);
                            setIsUpdateUserPhotoDialogOpen(false);
                        }}
                        onSelectPhotoButtonClick={onUploadProfileImage}
                        imageUrl={imageUrl}
                        setFinalCroppedImage={setCroppedImage}
                        imageType={selectedPhotoType}
                    />
                );
            } else {
                return <></>;
            }
        };

        const DeletePhotoDialogBox = (): React.ReactElement => {
            return (
                <DeletePhotoDialog
                    isDialogOpen={isDeletePhotoDialogOpen}
                    onCancelButtonClick={setIsDeletePhotoDialogOpen}
                    onPrimaryButtonClick={handleDeletePhoto}
                />
            );
        };

        const ErrorDialogBox = (): React.ReactElement => {
            return (
                <ErrorDialog
                    isErrorDialogOpen={isErrorDialogOpen}
                    onClose={function (): void {
                        if (deleteProfileImageStore.error) {
                            deleteProfileImageStore.removeError();
                        } else {
                            updateProfileImageStore.removeError();
                        }
                        setIsErrorDialogOpen(false);
                    }}
                    title={deleteProfileImageStore.error ? t('profileSetting.deletePhoto') : null}
                    errorMessage={
                        deleteProfileImageStore.error
                            ? t('profileSetting.noProfileImageToDelete')
                            : null
                    }
                />
            );
        };

        const UpdatePhotoErrorDialogBox = (): React.ReactElement => {
            if (errorMessage) {
                return (
                    <UpdatePhotoErrorDialog
                        isErrorDialogOpen={errorMessage ? true : false}
                        onCancelButtonClick={function (): void {
                            setErrorMessage(null);
                        }}
                        onPrimaryButtonClick={function (): void {
                            setErrorMessage(null);
                            triggerSelectPhoto();
                        }}
                        errorText={errorMessage}
                        fileName={selectedPhotoName}
                    />
                );
            } else {
                loggerStore.debug(
                    'Cannot find error message while creating UpdatePhotoErrorDialogBox'
                );
                return <></>;
            }
        };

        const SelectPhoto = (): React.ReactElement => {
            const typesFromStore = updateProfileImageStore.fileType();
            const prefix = 'image/';
            const fileTypes = typesFromStore.map((type) => {
                return prefix + type;
            });
            return (
                <form id="form-file-upload">
                    <input
                        type="file"
                        name="document"
                        accept={`${fileTypes.toString()}`}
                        id="document"
                        ref={inputFile}
                        onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                            if (event.target.files && event.target.files[0] !== null) {
                                const selectedFileType = event.target.files[0].name.substring(
                                    event.target.files[0].name.lastIndexOf('.') + 1
                                );
                                setSelectedPhotoType(selectedFileType);
                                setSelectedPhotoName(event.target.files[0].name);
                                if (
                                    updateProfileImageStore.handleFileSize(
                                        event.target.files[0].size
                                    ) === false
                                ) {
                                    setIsUploadSizeError(true);
                                } else if (typesFromStore.includes(selectedFileType)) {
                                    setSelectedPhoto(event.target.files[0]);
                                } else {
                                    setErrorMessage(t('common.invalidFileFormat'));
                                }
                            } else {
                                setIsErrorDialogOpen(true);
                            }
                        }}
                        style={{ display: 'none' }}
                    />
                </form>
            );
        };

        // If we get a successful response, then first-name, phone-number, email-id, gender will be present. Hence it is safe to force cast here
        const userDetailSectionCells: { label: string; cell: SectionRowCellType }[] = [
            {
                label: t('common.userPhoto'),
                cell: {
                    type: SectionRowCellEnum.ProfileImage,
                    imageUrl: profileDetailsStore.details.profilePhoto ?? '',
                    onUpdateClick: (): void => {
                        triggerSelectPhoto();
                    },
                    onDeleteClick: (): void => {
                        setIsDeletePhotoDialogOpen(true);
                    }
                }
            },
            {
                label: t('common.firstName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: profileDetailsStore.details.firstName!
                }
            },
            {
                label: t('common.lastName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: profileDetailsStore.details.lastName ?? '-'
                }
            },
            {
                label: t('common.otherNames'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: profileDetailsStore.details.otherNames ?? '-'
                }
            },
            {
                label: t('common.mobileNumber'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text:
                        (profileDetailsStore.details.phoneNumber &&
                            getFormattedPhoneNumber(profileDetailsStore.details.phoneNumber)) ??
                        '-'
                }
            },
            {
                label: t('common.emailId'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: profileDetailsStore.details.emailId!,
                    showEllipsis: true
                }
            },
            {
                label: t('common.gender'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: getGenderTranslation(t, profileDetailsStore.details.gender!)
                }
            },
            {
                label: t('common.roles'),
                cell: {
                    type: SectionRowCellEnum.UserRoleChips,
                    roles: profileDetailsStore.details.roles
                }
            }
        ];

        const getDetailSubComponents = (): React.ReactElement[] => {
            return userDetailSectionCells.map(({ label, cell }) => (
                <SectionRowComponentWithLabel key={label} label={label} cell={cell} />
            ));
        };

        return (
            <Stack>
                <Section
                    title={t('common.basicDetails')}
                    allowViewChange={false}
                    content={getDetailSubComponents()}
                    styleOverrides={{ padding: '0px' }}
                />
                {isDeletePhotoDialogOpen && DeletePhotoDialogBox()}
                {SelectPhoto()}
                {isErrorDialogOpen && ErrorDialogBox()}
                {errorMessage && UpdatePhotoErrorDialogBox()}
                {isUploadSizeError && FileSizeErrorDialogBox()}
                {selectedPhoto && UpdateUserPhotoDialogBox()}
                {updateProfileImageStore.profileImage && SuccessDialogBox()}
            </Stack>
        );
    }
);
