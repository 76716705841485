import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { SelectAccount } from './SelectAccount';
import { useRequestFundTransferStore, useValidateAccountDetailsStore } from '../store/hooks';
import { AccountType, ValidateAccountDetailsErrors } from '../store/ValidateAccountDetailsStore';
import { SystemAccountCountryDetailModel } from '../store/RequestFundTransferStore';
import { Instance } from 'mobx-state-tree';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { Dialog } from '../../../common/components/Dialog';
import { Button } from '../../../common/components/Button';

interface AccountDetailsProps {
    onNext: () => void;
}

export const AccountDetails = ({ onNext }: AccountDetailsProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const [senderError, setSenderError] = useState<string | null>(null);
    const [receiverError, setReceiverError] = useState<string | null>(null);
    const [senderAccountId, setSenderAccountId] = useState<string | null>(null);
    const [receiverAccountId, setReceiverAccountId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [senderCountry, setSenderCountry] = useState<Instance<
        typeof SystemAccountCountryDetailModel
    > | null>(null);
    const [receiverCountry, setReceiverCountry] = useState<Instance<
        typeof SystemAccountCountryDetailModel
    > | null>(null);
    const [senderAccountType, setSenderAccountType] = useState<AccountType | null>(null);
    const [senderSystemWalletError, setSenderSystemWalletError] = useState<string | null>(null);
    const [receiverSystemWalletError, setReceiverSystemWalletError] = useState<string | null>(null);
    const [receiverAccountType, setReceiverAccountType] = useState<AccountType | null>(null);
    const store = useRequestFundTransferStore();
    const validateAccountDetailsStore = useValidateAccountDetailsStore();
    const [isErrorDialogOpen, setIsErrorDialog] = useState<boolean>(false);

    useEffect(() => {
        if (store.senderAccountType) {
            setSenderAccountType(
                store.senderAccountType === AccountType.System
                    ? AccountType.System
                    : AccountType.User
            );
            setSenderCountry(store.senderCountrySystemDetails);
            setSenderAccountId(store.senderAccountId);
        }
        if (store.receiverAccountType) {
            setReceiverAccountType(
                store.receiverAccountType === AccountType.System
                    ? AccountType.System
                    : AccountType.User
            );
            setReceiverCountry(store.receiverCountrySystemDetails);
            setReceiverAccountId(store.receiverAccountId);
        }
    }, []);

    useEffect(() => {
        if (!isDialogOpen) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            validateAccountDetailsStore.removeError();
        }
    }, [isDialogOpen]);

    useEffect(() => {
        const isDetailsValid =
            senderAccountId &&
            senderAccountId.trim().length !== 0 &&
            receiverAccountId &&
            receiverAccountId.trim().length !== 0 &&
            senderError === null &&
            receiverError === null;
        setButtonDisabled(!isDetailsValid);
    }, [senderAccountId, receiverAccountId, senderError, receiverError]);

    const onProceed = async (): Promise<void> => {
        setLoading(true);
        setSenderError(null);
        setReceiverError(null);
        store.setSenderDetails(senderAccountId ?? '', senderAccountType, senderCountry);
        store.setReceiverDetails(receiverAccountId ?? '', receiverAccountType, receiverCountry);
        await validateAccountDetailsStore.validateAccountDetails(
            senderAccountId ?? '',
            receiverAccountId ?? ''
        );
        setLoading(false);
        if (validateAccountDetailsStore.error) {
            switch (validateAccountDetailsStore.error) {
                case ValidateAccountDetailsErrors.SenderAccountInactive:
                    if (senderAccountType === AccountType.System) {
                        setSenderSystemWalletError(t('common.inactiveAccountId'));
                    } else {
                        setSenderError(t('common.inactiveAccountId'));
                    }
                    break;
                case ValidateAccountDetailsErrors.RecipientAccountInactive:
                    if (receiverAccountType === AccountType.System) {
                        setReceiverSystemWalletError(t('common.inactiveAccountId'));
                    } else {
                        setReceiverError(t('common.inactiveAccountId'));
                    }
                    break;
                case ValidateAccountDetailsErrors.InvalidSenderAccount:
                    if (senderAccountType === AccountType.System) {
                        setSenderSystemWalletError(t('common.invalidAccountId'));
                    } else {
                        setSenderError(t('common.invalidAccountId'));
                    }
                    break;
                case ValidateAccountDetailsErrors.InvalidRecipientAccount:
                    if (receiverAccountType === AccountType.System) {
                        setReceiverSystemWalletError(t('common.invalidAccountId'));
                    } else {
                        setReceiverError(t('common.invalidAccountId'));
                    }
                    break;
                case ValidateAccountDetailsErrors.SenderProfileDisabled:
                    setSenderError(t('common.disabledUserAccountId'));
                    break;
                case ValidateAccountDetailsErrors.RecipientProfileDisabled:
                    setReceiverError(t('common.disabledUserAccountId'));
                    break;
                case ValidateAccountDetailsErrors.CannotTransferFundsToSameAccount:
                    if (
                        senderAccountType === AccountType.System &&
                        receiverAccountType === AccountType.System
                    ) {
                        setIsDialogOpen(true);
                    } else {
                        setSenderError(t('common.sameAccountIds'));
                        setReceiverError(t('common.sameAccountIds'));
                    }
                    break;
                case ValidateAccountDetailsErrors.RecipientProfileArchived:
                    setReceiverError(t('common.walletIdBelongsToArchiveUser'));
                    break;
                case ValidateAccountDetailsErrors.SenderProfileArchived:
                    setSenderError(t('common.walletIdBelongsToArchiveUser'));
                    break;
                case ValidateAccountDetailsErrors.SystemToSystemTransactionNotAllowed:
                    setIsDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setIsErrorDialog(true);
                    break;
                default:
                    setIsErrorDialog(true);
            }
        } else {
            onNext();
        }
    };

    return (
        <Stack spacing="32px">
            <LoadingIndicator isLoading={loading} />
            <SelectAccount
                title={t('genericFundsTransfer.fromAccountDetails')}
                onAccountSelected={(value): void => {
                    setSenderAccountId(value);
                    if (
                        validateAccountDetailsStore.error ===
                        ValidateAccountDetailsErrors.CannotTransferFundsToSameAccount
                    ) {
                        setReceiverError(null);
                    }
                    setSenderError(null);
                    setSenderSystemWalletError(null);
                }}
                onAccountTypeSelected={(value): void => {
                    setSenderAccountType(value);
                    setSenderError(null);
                    setSenderSystemWalletError(null);
                }}
                systemWalletErrorMessage={senderSystemWalletError}
                errorMessage={senderError}
                accountId={senderAccountId}
                accountType={senderAccountType}
                country={senderCountry}
                onCountrySelected={setSenderCountry}
            />
            <SelectAccount
                title={t('genericFundsTransfer.toAccountDetails')}
                onAccountSelected={(value): void => {
                    setReceiverAccountId(value);
                    if (
                        validateAccountDetailsStore.error ===
                        ValidateAccountDetailsErrors.CannotTransferFundsToSameAccount
                    ) {
                        setSenderError(null);
                    }
                    setReceiverError(null);
                    setReceiverSystemWalletError(null);
                }}
                onAccountTypeSelected={(value): void => {
                    setReceiverAccountType(value);
                    setReceiverError(null);
                    setReceiverSystemWalletError(null);
                }}
                systemWalletErrorMessage={receiverSystemWalletError}
                errorMessage={receiverError}
                accountId={receiverAccountId}
                accountType={receiverAccountType}
                country={receiverCountry}
                onCountrySelected={setReceiverCountry}
            />
            <Button
                name={'proceed'}
                title={t('common.proceed')}
                isDisabled={buttonDisabled}
                size="medium"
                variant={'filled'}
                color="primary"
                style={{ width: '90px', height: '40px', alignSelf: 'flex-end' }}
                onClick={onProceed}
            />
            <Box sx={{ width: '560px' }}>
                <Dialog
                    open={isDialogOpen}
                    title={t('common.cannotProcessRequest')}
                    secondaryButtonText={t('common.close')}
                    onSecondaryButtonClick={(): void => {
                        setIsDialogOpen(false);
                    }}
                    disableBackdropClick={true}>
                    <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                        {t('genericFundsTransfer.transferBetweenSystemAccountsError')}
                    </Typography>
                </Dialog>
            </Box>
            <ErrorDialog
                isErrorDialogOpen={isErrorDialogOpen}
                onClose={(): void => {
                    setIsErrorDialog(false);
                    validateAccountDetailsStore.removeError();
                }}
            />
        </Stack>
    );
};
