import {
    GetAgentRequestsRPC,
    ItemsPerPage,
    PageIndex,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { useGetAgentRequestsRPCClient } from '../rpcs/RPC';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { AgentRequestModel, getAgentRequestModel } from '../models/AgentRequestModel';
import {
    GetAgentRequestsSortOrder,
    GetAgentRequestsSortOrderEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/getAgentRequestsSortOrder';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum AgentRequestsHistoryErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const AgentRequestsHistoryStore = types
    .model({
        requestStatus: types.string,
        totalItems: types.number,
        agentRequests: types.array(AgentRequestModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<AgentRequestsHistoryErrors>(
                    'AgentRequestsHistoryErrors',
                    Object.values(AgentRequestsHistoryErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .views(() => ({
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        updateFilterOptions(requestStatus: Instance<string>): void {
            store.requestStatus = requestStatus;
        },
        fetchAgentRequestsHistory: flow(function* (pageIndex: number, sortOrder?: 'asc' | 'desc') {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetAgentRequestsRPC.Request(
                [
                    new GetAgentRequestsSortOrder(
                        0,
                        GetAgentRequestsSortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                store.requestStatus === AnyEnum.ANY
                    ? null
                    : RequestStatus.fromDTO({ case: store.requestStatus }),
                new ItemsPerPage(store.itemsPerPage()),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetAgentRequestsRPC.Response,
                GetAgentRequestsRPC.Errors.Errors
            > = yield useGetAgentRequestsRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const agentRequests = response.getAgentRequestsPaginationResponse.map(
                    (agentRequest) => getAgentRequestModel(agentRequest)
                );
                store.totalItems = response.totalItems;
                store.agentRequests = cast(agentRequests);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case AgentRequestsHistoryErrors.InvalidPageIndex:
                        store.error = AgentRequestsHistoryErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetAgentRequestsRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetAgentRequestsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createAgentRequestsHistoryStore = (): Instance<typeof AgentRequestsHistoryStore> => {
    return AgentRequestsHistoryStore.create({
        totalItems: 0,
        requestStatus: RequestStatus.RequestStatus.PENDING,
        agentRequests: []
    });
};
