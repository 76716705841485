/* eslint-disable no-unused-vars */
// The different Transaction Details types are returned by the functions defined below.
// Since we are only using the getWithinYafikaTransaction function, and the other functions that return the other different transaction types are not used,
// the elist rule for no-used-vars is disabled.
import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { GetTransactionDetailsRPC } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionDetailsRPC';
import RefundStatus = GetTransactionDetailsRPC.ResponseEnums.RefundStatus.RefundStatus;
import {
    TransactionDetailEnums,
    TransactionDetail
} from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';
import {
    BitmapImageType,
    BOName,
    BOUserComment,
    Comment,
    LocalizedText,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RequestDetails,
    UserName
} from '@resolut-tech/bcn-rpcs';

const getPaymentsTransaction = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('Payments')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('Sender Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Debit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        ),
        new TransactionDetail(
            new LocalizedText('Receiver Details'),
            new TransactionDetailEnums.CellType.Unstyled('BWB Prepaid')
        )
    ];
};

const getWithinBCNTransaction = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('Within Yafika')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('Sender Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Debit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        ),
        new TransactionDetail(
            new LocalizedText('Receiver Details'),
            new TransactionDetailEnums.CellType.Unstyled('Kristin Watson, +2606534982846')
        ),
        new TransactionDetail(
            new LocalizedText('Credit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fasfg654')
        ),
        new TransactionDetail(
            new LocalizedText('Narration for Receiver'),
            new TransactionDetailEnums.CellType.Unstyled(
                'Payment done for the Wi-Fi Bill for the month of July and August.'
            )
        )
    ];
};

const getAccountToAccountTransaction = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('Account to Account')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('User Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Debit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        ),
        new TransactionDetail(
            new LocalizedText('Credit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fasfg654')
        )
    ];
};

const getBCNToExternalTransaction = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('BCN to External')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('Sender Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Debit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        ),
        new TransactionDetail(
            new LocalizedText('Receiver Details'),
            new TransactionDetailEnums.CellType.Unstyled('Kristin Watson, +2606534982846')
        )
    ];
};

const getLoadWallet = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('Load Wallet')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('User Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Credit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        )
    ];
};

const getExternalToBCNTransaction = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Status'),
            new TransactionDetailEnums.CellType.PrefixIcon(
                TransactionDetailEnums.CellType.PrefixIconEnums.StatusIcon.StatusIcon.SUCCEEDED,
                'Success'
            )
        ),
        new TransactionDetail(
            new LocalizedText('Time, Date'),
            new TransactionDetailEnums.CellType.Unstyled('04:07 PM, 2021/01/07')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Type'),
            new TransactionDetailEnums.CellType.Unstyled('External to BCN')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        ),
        new TransactionDetail(
            new LocalizedText('Sender Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('Receiver Details'),
            new TransactionDetailEnums.CellType.Unstyled('Kristin Watson, +2606534982846')
        ),
        new TransactionDetail(
            new LocalizedText('Credit Account ID'),
            new TransactionDetailEnums.CellType.Unstyled('4cee8447-4812-4165-baf5-038b7fd4d434')
        )
    ];
};

const boUserComment = new BOUserComment(
    new LeoUUID(),
    new UserName(new BOName('Wade'), new BOName('Warren')),
    new MultiResolutionBitmapImage(
        new RemoteBitmapImage(
            new URL(
                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
            ),
            BitmapImageType.BitmapImageType.PNG,
            20,
            20
        ),
        new RemoteBitmapImage(
            new URL(
                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
            ),
            BitmapImageType.BitmapImageType.PNG,
            20,
            20
        ),
        new RemoteBitmapImage(
            new URL(
                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
            ),
            BitmapImageType.BitmapImageType.PNG,
            20,
            20
        ),
        new RemoteBitmapImage(
            new URL(
                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
            ),
            BitmapImageType.BitmapImageType.PNG,
            20,
            20
        )
    ),
    new LeoTimestamp('2022-09-30T02:03:59Z'),
    new Comment('The transaction was done by mistake by the user.')
);

export class MockGetTransactionDetailRPCImpl extends GetTransactionDetailsRPC {
    execute(
        _request: GetTransactionDetailsRPC.Request
    ): Promise<
        LeoRPCResult<GetTransactionDetailsRPC.Response, GetTransactionDetailsRPC.Errors.Errors>
    > {
        const response = new GetTransactionDetailsRPC.Response(
            RefundStatus.REFUNDABLE,
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Wade'), new BOName('Warren')),
                    null,
                    new LeoTimestamp(),
                    new Comment('The user had requested for the changes')
                )
            ),
            false,
            getWithinBCNTransaction()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetTransactionDetailsRPC.Response, GetTransactionDetailsRPC.Errors.Errors>
        >;
    }
}
