import { GetTransactionDetailsRPC } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionDetailsRPC';
import { GetTransactionDetailsRPCClientImpl } from '@resolut-tech/bcn-rpcs/build/back-office/getTransactionDetailsRPCClientImpl';
import { MockGetTransactionDetailRPCImpl } from './MockGetTransactionDetailRPCImpl';
import {
    GetLinkedTransactionsRPC,
    GetLinkedTransactionsRPCClientImpl,
    GetRefundHistoryRPC,
    GetRefundHistoryRPCClientImpl,
    GetMakerRefundTransactionDetailsRPC,
    GetRefundRequestListRPC,
    GetRefundRequestListRPCClientImpl,
    GetTransactionSearchResultsRPC,
    GetTransactionSearchResultsRPCClientImpl,
    GetRefundAmountRPC,
    GetRefundAmountRPCClientImpl,
    RequestTransactionRefundRPC,
    RequestTransactionRefundRPCClientImpl,
    GetCheckerRefundSummaryRPC,
    GetCheckerRefundSummaryRPCClientImpl,
    CheckRefundRequestRPC,
    CheckRefundRequestRPCClientImpl,
    WithdrawRefundRequestRPC,
    WithdrawRefundRequestRPCClientImpl,
    GetMakerRefundTransactionDetailsRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockGetTransactionsSearchResultsRPCImpl } from './MockGetTransactionsSearchResults';
import { MockGetLinkedTransactionsRPCImpl } from './MockGetLinkedTransactionsRPCImpl';
import { MockGetRefundHistoryRPCImpl } from './MockGetRefundHistoryRequestsRPCImpl';
import { MockGetMakerRefundTransactionDetailsRPCImpl } from './MockGetMakerRefundTransactionDetailsRPCImpl';
import { MockGetRefundRequestsRPCImpl } from './MockGetRefundRequestsRPCImpl';
import { MockGetRefundAmountRPCImpl } from './MockGetRefundAmountRPCImpl';
import { MockRequestTransactionRefundRPCImpl } from './MockRequestTransactionRefundRPCImpl';
import { MockGetCheckerRefundSummaryRPCImpl } from './MockGetCheckerRefundSummaryRPCImpl';
import { MockCheckRefundRequestRPCImpl } from './MockCheckRefundRequestRPCImpl';
import { WithdrawRefundRequestRPCImpl } from './WithdrawRefundRequestRPCImpl';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useGetTransactionSearchResultsClient = (
    apiClient: APIClient
): GetTransactionSearchResultsRPC => {
    if (MOCK) {
        return new MockGetTransactionsSearchResultsRPCImpl();
    } else {
        return new GetTransactionSearchResultsRPCClientImpl(apiClient);
    }
};

export const useGetTransactionDetailClient = (apiClient: APIClient): GetTransactionDetailsRPC => {
    if (MOCK) {
        return new MockGetTransactionDetailRPCImpl();
    } else {
        return new GetTransactionDetailsRPCClientImpl(apiClient);
    }
};

export const useGetLinkedTransactionsClient = (apiClient: APIClient): GetLinkedTransactionsRPC => {
    if (MOCK) {
        return new MockGetLinkedTransactionsRPCImpl();
    } else {
        return new GetLinkedTransactionsRPCClientImpl(apiClient);
    }
};

export const useGetRefundHistoryClient = (apiClient: APIClient): GetRefundHistoryRPC => {
    if (MOCK) {
        return new MockGetRefundHistoryRPCImpl();
    } else {
        return new GetRefundHistoryRPCClientImpl(apiClient);
    }
};

export const useGetMakerRefundTransactionDetailsClient = (
    apiClient: APIClient
): GetMakerRefundTransactionDetailsRPC => {
    if (MOCK) {
        return new MockGetMakerRefundTransactionDetailsRPCImpl();
    } else {
        return new GetMakerRefundTransactionDetailsRPCClientImpl(apiClient);
    }
};

export const useGetRefundRequestsListClient = (apiClient: APIClient): GetRefundRequestListRPC => {
    if (MOCK) {
        return new MockGetRefundRequestsRPCImpl();
    } else {
        return new GetRefundRequestListRPCClientImpl(apiClient);
    }
};

export const useGetRefundAmountClient = (apiClient: APIClient): GetRefundAmountRPC => {
    if (MOCK) {
        return new MockGetRefundAmountRPCImpl();
    } else {
        return new GetRefundAmountRPCClientImpl(apiClient);
    }
};

export const useRequestTransactionRefundClient = (
    apiClient: APIClient
): RequestTransactionRefundRPC => {
    if (MOCK) {
        return new MockRequestTransactionRefundRPCImpl();
    } else {
        return new RequestTransactionRefundRPCClientImpl(apiClient);
    }
};

export const useGetCheckerRefundSummaryRPCClient = (
    apiClient: APIClient
): GetCheckerRefundSummaryRPC => {
    if (MOCK) {
        return new MockGetCheckerRefundSummaryRPCImpl();
    } else {
        return new GetCheckerRefundSummaryRPCClientImpl(apiClient);
    }
};

export const useCheckRefundRequestRPCClient = (apiClient: APIClient): CheckRefundRequestRPC => {
    if (MOCK) {
        return new MockCheckRefundRequestRPCImpl();
    } else {
        return new CheckRefundRequestRPCClientImpl(apiClient);
    }
};

export const useWithdrawRefundRequestRPCClient = (
    apiClient: APIClient
): WithdrawRefundRequestRPC => {
    if (MOCK) {
        return new WithdrawRefundRequestRPCImpl();
    } else {
        return new WithdrawRefundRequestRPCClientImpl(apiClient);
    }
};
