import { Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useViewTransactionRulesStore } from '../store/hooks';
import { ViewCurrentTransactionValidationRules } from './ViewCurrentTransactionValidationRules';
import { useLoggerStore } from '../../../../log/hooks';

export const ViewCurrentTransactionRulesComponent = (): React.ReactElement => {
    const { t } = useTranslation();
    const store = useViewTransactionRulesStore();
    const loggerStore = useLoggerStore();

    async function fetchCurrentTransactionValidationDetails(): Promise<
        string | React.ReactElement[]
    > {
        await store.fetchCurrentTransactionValidationDetails().then(() => {
            return store.fetchCountryAndCBSIdList();
        });
        if (
            !store.currentTransactionValidationRules ||
            !store.currentTransactionValidationRules.length
        ) {
            loggerStore.debug(
                'Current transaction validation rules is empty in ViewTransactionRulesStore'
            );
            return t('common.noCurrentRulesFound');
        }
        return [
            <Stack key="viewCurrentTransactionValidationRules">
                <ViewCurrentTransactionValidationRules
                    rules={store.currentTransactionValidationRules}
                />
            </Stack>
        ];
    }

    return (
        <Section
            title={t('common.viewRules')}
            viewOverrides={{
                empty: { message: t('common.noDataFound') },
                loading: { message: t('common.loadingDetails') }
            }}
            onExpand={fetchCurrentTransactionValidationDetails}
            expandIndicator={t('common.showMore')}
            collapseIndicator={t('common.showLess')}
        />
    );
};
