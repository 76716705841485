import { BackOfficeResendSignInOTPRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoTimestamp } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../networking/MockExecuteHelper';

export class MockResendSignInOTPRPCImpl extends BackOfficeResendSignInOTPRPC {
    execute(
        _request: BackOfficeResendSignInOTPRPC.Request
    ): Promise<
        LeoRPCResult<
            BackOfficeResendSignInOTPRPC.Response,
            BackOfficeResendSignInOTPRPC.Errors.Errors
        >
    > {
        const response = new BackOfficeResendSignInOTPRPC.Response(
            new LeoTimestamp('2022-09-21T05:03:59Z'),
            new LeoTimestamp('2022-09-21T05:03:59Z'),
            2
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                BackOfficeResendSignInOTPRPC.Response,
                BackOfficeResendSignInOTPRPC.Errors.Errors
            >
        >;
    }
}
