import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { RequestStatus, IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { StatusType } from '../components/StatusComponent';
import { AnyEnum } from '../enums/AnyEnum';

export const getRequestStatusValue = (value: RequestStatus.RequestStatus): string => {
    const { t } = useTranslation();
    const values = RequestStatus.RequestStatus;
    switch (value) {
        case values.PENDING:
            return t('common.pending');
        case values.DISCARDED:
            return t('common.discarded');
        case values.DENIED:
            return t('common.denied');
        case values.APPROVED:
            return t('common.approved');
        case values.WITHDRAWN:
            return t('common.withdrawn');
    }
};

export const getRequestStatusOptionsWithAny = (): DropdownItem[] => {
    const { t } = useTranslation();
    let values = Object.keys(RequestStatus.RequestStatus);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getRequestStatusValue(value as RequestStatus.RequestStatus);
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getIntermediateRequestStatusValue = (
    value: IntermediateRequestStatus.IntermediateRequestStatus
): string => {
    const { t } = useTranslation();
    const values = IntermediateRequestStatus.IntermediateRequestStatus;
    switch (value) {
        case values.PENDING:
            return t('common.pending');
        case values.DISCARDED:
            return t('common.discarded');
        case values.DENIED:
            return t('common.denied');
        case values.APPROVED:
            return t('common.approved');
        case values.WITHDRAWN:
            return t('common.withdrawn');
        case values.PROCESSING:
            return t('common.processing');
    }
};

export const getIntermediateRequestStatusOptionsWithAny = (): DropdownItem[] => {
    const { t } = useTranslation();
    let values = Object.keys(IntermediateRequestStatus.IntermediateRequestStatus);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getIntermediateRequestStatusValue(
            value as IntermediateRequestStatus.IntermediateRequestStatus
        );
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getRequestStatusOptions = (): DropdownItem[] => {
    const values = Object.keys(RequestStatus.RequestStatus);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getRequestStatusValue(value as RequestStatus.RequestStatus);
        return { name: dropdownValue ?? '', value };
    });
};

export const getStatusTypeFromRequestStatus = (status: RequestStatus.RequestStatus): StatusType => {
    switch (status) {
        case RequestStatus.RequestStatus.APPROVED:
            return StatusType.APPROVED;
        case RequestStatus.RequestStatus.DENIED:
            return StatusType.DENIED;
        case RequestStatus.RequestStatus.DISCARDED:
            return StatusType.DISCARDED;
        case RequestStatus.RequestStatus.PENDING:
            return StatusType.PENDING;
        case RequestStatus.RequestStatus.WITHDRAWN:
            return StatusType.WITHDRAWN;
    }
};

export const getStatusTypeFromIntermediateRequestStatus = (
    status: IntermediateRequestStatus.IntermediateRequestStatus
): StatusType => {
    switch (status) {
        case IntermediateRequestStatus.IntermediateRequestStatus.PROCESSING:
            return StatusType.PROCESSING;
        case IntermediateRequestStatus.IntermediateRequestStatus.APPROVED:
            return StatusType.APPROVED;
        case IntermediateRequestStatus.IntermediateRequestStatus.DENIED:
            return StatusType.DENIED;
        case IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED:
            return StatusType.DISCARDED;
        case IntermediateRequestStatus.IntermediateRequestStatus.PENDING:
            return StatusType.PENDING;
        case IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN:
            return StatusType.WITHDRAWN;
    }
};
