import React, { useState } from 'react';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Upload } from '../../../../../assets/icons/Upload';
import { Typography } from '@mui/material';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { FileUploadError } from '../../utils/FileUploadUtils';
import { UploadProfileImageStore } from '../../../profile/store/UploadProfileImageStore';
import { FileAttributesEnums } from '@resolut-tech/bcn-rpcs/build/document/fileAttributes';
import { UploadFeeRulesStore } from '../../../fee-rules/store/UploadFeeRulesStore';
import { UploadTransactionRulesStore } from '../../../transaction-rules/store/UploadTransactionRulesStore';

export interface FileUploadProps {
    onFileUploadError: (error: FileUploadError) => void;
    onFileUploadBegin: (fileName: string) => void;
    onFileUploadSuccess: (fileId: LeoUUID) => void;
    store: Instance<
        | typeof UploadProfileImageStore
        | typeof UploadFeeRulesStore
        | typeof UploadTransactionRulesStore
    >;
}

export const FileUploadComponent = observer(
    ({
        onFileUploadError,
        onFileUploadBegin,
        onFileUploadSuccess,
        store
    }: FileUploadProps): React.ReactElement => {
        const palette = usePalette();
        const typography = useTypography();
        const [dragActive, setDragActive] = useState(false);
        const { t } = useTranslation();

        // Since the drag event is dragEnter, hence we are setting the dragActive to true
        const onDragEnter = (
            event: React.DragEvent<HTMLDivElement> | React.DragEvent<HTMLFormElement>
        ): void => {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(true);
        };

        // Since the drag event is dragOver, hence we are setting the dragActive to true
        const onDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(true);
        };

        // Since the drag event is dragLeave, hence we are setting the dragActive to false
        const onDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(false);
        };

        const handleFileUpload = async (_file: File): Promise<void> => {
            store.resetError();
            onFileUploadBegin(_file.name);
            await store.uploadFile(_file, FileAttributesEnums.FileExtension.FileExtension.CSV);
            if (store.error) {
                onFileUploadError(store.error);
            } else if (store.recordId) {
                onFileUploadSuccess(new LeoUUID(store.recordId));
            }
        };

        // Since this is a drop event, the drag event is no longer happening and hence dragActive is set to false. We will handle the file upload in this function.
        const onDrop = (event: React.DragEvent<HTMLDivElement>): void => {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(false);
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                const _file = event.dataTransfer.files[0];
                handleFileUpload(_file);
            }
        };

        // this function gets triggered when the user uses the input field to upload the file ( by clicking on the label in place of using drag and drop )
        const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.preventDefault();
            if (event.target.files && event.target.files[0]) {
                const _file = event.target.files[0];
                handleFileUpload(_file);
            }
        };

        // Since we are not using the form to submit the file for upload, hence we are not handling the onSubmit function
        const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
            event.preventDefault();
        };

        return (
            <form id="form-file-upload" onDragEnter={onDragEnter} onSubmit={onSubmit}>
                <input
                    type="file"
                    // since we are only allowing the csv type as of now, hence the accept value is set to csv
                    accept=".csv"
                    id="input-file-upload"
                    // since we are only allowing one file to be selected, hence multiple is set to false
                    multiple={false}
                    onChange={onChange}
                    // since we don't require the default UI for input field hence the display is set to none
                    style={{ display: 'none' }}
                />
                <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? 'drag-active' : ''}>
                    <div
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        style={{
                            backgroundColor: palette.primary[100],
                            borderRadius: '8px',
                            border: `1px dashed ${palette.primary[300]}`,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <Upload
                            color={palette.label[200]}
                            style={{ width: '32px', height: '32px', marginTop: '24px' }}
                        />
                        <Typography
                            component={'span'}
                            sx={{ ...typography.body2, marginTop: '12px' }}>
                            {`${t('common.dragAndDropToUpload')} `}
                            <Typography
                                // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag
                                component={'span'}
                                sx={{
                                    textDecoration: 'underline',
                                    ...typography.button2,
                                    color: palette.primary[300]
                                }}
                                display="inline">
                                {t('common.browse')}
                            </Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            sx={{
                                ...typography.caption1,
                                color: palette.label[200],
                                marginTop: '12px',
                                marginBottom: '24px'
                            }}>
                            {t('common.fileFormatCSV')}
                        </Typography>
                    </div>
                </label>
            </form>
        );
    }
);
