import { cast, flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    getRequestDetailsModel,
    RequestDetailsModel
} from '../../common/models/RequestDetailsModel';
import { GetBCNUserRequestHistoryRPC, UserStatus } from '@resolut-tech/bcn-rpcs';
import { useGetBCNUserRequestHistoryRPCClient } from '../rpcs/RPC';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserRequestHistoryErrors {
    InvalidUserID = 'INVALID_USER_ID'
}

const BCNUserRequestHistoryModel = types.model({
    oldUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    updatedUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    requestDetails: RequestDetailsModel
});

export const BCNUserRequestHistoryStore = types
    .model('RequestHistory', {
        requests: types.array(BCNUserRequestHistoryModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserRequestHistoryErrors>(
                    'BCNUserRequestHistoryErrors',
                    Object.values(BCNUserRequestHistoryErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchBCNRequestHistory: flow(function* (userId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetBCNUserRequestHistoryRPC.Request(new LeoUUID(userId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetBCNUserRequestHistoryRPC.Response,
                    GetBCNUserRequestHistoryRPC.Errors.Errors
                > = yield useGetBCNUserRequestHistoryRPCClient(apiClient).execute(request);
                store.error = null;
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const requestsHistory = response.userRequests.map((requestHistory) => {
                        return {
                            oldUserStatus: requestHistory.oldUserStatus,
                            updatedUserStatus: requestHistory.updatedUserStatus,
                            requestDetails: getRequestDetailsModel(requestHistory.requestDetails)
                        };
                    });
                    store.requests = cast(requestsHistory);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case BCNUserRequestHistoryErrors.InvalidUserID:
                            store.error = BCNUserRequestHistoryErrors.InvalidUserID;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBCNUserRequestHistoryRPC `
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetBCNUserRequestHistoryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = BCNUserRequestHistoryErrors.InvalidUserID;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetBCNUserRequestHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetBCNUserRequestHistoryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createBCNUserRequestHistoryStore = (): Instance<typeof BCNUserRequestHistoryStore> => {
    return BCNUserRequestHistoryStore.create({
        requests: []
    });
};
