import React, { useEffect, useState } from 'react';
import { Button, Section, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { UserPrivileges } from '../../user/UserPrivileges';
import { RequestDialog } from '../../common/components/dialog/RequestDialog';
import { RequestConfirmationDialog } from '../../common/components/dialog/RequestConfirmationDialog';
import { observer } from 'mobx-react';
import { useBOUserProfileManagement } from '../store/hooks';
import { useUserStore } from '../../store/hooks';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { BOProfileManagementErrors } from '../store/BOUserProfileManagementStore';
import { UserStatusTransition } from '@resolut-tech/bcn-rpcs';
import { NetworkingError } from '../../../error/store/ErrorStore';

interface BOUserProfileManagementProps {
    userIsActive: boolean;
    userId: string;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccessfulRequest: () => void;
}

type ManagementAction =
    | 'resetPassword'
    | 'regenerateAuthCode'
    | 'activate'
    | 'deactivate'
    | 'archive';

export const BOUserProfileManagement = observer(
    ({
        userIsActive,
        userId,
        setIsLoading,
        onSuccessfulRequest
    }: BOUserProfileManagementProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const userStore = useUserStore();
        const userPrivileges = userStore.privileges;
        const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [actionType, setActionType] = useState<ManagementAction>();
        const [dialogTitle, setDialogTitle] = useState<string | null>(null);
        const [confirmationDialogDescription, setConfirmationDialogDescription] = useState<
            string | null
        >(null);
        const [dialogButtonTitle, setDialogButtonTitle] = useState<string | null>(null);
        const [comment, setComment] = useState<string | null>(null);
        const [pendingRequests, setPendingRequests] = useState<React.ReactElement | null>(null);
        const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
        const [errorMessage, setErrorMessage] = useState<string | null>();
        const store = useBOUserProfileManagement();

        const constructPendingRequests = (): React.ReactElement => {
            return (
                <Stack spacing={'20px'} key={'pendingRequests'}>
                    <Typography sx={{ color: palette.label[300], ...typography.sh3 }}>
                        {t('common.pendingRequests')}
                    </Typography>
                    <Stack spacing={'12px'}>
                        {store.pendingRequests?.map((request) => {
                            return (
                                <Typography
                                    key={request.name}
                                    sx={{
                                        color: palette.label[300],
                                        ...typography.body2
                                    }}>{`${request.name}: ${request.count} `}</Typography>
                            );
                        })}
                    </Stack>
                </Stack>
            );
        };

        useEffect(() => {
            if (store.error) {
                switch (store.error) {
                    case BOProfileManagementErrors.InvalidBoUserId:
                        setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    case BOProfileManagementErrors.UserAlreadyArchived:
                        setErrorMessage(t('boUser.userAlreadyArchived'));
                        break;
                    case BOProfileManagementErrors.UserAlreadyInactive:
                        setErrorMessage(t('boUser.userAlreadyInactive'));
                        break;
                    case BOProfileManagementErrors.UserAlreadyActive:
                        setErrorMessage(t('boUser.userAlreadyActive'));
                        break;
                    case BOProfileManagementErrors.UserHasPendingRequests:
                        setPendingRequests(constructPendingRequests());
                        break;
                    case BOProfileManagementErrors.CannotDeactivateSelfProfile:
                        setErrorMessage(t('boUser.cannotDeactivateSelfProfile'));
                        break;
                    case BOProfileManagementErrors.UserAlreadyDeactivated:
                        setErrorMessage(t('boUser.userAlreadyDeactivated'));
                        break;
                    case BOProfileManagementErrors.CannotArchiveActiveBoUser:
                        setErrorMessage(t('boUser.cannotArchiveActiveBoUser'));
                        break;
                    case BOProfileManagementErrors.CannotArchiveSelfProfile:
                        setErrorMessage(t('boUser.cannotArchiveSelfProfile'));
                        break;
                    case BOProfileManagementErrors.CannotChangeSelfPassword:
                        setErrorMessage(t('boUser.cannotChangeSelfPassword'));
                        break;
                    case BOProfileManagementErrors.CannotRequestSelfAuthCodes:
                        setErrorMessage(t('boUser.cannotRequestSelfAuthCodes'));
                        break;
                    case NetworkingError.InternalError:
                        setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            }
        }, [store.error]);

        const forceResetPassword = (): React.ReactElement => {
            return (
                <Stack spacing={'8px'} key={'forceRestPassword'}>
                    <Button
                        name={'forceResetPassword'}
                        size={'small'}
                        variant={'outlined-neutral'}
                        onClick={(): void => {
                            setDialogTitle(t('boUser.requestToForceResetPassword'));
                            setDialogButtonTitle(t('common.proceed'));
                            setConfirmationDialogDescription(
                                t('boUser.resetPasswordConfirmationDescription')
                            );
                            setActionType('resetPassword');
                            setIsDialogOpen(true);
                        }}
                        title={t('boUser.requestToForceResetPassword')}
                        style={{ width: '210px' }}
                    />
                    <Typography sx={{ color: palette.label[300], ...typography.body2 }}>
                        {t('boUser.forceResetPasswordDescription')}
                    </Typography>
                </Stack>
            );
        };

        const regenerateAuthCodes = (): React.ReactElement => {
            return (
                <Stack spacing={'8px'} key={'regenerateAuthCodes'}>
                    <Button
                        name={'regenerateAuthCodes'}
                        size={'small'}
                        variant={'outlined-neutral'}
                        onClick={(): void => {
                            setDialogTitle(t('boUser.requestToRegenerateAuthCodes'));
                            setDialogButtonTitle(t('common.proceed'));
                            setConfirmationDialogDescription(
                                t('boUser.regenerateAuthCodeConfirmationDescription')
                            );
                            setActionType('regenerateAuthCode');
                            setIsDialogOpen(true);
                        }}
                        title={t('boUser.requestToRegenerateAuthCodes')}
                        style={{ width: '219px' }}
                    />
                    <Typography sx={{ color: palette.label[300], ...typography.body2 }}>
                        {t('boUser.reGenerateAuthCodesDescription')}
                    </Typography>
                </Stack>
            );
        };

        const deactivateUser = (): React.ReactElement => {
            return (
                <Stack spacing={'8px'} key={'deactivateUser'}>
                    <Button
                        name={'deactivateUser'}
                        size={'small'}
                        variant={'outlined-neutral'}
                        onClick={(): void => {
                            setDialogTitle(t('boUser.requestForUserDeactivation'));
                            setDialogButtonTitle(t('common.submitRequest'));
                            setActionType('deactivate');
                            setIsDialogOpen(true);
                        }}
                        title={t('boUser.requestForUserDeactivation')}
                        style={{ width: '194px' }}
                    />
                    <Typography sx={{ color: palette.label[300], ...typography.body2 }}>
                        {t('boUser.requestForUserDeactivationDescription')}
                    </Typography>
                </Stack>
            );
        };

        const archiveUser = (): React.ReactElement => {
            return (
                <Stack spacing={'8px'} key={'archiveUser'}>
                    <Button
                        name={'archiveUser'}
                        size={'small'}
                        variant={'outlined-color'}
                        color={'error'}
                        onClick={(): void => {
                            setDialogTitle(t('boUser.requestToArchiveUser'));
                            setDialogButtonTitle(t('common.submitRequest'));
                            setActionType('archive');
                            setIsDialogOpen(true);
                        }}
                        title={t('boUser.requestToArchiveUser')}
                        style={{ width: '161px' }}
                    />
                    <Typography sx={{ color: palette.label[300], ...typography.body2 }}>
                        {t('boUser.requestToArchiveUserDescription')}
                    </Typography>
                </Stack>
            );
        };

        const activateUser = (): React.ReactElement => {
            return (
                <Stack spacing={'8px'} key={'activateUser'}>
                    <Button
                        name={'activateUser'}
                        size={'small'}
                        variant={'outlined-neutral'}
                        onClick={(): void => {
                            setDialogTitle(t('boUser.requestForUserReactivation'));
                            setDialogButtonTitle(t('common.submitRequest'));
                            setActionType('activate');
                            setIsDialogOpen(true);
                        }}
                        title={t('boUser.requestForUserReactivation')}
                        style={{ width: '193px' }}
                    />
                    <Typography sx={{ color: palette.label[300], ...typography.body2 }}>
                        {t('boUser.requestForeUserReactivationDescription')}
                    </Typography>
                </Stack>
            );
        };

        const managementActions = (): React.ReactElement[] | null => {
            if (userIsActive) {
                const actionElements: React.ReactElement[] = [];
                if (userPrivileges.includes(UserPrivileges.RequestForceResetPassword)) {
                    actionElements.push(forceResetPassword());
                }
                if (userPrivileges.includes(UserPrivileges.RequestRegenerateAuthCodes)) {
                    actionElements.push(regenerateAuthCodes());
                }
                if (userPrivileges.includes(UserPrivileges.RequestActivateDeactivateBOUser)) {
                    actionElements.push(deactivateUser());
                }
                return actionElements;
            } else {
                const actionElements: React.ReactElement[] = [];
                if (userPrivileges.includes(UserPrivileges.RequestActivateDeactivateBOUser)) {
                    actionElements.push(activateUser());
                }
                // This is done since `Request to archive user` should only be shown for deactivated user
                if (userPrivileges.includes(UserPrivileges.RequestArchiveBOUser)) {
                    actionElements.push(archiveUser());
                }
                return actionElements;
            }
        };

        const onDialogButtonClick = async (
            _comment: string,
            needsConfirmation: boolean
        ): Promise<void> => {
            setIsLoading(true);
            setIsDialogOpen(false);
            if (!needsConfirmation) {
                setIsConfirmationDialogOpen(false);
                setComment(null);
            }
            switch (actionType) {
                case 'resetPassword':
                    if (needsConfirmation) {
                        setComment(_comment);
                        setIsConfirmationDialogOpen(true);
                    } else {
                        await store.requestForceResetPassword(userId, _comment);
                    }
                    break;
                case 'regenerateAuthCode':
                    if (needsConfirmation) {
                        setComment(_comment);
                        setIsConfirmationDialogOpen(true);
                    } else {
                        await store.requestRegenerateAuthCodes(userId, _comment);
                    }
                    break;
                case 'deactivate':
                    await store.requestToReactivateOrDeactivateBOUser(
                        userId,
                        _comment,
                        UserStatusTransition.UserStatusTransition.DEACTIVATE
                    );
                    break;
                case 'activate':
                    await store.requestToReactivateOrDeactivateBOUser(
                        userId,
                        _comment,
                        UserStatusTransition.UserStatusTransition.REACTIVATE
                    );
                    break;
                case 'archive':
                    await store.requestArchiveUser(userId, _comment);
                    break;
            }
            setIsLoading(false);
            if (store.error && store.pendingRequests) {
                setConfirmationDialogDescription(t('boUser.archiveWarningDescription'));
                setIsConfirmationDialogOpen(true);
            } else if (store.error) {
                setIsErrorDialogOpen(true);
            } else {
                switch (actionType) {
                    case 'activate':
                    case 'archive':
                    case 'deactivate':
                        onSuccessfulRequest();
                        break;
                    case 'regenerateAuthCode':
                    case 'resetPassword':
                        if (!needsConfirmation) {
                            // For regenerateAuthCode and resetPassword, a confirmation dialog is shown to the user,
                            //  we will call the callback function only when the confirmation is no longer required / already shown to the user
                            onSuccessfulRequest();
                        }
                        break;
                }
            }
        };

        return (
            <>
                <RequestDialog
                    title={dialogTitle}
                    buttonTitle={dialogButtonTitle}
                    isDialogOpen={isDialogOpen}
                    onClose={(): void => {
                        setComment(null);
                        setIsDialogOpen(false);
                    }}
                    onSubmit={(_comment): Promise<void> => onDialogButtonClick(_comment, true)}
                />
                <RequestConfirmationDialog
                    isDialogOpen={isConfirmationDialogOpen}
                    onClose={(): void => {
                        setComment(null);
                        setIsConfirmationDialogOpen(false);
                    }}
                    onSubmit={(_comment): Promise<void> => onDialogButtonClick(_comment, false)}
                    title={dialogTitle}
                    description={confirmationDialogDescription}
                    comment={comment}
                    content={pendingRequests}
                    primaryButtonText={t('common.submitRequest')}
                />
                <ErrorDialog
                    isErrorDialogOpen={isErrorDialogOpen}
                    title={dialogTitle}
                    onClose={(): void => {
                        setIsErrorDialogOpen(false);
                        store.resetError();
                    }}
                    errorMessage={errorMessage}
                />
                <Section
                    title={t('common.profileManagement')}
                    allowViewChange={false}
                    content={[
                        <Stack key={'container'} padding={'16px'} spacing={'16px'}>
                            {managementActions()}
                        </Stack>
                    ]}
                />
            </>
        );
    }
);
