import { Stack } from '@mui/material';
import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewTransactionRulesStore } from '../store/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import {
    TransactionValidationRulesDiffStore,
    TransactionValidationRulesDiffStoreErrors
} from '../store/TransactionValidationRulesDiffStore';
import { ViewTransactionValidationRulesDiff } from './ViewTransactionValidationRulesDiff';

export const ViewTransactionRulesChangesComponent = ({
    requestId,
    store
}: {
    requestId: string;
    store: Instance<typeof TransactionValidationRulesDiffStore>;
}): React.ReactElement => {
    const { t } = useTranslation();
    const viewTransactionRulesStore = useViewTransactionRulesStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function fetchTransactionValidationRuleDiff(): Promise<string | React.ReactElement[]> {
        await store.fetchTransactionValidationRulesDiff(requestId).then(() => {
            return viewTransactionRulesStore.fetchCountryAndCBSIdList();
        });
        if (store.error) {
            switch (store.error) {
                case TransactionValidationRulesDiffStoreErrors.OldRulesNotFound:
                    return t('common.somethingWentWrongProcessingRequest');
                case TransactionValidationRulesDiffStoreErrors.InvalidRequestId:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        } else {
            return [
                <Stack key="transactionValidationRuleDiffView">
                    <ViewTransactionValidationRulesDiff
                        oldTransactionValidationRules={store.oldTransactionValidationRules}
                        updatedTransactionValidationRules={store.updatedTransactionValidationRules}
                    />
                </Stack>
            ];
        }
    }
    return (
        <>
            <Section
                title={t('common.viewChanges')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchTransactionValidationRuleDiff}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </>
    );
};
