import {
    CheckCBSTransferRequestForUnclaimedFundsRPC,
    CheckerResponse,
    Comment,
    GetCheckerUnclaimedFundsRequestInfoSummaryRPC,
    GetUnclaimedFundsRequestDetailsListRPC
} from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance, cast } from 'mobx-state-tree';
import { Status } from '../../common/enums/StatusEnum';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    useCheckCBSTransferRequestForUnclaimedFundsRPCClient,
    useGetCheckerUnclaimedFundsRequestInfoSummaryRPCClient,
    useGetUnclaimedFundsRequestDetailsListRPCClient
} from '../rpcs/RPC';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import {
    CBSRequestDetailsModel,
    getCBSRequestDetailsModel
} from '../../common/models/CBSRequestDetailsModel';
import { getFullName } from '../../../../utils/StringUtils';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { UnclaimedFundsListModel } from '../models/UnclaimedFundsListModel';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { LeoErrors } from '../../common/errors/LeoErrors';

export enum CheckCBSTransferRequestStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    InvalidComment = 'INVALID_COMMENT',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    RequestDiscarded = 'REQUEST_DISCARDED',
    RequestDiscardedDueToUnknownError = 'REQUEST_DISCARDED_DUE_TO_UNKNOWN_ERROR',
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const CheckCBSTransferRequestStore = types
    .model({
        comment: types.maybeNull(types.string),
        countryListStore: CountryListStore,
        requestDetails: types.maybeNull(CBSRequestDetailsModel),
        unclaimedFundsList: types.array(UnclaimedFundsListModel),
        totalItems: types.number,
        totalNoOfTransaction: types.maybeNull(types.number),
        totalAmount: types.maybeNull(AmountModel),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckCBSTransferRequestStoreErrors>(
                    'CheckCBSTransferRequestStoreErrors',
                    Object.values(CheckCBSTransferRequestStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetComment(): void {
            store.comment = null;
        },
        removeError(): void {
            store.error = null;
        },
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        checkCBSTransferRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.comment = comment;
            try {
                if (store.comment && requestId && store.status) {
                    const request = new CheckCBSTransferRequestForUnclaimedFundsRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckCBSTransferRequestForUnclaimedFundsRPC.Response,
                        CheckCBSTransferRequestForUnclaimedFundsRPC.Errors.Errors
                    > = yield useCheckCBSTransferRequestForUnclaimedFundsRPCClient(
                        apiClient
                    ).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case CheckCBSTransferRequestStoreErrors.InvalidRequestId:
                                store.error = CheckCBSTransferRequestStoreErrors.InvalidRequestId;
                                break;
                            case CheckCBSTransferRequestStoreErrors.CannotCheckSelfRequest:
                                store.error =
                                    CheckCBSTransferRequestStoreErrors.CannotCheckSelfRequest;
                                break;
                            case CheckCBSTransferRequestStoreErrors.RequestAlreadyChecked:
                                store.error =
                                    CheckCBSTransferRequestStoreErrors.RequestAlreadyChecked;
                                break;
                            case CheckCBSTransferRequestStoreErrors.RequestAlreadyWithdrawn:
                                store.error =
                                    CheckCBSTransferRequestStoreErrors.RequestAlreadyWithdrawn;
                                break;
                            case CheckCBSTransferRequestStoreErrors.RequestDiscarded:
                                store.error = CheckCBSTransferRequestStoreErrors.RequestDiscarded;
                                break;
                            case CheckCBSTransferRequestStoreErrors.RequestDiscardedDueToUnknownError:
                                store.error =
                                    CheckCBSTransferRequestStoreErrors.RequestDiscardedDueToUnknownError;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckCBSTransferRequestForUnclaimedFundsRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckCBSTransferRequestForUnclaimedFundsRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        'status, requestId or comment are not present in CheckCBSTransferRequestStore'
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckCBSTransferRequestStoreErrors.InvalidRequestId;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = CheckCBSTransferRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unknown error: ${error} occurred in CheckCBSTransferRequestForUnclaimedFundsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckCBSTransferRequestForUnclaimedFundsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        getCheckerUnclaimedFundsRequestInfoSummary: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Request();
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Response,
                    GetCheckerUnclaimedFundsRequestInfoSummaryRPC.Errors.Errors
                > = yield useGetCheckerUnclaimedFundsRequestInfoSummaryRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.totalNoOfTransaction =
                        response.requestDetails?.numberOfTransactions ?? null;
                    store.totalAmount = response.requestDetails?.totalAmount
                        ? getAmountModel(response.requestDetails?.totalAmount)
                        : null;
                    store.requestDetails = response.requestDetails?.requestDetails
                        ? getCBSRequestDetailsModel(response.requestDetails.requestDetails)
                        : null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in GetCheckerUnclaimedFundsRequestInfoSummaryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetCheckerUnclaimedFundsRequestInfoSummaryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetCheckerUnclaimedFundsRequestInfoSummaryRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        getUnclaimedFundsRequestDetailsList: flow(function* (
            requestId: string,
            pageIndex: number,
            itemsPerPage: number,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetUnclaimedFundsRequestDetailsListRPC.Request(
                    new LeoUUID(requestId),
                    pageIndex,
                    itemsPerPage,
                    getSortOrder(sortOrder)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetUnclaimedFundsRequestDetailsListRPC.Response,
                    GetUnclaimedFundsRequestDetailsListRPC.Errors.Errors
                > = yield useGetUnclaimedFundsRequestDetailsListRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const unclaimedFundList = response.unclaimedFundsList.map((unclaimedFund) => {
                        return {
                            unclaimedFundId: unclaimedFund.unclaimedFundId.uuid,
                            createdAt: getFormattedTimeDateWithComma(
                                new Date(unclaimedFund.createdAt.timestamp)
                            ),
                            userName: getFullName(unclaimedFund.userName),
                            userPhoneNumber: unclaimedFund.userPhoneNumber.phoneNumber,
                            amount: getAmountModel(unclaimedFund.amount),
                            country: store.countryListStore
                                .countryList()
                                .filter((_country: CountryViewModel) => {
                                    return _country.countryCode === unclaimedFund.countryCode.code;
                                })[0].name
                        };
                    });
                    store.totalItems = response.totalItems;
                    store.unclaimedFundsList = cast(unclaimedFundList);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case CheckCBSTransferRequestStoreErrors.InvalidRequestId:
                            store.error = CheckCBSTransferRequestStoreErrors.InvalidRequestId;
                            break;
                        case CheckCBSTransferRequestStoreErrors.InvalidPageIndex:
                            store.error = CheckCBSTransferRequestStoreErrors.InvalidPageIndex;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetUnclaimedFundsRequestDetailsListRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case CheckCBSTransferRequestStoreErrors.InvalidRequestId:
                            store.error = CheckCBSTransferRequestStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 10
    }));

export const createCheckCBSTransferRequestStore = (): Instance<
    typeof CheckCBSTransferRequestStore
> => {
    return CheckCBSTransferRequestStore.create({
        countryListStore: createCountryListStore(),
        totalItems: 0
    });
};
