import { UserLocalStorageKeys } from './UserLocalStorageKeys';
import { cast, Instance, types } from 'mobx-state-tree';
import { getPersistedUserPrivileges } from './UserPrivileges';

export const UserStore = types
    .model({
        userId: types.string,
        privileges: types.array(types.string)
    })
    .actions((store) => ({
        setPrivileges(_userId: string, _privileges: string[]): void {
            store.userId = _userId;
            store.privileges = cast(_privileges);
            // the privileges are persisted for the following reasons
            // 1. they help to identify if the user is logged in or not.
            // 2. this ensures that we don't lose privileges once the page reloads ( since the state is refreshed )
            localStorage.setItem(UserLocalStorageKeys.privileges, _privileges.toString());
            localStorage.setItem(UserLocalStorageKeys.userId, _userId);
        }
    }));

export const createUserStore = (): Instance<typeof UserStore> => {
    return UserStore.create({
        userId: localStorage.getItem(UserLocalStorageKeys.userId) ?? '',
        privileges: getPersistedUserPrivileges()
    });
};
