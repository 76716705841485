import { Box, Divider, Stack, SxProps } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailsRowComponent } from './DetailsRowComponent';
import { StatusType } from './StatusComponent';
import { UserActionStatusHeader } from './UserActionStatusHeader';
import { WithdrawRequestModel } from '../models/WithdrawRequestModel';

interface WithdrawUserCommentComponentProps {
    withdrawalComment: Instance<typeof WithdrawRequestModel>;
    children: React.ReactNode;
    showDivider?: boolean;
    sx?: SxProps;
}

export const WithdrawUserCommentComponent = ({
    withdrawalComment,
    children,
    showDivider,
    sx
}: WithdrawUserCommentComponentProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    return (
        <Stack>
            <UserActionStatusHeader
                sx={{ ...sx }}
                title={t('common.requestWithdrawnBy', {
                    name: withdrawalComment.name
                })}
                subtitle={withdrawalComment.withdrawnAt}
                status={StatusType.WITHDRAWN}
                profilePhotoUrl={withdrawalComment.profilePhoto}
            />
            <Box
                sx={{
                    paddingLeft: '80px',
                    borderTop: showDivider ? `1px solid ${palette.outline[200]}` : '',
                    marginBottom: showDivider ? '32px' : ''
                }}>
                <DetailsRowComponent
                    label={t('common.withdrawalComment')}
                    text={withdrawalComment.withdrawComment}
                    sx={{ marginTop: showDivider ? '32px' : '' }}
                />
                <Divider
                    sx={{
                        border: `1px solid ${palette.outline[200]}`,
                        my: '16px',
                        marginRight: '32px'
                    }}
                />
                <DetailsRowComponent
                    label={t('common.requestRaisedTimeDate')}
                    text={withdrawalComment.requestedAt}
                    sx={{ paddingBottom: '16px' }}
                />
                <DetailsRowComponent
                    label={t('common.requestComment')}
                    text={withdrawalComment.requestComment}
                />
                {children}
            </Box>
        </Stack>
    );
};
