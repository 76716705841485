import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    ABRequestId,
    GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse,
    GetFundsTransferOutOfYafikaRequestsHistoryRPC,
    IntermediateRequestStatus,
    PGAmount,
    PGUserName
} from '@resolut-tech/bcn-rpcs';

const paginationResponseList: GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse[] = [
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7aC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Arlene McCoy'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Geodeon Adanlin'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Eric Gbozo'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Eric Gbozo'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7aC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Arlene McCoy'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Geodeon Adanlin'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Eric Gbozo'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName('Jane Cooper'),
        new PGUserName('Eric Gbozo'),
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    ),
    new GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse(
        new LeoTimestamp(),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName('Wade Wade'),
        null,
        new PGAmount(50000000, 'MWK'),
        new LeoUUID(),
        new ABRequestId('Ik76n7xC')
    )
];

export class MockGetFundsTransferOutOfYafikaRequestsHistoryRPCImpl extends GetFundsTransferOutOfYafikaRequestsHistoryRPC {
    execute(
        request: GetFundsTransferOutOfYafikaRequestsHistoryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetFundsTransferOutOfYafikaRequestsHistoryRPC.Response,
            GetFundsTransferOutOfYafikaRequestsHistoryRPC.Errors.Errors
        >
    > {
        let list: GetFundsTransferOutOfYafikaRequestsHistoryPaginationResponse[] =
            paginationResponseList;
        const requestIdSearchText = request.requestIdSearchText?.text.toLowerCase();
        const transactionIdSearchText = request.transactionIdSearchText?.text.toLowerCase() ?? '';
        const requestStatus = request.requestStatus;
        if (requestIdSearchText) {
            list = list.filter((transactionRequest) =>
                transactionRequest.requestId.id.toLowerCase().includes(requestIdSearchText)
            );
        }
        if (transactionIdSearchText) {
            list = list.filter((transactionRequest) =>
                transactionRequest.transactionId.uuid
                    .toLowerCase()
                    .includes(transactionIdSearchText)
            );
        }
        if (requestStatus) {
            list = list.filter((transactionRequest) => {
                return transactionRequest.requestStatus === requestStatus;
            });
        }
        const response = new GetFundsTransferOutOfYafikaRequestsHistoryRPC.Response(
            list,
            list.length
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetFundsTransferOutOfYafikaRequestsHistoryRPC.Response,
                GetFundsTransferOutOfYafikaRequestsHistoryRPC.Errors.Errors
            >
        >;
    }
}
