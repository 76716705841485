import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import {
    useCBSRequestDetailsStore,
    useDownloadUnclaimedFundsRequestStore,
    useWithdrawCBSTransferStore
} from '../store/hooks';
import { useUserStore } from '../../store/hooks';
import { TransactionSummary } from '../components/TransactionSummary';
import {
    Table,
    TableOptions,
    TableRowItems,
    Icon,
    TableHeader,
    usePalette,
    LoadingIndicator
} from '@surya-digital/leo-reactjs-ui';
import { getAmountString, getFormattedPhoneNumber } from '../../common/utils/UIUtils';
import { DownloadRequestsZipDialog } from '../components/DownloadRequestsZipDialog';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestStatus } from '../../common/enums/RequestStatus';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { UnclaimedProcessingRequestDetailCard } from '../components/UnclaimedProcessingCard';
import { UnclaimedFundsPendingRequestCard } from '../components/UnclaimedFundsPendingRequestCard';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { DownloadUnclaimedFundsRequestStoreErrors } from '../store/DownloadUnclaimedFundsRequestStore';
import { WithdrawCBSTransferStoreError } from '../store/WithdrawCBSTransferStore';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { CBSRequestDetailsStoreErrors } from '../store/CBSRequestDetailsStore';

export const CBSRequestDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get('requestId');
    const store = useCBSRequestDetailsStore();
    const downloadUnclaimedFundsRequestStore = useDownloadUnclaimedFundsRequestStore();
    const userStore = useUserStore();
    const palette = usePalette();
    const [isDownloadRequestsZipDialogOpen, setIsDownloadRequestsZipDialogOpen] =
        useState<boolean>(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>();
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const withdrawRequestStore = useWithdrawCBSTransferStore();
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();

    const fetchCountryList = async (): Promise<void> => {
        await store.fetchCountries();
    };

    const fetchUnclaimedFundsRequestDetails = async (): Promise<void> => {
        if (requestId) {
            setLoading(true);
            await store.getUnclaimedFundsRequestDetails(requestId);
            setLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            navigate('/unclaimed-funds/search', { replace: true });
        }
    };

    useEffect(() => {
        fetchCountryList();
        fetchUnclaimedFundsRequestDetails();
        // This is done to ensure that when user click browser back button the label shown to user is correct. As the successor screen can have two entry point.
        if (breadcrumbStore.links.at(-1)?.label === t('common.requestDetails')) {
            breadcrumbStore.removeLastElement();
        }
    }, []);

    const DownloadRequestsZipDialogBox = (): React.ReactElement => {
        return (
            <DownloadRequestsZipDialog
                isDialogOpen={isDownloadRequestsZipDialogOpen}
                onCancel={(): void => {
                    setIsDownloadRequestsZipDialogOpen(false);
                }}
            />
        );
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogTitle(null);
                    setErrorDialogMessage(null);
                    store.removeError();
                    downloadUnclaimedFundsRequestStore.resetError();
                }}
            />
        );
    };

    const showWithdrawSection = (): boolean => {
        return userStore.userId === store.requestDetails?.requesterComment?.userId;
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        const _requestId = store.requestDetails?.requestId;
        if (_requestId) {
            await withdrawRequestStore.withdrawRequest(_requestId, comment);
        } else {
            loggerStore.debug(
                'Cannot find requestId in requestCBSTransferStore while withdrawing unclaimed funds request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        if (withdrawRequestStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (withdrawRequestStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case WithdrawCBSTransferStoreError.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been withdrawn, we will fetch the Unclaimed Funds Request Details again.
            fetchUnclaimedFundsRequestDetails();
        }
    };

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'timeDate',
                sortable: true,
                name: t('common.timeDate'),
                width: '160px'
            },
            {
                id: 'sender',
                name: t('common.sender'),
                width: '160px'
            },
            {
                id: 'recipient',
                name: t('common.recipient'),
                width: '160px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '160px'
            },
            {
                id: 'country',
                name: t('common.country'),
                width: '120px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<undefined>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (requestId) {
            await store.getUnclaimedFundsRequestDetailsList(
                requestId,
                option.page ? option.page - 1 : 0,
                store.itemsPerPage(),
                option.sort?.order
            );
        } else {
            loggerStore.debug('Cannot find request ID in CBSRequestDetails');
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        if (store.error) {
            switch (store.error) {
                case CBSRequestDetailsStoreErrors.InvalidRequestId:
                case CBSRequestDetailsStoreErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.unclaimedFundsList.map((unclaimedFund) => {
            return [
                { data: unclaimedFund.createdAt },
                { data: unclaimedFund.userName },
                { data: getFormattedPhoneNumber(unclaimedFund.userPhoneNumber) },
                { data: getAmountString(unclaimedFund.amount) },
                { data: unclaimedFund.country },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const handleDownloadButtonClick = async (id: string): Promise<void> => {
        setScreenBlockingLoading(true);
        await downloadUnclaimedFundsRequestStore.downloadUnclaimedFundsRequest(id);
        setScreenBlockingLoading(false);
        if (downloadUnclaimedFundsRequestStore.error) {
            setErrorDialogTitle(t('common.downloadFile'));
            switch (downloadUnclaimedFundsRequestStore.error) {
                case DownloadUnclaimedFundsRequestStoreErrors.RequestToDownloadIsInProgress:
                    setErrorDialogMessage(t('unclaimedFunds.downloadRequestsZipInProgress'));
                    break;
                case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestId:
                case DownloadUnclaimedFundsRequestStoreErrors.InvalidRequestType:
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            setIsDownloadRequestsZipDialogOpen(true);
        }
    };

    const getPendingCard = (): React.ReactElement => {
        const showPendingCard: Boolean = Boolean(
            store.requestDetails?.status === RequestStatus.PENDING
        );
        if (
            store.requestDetails &&
            showPendingCard &&
            store.requestDetails.requesterComment &&
            requestId
        ) {
            return (
                <UnclaimedFundsPendingRequestCard
                    comment={store.requestDetails.requesterComment}
                    onWithdrawClick={onWithdrawClick}
                    showWithdrawSection={showWithdrawSection()}>
                    {store.totalAmount && store.totalNoOfTransaction && (
                        <TransactionSummary
                            onDownloadClick={(): void => {
                                handleDownloadButtonClick(requestId);
                            }}
                            totalAmount={store.totalAmount}
                            status={store.requestDetails.status}
                            totalNoOfTransaction={store.totalNoOfTransaction}
                        />
                    )}
                </UnclaimedFundsPendingRequestCard>
            );
        } else {
            return <></>;
        }
    };

    const getProcessingCard = (): React.ReactElement => {
        const showProcessingCard: Boolean = Boolean(
            store.requestDetails?.status === RequestStatus.PROCESSING
        );
        if (
            store.requestDetails &&
            showProcessingCard &&
            store.requestDetails.requesterComment &&
            requestId
        ) {
            return (
                <UnclaimedProcessingRequestDetailCard
                    makerComment={store.requestDetails.requesterComment}
                    // Checker comment is always present when the request is in `Processing` state.
                    checkerComment={store.requestDetails.evaluatorComment!}
                    viewDownloadButton={true}
                    onSecondaryButtonClick={(): void => {
                        handleDownloadButtonClick(requestId);
                    }}
                />
            );
        } else {
            return <></>;
        }
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    withdrawRequestStore.resetComment();
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('common.requestDetails')}
                subtitle={t('unclaimedFunds.requestDetailsSubtitle')}
            />
            {store.error ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : loading ? (
                <LoadingState />
            ) : (
                <Stack>
                    {screenBlockingLoading && (
                        <LoadingIndicator isLoading={screenBlockingLoading} />
                    )}
                    <BreadcrumbComponent currentLabel={t('common.requestDetails')} />
                    <Stack
                        spacing="32px"
                        sx={{
                            padding: '32px',
                            boxSizing: 'border-box',
                            width: '100%'
                        }}>
                        {getPendingCard()}
                        {getProcessingCard()}
                        <Table
                            // To remove the border and borderRadius added on the table view when used inside a section component.
                            styleOverrides={{
                                borderRadius: '0px',
                                border: 'none',
                                width: 'calc(100vw - 304px)'
                            }}
                            name="unclaimedFundsRequestDetailsList"
                            headers={getHeaders()}
                            onTableOptionsChange={getData}
                            paginationOption={{
                                itemsPerPage: store.itemsPerPage(),
                                getPageIndicatorText(startItem, endItem, totalItems): string {
                                    return t('common.paginationIndicationText', {
                                        startItem,
                                        endItem,
                                        totalItems
                                    });
                                }
                            }}
                            viewOverrides={{
                                empty: { message: t('common.noResultsFound') }
                            }}
                            onRowClick={(_row, index): void => {
                                const selectedTransaction = store.unclaimedFundsList[index];
                                if (selectedTransaction) {
                                    breadcrumbStore.setLink(
                                        t('common.requestDetails'),
                                        `${window.location.pathname}?requestId=${requestId}`
                                    );
                                    navigate(
                                        `/unclaimed-funds/search/transaction-details?unclaimedFundId=${selectedTransaction.unclaimedFundId}`
                                    );
                                } else {
                                    loggerStore.debug(
                                        `Array index: ${index} for selected Transaction in the list with length ${store.unclaimedFundsList.length} is out of bounds`
                                    );
                                    setErrorDialogMessage(
                                        t('common.somethingWentWrongProcessingRequest')
                                    );
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            )}
            {isDownloadRequestsZipDialogOpen && DownloadRequestsZipDialogBox()}
            {errorDialogMessage && ErrorDialogBox()}
            {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
        </Stack>
    );
});
