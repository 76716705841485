import { Grid } from '@mui/material';
import { DateRangePickerField, DateRangePickerInput } from '@surya-digital/leo-reactjs-ui';
import enZALocale from 'date-fns/locale/en-ZA';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import {
    DateRangeFilterOptions,
    getDateRangeInitialFilter
} from '../../common/utils/TableFilterUtils';
import { Button } from '../../../common/components/Button';

interface AuditLogsFilterProps {
    filter: Instance<typeof DateRangeFilterOptions>;
    setFilter: (newFilter: Instance<typeof DateRangeFilterOptions>) => void;
}

export const AuditLogsFilter = ({
    filter,
    setFilter
}: AuditLogsFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getDateRangeInitialFilter();
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const [dateRange, setDateRange] = useState(filter);
    const [isValid, setIsValid] = useState(false);
    const [disableSearch, setDisableSearch] = useState(true);

    useEffect(() => {
        setDateRange(filter);
    }, [filter]);

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <DateRangePickerField
                        value={dateRange}
                        locale={enZALocale}
                        startDateLabel={t('common.selectStartDate')}
                        endDateLabel={t('common.selectEndDate')}
                        onChange={(newValue: DateRangePickerInput): void => {
                            setDisableSearch(false);
                            setDateRange(newValue);
                            if (newValue.startDate && newValue.endDate) {
                                if (newValue.startDate <= newValue.endDate) {
                                    setIsValid(true);
                                } else {
                                    setIsValid(false);
                                }
                            }
                        }}
                        maxDate={new Date()}
                        showDivider
                        onError={(startDateError, endDateError): void => {
                            if (startDateError || endDateError) {
                                setIsValid(false);
                            }
                        }}
                        style={{ width: '100%' }}
                    />
                </Grid>

                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="8px">
                    <Button
                        name={t('common.reset')}
                        title={t('common.reset')}
                        size="medium"
                        isDisabled={!(dateRange.startDate ?? dateRange.endDate) || isInitialFilter}
                        variant="outlined-color"
                        color="error"
                        onClick={(): void => {
                            setDisableSearch(true);
                            setFilter(getDateRangeInitialFilter());
                        }}
                        style={{ outline: 'none' }}
                    />
                    <Button
                        name={t('common.apply')}
                        title={t('common.apply')}
                        isDisabled={
                            !isValid || !dateRange.endDate || !dateRange.startDate || disableSearch
                        }
                        size="medium"
                        variant="filled"
                        onClick={(): void => {
                            setDisableSearch(true);
                            setFilter(dateRange);
                        }}
                    />
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
