import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { PageHeader } from '../../common/components/PageHeader';
import { LoadingState } from '../../common/components/LoadingState';
import { TransactionDetailsSection } from '../../transaction/components/TransactionDetailsSection';
import { LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import {
    useCheckGenericFundsTransferRequestStore,
    useGenericFundsTransferDetailsStore,
    useWithdrawGenericFundsTransferRequestStore
} from '../store/hooks';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { RequestDetailsDialog } from '../components/RequestDetailsDialog';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { CheckRequestErrors } from '../store/CheckRequestStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { UserPrivileges } from '../../user/UserPrivileges';
import { useUserStore } from '../../store/hooks';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { Status } from '../../common/enums/StatusEnum';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { ErrorState } from '../../common/components/ErrorState';
import { ProcessingCard } from '../../common/components/ProcessingCard';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { WithdrawRequestStoreError } from '../store/WithdrawRequestStore';

export const TransferDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get('transferDetails');
    const navigate = useNavigate();
    const store = useGenericFundsTransferDetailsStore();
    const checkGenericFundsTransferRequestStore = useCheckGenericFundsTransferRequestStore();
    const withdrawGenericFundsTransferRequestStore = useWithdrawGenericFundsTransferRequestStore();
    const userStore = useUserStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isRequestDetailsDialogOpen, setIsRequestDetailsDialogOpen] = useState(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const [checkerResponseStatus, setCheckerResponseStatus] = useState<Status>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);

    const onCheckGenericFundsTransferError = (): void => {
        switch (checkGenericFundsTransferRequestStore.error) {
            case CheckRequestErrors.InvalidRequestId:
            case CheckRequestErrors.UnableToPerformExchange:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.SenderAccountInactive:
                setErrorMessage(t('common.senderAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RecipientAccountInactive:
                setErrorMessage(t('common.receiverAccountInactive'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.SenderProfileDisabled:
                setErrorMessage(t('common.senderProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RecipientProfileDisabled:
                setErrorMessage(t('common.receiverProfileDisabled'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.InsufficientBalance:
                setErrorMessage(t('common.insufficientBalance'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.ReceivingAccountWouldCrossLimit:
                setErrorMessage(t('common.receivingAccountWillCrossLimit'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RequestAlreadyChecked:
                setErrorMessage(t('common.requestAlreadyChecked'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RequestUnderProcess:
                setErrorMessage(t('common.requestUnderProcess'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RequestAlreadyWithdrawn:
                setErrorMessage(t('common.requestAlreadyWithdrawn'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.InvalidExchangeRateId:
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RequestAlreadyDiscarded:
                setErrorMessage(t('common.requestAlreadyDiscarded'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.CannotCheckSelfRequest:
                setErrorMessage(t('common.cannotCheckSelfRequest'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.RecipientProfileArchived:
                setErrorMessage(t('common.receiverProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case CheckRequestErrors.SenderProfileArchived:
                setErrorMessage(t('common.senderProfileArchived'));
                setIsErrorDialogOpen(true);
                break;
            case NetworkingError.InternalError:
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
                break;
            default:
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
        }
    };

    const fetchTransferDetails = async (): Promise<void> => {
        if (requestId) {
            setIsLoading(true);
            await store.fetchTransferDetails(requestId);
            setIsLoading(false);
            if (store.error === NetworkingError.InternalError) {
                setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                setIsErrorDialogOpen(true);
            }
        } else {
            navigate('/generic-funds-transfer/transfer-requests', { replace: true });
        }
    };

    const onSubmitCheckerResponse = async (): Promise<void> => {
        await checkGenericFundsTransferRequestStore.checkGenericFundTransferRequest(
            requestId!,
            checkerResponseStatus === Status.APPROVED
                ? CheckerResponseEnums.Status.Status.APPROVED
                : CheckerResponseEnums.Status.Status.DENIED
        );
        setIsDataLoading(false);
        if (!checkGenericFundsTransferRequestStore.error) {
            checkGenericFundsTransferRequestStore.resetStore();
            navigate('/generic-funds-transfer/transfer-requests', { replace: true });
        } else {
            setErrorDialogTitle(
                checkerResponseStatus === Status.APPROVED
                    ? t('common.approveRequest')
                    : t('common.denyRequest')
            );
            onCheckGenericFundsTransferError();
        }
    };

    const onWithdrawRequest = async (): Promise<void> => {
        await withdrawGenericFundsTransferRequestStore.withdrawRequest(requestId!);
        setIsDataLoading(false);
        if (withdrawGenericFundsTransferRequestStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (withdrawGenericFundsTransferRequestStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorMessage(t('common.requestAlreadyChecked'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorMessage(t('common.canOnlyWithdrawSelfRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorMessage(t('common.requestAlreadyWithdrawn'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorMessage(t('common.requestAlreadyDiscarded'));
                    setIsErrorDialogOpen(true);
                    break;
                case WithdrawRequestStoreError.InvalidComment:
                    setErrorMessage(t('common.invalidComment'));
                    setIsErrorDialogOpen(true);
                    break;
                case NetworkingError.InternalError:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
            }
        } else {
            withdrawGenericFundsTransferRequestStore.resetStore();
            // Since the request has been withdrawn, we will fetch the transfer details again.
            fetchTransferDetails();
        }
    };

    const onCheckerAction = async (status: Status): Promise<void> => {
        setIsDataLoading(true);
        setCheckerResponseStatus(status);
        // Since the request details is displayed only when requestId is present, requestId is never null here.
        await checkGenericFundsTransferRequestStore.getGenericFundsTransferSummary(requestId!);
        setIsDataLoading(false);
        if (!checkGenericFundsTransferRequestStore.error) {
            setIsRequestDetailsDialogOpen(true);
        } else {
            setErrorDialogTitle(
                status === Status.APPROVED ? t('common.approveRequest') : t('common.denyRequest')
            );
            onCheckGenericFundsTransferError();
        }
    };

    const showApproveAndDenyButton = userStore.privileges.includes(
        UserPrivileges.CheckFundsTransferRequest
    );

    const showWithdrawButton = Boolean(
        userStore.privileges.includes(UserPrivileges.RequestFundsTransfer) &&
            userStore.userId === store.requestDetails?.requesterComment?.userId
    );

    useEffect(() => {
        fetchTransferDetails();
    }, []);

    useEffect(() => {
        if (!isErrorDialogOpen) {
            // This is done to ensure that once the error dialog is removed, the error is removed from the store as well.
            store.removeError();
            checkGenericFundsTransferRequestStore.removeError();
            withdrawGenericFundsTransferRequestStore.removeError();
        }
    }, [isErrorDialogOpen]);

    useEffect(() => {
        if (isDataLoading && checkGenericFundsTransferRequestStore.comment) {
            onSubmitCheckerResponse();
        } else if (isDataLoading && withdrawGenericFundsTransferRequestStore.comment) {
            onWithdrawRequest();
        }
    }, [isDataLoading]);

    return (
        <Stack direction="column" sx={{ flex: 1 }}>
            <LoadingIndicator isLoading={isDataLoading} />
            <PageHeader
                title={t('genericFundsTransfer.transferDetailsTitle')}
                subtitle={t('genericFundsTransfer.transferDetailsSubtitle')}
            />
            {store.error ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : isLoading ? (
                <LoadingState />
            ) : (
                <Stack>
                    <BreadcrumbComponent
                        currentLabel={t('genericFundsTransfer.transferDetailsTitle')}
                    />
                    <ErrorDialog
                        title={errorDialogTitle}
                        secondaryButtonText={t('common.cancel')}
                        errorMessage={errorMessage ?? undefined}
                        isErrorDialogOpen={isErrorDialogOpen}
                        onClose={(): void => {
                            checkGenericFundsTransferRequestStore.resetStore();
                            withdrawGenericFundsTransferRequestStore.resetStore();
                            setIsErrorDialogOpen(false);
                        }}
                    />
                    <WithdrawDialog
                        isDialogOpen={isWithdrawRequestDialogOpen}
                        onClose={(): void => {
                            setIsWithdrawRequestDialogOpen(false);
                        }}
                        onSubmit={(comment: string): void => {
                            setIsDataLoading(true);
                            withdrawGenericFundsTransferRequestStore.setComment(comment);
                            setIsWithdrawRequestDialogOpen(false);
                        }}
                    />
                    {checkGenericFundsTransferRequestStore.amount &&
                        checkGenericFundsTransferRequestStore.amountDebited &&
                        checkGenericFundsTransferRequestStore.amountCredited && (
                            <RequestDetailsDialog
                                title={
                                    checkerResponseStatus === Status.APPROVED
                                        ? t('common.approveFundsTransfer')
                                        : t('common.denyFundsTransfer')
                                }
                                primaryButtonText={
                                    checkerResponseStatus === Status.APPROVED
                                        ? t('common.approveRequest')
                                        : t('common.denyRequest')
                                }
                                amount={checkGenericFundsTransferRequestStore.amount}
                                fee={checkGenericFundsTransferRequestStore.fee}
                                amountDebited={checkGenericFundsTransferRequestStore.amountDebited}
                                amountCredited={
                                    checkGenericFundsTransferRequestStore.amountCredited
                                }
                                isDialogOpen={isRequestDetailsDialogOpen}
                                onClose={(): void => {
                                    setIsRequestDetailsDialogOpen(false);
                                }}
                                onSubmit={(comment: string): void => {
                                    setIsDataLoading(true);
                                    checkGenericFundsTransferRequestStore.setComment(comment);
                                    setIsRequestDetailsDialogOpen(false);
                                }}
                                showWarningChip={false}
                            />
                        )}
                    {store.requestDetails &&
                        !store.isProcessing &&
                        store.requestDetails?.status ===
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING &&
                        store.requestDetails.requesterComment && (
                            <Box sx={{ margin: '32px' }}>
                                <PendingRequestCard
                                    onApproveClick={(): void => {
                                        onCheckerAction(Status.APPROVED);
                                    }}
                                    onDenyClick={(): void => {
                                        onCheckerAction(Status.DENIED);
                                    }}
                                    onWithdrawClick={(): void => {
                                        setIsWithdrawRequestDialogOpen(true);
                                    }}
                                    showApproveAndDenySection={showApproveAndDenyButton}
                                    showWithdrawSection={showWithdrawButton}
                                    comment={store.requestDetails.requesterComment}
                                />
                            </Box>
                        )}
                    {store.isProcessing &&
                        store.requestDetails?.requesterComment &&
                        store.requestDetails.evaluatorComment && (
                            <Stack sx={{ margin: '32px' }}>
                                <ProcessingCard
                                    message={t('genericFundsTransfer.processingStateTitle')}
                                    makerComment={store.requestDetails.requesterComment}
                                    checkerComment={store.requestDetails.evaluatorComment}
                                />
                            </Stack>
                        )}
                    {store.requestDetails &&
                        !store.isProcessing &&
                        store.requestDetails?.status !==
                            IntermediateRequestStatus.IntermediateRequestStatus.PENDING && (
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                    margin: '32px'
                                }}>
                                <RequestDetailsSection requestDetails={store.requestDetails} />
                            </Box>
                        )}
                    <Stack
                        sx={{
                            padding: '0px 32px 32px 32px',
                            boxSizing: 'border-box',
                            width: '100%'
                        }}>
                        <TransactionDetailsSection transactionDetails={store.transactionDetails} />
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
});
