import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled, Typography } from '@mui/material';
import { Instance } from 'mobx-state-tree';
import { AmountDetailsRow } from '../../common/components/AmountDetailsRow';
import { TransferAmountDetails } from '../../common/components/TransferAmountDetails';
import { WarningContainer } from '../../common/components/WarningContainer';
import { AmountModel } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { Dialog } from '../../../common/components/Dialog';

interface RequestDetailsDialogProps {
    title: string;
    primaryButtonText: string;
    amount: Instance<typeof AmountModel>;
    fee: Instance<typeof AmountModel> | null;
    amountDebited: Instance<typeof AmountModel>;
    amountCredited: Instance<typeof AmountModel>;
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmit: (comment: string) => void;
    showWarningChip: boolean;
}
export const RequestDetailsDialog = ({
    title,
    primaryButtonText,
    amount,
    fee,
    amountDebited,
    amountCredited,
    isDialogOpen,
    onClose,
    onSubmit,
    showWarningChip
}: RequestDetailsDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const [comment, setComment] = useState<string | null>(null);

    const StyledTypography = styled(Typography)(() => ({
        ...typography.small1,
        color: palette.label[300],
        paddingBottom: '12px'
    }));

    return (
        <Dialog
            open={isDialogOpen}
            onClose={(): void => {
                setComment(null);
                onClose();
            }}
            isCloseIconPresent
            title={title}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={t('common.cancel')}
            isPrimaryButtonDisabled={!comment || comment?.trim().length === 0}
            onPrimaryButtonClick={async (): Promise<void> => {
                // Since the primary button is enabled only when comment is present, comment can never be null here.
                onSubmit(comment!);
                setComment(null);
            }}
            onSecondaryButtonClick={(): void => {
                setComment(null);
                onClose();
            }}
            disableBackdropClick
            dialogWidth="560px">
            <Stack>
                <StyledTypography>{t('genericFundsTransfer.sendingAccount')}</StyledTypography>
                <AmountDetailsRow label={t('common.amount')} text={getAmountString(amount)} />
                {fee && (
                    <AmountDetailsRow
                        label={t('common.fee')}
                        text={getAmountString(fee)}
                        sx={{ paddingTop: '6px' }}
                    />
                )}
                <TransferAmountDetails
                    label={t('genericFundsTransfer.amountDebited')}
                    text={getAmountString(amountDebited)}
                    sx={{ paddingTop: '12px' }}
                />
                <StyledTypography sx={{ paddingTop: '20px' }}>
                    {t('genericFundsTransfer.receivingAccount')}
                </StyledTypography>
                <TransferAmountDetails
                    label={t('genericFundsTransfer.amountCredited')}
                    text={getAmountString(amountCredited)}
                />
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={comment ?? ''}
                    onTextChange={setComment}
                    label={t('common.addComment')}
                    style={{ marginTop: '20px' }}
                />
                {showWarningChip && (
                    <WarningContainer
                        text={t('common.exchangeRateMessage')}
                        sx={{ marginTop: '20px' }}
                    />
                )}
            </Stack>
        </Dialog>
    );
};
