import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import successIcon from '../../../../../assets/check-circle.svg';
import { Dialog } from '../../../../common/components/Dialog';

interface SuccessDialogProps {
    title: string;
    secondaryButtonLabel?: string;
    successMessage: string;
    isDialogOpen: boolean;
    isCloseIconPresent?: boolean;
    onCancel: () => void;
}
export const SuccessDialog = ({
    title,
    successMessage,
    isDialogOpen,
    onCancel,
    secondaryButtonLabel,
    isCloseIconPresent = true
}: SuccessDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Dialog
            open={isDialogOpen}
            onClose={isCloseIconPresent ? onCancel : undefined}
            isCloseIconPresent={isCloseIconPresent}
            title={title}
            secondaryButtonText={secondaryButtonLabel ?? t('common.close')}
            onSecondaryButtonClick={onCancel}
            disableBackdropClick>
            <Stack
                style={{
                    height: '104px',
                    alignItems: 'center'
                }}>
                <img
                    src={successIcon}
                    style={{ height: '32px', width: '32px', marginTop: '16px' }}
                />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.success[300],
                        marginTop: '16px'
                    }}>
                    {successMessage}
                </Typography>
            </Stack>
        </Dialog>
    );
};
