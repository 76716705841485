import { Stack } from '@mui/material';
import {
    DropdownItem,
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { PageHeader } from '../../common/components/PageHeader';
import { StatusComponent } from '../../common/components/StatusComponent';
import {
    getFormattedPhoneNumber,
    getTableStyleOverrides,
    getUserStatus
} from '../../common/utils/UIUtils';
import { AgentSearchFilter } from '../components/AgentSearchFilter';
import { AgentFilterOptions, AgentSearchStoreErrors } from '../store/AgentSearchStore';
import { useAgentSearchStore } from '../store/hooks';
import { getAgentInitialFilter } from '../utils/UIUtils';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const Agents = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useAgentSearchStore();
    const initialFilter = getAgentInitialFilter(t);
    const [countries, setCountries] = useState<CountryViewModel[] | null>(null);
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    const fetchCountryList = async (): Promise<void> => {
        await store.fetchCountries();
        setCountries(store.countryList());
    };

    useEffect(() => {
        fetchCountryList();
        breadcrumbStore.setInitialLink(t('agencyBanking.agents'), window.location.pathname);
    }, []);

    const countryDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    name: countryIterator.name,
                    value: countryIterator.countryCode
                };
            });
        }
        return [];
    };

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'name',
                name: t('common.name'),
                width: '160px'
            },
            {
                id: 'mobileNumber',
                name: t('common.mobileNumber'),
                width: '160px'
            },
            {
                id: 'country',
                name: t('common.country'),
                width: '240px'
            },
            {
                id: 'userStatus',
                name: t('agencyBanking.agentStatus'),
                width: '137px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<typeof AgentFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        store.updateFilterOptions(option.filter ?? initialFilter);
        await store.getAgents(
            option.filter,
            option.page ? option.page - 1 : 0, // This is done since the first page is sent as page 0 to server
            store.itemsPerPage()
        );
        if (store.error) {
            switch (store.error) {
                case AgentSearchStoreErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.agents.map((agent) => {
            return [
                { data: agent.name },
                { data: getFormattedPhoneNumber(agent.phoneNumber) },
                { data: agent.country },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getUserStatus(agent.status)}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack>
            <PageHeader
                title={t('agencyBanking.agents')}
                subtitle={t('agencyBanking.searchAndViewAgents')}
            />
            <Table
                name="agentsList"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    idle: !store.filterOptions
                        ? {
                              message: t('agencyBanking.searchUSingNameOrMobileNumber')
                          }
                        : undefined,
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('common.searchTableLoadingState') }
                }}
                filterOption={{
                    initialFilterValue:
                        (store.filterOptions as Instance<typeof AgentFilterOptions>) ??
                        getAgentInitialFilter(t),
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <AgentSearchFilter
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={store.minimumSearchTextLength()}
                                countryDropdownItems={countryDropdownItems()}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    const selectedAgent = store.agents[index];
                    if (selectedAgent) {
                        navigate(`/agency-banking/agents/details?agentId=${selectedAgent.id}`);
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected agent in the list with length ${store.agents.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </Stack>
    );
});
