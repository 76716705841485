import {
    LeoDate,
    LeoEmailId,
    LeoPhoneNumber,
    LeoRPCResult,
    LeoTimestamp,
    LeoUUID
} from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    AccountStatus,
    Amount,
    BCNAccountDetails,
    BCNUserBasicDetails,
    BitmapImageType,
    Currency,
    BOGender,
    GetBCNUserDetailsRPC,
    KYCDocumentHistoryDetails,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    UserName,
    UserStatus,
    BOUserComment,
    Comment,
    BOName
} from '@resolut-tech/bcn-rpcs';
import {
    BCNUserUpdateRequestDetails,
    BCNUserUpdateRequestDetailsEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/bCNUserUpdateRequestDetails';

const profilePicture = new MultiResolutionBitmapImage(
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    ),
    new RemoteBitmapImage(
        new URL(
            'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
        ),
        BitmapImageType.BitmapImageType.PNG,
        20,
        20
    )
);

const basicDetails: BCNUserBasicDetails = new BCNUserBasicDetails(
    profilePicture,
    new UserName(new BOName('Esther'), new BOName('Howard')),
    new BOName('Esther'),
    UserStatus.UserStatus.ACTIVE,
    new LeoPhoneNumber('+265995118354'),
    new LeoEmailId('esther.howard@email.com'),
    new LeoDate('2020-10-20'),
    BOGender.BOGender.MALE,
    'GILSPB08',
    new LeoDate('2020-10-20')
);

const accountDetails: BCNAccountDetails[] = [
    new BCNAccountDetails(
        'Malawi Personal',
        new LeoUUID(),
        new Amount(50000000, new Currency('MWK')),
        AccountStatus.AccountStatus.ACTIVE,
        true
    )
];

const kycDocumentHistoryDetails: KYCDocumentHistoryDetails[] = [
    new KYCDocumentHistoryDetails(new LeoUUID(), new LeoTimestamp('2022-09-30T02:03:59Z'))
];

const bcnUserUpdateRequestDetails: BCNUserUpdateRequestDetails = new BCNUserUpdateRequestDetails(
    new LeoUUID(),
    UserStatus.UserStatus.ACTIVE,
    UserStatus.UserStatus.DEACTIVATED,
    new BOUserComment(
        new LeoUUID(),
        new UserName(new BOName('Wade'), new BOName('Warren')),
        profilePicture,
        new LeoTimestamp(),
        new Comment('The user had requested for the changes')
    ),
    null,
    BCNUserUpdateRequestDetailsEnums.Status.Status.PENDING
);

export class MockGetBCNUserDetailsImpl extends GetBCNUserDetailsRPC {
    execute(
        _request: GetBCNUserDetailsRPC.Request
    ): Promise<LeoRPCResult<GetBCNUserDetailsRPC.Response, GetBCNUserDetailsRPC.Errors.Errors>> {
        const response = new GetBCNUserDetailsRPC.Response(
            basicDetails,
            accountDetails,
            kycDocumentHistoryDetails,
            bcnUserUpdateRequestDetails
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetBCNUserDetailsRPC.Response, GetBCNUserDetailsRPC.Errors.Errors>
        >;
    }
}
