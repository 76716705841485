import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { AgencyBankingWithdrawRequestStore } from './AgencyBankingWithdrawRequestStore';
import { AgentBasicDetailsStore } from './AgentBasicDetailsStore';
import { AgentRequestHistorySectionStore } from './AgentRequestHistorySectionStore';
import { AgentRequestsHistoryStore } from './AgentRequestsHistoryStore';
import { AgentSearchStore } from './AgentSearchStore';
import { AgentTransactionDetailStore } from './AgentTransactionDetailStore';
import { AgentTransactionHistoryStore } from './AgentTransactionHistoryStore';
import { AgentTransactionRequestHistoryStore } from './AgentTransactionRequestHistoryStore';
import { AgentTransactionSearchStore } from './AgentTransactionSearchStore';
import { CheckAgentStatusUpdateRequestStore } from './CheckAgentStatusUpdateRequestStore';
import { CheckMoveFundsOutOfYafikaRequestStore } from './CheckMoveFundsOutOfYafikaRequestStore';
import { RequestMoveFundsOutOfYafikaStore } from './RequestMoveFundsOutOfYafikaStore';
import { UpdateAgentStateStore } from './UpdateAgentStateStore';

export const useAgentTransactionSearchStore = (): Instance<typeof AgentTransactionSearchStore> => {
    return useRootStore().homeStore.agentBanking.agentTransactionSearchStore;
};

export const useAgentTransactionDetailStore = (): Instance<typeof AgentTransactionDetailStore> => {
    return useRootStore().homeStore.agentBanking.agentTransactionDetailStore;
};

export const useAgentTransactionHistoryStore = (): Instance<
    typeof AgentTransactionHistoryStore
> => {
    return useRootStore().homeStore.agentBanking.agentTransactionHistoryStore;
};

export const useAgencyBankingWithdrawRequestStore = (): Instance<
    typeof AgencyBankingWithdrawRequestStore
> => {
    return useRootStore().homeStore.agentBanking.agencyBankingWithdrawRequestStore;
};

export const useRequestMoveFundsOutOfYafikaStore = (): Instance<
    typeof RequestMoveFundsOutOfYafikaStore
> => {
    return useRootStore().homeStore.agentBanking.requestMoveFundsOutOfYafikaStore;
};

export const useAgentTransactionRequestHistoryStore = (): Instance<
    typeof AgentTransactionRequestHistoryStore
> => {
    return useRootStore().homeStore.agentBanking.agentTransactionRequestHistoryStore;
};

export const useCheckMoveFundsOutOfYafikaRequestStore = (): Instance<
    typeof CheckMoveFundsOutOfYafikaRequestStore
> => {
    return useRootStore().homeStore.agentBanking.checkMoveFundsOutOfYafikaRequestStore;
};

export const useAgentSearchStore = (): Instance<typeof AgentSearchStore> => {
    return useRootStore().homeStore.agentBanking.agentSearchStore;
};

export const useAgentBasicDetailsStore = (): Instance<typeof AgentBasicDetailsStore> => {
    return useRootStore().homeStore.agentBanking.agentBasicDetailsStore;
};

export const useCheckAgentStatusUpdateRequestStore = (): Instance<
    typeof CheckAgentStatusUpdateRequestStore
> => {
    return useRootStore().homeStore.agentBanking.checkAgentStatusUpdateRequestStore;
};

export const useAgentRequestsHistoryStore = (): Instance<typeof AgentRequestsHistoryStore> => {
    return useRootStore().homeStore.agentBanking.agentRequestsHistoryStore;
};

export const useAgentRequestHistorySectionStore = (): Instance<
    typeof AgentRequestHistorySectionStore
> => {
    return useRootStore().homeStore.agentBanking.agentRequestHistorySectionStore;
};

export const useUpdateAgentStateStore = (): Instance<typeof UpdateAgentStateStore> => {
    return useRootStore().homeStore.agentBanking.updateAgentStateStore;
};
