import { Box, styled } from '@mui/material';
import React from 'react';
import { PendingRequestBody } from './PendingRequestBody';
import { StatusType } from '../StatusComponent';
import { UserActionStatusHeader } from '../UserActionStatusHeader';
import { WithdrawActionSection } from '../WithdrawActionSection';
import { ApproveDenyActionSection } from '../ApproveDenyActionSection';
import { useTranslation } from 'react-i18next';
import { RulesDownloadFileNameEnum } from '../../types/RulesDownloadFileNameEnum';

const StyledBox = styled(Box)(() => ({
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px'
}));

interface FileDetails {
    createdAt: string;
    documentId: string;
}

interface BORuleUserComment {
    name: string;
    profilePhoto: string | null;
    requestedAt: string;
    comment: string;
}

interface PendingRuleRequestDetailsSectionComponentProps {
    isLoading: boolean;
    onWithdrawClick?: () => void;
    boUserComment: BORuleUserComment;
    newRuleFile: FileDetails;
    currentRuleFile: FileDetails | null;
    newRuleFileName: RulesDownloadFileNameEnum;
    currentRuleFileName: RulesDownloadFileNameEnum;
    onDownloadClick: (documentId: string, fileName: RulesDownloadFileNameEnum) => void;
    showWithdrawActionButton?: boolean;
    showApproveDenyActionButton?: boolean;
    onDenyClick?: () => void;
    onApproveClick?: () => void;
}

export const PendingRuleRequestDetailsSectionComponent = ({
    isLoading,
    onWithdrawClick,
    boUserComment,
    newRuleFile,
    currentRuleFile,
    newRuleFileName,
    currentRuleFileName,
    onDownloadClick,
    showWithdrawActionButton,
    showApproveDenyActionButton,
    onApproveClick,
    onDenyClick
}: PendingRuleRequestDetailsSectionComponentProps): React.ReactElement => {
    const { t } = useTranslation();

    const renderActionSection = (): React.ReactElement => {
        if (showApproveDenyActionButton && onApproveClick && onDenyClick) {
            return (
                <ApproveDenyActionSection
                    onApproveClick={onApproveClick}
                    onDenyClick={onDenyClick}
                />
            );
        }
        if (showWithdrawActionButton && onWithdrawClick) {
            return <WithdrawActionSection onWithdrawClick={onWithdrawClick} />;
        }
        return <></>;
    };

    return (
        <>
            {boUserComment && (
                <StyledBox>
                    <UserActionStatusHeader
                        title={t('common.requestRaisedBy', {
                            name: boUserComment.name
                        })}
                        subtitle={boUserComment.requestedAt}
                        profilePhotoUrl={boUserComment.profilePhoto}
                        status={StatusType.PENDING}
                        sx={{
                            borderRadius: '8px 8px 0px 0px'
                        }}
                    />
                    <PendingRequestBody
                        isLoading={isLoading}
                        comment={boUserComment.comment}
                        newRuleFile={newRuleFile}
                        currentRuleFile={currentRuleFile}
                        newRuleFileName={newRuleFileName}
                        currentRuleFileName={currentRuleFileName}
                        onDownloadClick={onDownloadClick}
                    />
                    {renderActionSection()}
                </StyledBox>
            )}
        </>
    );
};
