export enum RulesDownloadFileNameEnum {
    FeeRulesTemplateFile = 'Fee_Determination_Rules_Template_File.csv',
    CurrentFeeRulesFile = 'Current_Fee_Determination_Rules.csv',
    OldFeeRulesFile = 'Old_Fee_Determination_Rules.csv',
    NewFeeRulesFile = 'Updated_Fee_Determination_Rules.csv',
    TransactionRulesTemplateFile = 'Transaction_Validation_Rules_Template_File.csv',
    CurrentTransactionRulesFile = 'Current_Transaction_Validation_Rules.csv',
    OldTransactionRulesFile = 'Old_Transaction_Validation_Rules.csv',
    NewTransactionRulesFile = 'Updated_Transaction_Validation_Rules.csv'
}
