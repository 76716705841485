import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import errorIcon from '../../../../../src/assets/alert-circle.svg';
import { Dialog } from '../../../common/components/Dialog';

interface UpdatePhotoErrorDialogProps {
    isErrorDialogOpen: boolean;
    onCancelButtonClick: () => void;
    onPrimaryButtonClick: () => void;
    errorText: string;
    fileName: string;
}

export const UpdatePhotoErrorDialog = ({
    isErrorDialogOpen,
    onCancelButtonClick,
    errorText,
    onPrimaryButtonClick,
    fileName
}: UpdatePhotoErrorDialogProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    const getUpdatePhotoErrorComponent = (): React.ReactElement => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                <img src={errorIcon} style={{ height: '20px', width: '20px' }} />
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.error[300],
                        marginLeft: '4px'
                    }}>
                    {errorText}
                </Typography>
            </Box>
        );
    };

    return (
        <Dialog
            open={isErrorDialogOpen}
            onClose={onCancelButtonClick}
            title={t('profileSetting.updateUserPhoto')}
            primaryButtonText={t('profileSetting.selectPhoto')}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onCancelButtonClick}
            onPrimaryButtonClick={async (): Promise<void> => {
                onPrimaryButtonClick();
            }}>
            <Box style={{ marginBottom: '8px' }}>
                <Typography sx={{ ...typography.small2 }}>{t('common.fileDetails')}</Typography>
                <Typography sx={{ ...typography.body2, marginTop: '8px' }}>{fileName}</Typography>
                {getUpdatePhotoErrorComponent()}
            </Box>
        </Dialog>
    );
};
