import { FileDetails, RequestStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getFormattedTimeDateWithComma } from '../utils/DateUtils';
import { BOUserCommentModel } from './BOUserCommentModel';

export const FileDetailsModel = types.model({
    createdAt: types.string,
    documentId: types.string
});

export const RuleDetailModel = types.model({
    boUserComment: types.maybeNull(BOUserCommentModel),
    newRuleFile: types.maybeNull(FileDetailsModel),
    currentRuleFile: types.maybeNull(FileDetailsModel)
});

export const getNewRuleFileModel = (
    fileDetails: FileDetails
): Instance<typeof FileDetailsModel> => {
    return FileDetailsModel.create({
        createdAt: getFormattedTimeDateWithComma(new Date(fileDetails.createdAt.timestamp)),
        documentId: fileDetails.recordId.uuid
    });
};

export const getCurrentRuleFileModel = (
    fileDetails: FileDetails | null | undefined
): Instance<typeof FileDetailsModel> | null => {
    if (fileDetails) {
        return FileDetailsModel.create({
            createdAt: getFormattedTimeDateWithComma(new Date(fileDetails.createdAt.timestamp)),
            documentId: fileDetails.recordId.uuid
        });
    }
    return null;
};

export const RuleHistoryDetailsModel = types.model({
    requestId: types.string,
    requestedAt: types.string,
    requestStatus: types.enumeration<RequestStatus.RequestStatus>(
        'RequestStatus',
        Object.values(RequestStatus.RequestStatus)
    ),
    checkedBy: types.maybeNull(types.string),
    requestedBy: types.string
});
