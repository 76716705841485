import { LeoErrors } from './../../common/errors/LeoErrors';
import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    useGetMakerRefundTransactionDetailsClient,
    useGetRefundAmountClient,
    useRequestTransactionRefundClient
} from '../rpcs/RPC';
import {
    Comment,
    GetRefundAmountRPC,
    GetMakerRefundTransactionDetailsRPC,
    RequestTransactionRefundRPC
} from '@resolut-tech/bcn-rpcs';
import { AmountModel, getAmountModel, getAmountType } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RequestTransactionRefundErrors {
    InvalidTransactionId = 'INVALID_TRANSACTION_ID',
    RefundPeriodExpired = 'REFUND_PERIOD_EXPIRED',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    AmountTooLess = 'AMOUNT_TOO_LESS',
    AmountTooLarge = 'AMOUNT_TOO_LARGE',
    UnableToPerformExchange = 'UNABLE_TO_PERFORM_EXCHANGE',
    AdditionalFeeAmountTooLarge = 'ADDITIONAL_FEE_AMOUNT_TOO_LARGE',
    AdditionalFeeAmountTooLess = 'ADDITIONAL_FEE_AMOUNT_TOO_LESS',
    ReceivingAccountWouldCrossLimit = 'RECEIVING_ACCOUNT_WOULD_CROSS_LIMIT',
    SenderAccountInactive = 'SENDER_ACCOUNT_INACTIVE',
    RecipientAccountInactive = 'RECIPIENT_ACCOUNT_INACTIVE',
    RecipientProfileDisabled = 'RECIPIENT_PROFILE_DISABLED',
    SenderProfileDisabled = 'SENDER_PROFILE_DISABLED',
    SenderProfileArchived = 'SENDER_PROFILE_ARCHIVED',
    RecipientProfileArchived = 'RECIPIENT_PROFILE_ARCHIVED',
    UnsupportedTransactionType = 'UNSUPPORTED_TRANSACTION_TYPE',
    CurrencyMismatch = 'CURRENCY_MISMATCH',
    TransactionAlreadyRefunded = 'TRANSACTION_ALREADY_REFUNDED',
    RefundUnderProcess = 'REFUND_UNDER_PROCESS',
    AdditionalFeeCannotBeMoreThanBaseAmount = 'ADDITIONAL_FEE_CANNOT_BE_MORE_THAN_BASE_AMOUNT',
    InvalidAmountError = 'INVALID_AMOUNT_ERROR'
}

const getStoreError = (
    error:
        | GetMakerRefundTransactionDetailsRPC.Errors.Errors
        | GetRefundAmountRPC.Errors.Errors
        | RequestTransactionRefundRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): RequestTransactionRefundErrors | NetworkingError => {
    switch (error.code) {
        case RequestTransactionRefundErrors.InvalidTransactionId:
            return RequestTransactionRefundErrors.InvalidTransactionId;
        case RequestTransactionRefundErrors.RefundPeriodExpired:
            return RequestTransactionRefundErrors.RefundPeriodExpired;
        case RequestTransactionRefundErrors.InsufficientBalance:
            return RequestTransactionRefundErrors.InsufficientBalance;
        case RequestTransactionRefundErrors.AmountTooLess:
            return RequestTransactionRefundErrors.AmountTooLess;
        case RequestTransactionRefundErrors.AmountTooLarge:
            return RequestTransactionRefundErrors.AmountTooLarge;
        case RequestTransactionRefundErrors.UnableToPerformExchange:
            return RequestTransactionRefundErrors.UnableToPerformExchange;
        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLarge:
            return RequestTransactionRefundErrors.AdditionalFeeAmountTooLarge;
        case RequestTransactionRefundErrors.ReceivingAccountWouldCrossLimit:
            return RequestTransactionRefundErrors.ReceivingAccountWouldCrossLimit;
        case RequestTransactionRefundErrors.SenderAccountInactive:
            return RequestTransactionRefundErrors.SenderAccountInactive;
        case RequestTransactionRefundErrors.RecipientAccountInactive:
            return RequestTransactionRefundErrors.RecipientAccountInactive;
        case RequestTransactionRefundErrors.RecipientProfileDisabled:
            return RequestTransactionRefundErrors.RecipientProfileDisabled;
        case RequestTransactionRefundErrors.SenderProfileDisabled:
            return RequestTransactionRefundErrors.SenderProfileDisabled;
        case RequestTransactionRefundErrors.SenderProfileArchived:
            return RequestTransactionRefundErrors.SenderProfileArchived;
        case RequestTransactionRefundErrors.RecipientProfileArchived:
            return RequestTransactionRefundErrors.RecipientProfileArchived;
        case RequestTransactionRefundErrors.UnsupportedTransactionType:
            return RequestTransactionRefundErrors.UnsupportedTransactionType;
        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLess:
            return RequestTransactionRefundErrors.AdditionalFeeAmountTooLess;
        case RequestTransactionRefundErrors.CurrencyMismatch:
            return RequestTransactionRefundErrors.CurrencyMismatch;
        case RequestTransactionRefundErrors.TransactionAlreadyRefunded:
            return RequestTransactionRefundErrors.TransactionAlreadyRefunded;
        case RequestTransactionRefundErrors.RefundUnderProcess:
            return RequestTransactionRefundErrors.RefundUnderProcess;
        case RequestTransactionRefundErrors.AdditionalFeeCannotBeMoreThanBaseAmount:
            return RequestTransactionRefundErrors.AdditionalFeeCannotBeMoreThanBaseAmount;
        default:
            loggerStore.error(
                `Unhandled error: ${error} occurred in RequestTransactionRefundStore`
            );
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): RequestTransactionRefundErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidLeoUUIDError:
            return RequestTransactionRefundErrors.InvalidTransactionId;
        case LeoErrors.InvalidAmountError:
            return RequestTransactionRefundErrors.InvalidAmountError;
        default:
            loggerStore.error(
                `Unhandled error: ${error} occurred in RequestTransactionRefundStore`
            );
            return NetworkingError.InternalError;
    }
};

export const RequestTransactionRefundStore = types
    .model('RefundTransactionDetails', {
        baseAmount: types.maybeNull(AmountModel),
        bankFee: types.maybeNull(AmountModel),
        totalAmount: types.maybeNull(AmountModel),
        additionalFeeCurrency: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        errorLimitAmount: types.maybeNull(types.string),
        isBankFeeSelected: false,
        isAdditionalFeeSelected: false,
        additionalFee: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<RequestTransactionRefundErrors>(
                    'RequestTransactionRefundErrors',
                    Object.values(RequestTransactionRefundErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError: (): void => {
            store.error = null;
            store.errorLimitAmount = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        setIsBankFeeSelected(value: boolean): void {
            store.isBankFeeSelected = value;
        },
        setIsAdditionalFeeSelected(value: boolean): void {
            store.isAdditionalFeeSelected = value;
        },
        setAdditionalFee(fee: string | null): void {
            store.additionalFee = fee;
        },
        resetFeeValues(): void {
            store.additionalFee = null;
            store.isAdditionalFeeSelected = false;
            store.isBankFeeSelected = false;
        },
        resetStore(): void {
            store.additionalFee = null;
            store.isAdditionalFeeSelected = false;
            store.isBankFeeSelected = false;
            store.error = null;
            store.errorLimitAmount = null;
            store.additionalFeeCurrency = null;
            store.comment = null;
        },
        getRefundTransactionDetails: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            store.errorLimitAmount = null;
            try {
                const request = new GetMakerRefundTransactionDetailsRPC.Request(
                    new LeoUUID(transactionId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetMakerRefundTransactionDetailsRPC.Response,
                    GetMakerRefundTransactionDetailsRPC.Errors.Errors
                > = yield useGetMakerRefundTransactionDetailsClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.baseAmount = getAmountModel(response.baseAmount);
                    store.bankFee = response.bankFee && getAmountModel(response.bankFee);
                    store.totalAmount = getAmountModel(response.totalAmount);
                    store.additionalFeeCurrency = response.additionalFeeCurrency.currencyCode;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                    let _error: GetMakerRefundTransactionDetailsRPC.Errors.Errors;
                    switch (error.code) {
                        case RequestTransactionRefundErrors.AmountTooLess:
                            _error =
                                error as GetMakerRefundTransactionDetailsRPC.Errors.AmountTooLess;
                            if (_error.minimumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.minimumAmount)
                                );
                            }
                            break;
                        case RequestTransactionRefundErrors.AmountTooLarge:
                            _error =
                                error as GetMakerRefundTransactionDetailsRPC.Errors.AmountTooLarge;
                            if (_error.maximumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.maximumAmount)
                                );
                            }
                            break;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetMakerRefundTransactionDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetMakerRefundTransactionDetailsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        getRefundAmount: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            store.errorLimitAmount = null;
            store.totalAmount = null;
            try {
                const transactionUUID = new LeoUUID(transactionId);
                const request = new GetRefundAmountRPC.Request(
                    transactionUUID,
                    store.isBankFeeSelected,
                    store.additionalFee !== null
                        ? getAmountType(Number(store.additionalFee), store.additionalFeeCurrency!)
                        : null
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetRefundAmountRPC.Response,
                    GetRefundAmountRPC.Errors.Errors
                > = yield useGetRefundAmountClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.totalAmount = getAmountModel(response.refundableAmount);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                    let _error: GetRefundAmountRPC.Errors.Errors;
                    switch (error.code) {
                        case RequestTransactionRefundErrors.AmountTooLess:
                            _error = error as GetRefundAmountRPC.Errors.AmountTooLess;
                            if (_error.minimumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.minimumAmount)
                                );
                            }
                            break;
                        case RequestTransactionRefundErrors.AmountTooLarge:
                            _error = error as GetRefundAmountRPC.Errors.AmountTooLarge;
                            if (_error.maximumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.maximumAmount)
                                );
                            }
                            break;
                        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLess:
                            _error = error as GetRefundAmountRPC.Errors.AdditionalFeeAmountTooLess;
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(_error.minimumAllowedAmount)
                            );
                            break;
                        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLarge:
                            _error = error as GetRefundAmountRPC.Errors.AdditionalFeeAmountTooLarge;
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(_error.maximumAllowedAmount)
                            );
                            break;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetRefundAmountRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(`Unknown error: ${error} occurred in GetRefundAmountRPC`);
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        requestTransactionRefund: flow(function* (transactionId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            store.errorLimitAmount = null;
            try {
                const transactionUUID = new LeoUUID(transactionId);
                // requestTransactionRefund function is called only after comment is set. Hence store.comment cannot be null here.
                const request = new RequestTransactionRefundRPC.Request(
                    transactionUUID,
                    store.isBankFeeSelected,
                    store.additionalFee !== null
                        ? getAmountType(Number(store.additionalFee), store.additionalFeeCurrency!)
                        : null,
                    new Comment(store.comment!)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestTransactionRefundRPC.Response,
                    RequestTransactionRefundRPC.Errors.Errors
                > = yield useRequestTransactionRefundClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    store.error = getStoreError(error, loggerStore);
                    let _error: RequestTransactionRefundRPC.Errors.Errors;
                    switch (error.code) {
                        case RequestTransactionRefundErrors.AmountTooLess:
                            _error = error as RequestTransactionRefundRPC.Errors.AmountTooLess;
                            if (_error.minimumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.minimumAmount)
                                );
                            }
                            break;
                        case RequestTransactionRefundErrors.AmountTooLarge:
                            _error = error as RequestTransactionRefundRPC.Errors.AmountTooLarge;
                            if (_error.maximumAmount) {
                                store.errorLimitAmount = getAmountString(
                                    getAmountModel(_error.maximumAmount)
                                );
                            }
                            break;
                        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLess:
                            _error =
                                error as RequestTransactionRefundRPC.Errors.AdditionalFeeAmountTooLess;
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(_error.minimumAllowedAmount)
                            );
                            break;
                        case RequestTransactionRefundErrors.AdditionalFeeAmountTooLarge:
                            _error =
                                error as RequestTransactionRefundRPC.Errors.AdditionalFeeAmountTooLarge;
                            store.errorLimitAmount = getAmountString(
                                getAmountModel(_error.maximumAllowedAmount)
                            );
                            break;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestTransactionRefundRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in RequestTransactionRefundRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createRequestTransactionRefundStore = (): Instance<
    typeof RequestTransactionRefundStore
> => {
    return RequestTransactionRefundStore.create();
};
