import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    Amount,
    Currency,
    RequestGenericFundsTransferRPC,
    TransferAmount
} from '@resolut-tech/bcn-rpcs';

export class MockRequestGenericFundsTransferRPCImpl extends RequestGenericFundsTransferRPC {
    execute(
        _request: RequestGenericFundsTransferRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestGenericFundsTransferRPC.Response,
            RequestGenericFundsTransferRPC.Errors.Errors
        >
    > {
        const response = new RequestGenericFundsTransferRPC.Response(
            new TransferAmount(
                new Amount(50100000, new Currency('MWK')),
                new Amount(50000000, new Currency('MWK'))
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestGenericFundsTransferRPC.Response,
                RequestGenericFundsTransferRPC.Errors.Errors
            >
        >;
    }
}
