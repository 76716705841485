import { RequestBOUserUpdateRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestBOUserUpdateRPCImpl extends RequestBOUserUpdateRPC {
    execute(
        _request: RequestBOUserUpdateRPC.Request
    ): Promise<
        LeoRPCResult<RequestBOUserUpdateRPC.Response, RequestBOUserUpdateRPC.Errors.Errors>
    > {
        const response = new RequestBOUserUpdateRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<RequestBOUserUpdateRPC.Response, RequestBOUserUpdateRPC.Errors.Errors>
        >;
    }
}
