import {
    GetGenericFundsTransferRequestListRPC,
    RequestStatus,
    TransferRequest,
    TransferRequestAccount
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types, cast } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { useGetGenericFundsTransferRequestListRPCClient } from '../rpcs/RPC';
import {
    getMoneyRequestInitialFilter,
    getSortOrder,
    MoneyRequestFilterOptions
} from '../../common/utils/TableFilterUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum TransferRequestErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const TransferRequestModal = types.model({
    requestId: types.string,
    requestedAt: types.string,
    requestedBy: types.string,
    evaluatedBy: types.maybeNull(types.string),
    amount: AmountModel,
    fromAccountId: types.maybeNull(types.string),
    toAccountId: types.maybeNull(types.string),
    status: types.enumeration<RequestStatus.RequestStatus>(
        'RequestStatus',
        Object.values(RequestStatus.RequestStatus)
    )
});

const getTransferRequestModal = (
    transferRequest: TransferRequest
): Instance<typeof TransferRequestModal> => {
    let fromAccountId = null;
    let toAccountId = null;
    if (transferRequest.fromAccountId instanceof TransferRequestAccount.SystemAccount) {
        fromAccountId = transferRequest.fromAccountId.displayName;
    } else if (transferRequest.fromAccountId instanceof TransferRequestAccount.UserAccount) {
        fromAccountId = transferRequest.fromAccountId.accountId.uuid;
    }
    if (transferRequest.toAccountId instanceof TransferRequestAccount.UserAccount) {
        toAccountId = transferRequest.toAccountId.accountId.uuid;
    } else if (transferRequest.toAccountId instanceof TransferRequestAccount.SystemAccount) {
        toAccountId = transferRequest.toAccountId.displayName;
    }
    return TransferRequestModal.create({
        requestId: transferRequest.requestId.uuid,
        requestedAt: getFormattedTimeDateWithComma(new Date(transferRequest.requestedAt.timestamp)),
        requestedBy: getFullName(transferRequest.requestMadeBy),
        evaluatedBy: transferRequest.checkedBy ? getFullName(transferRequest.checkedBy) : null,
        amount: getAmountModel(transferRequest.amount),
        fromAccountId,
        toAccountId,
        status: transferRequest.status
    });
};

export const RequestListStore = types
    .model({
        filterOptions: MoneyRequestFilterOptions,
        transferRequest: types.array(TransferRequestModal),
        totalItems: types.number,
        error: types.maybeNull(
            types.union(
                types.enumeration<TransferRequestErrors>(
                    'TransferRequestErrors',
                    Object.values(TransferRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        updateFilterOptions(filterOptions: Instance<typeof MoneyRequestFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        removeError(): void {
            store.error = null;
        },
        fetchRequestList: flow(function* (
            searchText: string,
            pageIndex: number,
            itemsPerPage: number,
            searchBy?: string,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            let requestStatus = null;
            if (RequestStatus.isInstanceOf(searchBy)) {
                requestStatus = RequestStatus.fromDTO({
                    case: searchBy ?? store.filterOptions.requestStatus
                });
            }
            const request = new GetGenericFundsTransferRequestListRPC.Request(
                requestStatus,
                searchText ?? null,
                pageIndex,
                itemsPerPage,
                getSortOrder(sortOrder)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetGenericFundsTransferRequestListRPC.Response,
                GetGenericFundsTransferRequestListRPC.Errors.InvalidPageIndex
            > = yield useGetGenericFundsTransferRequestListRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const transferRequestList = response.transferRequests.map(
                    (transferRequest: TransferRequest) => {
                        return getTransferRequestModal(transferRequest);
                    }
                );
                store.totalItems = response.totalItems;
                store.transferRequest = cast(transferRequestList);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case TransferRequestErrors.InvalidPageIndex:
                        store.error = TransferRequestErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetGenericFundsTransferRequestListRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetGenericFundsTransferRequestListRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10
    }));

export const createRequestListStore = (): Instance<typeof RequestListStore> => {
    return RequestListStore.create({
        filterOptions: getMoneyRequestInitialFilter(),
        totalItems: 0,
        transferRequest: []
    });
};
