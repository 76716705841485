import { Box, SxProps, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface DetailsRowComponentProps {
    label: string;
    text: string;
    sx?: SxProps;
}

export const DetailsRowComponent = ({
    label,
    text,
    sx
}: DetailsRowComponentProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box sx={{ ...sx }}>
            <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                {label}
            </Typography>
            <Typography
                sx={{
                    ...typography.body2,
                    color: palette.label[300],
                    wordBreak: 'break-word'
                }}>
                {text}
            </Typography>
        </Box>
    );
};
