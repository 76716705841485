import { UserLocalStorageKeys } from './UserLocalStorageKeys';

export enum UserPrivileges {
    // Fee Determination Rules
    CheckFeeRuleRequest = 'CHECK_FEE_DETERMINATION_RULE_REQUEST',
    RequestFeeRuleUpdate = 'REQUEST_FEE_DETERMINATION_RULE_UPDATE',
    ViewFeeRule = 'VIEW_FEE_DETERMINATION_RULE',
    ViewFeeRuleHistory = 'VIEW_FEE_DETERMINATION_RULE_HISTORY',
    DownloadFeeRule = 'DOWNLOAD_FEE_DETERMINATION_RULE',
    // Transaction Validation Rules
    RequestTransactionRuleUpdate = 'REQUEST_TRANSACTION_VALIDATION_RULE_UPDATE',
    ViewTransactionRule = 'VIEW_TRANSACTION_VALIDATION_RULE',
    CheckTransactionRuleRequest = 'CHECK_TRANSACTION_VALIDATION_RULE_REQUEST',
    ViewTransactionRuleHistory = 'VIEW_TRANSACTION_VALIDATION_RULE_HISTORY',
    DownloadTransactionRule = 'DOWNLOAD_TRANSACTION_VALIDATION_RULE',
    // Transaction Search
    ViewTransaction = 'VIEW_TRANSACTION',
    ViewTransactionRefundRequests = 'VIEW_TRANSACTION_REFUND_REQUESTS',
    RequestTransactionRefund = 'REQUEST_TRANSACTION_REFUND',
    CheckTransactionRefundRequest = 'CHECK_TRANSACTION_REFUND_REQUEST',
    // Generic Funds Transfer
    RequestFundsTransfer = 'REQUEST_FUNDS_TRANSFER',
    ViewFundsTransfer = 'VIEW_FUNDS_TRANSFER',
    CheckFundsTransferRequest = 'CHECK_FUNDS_TRANSFER_REQUEST',
    // Unclaimed Funds
    ViewUnclaimedFunds = 'VIEW_UNCLAIMED_FUNDS',
    RequestCBSTransfer = 'REQUEST_CBS_TRANSFER',
    CheckCBSTransferRequest = 'CHECK_CBS_TRANSFER_REQUEST',
    // BO User Management
    RequestAddEditBOUser = 'REQUEST_ADD_EDIT_BO_USER',
    RequestActivateDeactivateBOUser = 'REQUEST_ACTIVATE_DEACTIVATE_BO_USER',
    RequestArchiveBOUser = 'REQUEST_ARCHIVE_BO_USER',
    RequestForceResetPassword = 'REQUEST_FORCE_RESET_PASSWORD',
    RequestRegenerateAuthCodes = 'REQUEST_REGENERATE_AUTH_CODES',
    ViewBOUser = 'VIEW_BO_USER',
    CheckBOUserUpdateRequest = 'CHECK_BO_USER_UPDATE_REQUEST',
    DownloadBOAuthCodes = 'DOWNLOAD_BO_AUTH_CODES',
    // BCN User Management
    RequestActivateDeactivateBCNUser = 'REQUEST_ACTIVATE_DEACTIVATE_BCN_USER',
    RequestArchiveBCNUser = 'REQUEST_ARCHIVE_BCN_USER',
    ViewBCNUser = 'VIEW_BCN_USER',
    CheckBCNUserUpdateRequest = 'CHECK_BCN_USER_UPDATE_REQUEST',
    // Reports
    ViewReports = 'VIEW_REPORTS',
    DownloadReports = 'DOWNLOAD_REPORTS',
    // Audit Logs
    ViewAuditLogs = 'VIEW_AUDIT_LOGS',
    // Agency Banking
    ViewAgent = 'VIEW_AGENT',
    ViewAgentTransaction = 'VIEW_AGENT_TRANSACTION',
    RequestMoveFundsOutOfYafika = 'REQUEST_MOVE_FUNDS_OUT_OF_YAFIKA',
    CheckMoveFundsOutOfYafikaRequest = 'CHECK_MOVE_FUNDS_OUT_OF_YAFIKA_REQUEST',
    CheckAgentUpdateRequest = 'CHECK_AGENT_UPDATE_REQUEST',
    RequestActivateDeactivateAgent = 'REQUEST_ACTIVATE_DEACTIVATE_AGENT',
    RequestArchiveAgent = 'REQUEST_ARCHIVE_AGENT'
}

// This function should only be used by Root-Store and User-Store.
// Root store should use the persisted privileges to check if the user is logged in or not.
// User store should use the persisted value to start its initial value
export const getPersistedUserPrivileges = (): string[] => {
    const privilegeString = localStorage.getItem(UserLocalStorageKeys.privileges);
    if (privilegeString) {
        return privilegeString.split(',');
    }
    return [];
};

export const removePersistedUserPrivileges = (): void => {
    localStorage.removeItem(UserLocalStorageKeys.privileges);
    localStorage.removeItem(UserLocalStorageKeys.userId);
};
