import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefundDialog } from './RefundDialog';
import { useRequestTransactionRefundStore } from '../store/hooks';
import { RequestTransactionRefundErrors } from '../store/RequestTransactionRefundStore';
import { observer } from 'mobx-react';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { Button } from '../../../common/components/Button';

interface RefundRequestDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    transactionId: string;
    onSubmitRequest: (comment: string) => void;
    amountFieldErrorText: string | null;
    setAmountFieldErrorText: React.Dispatch<React.SetStateAction<string | null>>;
    handleRequestRefundStoreErrors: (
        errors: RequestTransactionRefundErrors | NetworkingError
    ) => void;
}
export const RefundRequestDialog = observer(
    ({
        isDialogOpen,
        onClose,
        transactionId,
        onSubmitRequest,
        amountFieldErrorText,
        setAmountFieldErrorText,
        handleRequestRefundStoreErrors
    }: RefundRequestDialogProps): React.ReactElement => {
        const { t } = useTranslation();
        const store = useRequestTransactionRefundStore();
        const isMount = useRef(false);
        const [isCalculateAmountEnabled, setIsCalculateAmountEnabled] = useState(false);
        const [isDataLoading, setIsDataLoading] = useState(false);
        const loggerStore = useLoggerStore();

        // This effect is used to enable the calculate amount when there is change in Bank Fee and Additional selection is updated.
        useEffect(() => {
            // This is to make sure that the Calculate Amount button is disable when the component is mounted.
            if (!isMount.current) {
                isMount.current = true;
            } else {
                setIsCalculateAmountEnabled(true);
                if (!store.isAdditionalFeeSelected) {
                    store.setAdditionalFee(null);
                }
            }
        }, [store.isBankFeeSelected, store.isAdditionalFeeSelected, store.additionalFee]);

        const onCalculateAmount = async (): Promise<void> => {
            setIsDataLoading(true);
            await store.getRefundAmount(transactionId);
            setIsDataLoading(false);
            setIsCalculateAmountEnabled(false);
            if (store.error) {
                handleRequestRefundStoreErrors(store.error);
            }
        };

        // store.baseAmount, store.additionalFeeCurrency cannot be null here as the dialog is opened only when these values are present.
        if (store.baseAmount && store.additionalFeeCurrency) {
            return (
                <RefundDialog
                    title={t('transaction.requestRefund')}
                    primaryButtonText={t('common.submitRequest')}
                    onSubmit={onSubmitRequest}
                    amountFieldErrorText={amountFieldErrorText}
                    baseAmount={store.baseAmount}
                    bankFee={store.bankFee}
                    totalAmount={store.totalAmount}
                    isDialogOpen={isDialogOpen}
                    onClose={(): void => {
                        setIsCalculateAmountEnabled(false);
                        setAmountFieldErrorText(null);
                        store.resetStore();
                        onClose();
                    }}
                    showWarningChip={store.baseAmount.currency !== store.additionalFeeCurrency}
                    additionalFee={store.additionalFee ?? ''}
                    onAdditionalFeeChanged={(value: string): void => {
                        setAmountFieldErrorText(null);
                        if (value.length) {
                            store.setAdditionalFee(value);
                        } else {
                            store.setAdditionalFee(null);
                        }
                        setIsCalculateAmountEnabled(true);
                    }}
                    isAdditionalFeeSelected={store.isAdditionalFeeSelected}
                    isBankFeeSelected={store.isBankFeeSelected}
                    onAdditionalFeeSelectionChange={(value: boolean): void => {
                        store.setIsAdditionalFeeSelected(value);
                    }}
                    onBankFeeSelectionChange={(value: boolean): void => {
                        store.setIsBankFeeSelected(value);
                    }}
                    additionalFeeCurrency={store.additionalFeeCurrency}
                    isPrimaryButtonDisabled={
                        isCalculateAmountEnabled || Boolean(amountFieldErrorText)
                    }
                    calculateAmountButton={
                        <Button
                            name="calculateAmount"
                            title={t('transaction.calculateAmount')}
                            size="small"
                            variant="outlined-color"
                            onClick={onCalculateAmount}
                            style={{ marginLeft: '8px', width: '128px' }}
                            isDisabled={!isCalculateAmountEnabled}
                            isLoading={isDataLoading}
                        />
                    }
                />
            );
        } else {
            loggerStore.debug(
                'baseAmount or additionalCurrency is not present while creating RefundRequestDialog'
            );
            return <></>;
        }
    }
);
