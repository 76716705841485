import * as React from 'react';

export const Trash = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33594 3.99967C1.33594 3.63148 1.63441 3.33301 2.0026 3.33301H14.0026C14.3708 3.33301 14.6693 3.63148 14.6693 3.99967C14.6693 4.36786 14.3708 4.66634 14.0026 4.66634H2.0026C1.63441 4.66634 1.33594 4.36786 1.33594 3.99967Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66406 2.00033C6.48725 2.00033 6.31768 2.07056 6.19266 2.19559C6.06763 2.32061 5.9974 2.49018 5.9974 2.66699V3.33366H9.9974V2.66699C9.9974 2.49018 9.92716 2.32061 9.80213 2.19559C9.67711 2.07056 9.50754 2.00033 9.33073 2.00033H6.66406ZM11.3307 3.33366V2.66699C11.3307 2.13656 11.12 1.62785 10.7449 1.25278C10.3699 0.877706 9.86116 0.666992 9.33073 0.666992H6.66406C6.13363 0.666992 5.62492 0.877706 5.24985 1.25278C4.87478 1.62785 4.66406 2.13656 4.66406 2.66699V3.33366H3.33073C2.96254 3.33366 2.66406 3.63214 2.66406 4.00033V13.3337C2.66406 13.8641 2.87478 14.3728 3.24985 14.7479C3.62492 15.1229 4.13363 15.3337 4.66406 15.3337H11.3307C11.8612 15.3337 12.3699 15.1229 12.7449 14.7479C13.12 14.3728 13.3307 13.8641 13.3307 13.3337V4.00033C13.3307 3.63214 13.0323 3.33366 12.6641 3.33366H11.3307ZM3.9974 4.66699V13.3337C3.9974 13.5105 4.06763 13.68 4.19266 13.8051C4.31768 13.9301 4.48725 14.0003 4.66406 14.0003H11.3307C11.5075 14.0003 11.6771 13.9301 11.8021 13.8051C11.9272 13.68 11.9974 13.5105 11.9974 13.3337V4.66699H3.9974Z"
                fill="currentColor"
            />
        </svg>
    );
};
