import { Box, Typography } from '@mui/material';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ProfileManagementComponent } from '../../common/components/ProfileManagementComponent';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { useUpdateBCNUserStateStore } from '../store/hooks';
import { UpdateBCNUserStateStoreErrors } from '../store/UpdateBCNUserStateStore';
import { useCountdownTimer } from '../utils/useCountdownTimer';
import { ArchiveNonZeroAccountDetailsDialog } from './ArchiveNonZeroAccountDetailsDialog';
import { Dialog } from '../../../common/components/Dialog';

interface ProfileManagementSectionProps {
    userId: string;
    userStatus: UserStatus.UserStatus;
    setScreenLoading: (load: boolean) => void;
    getUpdatedData: () => void;
}

export const ProfileManagementSection = observer(
    ({
        userId,
        userStatus,
        setScreenLoading,
        getUpdatedData
    }: ProfileManagementSectionProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const [isDisabled, setIsDisabled] = useState(false);
        const [
            isArchiveNonZeroAccountDetailsDialogOpen,
            setIsArchiveNonZeroAccountDetailsDialogOpen
        ] = useState(false);
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
        const store = useUpdateBCNUserStateStore();
        const userStore = useUserStore();
        const [countdown, setCountdown] = useCountdownTimer(5);

        useEffect(() => {
            if (store.comment && store.comment.trim().length > 0) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        }, [store.comment]);

        const getErrorText = (): string | undefined => {
            switch (store.error) {
                case UpdateBCNUserStateStoreErrors.InvalidComment:
                    return t('common.invalidComment');
                case UpdateBCNUserStateStoreErrors.InvalidUserId:
                    return t('bcnUser.invalidBCNUserId');
                case UpdateBCNUserStateStoreErrors.UserAlreadyActivated:
                    return t('bcnUser.userAlreadyActive');
                case UpdateBCNUserStateStoreErrors.UserAlreadyDeactivated:
                    return t('bcnUser.userAlreadyDeactivated');
                case UpdateBCNUserStateStoreErrors.UserAlreadyArchived:
                    return t('bcnUser.userAlreadyArchived');
                case UpdateBCNUserStateStoreErrors.RequestAlreadyRaised:
                    return t('common.requestAlreadyRaised');
                case UpdateBCNUserStateStoreErrors.UnableToArchiveUser:
                    return t('bcnUser.unableToArchiveUser');
                case UpdateBCNUserStateStoreErrors.PendingTransactionsFound:
                    return t('bcnUser.pendingTransactionsFound');
                case UpdateBCNUserStateStoreErrors.AgentAccountNotArchived:
                    return t('bcnUser.agentAccountNotArchived');
                case UpdateBCNUserStateStoreErrors.CannotArchiveActiveBcnUser:
                    return t('common.somethingWentWrong');
                case UpdateBCNUserStateStoreErrors.AgentAccountActive:
                    return t('common.agentAccountActive', {
                        id: store.agentId
                    });
                case NetworkingError.InternalError:
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        };

        const onSubmit = async (): Promise<void> => {
            setIsDialogOpen(false);
            setScreenLoading(true);
            userStatus === UserStatus.UserStatus.ACTIVE
                ? await store.raiseRequestToDeactivateBCNUser(userId)
                : await store.raiseRequestToActivateBCNUser(userId);
            if (store.error) {
                setIsDialogOpen(true);
            } else {
                getUpdatedData();
            }
            store.resetComment();
            setScreenLoading(false);
        };

        const onSubmitToArchiveUser = async (): Promise<void> => {
            setIsArchiveDialogOpen(false);
            setScreenLoading(true);
            await store.raiseRequestToArchiveBCNUser(userId);
            if (store.nonZeroAccountDetails.length > 0) {
                setCountdown(5);
                setIsArchiveNonZeroAccountDetailsDialogOpen(true);
            } else if (store.error) {
                setIsArchiveDialogOpen(true);
            } else {
                getUpdatedData();
            }
            if (!store.nonZeroAccountDetails.length) store.resetComment();
            setScreenLoading(false);
        };

        const onSubmitToArchiveUserWithBalance = async (): Promise<void> => {
            setIsArchiveNonZeroAccountDetailsDialogOpen(false);
            setScreenLoading(true);
            await store.raiseRequestToArchiveBCNUserWithBalance(userId);
            if (store.error) {
                setIsArchiveDialogOpen(true);
            } else {
                getUpdatedData();
            }
            store.resetComment();
            store.resetNonZeroAccountDetails();
            setScreenLoading(false);
        };

        const getPrimaryButtonTitle = (): string | null => {
            if (store.error) {
                return null;
            } else {
                return t('common.submitRequest');
            }
        };

        const getSecondaryButtonTitle = (): string | null => {
            if (store.error) {
                return t('common.close');
            } else {
                return t('common.cancel');
            }
        };

        const getErrorComponent = (): React.ReactElement => {
            return (
                <Box sx={{ textAlign: 'center' }}>
                    <AlertCircle
                        style={{ height: '32px', width: '32px', color: palette.error[300] }}
                    />
                    <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                        {getErrorText()}
                    </Typography>
                </Box>
            );
        };

        const getCommentField = (): React.ReactElement => {
            return (
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={store.comment ?? ''}
                    onTextChange={(inputValue: string): void => {
                        store.setComment(inputValue);
                    }}
                    label={t('common.addComment')}
                    style={{ width: '520px' }}
                />
            );
        };

        return (
            <>
                <ArchiveNonZeroAccountDetailsDialog
                    title={t('bcnUser.requestToArchiveUser')}
                    isArchiveNonZeroAccountDetailsDialogOpen={
                        isArchiveNonZeroAccountDetailsDialogOpen
                    }
                    onArchiveNonZeroAccountDetailsDialogClose={(): void => {
                        setIsArchiveNonZeroAccountDetailsDialogOpen(false);
                        store.resetNonZeroAccountDetails();
                        store.removeError();
                        store.resetComment();
                    }}
                    nonZeroAccountDetails={store.nonZeroAccountDetails}
                    onSubmitToArchiveUserWithBalance={onSubmitToArchiveUserWithBalance}
                    countdown={countdown}
                />
                <Dialog
                    open={isDialogOpen}
                    title={
                        userStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('bcnUser.requestForUserReactivation')
                            : t('bcnUser.requestForUserDeactivation')
                    }
                    primaryButtonText={getPrimaryButtonTitle() ?? undefined}
                    isPrimaryButtonDisabled={isDisabled}
                    onPrimaryButtonClick={(): Promise<void> => onSubmit()}
                    secondaryButtonText={getSecondaryButtonTitle() ?? undefined}
                    onSecondaryButtonClick={(): void => {
                        setIsDialogOpen(false);
                        if (store.error) {
                            store.removeError();
                        } else {
                            store.resetComment();
                        }
                    }}
                    disableBackdropClick={true}>
                    {store.error ? getErrorComponent() : getCommentField()}
                </Dialog>
                <Dialog
                    open={isArchiveDialogOpen}
                    title={t('bcnUser.requestToArchiveUser')}
                    primaryButtonText={getPrimaryButtonTitle() ?? undefined}
                    isPrimaryButtonDisabled={isDisabled}
                    onPrimaryButtonClick={(): Promise<void> => onSubmitToArchiveUser()}
                    secondaryButtonText={getSecondaryButtonTitle() ?? undefined}
                    onSecondaryButtonClick={(): void => {
                        setIsArchiveDialogOpen(false);
                        store.removeError();
                        store.resetComment();
                    }}
                    disableBackdropClick={true}>
                    {store.error ? getErrorComponent() : getCommentField()}
                </Dialog>
                <ProfileManagementComponent
                    userId={userId}
                    setIsDialogOpen={setIsDialogOpen}
                    setIsArchiveDialogOpen={setIsArchiveDialogOpen}
                    allowDeactivateOrReactivate={userStore.privileges.includes(
                        UserPrivileges.RequestActivateDeactivateBCNUser
                    )}
                    allowArchive={
                        userStore.privileges.includes(UserPrivileges.RequestArchiveBCNUser) &&
                        userStatus === UserStatus.UserStatus.DEACTIVATED
                    }
                    deactivationOrActivationTitle={
                        userStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('bcnUser.requestForUserReactivation')
                            : t('bcnUser.requestForUserDeactivation')
                    }
                    deactivationOrActivationSubtitle={
                        userStatus === UserStatus.UserStatus.DEACTIVATED
                            ? t('bcnUser.requestForUserReactivationSubtitle')
                            : t('bcnUser.requestForUserDeactivationSubtitle')
                    }
                    archiveTitle={t('bcnUser.requestToArchiveUser')}
                    archiveSubtitle={t('bcnUser.requestForUserArchiveSubtitle')}
                />
            </>
        );
    }
);
