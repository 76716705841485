import React, { useEffect, useState } from 'react';
import { sidebarTopListData } from './SidebarUtils';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Settings } from '../../../../assets/icons/Settings';
import { SignOut } from '../../../../assets/icons/SignOut';
import { ListItem, SubListItemProp } from './ListItem';
import { SignOutDialog } from '../../sign-out/components/SignOutDialog';
import { useUserStore } from '../../store/hooks';
import { YafikaLogo } from '../../../../assets/icons/YafikaLogo';

export const SidebarComponent = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const userPrivileges = useUserStore().privileges;
    const [bottomListActiveItem, setBottomListActiveItem] = useState<string>('');
    const [showSignOutDialog, setShowSignOutDialog] = useState<boolean>(false);

    const sidebarBottomListData = (): SubListItemProp[] => [
        {
            label: t('common.profileSettings'),
            icon: <Settings />,
            path: '/profile-settings'
        },
        {
            isDestructive: true,
            label: t('common.signOut'),
            icon: <SignOut />
        }
    ];

    useEffect(() => {
        setBottomListActiveItem('');
        sidebarBottomListData().forEach((listItem) => {
            if (listItem.path && matchPath(listItem.path, pathname)) {
                setBottomListActiveItem(listItem.label);
            }
        });
    }, [pathname]);

    const getBottomMenuContent = (): React.ReactElement => {
        return (
            <>
                {sidebarBottomListData().map((listItem, index) => {
                    return (
                        <ListItem
                            key={index}
                            listItem={listItem}
                            onSelect={(path?: string): void => {
                                if (listItem.label === t('common.signOut')) {
                                    setShowSignOutDialog(true);
                                } else {
                                    setBottomListActiveItem(listItem.label);
                                    navigate(path ?? '');
                                }
                            }}
                            activeItem={bottomListActiveItem}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <>
            <Sidebar
                open
                list={sidebarTopListData(t, userPrivileges)}
                logo={<YafikaLogo />}
                currentPath={pathname}
                setCurrentPath={(path: string): void => {
                    navigate(path);
                    setBottomListActiveItem('');
                }}
                bottomMenuContent={getBottomMenuContent()}
            />
            <SignOutDialog
                open={showSignOutDialog}
                onCancelButtonClick={(): void => {
                    setShowSignOutDialog(false);
                }}
            />
        </>
    );
});
