import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import {
    FileAttributes,
    GetBOPresignedDocumentUploadURLForFeeRulesRPC
} from '@resolut-tech/bcn-rpcs';
import { useGetBOPresignedDocumentUploadURLForFeeRulesRPCClient } from '../rpcs/RPC';
import {
    FileUploadError,
    getFileAttribute,
    uploadFileToS3
} from '../../common/utils/FileUploadUtils';
import { FileAttributesEnums } from '@resolut-tech/bcn-rpcs/build/document/fileAttributes';
import { APIClient } from '@surya-digital/tedwig';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LoggerStore } from '../../../../log/LoggerStore';
import { getLoggerStore } from '../../../../log/hooks';

const getUploadURL = async (
    fileAttribute: FileAttributes,
    apiClient: APIClient,
    loggerStore: Instance<typeof LoggerStore>
): Promise<{
    documentURL: URL;
    documentId: LeoUUID;
}> => {
    const request = new GetBOPresignedDocumentUploadURLForFeeRulesRPC.Request(fileAttribute);
    const result: LeoRPCResult<
        GetBOPresignedDocumentUploadURLForFeeRulesRPC.Response,
        GetBOPresignedDocumentUploadURLForFeeRulesRPC.Errors.Errors
    > = await useGetBOPresignedDocumentUploadURLForFeeRulesRPCClient(apiClient).execute(request);
    if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return { documentURL: response.documentURL, documentId: response.documentId };
    } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        loggerStore.error(
            `GetBOPresignedDocumentUploadURLForFeeRulesRPC resulted in error: ${error}`
        );
        return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
    } else {
        loggerStore.error(
            `GetBOPresignedDocumentUploadURLForFeeRulesRPC resulted in unexpected error`
        );
        return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
    }
};

const _uploadFile = async (
    fileAttribute: FileAttributes,
    file: File,
    apiClient: APIClient,
    loggerStore: Instance<typeof LoggerStore>
): Promise<LeoUUID> => {
    const { documentURL, documentId } = await getUploadURL(fileAttribute, apiClient, loggerStore);
    await uploadFileToS3(documentURL, file);
    return documentId;
};

export const UploadFeeRulesStore = types
    .model({
        recordId: types.maybeNull(types.string),
        error: types.maybeNull(
            types.enumeration<FileUploadError>('FileUploadError', Object.values(FileUploadError))
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        uploadFile: flow(function* (file: File) {
            const loggerStore = getLoggerStore(store);
            const apiClient = getAPIClient(store);
            try {
                const result = yield getFileAttribute(
                    file,
                    FileAttributesEnums.FileExtension.FileExtension.CSV,
                    true,
                    loggerStore
                ).then((fileAttribute) => {
                    return _uploadFile(fileAttribute, file, apiClient, loggerStore);
                });

                store.recordId = result.uuid;
            } catch (error) {
                switch (error) {
                    case FileUploadError.MaxFileSizeExceeded:
                        store.error = FileUploadError.MaxFileSizeExceeded;
                        break;
                    case FileUploadError.CouldNotFetchUploadURL:
                        store.error = FileUploadError.CouldNotFetchUploadURL;
                        break;
                    case FileUploadError.InvalidFileFormat:
                        store.error = FileUploadError.InvalidFileFormat;
                        break;
                    case FileUploadError.UploadFailed:
                        store.error = FileUploadError.UploadFailed;
                        break;
                    case FileUploadError.UploadedFileIdNotFound:
                        store.error = FileUploadError.UploadedFileIdNotFound;
                        break;
                    case FileUploadError.InvalidFileName:
                        store.error = FileUploadError.InvalidFileName;
                        break;
                    case FileUploadError.InvalidFileSHA:
                        store.error = FileUploadError.InvalidFileSHA;
                        break;
                    case FileUploadError.MinFileSizeDidNotExceed:
                        store.error = FileUploadError.MinFileSizeDidNotExceed;
                        break;
                    default:
                        store.error = FileUploadError.InternalError;
                }
            }
        })
    }));

export const createUploadFeeRulesStore = (): Instance<typeof UploadFeeRulesStore> => {
    return UploadFeeRulesStore.create();
};
