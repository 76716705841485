import { Stack, Typography } from '@mui/material';
import { Button, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronsDown } from '../../../../../assets/icons/ChevronsDown';
import { ChevronsRight } from '../../../../../assets/icons/ChevronsRight';
import { RuleDiffOrientation } from './RuleDiffTableContentCell';

interface RuleDiffOrientationSelectorProps {
    orientation: RuleDiffOrientation;
    setOrientation: React.Dispatch<React.SetStateAction<RuleDiffOrientation>>;
}

export const RuleDiffOrientationSelector = ({
    orientation,
    setOrientation
}: RuleDiffOrientationSelectorProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Stack
            direction="row"
            spacing={2}
            padding={1}
            justifyContent="flex-end"
            alignItems="center">
            <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                {t('common.changeDiffOrientation')}
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                padding={1}
                sx={{ border: `1px solid ${palette.outline[200]}`, borderRadius: '12px' }}>
                <Button
                    size="small"
                    variant={
                        orientation === RuleDiffOrientation.Unified ? 'filled' : 'plain-neutral'
                    }
                    name="unified-view"
                    icon={<ChevronsDown />}
                    iconPosition="left"
                    onClick={(): void => {
                        setOrientation(RuleDiffOrientation.Unified);
                    }}
                />
                <Button
                    size="small"
                    variant={orientation === RuleDiffOrientation.Split ? 'filled' : 'plain-neutral'}
                    name="split-view"
                    icon={<ChevronsRight />}
                    iconPosition="left"
                    onClick={(): void => {
                        setOrientation(RuleDiffOrientation.Split);
                    }}
                />
            </Stack>
        </Stack>
    );
};
