import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import alertCircle from '../../../../assets/alert-circle.svg';
import { DownloadFileErrors } from '../errors/DownloadFileErrors';
import { Dialog } from '../../../common/components/Dialog';

interface DownloadFileErrorDialogProps {
    isErrorDialogOpen: boolean;
    onClose: () => void;
    storeError: DownloadFileErrors | null;
}

export function DownloadFileErrorDialog({
    isErrorDialogOpen,
    onClose,
    storeError
}: DownloadFileErrorDialogProps): React.ReactElement {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    return (
        <Dialog
            open={isErrorDialogOpen}
            title={t('common.downloadFailed')}
            secondaryButtonText={t('common.dismiss')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Box sx={{ textAlign: 'center' }}>
                <img src={alertCircle} alt="alert" />
                <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                    {storeError === DownloadFileErrors.RecordNotFound
                        ? t('common.documentNotFound')
                        : t('common.somethingWentWrongDuringDownload')}
                </Typography>
            </Box>
        </Dialog>
    );
}
