import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { ColDef } from 'ag-grid-community';
import { useUpdateFeeRulesStore, useViewFeeRulesStore } from '../store/hooks';
import { observer } from 'mobx-react';
import { LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import { EditRules } from '../../common/components/rules/EditRules';
import { FeeDeterminationRuleModel } from '../models/FeeDeterminationRuleModel';
import { Instance } from 'mobx-state-tree';
import { InsertFeeRulesRowDialog } from '../components/InsertFeeRulesRowDialog';
import { EditRulesUpdateRequestDialog } from '../../common/components/rules/EditRulesUpdateRequestDialog';
import { generateCSVFile } from '../../common/utils/FileUtils';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { UpdateFeeRulesErrors } from '../store/UpdateFeeRulesStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { RuleValidationErrorComponent } from '../../common/components/rules/RuleValidationErrorComponent';
import {
    getCbsIdFromCountryName,
    getCountryNameFromCbsId,
    parseRuleDecimalAmount
} from '../../common/utils/UIUtils';
import { FeeRuleFeeType, FeeRuleTransactionType, RuleEditMechanism } from '@resolut-tech/bcn-rpcs';
import { NetworkingError } from '../../../error/store/ErrorStore';

export interface FeeRulesRowData {
    transactionType: string;
    amountRangeLow: string;
    amountRangeHigh: string;
    cbsId: string;
    feeType: string;
    percentage: string;
    upperBound: string;
    lowerBound: string;
    flatFee: string;
    bankFeePercentage: string;
    counterPartyFeePercentage: string;
    agentCommissionPercentage: string;
}

export const EditFeeRules = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useViewFeeRulesStore();
    const updateFeeRulesStore = useUpdateFeeRulesStore();
    const [isLoading, setIsLoading] = useState(false);
    const initialRows = useRef<FeeRulesRowData[]>([]);
    const [rows, setRows] = useState<FeeRulesRowData[]>([]);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isFeeRulesEdited = JSON.stringify(initialRows.current) === JSON.stringify(rows);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showRequestFeeRulesUpdateDialog, setShowRequestFeeRulesUpdateDialog] = useState(false);
    const [errorDialogLabel, setErrorDialogLabel] = useState<string | null>(null);
    const [isRuleUpdated, setIsRuleUpdated] = useState(false);
    const [ruleValidationError, setRuleValidationError] = useState<string | null>(null);

    const isEmptyRow = (row: FeeRulesRowData): boolean => {
        if (
            row.transactionType !== '' ||
            row.amountRangeLow !== '' ||
            row.amountRangeHigh !== '' ||
            row.cbsId !== '' ||
            row.feeType !== '' ||
            row.percentage !== '' ||
            row.upperBound !== '' ||
            row.lowerBound !== '' ||
            row.flatFee !== '' ||
            row.bankFeePercentage !== '' ||
            row.counterPartyFeePercentage !== '' ||
            row.agentCommissionPercentage !== ''
        ) {
            return false;
        } else {
            return true;
        }
    };

    // setRowData checks for empty row in row data. If there is an empty row while updating the row data, it will be removed.
    const setRowData = (_rows: FeeRulesRowData[]): void => {
        const filteredRows = _rows.filter((row) => !isEmptyRow(row));
        setRows(filteredRows);
    };

    const feeDeterminationRuleModelToRows = (
        rules: Instance<typeof FeeDeterminationRuleModel>[]
    ): FeeRulesRowData[] => {
        return rules.map((rule) => ({
            transactionType: rule.transactionType,
            amountRangeLow: rule.amountRangeLow?.toString() ?? '',
            amountRangeHigh: rule.amountRangeHigh?.toString() ?? '',
            cbsId: rule.cbsId,
            feeType: rule.feeType,
            percentage: rule.percentage?.toString() ?? '',
            upperBound: rule.upperBound?.toString() ?? '',
            lowerBound: rule.lowerBound?.toString() ?? '',
            flatFee: rule.flatFee?.toString() ?? '',
            bankFeePercentage: rule.bankFeePercentage?.toString() ?? '',
            counterPartyFeePercentage: rule.counterPartyFeePercentage?.toString() ?? '',
            agentCommissionPercentage: rule.agentCommissionPercentage?.toString() ?? ''
        }));
    };

    const fetchCurrentFeeDeterminationRuleDetails = async (): Promise<void> => {
        setIsLoading(true);
        await store.fetchCurrentFeeDeterminationRuleDetails().then(() => {
            return store.fetchCountryAndCBSIdList();
        });
        if (store.error) {
            setErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
        } else if (store.currentFeeDeterminationRules) {
            const _rows = feeDeterminationRuleModelToRows(store.currentFeeDeterminationRules);
            const _initialRows = feeDeterminationRuleModelToRows(
                store.currentFeeDeterminationRules
            );
            initialRows.current = _initialRows;
            for (const row of _rows) {
                row.cbsId = getCountryNameFromCbsId(store.getCbsIdWithCountryList(), row.cbsId);
            }
            setRowData(_rows);
        } else {
            setRowData([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchCurrentFeeDeterminationRuleDetails();

        return (): void => {
            setIsLoading(false);
            setRows([]);
            updateFeeRulesStore.resetStore();
        };
    }, []);

    const columns: ColDef[] = [
        {
            field: 'transactionType',
            minWidth: 260,
            headerName: t('rulesCSVHeaders.feeRules.transactionType'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: Object.values(FeeRuleTransactionType.FeeRuleTransactionType)
            }
        },
        {
            field: 'amountRangeLow',
            minWidth: 144,
            headerName: t('rulesCSVHeaders.feeRules.amountRangeLow'),
            type: 'numericColumn',
            valueFormatter: (feeRule): string => {
                const _feeRule: FeeRulesRowData = feeRule.data;
                if (_feeRule) {
                    return parseRuleDecimalAmount(_feeRule.amountRangeLow);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'amountRangeHigh',
            minWidth: 144,
            headerName: t('rulesCSVHeaders.feeRules.amountRangeHigh'),
            type: 'numericColumn',
            valueFormatter: (feeRule): string => {
                const _feeRule: FeeRulesRowData = feeRule.data;
                if (_feeRule) {
                    return parseRuleDecimalAmount(_feeRule.amountRangeHigh);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'cbsId',
            minWidth: 120,
            headerName: t('common.cbs'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: store.getCbsIdCountryList()
            }
        },
        {
            field: 'feeType',
            minWidth: 220,
            headerName: t('rulesCSVHeaders.feeRules.feeType'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: Object.values(FeeRuleFeeType.FeeRuleFeeType)
            }
        },
        {
            field: 'percentage',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.percentage'),
            type: 'numericColumn'
        },
        {
            field: 'upperBound',
            headerName: t('rulesCSVHeaders.feeRules.upperBound'),
            type: 'numericColumn',
            valueFormatter: (feeRule): string => {
                const _feeRule: FeeRulesRowData = feeRule.data;
                if (_feeRule) {
                    return parseRuleDecimalAmount(_feeRule.upperBound);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'lowerBound',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.lowerBound'),
            type: 'numericColumn',
            valueFormatter: (feeRule): string => {
                const _feeRule: FeeRulesRowData = feeRule.data;
                if (_feeRule) {
                    return parseRuleDecimalAmount(_feeRule.lowerBound);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'flatFee',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.flatFee'),
            type: 'numericColumn',
            valueFormatter: (feeRule): string => {
                const _feeRule: FeeRulesRowData = feeRule.data;
                if (_feeRule) {
                    return parseRuleDecimalAmount(_feeRule.flatFee);
                } else {
                    return '';
                }
            }
        },
        {
            field: 'bankFeePercentage',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.bankFeePercentage'),
            type: 'numericColumn'
        },
        {
            field: 'counterPartyFeePercentage',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.counterPartyFeePercentage'),
            type: 'numericColumn'
        },
        {
            field: 'agentCommissionPercentage',
            minWidth: 120,
            headerName: t('rulesCSVHeaders.feeRules.agentCommissionPercentage'),
            type: 'numericColumn'
        }
    ];

    const getDataAsCsv = (): string => {
        // CSV file data is generated manually. It should contain both the headers and rows.
        // csvHeaders will be used as the first line in CSV file to hold the headers.
        const csvHeaders = `Transaction Type,Amount Range Low,Amount Range High,CBS ID,Type of Fee,Percentage,Upper Bound,Lower Bound,Flat Fee,% Yafika,% Counterparty,% Agent`;
        const csvRows = rows.map((row) => {
            const amountRangeLow = row.amountRangeLow;
            const amountRangeHigh = row.amountRangeHigh;
            const upperBound = row.upperBound;
            const lowerBound = row.lowerBound;
            const flatFee = row.flatFee;
            const cbsId = getCbsIdFromCountryName(store.getCbsIdWithCountryList(), row.cbsId);
            return `${row.transactionType},${amountRangeLow},${amountRangeHigh},${cbsId},${row.feeType},${row.percentage},${upperBound},${lowerBound},${flatFee},${row.bankFeePercentage},${row.counterPartyFeePercentage},${row.agentCommissionPercentage}`;
        });
        return [csvHeaders, ...csvRows].join('\n');
    };

    const getActionElement = (): ActionElement => {
        return {
            primaryButton: {
                title: t('common.requestUpdate'),
                isDisabled: isFeeRulesEdited,
                onClick: (): void => {
                    const csvData = getDataAsCsv();
                    updateFeeRulesStore.setCsvData(csvData);
                    setShowRequestFeeRulesUpdateDialog(true);
                }
            },
            secondaryButton: {
                title: t('common.cancel'),
                onClick: (): void => {
                    navigate('/fee-rules/update');
                }
            }
        };
    };

    const onUploadRuleSubmitFile = async (recordId: LeoUUID): Promise<void> => {
        updateFeeRulesStore.setFileId(recordId.uuid);
        await updateFeeRulesStore.updateFeeRule(RuleEditMechanism.RuleEditMechanism.INLINE_EDIT);
        if (updateFeeRulesStore.error) {
            switch (updateFeeRulesStore.error) {
                case UpdateFeeRulesErrors.InvalidFile:
                    setRuleValidationError(
                        updateFeeRulesStore.errorLocalizedText ?? t('common.updateRuleError')
                    );
                    break;
                case UpdateFeeRulesErrors.InvalidComment:
                    setErrorDialogLabel(t('common.invalidComment'));
                    break;
                case UpdateFeeRulesErrors.UnknownFile:
                    setErrorDialogLabel(t('common.updateRuleError'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            setIsRuleUpdated(true);
        }
    };

    return (
        <Stack>
            <PageHeader
                title={t('feeRules.updateRules')}
                subtitle={t('feeRules.updateRulesSubtitle')}
                actionElement={getActionElement()}
            />
            <InsertFeeRulesRowDialog
                isDialogOpen={isDialogOpen}
                onDialogClose={(): void => {
                    setIsDialogOpen(false);
                }}
                onInsertButtonClick={(row): void => {
                    setRowData([...rows, row]);
                }}
            />
            {updateFeeRulesStore.csvData && (
                <EditRulesUpdateRequestDialog
                    title={t('feeRules.updateRules')}
                    isDialogOpen={showRequestFeeRulesUpdateDialog}
                    onDialogClose={(): void => {
                        setShowRequestFeeRulesUpdateDialog(false);
                        updateFeeRulesStore.resetStore();
                    }}
                    setStoreComment={updateFeeRulesStore.setComment}
                    csvFile={generateCSVFile(
                        updateFeeRulesStore.csvData,
                        t('feeRules.generatedCSVFileName')
                    )}
                    uploadStore={updateFeeRulesStore.uploadFeeRulesStore}
                    onUploadRuleSubmitFile={onUploadRuleSubmitFile}
                    setErrorDialogLabel={setErrorDialogLabel}
                    validatingRulesMessage={t('feeRules.validatingRules')}
                />
            )}
            <ErrorDialog
                title={t('feeRules.updateRules')}
                isErrorDialogOpen={Boolean(errorDialogLabel)}
                errorMessage={errorDialogLabel}
                onClose={(): void => {
                    setErrorDialogLabel(null);
                    updateFeeRulesStore.resetError();
                    store.removeError();
                }}
            />
            <SuccessDialog
                title={t('feeRules.updateRules')}
                isDialogOpen={isRuleUpdated}
                successMessage={t('common.requestRaisedSuccessfully')}
                onCancel={(): void => {
                    setIsRuleUpdated(false);
                    navigate('/fee-rules/update');
                }}
            />
            <Stack padding="32px">
                {isLoading ? (
                    <LoadingIndicator isLoading={isLoading} />
                ) : (
                    <Stack spacing="32px">
                        {ruleValidationError && (
                            <RuleValidationErrorComponent errorText={ruleValidationError} />
                        )}
                        <EditRules
                            rows={rows}
                            columns={columns}
                            setRows={setRowData}
                            onInsertButtonClick={(): void => {
                                setIsDialogOpen(true);
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
});
