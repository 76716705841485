import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BCNUserAccountDetailsModel } from '../models/BCNUserAccountDetailModel';
import { AccountTable } from './AccountTable';

interface BCNUserAccountDetailsProp {
    accountDetails: Instance<typeof BCNUserAccountDetailsModel>[];
}

export const BCNUserAccountDetails = ({
    accountDetails
}: BCNUserAccountDetailsProp): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Section
            title={t('bcnUser.accountDetails')}
            allowViewChange={false}
            content={[<AccountTable accountDetails={accountDetails} key="accountDetails" />]}
            styleOverrides={{
                // To fix the padding that is added on the Section when an accordion is not used.
                padding: '0px',
                margin: '2px 0px',
                borderRadius: '6px'
            }}
        />
    );
};
