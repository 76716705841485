import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NetworkingError } from '../../error/store/ErrorStore';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { AuthCodeEntryErrors } from '../stores/AuthCodeEntryStore';
import { useSignInStore } from '../stores/hooks';
import { Dialog } from '../../common/components/Dialog';

export interface AuthErrorDialogProps {
    open: boolean;
    error?: CommonAuthErrors | NetworkingError | AuthCodeEntryErrors;
    setOpen: (open: boolean) => void;
}

export const AuthErrorDialog = ({
    open,
    error,
    setOpen
}: AuthErrorDialogProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const signInStore = useSignInStore();

    const dialogBoxMessage = (): string | undefined => {
        switch (error) {
            case CommonAuthErrors.SignInTemporarilyBlocked:
                return t('signIn.signInTemporarilyBlocked');
            case CommonAuthErrors.BoUserDisabled:
                return t('signIn.boUserDisabled');
            case CommonAuthErrors.TooManyIncorrectAttempts:
            case CommonAuthErrors.TooManyOtpAttempts:
            case AuthCodeEntryErrors.TooManyIncorrectAuthCodeAttempts:
                return t('signIn.tooManyIncorrectAttempts');
            case AuthCodeEntryErrors.TooManyRequests:
                return t('signIn.tooManyRequest');
            case CommonAuthErrors.InactiveState:
            case NetworkingError.InternalError:
                return t('common.somethingWentWrongProcessingRequest');
            default:
                return t('common.somethingWentWrongProcessingRequest');
        }
    };

    const onDialogClose = (): void => {
        setOpen(false);
        signInStore.resetStore();
        navigate('/auth');
    };

    return (
        <Box sx={{ width: '560px' }}>
            <Dialog
                open={open}
                title={t('common.somethingWentWrong')}
                secondaryButtonText={t('signIn.startOver')}
                onSecondaryButtonClick={onDialogClose}
                disableBackdropClick={true}>
                <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                    {dialogBoxMessage()}
                </Typography>
            </Dialog>
        </Box>
    );
};
