import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { useBOUserRequestSummaryStore } from '../store/hooks';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import {
    getFormattedPhoneNumber,
    getGenderTranslation,
    getRequestStatus,
    getUserStatus
} from '../../common/utils/UIUtils';
import { RequestType } from '../store/BOUserRequestSummaryStore';
import {
    SectionRowComponentWithLabel,
    SectionRowComponentWithLabelProp
} from '../../common/components/SectionRowComponentWithLabel';
import { SectionRowCellEnum } from '../../common/types/SectionRowComponentTypes';
import { getBORoleName } from '../utils/UIUtils';
import { RoleDiffComponent } from './RoleDiffComponent';
import { BOUserRequestChip, BOUserRequestChipType } from './BOUserRequestChip';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import { Dialog } from '../../../common/components/Dialog';

interface BORequestSummaryDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
}

export const BORequestSummaryDialog = ({
    isDialogOpen,
    onClose
}: BORequestSummaryDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const store = useBOUserRequestSummaryStore();

    const field = (
        label: string,
        value?: string | StatusType,
        isChip = false
    ): React.ReactElement => {
        return (
            <Stack>
                <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                    {label}
                </Typography>
                {isChip ? (
                    <StatusComponent variant={'status-cell'} status={value as StatusType} />
                ) : (
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {value}
                    </Typography>
                )}
            </Stack>
        );
    };

    const getDetailCells = (): React.ReactElement[] => {
        const userDetailSectionCells: SectionRowComponentWithLabelProp[] = [
            {
                label: t('common.firstName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: store.newUser?.firstName ?? ''
                }
            },
            {
                label: t('common.lastName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: store.newUser?.lastName ?? '-'
                }
            },
            {
                label: t('common.otherNames'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: store.newUser?.otherName ?? '-'
                }
            },
            {
                label: t('common.mobileNumber'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text:
                        (store.newUser?.phoneNumber &&
                            getFormattedPhoneNumber(store.newUser?.phoneNumber)) ??
                        ''
                }
            },
            {
                label: t('common.emailId'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: store.newUser?.emailId ?? '',
                    showEllipsis: true
                }
            },
            {
                label: t('common.country'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: store.newUser?.country ?? ''
                }
            },
            {
                label: t('common.gender'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: getGenderTranslation(t, store.newUser?.gender)
                }
            },
            {
                label: t('boUser.userRoles'),
                cell: {
                    type: SectionRowCellEnum.UserRoleChips,
                    roles:
                        store.newUser?.roles.map(({ workflow, name }) =>
                            getBORoleName(workflow, name)
                        ) ?? []
                }
            }
        ];

        return userDetailSectionCells.map(({ label, cell }) => (
            <SectionRowComponentWithLabel
                key={label}
                label={label}
                cell={cell}
                variant={'dialog'}
            />
        ));
    };

    const addUser = (): React.ReactElement => {
        return (
            <>
                <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                    {t('boUser.profileDetails')}
                </Typography>
                <Stack>{getDetailCells()}</Stack>
            </>
        );
    };

    const editUser = (): React.ReactElement => {
        return (
            <>
                {store.editUser?.profileChanges && (
                    <>
                        <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                            {t('boUser.profileChanges')}
                        </Typography>
                        <Stack>
                            {[
                                <SectionRowComponentWithLabel
                                    key={'diffHeader'}
                                    label={''}
                                    cell={{
                                        type: SectionRowCellEnum.DiffHeader,
                                        currentDetailsLabel: t('boUser.oldDetails'),
                                        updatedDetailsLabel: t('boUser.updatedDetails')
                                    }}
                                    variant={'dialog'}
                                />,
                                ...(store.editUser?.profileChanges?.map((change) => {
                                    return (
                                        <SectionRowComponentWithLabel
                                            key={change.label}
                                            label={change.label}
                                            cell={{
                                                type: SectionRowCellEnum.Unstyled,
                                                text:
                                                    (change.oldValue &&
                                                        getFormattedPhoneNumber(change.oldValue)) ??
                                                    '-',
                                                diffText:
                                                    change.newValue &&
                                                    getFormattedPhoneNumber(change.newValue),
                                                showDiff: true,
                                                isSectionComponent: true
                                            }}
                                            variant={'dialog'}
                                        />
                                    );
                                }) ?? [])
                            ]}
                        </Stack>
                    </>
                )}
                {store.editUser?.roles && (
                    <>
                        <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                            {t('boUser.roleChanges')}
                        </Typography>
                        <Grid container flexGrow={1}>
                            {store.editUser?.roles.map((role, index) => (
                                <RoleDiffComponent key={index} role={role} />
                            ))}
                        </Grid>
                    </>
                )}
            </>
        );
    };

    const requestedFor = (type: BOUserRequestChipType): React.ReactElement => {
        return (
            <>
                <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                    {t('common.requestedFor')}
                </Typography>
                <BOUserRequestChip type={type} />
            </>
        );
    };

    const statusChange = (newStatus: UserStatus.UserStatus): React.ReactElement => {
        return (
            <Grid container direction={'row'}>
                <Grid item xs={6}>
                    {field(t('common.oldStatus'), getUserStatus(store.oldStatus!), true)}
                </Grid>
                <Grid item xs={6}>
                    {field(t('common.updatedStatus'), getUserStatus(newStatus), true)}
                </Grid>
            </Grid>
        );
    };

    const requestSummary = (): React.ReactElement => {
        switch (store.requestType) {
            case RequestType.Add:
                return addUser();
            case RequestType.Edit:
                return editUser();
            case RequestType.ForceResetPassword:
                return requestedFor(BOUserRequestChipType.ForceResetPassword);
            case RequestType.RegenerateAuthCode:
                return requestedFor(BOUserRequestChipType.ReGenerateAuthCodes);
            case RequestType.Deactivate:
                return statusChange(UserStatus.UserStatus.DEACTIVATED);
            case RequestType.Reactivate:
                return statusChange(UserStatus.UserStatus.ACTIVE);
            case RequestType.Archive:
                return statusChange(UserStatus.UserStatus.ARCHIVED);
            default:
                return <></>;
        }
    };

    return (
        <Dialog
            open={isDialogOpen}
            title={t('boUser.viewRequestSummary')}
            secondaryButtonText={t('common.close')}
            onSecondaryButtonClick={(): void => {
                onClose();
            }}
            disableBackdropClick
            dialogWidth="560px">
            <Stack spacing={'12px'}>
                <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                    {t('common.requestDetails')}
                </Typography>
                <Grid container direction={'row'}>
                    <Grid item xs={6}>
                        {field(t('common.timeDate'), store.comment?.requestedAt)}
                    </Grid>
                    <Grid item xs={6}>
                        {field(
                            t('common.requestStatus'),
                            getRequestStatus(store.requestStatus),
                            true
                        )}
                    </Grid>
                </Grid>
                {field(t('common.requestedBy'), store.comment?.name)}
                {field(t('common.comment'), store.comment?.comment)}
                {requestSummary()}
            </Stack>
        </Dialog>
    );
};
