import { flow, Instance, types } from 'mobx-state-tree';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { Status } from '../../common/enums/StatusEnum';
import {
    CheckerResponse,
    CheckerResponseEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import {
    CheckAgentStatusUpdateRequestRPC,
    CheckToArchiveAgentUpdateRequestWithCommissionRPC,
    Comment
} from '@resolut-tech/bcn-rpcs';
import {
    useCheckAgentStatusUpdateRequestRPCClient,
    useCheckToArchiveAgentUpdateRequestWithCommissionRPCClient
} from '../rpcs/RPC';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum CheckAgentStatusUpdateRequestStoreErrors {
    InvalidRequestID = 'INVALID_REQUEST_ID',
    NonZeroCommissionAccount = 'NON_ZERO_COMMISSION_ACCOUNT',
    IncorrectRequestType = 'INCORRECT_REQUEST_TYPE',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    InvalidComment = 'INVALID_COMMENT',
    BcnUserAccountDisabled = 'BCN_USER_ACCOUNT_DISABLED'
}

export const CheckAgentStatusUpdateRequestStore = types
    .model({
        comment: types.maybeNull(types.string),
        totalCommissionBalance: types.maybeNull(AmountModel),
        bcnUserId: types.maybeNull(types.string),
        status: types.maybeNull(types.enumeration<Status>('Status', Object.values(Status))),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckAgentStatusUpdateRequestStoreErrors>(
                    'CheckAgentStatusUpdateRequestStoreErrors',
                    Object.values(CheckAgentStatusUpdateRequestStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        setStatus(status: CheckerResponseEnums.Status.Status): void {
            store.status = status;
        },
        resetStore(): void {
            store.error = null;
            store.comment = null;
            store.bcnUserId = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        resetComment(): void {
            store.comment = null;
        },
        checkAgentStatusUpdateRequest: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                if (store.comment && requestId && store.status) {
                    const request = new CheckAgentStatusUpdateRequestRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckAgentStatusUpdateRequestRPC.Response,
                        CheckAgentStatusUpdateRequestRPC.Errors.Errors
                    > = yield useCheckAgentStatusUpdateRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.IncorrectRequestType:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.IncorrectRequestType;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.NonZeroCommissionAccount:
                                if (
                                    error instanceof
                                    CheckAgentStatusUpdateRequestRPC.Errors.NonZeroCommissionAccount
                                ) {
                                    store.totalCommissionBalance = getAmountModel(
                                        error.totalCommissionBalance
                                    );
                                    store.error =
                                        CheckAgentStatusUpdateRequestStoreErrors.NonZeroCommissionAccount;
                                }
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.BcnUserAccountDisabled:
                                if (
                                    error instanceof
                                    CheckAgentStatusUpdateRequestRPC.Errors.BcnUserAccountDisabled
                                ) {
                                    store.bcnUserId = error.bcnUserId.uuid;
                                    store.error =
                                        CheckAgentStatusUpdateRequestStoreErrors.BcnUserAccountDisabled;
                                }
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckAgentStatusUpdateRequestRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckAgentStatusUpdateRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `comment, status or requestId are not present while archiving agent in CheckAgentStatusUpdateRequestStore`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = CheckAgentStatusUpdateRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckAgentStatusUpdateRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckAgentStatusUpdateRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        checkToArchiveAgentUpdateRequestWithCommission: flow(function* (requestId: string) {
            store.error = null;
            const loggerStore = getLoggerStore(store);
            try {
                if (store.comment && requestId && store.status) {
                    const request = new CheckToArchiveAgentUpdateRequestWithCommissionRPC.Request(
                        new LeoUUID(requestId),
                        new CheckerResponse(store.status, new Comment(store.comment))
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        CheckToArchiveAgentUpdateRequestWithCommissionRPC.Response,
                        CheckToArchiveAgentUpdateRequestWithCommissionRPC.Errors.Errors
                    > = yield useCheckToArchiveAgentUpdateRequestWithCommissionRPCClient(
                        apiClient
                    ).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded;
                                break;
                            case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn:
                                store.error =
                                    CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in CheckToArchiveAgentUpdateRequestWithCommissionRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in CheckToArchiveAgentUpdateRequestWithCommissionRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `Comment, Status or RequestId is not present while archiving agent in CheckAgentStatusUpdateRequestStore`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID;
                            break;
                        case LeoErrors.InvalidCommentError:
                            store.error = CheckAgentStatusUpdateRequestStoreErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckToArchiveAgentUpdateRequestWithCommissionRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckToArchiveAgentUpdateRequestWithCommissionRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createCheckAgentStatusUpdateRequestStore = (): Instance<
    typeof CheckAgentStatusUpdateRequestStore
> => {
    return CheckAgentStatusUpdateRequestStore.create();
};
