import { RequestDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { RequestStatus } from '../enums/RequestStatus';
import { BOUserCommentModel, getBOUserCommentModel } from './BOUserCommentModel';
import { getWithdrawRequestModel, WithdrawRequestModel } from './WithdrawRequestModel';

export const CBSRequestDetailsModel = types.model({
    status: types.enumeration<RequestStatus>('RequestStatus', Object.values(RequestStatus)),
    evaluatorComment: types.maybeNull(BOUserCommentModel),
    requesterComment: types.maybeNull(BOUserCommentModel),
    withdrawalComment: types.maybeNull(WithdrawRequestModel),
    requestId: types.string
});

export const getCBSRequestDetailsModel = (
    requestDetails: RequestDetails.RequestDetails
): Instance<typeof CBSRequestDetailsModel> => {
    let evaluatorComment: Instance<typeof BOUserCommentModel> | null = null;
    let requesterComment: Instance<typeof BOUserCommentModel> | null = null;
    let withdrawalComment: Instance<typeof WithdrawRequestModel> | null = null;
    let status = RequestStatus.PENDING;
    if (
        requestDetails instanceof RequestDetails.Approved ||
        requestDetails instanceof RequestDetails.Denied
    ) {
        status =
            requestDetails instanceof RequestDetails.Approved
                ? RequestStatus.APPROVED
                : RequestStatus.DENIED;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof RequestDetails.Pending) {
        status = RequestStatus.PENDING;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }
    if (requestDetails instanceof RequestDetails.Discarded) {
        status = RequestStatus.DISCARDED;
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
        evaluatorComment = requestDetails.checkerComment
            ? getBOUserCommentModel(requestDetails.checkerComment)
            : null;
    }
    if (requestDetails instanceof RequestDetails.Withdrawn) {
        status = RequestStatus.WITHDRAWN;
        withdrawalComment = getWithdrawRequestModel(
            requestDetails.withdrawalComment,
            requestDetails.makerComment.text,
            requestDetails.requestRaisedAt
        );
    }
    if (requestDetails instanceof RequestDetails.Processing) {
        status = RequestStatus.PROCESSING;
        evaluatorComment = getBOUserCommentModel(requestDetails.checkerComment);
        requesterComment = getBOUserCommentModel(requestDetails.makerComment);
    }

    return CBSRequestDetailsModel.create({
        status,
        withdrawalComment,
        requesterComment,
        evaluatorComment,
        requestId: requestDetails.requestId.uuid
    });
};
