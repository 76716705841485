import { Stack, styled } from '@mui/material';
import { Card, usePalette } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommentComponent } from '../../common/components/CommentComponent';
import { StatusType } from '../../common/components/StatusComponent';
import { UserActionStatusHeader } from '../../common/components/UserActionStatusHeader';
import { BOUserCommentModel } from '../../common/models/BOUserCommentModel';
import { ApproveDenyUnclaimedFundsActionSection } from './ApproveDenyUnclaimedFundsActionSection';
import { WithdrawUnclaimedFundsActionSection } from './WithdrawUnclaimedFundsActionSection';
import { ViewUnclaimedFundsActionSection } from './ViewUnclaimedFundsActionSection';

interface UnclaimedFundsPendingRequestCardProps {
    children?: React.ReactNode;
    onApproveClick?: () => void;
    onDenyClick?: () => void;
    onWithdrawClick?: () => void;
    comment: Instance<typeof BOUserCommentModel>;
    showApproveAndDenySection?: boolean;
    showWithdrawSection?: boolean;
    showViewDetailsSection?: boolean;
    onViewDetailsButtonClick?: () => void;
}

export const UnclaimedFundsPendingRequestCard = ({
    children,
    comment,
    onApproveClick,
    onDenyClick,
    onWithdrawClick,
    showApproveAndDenySection,
    showWithdrawSection,
    showViewDetailsSection,
    onViewDetailsButtonClick
}: UnclaimedFundsPendingRequestCardProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    const CardSection = styled(Stack)({
        borderTop: `1px solid ${palette.outline[200]}`,
        padding: '32px 32px 32px 80px'
    });

    const renderActionSection = (): React.ReactElement => {
        if (showApproveAndDenySection && onApproveClick && onDenyClick) {
            return (
                <ApproveDenyUnclaimedFundsActionSection
                    onApproveClick={onApproveClick}
                    onDenyClick={onDenyClick}
                    onViewDetailsButtonClick={onViewDetailsButtonClick}
                />
            );
        }
        if (showWithdrawSection && onWithdrawClick) {
            return (
                <WithdrawUnclaimedFundsActionSection
                    onWithdrawClick={onWithdrawClick}
                    onViewDetailsButtonClick={onViewDetailsButtonClick}
                />
            );
        }
        if (showViewDetailsSection && onViewDetailsButtonClick) {
            return (
                <ViewUnclaimedFundsActionSection
                    onViewDetailsButtonClick={onViewDetailsButtonClick}
                />
            );
        }
        return <></>;
    };

    return (
        <Card>
            <UserActionStatusHeader
                title={t('common.requestRaisedBy', {
                    name: comment.name
                })}
                subtitle={comment.requestedAt}
                profilePhotoUrl={comment.profilePhoto}
                status={StatusType.PENDING}
                sx={{
                    borderRadius: '8px 8px 0px 0px'
                }}
            />
            <CardSection spacing={2}>
                <CommentComponent text={comment.comment} />
                {children}
            </CardSection>
            {renderActionSection()}
        </Card>
    );
};
