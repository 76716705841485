import { cast, flow, Instance, types } from 'mobx-state-tree';
import { GetTransactionValidationRuleDiffRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import {
    getTransactionValidationRuleModel,
    TransactionValidationRuleModel
} from '../models/TransactionValidationRuleModel';
import { useGetTransactionValidationRuleDiffRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum TransactionValidationRulesDiffStoreErrors {
    OldRulesNotFound = 'OLD_RULES_NOT_FOUND',
    InvalidRequestId = 'INVALID_REQUEST_ID'
}

export const TransactionValidationRulesDiffStore = types
    .model('TransactionValidationRulesDiff', {
        oldTransactionValidationRules: types.array(TransactionValidationRuleModel),
        updatedTransactionValidationRules: types.array(TransactionValidationRuleModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<TransactionValidationRulesDiffStoreErrors>(
                    'TransactionValidationRulesDiffStoreErrors',
                    Object.values(TransactionValidationRulesDiffStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchTransactionValidationRulesDiff: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetTransactionValidationRuleDiffRPC.Request(
                    new LeoUUID(requestId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetTransactionValidationRuleDiffRPC.Response,
                    GetTransactionValidationRuleDiffRPC.Errors.Errors
                > = yield useGetTransactionValidationRuleDiffRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.oldTransactionValidationRules = cast(
                        response.oldTransactionValidationRules.map((transactionValidationRule) =>
                            getTransactionValidationRuleModel(transactionValidationRule)
                        )
                    );
                    store.updatedTransactionValidationRules = cast(
                        response.updatedTransactionValidationRules.map(
                            (transactionValidationRule) =>
                                getTransactionValidationRuleModel(transactionValidationRule)
                        )
                    );
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case TransactionValidationRulesDiffStoreErrors.OldRulesNotFound:
                            store.error =
                                TransactionValidationRulesDiffStoreErrors.OldRulesNotFound;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionValidationRuleDiffRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetTransactionValidationRuleDiffRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error =
                                TransactionValidationRulesDiffStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetTransactionValidationRuleDiffRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetTransactionValidationRuleDiffRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createTransactionValidationRulesDiffStore = (): Instance<
    typeof TransactionValidationRulesDiffStore
> => {
    return TransactionValidationRulesDiffStore.create();
};
