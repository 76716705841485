import { PGUserName, UserName } from '@resolut-tech/bcn-rpcs';
import { LocalizedText } from '@resolut-tech/bcn-rpcs/build/assets/localizedText';

// Since the application is rendered in only english as of now, we return the en string of the LocalizedText.
export const getTranslatedString = (text: LocalizedText): string => {
    return text.en;
};

export const getFullName = ({ firstName, lastName }: UserName): string => {
    if (!lastName) {
        return firstName.text;
    }
    return `${firstName.text} ${lastName.text}`;
};

export const getPGFullName = ({ firstName, lastName }: PGUserName): string => {
    if (!lastName) {
        return firstName;
    }
    return `${firstName} ${lastName}`;
};
