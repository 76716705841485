import { APIClient } from '@surya-digital/tedwig';
import {
    CheckTransactionValidationRuleRequestRPC,
    CheckTransactionValidationRuleRequestRPCClientImpl,
    GetBOPresignedDocumentUploadURLForTransactionRulesRPC,
    GetBOPresignedDocumentUploadURLForTransactionRulesRPCClientImpl,
    GetBOPresignedDownloadURLForTransactionRulesRPC,
    GetBOPresignedDownloadURLForTransactionRulesRPCClientImpl,
    GetBOPresignedDownloadURLForTransactionRulesTemplateRPC,
    GetBOPresignedDownloadURLForTransactionRulesTemplateRPCClientImpl,
    GetCountryAndCBSIdListForTVRRPC,
    GetCountryAndCBSIdListForTVRRPCClientImpl,
    GetCurrentTransactionValidationRuleDetailsFileRPC,
    GetCurrentTransactionValidationRuleDetailsFileRPCClientImpl,
    GetCurrentTransactionValidationRuleDetailsRPC,
    GetCurrentTransactionValidationRuleDetailsRPCClientImpl,
    GetTransactionValidationRuleDiffRPC,
    GetTransactionValidationRuleDiffRPCClientImpl,
    GetTransactionValidationRuleHistoryRequestDetailsRPC,
    GetTransactionValidationRuleHistoryRequestDetailsRPCClientImpl,
    GetTransactionValidationRuleRequestDetailsRPC,
    GetTransactionValidationRuleRequestDetailsRPCClientImpl,
    GetTransactionValidationRuleRequestHistoryRPC,
    GetTransactionValidationRuleRequestHistoryRPCClientImpl,
    RequestTransactionValidationRuleUpdateRPC,
    RequestTransactionValidationRuleUpdateRPCClientImpl,
    WithdrawTransactionValidationRuleRequestRPC,
    WithdrawTransactionValidationRuleRequestRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockCheckTransactionValidationRuleRequestRPCImpl } from './MockCheckTransactionValidationRuleRequestRPCImpl';
import { MockGetBOPresignedDocumentUploadURLForTransactionRulesRPCImpl } from './MockGetBOPresignedDocumentUploadURLForTransactionRulesRPCImpl';
import { MockGetBOPresignedDownloadURLForTransactionRulesRPCClientImpl } from './MockGetBOPresignedDownloadURLForTransactionRulesRPCClientImpl';
import { MockGetBOPresignedDownloadURLForTransactionRulesTemplateRPClientImpl } from './MockGetBOPresignedDownloadURLForTransactionRulesTemplateRPClientImpl';
import { MockGetCurrentTransactionValidationRuleDetailsFileRPCImpl } from './MockGetCurrentTransactionValidationRuleDetailsFileRPCImpl.ts';
import { MockGetCurrentTransactionValidationRuleDetailsRPCClientImpl } from './MockGetCurrentTransactionValidationRuleDetailsRPCClientImpl';
import { MockGetTransactionValidationRuleDiffRPCClientImpl } from './MockGetTransactionValidationRuleDiffRPCClientImpl';
import { MockGetTransactionValidationRuleRequestDetailsRPCImpl } from './MockGetTransactionValidationRuleRequestDetailsRPCImpl';
import { MockRequestTransactionValidationRuleUpdateRPCImpl } from './MockRequestTransactionValidationRuleUpdateRPCImpl';
import { MockWithdrawTransactionValidationRuleRequestRPCImpl } from './MockWithdrawTransactionValidationRuleRequestRPCImpl';
import { MockGetTransactionValidationRuleRequestHistoryRPCImpl } from './MockGetTransactionValidationRuleRequestHistoryRPCImpl';
import { MockGetTransactionValidationRuleHistoryRequestDetailsRPCImpl } from './MockGetTransactionValidationRuleHistoryRequestDetailsRPCImpl';
import { MockGetCountryAndCBSIdListForTVRRPCImpl } from './MockGetCountryAndCBSIdListForTVRRPCImpl';

const MOCK = false;

export const useGetTransactionValidationRuleRequestDetailsRPCClient = (
    apiClient: APIClient
): GetTransactionValidationRuleRequestDetailsRPC => {
    if (MOCK) {
        return new MockGetTransactionValidationRuleRequestDetailsRPCImpl();
    } else {
        return new GetTransactionValidationRuleRequestDetailsRPCClientImpl(apiClient);
    }
};

export const useGetCurrentTransactionValidationRuleDetailsFileRPCClient = (
    apiClient: APIClient
): GetCurrentTransactionValidationRuleDetailsFileRPC => {
    if (MOCK) {
        return new MockGetCurrentTransactionValidationRuleDetailsFileRPCImpl();
    } else {
        return new GetCurrentTransactionValidationRuleDetailsFileRPCClientImpl(apiClient);
    }
};

export const useCheckTransactionValidationRuleRPCClient = (
    apiClient: APIClient
): CheckTransactionValidationRuleRequestRPC => {
    if (MOCK) {
        return new MockCheckTransactionValidationRuleRequestRPCImpl();
    } else {
        return new CheckTransactionValidationRuleRequestRPCClientImpl(apiClient);
    }
};

export const useRequestTransactionValidationRuleUpdateRPCClient = (
    apiClient: APIClient
): RequestTransactionValidationRuleUpdateRPC => {
    if (MOCK) {
        return new MockRequestTransactionValidationRuleUpdateRPCImpl();
    } else {
        return new RequestTransactionValidationRuleUpdateRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDownloadURLForTransactionRulesRPCClient = (
    apiClient: APIClient
): GetBOPresignedDownloadURLForTransactionRulesRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDownloadURLForTransactionRulesRPCClientImpl();
    } else {
        return new GetBOPresignedDownloadURLForTransactionRulesRPCClientImpl(apiClient);
    }
};

export const useWithdrawTransactionRulesRequestRPCClient = (
    apiClient: APIClient
): WithdrawTransactionValidationRuleRequestRPC => {
    if (MOCK) {
        return new MockWithdrawTransactionValidationRuleRequestRPCImpl();
    } else {
        return new WithdrawTransactionValidationRuleRequestRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDocumentUploadURLForTransactionRulesRPCClient = (
    apiClient: APIClient
): GetBOPresignedDocumentUploadURLForTransactionRulesRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDocumentUploadURLForTransactionRulesRPCImpl();
    } else {
        return new GetBOPresignedDocumentUploadURLForTransactionRulesRPCClientImpl(apiClient);
    }
};

export const useGetBOPresignedDownloadURLForTransactionRulesTemplateRPCClient = (
    apiClient: APIClient
): GetBOPresignedDownloadURLForTransactionRulesTemplateRPC => {
    if (MOCK) {
        return new MockGetBOPresignedDownloadURLForTransactionRulesTemplateRPClientImpl();
    } else {
        return new GetBOPresignedDownloadURLForTransactionRulesTemplateRPCClientImpl(apiClient);
    }
};

export const useGetCurrentTransactionValidationRuleDetailsRPCClient = (
    apiClient: APIClient
): GetCurrentTransactionValidationRuleDetailsRPC => {
    if (MOCK) {
        return new MockGetCurrentTransactionValidationRuleDetailsRPCClientImpl();
    } else {
        return new GetCurrentTransactionValidationRuleDetailsRPCClientImpl(apiClient);
    }
};

export const useGetTransactionValidationRuleDiffRPCClient = (
    apiClient: APIClient
): GetTransactionValidationRuleDiffRPC => {
    if (MOCK) {
        return new MockGetTransactionValidationRuleDiffRPCClientImpl();
    } else {
        return new GetTransactionValidationRuleDiffRPCClientImpl(apiClient);
    }
};

export const useGetTransactionValidationRuleRequestHistoryRPCClient = (
    apiClient: APIClient
): GetTransactionValidationRuleRequestHistoryRPC => {
    if (MOCK) {
        return new MockGetTransactionValidationRuleRequestHistoryRPCImpl();
    } else {
        return new GetTransactionValidationRuleRequestHistoryRPCClientImpl(apiClient);
    }
};

export const useGetTransactionValidationRuleHistoryRequestDetailsRPCClient = (
    apiClient: APIClient
): GetTransactionValidationRuleHistoryRequestDetailsRPC => {
    if (MOCK) {
        return new MockGetTransactionValidationRuleHistoryRequestDetailsRPCImpl();
    } else {
        return new GetTransactionValidationRuleHistoryRequestDetailsRPCClientImpl(apiClient);
    }
};

export const useGetCountryAndCBSIdListForTVRRPCClient = (
    apiClient: APIClient
): GetCountryAndCBSIdListForTVRRPC => {
    if (MOCK) {
        return new MockGetCountryAndCBSIdListForTVRRPCImpl();
    } else {
        return new GetCountryAndCBSIdListForTVRRPCClientImpl(apiClient);
    }
};
