import { useWithdrawBCNUserUpdateRequestRPCClient } from './../rpcs/RPC';
import { Comment, WithdrawBCNUserUpdateRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance } from 'mobx-state-tree';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserWithdrawStoreError {
    InvalidComment = 'INVALID_COMMENT'
}

export const BCNUserWithdrawRequestStore = types
    .model({
        requestId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserWithdrawStoreError>(
                    'BCNUserWithdrawStoreError',
                    Object.values(BCNUserWithdrawStoreError)
                ),
                types.enumeration<WithdrawRequestErrors>(
                    'WithdrawRequestErrors',
                    Object.values(WithdrawRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        resetComment(): void {
            store.comment = null;
        },
        withdrawRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            try {
                if (store.requestId) {
                    const _comment = new Comment(comment);
                    const _requestId = new LeoUUID(requestId);
                    const request = new WithdrawBCNUserUpdateRequestRPC.Request(
                        _requestId,
                        _comment
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        WithdrawBCNUserUpdateRequestRPC.Response,
                        WithdrawBCNUserUpdateRequestRPC.Errors.Errors
                    > = yield useWithdrawBCNUserUpdateRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case WithdrawRequestErrors.InvalidRequestId:
                                store.error = WithdrawRequestErrors.InvalidRequestId;
                                break;
                            case WithdrawRequestErrors.RequestAlreadyChecked:
                                store.error = WithdrawRequestErrors.RequestAlreadyChecked;
                                break;
                            case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                                store.error = WithdrawRequestErrors.CanOnlyWithdrawSelfRequest;
                                break;
                            case WithdrawRequestErrors.RequestAlreadyDiscarded:
                                store.error = WithdrawRequestErrors.RequestAlreadyDiscarded;
                                break;
                            case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                                store.error = WithdrawRequestErrors.RequestAlreadyWithdrawn;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in WithdrawBCNUserUpdateRequestRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in WithdrawBCNUserUpdateRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug('requestId not found in BCNUserWithdrawRequestStore');
                    store.error = WithdrawRequestErrors.InvalidRequestId;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = BCNUserWithdrawStoreError.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in WithdrawBCNUserUpdateRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in WithdrawBCNUserUpdateRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createBCNUserWithdrawRequestStore = (): Instance<
    typeof BCNUserWithdrawRequestStore
> => {
    return BCNUserWithdrawRequestStore.create({});
};
