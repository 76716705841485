import * as React from 'react';

export const CopyButton = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_8449_76382)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.33301 6.66634C6.96482 6.66634 6.66634 6.96482 6.66634 7.33301V13.333C6.66634 13.7012 6.96482 13.9997 7.33301 13.9997H13.333C13.7012 13.9997 13.9997 13.7012 13.9997 13.333V7.33301C13.9997 6.96482 13.7012 6.66634 13.333 6.66634H7.33301ZM5.33301 7.33301C5.33301 6.22844 6.22844 5.33301 7.33301 5.33301H13.333C14.4376 5.33301 15.333 6.22844 15.333 7.33301V13.333C15.333 14.4376 14.4376 15.333 13.333 15.333H7.33301C6.22844 15.333 5.33301 14.4376 5.33301 13.333V7.33301Z"
                    fill="#6A39F1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.66699 2.00033C2.49018 2.00033 2.32061 2.07056 2.19559 2.19559C2.07056 2.32061 2.00033 2.49018 2.00033 2.66699V8.66699C2.00033 8.8438 2.07056 9.01337 2.19559 9.1384C2.32061 9.26342 2.49018 9.33366 2.66699 9.33366H3.33366C3.70185 9.33366 4.00033 9.63214 4.00033 10.0003C4.00033 10.3685 3.70185 10.667 3.33366 10.667H2.66699C2.13656 10.667 1.62785 10.4563 1.25278 10.0812C0.877706 9.70613 0.666992 9.19743 0.666992 8.66699V2.66699C0.666992 2.13656 0.877706 1.62785 1.25278 1.25278C1.62785 0.877706 2.13656 0.666992 2.66699 0.666992H8.66699C9.19743 0.666992 9.70613 0.877706 10.0812 1.25278C10.4563 1.62785 10.667 2.13656 10.667 2.66699V3.33366C10.667 3.70185 10.3685 4.00033 10.0003 4.00033C9.63214 4.00033 9.33366 3.70185 9.33366 3.33366V2.66699C9.33366 2.49018 9.26342 2.32061 9.1384 2.19559C9.01337 2.07056 8.8438 2.00033 8.66699 2.00033H2.66699Z"
                    fill="#6A39F1"
                />
            </g>
            <defs>
                <clipPath id="clip0_8449_76382">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
