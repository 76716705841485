import { Instance, types } from 'mobx-state-tree';
import {
    createRequestTransactionRefundStore,
    RequestTransactionRefundStore
} from './RequestTransactionRefundStore';
import { createRefundRequestsStore, RefundRequestsStore } from './RefundRequestsStore';
import { createTransactionDetailStore, TransactionDetailStore } from './TransactionDetailStore';
import { createTransactionSearchStore, TransactionSearchStore } from './TransactionSearchStore';
import {
    CheckRequestDetailsStore,
    createCheckRequestDetailsStore
} from './CheckRequestDetailsStore';
import {
    createRefundWithdrawRequestStore,
    RefundWithdrawRequestStore
} from './RefundWithdrawRequestStore';

export const TransactionStore = types
    .model({
        transactionSearchStore: TransactionSearchStore,
        transactionDetailStore: TransactionDetailStore,
        requestTransactionRefundStore: RequestTransactionRefundStore,
        refundRequestsStore: RefundRequestsStore,
        checkRequestDetailsStore: CheckRequestDetailsStore,
        refundWithdrawRequestStore: RefundWithdrawRequestStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.transactionDetailStore = createTransactionDetailStore();
            store.requestTransactionRefundStore = createRequestTransactionRefundStore();
            store.checkRequestDetailsStore = createCheckRequestDetailsStore();
            store.refundWithdrawRequestStore = createRefundWithdrawRequestStore();
        }
    }));

export const createTransactionStore = (): Instance<typeof TransactionStore> => {
    return TransactionStore.create({
        transactionSearchStore: createTransactionSearchStore(),
        transactionDetailStore: createTransactionDetailStore(),
        requestTransactionRefundStore: createRequestTransactionRefundStore(),
        refundRequestsStore: createRefundRequestsStore(),
        checkRequestDetailsStore: createCheckRequestDetailsStore(),
        refundWithdrawRequestStore: createRefundWithdrawRequestStore()
    });
};
