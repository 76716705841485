import { Stack, styled, Typography } from '@mui/material';
import { ChevronRight, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { Button } from '../../../common/components/Button';

interface ReportsCardProps {
    title: string;
    subtitle: string;
    icon: string;
    onReportsCardClick: () => void;
}

export function ReportsCardComponent({
    title,
    subtitle,
    icon,
    onReportsCardClick
}: ReportsCardProps): React.ReactElement {
    const palette = usePalette();
    const typography = useTypography();

    const StyledStack = styled(Stack)(() => ({
        boxSizing: 'border-box',
        backgroundColor: palette.background[400],
        border: `1px solid ${palette.outline[100]}`,
        borderRadius: '8px',
        padding: '16px',
        height: '128px',
        width: '344px',
        '&:hover': {
            cursor: 'pointer',
            boxShadow:
                '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08)'
        }
    }));

    const IconBackground = styled(Stack)(() => ({
        padding: '8px',
        width: '36px',
        boxSizing: 'border-box',
        backgroundColor: palette.primary[100],
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px'
    }));

    return (
        <div onClick={(): void => onReportsCardClick()}>
            <StyledStack>
                <IconBackground>
                    <img height="20px" width="20px" src={icon} alt={`${title} icon`} />
                </IconBackground>
                <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                    <Stack>
                        <Typography
                            sx={{ color: palette.label[300], ...typography.small2, mt: '16px' }}>
                            {title}
                        </Typography>
                        <Typography sx={{ color: palette.label[200], ...typography.body3 }}>
                            {subtitle}
                        </Typography>
                    </Stack>
                    <Button
                        style={{ border: '0px' }}
                        variant="plain-neutral"
                        name="redirect"
                        size="small"
                        icon={<ChevronRight style={{ color: palette.primary[300] }} />}
                        iconPosition="left"
                        onClick={(): void => {}}
                        isDisabled
                    />
                </Stack>
            </StyledStack>
        </div>
    );
}
