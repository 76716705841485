import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Router as AppRouter } from './routes';
import './i18n/config';
import { ThemeConfig } from '@surya-digital/leo-reactjs-ui';
import { typographyStyles } from './theme/typography/typographyStyles';
import { ColorPalette } from './theme/color-palette/colorPalette';
import { RootStoreProvider } from './modules/store/root/store/RootStore';

export function App(): JSX.Element {
    return (
        <ThemeConfig
            theme="default"
            colorPalettes={[{ name: 'default', palette: ColorPalette }]}
            typography={typographyStyles}>
            <RootStoreProvider>
                <Router>
                    <AppRouter />
                </Router>
            </RootStoreProvider>
        </ThemeConfig>
    );
}
