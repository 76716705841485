import { useSignOutBoUserRPCClient } from './../rpcs/RPC';
import { SignOutBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { HomeStore } from '../../store/HomeStore';
import { getAPIClient } from '../../../networking/store/NetworkingStore';

export const SignOutStore = types.model().actions((store) => ({
    signOutUser: flow(function* () {
        const request = new SignOutBOUserRPC.Request();
        const apiClient = getAPIClient(store);
        yield useSignOutBoUserRPCClient(apiClient).execute(request);
        const homeStore = getParent<typeof HomeStore>(store);
        homeStore.signOutUser();
    })
}));

export const createSignOutStore = (): Instance<typeof SignOutStore> => {
    return SignOutStore.create();
};
