import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { Amount, Currency, GetCheckerGenericFundsTransferSummaryRPC } from '@resolut-tech/bcn-rpcs';

export class MockGetCheckerGenericFundsTransferSummaryRPCImpl extends GetCheckerGenericFundsTransferSummaryRPC {
    execute(
        _request: GetCheckerGenericFundsTransferSummaryRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCheckerGenericFundsTransferSummaryRPC.Response,
            GetCheckerGenericFundsTransferSummaryRPC.Errors.Errors
        >
    > {
        const response = new GetCheckerGenericFundsTransferSummaryRPC.Response(
            new Amount(5000000, new Currency('MWK')),
            new Amount(100000, new Currency('MWK')),
            new Amount(50100000, new Currency('MWK')),
            new Amount(50000000, new Currency('MWK'))
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCheckerGenericFundsTransferSummaryRPC.Response,
                GetCheckerGenericFundsTransferSummaryRPC.Errors.Errors
            >
        >;
    }
}
