import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { BOUserDetailStore } from './BOUserDetailStore';
import { BOUserSearchStore } from './BOUserSearchStore';
import { BORoleStore } from './BORoleStore';
import { BOUserProfileManagementStore } from './BOUserProfileManagementStore';
import { BOUserRequestsStore } from './BOUserRequestsStore';
import { BOUserRequestHistoryStore } from './BOUserRequestHistoryStore';
import { BOUserRequestSummaryStore } from './BOUserRequestSummaryStore';
import { CheckBOUserChangeRequestStore } from './CheckBOUserChangeRequestStore';
import { BOUserWithdrawRequestStore } from './BOUserWithdrawRequestStore';
import { NewBOUserRequestDetailsStore } from './NewBOUserDetailsStore';
import { RequestToAddEditBOUserStore } from './RequestToAddEditBOUserStore';
import { ValidateBOUserDetailsStore } from './ValidateBOUserDetailsStore';
import { NewBOUserCheckStore } from './NewBOUserCheckStore';

export const useBOUserSearchStore = (): Instance<typeof BOUserSearchStore> => {
    return useRootStore().homeStore.boUsers.boUserSearchStore;
};

export const useBOUserDetailStore = (): Instance<typeof BOUserDetailStore> => {
    return useRootStore().homeStore.boUsers.boUserDetailsStore;
};

export const useBORoleStore = (): Instance<typeof BORoleStore> => {
    return useRootStore().homeStore.boUsers.boRoleStore;
};

export const useBOUserProfileManagement = (): Instance<typeof BOUserProfileManagementStore> => {
    return useRootStore().homeStore.boUsers.boUserProfileManagementStore;
};

export const useBOUserRequestStore = (): Instance<typeof BOUserRequestsStore> => {
    return useRootStore().homeStore.boUsers.boUserRequestsStore;
};

export const useBOUserRequestHistoryStore = (): Instance<typeof BOUserRequestHistoryStore> => {
    return useRootStore().homeStore.boUsers.boUserRequestHistoryStore;
};

export const useBOUserRequestSummaryStore = (): Instance<typeof BOUserRequestSummaryStore> => {
    return useRootStore().homeStore.boUsers.boUserRequestSummaryStore;
};

export const useCheckBOUserChangeRequestStore = (): Instance<
    typeof CheckBOUserChangeRequestStore
> => {
    return useRootStore().homeStore.boUsers.checkBOUserChangeRequestStore;
};

export const useBOUserWithdrawRequestStore = (): Instance<typeof BOUserWithdrawRequestStore> => {
    return useRootStore().homeStore.boUsers.boUserWithdrawRequestStore;
};

export const useNewBOUserRequestDetailsStore = (): Instance<
    typeof NewBOUserRequestDetailsStore
> => {
    return useRootStore().homeStore.boUsers.newBOUserRequestDetailsStore;
};

export const useRequestToAddEditBOUserStore = (): Instance<typeof RequestToAddEditBOUserStore> => {
    return useRootStore().homeStore.boUsers.requestToAddEdtBOUserStore;
};

export const useValidateBOUserDetailsStore = (): Instance<typeof ValidateBOUserDetailsStore> => {
    return useRootStore().homeStore.boUsers.validateBOUsersDetailsStore;
};

export const useNewBOUserCheckStore = (): Instance<typeof NewBOUserCheckStore> => {
    return useRootStore().homeStore.boUsers.newBOUserCheckStore;
};
