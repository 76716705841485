import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { TransactionSearchBar } from '../components/TransactionSearchBar';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { useTransactionSearchStore } from '../store/hooks';
import { TransactionType } from '@resolut-tech/bcn-rpcs';
import { useNavigate } from 'react-router-dom';
import { getFormattedPhoneNumber, getTableStyleOverrides } from '../../common/utils/UIUtils';
import { TransactionFilterOptions, TransactionSearchErrors } from '../store/TransactionSearchStore';
import { Instance } from 'mobx-state-tree';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';

export const TransactionSearch = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useTransactionSearchStore();
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();
    const [countries, setCountries] = useState<CountryViewModel[] | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    const fetchCountryList = async (): Promise<void> => {
        await store.fetchCountries();
        setCountries(store.countryList());
    };

    useEffect(() => {
        fetchCountryList();
        breadcrumbStore.setInitialLink(
            t('transaction.transactionSearchTitle'),
            window.location.pathname
        );
    }, []);

    const getTransactionType = (type: TransactionType.TransactionType): string => {
        switch (type) {
            case TransactionType.TransactionType.BILL_PAYMENT:
                return t('transaction.transactionTypeBillPayment');
            case TransactionType.TransactionType.WALLET_TO_WALLET:
                return t('transaction.transactionTypeAccountToAccount');
            case TransactionType.TransactionType.YAFIKA_TO_EXTERNAL:
                return t('transaction.transactionTypeYafikaToExternal');
            case TransactionType.TransactionType.EXTERNAL_TO_YAFIKA:
                return t('transaction.transactionTypeExternalToYafika');
            case TransactionType.TransactionType.LOAD_WALLET:
                return t('transaction.transactionTypeLoadWallet');
            case TransactionType.TransactionType.WITHIN_YAFIKA:
                return t('transaction.transactionTypeWithinYafika');
            case TransactionType.TransactionType.AB_CASH_IN:
                return t('transaction.transactionTypeABCashIn');
            case TransactionType.TransactionType.AB_CASH_OUT:
                return t('transaction.transactionTypeABCashOut');
            case TransactionType.TransactionType.AB_AGENT_COMMISSION:
                return t('transaction.transactionTypeABAgentCommission');
            case TransactionType.TransactionType.AB_MONEY_TRANSFER:
                return t('transaction.transactionTypeABMoneyTransfer');
            case TransactionType.TransactionType.GENERIC_FUNDS_TRANSFER:
                return t('transaction.transactionTypeGenericFundsTransfer');
        }
    };

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'timeDate',
                sortable: true,
                name: t('common.timeDate'),
                width: '182px'
            },
            {
                id: 'transactionId',
                name: t('common.transactionId'),
                width: '160px',
                ellipsisContent: true
            },
            {
                id: 'transactionType',
                name: t('common.tableHeader.transactionType'),
                width: '182px'
            },
            {
                id: 'sendersName',
                name: t('common.tableHeader.sendersName'),
                width: '182px'
            },
            {
                id: 'sendersMobileNumber',
                name: t('common.tableHeader.sendersMobileNumber'),
                width: '182px',
                align: 'right'
            },
            {
                id: 'debitAccountId',
                name: t('transaction.tableHeader.debitAccountId'),
                width: '182px',
                ellipsisContent: true
            },
            {
                id: 'receiversName',
                name: t('common.tableHeader.receiversName'),
                width: '160px'
            },
            {
                id: 'receiversMobileNumber',
                name: t('common.tableHeader.receiversMobileNumber'),
                width: '182px',
                align: 'right'
            },
            {
                id: 'creditAccountId',
                name: t('transaction.tableHeader.creditAccountId'),
                width: '160px',
                ellipsisContent: true
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<typeof TransactionFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchTransactionDetails(
            option.filter?.searchBy ?? '',
            option.filter?.searchText ?? '',
            option.page ? option.page - 1 : 0,
            option.filter?.searchByPhoneCode ?? '',
            option.filter?.searchByFirstName ?? '',
            option.filter?.searchByLastName ?? '',
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case TransactionSearchErrors.InvalidSearchTextError:
                    return t('common.invalidSearchText');
                case TransactionSearchErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.transactions.map((transaction) => {
            return [
                {
                    data: transaction.createdAt
                },
                { data: transaction.transactionId },
                {
                    data: getTransactionType(
                        transaction.transactionType as TransactionType.TransactionType
                    )
                },
                { data: transaction.senderName ?? '-' },
                {
                    data:
                        (transaction.senderMobileNumber &&
                            getFormattedPhoneNumber(transaction.senderMobileNumber)) ??
                        '-',
                    align: 'right'
                },
                { data: transaction.debitAccountId ?? '-' },
                { data: transaction.receiverName ?? '-' },
                {
                    data:
                        (transaction.receiverMobileNumber &&
                            getFormattedPhoneNumber(transaction.receiverMobileNumber)) ??
                        '-',
                    align: 'right'
                },
                { data: transaction.creditAccountId ?? '-' },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack direction="column">
            <PageHeader
                title={t('transaction.transactionSearchTitle')}
                subtitle={t('transaction.transactionsSearchSubTitle')}
            />
            <Table
                name={'TransactionTable'}
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    // store.isTableIdle value is used to check if the table has to start with idle mode or not.
                    // Because when the table is filtered and the page is revisited the table should start loading the data, instead of showing idle view.
                    idle: store.isTableIdle
                        ? { message: t('transaction.transactionSearchIdleState') }
                        : undefined,
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('transaction.transactionSearchLoadingState') }
                }}
                filterOption={{
                    initialFilterValue: store.filterOptions,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <TransactionSearchBar
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={store.minimumSearchTextLength()}
                                countries={countries}
                            />
                        );
                    }
                }}
                onRowClick={(row): void => {
                    navigate(`/transaction/search/details?transactionId=${row[1].data}`);
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </Stack>
    );
});
