import { removePersistedUserPrivileges } from './../user/UserPrivileges';
import { createUserStore } from './../user/UserStore';
import { types, Instance, getParent } from 'mobx-state-tree';
import { createTransactionStore, TransactionStore } from '../transaction/store/TransactionStore';
import {
    createGenericFundTransferStore,
    GenericFundTransferStore
} from '../generic-funds-transfer/store/GenericFundTransferStore';
import { BOUserStore, createBOUserStore } from '../bo-users/store/BOUserStore';
import { BCNUserStore, createBCNUserStore } from '../bcn-users/store/BCNUserStore';
import { createReportStore, ReportStore } from '../reports/store/ReportStore';
import { UserStore } from '../user/UserStore';
import { AuditLogsStore, createAuditLogsStore } from '../audit-logs/store/AuditLogsStore';
import { createSignOutStore, SignOutStore } from '../sign-out/store/SignOutStore';
import { RootStore } from '../../store/root/store/RootStore';
import { createProfileStore, ProfileStore } from '../profile/store/ProfileStore';
import {
    createUnclaimedFundsStore,
    UnclaimedFundsStore
} from '../unclaimed-funds/store/UnclaimedFundsStore';
import {
    AgencyBankingStore,
    createAgencyBankingStore
} from '../agent-banking/store/AgencyBankingStore';
import { createFeeRulesStore, FeeRulesStore } from '../fee-rules/store/FeeRulesStore';
import {
    TransactionRulesStore,
    createTransactionRulesStore
} from '../transaction-rules/store/TransactionRulesStore';
import { BreadcrumbStore, createBreadcrumbStore } from '../breadcrumb/store/BreadcrumbStore';

export const HomeStore = types
    .model({
        user: UserStore,
        feeRules: FeeRulesStore,
        transactionRules: TransactionRulesStore,
        transaction: TransactionStore,
        genericFundTransfer: GenericFundTransferStore,
        unclaimedFunds: UnclaimedFundsStore,
        boUsers: BOUserStore,
        bcnUsers: BCNUserStore,
        report: ReportStore,
        auditLogs: AuditLogsStore,
        profile: ProfileStore,
        signOut: SignOutStore,
        agentBanking: AgencyBankingStore,
        breadcrumbStore: BreadcrumbStore
    })
    .actions((store) => ({
        signOutUser(): void {
            removePersistedUserPrivileges();
            const rootStore = getParent<typeof RootStore>(store);
            rootStore.signOutUser();
        },
        setUserPrivileges(userId: string, privileges: string[]): void {
            store.user.setPrivileges(userId, privileges);
        }
    }));

export const createHomeStore = (): Instance<typeof HomeStore> => {
    return HomeStore.create({
        user: createUserStore(),
        feeRules: createFeeRulesStore(),
        transactionRules: createTransactionRulesStore(),
        transaction: createTransactionStore(),
        genericFundTransfer: createGenericFundTransferStore(),
        unclaimedFunds: createUnclaimedFundsStore(),
        boUsers: createBOUserStore(),
        bcnUsers: createBCNUserStore(),
        report: createReportStore(),
        auditLogs: createAuditLogsStore(),
        profile: createProfileStore(),
        signOut: createSignOutStore(),
        agentBanking: createAgencyBankingStore(),
        breadcrumbStore: createBreadcrumbStore()
    });
};
