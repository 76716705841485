import { AgentBasicDetails, BOGender, UserStatus } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getDateString } from '../../common/utils/DateUtils';

export const AgentBasicDetailsModel = types.model({
    profilePhoto: types.maybeNull(types.string),
    firstName: types.string,
    lastName: types.maybeNull(types.string),
    otherNames: types.maybeNull(types.string),
    userId: types.string,
    status: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    phoneNumber: types.string,
    emailId: types.maybeNull(types.string),
    dateOfBirth: types.string,
    gender: types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender)),
    shopName: types.string,
    shopPhoto: types.maybeNull(types.string),
    nationalId: types.string,
    walletName: types.maybeNull(types.string),
    walletId: types.string
});

export const getAgentBasicDetailsModel = (
    agentDetails: AgentBasicDetails
): Instance<typeof AgentBasicDetailsModel> => {
    return AgentBasicDetailsModel.create({
        profilePhoto: agentDetails.profilePhoto && getImageUrl(agentDetails.profilePhoto),
        firstName: agentDetails.username.firstName.text,
        lastName: agentDetails.username.lastName?.text,
        otherNames: agentDetails.otherNames?.text,
        userId: agentDetails.userId.uuid,
        status: agentDetails.status,
        phoneNumber: agentDetails.phoneNumber.phoneNumber,
        emailId: agentDetails.emailId?.emailId ?? null,
        dateOfBirth: getDateString(new Date(agentDetails.dateOfBirth.date)),
        gender: agentDetails.gender,
        shopName: agentDetails.shopName,
        shopPhoto: agentDetails.shopPhoto?.toString() ?? null,
        nationalId: agentDetails.nationalId,
        walletName: agentDetails.walletName,
        walletId: agentDetails.walletId.uuid
    });
};
