import { getFormattedStringFromNumber } from '../../../utils/UIUtils';

/**
 * Function to get the formatted amount with separators and allows upto 4 decimal
 * @param value - Value of the amount to be formatted
 * @returns - Formatted amount of type string
 */
export const getFormattedAmount = (value: string, isDecimalAllowed: boolean): string => {
    // Remove non-digit and non-decimal characters
    let formattedValue = value.replace(/[^\d.]/g, '');
    // Remove extra decimal points
    formattedValue = formattedValue.replace(/(\..*)\./g, '$1');
    if (isDecimalAllowed) {
        // Limit decimal points to two
        const decimalIndex = formattedValue.indexOf('.');
        if (decimalIndex !== -1) {
            const decimalPart = formattedValue.substring(decimalIndex + 1, decimalIndex + 3);
            formattedValue = formattedValue.substring(0, decimalIndex + 1) + decimalPart;
        }
    } else {
        // Remove decimal point if not allowed
        formattedValue = formattedValue.replace('.', '');
    }
    return formattedValue;
};

export const parseFormattedAmount = (value: string): string => {
    // This is done to ensure that if the string contains a decimal it should be shown to the user correctly.
    // On entering 3000., the original function was converting the string to 3000 and as a result 3000.1 was being converted it to 30001 and the decimal was not added, so the string is updated to ensure that the decimal is persisted
    let isDecimalPresent = false;
    if (value === '') return '';
    if (value.at(-1) === '.') {
        isDecimalPresent = true;
    }
    const _value = value.replace(',', '');
    const amount = Number(_value);
    if (isNaN(amount)) return value;
    const output = getFormattedStringFromNumber(amount);
    if (isDecimalPresent) {
        return output + '.';
    } else {
        return output;
    }
};
