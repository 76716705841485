import { RegenerateAuthCodesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRegenerateAuthCodesRPC extends RegenerateAuthCodesRPC {
    execute(
        _request: RegenerateAuthCodesRPC.Request
    ): Promise<
        LeoRPCResult<RegenerateAuthCodesRPC.Response, RegenerateAuthCodesRPC.Errors.Errors>
    > {
        const response = new RegenerateAuthCodesRPC.Response(
            new URL('https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<RegenerateAuthCodesRPC.Response, RegenerateAuthCodesRPC.Errors.Errors>
        >;
    }
}
