import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KYCDocumentHistoryDetailsModel } from '../models/KYCDocumentHistoryDetailsModel';
import { KYCDocumentHistoryTable } from './KYCDocumentHistoryTable';

interface KYCDocumentHistorySectionProp {
    kycDocumentHistoryDetails: Instance<typeof KYCDocumentHistoryDetailsModel>[];
}

export const KYCDocumentHistorySection = ({
    kycDocumentHistoryDetails
}: KYCDocumentHistorySectionProp): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Section
            title={t('bcnUser.kycDocumentHistory')}
            allowViewChange={false}
            content={[
                <KYCDocumentHistoryTable
                    kycDocumentRequests={kycDocumentHistoryDetails}
                    key="kycRequestDetails"
                />
            ]}
            styleOverrides={{
                padding: '0px',
                margin: '2px 0px',
                borderRadius: '6px'
            }}
        />
    );
};
