import { Grid, Stack, Typography } from '@mui/material';
import { getGraphTableTitle } from '../utils/UIUtils';
import { ReportType } from '@resolut-tech/bcn-rpcs';
import {
    DateRangePickerField,
    DateRangePickerInput,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import enZALocale from 'date-fns/locale/en-ZA';
import { Download } from '../../../../assets/icons/Download';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { Button } from '../../../common/components/Button';

interface ReportFilterProps {
    filter: DateRangePickerInput;
    setFilter: (newFilter: DateRangePickerInput) => void;
    setReportDateRange: React.Dispatch<React.SetStateAction<DateRangePickerInput | null>>;
    reportType: string | null;
    onDownloadClick: (filter: DateRangePickerInput) => void;
}

export const initialReportFilter = {
    startDate: null,
    endDate: null
};

export const ReportFilter = ({
    filter,
    setFilter,
    setReportDateRange,
    reportType,
    onDownloadClick
}: ReportFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const [dateRange, setDateRange] = useState<DateRangePickerInput>(filter);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState(false);

    return (
        <Stack>
            <Stack
                borderBottom={`1px solid ${palette.outline[200]}`}
                sx={{ padding: '16px' }}
                boxSizing="border-box">
                <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                    {getGraphTableTitle(t, reportType as ReportType.ReportType)}
                </Typography>
            </Stack>

            <TableFilterContainer>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <DateRangePickerField
                            value={dateRange}
                            locale={enZALocale}
                            startDateLabel={t('common.selectStartDate')}
                            endDateLabel={t('common.selectEndDate')}
                            maxDate={new Date()}
                            onChange={(newValue: DateRangePickerInput): void => {
                                if (newValue.startDate && newValue.endDate) {
                                    if (newValue.startDate <= newValue.endDate) {
                                        setIsValid(true);
                                    } else {
                                        setIsValid(false);
                                    }
                                }
                                setDateRange(newValue);
                                setReportDateRange(newValue);
                            }}
                            showDivider
                            onError={(startDateError, endDateError): void => {
                                if (startDateError || endDateError) {
                                    setIsValid(false);
                                }
                            }}
                            style={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                        <Button
                            title={t('common.download')}
                            name={t('common.download')}
                            size="medium"
                            variant="outlined-color"
                            icon={<Download />}
                            isLoading={isDownloading}
                            iconPosition="left"
                            onClick={async (): Promise<void> => {
                                setIsDownloading(true);
                                await onDownloadClick(dateRange);
                                setIsDownloading(false);
                            }}
                            style={{ width: '125px' }}
                        />

                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                            <Button
                                name={t('common.resetRange')}
                                title={t('common.resetRange')}
                                size="medium"
                                isDisabled={!(dateRange.startDate ?? dateRange.endDate)}
                                variant="outlined-color"
                                color="error"
                                onClick={(): void => {
                                    setDateRange(initialReportFilter);
                                    setFilter(initialReportFilter);
                                    setReportDateRange(null);
                                }}
                                style={{ outline: 'none' }}
                            />
                            <Button
                                name={t('common.applyRange')}
                                title={t('common.applyRange')}
                                isDisabled={!isValid}
                                size="medium"
                                variant="filled"
                                onClick={(): void => {
                                    setFilter(dateRange);
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </TableFilterContainer>
        </Stack>
    );
};
