import { KYCDocumentHistoryDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getDateString } from '../../common/utils/DateUtils';

export const KYCDocumentHistoryDetailsModel = types.model({
    requestId: types.string,
    requestedAt: types.string
});

export const getKYCDocumentHistoryDetailsModel = (
    kycDetails: KYCDocumentHistoryDetails
): Instance<typeof KYCDocumentHistoryDetailsModel> => {
    return KYCDocumentHistoryDetailsModel.create({
        requestId: kycDetails.kycId.uuid,
        requestedAt: getDateString(new Date(kycDetails.requestedAt.timestamp))
    });
};
