import { Instance } from 'mobx-state-tree';
import { TransactionValidationRuleModel } from '../models/TransactionValidationRuleModel';

export const transactionValidationRuleModelToString = (
    rule: Instance<typeof TransactionValidationRuleModel>
): string => {
    return [
        rule.monetaryLimit.toString(),
        rule.periodicLimit.toString(),
        rule.timeWindowInSeconds.toString(),
        rule.cbsId
    ].join(',');
};

export const stringToTransactionValidationRuleModel = (
    rule: string
): Instance<typeof TransactionValidationRuleModel> => {
    const [monetaryLimit, periodicLimit, timeWindowInSeconds, cbsId] = rule.split(',');
    return TransactionValidationRuleModel.create({
        monetaryLimit: Number(monetaryLimit),
        periodicLimit: Number(periodicLimit),
        timeWindowInSeconds: Number(timeWindowInSeconds),
        cbsId
    });
};
