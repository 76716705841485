import {
    Comment,
    CountryCode,
    GetMakerUnclaimedTransactionInfoRPC,
    GetUnclaimedFundsRequestSummaryRPC,
    RequestCBSTransferForUnclaimedFundsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, getParent, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import {
    CBSRequestDetailsModel,
    getCBSRequestDetailsModel
} from '../../common/models/CBSRequestDetailsModel';
import { getLeoDate } from '../../common/utils/DateUtils';
import {
    useGetMakerUnclaimedTransactionInfoRPCClient,
    useGetUnclaimedFundsRequestSummaryRPCClient,
    useRequestCBSTransferForUnclaimedFundsRPCClient
} from '../rpcs/RPC';
import { UnclaimedFundsStore } from './UnclaimedFundsStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { LeoErrors } from '../../common/errors/LeoErrors';

export enum RequestCBSTransferErrors {
    InvalidDateRange = 'INVALID_DATE_RANGE',
    NoUnclaimedTransactionsAvailable = 'NO_UNCLAIMED_TRANSACTIONS_AVAILABLE',
    ProcessingRequestFound = 'PROCESSING_REQUEST_FOUND',
    InvalidComment = 'INVALID_COMMENT'
}

export const RequestCBSTransferStore = types
    .model({
        startDate: types.maybeNull(types.Date),
        endDate: types.Date,
        countryCode: types.maybeNull(types.string),
        totalTransaction: types.maybeNull(types.number),
        totalAmount: types.maybeNull(AmountModel),
        comment: types.maybeNull(types.string),
        requestDetails: types.maybeNull(CBSRequestDetailsModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<RequestCBSTransferErrors>(
                    'RequestCBSTransferErrors',
                    Object.values(RequestCBSTransferErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setComment(comment: string | null): void {
            store.comment = comment;
        },
        resetStore(): void {
            store.startDate = null;
            store.countryCode = null;
            store.totalTransaction = null;
            store.totalAmount = null;
            store.comment = null;
            store.error = null;
        },
        setupStore(): void {
            const unclaimedFund = getParent<typeof UnclaimedFundsStore>(store);
            store.startDate = unclaimedFund.unclaimedFundsListStore.startDate;
            store.endDate = unclaimedFund.unclaimedFundsListStore.endDate;
            store.countryCode = unclaimedFund.unclaimedFundsListStore.countryCode;
        },
        requestCBSTransferForUnclaimedFunds: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.comment && store.countryCode) {
                    const request = new RequestCBSTransferForUnclaimedFundsRPC.Request(
                        store.startDate ? getLeoDate(store.startDate) : null,
                        getLeoDate(store.endDate),
                        new CountryCode(store.countryCode),
                        new Comment(store.comment)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        RequestCBSTransferForUnclaimedFundsRPC.Response,
                        RequestCBSTransferForUnclaimedFundsRPC.Errors.Errors
                    > = yield useRequestCBSTransferForUnclaimedFundsRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case RequestCBSTransferErrors.InvalidDateRange:
                                store.error = RequestCBSTransferErrors.InvalidDateRange;
                                break;
                            case RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable:
                                store.error =
                                    RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable;
                                break;
                            case RequestCBSTransferErrors.ProcessingRequestFound:
                                store.error = RequestCBSTransferErrors.ProcessingRequestFound;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in RequestCBSTransferForUnclaimedFundsRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in RequestCBSTransferForUnclaimedFundsRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        `Could not find comment or countryCode in RequestCBSTransferForUnclaimedFundsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidCommentError:
                            store.error = RequestCBSTransferErrors.InvalidComment;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestCBSTransferForUnclaimedFundsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in RequestCBSTransferForUnclaimedFundsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        getMakerUnclaimedTransactionInfo: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                if (store.countryCode) {
                    const request = new GetMakerUnclaimedTransactionInfoRPC.Request(
                        store.startDate ? getLeoDate(store.startDate) : null,
                        getLeoDate(store.endDate),
                        new CountryCode(store.countryCode)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        GetMakerUnclaimedTransactionInfoRPC.Response,
                        GetMakerUnclaimedTransactionInfoRPC.Errors.Errors
                    > = yield useGetMakerUnclaimedTransactionInfoRPCClient(apiClient).execute(
                        request
                    );
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        store.totalTransaction = response.numberOfTransactions;
                        store.totalAmount = getAmountModel(response.totalAmount);
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case RequestCBSTransferErrors.InvalidDateRange:
                                store.error = RequestCBSTransferErrors.InvalidDateRange;
                                break;
                            case RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable:
                                store.error =
                                    RequestCBSTransferErrors.NoUnclaimedTransactionsAvailable;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in GetMakerUnclaimedTransactionInfoRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in GetMakerUnclaimedTransactionInfoRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        'Cannot find countryCode in GetMakerUnclaimedTransactionInfoRPC'
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetMakerUnclaimedTransactionInfoRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        }),
        getUnclaimedFundsRequestSummary: flow(function* () {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetUnclaimedFundsRequestSummaryRPC.Request();
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetUnclaimedFundsRequestSummaryRPC.Response,
                    GetUnclaimedFundsRequestSummaryRPC.Errors.Errors
                > = yield useGetUnclaimedFundsRequestSummaryRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.requestDetails = response.requestDetails
                        ? getCBSRequestDetailsModel(response.requestDetails)
                        : null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestSummaryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetUnclaimedFundsRequestSummaryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestSummaryRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createRequestCBSTransferStore = (): Instance<typeof RequestCBSTransferStore> => {
    return RequestCBSTransferStore.create({
        endDate: new Date()
    });
};
