import React, { useEffect } from 'react';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { useRootStore } from '../store/RootStore';
import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingState } from '../../../home/common/components/LoadingState';
import { useNetworkingErrorStore } from '../../../networking/store/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { useUserStore } from '../../../home/store/hooks';
import { homeRoutes } from '../../../../routes';
import { useLoggerStore } from '../../../../log/hooks';

export const RootPage = observer((): React.ReactElement => {
    const palette = usePalette();
    const store = useRootStore();
    const userStore = useUserStore();
    const navigate = useNavigate();
    const errorStore = useNetworkingErrorStore();
    const loggerStore = useLoggerStore();

    useEffect(() => {
        if (errorStore.error) {
            switch (errorStore.error) {
                case NetworkingError.InternalServerError:
                    navigate('/500');
                    break;
                case NetworkingError.PageNotFound:
                    navigate('/404');
                    break;
                case NetworkingError.RPCError:
                    navigate('/auth');
                    break;
            }
            errorStore.removeError();
        }
    }, [errorStore.error]);

    const handleInitialStartState = async (): Promise<void> => {
        if (!store.isUserLoggedIn) {
            navigate('/auth');
        } else {
            await store.setupUserPrivileges();
            const privileges = userStore.privileges;
            const _homeRoutes = homeRoutes(privileges);
            const _childrenHomeRoutes = _homeRoutes[0].children;
            if (_childrenHomeRoutes) {
                const firstChildRoute = _childrenHomeRoutes[0];
                // This is done to handle the situation in which the child contains further sub-children ( Since the sidebar support sub-items )
                if (firstChildRoute.children?.length && firstChildRoute.children?.length > 0) {
                    const routeToNavigate = firstChildRoute.children[0].path;
                    if (routeToNavigate) {
                        navigate(routeToNavigate);
                    } else {
                        loggerStore.debug(
                            `Could not find route for privileges - ${privileges} hence the user is navigated to profile screen`
                        );
                        // To avoid a bad user experience, we are taking the user to the profile screen if no paths are found for the children routes
                        navigate('/profile-settings');
                    }
                } else {
                    const routeToNavigate = firstChildRoute.path;
                    if (routeToNavigate) {
                        navigate(routeToNavigate);
                    } else {
                        loggerStore.debug(
                            `Could not find route for privileges - ${privileges} hence the user is navigated to profile screen`
                        );
                        // To avoid a bad user experience, we are taking the user to the profile screen if no paths are found for the children routes
                        navigate('/profile-settings');
                    }
                }
            } else {
                loggerStore.debug(
                    `There are no paths present in routes for privileges - ${privileges} hence the user is taken back to the auth workflow`
                );
                navigate('/auth');
            }
        }
    };

    useEffect(() => {
        handleInitialStartState();
    }, []);

    const RootContainer = styled(Box)({
        backgroundColor: palette.background[300],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
    });

    return (
        <RootContainer>
            <LoadingState />
        </RootContainer>
    );
});
