import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import {
    PasswordInputField,
    Section,
    usePalette,
    useTypography
} from '@surya-digital/leo-reactjs-ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useChangePasswordStore, useRegenerateAuthCodesStore } from '../store/hooks';
import { useSignOutStore } from '../../store/hooks';
import { observer } from 'mobx-react';
import { ChangePasswordErrors } from '../store/ChangePasswordStore';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { RegenerateAuthCodesErrors } from '../store/RegenerateAuthCodesStore';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { Dialog } from '../../../common/components/Dialog';
import { Button } from '../../../common/components/Button';

interface ProfileManagementSectionProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO:- we are proceeding with the current implementation since we are running short on time. Once we are done with the project, we will come back to this and try to break this into smaller components
export const ProfileManagementSection = observer(
    ({ setIsLoading }: ProfileManagementSectionProps): React.ReactElement => {
        const { t } = useTranslation();
        const typography = useTypography();
        const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
        const [isRegenerateAuthCodesDialogOpen, setIsRegenerateAuthCodesDialogOpen] =
            useState(false);
        const regenerateAuthCodesStore = useRegenerateAuthCodesStore();
        const navigate = useNavigate();
        const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
        const palette = usePalette();
        const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);
        const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
        const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
        const [isPrimaryButtonEnable, setIsPrimaryButtonEnable] = useState<boolean>(false);
        const [isChangePasswordSuccessfulDialogOpen, setIsChangePasswordSuccessfulDialogOpen] =
            useState<boolean>(false);
        const signOutStore = useSignOutStore();
        const changePasswordStore = useChangePasswordStore();
        const [errorMessage, setErrorMessage] = useState<string | null>();
        const [authCodePasswordError, setAuthCodePasswordError] = useState<boolean>(false);

        // since all the error are handled for change password so null is used.
        const getNewPasswordErrorHelperText = (): string | null => {
            switch (changePasswordStore.error) {
                case ChangePasswordErrors.InsecurePassword:
                    return t('signIn.insecurePassword');
                case ChangePasswordErrors.InvalidPasswordError:
                    return t('signIn.invalidPassword');
                case ChangePasswordErrors.PasswordMismatch:
                    return t('profileSetting.profileManagement.passwordDoesNotMatch');
                default:
                    return null;
            }
        };

        const getOldPasswordErrorHelperText = (): string | null => {
            switch (changePasswordStore.error) {
                case ChangePasswordErrors.ReusedPassword:
                    return t('signIn.reusedPassword');
                case ChangePasswordErrors.IncorrectOldPassword:
                    return `${t('common.invalidPassword')}, ${t('common.attemptLeft', {
                        numberOfAttemptLeft: changePasswordStore.numberOfValidAttemptsLeft
                    })}`;
                default:
                    return null;
            }
        };

        const getConfirmPasswordErrorHelperText = (): string | null => {
            if (changePasswordStore.error === ChangePasswordErrors.PasswordMismatch) {
                return t('profileSetting.profileManagement.passwordDoesNotMatch');
            } else {
                return null;
            }
        };

        const PasswordPolicyComponent = (): React.ReactElement => {
            const passwordPolicy = changePasswordStore.getPasswordPolicy();
            if (passwordPolicy) {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '24px',
                            width: '360px'
                        }}>
                        <Typography sx={{ ...typography.small2 }}>
                            {passwordPolicy.title}
                        </Typography>
                        {passwordPolicy.description.map((desc, index) => {
                            return (
                                <List
                                    sx={{ listStyleType: 'disc', padding: 0, marginLeft: '20px' }}
                                    key={index}>
                                    <ListItem
                                        key={index}
                                        sx={{
                                            padding: 0,
                                            display: 'list-item',
                                            ...typography.body2
                                        }}>
                                        {desc}
                                    </ListItem>
                                </List>
                            );
                        })}
                    </Box>
                );
            } else {
                return <></>;
            }
        };

        const setErrorToFalse = (): void => {
            setOldPasswordError(false);
            setNewPasswordError(false);
            setConfirmPasswordError(false);
            changePasswordStore.removeError();
        };

        const onOldPasswordChange = (value: string): void => {
            setErrorToFalse();
            changePasswordStore.setOldPassword(value);
        };

        const onNewPasswordChange = (value: string): void => {
            setErrorToFalse();
            changePasswordStore.setNewPassword(value);
        };

        const onConfirmPasswordChange = (value: string): void => {
            setErrorToFalse();
            changePasswordStore.setConfirmNewPassword(value);
        };

        useEffect(() => {
            setIsPrimaryButtonEnable(
                Boolean(changePasswordStore.oldPassword) &&
                    Boolean(changePasswordStore.newPassword) &&
                    Boolean(changePasswordStore.confirmNewPassword)
            );
        }, [
            changePasswordStore.confirmNewPassword,
            changePasswordStore.newPassword,
            changePasswordStore.oldPassword
        ]);

        const PasswordTextFields = (): React.ReactElement => {
            return (
                <>
                    <PasswordInputField
                        name="oldPassword"
                        value={changePasswordStore.oldPassword ?? undefined}
                        onTextChange={onOldPasswordChange}
                        isRequired={true}
                        label={t('profileSetting.profileManagement.currentPassword')}
                        helperText={getOldPasswordErrorHelperText() ?? undefined}
                        helperIcon={oldPasswordError ? <AlertCircle /> : undefined}
                        error={oldPasswordError}
                        style={{
                            width: '360px'
                        }}
                    />
                    <PasswordInputField
                        name="newPassword"
                        value={changePasswordStore.newPassword ?? undefined}
                        onTextChange={onNewPasswordChange}
                        isRequired={true}
                        label={t('signIn.newPassword')}
                        helperText={getNewPasswordErrorHelperText() ?? undefined}
                        helperIcon={newPasswordError ? <AlertCircle /> : undefined}
                        error={newPasswordError}
                        style={{
                            width: '360px',
                            marginTop: '20px'
                        }}
                    />
                    <PasswordInputField
                        name="confirmNewPassword"
                        value={changePasswordStore.confirmNewPassword ?? undefined}
                        onTextChange={onConfirmPasswordChange}
                        isRequired={true}
                        label={t('signIn.confirmNewPassword')}
                        helperIcon={confirmPasswordError ? <AlertCircle /> : undefined}
                        helperText={getConfirmPasswordErrorHelperText() ?? undefined}
                        error={confirmPasswordError}
                        style={{
                            width: '360px',
                            marginTop: '20px'
                        }}
                    />
                </>
            );
        };

        const handleErrors = async (): Promise<void> => {
            switch (changePasswordStore.error) {
                case ChangePasswordErrors.InsecurePassword:
                case ChangePasswordErrors.InvalidPasswordError:
                    setNewPasswordError(true);
                    break;
                case ChangePasswordErrors.IncorrectOldPassword:
                case ChangePasswordErrors.ReusedPassword:
                    setOldPasswordError(true);
                    break;
                case ChangePasswordErrors.PasswordMismatch:
                    setNewPasswordError(true);
                    setConfirmPasswordError(true);
                    break;
                case ChangePasswordErrors.TooManyRequests:
                    setIsChangePasswordDialogOpen(false);
                    setErrorMessage(
                        t('profileSetting.profileManagement.reachedTheMaxLimitPleaseContactAdmin')
                    );
                    setIsErrorDialogOpen(true);
                    break;
                case ChangePasswordErrors.TooManyIncorrectAttempts:
                    setIsChangePasswordDialogOpen(false);
                    setIsLoading(true);
                    await signOutStore.signOutUser();
                    navigate('/');
                    setIsLoading(false);
                    break;
                case NetworkingError.InternalError:
                    setIsChangePasswordDialogOpen(false);
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    break;
                default:
                    setIsChangePasswordDialogOpen(false);
                    setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
            }
        };

        const onChangePasswordButtonClick = async (): Promise<void> => {
            setIsChangePasswordDialogOpen(false);
            setIsLoading(true);
            await changePasswordStore.submitChangePassword();
            setIsLoading(false);
            if (changePasswordStore.error) {
                setIsChangePasswordDialogOpen(true);
                handleErrors();
            } else {
                setIsChangePasswordSuccessfulDialogOpen(true);
            }
        };

        const ChangePasswordDialog = (): React.ReactElement => {
            return (
                <Box sx={{ width: '560px' }}>
                    <Dialog
                        open={isChangePasswordDialogOpen}
                        title={t('signIn.changePassword')}
                        primaryButtonText={t('signIn.changePassword')}
                        onPrimaryButtonClick={async (): Promise<void> => {
                            await onChangePasswordButtonClick();
                        }}
                        isPrimaryButtonDisabled={!isPrimaryButtonEnable}
                        secondaryButtonText={t('common.cancel')}
                        onSecondaryButtonClick={(): void => {
                            changePasswordStore.resetStore();
                            setErrorToFalse();
                            setIsChangePasswordDialogOpen(false);
                        }}
                        disableBackdropClick={true}>
                        <Stack direction="column" alignItems="center">
                            {PasswordTextFields()}
                            {PasswordPolicyComponent()}
                        </Stack>
                    </Dialog>
                </Box>
            );
        };

        const onDialogClose = async (): Promise<void> => {
            await signOutStore.signOutUser();
            navigate('/');
        };

        const PasswordChangedSuccessfullyDialog = (): React.ReactElement => {
            return (
                <Box sx={{ width: '560px' }}>
                    <SuccessDialog
                        title={t('signIn.changePassword')}
                        successMessage={t(
                            'profileSetting.profileManagement.passwordChangedSuccessfully'
                        )}
                        isDialogOpen={isChangePasswordSuccessfulDialogOpen}
                        onCancel={onDialogClose}
                        secondaryButtonLabel={t('common.signOut')}
                        isCloseIconPresent={false}
                    />
                </Box>
            );
        };

        const ErrorDialogBox = (): React.ReactElement => {
            return (
                <ErrorDialog
                    isErrorDialogOpen={isErrorDialogOpen}
                    onClose={function (): void {
                        if (changePasswordStore.error) {
                            changePasswordStore.resetStore();
                            setErrorToFalse();
                        }
                        setIsErrorDialogOpen(false);
                    }}
                    title={t('common.profileSettings')}
                    errorMessage={errorMessage}
                />
            );
        };

        const onDownloadFile = async (): Promise<void> => {
            setIsRegenerateAuthCodesDialogOpen(false);
            setIsLoading(true);
            await regenerateAuthCodesStore.downloadFile();
            setIsLoading(false);
            if (regenerateAuthCodesStore.error) {
                switch (regenerateAuthCodesStore.error) {
                    case RegenerateAuthCodesErrors.IncorrectPassword:
                    case RegenerateAuthCodesErrors.InvalidPassWord:
                        setIsRegenerateAuthCodesDialogOpen(true);
                        setAuthCodePasswordError(true);
                        break;
                    case RegenerateAuthCodesErrors.TooManyIncorrectAttempts:
                        setIsLoading(true);
                        await signOutStore.signOutUser();
                        navigate('/');
                        setIsLoading(false);
                        break;
                    case RegenerateAuthCodesErrors.CouldNotGenerateCodes:
                        setErrorMessage(t('common.somethingWentWrongDuringDownload'));
                        setIsErrorDialogOpen(true);
                        break;
                    case RegenerateAuthCodesErrors.TooManyRequests:
                        setErrorMessage(
                            t(
                                'profileSetting.profileManagement.reachedTheMaxLimitPleaseContactAdmin'
                            )
                        );
                        setIsErrorDialogOpen(true);
                        break;
                    case NetworkingError.InternalError:
                        setErrorMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                        break;
                    default:
                        setIsErrorDialogOpen(true);
                }
            } else {
                setIsLoading(true);
                await signOutStore.signOutUser();
                navigate('/');
                setIsLoading(false);
            }
        };

        const onAuthCodePasswordChange = (value: string): void => {
            setAuthCodePasswordError(false);
            regenerateAuthCodesStore.removeError();
            regenerateAuthCodesStore.setPassword(value);
        };

        const getAuthCodePasswordErrorHelperText = (): string | undefined => {
            switch (regenerateAuthCodesStore.error) {
                case RegenerateAuthCodesErrors.IncorrectPassword:
                    return `${t('common.invalidPassword')}, ${t('common.attemptLeft', {
                        numberOfAttemptLeft: regenerateAuthCodesStore.numberOfValidAttemptsLeft
                    })}`;
                case RegenerateAuthCodesErrors.InvalidPassWord:
                    return t('common.invalidPassword');
                default:
                    return undefined;
            }
        };

        const RegenerateAuthCodesDialog = (): React.ReactElement => {
            return (
                <Box sx={{ width: '560px' }}>
                    <Dialog
                        open={isRegenerateAuthCodesDialogOpen}
                        title={t('profileSetting.profileManagement.regenerateAuthCodes')}
                        primaryButtonText={t('common.downloadFile')}
                        isPrimaryButtonDisabled={
                            !regenerateAuthCodesStore.password ||
                            regenerateAuthCodesStore.password.trim().length === 0 ||
                            Boolean(regenerateAuthCodesStore.error)
                        }
                        onPrimaryButtonClick={async (): Promise<void> => {
                            await onDownloadFile();
                        }}
                        secondaryButtonText={t('common.cancel')}
                        onSecondaryButtonClick={(): void => {
                            regenerateAuthCodesStore.resetStore();
                            setAuthCodePasswordError(false);
                            setIsRegenerateAuthCodesDialogOpen(false);
                        }}
                        disableBackdropClick={true}>
                        <Stack spacing={'16px'}>
                            <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                                {t(
                                    'profileSetting.profileManagement.regenerateAuthCodesDownloadFileTitle'
                                )}
                            </Typography>
                            <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                                {t('profileSetting.profileManagement.enterPasswordToProceed')}
                            </Typography>
                            <PasswordInputField
                                name="password"
                                value={regenerateAuthCodesStore.password ?? undefined}
                                onTextChange={onAuthCodePasswordChange}
                                label={t('profileSetting.profileManagement.currentPassword')}
                                helperText={getAuthCodePasswordErrorHelperText()}
                                helperIcon={authCodePasswordError ? <AlertCircle /> : undefined}
                                error={authCodePasswordError}
                                style={{
                                    width: '360px',
                                    marginTop: '12px'
                                }}
                            />
                        </Stack>
                    </Dialog>
                </Box>
            );
        };

        return (
            <>
                <Section
                    title={t('common.profileManagement')}
                    allowViewChange={false}
                    content={[
                        <Box key="profileManagement" padding="16px">
                            <Box>
                                <Button
                                    name="secondaryButton"
                                    variant="outlined-neutral"
                                    size="small"
                                    title={t('signIn.changePassword')}
                                    onClick={(): void => {
                                        setIsChangePasswordDialogOpen(true);
                                    }}
                                />
                                <Typography
                                    sx={{
                                        ...typography.body2,
                                        marginTop: '8px',
                                        marginBottom: '16px'
                                    }}>
                                    {t('profileSetting.profileManagement.changePasswordSubtitle')}
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    name="secondaryButton"
                                    variant="outlined-neutral"
                                    size="small"
                                    title={t(
                                        'profileSetting.profileManagement.regenerateAuthCodes'
                                    )}
                                    onClick={(): void => {
                                        setIsRegenerateAuthCodesDialogOpen(true);
                                    }}
                                />
                                <Typography sx={{ ...typography.body2, marginTop: '8px' }}>
                                    {t(
                                        'profileSetting.profileManagement.regenerateAuthCodesSubtitle'
                                    )}
                                </Typography>
                            </Box>
                            {isChangePasswordDialogOpen && ChangePasswordDialog()}
                            {isChangePasswordSuccessfulDialogOpen &&
                                PasswordChangedSuccessfullyDialog()}
                            {isErrorDialogOpen && ErrorDialogBox()}
                            {isRegenerateAuthCodesDialogOpen && RegenerateAuthCodesDialog()}
                        </Box>
                    ]}
                />
            </>
        );
    }
);
