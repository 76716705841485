import {
    CountryModel,
    CountryViewModel,
    CountryListStore,
    createCountryListStore
} from '../../store/country-list/CountryListStore';
import { CommonAuthErrors } from '../errors/CommonAuthErrors';
import { Password, SignInBOUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoPhoneNumber, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { useSignInBoUserRPCClient } from '../rpcs/RPC';
import { getPhoneNumberWithCountryCode } from '../../home/common/utils/UIUtils';
import { getAPIClient } from '../../networking/store/NetworkingStore';
import { LeoErrors } from '../../home/common/errors/LeoErrors';
import { getLoggerStore } from '../../../log/hooks';
import { LoggerStore } from '../../../log/LoggerStore';
import { NetworkingError } from '../../error/store/ErrorStore';

export enum MobileNumberEntryErrors {
    InvalidCredentials = 'INVALID_CREDENTIALS'
}

const getStoreError = (
    error: SignInBOUserRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): MobileNumberEntryErrors | CommonAuthErrors | NetworkingError => {
    switch (error.code) {
        case MobileNumberEntryErrors.InvalidCredentials:
            return MobileNumberEntryErrors.InvalidCredentials;
        case CommonAuthErrors.BoUserDisabled:
            return CommonAuthErrors.BoUserDisabled;
        case CommonAuthErrors.SignInTemporarilyBlocked:
            return CommonAuthErrors.SignInTemporarilyBlocked;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in MobileNumberEntryStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): LeoErrors | CommonAuthErrors | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidLeoPhoneNumberError:
            return LeoErrors.InvalidLeoPhoneNumberError;
        case LeoErrors.InvalidPasswordError:
            return LeoErrors.InvalidPasswordError;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in MobileNumberEntryStore`);
            return NetworkingError.InternalError;
    }
};

export const MobileNumberEntryStore = types
    .model({
        countryListStore: CountryListStore,
        country: types.maybeNull(CountryModel),
        phoneNumber: types.string,
        password: types.string,
        passwordValidatedToken: types.maybeNull(types.string),
        authCodeLHS: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<MobileNumberEntryErrors>(
                    'MobileNumberEntryErrors',
                    Object.values(MobileNumberEntryErrors)
                ),
                types.enumeration<CommonAuthErrors>(
                    'CommonAuthErrors',
                    Object.values(CommonAuthErrors)
                ),
                types.enumeration<LeoErrors>('LeoErrors', Object.values(LeoErrors)),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        resetStore(): void {
            store.country = null;
            store.phoneNumber = '';
            store.password = '';
            store.passwordValidatedToken = null;
            store.authCodeLHS = null;
            store.error = null;
        },
        setPhoneNumber(phoneNumber: string): void {
            store.phoneNumber = phoneNumber;
        },
        setPassword(password: string): void {
            store.password = password;
        },
        setCountry(country: CountryViewModel): void {
            const _country = store.countryListStore.getCountryModel(country) ?? null;
            if (_country) {
                store.country = CountryModel.create({ ..._country });
            }
        },
        removeError(): void {
            store.error = null;
        },
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        signInBoUser: flow(function* () {
            const loggerStore = getLoggerStore(store);
            if (store.country) {
                try {
                    store.error = null;
                    const _phoneNumberString = getPhoneNumberWithCountryCode(
                        store.country.phoneCode,
                        store.phoneNumber
                    );
                    const _phoneNumber = new LeoPhoneNumber(_phoneNumberString);
                    const _password = new Password(store.password);
                    const request = new SignInBOUserRPC.Request(_phoneNumber, _password.password);
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        SignInBOUserRPC.Response,
                        SignInBOUserRPC.Errors.Errors
                    > = yield useSignInBoUserRPCClient(apiClient).execute(request);

                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        store.passwordValidatedToken = response.passwordValidatedToken.uuid;
                        store.authCodeLHS = response.authCodeLHS;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in SignInBOUserRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        store.error = getLeoError(error, loggerStore);
                    } else {
                        loggerStore.error(`Unknown error: ${error} occurred in SignInBOUserRPC`);
                        store.error = NetworkingError.InternalError;
                    }
                }
            } else {
                loggerStore.debug(
                    `There is no country present in MobileNumberEntryStore while calling SignInBOUserRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views((store) => ({
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        }
    }));

export const createMobileNumberEntryStore = (): Instance<typeof MobileNumberEntryStore> => {
    return MobileNumberEntryStore.create({
        countryListStore: createCountryListStore(),
        phoneNumber: '',
        password: ''
    });
};
