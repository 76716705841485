import { LeoDate } from '@surya-digital/leo-ts-runtime';
import moment from 'moment';

export const getFormattedTimeDateWithComma = (date: Date): string => {
    return moment(date).format('h:mm A, DD/MM/YYYY');
};

export const getDateString = (date: Date): string => {
    return moment(date).format('DD/MM/YYYY');
};

export const getLeoDate = (date: Date): LeoDate => {
    return new LeoDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
};

export const getFormattedTimeFromSeconds = (seconds: number): string => {
    // Ensure that seconds is greater than 0
    if (seconds < 1) {
        return '0';
    }
    const duration = moment.duration(seconds, 'seconds');
    // Define the parts of the duration (years, days, hours, minutes, and seconds)
    const parts = [
        { value: duration.years(), label: 'year' },
        { value: duration.days(), label: 'day' },
        { value: duration.hours(), label: 'hour' },
        { value: duration.minutes(), label: 'minute' },
        { value: duration.seconds(), label: 'second' }
    ];
    // Filter out any parts with a value of 0, format each part as a string, and join them together
    const formattedParts = parts
        .filter(({ value }) => value > 0)
        .map(({ value, label }) => `${value} ${label}${value > 1 ? 's' : ''}`)
        .join(' ');
    // Return the formatted duration string
    return formattedParts;
};
