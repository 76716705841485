import { GetReportFileRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetReportFileRPCImpl extends GetReportFileRPC {
    execute(
        _request: GetReportFileRPC.Request
    ): Promise<LeoRPCResult<GetReportFileRPC.Response, GetReportFileRPC.Errors.Errors>> {
        const response = new GetReportFileRPC.Response(
            new URL('https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetReportFileRPC.Response, GetReportFileRPC.Errors.Errors>
        >;
    }
}
