import { LocalizedText } from '@resolut-tech/bcn-rpcs';
import {
    UnclaimedTransactionDetail,
    UnclaimedTransactionDetailEnums
} from '@resolut-tech/bcn-rpcs/build/back-office/unclaimedTransactionDetail';
import { types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';

export interface UnclaimedTransactionDetailView {
    label: string;
    cellType: UnclaimedTransactionDetailEnums.CellType.CellType;
}

// The LocalizedText that we get from the rpc is converted to en string while parsing the response data to UnclaimedTransactionDetailView type from UnclaimedTransactionDetail type.
// localizedLabel variable holds this LocalizedText so that the value can be used while converting the data back to the type UnclaimedTransactionDetail.
let localizedLabel: LocalizedText;

// types.custom needs fromSnapshot and toSnapshot methods are mandatory for using types.custom.
// Since CellType.CellType enum is a complex types which is not a just string enum. It would be difficult to create model for it. Hence creating a custom type.
export const UnclaimedTransactionDetailModel = types.custom<
    UnclaimedTransactionDetail,
    UnclaimedTransactionDetailView
>({
    name: 'UnclaimedTransactionDetailType',
    fromSnapshot(snapshot: UnclaimedTransactionDetail): UnclaimedTransactionDetailView {
        localizedLabel = snapshot.label;
        return {
            label: getTranslatedString(snapshot.label),
            cellType: snapshot.cellType
        };
    },
    toSnapshot(value: UnclaimedTransactionDetailView): UnclaimedTransactionDetail {
        return new UnclaimedTransactionDetail(localizedLabel, value.cellType);
    },
    isTargetType(value: UnclaimedTransactionDetail | UnclaimedTransactionDetail): boolean {
        return !(value instanceof UnclaimedTransactionDetail);
    },
    getValidationMessage(_snapshot: UnclaimedTransactionDetail): string {
        return '';
    }
});
