import { Comment, WithdrawRefundRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { types, flow, Instance } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { useWithdrawRefundRequestRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { LoggerStore } from '../../../../log/LoggerStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RefundWithdrawStoreError {
    InvalidComment = 'INVALID_COMMENT'
}

const getStoreError = (
    error: WithdrawRefundRequestRPC.Errors.Errors,
    loggerStore: Instance<typeof LoggerStore>
): WithdrawRequestErrors | NetworkingError => {
    switch (error.code) {
        case WithdrawRequestErrors.InvalidRequestId:
            return WithdrawRequestErrors.InvalidRequestId;
        case WithdrawRequestErrors.RequestAlreadyChecked:
            return WithdrawRequestErrors.RequestAlreadyChecked;
        case WithdrawRequestErrors.RequestAlreadyWithdrawn:
            return WithdrawRequestErrors.RequestAlreadyWithdrawn;
        case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
            return WithdrawRequestErrors.CanOnlyWithdrawSelfRequest;
        case WithdrawRequestErrors.RequestAlreadyDiscarded:
            return WithdrawRequestErrors.RequestAlreadyDiscarded;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in RefundWithdrawRequestStore`);
            return NetworkingError.InternalError;
    }
};

const getLeoError = (
    error: Error,
    loggerStore: Instance<typeof LoggerStore>
): WithdrawRequestErrors | RefundWithdrawStoreError | NetworkingError => {
    switch (error.name) {
        case LeoErrors.InvalidLeoUUIDError:
            return WithdrawRequestErrors.InvalidRequestId;
        case LeoErrors.InvalidCommentError:
            return RefundWithdrawStoreError.InvalidComment;
        default:
            loggerStore.error(`Unhandled error: ${error} occurred in RefundWithdrawRequestStore`);
            return NetworkingError.InternalError;
    }
};

export const RefundWithdrawRequestStore = types
    .model({
        requestId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<RefundWithdrawStoreError>(
                    'RefundWithdrawStoreError',
                    Object.values(RefundWithdrawStoreError)
                ),
                types.enumeration<WithdrawRequestErrors>(
                    'WithdrawRequestErrors',
                    Object.values(WithdrawRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        resetComment(): void {
            store.comment = null;
        },
        withdrawRequest: flow(function* (requestId: string, comment: string) {
            const loggerStore = getLoggerStore(store);
            store.requestId = requestId;
            try {
                if (store.requestId) {
                    const _comment = new Comment(comment);
                    const _requestId = new LeoUUID(requestId);
                    const request = new WithdrawRefundRequestRPC.Request(_requestId, _comment);
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        WithdrawRefundRequestRPC.Response,
                        WithdrawRefundRequestRPC.Errors.Errors
                    > = yield useWithdrawRefundRequestRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        return;
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        store.error = getStoreError(error, loggerStore);
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in WithdrawRefundRequestRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug('requestId not found in WithdrawRefundRequestRPC');
                    store.error = WithdrawRequestErrors.InvalidRequestId;
                }
            } catch (error) {
                if (error instanceof Error) {
                    store.error = getLeoError(error, loggerStore);
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in WithdrawRefundRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createRefundWithdrawRequestStore = (): Instance<typeof RefundWithdrawRequestStore> => {
    return RefundWithdrawRequestStore.create();
};
