import {
    CountryCode,
    FundStatus,
    GetUnclaimedFundListRPC,
    GetUnclaimedFundListSortOrder,
    ItemsPerPage,
    PageIndex
} from '@resolut-tech/bcn-rpcs';
import { GetUnclaimedFundListSortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getUnclaimedFundListSortOrder';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { getPGAmountString } from '../../common/utils/UIUtils';
import { useGetUnclaimedFundsListRPCClient } from '../rpcs/RPC';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma, getLeoDate } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum UnclaimedFundsListErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

const UnclaimedFundsListModel = types.model({
    unclaimedFundId: types.string,
    createdAt: types.string,
    userName: types.string,
    userPhoneNumber: types.string,
    amount: types.string,
    country: types.string
});

export const UnclaimedFundsListFilterOptions = types.model({
    selectedCountryCode: types.string,
    selectedFundStatus: types.string,
    startDate: types.maybeNull(types.Date),
    endDate: types.Date
});

export const UnclaimedFundsListStore = types
    .model({
        // The initial filter options for unclaimed Funds List search table will be dependent on `t` function.
        // The filter options is set as maybe and then updated from the UI.
        filterOptions: types.maybe(UnclaimedFundsListFilterOptions),
        isTableIdle: types.boolean,
        countryListStore: CountryListStore,
        countryCode: types.maybeNull(types.string),
        totalItems: types.number,
        startDate: types.maybeNull(types.Date),
        endDate: types.Date,
        unclaimedFundsList: types.array(UnclaimedFundsListModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<UnclaimedFundsListErrors>(
                    'unclaimedFundsListErrors',
                    Object.values(UnclaimedFundsListErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .views((store) => ({
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        },
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        updateFilterOptions(filterOptions: Instance<typeof UnclaimedFundsListFilterOptions>): void {
            store.isTableIdle = false;
            store.filterOptions = filterOptions;
        },
        removeError(): void {
            store.error = null;
        },
        fetchUnclaimedFundsList: flow(function* (
            filter: Instance<typeof UnclaimedFundsListFilterOptions | undefined>,
            pageIndex: number,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                if (filter) {
                    store.startDate = filter.startDate ?? null;
                    store.endDate = filter.endDate;
                    store.countryCode = filter.selectedCountryCode;
                    const createdAtStartDate = filter.startDate
                        ? getLeoDate(filter.startDate)
                        : null;
                    const createdAtEndDate = getLeoDate(filter.endDate);
                    const request = new GetUnclaimedFundListRPC.Request(
                        [
                            new GetUnclaimedFundListSortOrder(
                                0,
                                GetUnclaimedFundListSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
                                getSortOrder(sortOrder)
                            )
                        ],
                        createdAtStartDate,
                        createdAtEndDate,
                        new CountryCode(filter.selectedCountryCode),
                        filter.selectedFundStatus === FundStatus.FundStatus.MOVED_OUT
                            ? FundStatus.FundStatus.MOVED_OUT
                            : FundStatus.FundStatus.UNCLAIMED,
                        new ItemsPerPage(store.itemsPerPage()),
                        new PageIndex(pageIndex)
                    );
                    const apiClient = getAPIClient(store);
                    const result: LeoRPCResult<
                        GetUnclaimedFundListRPC.Response,
                        GetUnclaimedFundListRPC.Errors.Errors
                    > = yield useGetUnclaimedFundsListRPCClient(apiClient).execute(request);
                    if (result instanceof LeoRPCResult.Response) {
                        const { response } = result;
                        const unclaimedFundList =
                            response.getUnclaimedFundListPaginationResponse.map((unclaimedFund) => {
                                return {
                                    unclaimedFundId: unclaimedFund.unclaimedFundId.uuid,
                                    createdAt: getFormattedTimeDateWithComma(
                                        new Date(unclaimedFund.createdAt.timestamp)
                                    ),
                                    userName: getPGFullName(unclaimedFund.userName),
                                    userPhoneNumber: unclaimedFund.userPhoneNumber.phoneNumber,
                                    amount: getPGAmountString(unclaimedFund.amount),
                                    country: store.countryListStore
                                        .countryList()
                                        .filter((_country: CountryViewModel) => {
                                            return (
                                                _country.countryCode ===
                                                unclaimedFund.countryCode.code
                                            );
                                        })[0].name
                                };
                            });
                        store.totalItems = response.totalItems;
                        store.unclaimedFundsList = cast(unclaimedFundList);
                    } else if (result instanceof LeoRPCResult.Error) {
                        const { error } = result;
                        switch (error.code) {
                            case UnclaimedFundsListErrors.InvalidPageIndex:
                                store.error = UnclaimedFundsListErrors.InvalidPageIndex;
                                break;
                            default:
                                loggerStore.error(
                                    `Unhandled error: ${error} occurred in GetUnclaimedFundListRPC`
                                );
                                store.error = NetworkingError.InternalError;
                        }
                    } else {
                        loggerStore.error(
                            `Unknown error occurred in GetUnclaimedFundListRPC with result: ${result}`
                        );
                        store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.debug(
                        'There are no filters present while calling GetUnclaimedFundListRPC'
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(`Unhandled error: ${error} occurred in GetUnclaimedFundsListRPC`);
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createUnclaimedFundsListStore = (): Instance<typeof UnclaimedFundsListStore> => {
    return UnclaimedFundsListStore.create({
        totalItems: 0,
        unclaimedFundsList: [],
        countryListStore: createCountryListStore(),
        endDate: new Date(),
        isTableIdle: true
    });
};
