import { WithdrawAgentRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockWithdrawAgentRequestRPCImpl extends WithdrawAgentRequestRPC {
    execute(
        _request: WithdrawAgentRequestRPC.Request
    ): Promise<
        LeoRPCResult<WithdrawAgentRequestRPC.Response, WithdrawAgentRequestRPC.Errors.Errors>
    > {
        const response = new WithdrawAgentRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<WithdrawAgentRequestRPC.Response, WithdrawAgentRequestRPC.Errors.Errors>
        >;
    }
}
