import { RequestBOUserRegenerateAuthCodesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestBOUserRegenerateAuthCodesRPCImpl extends RequestBOUserRegenerateAuthCodesRPC {
    execute(
        _request: RequestBOUserRegenerateAuthCodesRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestBOUserRegenerateAuthCodesRPC.Response,
            RequestBOUserRegenerateAuthCodesRPC.Errors.Errors
        >
    > {
        const response = new RequestBOUserRegenerateAuthCodesRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestBOUserRegenerateAuthCodesRPC.Response,
                RequestBOUserRegenerateAuthCodesRPC.Errors.Errors
            >
        >;
    }
}
