import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { RequestToMoveFundsOutOfYafikaRPC } from '@resolut-tech/bcn-rpcs';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestToMoveFundsOutOfYafikaRPCImpl extends RequestToMoveFundsOutOfYafikaRPC {
    execute(
        _request: RequestToMoveFundsOutOfYafikaRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestToMoveFundsOutOfYafikaRPC.Response,
            RequestToMoveFundsOutOfYafikaRPC.Errors.Errors
        >
    > {
        const response = new RequestToMoveFundsOutOfYafikaRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestToMoveFundsOutOfYafikaRPC.Response,
                RequestToMoveFundsOutOfYafikaRPC.Errors.Errors
            >
        >;
    }
}
