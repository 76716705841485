import { GetUserRolesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    getWorkflowDependentRolesModel,
    WorkflowDependentRolesModel
} from '../models/WorkflowDependentRolesModel';
import { useGetUserRolesRPCClient } from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const BORoleStore = types
    .model({
        workflowDependentRoles: types.array(WorkflowDependentRolesModel),
        error: types.maybeNull(
            types.enumeration<NetworkingError>('NetworkingError', Object.values(NetworkingError))
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        getBORoles: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const request = new GetUserRolesRPC.Request();
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<GetUserRolesRPC.Response, GetUserRolesRPC.Errors.Errors> =
                yield useGetUserRolesRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.workflowDependentRoles = cast(
                    response.userRoles.map((workflowDependentRole) =>
                        getWorkflowDependentRolesModel(workflowDependentRole)
                    )
                );
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                loggerStore.error(`Unhandled error: ${error} occurred in GetUserRolesRPC`);
                store.error = NetworkingError.InternalError;
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetUserRolesRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createBORoleStore = (): Instance<typeof BORoleStore> => {
    return BORoleStore.create({
        workflowDependentRoles: []
    });
};
