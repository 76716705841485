import { getPGFullName } from './../../../../utils/StringUtils';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import {
    BOUserChangeRequestType,
    GetBOUserChangeRequestsPaginationResponse,
    GetBOUserChangeRequestsRPC,
    GetBOUserChangeRequestsSortOrder,
    ItemsPerPage,
    PageIndex,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';
import { useGetBOUserChangeRequestsRPCClient } from '../rpcs/RPC';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { RequestStatus as RequestStatusEnum } from '../../common/enums/RequestStatus';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { GetBOUserChangeRequestsSortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getBOUserChangeRequestsSortOrder';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BOUserRequestsErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const BOUserRequest = types.model({
    userId: types.maybeNull(types.string),
    requestId: types.string,
    requestedAt: types.string,
    requestedBy: types.string,
    requestedFor: types.string,
    evaluatedBy: types.string,
    requestType: types.enumeration<BOUserChangeRequestType.BOUserChangeRequestType>(
        'RequestType',
        Object.values(BOUserChangeRequestType.BOUserChangeRequestType)
    ),
    requestStatus: types.enumeration<RequestStatus.RequestStatus>(
        'RequestStatus',
        Object.values(RequestStatus.RequestStatus)
    )
});

export const BOUserRequestsFilterOptions = types.model({
    status: types.string,
    type: types.string
});

export const BOUserRequestsStore = types
    .model({
        filterOptions: BOUserRequestsFilterOptions,
        totalItems: types.number,
        requests: types.array(BOUserRequest),
        error: types.maybeNull(
            types.union(
                types.enumeration<BOUserRequestsErrors>(
                    'BOUserRequestsErrors',
                    Object.values(BOUserRequestsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        updateFilterOptions(filterOptions: Instance<typeof BOUserRequestsFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        removeError(): void {
            store.error = null;
        },
        getBOUsersRequests: flow(function* (
            pageIndex: number,
            itemsPerPage: number,
            requestStatus?: string,
            requestType?: string,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            const request = new GetBOUserChangeRequestsRPC.Request(
                [
                    new GetBOUserChangeRequestsSortOrder(
                        0,
                        GetBOUserChangeRequestsSortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                requestStatus !== AnyEnum.ANY
                    ? RequestStatus.fromDTO({ case: requestStatus ?? '' })
                    : null,
                requestType !== AnyEnum.ANY
                    ? BOUserChangeRequestType.fromDTO({ case: requestType ?? '' })
                    : null,
                new ItemsPerPage(itemsPerPage),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetBOUserChangeRequestsRPC.Response,
                GetBOUserChangeRequestsRPC.Errors.InvalidPageIndex
            > = yield useGetBOUserChangeRequestsRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const requests = response.getBOUserChangeRequestsPaginationResponse.map(
                    (_request: GetBOUserChangeRequestsPaginationResponse) => {
                        return BOUserRequest.create({
                            userId: _request.userId?.uuid,
                            requestId: _request.requestId.uuid,
                            requestedAt: getFormattedTimeDateWithComma(
                                new Date(_request.requestedAt.timestamp)
                            ),
                            requestedBy: getPGFullName(_request.requestedBy),
                            requestedFor: getPGFullName(_request.requestedFor),
                            evaluatedBy: _request.checkedBy
                                ? getPGFullName(_request.checkedBy)
                                : '-',
                            requestType: _request.requestType,
                            requestStatus: _request.requestStatus
                        });
                    }
                );
                store.requests = cast(requests);
                store.totalItems = response.totalItems;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case BOUserRequestsErrors.InvalidPageIndex:
                        store.error = BOUserRequestsErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetBOUserChangeRequestsRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetBOUserChangeRequestsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 10,
        getBOUsersRequestInitialFilter: (): Instance<typeof BOUserRequestsFilterOptions> => {
            return BOUserRequestsFilterOptions.create({
                status: RequestStatusEnum.PENDING,
                type: AnyEnum.ANY
            });
        }
    }));

export const createBOUserRequestsStore = (): Instance<typeof BOUserRequestsStore> => {
    return BOUserRequestsStore.create({
        filterOptions: BOUserRequestsFilterOptions.create({
            status: RequestStatusEnum.PENDING,
            type: AnyEnum.ANY
        }),
        totalItems: 0,
        requests: []
    });
};
