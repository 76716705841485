import React, { useState } from 'react';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useSignOutStore } from '../../store/hooks';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '../../../common/components/Dialog';

export interface SignOutDialogProps {
    open: boolean;
    onCancelButtonClick: () => void;
}

export const SignOutDialog = observer(
    ({ open, onCancelButtonClick }: SignOutDialogProps): React.ReactElement => {
        const [isUserBeingSignedOut, setIsUserBeingSignedOut] = useState(false);

        const typography = useTypography();
        const palette = usePalette();
        const { t } = useTranslation();
        const store = useSignOutStore();
        const breadcrumbStore = useBreadcrumbStore();
        const navigate = useNavigate();

        const signOutUser = async (): Promise<void> => {
            setIsUserBeingSignedOut(true);
            await store.signOutUser();
            setIsUserBeingSignedOut(false);
            navigate('/auth');
        };

        return (
            <Box sx={{ width: '560px' }}>
                <Dialog
                    open={open}
                    title={t('signOut.signOutDialogTitle')}
                    primaryButtonText={t('common.signOut')}
                    onPrimaryButtonClick={async (): Promise<void> => {
                        await signOutUser();
                        breadcrumbStore.resetLinks();
                    }}
                    secondaryButtonText={isUserBeingSignedOut ? undefined : t('common.cancel')}
                    onSecondaryButtonClick={onCancelButtonClick}
                    disableBackdropClick={true}>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {t('signOut.signOutDialogSubtitle')}
                    </Typography>
                </Dialog>
            </Box>
        );
    }
);
