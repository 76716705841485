import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { useTranslation } from 'react-i18next';
import { BOUserChangeRequestType, BOUserRequestType } from '@resolut-tech/bcn-rpcs';
import { AnyEnum } from '../../common/enums/AnyEnum';
import { RequestType } from '../components/BOUserRequestTypeComponent';

export const getRequestTypeValue = (
    value: BOUserChangeRequestType.BOUserChangeRequestType
): string => {
    const { t } = useTranslation();
    const values = BOUserChangeRequestType.BOUserChangeRequestType;
    switch (value) {
        case values.NEW_USER:
            return t('boUser.newUser');
        case values.UPDATED_USER:
            return t('boUser.updatedUser');
    }
};

export const getRequestTypeOptionsWithAny = (): DropdownItem[] => {
    const { t } = useTranslation();
    let values = Object.keys(BOUserChangeRequestType.BOUserChangeRequestType);
    const anyValue = Object.keys(AnyEnum);
    values = values.concat(anyValue);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getRequestTypeValue(
            value as BOUserChangeRequestType.BOUserChangeRequestType
        );
        return { name: dropdownValue ?? t('common.any'), value };
    });
};

export const getRequestTypeOptions = (): DropdownItem[] => {
    const values = Object.keys(BOUserRequestType.BOUserRequestType);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getRequestTypeValue(
            value as BOUserChangeRequestType.BOUserChangeRequestType
        );
        return { name: dropdownValue ?? '', value };
    });
};

export const getTypeFromRequestType = (
    type: BOUserChangeRequestType.BOUserChangeRequestType
): RequestType => {
    switch (type) {
        case BOUserChangeRequestType.BOUserChangeRequestType.NEW_USER:
            return RequestType.NEW_USER;
        case BOUserChangeRequestType.BOUserChangeRequestType.UPDATED_USER:
            return RequestType.UPDATED_USER;
    }
};
