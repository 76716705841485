import { cast, flow, Instance, types } from 'mobx-state-tree';
import { useGetFeeDeterminationRuleDiffRPCClient } from '../rpcs/RPC';
import { GetFeeDeterminationRuleDiffRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    FeeDeterminationRuleModel,
    getFeeDeterminationRuleModel
} from '../models/FeeDeterminationRuleModel';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum FeeDeterminationRulesDiffStoreErrors {
    OldRulesNotFound = 'OLD_RULES_NOT_FOUND',
    InvalidRequestId = 'INVALID_REQUEST_ID'
}

export const FeeDeterminationRulesDiffStore = types
    .model('FeeDeterminationRulesDiff', {
        oldFeeDeterminationRules: types.array(FeeDeterminationRuleModel),
        updatedFeeDeterminationRules: types.array(FeeDeterminationRuleModel),
        error: types.maybeNull(
            types.union(
                types.enumeration<FeeDeterminationRulesDiffStoreErrors>(
                    'FeeDeterminationRulesDiffStoreErrors',
                    Object.values(FeeDeterminationRulesDiffStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        fetchFeeDeterminationRulesDiff: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetFeeDeterminationRuleDiffRPC.Request(new LeoUUID(requestId));
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetFeeDeterminationRuleDiffRPC.Response,
                    GetFeeDeterminationRuleDiffRPC.Errors.Errors
                > = yield useGetFeeDeterminationRuleDiffRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.oldFeeDeterminationRules = cast(
                        response.oldFeeDeterminationRules.map((feeDeterminationRule) =>
                            getFeeDeterminationRuleModel(feeDeterminationRule)
                        )
                    );
                    store.updatedFeeDeterminationRules = cast(
                        response.updatedFeeDeterminationRules.map((feeDeterminationRule) =>
                            getFeeDeterminationRuleModel(feeDeterminationRule)
                        )
                    );
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case FeeDeterminationRulesDiffStoreErrors.OldRulesNotFound:
                            store.error = FeeDeterminationRulesDiffStoreErrors.OldRulesNotFound;
                            break;
                        case FeeDeterminationRulesDiffStoreErrors.InvalidRequestId:
                            store.error = FeeDeterminationRulesDiffStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetFeeDeterminationRuleDiffRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetFeeDeterminationRuleDiffRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = FeeDeterminationRulesDiffStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unknown error: ${error} occurred in GetFeeDeterminationRuleDiffRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unhandled error: ${error} occurred in GetFeeDeterminationRuleDiffRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createFeeDeterminationRulesDiffStore = (): Instance<
    typeof FeeDeterminationRulesDiffStore
> => {
    return FeeDeterminationRulesDiffStore.create();
};
