import {
    Amount,
    BOName,
    CountryCode,
    Currency,
    FundListItem,
    GetUnclaimedFundsRequestDetailsListRPC,
    UserName
} from '@resolut-tech/bcn-rpcs';
import { LeoPhoneNumber, LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];

const UnclaimedFunds: FundListItem[] = Array(23).fill(
    new FundListItem(
        new LeoTimestamp(new Date().toISOString()),
        new LeoUUID(),
        new UserName(new BOName(firstName[getRandomInt(4)]), new BOName(lastName[getRandomInt(4)])),
        new LeoPhoneNumber('+265995118354'),
        new Amount(50000000, new Currency('MWK')),
        new CountryCode('25')
    )
);

export class MockGetUnclaimedFundsRequestDetailsListRPCImpl extends GetUnclaimedFundsRequestDetailsListRPC {
    execute(
        _request: GetUnclaimedFundsRequestDetailsListRPC.Request
    ): Promise<
        LeoRPCResult<
            GetUnclaimedFundsRequestDetailsListRPC.Response,
            GetUnclaimedFundsRequestDetailsListRPC.Errors.Errors
        >
    > {
        const response = new GetUnclaimedFundsRequestDetailsListRPC.Response(
            UnclaimedFunds.length,
            UnclaimedFunds
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetUnclaimedFundsRequestDetailsListRPC.Response,
                GetUnclaimedFundsRequestDetailsListRPC.Errors.Errors
            >
        >;
    }
}
