import { Box, Stack, styled, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { SectionRowCellType } from '../types/SectionRowComponentTypes';
import { SectionRowComponent } from './SectionRowComponent';

export interface KYCSectionRowComponentWithLabelProp {
    label: string;
    cell: SectionRowCellType;
}

export const KYCSectionRowComponentWithLabel = ({
    label,
    cell
}: KYCSectionRowComponentWithLabelProp): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    const StyledBox = styled(Box)(() => ({
        width: '240px',
        height: '24px',
        paddingRight: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        alignSelf: 'center'
    }));

    return (
        <Stack
            direction="row"
            sx={{
                paddingRight: '16px',
                paddingLeft: '16px',
                borderBottom: `1px solid ${palette.outline[200]}`
            }}>
            <StyledBox>
                <Typography sx={{ ...typography.small2, color: palette.label[200] }}>
                    {label}
                </Typography>
            </StyledBox>
            <Box width={`calc(100% - 240px)`} alignSelf="center">
                <SectionRowComponent {...cell} />
            </Box>
        </Stack>
    );
};
