import { TextAreaInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled, Typography } from '@mui/material';
import { Instance } from 'mobx-state-tree';
import { AmountDetailsRow } from '../../common/components/AmountDetailsRow';
import { WarningContainer } from '../../common/components/WarningContainer';
import { AmountModel } from '../../common/models/AmountModel';
import { getAmountString } from '../../common/utils/UIUtils';
import { TransferAmountDetails } from '../../common/components/TransferAmountDetails';
import { AdditionalFeeField } from './AdditionalFeeField';
import { Dialog } from '../../../common/components/Dialog';

interface RefundDialogProps {
    title: string;
    primaryButtonText: string;
    onSubmit: (comment: string) => void;
    isMakerFlow?: boolean;
    isBankFeeSelected?: boolean;
    isAdditionalFeeSelected?: boolean;
    onBankFeeSelectionChange?: (value: boolean) => void;
    onAdditionalFeeSelectionChange?: (value: boolean) => void;
    baseAmount: Instance<typeof AmountModel>;
    bankFee: Instance<typeof AmountModel> | null;
    appliedAdditionalFee?: Instance<typeof AmountModel> | null;
    additionalFee?: string;
    additionalFeeCurrency?: string;
    onAdditionalFeeChanged?: (value: string) => void;
    totalAmount: Instance<typeof AmountModel> | null;
    isDialogOpen: boolean;
    onClose: () => void;
    showWarningChip: boolean;
    calculateAmountButton?: React.ReactElement;
    isPrimaryButtonDisabled?: boolean;
    amountFieldErrorText?: string | null;
}
export const RefundDialog = ({
    title,
    primaryButtonText,
    isMakerFlow = true,
    baseAmount,
    bankFee,
    appliedAdditionalFee,
    isBankFeeSelected,
    onBankFeeSelectionChange,
    isAdditionalFeeSelected = false,
    onAdditionalFeeSelectionChange,
    onAdditionalFeeChanged,
    additionalFee,
    additionalFeeCurrency,
    totalAmount,
    isDialogOpen,
    onClose,
    onSubmit,
    showWarningChip,
    calculateAmountButton,
    isPrimaryButtonDisabled = false,
    amountFieldErrorText
}: RefundDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();
    const [comment, setComment] = useState<string | null>(null);

    const StyledTypography = styled(Typography)(() => ({
        ...typography.small1,
        color: palette.label[300],
        paddingBottom: '12px'
    }));

    return (
        <Dialog
            open={isDialogOpen}
            onClose={(): void => {
                setComment(null);
                onClose();
            }}
            isCloseIconPresent
            title={title}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={t('common.cancel')}
            isPrimaryButtonDisabled={
                !comment || comment?.trim().length === 0 || isPrimaryButtonDisabled
            }
            onPrimaryButtonClick={async (): Promise<void> => {
                // Since the primary button is enabled only when comment is present, comment can never be null here.
                onSubmit(comment!);
                setComment(null);
            }}
            onSecondaryButtonClick={(): void => {
                setComment(null);
                onClose();
            }}
            disableBackdropClick
            dialogWidth="560px">
            <Stack>
                <StyledTypography>
                    {isMakerFlow
                        ? t('transaction.transactionAmount')
                        : t('transaction.reversibleAmount')}
                </StyledTypography>
                <AmountDetailsRow
                    label={t('transaction.baseAmount')}
                    text={getAmountString(baseAmount)}
                    showCheckbox={isMakerFlow}
                    isSelected={true}
                    isDisabled={true}
                />
                {bankFee && (
                    <AmountDetailsRow
                        label={t('transaction.bankFee')}
                        text={getAmountString(bankFee)}
                        sx={{ paddingTop: '6px' }}
                        showCheckbox={isMakerFlow}
                        isSelected={isBankFeeSelected}
                        onSelectionChanged={onBankFeeSelectionChange}
                    />
                )}
                <StyledTypography sx={{ paddingTop: '20px' }}>
                    {t('transaction.transactionFee')}
                </StyledTypography>
                {isMakerFlow ? (
                    <AdditionalFeeField
                        isFeeCharged={isAdditionalFeeSelected}
                        // additionalFeeCurrency, onSelectionChanged and onFeeChanged must be provided when isMakerFlow is true.
                        onSelectionChanged={onAdditionalFeeSelectionChange!}
                        onFeeChanged={onAdditionalFeeChanged!}
                        fee={additionalFee ?? ''}
                        currency={additionalFeeCurrency!}
                        errorText={amountFieldErrorText ?? null}
                    />
                ) : (
                    appliedAdditionalFee && (
                        <AmountDetailsRow
                            label={t('transaction.additionalFee')}
                            text={getAmountString(appliedAdditionalFee)}
                            sx={{ paddingTop: '6px' }}
                        />
                    )
                )}
                <TransferAmountDetails
                    title={t('transaction.totalAmountTransferringToReceiver')}
                    label={t('common.amount')}
                    text={totalAmount && getAmountString(totalAmount)}
                    sx={{ my: '20px' }}>
                    {calculateAmountButton}
                </TransferAmountDetails>
                <TextAreaInputField
                    name="comment"
                    isRequired
                    numberOfRows={3}
                    value={comment ?? ''}
                    onTextChange={setComment}
                    label={t('common.addComment')}
                />
                {showWarningChip && (
                    <WarningContainer
                        text={t('common.exchangeRateMessage')}
                        sx={{ marginTop: '20px' }}
                    />
                )}
            </Stack>
        </Dialog>
    );
};
