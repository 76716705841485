import { GetDocumentIdForSignedInUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetDocumentIdForSignedInUserRPCImpl extends GetDocumentIdForSignedInUserRPC {
    execute(
        _request: GetDocumentIdForSignedInUserRPC.Request
    ): Promise<
        LeoRPCResult<
            GetDocumentIdForSignedInUserRPC.Response,
            GetDocumentIdForSignedInUserRPC.Errors.Errors
        >
    > {
        const response = new GetDocumentIdForSignedInUserRPC.Response(new LeoUUID());
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetDocumentIdForSignedInUserRPC.Response,
                GetDocumentIdForSignedInUserRPC.Errors.Errors
            >
        >;
    }
}
