import { Stack, Typography } from '@mui/material';
import { useTypography, usePalette, Card } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RuleValidationErrorComponentProps {
    errorText: string;
}

export const RuleValidationErrorComponent = ({
    errorText
}: RuleValidationErrorComponentProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Card>
            <Stack>
                <Typography
                    sx={{
                        ...typography.sh3,
                        color: palette.error[300],
                        padding: '16px',
                        borderBottom: `1px solid ${palette.outline[200]}`
                    }}>
                    {t('common.errorsFoundWhileParsing')}
                </Typography>
                <Typography
                    sx={{
                        ...typography.body2,
                        color: palette.error[300],
                        padding: '16px',
                        whiteSpace: 'pre-line'
                    }}>
                    {errorText}
                </Typography>
            </Stack>
        </Card>
    );
};
