import {
    CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse,
    CBSTransferForUnclaimedFundsRequestHistoryRPC,
    CBSTransferForUnclaimedFundsRequestHistorySortOrder,
    IntermediateRequestStatus,
    ItemsPerPage,
    PageIndex
} from '@resolut-tech/bcn-rpcs';
import { CBSTransferForUnclaimedFundsRequestHistorySortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/cBSTransferForUnclaimedFundsRequestHistorySortOrder';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types, cast } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getPGAmountString } from '../../common/utils/UIUtils';
import { useCBSTransferForUnclaimedFundsRequestHistoryClient } from '../rpcs/RPC';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum UnclaimedFundsRequestHistoryStoreErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

const UnclaimedFundsRequestHistoryModel = types.model({
    requestedAt: types.string,
    requestStatus: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    ),
    requestedBy: types.string,
    checkedBy: types.maybeNull(types.string),
    requestId: types.string,
    amount: types.string
});

const getUnclaimedFundsRequestHistoryModel = (
    response: CBSTransferForUnclaimedFundsRequestHistoryPaginationResponse
): Instance<typeof UnclaimedFundsRequestHistoryModel> => {
    return UnclaimedFundsRequestHistoryModel.create({
        requestedAt: getFormattedTimeDateWithComma(new Date(response.requestedAt.timestamp)),
        requestStatus: response.requestStatus,
        requestedBy: getPGFullName(response.requestedBy),
        checkedBy: response.checkedBy ? getPGFullName(response.checkedBy) : null,
        requestId: response.requestId.uuid,
        amount: getPGAmountString(response.amount)
    });
};

export const UnclaimedFundsRequestHistoryStore = types
    .model({
        unclaimedFundsRequestHistoryList: types.array(UnclaimedFundsRequestHistoryModel),
        totalItems: types.number,
        requestStatus: types.string,
        error: types.maybeNull(
            types.union(
                types.enumeration<UnclaimedFundsRequestHistoryStoreErrors>(
                    'UnclaimedFundsRequestHistoryStoreErrors',
                    Object.values(UnclaimedFundsRequestHistoryStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        updateRequestStatus(requestStatus: string): void {
            store.requestStatus = requestStatus;
        },
        removeError(): void {
            store.error = null;
        },
        getUnclaimedFundRequestHistoryList: flow(function* (
            requestStatusDropdownValue: string | undefined,
            pageIndex: number,
            itemsPerPage: number,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            let requestStatus = null;
            try {
                if (IntermediateRequestStatus.isInstanceOf(requestStatusDropdownValue)) {
                    requestStatus = IntermediateRequestStatus.fromDTO({
                        case: requestStatusDropdownValue ?? store.requestStatus
                    });
                }
                const request = new CBSTransferForUnclaimedFundsRequestHistoryRPC.Request(
                    [
                        new CBSTransferForUnclaimedFundsRequestHistorySortOrder(
                            0,
                            CBSTransferForUnclaimedFundsRequestHistorySortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                            getSortOrder(sortOrder)
                        )
                    ],
                    requestStatus,
                    new ItemsPerPage(itemsPerPage),
                    new PageIndex(pageIndex)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    CBSTransferForUnclaimedFundsRequestHistoryRPC.Response,
                    CBSTransferForUnclaimedFundsRequestHistoryRPC.Errors.InvalidPageIndex
                > = yield useCBSTransferForUnclaimedFundsRequestHistoryClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const historyList =
                        response.cBSTransferForUnclaimedFundsRequestHistoryPaginationResponse.map(
                            (list) => getUnclaimedFundsRequestHistoryModel(list)
                        );
                    store.unclaimedFundsRequestHistoryList = cast(historyList);
                    store.totalItems = response.totalItems;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case UnclaimedFundsRequestHistoryStoreErrors.InvalidPageIndex:
                            store.error = UnclaimedFundsRequestHistoryStoreErrors.InvalidPageIndex;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CBSTransferForUnclaimedFundsRequestHistoryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in CBSTransferForUnclaimedFundsRequestHistoryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(
                    `Unhandled error: ${error} occurred in CBSTransferForUnclaimedFundsRequestHistoryRPC`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 10
    }));

export const createUnclaimedFundsRequestHistoryStore = (): Instance<
    typeof UnclaimedFundsRequestHistoryStore
> => {
    return UnclaimedFundsRequestHistoryStore.create({
        requestStatus: IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        totalItems: 0,
        unclaimedFundsRequestHistoryList: []
    });
};
