import { Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../common/components/Dialog';

interface DeletePhotoDialogProps {
    isDialogOpen: boolean;
    onCancelButtonClick: React.Dispatch<React.SetStateAction<boolean>>;
    onPrimaryButtonClick: () => void;
}

export const DeletePhotoDialog = ({
    isDialogOpen,
    onCancelButtonClick,
    onPrimaryButtonClick
}: DeletePhotoDialogProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();
    const { t } = useTranslation();

    return (
        <Dialog
            open={isDialogOpen}
            title={t('profileSetting.deletePhotoSectionTitle')}
            primaryButtonColor="error"
            primaryButtonText={t('profileSetting.deletePhoto')}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={(): void => {
                onCancelButtonClick(false);
            }}
            onPrimaryButtonClick={async (): Promise<void> => {
                onPrimaryButtonClick();
            }}
            disableBackdropClick>
            <Typography sx={{ ...typography.small2, color: palette.error[300] }}>
                {t('profileSetting.deletePhotoConfirmationText')}
            </Typography>
        </Dialog>
    );
};
