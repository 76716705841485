import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    GetBCNUserRequestsPaginationResponse,
    GetBCNUserRequestsRPC,
    IntermediateRequestStatus,
    PGUserName
} from '@resolut-tech/bcn-rpcs';

const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

const firstName = ['Jane', 'Wade', 'Esther', 'Cameron', 'Brooklyn'];
const lastName = ['Cooper', 'Warren', 'Howard', 'Williamson', 'Simmons'];

const requestList = [
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.PENDING,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DENIED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.WITHDRAWN,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.APPROVED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    ),
    new GetBCNUserRequestsPaginationResponse(
        new LeoTimestamp(new Date().toISOString()),
        IntermediateRequestStatus.IntermediateRequestStatus.DISCARDED,
        new PGUserName(firstName[getRandomInt(4)], lastName[getRandomInt(4)]),
        null,
        new LeoUUID()
    )
];

export class MockGetBCNUserRequestsRPCImpl extends GetBCNUserRequestsRPC {
    execute(
        request: GetBCNUserRequestsRPC.Request
    ): Promise<LeoRPCResult<GetBCNUserRequestsRPC.Response, GetBCNUserRequestsRPC.Errors.Errors>> {
        const list = requestList.filter((viewRequest) => {
            if (request.requestStatus) {
                return viewRequest.requestStatus === request.requestStatus;
            } else {
                return viewRequest;
            }
        });
        const response = new GetBCNUserRequestsRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetBCNUserRequestsRPC.Response, GetBCNUserRequestsRPC.Errors.Errors>
        >;
    }
}
