import { Grid } from '@mui/material';
import {
    AutoCompleteInputField,
    AutoCompleteItem,
    DropdownInputField,
    DropdownItem,
    TextInputField
} from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeeRulesRowData } from '../pages/EditFeeRules';
import { FeeRuleFeeType, FeeRuleTransactionType } from '@resolut-tech/bcn-rpcs';
import { AmountTextField } from '../../common/components/amount-text-field/AmountTextField';
import { useViewFeeRulesStore } from '../store/hooks';
import { Dialog } from '../../../common/components/Dialog';

interface InsertFeeRulesRowDialogProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onInsertButtonClick: (row: FeeRulesRowData) => void;
}

export const InsertFeeRulesRowDialog = ({
    isDialogOpen,
    onDialogClose,
    onInsertButtonClick
}: InsertFeeRulesRowDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const [transactionType, setTransactionType] = useState('');
    const [feeType, setFeeType] = useState('');
    const [amountRangeLow, setAmountRangeLow] = useState('');
    const [amountRangeHigh, setAmountRangeHigh] = useState('');
    const [bankFeePercentage, setBankFeePercentage] = useState('');
    const [counterPartyFeePercentage, setCounterPartyFeePercentage] = useState('');
    const [agentCommissionPercentage, setAgentCommissionPercentage] = useState('');
    const [flatFee, setFlatFee] = useState('');
    const [percentage, setPercentage] = useState('');
    const [lowerBound, setLowerBound] = useState('');
    const [upperBound, setUpperBound] = useState('');
    const [cbsId, setCbsId] = useState('');
    const [isFlatFeeDisabled, setIsFlatFeeDisabled] = useState(false);
    const [isPercentageDisabled, setIsPercentageDisabled] = useState(false);
    const [isLowerBoundDisabled, setIsLowerBoundDisabled] = useState(false);
    const [isUpperBoundDisabled, setIsUpperBoundDisabled] = useState(false);
    const store = useViewFeeRulesStore();

    const reset = (): void => {
        setTransactionType('');
        setFeeType('');
        setAmountRangeLow('');
        setAmountRangeHigh('');
        setBankFeePercentage('');
        setCounterPartyFeePercentage('');
        setAgentCommissionPercentage('');
        setFlatFee('');
        setPercentage('');
        setLowerBound('');
        setUpperBound('');
        setCbsId('');
    };

    const onClose = (): void => {
        reset();
        onDialogClose();
    };

    const getSystemAccountsAutoCompleteItems = (): AutoCompleteItem[] => {
        const transactionTypes = Object.values(FeeRuleTransactionType.FeeRuleTransactionType);
        return transactionTypes.map((_transactionType): AutoCompleteItem => {
            return {
                id: _transactionType,
                label: _transactionType
            };
        });
    };

    const getFeeTypeDropdownItems = (): DropdownItem[] => {
        const feeTypes = Object.values(FeeRuleFeeType.FeeRuleFeeType);
        return feeTypes.map((_feeType): DropdownItem => {
            return {
                name: _feeType,
                value: _feeType
            };
        });
    };

    const getCBSIdCountryDropdownItems = (): DropdownItem[] => {
        const list = store.countryCbsIdList;
        return list.map((country): DropdownItem => {
            return {
                name: country.name,
                value: country.name
            };
        });
    };

    const onFeeTypeSelect = (item: DropdownItem): void => {
        setFeeType(item.value);
        switch (item.value) {
            case FeeRuleFeeType.FeeRuleFeeType.FLAT_FEE:
                setIsFlatFeeDisabled(false);
                setIsLowerBoundDisabled(true);
                setLowerBound('');
                setIsUpperBoundDisabled(true);
                setUpperBound('');
                setIsPercentageDisabled(true);
                setPercentage('');
                break;
            case FeeRuleFeeType.FeeRuleFeeType.PERCENTAGE:
                setIsFlatFeeDisabled(true);
                setFlatFee('');
                setIsLowerBoundDisabled(true);
                setLowerBound('');
                setIsUpperBoundDisabled(true);
                setUpperBound('');
                setIsPercentageDisabled(false);
                break;
            case FeeRuleFeeType.FeeRuleFeeType.PERCENTAGE_WITH_BOUNDS:
                setIsFlatFeeDisabled(true);
                setFlatFee('');
                setIsLowerBoundDisabled(false);
                setIsUpperBoundDisabled(false);
                setIsPercentageDisabled(false);
                break;
            default:
                break;
        }
    };

    return (
        <Dialog
            open={isDialogOpen}
            title={t('common.insertRow')}
            primaryButtonText={t('common.insertRow')}
            onPrimaryButtonClick={async (): Promise<void> => {
                onInsertButtonClick({
                    transactionType,
                    amountRangeLow,
                    amountRangeHigh,
                    cbsId,
                    feeType,
                    percentage,
                    upperBound,
                    lowerBound,
                    flatFee,
                    bankFeePercentage,
                    counterPartyFeePercentage,
                    agentCommissionPercentage
                });
                onClose();
            }}
            isPrimaryButtonDisabled={Boolean(!transactionType || !feeType || !cbsId)}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Grid container spacing="20px">
                <Grid item xs={6}>
                    <AutoCompleteInputField
                        isRequired={true}
                        id="transactionType"
                        label={t('rulesCSVHeaders.feeRules.transactionType')}
                        options={getSystemAccountsAutoCompleteItems()}
                        onSelect={(value): void => {
                            if (value?.id) {
                                setTransactionType(value.id);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DropdownInputField
                        isRequired={true}
                        name="feeType"
                        value={feeType}
                        onSelect={(item: DropdownItem): void => {
                            onFeeTypeSelect(item);
                        }}
                        label={t('rulesCSVHeaders.feeRules.feeType')}
                        options={getFeeTypeDropdownItems()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DropdownInputField
                        isRequired={true}
                        name="cbsId"
                        value={cbsId}
                        onSelect={(item: DropdownItem): void => {
                            setCbsId(item.value);
                        }}
                        label={t('common.cbs')}
                        options={getCBSIdCountryDropdownItems()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AmountTextField
                        name="amountRangeLow"
                        value={amountRangeLow}
                        onAmountChange={(value: string): void => {
                            setAmountRangeLow(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.amountRangeLow')}
                        isDecimalAllowed={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AmountTextField
                        name="amountRangeHigh"
                        value={amountRangeHigh}
                        onAmountChange={(value: string): void => {
                            setAmountRangeHigh(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.amountRangeHigh')}
                        isDecimalAllowed={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="bankFeePercentage"
                        value={bankFeePercentage}
                        type="text"
                        onTextChange={(value: string): void => {
                            setBankFeePercentage(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.bankFeePercentage')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="counterPartyFeePercentage"
                        value={counterPartyFeePercentage}
                        type="text"
                        onTextChange={(value: string): void => {
                            setCounterPartyFeePercentage(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.counterPartyFeePercentage')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="agentCommissionPercentage"
                        value={agentCommissionPercentage}
                        type="text"
                        onTextChange={(value: string): void => {
                            setAgentCommissionPercentage(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.agentCommissionPercentage')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInputField
                        name="percentage"
                        value={percentage}
                        type="text"
                        onTextChange={(value: string): void => {
                            setPercentage(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.percentage')}
                        isDisabled={isPercentageDisabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AmountTextField
                        name="flatFee"
                        value={flatFee}
                        onAmountChange={(value: string): void => {
                            setFlatFee(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.flatFee')}
                        isDisabled={isFlatFeeDisabled}
                        isDecimalAllowed={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AmountTextField
                        name="lowerBound"
                        value={lowerBound}
                        onAmountChange={(value: string): void => {
                            setLowerBound(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.lowerBound')}
                        isDisabled={isLowerBoundDisabled}
                        isDecimalAllowed={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AmountTextField
                        name="upperBound"
                        value={upperBound}
                        onAmountChange={(value: string): void => {
                            setUpperBound(value);
                        }}
                        label={t('rulesCSVHeaders.feeRules.upperBound')}
                        isDisabled={isUpperBoundDisabled}
                        isDecimalAllowed={false}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};
