import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { CBSRequestDetailsStore } from './CBSRequestDetailsStore';
import { CheckCBSTransferRequestStore } from './CheckCBSTransferRequestStore';
import { DownloadUnclaimedFundsRequestStore } from './DownloadUnclaimedFundsRequestStore';
import { RequestCBSTransferStore } from './RequestCBSTransferStore';
import { UnclaimedFundsListStore } from './UnclaimedFundsListStore';
import { UnclaimedFundsRequestHistoryStore } from './UnclaimedFundsRequestHistoryStore';
import { UnclaimedTransactionDetailsStore } from './UnclaimedTransactionDetailsStore';
import { WithdrawCBSTransferStore } from './WithdrawCBSTransferStore';

export const useUnclaimedFundsListStore = (): Instance<typeof UnclaimedFundsListStore> => {
    return useRootStore().homeStore.unclaimedFunds.unclaimedFundsListStore;
};

export const useRequestCBSTransferStore = (): Instance<typeof RequestCBSTransferStore> => {
    return useRootStore().homeStore.unclaimedFunds.requestCBSTransferStore;
};

export const useUnclaimedTransactionDetailsStore = (): Instance<
    typeof UnclaimedTransactionDetailsStore
> => {
    return useRootStore().homeStore.unclaimedFunds.unclaimedTransactionDetailsStore;
};

export const useCBSRequestDetailsStore = (): Instance<typeof CBSRequestDetailsStore> => {
    return useRootStore().homeStore.unclaimedFunds.cbsRequestDetailsStore;
};

export const useDownloadUnclaimedFundsRequestStore = (): Instance<
    typeof DownloadUnclaimedFundsRequestStore
> => {
    return useRootStore().homeStore.unclaimedFunds.downloadUnclaimedFundsRequestStore;
};

export const useWithdrawCBSTransferStore = (): Instance<typeof WithdrawCBSTransferStore> => {
    return useRootStore().homeStore.unclaimedFunds.withdrawCBSTransferStore;
};

export const useUnclaimedFundsRequestHistoryStore = (): Instance<
    typeof UnclaimedFundsRequestHistoryStore
> => {
    return useRootStore().homeStore.unclaimedFunds.unclaimedFundsRequestHistoryStore;
};

export const useCheckCBSTransferRequestStore = (): Instance<
    typeof CheckCBSTransferRequestStore
> => {
    return useRootStore().homeStore.unclaimedFunds.checkCBSTransferRequestStore;
};
