import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BitmapImageType,
    BOGender,
    BOName,
    BORole,
    BORoleDiff,
    BORoleDiffStatus,
    BOUserBasicDetails,
    BOUserComment,
    BOUserRequestDetails,
    BOUserRequestType,
    Comment,
    GetBOUserDetailsRPC,
    LocalizedText,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    UserName,
    UserStatus
} from '@resolut-tech/bcn-rpcs';
import {
    LeoEmailId,
    LeoPhoneNumber,
    LeoRPCResult,
    LeoTimestamp,
    LeoUUID
} from '@surya-digital/leo-ts-runtime';

export class MockGetBOUserDetailsRPCImpl extends GetBOUserDetailsRPC {
    execute(
        _request: GetBOUserDetailsRPC.Request
    ): Promise<LeoRPCResult<GetBOUserDetailsRPC.Response, GetBOUserDetailsRPC.Errors.Errors>> {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );
        const details = new BOUserBasicDetails(
            profilePicture,
            new UserName(new BOName('Esther')),
            new BOName('Esther'),
            UserStatus.UserStatus.ACTIVE,
            new LeoPhoneNumber('+265995118354'),
            new LeoEmailId('esther.howard@email.com'),
            new LocalizedText('Malawi'),
            BOGender.BOGender.MALE,
            new LeoTimestamp(),
            [
                new BORole(
                    'Fee Determination Rules Viewer',
                    new LocalizedText('Viewer'),
                    new LocalizedText('Fee Determination Rules'),
                    new LocalizedText('View Rules, View History, Download Rules')
                ),
                new BORole(
                    'Transactions Refund Maker',
                    new LocalizedText('Refund Maker'),
                    new LocalizedText('Transactions'),
                    new LocalizedText('View List, View Details, View History, Raise Request')
                ),
                new BORole(
                    'BCN User Management Checker',
                    new LocalizedText('Checker'),
                    new LocalizedText('BCN User Management'),
                    new LocalizedText(
                        'View BO Users List, View BO Users Requests List, View BO User Details, Raise Request, Force Reset Password, Regenerate Auth Codes'
                    )
                )
            ]
        );

        const requestDetails = new BOUserRequestDetails(
            new LeoUUID(),
            BOUserRequestType.BOUserRequestType.EDIT_USER,
            new BOUserComment(
                new LeoUUID(),
                new UserName(new BOName('Wade'), new BOName('Warren')),
                profilePicture,
                new LeoTimestamp(),
                new Comment('The user had requested for the changes')
            ),
            new BOName('Ester'),
            new BOName('Howardy'),
            new BOName('Ester'),
            BOGender.BOGender.FEMALE,
            new LeoPhoneNumber('+265995118356'),
            new LeoEmailId('ester.howardy@email.com'),
            [
                new BORoleDiff(
                    new LocalizedText('Viewer'),
                    new LocalizedText('Fee Determination Rules'),
                    BORoleDiffStatus.BORoleDiffStatus.REMOVED
                ),
                new BORoleDiff(
                    new LocalizedText('Maker'),
                    new LocalizedText('Fee Determination Rules'),
                    BORoleDiffStatus.BORoleDiffStatus.ADDED
                ),
                new BORoleDiff(
                    new LocalizedText('Refund Maker'),
                    new LocalizedText('Transactions')
                ),
                new BORoleDiff(
                    new LocalizedText('Checker'),
                    new LocalizedText('BCN User Management')
                )
            ]
        );

        const response = new GetBOUserDetailsRPC.Response(details, requestDetails);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetBOUserDetailsRPC.Response, GetBOUserDetailsRPC.Errors.Errors>
        >;
    }
}
