import { Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { UserAvatar } from '../../common/components/UserAvatar';
import { getFormattedPhoneNumber } from '../../common/utils/UIUtils';
import { AuditLogModel } from '../store/AuditLogsStore';

interface AuditListDataProp {
    details: Instance<typeof AuditLogModel>;
}

export const AuditListData = ({ details }: AuditListDataProp): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Stack
            key="auditLogs"
            direction="row"
            padding="8px 16px 8px 16px"
            justifyContent="space-between">
            <Stack direction="row" alignItems="center">
                <UserAvatar src={details.profilePhoto ?? null} size="extra-small" />
                <Stack direction="row" spacing="4px" paddingLeft="12px">
                    <Typography sx={{ ...typography.small3, color: palette.label[300] }}>
                        {details.name ?? getFormattedPhoneNumber(details.phoneNumber)}
                    </Typography>
                    <Typography sx={{ ...typography.body3, color: palette.label[200] }}>
                        {`${'-'}  ${details.eventInfo}`}
                    </Typography>
                </Stack>
            </Stack>
            <Stack>
                <Typography sx={{ ...typography.body3, color: palette.label[200] }}>
                    {details.timestamp}
                </Typography>
            </Stack>
        </Stack>
    );
};
