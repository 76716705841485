import { Grid } from '@mui/material';
import {
    DatePickerField,
    DatePickerInput,
    DropdownInputField,
    DropdownItem
} from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { UnclaimedFundsListFilterOptions } from '../store/UnclaimedFundsListStore';
import { getInitialFilter, getSelectedFundStatusOptions } from '../utils/UIUtils';
import enZALocale from 'date-fns/locale/en-ZA';
import { Button } from '../../../common/components/Button';

interface RequestCBSTransferFilterProps {
    filter: Instance<typeof UnclaimedFundsListFilterOptions>;
    setFilter: (newFilter: Instance<typeof UnclaimedFundsListFilterOptions>) => void;
    countryDropdownItems: DropdownItem[];
    initialCountryCode: string;
}

export const UnclaimedFundsFilter = ({
    filter,
    setFilter,
    countryDropdownItems,
    initialCountryCode
}: RequestCBSTransferFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getInitialFilter(t, initialCountryCode);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const [selectedCountryCode, setSelectedCountryCode] = useState(filter.selectedCountryCode);
    const [selectedFundStatus, setSelectedFundStatus] = useState<string>(filter.selectedFundStatus);
    const [startDate, setStartDate] = useState<Date | null>(filter.startDate);
    const [endDate, setEndDate] = useState<Date>(filter.endDate);
    const [isValidDate, setIsValidDate] = useState(true);
    const [disableSearch, setDisableSearch] = useState(false);

    const onCountryDropdownChange = (selected: DropdownItem): void => {
        setDisableSearch(false);
        setSelectedCountryCode(selected.value);
    };

    const onFundStatusDropdownChange = (selected: DropdownItem): void => {
        setDisableSearch(false);
        setSelectedFundStatus(selected.value);
    };

    const isDateValid = useCallback(() => {
        if (startDate && endDate) {
            if (startDate <= endDate) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }, [startDate, endDate]);

    useEffect(() => {
        setSelectedCountryCode(filter.selectedCountryCode);
        setSelectedFundStatus(filter.selectedFundStatus);
        setStartDate(filter.startDate);
        setEndDate(filter.endDate);
    }, [filter]);

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="countryDropdown"
                        value={selectedCountryCode}
                        onSelect={onCountryDropdownChange}
                        label={t('common.selectCountry')}
                        options={countryDropdownItems}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="fundStatus"
                        value={selectedFundStatus}
                        onSelect={onFundStatusDropdownChange}
                        label={t('unclaimedFunds.fundStatus')}
                        options={getSelectedFundStatusOptions(t)}
                    />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <DatePickerField
                        label={t('common.selectStartDate')}
                        locale={enZALocale}
                        value={startDate}
                        maxDate={endDate}
                        onChange={(newValue: DatePickerInput): void => {
                            setDisableSearch(false);
                            setIsValidDate(true);
                            setStartDate(newValue);
                        }}
                        onError={(startDateError): void => {
                            if (startDateError) {
                                setIsValidDate(false);
                            }
                        }}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DatePickerField
                        label={t('common.selectEndDate')}
                        value={endDate}
                        locale={enZALocale}
                        minDate={startDate ?? undefined}
                        maxDate={new Date()}
                        onChange={(newValue: DatePickerInput): void => {
                            if (newValue) {
                                setDisableSearch(false);
                                setIsValidDate(true);
                                setEndDate(newValue);
                            }
                        }}
                        onError={(endDateError): void => {
                            if (endDateError) {
                                setIsValidDate(false);
                            }
                        }}
                        style={{ width: '100%' }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="8px">
                    <Button
                        name="plain-color"
                        variant="plain-color"
                        color="error"
                        size="medium"
                        title={t('common.reset')}
                        isDisabled={isInitialFilter}
                        onClick={(): void => {
                            setDisableSearch(true);
                            setFilter(initialFilter);
                        }}
                    />
                    <Button
                        name="filled"
                        variant="filled"
                        isDisabled={!isDateValid() || disableSearch || !isValidDate}
                        size="medium"
                        title={t('common.search')}
                        onClick={(): void => {
                            setDisableSearch(true);
                            setFilter({
                                selectedCountryCode,
                                selectedFundStatus,
                                startDate,
                                endDate
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
