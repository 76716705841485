import {
    GetUnclaimedFundsRequestDetailsListRPC,
    GetUnclaimedFundsRequestDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import {
    CountryListStore,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import {
    CBSRequestDetailsModel,
    getCBSRequestDetailsModel
} from '../../common/models/CBSRequestDetailsModel';
import { UnclaimedFundsListModel } from '../models/UnclaimedFundsListModel';
import {
    useGetUnclaimedFundsRequestDetailsListRPCClient,
    useGetUnclaimedFundsRequestDetailsRPCClient
} from '../rpcs/RPC';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { LeoErrors } from '../../common/errors/LeoErrors';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum CBSRequestDetailsStoreErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const CBSRequestDetailsStore = types
    .model({
        countryListStore: CountryListStore,
        requestDetails: types.maybeNull(CBSRequestDetailsModel),
        totalNoOfTransaction: types.maybeNull(types.number),
        totalAmount: types.maybeNull(AmountModel),
        unclaimedFundsList: types.array(UnclaimedFundsListModel),
        totalItems: types.number,
        error: types.maybeNull(
            types.union(
                types.enumeration<CBSRequestDetailsStoreErrors>(
                    'CBSRequestDetailsStoreErrors',
                    Object.values(CBSRequestDetailsStoreErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        fetchCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        removeError(): void {
            store.error = null;
        },
        getUnclaimedFundsRequestDetails: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            try {
                const request = new GetUnclaimedFundsRequestDetailsRPC.Request(
                    new LeoUUID(requestId)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetUnclaimedFundsRequestDetailsRPC.Response,
                    GetUnclaimedFundsRequestDetailsRPC.Errors.Errors
                > = yield useGetUnclaimedFundsRequestDetailsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.totalNoOfTransaction = response.requestDetails.numberOfTransactions;
                    store.totalAmount = getAmountModel(response.requestDetails.totalAmount);
                    store.requestDetails = response.requestDetails.requestDetails
                        ? getCBSRequestDetailsModel(response.requestDetails.requestDetails)
                        : null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case CBSRequestDetailsStoreErrors.InvalidRequestId:
                            store.error = CBSRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetUnclaimedFundsRequestDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CBSRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetUnclaimedFundsRequestDetailsRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        getUnclaimedFundsRequestDetailsList: flow(function* (
            requestId: string,
            pageIndex: number,
            itemsPerPage: number,
            sortOrder?: 'asc' | 'desc'
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const request = new GetUnclaimedFundsRequestDetailsListRPC.Request(
                    new LeoUUID(requestId),
                    pageIndex,
                    itemsPerPage,
                    getSortOrder(sortOrder)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetUnclaimedFundsRequestDetailsListRPC.Response,
                    GetUnclaimedFundsRequestDetailsListRPC.Errors.Errors
                > = yield useGetUnclaimedFundsRequestDetailsListRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    const unclaimedFundList = response.unclaimedFundsList.map((unclaimedFund) => {
                        return {
                            unclaimedFundId: unclaimedFund.unclaimedFundId.uuid,
                            createdAt: getFormattedTimeDateWithComma(
                                new Date(unclaimedFund.createdAt.timestamp)
                            ),
                            userName: getFullName(unclaimedFund.userName),
                            userPhoneNumber: unclaimedFund.userPhoneNumber.phoneNumber,
                            amount: getAmountModel(unclaimedFund.amount),
                            country: store.countryListStore
                                .countryList()
                                .filter((_country: CountryViewModel) => {
                                    return _country.countryCode === unclaimedFund.countryCode.code;
                                })[0].name
                        };
                    });
                    store.totalItems = response.totalItems;
                    store.unclaimedFundsList = cast(unclaimedFundList);
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case CBSRequestDetailsStoreErrors.InvalidRequestId:
                            store.error = CBSRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        case CBSRequestDetailsStoreErrors.InvalidPageIndex:
                            store.error = CBSRequestDetailsStoreErrors.InvalidPageIndex;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetUnclaimedFundsRequestDetailsListRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CBSRequestDetailsStoreErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetUnclaimedFundsRequestDetailsListRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 10
    }));

export const createCBSRequestDetailsStore = (): Instance<typeof CBSRequestDetailsStore> => {
    return CBSRequestDetailsStore.create({
        countryListStore: createCountryListStore(),
        totalItems: 0
    });
};
