import { MockSignOutBOUserRPCImpl } from './MockSignOutBOUserImpl';
import { SignOutBOUserRPC, SignOutBOUserRPCClientImpl } from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useSignOutBoUserRPCClient = (apiClient: APIClient): SignOutBOUserRPC => {
    if (MOCK) {
        return new MockSignOutBOUserRPCImpl();
    } else {
        return new SignOutBOUserRPCClientImpl(apiClient);
    }
};
