import {
    BackOfficeConfirmSignInOTPRPC,
    LocalizedText,
    PasswordPolicy
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../networking/MockExecuteHelper';

export class MockConfirmSignInOTPRPCImpl extends BackOfficeConfirmSignInOTPRPC {
    execute(
        _request: BackOfficeConfirmSignInOTPRPC.Request
    ): Promise<
        LeoRPCResult<
            BackOfficeConfirmSignInOTPRPC.Response,
            BackOfficeConfirmSignInOTPRPC.Errors.Errors
        >
    > {
        const response = new BackOfficeConfirmSignInOTPRPC.Response(
            new BackOfficeConfirmSignInOTPRPC.ResponseEnums.Result.ForceResetPassword(
                new LeoUUID(),
                new PasswordPolicy(new LocalizedText('Your Password Must:'), [
                    new LocalizedText('Must be atleast 8 characters long.'),
                    new LocalizedText(
                        'Contains atleast 1 upper case character(s), 1 lower case character(s), 1 special character(s) and 1 digit(s).'
                    ),
                    new LocalizedText('Doesn’t contain common dictionary words.'),
                    new LocalizedText('Not start or end with white spaces.')
                ])
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                BackOfficeConfirmSignInOTPRPC.Response,
                BackOfficeConfirmSignInOTPRPC.Errors.Errors
            >
        >;
    }
}
