import {
    LeoDate,
    LeoEmailId,
    LeoPhoneNumber,
    LeoRPCResult,
    LeoTimestamp,
    LeoUUID
} from '@surya-digital/leo-ts-runtime';
import {
    AgentBasicDetails,
    AgentRequestDetails,
    BOGender,
    BOName,
    BOUserComment,
    GetAgentDetailsRPC,
    UserName,
    UserStatus,
    Comment
} from '@resolut-tech/bcn-rpcs';
import { mockExecute } from '../../../networking/MockExecuteHelper';

const basicDetails: AgentBasicDetails = new AgentBasicDetails(
    new LeoUUID(),
    null,
    new UserName(new BOName('Esther'), new BOName('Howard')),
    new BOName('Jane'),
    UserStatus.UserStatus.ACTIVE,
    new LeoPhoneNumber('+265995118354'),
    new LeoEmailId('esther.howard@email.com'),
    new LeoDate('2020-10-20'),
    BOGender.BOGender.MALE,
    'Dilani Shop',
    new URL(
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8whjBBAvjXr77HYiGbqQKuSJ7YAroWhWuRw&usqp=CAU'
    ),
    'GILSPB08',
    'Malawi Personal',
    new LeoUUID()
);

const requestDetails: AgentRequestDetails = new AgentRequestDetails(
    new LeoUUID(),
    UserStatus.UserStatus.ACTIVE,
    UserStatus.UserStatus.DEACTIVATED,
    new BOUserComment(
        new LeoUUID(),
        new UserName(new BOName('Wade'), new BOName('Cooper')),
        null,
        new LeoTimestamp(new Date().toISOString()),
        new Comment('The agent has requested to deactivate the account temporarily.')
    )
);

export class MockGetAgentDetailsRPCImpl extends GetAgentDetailsRPC {
    execute(
        _request: GetAgentDetailsRPC.Request
    ): Promise<LeoRPCResult<GetAgentDetailsRPC.Response, GetAgentDetailsRPC.Errors.Errors>> {
        const response = new GetAgentDetailsRPC.Response(basicDetails, requestDetails);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetAgentDetailsRPC.Response, GetAgentDetailsRPC.Errors.Errors>
        >;
    }
}
