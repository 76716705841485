import { Box, Grid } from '@mui/material';
import { DropdownInputField, DropdownItem, SearchField } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { TransactionFilterOptions } from '../store/TransactionSearchStore';
import { TransactionSearchByDropdown, getTransactionSearchByOptions } from '../utils/UIUtils';
import { Button } from '../../../common/components/Button';

interface TransactionSearchProps {
    filter: Instance<typeof TransactionFilterOptions>;
    setFilter: (newFilter: Instance<typeof TransactionFilterOptions>) => void;
    minimumSearchTextLength: number;
    countries: CountryViewModel[] | null;
}

export const TransactionSearchBar = ({
    filter,
    setFilter,
    minimumSearchTextLength,
    countries
}: TransactionSearchProps): React.ReactElement => {
    const { t } = useTranslation();
    const searchByOptions = getTransactionSearchByOptions();
    const [searchValue, setSearchValue] = useState(filter.searchText);
    const [dropdownData, setDropdownData] = useState(filter.searchBy);
    const [firstName, setFirstName] = useState(filter.searchByFirstName);
    const [lastName, setLastName] = useState(filter.searchByLastName);
    const [phoneCode, setPhoneCode] = useState(filter.searchByPhoneCode);
    const [disableSearch, setDisableSearch] = useState(true);
    const [showCountryCodeDropdown, setShowCountryCodeDropdown] = useState<boolean>(false);
    const [showNameDropdown, setShowNameDropdown] = useState<boolean>(false);

    useEffect(() => {
        setSearchValue(filter.searchText);
        setDropdownData(filter.searchBy);
        setFirstName(filter.searchByFirstName);
        setLastName(filter.searchByLastName);
        setPhoneCode(filter.searchByPhoneCode);
        switch (filter.searchBy) {
            case TransactionSearchByDropdown.MobileNumber:
                setShowCountryCodeDropdown(true);
                break;
            case TransactionSearchByDropdown.Name:
                setShowNameDropdown(true);
                break;
        }
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setDisableSearch(false);
        setSearchValue(inputValue);
    };

    const onFirstNameChange = (inputValue: string): void => {
        if (!lastName && inputValue.length === 0) {
            setDisableSearch(true);
        } else {
            setDisableSearch(false);
        }
        setFirstName(inputValue.length === 0 ? null : inputValue);
    };

    const onLastNameChange = (inputValue: string): void => {
        if (!firstName && inputValue.length === 0) {
            setDisableSearch(true);
        } else {
            setDisableSearch(false);
        }
        setLastName(inputValue.length === 0 ? null : inputValue);
    };

    const handleDisableSearch = (): boolean => {
        if (dropdownData === TransactionSearchByDropdown.Name) {
            return Boolean(
                disableSearch ||
                    (firstName && firstName.length < minimumSearchTextLength) ||
                    (lastName && lastName.length < minimumSearchTextLength)
            );
        } else {
            return Boolean(
                (searchValue && searchValue.trim().length < minimumSearchTextLength) ||
                    disableSearch ||
                    searchValue?.trim().length === 0
            );
        }
    };

    const onSearchClear = (): void => {
        setSearchValue(null);
        setDisableSearch(true);
    };

    const onFirstNameClear = (): void => {
        setFirstName(null);
        setDisableSearch(lastName ? false : true);
    };

    const onLastNameClear = (): void => {
        setLastName(null);
        setDisableSearch(firstName ? false : true);
    };

    const resetFields = (): void => {
        setSearchValue(null);
        setFirstName(null);
        setLastName(null);
        setPhoneCode(null);
        setShowCountryCodeDropdown(false);
        setShowNameDropdown(false);
    };

    const onChangeDropdownInput = (inputValue: DropdownItem): void => {
        setDropdownData(inputValue.value);
        resetFields();
        switch (inputValue.value) {
            case TransactionSearchByDropdown.MobileNumber:
                setShowCountryCodeDropdown(true);
                break;
            case TransactionSearchByDropdown.Name:
                setShowNameDropdown(true);
                break;
        }
        onSearchClear();
    };

    const getSearchFieldPlaceholder = (): string => {
        const searchBy = searchByOptions
            .find((item) => item.value === dropdownData)
            ?.name.toLowerCase();
        return searchBy ?? '';
    };

    const onCountryCodeChange = (value: DropdownItem): void => {
        setSearchValue(null);
        setPhoneCode(value.value);
    };

    const countryCodeDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    value: countryIterator.phoneCode,
                    name: countryIterator.phoneCode
                };
            });
        }
        return [];
    };

    const getCountryCodeDropdown = (): React.ReactElement => {
        return (
            <Grid item xs={6}>
                <DropdownInputField
                    name="countryCode"
                    value={phoneCode ?? ''}
                    onSelect={onCountryCodeChange}
                    label={t('common.countryCode')}
                    options={countryCodeDropdownItems()}
                />
            </Grid>
        );
    };

    const getFirstNameLastNameComponent = (): React.ReactElement => {
        return (
            <>
                <SearchField
                    name="first-name"
                    value={firstName ?? ''}
                    onTextChange={onFirstNameChange}
                    placeholder={`${t('common.searchUsing')} ${t(
                        'common.firstName'
                    ).toLowerCase()}`}
                    onClear={onFirstNameClear}
                    allowClearField={true}
                    style={{ width: '100%' }}
                />
                <SearchField
                    name="last-name"
                    value={lastName ?? ''}
                    onTextChange={onLastNameChange}
                    placeholder={`${t('common.searchUsing')} ${t('common.lastName').toLowerCase()}`}
                    onClear={onLastNameClear}
                    allowClearField={true}
                    style={{ width: '100%' }}
                />
            </>
        );
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchDropdown"
                        value={dropdownData}
                        onSelect={onChangeDropdownInput}
                        label={t('common.searchBy')}
                        options={searchByOptions}
                    />
                </Grid>
                <Grid
                    item
                    xs={8}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="16px">
                    {showCountryCodeDropdown && getCountryCodeDropdown()}
                    {showNameDropdown ? (
                        getFirstNameLastNameComponent()
                    ) : (
                        <SearchField
                            name="searchField"
                            value={searchValue ?? ''}
                            isDisabled={!dropdownData}
                            onTextChange={onSearchChange}
                            onClear={onSearchClear}
                            allowClearField={true}
                            placeholder={`${t(
                                'common.searchUsing'
                            )} ${getSearchFieldPlaceholder()}`}
                        />
                    )}
                    <Box>
                        <Button
                            name="filled"
                            variant="filled"
                            size="medium"
                            isDisabled={handleDisableSearch()}
                            title={t('common.search')}
                            onClick={(): void => {
                                setDisableSearch(true);
                                setFilter({
                                    searchBy: dropdownData,
                                    searchText: searchValue?.trim() ?? searchValue,
                                    searchByFirstName: firstName,
                                    searchByLastName: lastName,
                                    searchByPhoneCode: phoneCode
                                });
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
