import { MockGetUserPrivilegesRPCImpl } from './MockGetUserPrivilegesRPCImpl';
import { GetUserPrivilegesRPC, GetUserPrivilegesRPCClientImpl } from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useGetUserPrivilegesRPCImpl = (apiClient: APIClient): GetUserPrivilegesRPC => {
    if (MOCK) {
        return new MockGetUserPrivilegesRPCImpl();
    } else {
        return new GetUserPrivilegesRPCClientImpl(apiClient);
    }
};
