import {
    FeeDeterminationRule,
    GetCurrentFeeDeterminationRuleDetailsRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetCurrentFeeDeterminationRuleDetailsRPCImpl extends GetCurrentFeeDeterminationRuleDetailsRPC {
    execute(
        _request: GetCurrentFeeDeterminationRuleDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetCurrentFeeDeterminationRuleDetailsRPC.Response,
            GetCurrentFeeDeterminationRuleDetailsRPC.Errors.Errors
        >
    > {
        const currentFeeDeterminationRules = [
            new FeeDeterminationRule(
                'WITHIN_YAFIKA',
                'PERCENTAGE',
                0,
                5000000,
                4,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WITHIN_YAFIKA',
                'PERCENTAGE',
                0,
                10000000,
                3,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WITHIN_YAFIKA',
                'PERCENTAGE_WITH_BOUNDS',
                5010000,
                900000000,
                2,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WITHIN_YAFIKA',
                'FLAT_FEE',
                900010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WALLET_TO_WALLET',
                'PERCENTAGE',
                0,
                5000000,
                3,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WALLET_TO_WALLET',
                'PERCENTAGE_WITH_BOUNDS',
                5010000,
                10000000,
                2,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'WALLET_TO_WALLET',
                'FLAT_FEE',
                100010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_SUBSCRIPTION',
                'PERCENTAGE',
                0,
                50000000,
                3,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_SUBSCRIPTION',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                2,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_SUBSCRIPTION',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_SRWB_PREPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_SRWB_PREPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                3,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_SRWB_PREPAID',
                'FLAT_FEE',
                1000010000,
                null,
                2,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_BOXOFFICE',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_BOXOFFICE',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                3,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_DSTV_BOXOFFICE',
                'FLAT_FEE',
                1000010000,
                null,
                2,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_PREPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_PREPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_PREPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_POSTPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_POSTPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_LWB_POSTPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_AIRTEL_AIRTIME',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_AIRTEL_AIRTIME',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_AIRTEL_AIRTIME',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_PREPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_PREPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_PREPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_POSTPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_POSTPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_ESCOM_POSTPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_PREPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_PREPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_PREPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_POSTPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_POSTPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_BWB_POSTPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_PREPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_PREPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_PREPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_POSTPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_POSTPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_NRWB_POSTPAID',
                'FLAT_FEE',
                1000010000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_CRWB_POSTPAID',
                'PERCENTAGE',
                0,
                50000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'BILLER_CRWB_POSTPAID',
                'PERCENTAGE_WITH_BOUNDS',
                50010000,
                1000000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'AB_CASH_IN',
                'FLAT_FEE',
                0,
                500000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'AB_CASH_OUT',
                'FLAT_FEE',
                0,
                500000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'AB_MONEY_TRANSFER_WITHIN_YAFIKA',
                'FLAT_FEE',
                0,
                500000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'AB_MONEY_TRANSFER_YAFIKA_TO_EXTERNAL',
                'FLAT_FEE',
                0,
                500000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            ),
            new FeeDeterminationRule(
                'AB_MONEY_TRANSFER_EXTERNAL_TO_YAFIKA',
                'FLAT_FEE',
                0,
                500000000,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                'e751127b-df82-4bd2-894d-7096be687ed1'
            )
        ];

        const response = new GetCurrentFeeDeterminationRuleDetailsRPC.Response(
            currentFeeDeterminationRules
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetCurrentFeeDeterminationRuleDetailsRPC.Response,
                GetCurrentFeeDeterminationRuleDetailsRPC.Errors.Errors
            >
        >;
    }
}
