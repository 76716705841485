import { Grid, Stack } from '@mui/material';
import { IntermediateRequestStatus } from '@resolut-tech/bcn-rpcs';
import { Section, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { RequestDetailsSection } from '../../common/components/RequestDetailsSection';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getUserStatus } from '../../common/utils/UIUtils';
import { BCNUserRequestHistoryErrors } from '../store/BCNUserRequestHistoryStore';
import { useBCNUserRequestHistoryStore } from '../store/hooks';

export const BCNRequestHistorySection = ({
    userId
}: {
    userId: string | null;
}): React.ReactElement => {
    const { t } = useTranslation();
    const store = useBCNUserRequestHistoryStore();
    const typography = useTypography();
    const palette = usePalette();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    async function fetchBCNRequestHistory(): Promise<string | React.ReactElement[]> {
        if (userId) {
            await store.fetchBCNRequestHistory(userId!);
            if (store.error) {
                switch (store.error) {
                    case BCNUserRequestHistoryErrors.InvalidUserID:
                        return t('common.somethingWentWrongProcessingRequest');
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        return t('common.errorMessage');
                    default:
                        return t('common.errorMessage');
                }
            } else if (store.requests.length === 0) {
                return [];
            } else {
                return [
                    <Stack key="bcnUserRequestHistory" spacing="16px">
                        {store.requests.map((request, index) => {
                            return (
                                <RequestDetailsSection
                                    key={index}
                                    requestDetails={request.requestDetails!}
                                    isSectionItem={true}>
                                    <Grid container sx={{ margin: '16px 0px 16px 0px' }}>
                                        <Grid item xs={6}>
                                            <Stack
                                                sx={{
                                                    ...typography.small2,
                                                    color: palette.label[300]
                                                }}>
                                                {request.requestDetails.status ===
                                                IntermediateRequestStatus.IntermediateRequestStatus
                                                    .PENDING
                                                    ? t('common.currentStatus')
                                                    : t('common.oldStatus')}
                                            </Stack>
                                            <StatusComponent
                                                variant="status-cell"
                                                status={getUserStatus(request.oldUserStatus)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack
                                                sx={{
                                                    ...typography.small2,
                                                    color: palette.label[300]
                                                }}>
                                                {t('bcnUser.updatedUserStatus')}
                                            </Stack>
                                            <StatusComponent
                                                variant="status-cell"
                                                status={getUserStatus(request.updatedUserStatus)}
                                            />
                                        </Grid>
                                    </Grid>
                                </RequestDetailsSection>
                            );
                        })}
                    </Stack>
                ];
            }
        } else {
            return t('common.errorMessage');
        }
    }

    return (
        <>
            <Section
                title={t('bcnUser.bcnUserRequestHistory')}
                viewOverrides={{
                    empty: { message: t('common.noDataFound') },
                    loading: { message: t('common.loadingDetails') }
                }}
                onExpand={fetchBCNRequestHistory}
                expandIndicator={t('common.showMore')}
                collapseIndicator={t('common.showLess')}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.removeError();
                }}
            />
        </>
    );
};
