import { Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAmountString } from '../../common/utils/UIUtils';
import { useUpdateAgentStateStore } from '../store/hooks';
import { Dialog } from '../../../common/components/Dialog';

interface AgentArchiveWarningDialogProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    onSubmit: () => Promise<void>;
    countdown: number;
}

export const AgentArchiveWarningDialog = observer(
    ({
        isDialogOpen,
        onDialogClose,
        onSubmit,
        countdown
    }: AgentArchiveWarningDialogProps): React.ReactElement => {
        const { t } = useTranslation();
        const palette = usePalette();
        const typography = useTypography();
        const store = useUpdateAgentStateStore();

        const getPrimaryButtonTitle = (): string => {
            if (store.balanceAmount) {
                if (countdown === 0) {
                    return t('common.proceed');
                } else {
                    return t('common.proceedWithTimer', { timer: countdown });
                }
            } else {
                return t('agencyBanking.viewPendingRequests');
            }
        };

        const getBalanceDetails = (): React.ReactElement => {
            return (
                <Stack direction="column">
                    <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                        {t('agencyBanking.commissionBalance')}
                    </Typography>
                    {store.balanceAmount && (
                        <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                            {getAmountString(store.balanceAmount)}
                        </Typography>
                    )}
                </Stack>
            );
        };

        return (
            <Dialog
                open={isDialogOpen}
                title={t('agencyBanking.requestToArchiveAgent')}
                primaryButtonText={getPrimaryButtonTitle()}
                isPrimaryButtonDisabled={Boolean(store.balanceAmount && countdown > 0)}
                onPrimaryButtonClick={(): Promise<void> => onSubmit()}
                secondaryButtonText={t('common.cancel')}
                onSecondaryButtonClick={onDialogClose}
                disableBackdropClick={true}>
                <Stack direction="column" spacing="20px">
                    <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                        {store.balanceAmount
                            ? t('agencyBanking.nonZeroBalanceSubtitle')
                            : t('agencyBanking.pendingRequestsSubtitle')}
                    </Typography>
                    {store.balanceAmount && getBalanceDetails()}
                </Stack>
            </Dialog>
        );
    }
);
