import * as React from 'react';

export const Banknote = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 7C3.44772 7 3 7.44772 3 8V16C3 16.5523 3.44772 17 4 17H20C20.5523 17 21 16.5523 21 16V8C21 7.44772 20.5523 7 20 7H4ZM1 8C1 6.34315 2.34315 5 4 5H20C21.6569 5 23 6.34315 23 8V16C23 17.6569 21.6569 19 20 19H4C2.34315 19 1 17.6569 1 16V8Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 12C5 11.4477 5.44772 11 6 11H6.01C6.56228 11 7.01 11.4477 7.01 12C7.01 12.5523 6.56228 13 6.01 13H6C5.44772 13 5 12.5523 5 12ZM17 12C17 11.4477 17.4477 11 18 11H18.01C18.5623 11 19.01 11.4477 19.01 12C19.01 12.5523 18.5623 13 18.01 13H18C17.4477 13 17 12.5523 17 12Z"
                fill="currentColor"
            />
        </svg>
    );
};
