import { Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBOUserChangeRequestStore } from '../store/CheckBOUserChangeRequestStore';
import { Dialog } from '../../../common/components/Dialog';

interface BOPendingRequestDialogProps {
    title: string;
    isDialogOpen: boolean;
    onClose: () => void;
    checkRequestStore: Instance<typeof CheckBOUserChangeRequestStore>;
}

export const BOPendingRequestDialog = ({
    title,
    isDialogOpen,
    onClose,
    checkRequestStore
}: BOPendingRequestDialogProps): React.ReactElement => {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    return (
        <Dialog
            open={isDialogOpen}
            title={title}
            secondaryButtonText={t('common.close')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Stack direction="column" spacing="20px">
                <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                    {t('boUser.pendingRequestSubtitle')}
                </Typography>
                <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                    {t('common.pendingRequests')}
                </Typography>
                {checkRequestStore.pendingRequest?.map((request, index) => {
                    return (
                        <Typography
                            key={index}
                            sx={{ ...typography.body2, color: palette.label[300] }}>
                            {`${request.requestName}: ${request.count}`}
                        </Typography>
                    );
                })}
            </Stack>
        </Dialog>
    );
};
