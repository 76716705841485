import { LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    CountryCode,
    GetBCNUserListPaginationResponse,
    GetBCNUserListRPC,
    UserStatus
} from '@resolut-tech/bcn-rpcs';

const paginationResponseList: GetBCNUserListPaginationResponse[] = [
    new GetBCNUserListPaginationResponse(
        'Jane Cooper',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID('70127105-fa9a-4993-9f4f-eeb03d4599a7')
    ),
    new GetBCNUserListPaginationResponse(
        'Wade Warren',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('26'),
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID('d1d0c0f2-b5d7-4a43-81ae-6835e995b315')
    ),
    new GetBCNUserListPaginationResponse(
        'Esther Howard',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('27'),
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('cea7ad84-baba-4263-abdc-80c293dc62a4')
    ),
    new GetBCNUserListPaginationResponse(
        'Cameron Williamson',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.DEACTIVATED,
        new LeoUUID('98bcb50f-d97e-4acc-b1ea-f718e630d166')
    ),
    new GetBCNUserListPaginationResponse(
        'Brooklyn Simmons',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('25'),
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('efe47f65-6eb9-4ec7-a74e-fed60831acd6')
    ),
    new GetBCNUserListPaginationResponse(
        'Leslie Alexander',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('26'),
        UserStatus.UserStatus.ACTIVE,
        new LeoUUID('f2f66eea-7ae9-438e-a387-3430bba5755c')
    ),
    new GetBCNUserListPaginationResponse(
        'Jenny Wilson',
        new LeoPhoneNumber('+265995118354'),
        new CountryCode('27'),
        UserStatus.UserStatus.ARCHIVED,
        new LeoUUID('ac806b1c-7a8e-4645-9c4e-1322370fac24')
    )
];

export class MockGetBCNUserListRPCImpl extends GetBCNUserListRPC {
    execute(
        request: GetBCNUserListRPC.Request
    ): Promise<LeoRPCResult<GetBCNUserListRPC.Response, GetBCNUserListRPC.Errors.Errors>> {
        let list: GetBCNUserListPaginationResponse[] = paginationResponseList;
        const phoneNumberSearchText = request.phoneNumberSearchText?.text;
        const nameSearchText = request.nameSearchText?.text;
        const requestCountryCode = request.countryCode;

        if (nameSearchText) {
            list = list.filter((t) => t.name.toLowerCase().includes(nameSearchText));
        }
        if (phoneNumberSearchText) {
            list = list.filter((t) =>
                t.phoneNumber?.phoneNumber.toLowerCase().includes(phoneNumberSearchText)
            );
        }
        if (request.userStatus) {
            list = list.filter((t) => {
                return t.userStatus === request.userStatus;
            });
        }
        if (requestCountryCode) {
            list = list.filter((t) => {
                return t.countryCode.code === requestCountryCode.code;
            });
        }
        const response = new GetBCNUserListRPC.Response(list, list.length);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetBCNUserListRPC.Response, GetBCNUserListRPC.Errors.Errors>
        >;
    }
}
