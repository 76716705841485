import { CountryCBSId } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';

export interface CBSIdWithCountry {
    country: string;
    cbsId: string;
}

export const CountryCbsIdListModel = types.model({
    name: types.string,
    countryCode: types.string,
    cbsId: types.string
});

export const getCountryCbsIdListModel = (
    countryCbsIdList: CountryCBSId
): Instance<typeof CountryCbsIdListModel> => {
    return CountryCbsIdListModel.create({
        name: getTranslatedString(countryCbsIdList.displayName),
        countryCode: countryCbsIdList.countryCode.code,
        cbsId: countryCbsIdList.cbsId.uuid
    });
};
