import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common/components/Button';

interface UpdateDeletePhotoActionSectionProps {
    onUpdateClick: () => void;
    onDeleteClick: () => void;
    isDeleteButtonDisabled: boolean;
}

export function UpdateDeletePhotoActionSection({
    onUpdateClick,
    onDeleteClick,
    isDeleteButtonDisabled
}: UpdateDeletePhotoActionSectionProps): React.ReactElement {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 8px'
            }}>
            <Button
                name="update-button"
                variant="filled"
                size="small"
                title={t('profileSetting.updatePhoto')}
                onClick={onUpdateClick}
                style={{
                    marginRight: '8px',
                    width: '106px'
                }}
            />
            <Button
                name="delete-button"
                variant="outlined-color"
                size="small"
                color="error"
                isDisabled={isDeleteButtonDisabled}
                title={t('profileSetting.deletePhoto')}
                onClick={onDeleteClick}
                style={{
                    width: '104px'
                }}
            />
        </Box>
    );
}
