import * as React from 'react';

export const Coins = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_8802_15907)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.66667 1.66667C3.90524 1.66667 1.66667 3.90524 1.66667 6.66667C1.66667 9.42809 3.90524 11.6667 6.66667 11.6667C9.42809 11.6667 11.6667 9.42809 11.6667 6.66667C11.6667 3.90524 9.42809 1.66667 6.66667 1.66667ZM0 6.66667C0 2.98477 2.98477 0 6.66667 0C10.3486 0 13.3333 2.98477 13.3333 6.66667C13.3333 10.3486 10.3486 13.3333 6.66667 13.3333C2.98477 13.3333 0 10.3486 0 6.66667Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5283 7.86737C15.7581 7.4686 16.2676 7.3316 16.6664 7.56138C17.5528 8.07216 18.3098 8.7801 18.8787 9.63038C19.4477 10.4807 19.8133 11.4505 19.9472 12.4647C20.0812 13.479 19.9799 14.5105 19.6513 15.4793C19.3226 16.4481 18.7754 17.3283 18.052 18.0518C17.3286 18.7752 16.4484 19.3224 15.4795 19.651C14.5107 19.9797 13.4792 20.0809 12.465 19.947C11.4507 19.813 10.4809 19.4474 9.63063 18.8785C8.78035 18.3096 8.07241 17.5526 7.56163 16.6661C7.33185 16.2674 7.46884 15.7578 7.86761 15.5281C8.26639 15.2983 8.77593 15.4353 9.00571 15.834C9.38879 16.4989 9.91975 17.0666 10.5575 17.4933C11.1952 17.92 11.9225 18.1942 12.6832 18.2947C13.4439 18.3951 14.2175 18.3192 14.9442 18.0727C15.6708 17.8262 16.3309 17.4158 16.8735 16.8732C17.4161 16.3307 17.8265 15.6705 18.0729 14.9439C18.3194 14.2173 18.3954 13.4437 18.2949 12.683C18.1944 11.9223 17.9202 11.1949 17.4935 10.5572C17.0668 9.9195 16.4991 9.38855 15.8343 9.00547C15.4355 8.77569 15.2985 8.26614 15.5283 7.86737Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 5.00008C5 4.53984 5.3731 4.16675 5.83333 4.16675H6.66667C7.1269 4.16675 7.5 4.53984 7.5 5.00008V8.33342C7.5 8.79365 7.1269 9.16675 6.66667 9.16675C6.20643 9.16675 5.83333 8.79365 5.83333 8.33342V5.83341C5.3731 5.83341 5 5.46032 5 5.00008Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8271 11.3273C14.1525 11.0019 14.6802 11.0019 15.0056 11.3273L15.5889 11.9107C15.7452 12.0669 15.833 12.2789 15.833 12.4999C15.833 12.7209 15.7452 12.9329 15.5889 13.0892L13.2556 15.4225C12.9302 15.7479 12.4025 15.7479 12.0771 15.4225C11.7516 15.0971 11.7516 14.5694 12.0771 14.244L13.8212 12.4999C13.5017 12.174 13.5036 11.6508 13.8271 11.3273Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_8802_15907">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
