import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Dialog } from '../../../../common/components/Dialog';

interface RequestConfirmationDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmit: (comment: string) => void;
    title: string | null;
    description: string | null;
    comment: string | null;
    content?: React.ReactElement | null;
    primaryButtonText: string;
}

export function RequestConfirmationDialog({
    isDialogOpen,
    onClose,
    onSubmit,
    title,
    description,
    comment,
    content,
    primaryButtonText
}: RequestConfirmationDialogProps): React.ReactElement {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Dialog
            open={isDialogOpen}
            title={title ?? ''}
            primaryButtonText={content ? undefined : primaryButtonText}
            secondaryButtonText={t('common.cancel')}
            onPrimaryButtonClick={async (): Promise<void> => {
                // Since the primary button is enabled only when comment is present, comment can never be null here.
                onSubmit(comment!);
            }}
            onSecondaryButtonClick={onClose}
            disableBackdropClick
            dialogWidth="560px">
            <Stack spacing={'20px'}>
                <Typography sx={{ color: palette.warning[300], ...typography.small2 }}>
                    {description}
                </Typography>
                {content}
            </Stack>
        </Dialog>
    );
}
