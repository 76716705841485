import { WorkflowDependentRoles } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';
import { BORoleModel, getBORoleModel } from './BORoleModel';

export const WorkflowDependentRolesModel = types.model({
    workflow: types.string,
    roles: types.array(BORoleModel)
});

export const getWorkflowDependentRolesModel = (
    workflowDependentRoles: WorkflowDependentRoles
): Instance<typeof WorkflowDependentRolesModel> => {
    return WorkflowDependentRolesModel.create({
        workflow: getTranslatedString(workflowDependentRoles.workflow),
        roles: workflowDependentRoles.roles.map((role) => getBORoleModel(role))
    });
};
