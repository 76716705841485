import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AgentTransactionDetailsSection } from '../components/AgentTransactionDetailSection';
import {
    useAgencyBankingWithdrawRequestStore,
    useAgentTransactionDetailStore,
    useCheckMoveFundsOutOfYafikaRequestStore,
    useRequestMoveFundsOutOfYafikaStore
} from '../store/hooks';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import { ProcessingCard } from '../../common/components/ProcessingCard';
import { UserPrivileges } from '../../user/UserPrivileges';
import { useUserStore } from '../../store/hooks';
import { AmountMoveOutOfYafikaDetails } from '../components/AmountMoveOutOfYafikaDetails';
import { Card, LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { AgentTransactionHistorySection } from '../components/AgentTransactionHistorySection';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { MoveFundsOutOfYafikaRequestDialog } from '../components/MoveFundsOutOfYafikaRequestDialog';
import { RequestToMoveFundsOutOfYafikaErrors } from '../store/RequestMoveFundsOutOfYafikaStore';
import { Status } from '../../common/enums/StatusEnum';
import { CheckMoveFundsOutOfYafikaRequestStoreErrors } from '../store/CheckMoveFundsOutOfYafikaRequestStore';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { AgencyBankingWithdrawRequestStoreError } from '../store/AgencyBankingWithdrawRequestStore';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { AgentTransactionDetailErrors } from '../store/AgentTransactionDetailStore';

export const AgentTransactionDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();
    const store = useAgentTransactionDetailStore();
    const userStore = useUserStore();
    const requestStore = useRequestMoveFundsOutOfYafikaStore();
    const withdrawRequestStore = useAgencyBankingWithdrawRequestStore();
    const checkRequestStore = useCheckMoveFundsOutOfYafikaRequestStore();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const transactionId = searchParam.get('transactionId');
    const [loading, setLoading] = useState(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState('');
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const [dialogBoxTitle, setDialogBoxTitle] = useState<string | null>(null);
    const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
    const [isCheckDialogOpen, setIsCheckDialogOpen] = useState(false);
    const loggerStore = useLoggerStore();

    if (!transactionId) {
        loggerStore.debug('There is no transactionId present in AgentTransactionDetails');
        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        navigate('/agency-banking/transactions', { replace: true });
        return <></>;
    }

    const fetchAgentTransactionDetails = async (): Promise<void> => {
        setLoading(true);
        store.resetStore();
        await store.fetchAgentTransactionDetails(transactionId);
        setLoading(false);
    };

    useEffect(() => {
        fetchAgentTransactionDetails();
    }, []);

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckMoveFundsOutOfYafikaRequest
    );
    const requestMoveFundsOutOfYafikaPrivilege = userStore.privileges.includes(
        UserPrivileges.RequestMoveFundsOutOfYafika
    );
    const showPendingCard = Boolean(store.requestDetails?.pendingRequestDetails);
    const showProcessingCard = Boolean(store.requestDetails?.processingRequestDetails);

    const onRequestMoveFundsOutOfYafika = async (): Promise<void> => {
        setIsRequestDialogOpen(true);
    };

    useEffect(() => {
        setErrorDialogMessage(
            store.error === NetworkingError.InternalError
                ? t('common.somethingWentWrongProcessingRequest')
                : ''
        );
    }, [store.error]);

    const onDialogRequestButtonClick = async (): Promise<void> => {
        setIsRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        await requestStore.requestToMoveFundsOutOfYafika(transactionId);
        if (requestStore.error) {
            setDialogBoxTitle(t('common.requestFundsTransfer'));
            switch (requestStore.error) {
                case RequestToMoveFundsOutOfYafikaErrors.InvalidTransactionId:
                case RequestToMoveFundsOutOfYafikaErrors.UnsupportedTransactionType:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyRefunded:
                    setErrorDialogMessage(t('agencyBanking.fundsAlreadyRefunded'));
                    break;
                case RequestToMoveFundsOutOfYafikaErrors.FundsAlreadyMoved:
                    setErrorDialogMessage(t('agencyBanking.fundsAlreadyMoved'));
                    break;
                case RequestToMoveFundsOutOfYafikaErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been, we will fetch the agent transaction details again.
            fetchAgentTransactionDetails();
        }
        setScreenBlockingLoading(false);
        requestStore.resetStore();
    };

    const getPageHeaderActionElement = (): ActionElement | undefined => {
        if (store.canMoveFundsOutOfYafika && requestMoveFundsOutOfYafikaPrivilege) {
            return {
                secondaryButton: {
                    title: t('agencyBanking.moveFundsOutOfYafika'),
                    onClick: onRequestMoveFundsOutOfYafika
                }
            };
        }
        return undefined;
    };

    const showWithdrawSection = (): boolean => {
        if (requestMoveFundsOutOfYafikaPrivilege) {
            if (userStore.userId === store.requestDetails?.pendingRequestDetails?.comment.userId) {
                return true;
            }
        }
        return false;
    };

    const onApproveClick = (): void => {
        checkRequestStore.setStatus(Status.APPROVED);
        setDialogBoxTitle(t('common.approveFundsTransfer'));
        setIsCheckDialogOpen(true);
    };

    const onDenyClick = (): void => {
        checkRequestStore.setStatus(Status.DENIED);
        setDialogBoxTitle(t('common.denyFundsTransfer'));
        setIsCheckDialogOpen(true);
    };

    const onClickApproveOrDenyRequest = async (): Promise<void> => {
        setIsCheckDialogOpen(false);
        setScreenBlockingLoading(true);
        const requestId = store.requestDetails?.pendingRequestDetails?.requestId;
        if (requestId) {
            await checkRequestStore.checkPendingRequest(requestId);
            if (checkRequestStore.error) {
                switch (checkRequestStore.error) {
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidRequestId:
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.UnsupportedTransactionType:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.FundsAlreadyRefunded:
                        setErrorDialogMessage(t('agencyBanking.fundsAlreadyRefunded'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.FailedToMoveFundsOutOfYafika:
                        setErrorDialogMessage(t('agencyBanking.failedToMoveFundsOutOfYafika'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.CannotCheckSelfRequest:
                        setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyDiscarded:
                        setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.TransactionMovedToUnclaimedFunds:
                        setErrorDialogMessage(t('agencyBanking.transactionMovedToUnclaimedFunds'));
                        break;
                    case CheckMoveFundsOutOfYafikaRequestStoreErrors.InvalidComment:
                        setErrorDialogMessage(t('common.invalidComment'));
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            } else {
                // Since the request has been checked, we will fetch the agent transaction details again.
                fetchAgentTransactionDetails();
            }
        } else {
            loggerStore.debug(
                'There is no requestId present while checking request in AgentTransactionDetailsStore'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        checkRequestStore.removeError();
        checkRequestStore.resetComment();
        setScreenBlockingLoading(false);
    };

    const onWithdrawClick = (): void => {
        setDialogBoxTitle(t('common.withdrawRequest'));
        setIsWithdrawRequestDialogOpen(true);
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        const requestId = store.requestDetails?.pendingRequestDetails?.requestId;
        if (requestId) {
            await withdrawRequestStore.withdrawRequest(requestId, comment);
            if (withdrawRequestStore.error) {
                switch (withdrawRequestStore.error) {
                    case WithdrawRequestErrors.InvalidRequestId:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        break;
                    case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                        setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyDiscarded:
                        setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        break;
                    case AgencyBankingWithdrawRequestStoreError.InvalidComment:
                        setErrorDialogMessage(t('common.invalidComment'));
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            } else {
                // Since the request has been withdrawn, we will fetch the agent transaction details again.
                fetchAgentTransactionDetails();
            }
        } else {
            loggerStore.debug(
                'There is no requestId present while withdrawing request in AgentTransactionDetailsStore'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        withdrawRequestStore.removeError();
    };

    const renderAgentTransactionDetails = (): React.ReactElement => {
        if (!store.amount) {
            loggerStore.debug(
                'store.amount is empty in AgentTransactionDetailStore while rendering agent transaction details'
            );
            return <></>;
        }
        return (
            <Stack>
                <BreadcrumbComponent currentLabel={t('common.transactionDetailsTitle')} />
                {showPendingCard && store.requestDetails?.pendingRequestDetails?.comment && (
                    <Stack sx={{ padding: '32px 32px 0px 32px' }}>
                        <PendingRequestCard
                            onApproveClick={onApproveClick}
                            onDenyClick={onDenyClick}
                            onWithdrawClick={onWithdrawClick}
                            showApproveAndDenySection={showApproveAndDenySection}
                            showWithdrawSection={showWithdrawSection()}
                            comment={store.requestDetails.pendingRequestDetails.comment}>
                            <AmountMoveOutOfYafikaDetails amount={store.amount} />
                        </PendingRequestCard>
                    </Stack>
                )}
                {showProcessingCard &&
                    store.requestDetails?.processingRequestDetails?.makerComment &&
                    store.requestDetails.processingRequestDetails.checkerComment && (
                        <Stack sx={{ padding: '32px 32px 0px 32px' }}>
                            <ProcessingCard
                                message={t('agencyBanking.amountIsMovingOutOfYafika')}
                                makerComment={
                                    store.requestDetails?.processingRequestDetails?.makerComment
                                }
                                checkerComment={
                                    store.requestDetails?.processingRequestDetails?.checkerComment
                                }
                            />
                        </Stack>
                    )}
                {store.requestDetails?.isSuccess && (
                    <Stack sx={{ padding: '32px 32px 0px 32px' }}>
                        <Card style={{ background: palette.warning[100], padding: '16px' }}>
                            <Stack direction="row">
                                <AlertCircle
                                    color={palette.warning[300]}
                                    width="24px"
                                    height="24px"
                                />
                                <Typography
                                    sx={{
                                        color: palette.warning[300],
                                        ...typography.small2,
                                        marginLeft: '16px'
                                    }}>
                                    {t('agencyBanking.amountAlreadyMovedOutOfYafika')}
                                </Typography>
                            </Stack>
                        </Card>
                    </Stack>
                )}
                <WithdrawDialog
                    isDialogOpen={isWithdrawRequestDialogOpen}
                    onClose={(): void => {
                        setIsWithdrawRequestDialogOpen(false);
                    }}
                    onSubmit={onDialogWithdrawButtonClick}
                />
                <MoveFundsOutOfYafikaRequestDialog
                    dialogTitle={t('common.requestFundsTransfer')}
                    showWarningMessage={true}
                    primaryButtonText={t('common.submitRequest')}
                    comment={requestStore.comment ?? ''}
                    amount={store.amount}
                    isDialogOpen={isRequestDialogOpen}
                    onPrimaryButtonClick={onDialogRequestButtonClick}
                    onDialogClose={(): void => {
                        requestStore.resetStore();
                        setIsRequestDialogOpen(false);
                    }}
                    setComment={requestStore.setComment}
                />
                {dialogBoxTitle && (
                    <MoveFundsOutOfYafikaRequestDialog
                        dialogTitle={dialogBoxTitle}
                        showWarningMessage={checkRequestStore.status === Status.APPROVED}
                        primaryButtonText={
                            checkRequestStore.status === Status.APPROVED
                                ? t('common.approveRequest')
                                : t('common.denyRequest')
                        }
                        comment={checkRequestStore.comment ?? ''}
                        amount={store.amount}
                        isDialogOpen={isCheckDialogOpen}
                        onPrimaryButtonClick={onClickApproveOrDenyRequest}
                        onDialogClose={(): void => {
                            checkRequestStore.resetComment();
                            setIsCheckDialogOpen(false);
                        }}
                        setComment={checkRequestStore.setComment}
                    />
                )}
                <Stack
                    spacing="32px"
                    sx={{ padding: '32px', boxSizing: 'border-box', width: '100%' }}>
                    <AgentTransactionDetailsSection transactionDetails={store.transactionDetails} />
                    <AgentTransactionHistorySection
                        transactionId={transactionId}
                        amount={store.amount}
                    />
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack direction="column">
            <LoadingIndicator isLoading={screenBlockingLoading} />
            <PageHeader
                title={t('common.transactionDetailsTitle')}
                subtitle={t('common.transactionDetailsSubtitle')}
                actionElement={getPageHeaderActionElement()}
            />
            {store.error === AgentTransactionDetailErrors.InvalidTransactionId ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : loading ? (
                <LoadingState />
            ) : (
                renderAgentTransactionDetails()
            )}
            {errorDialogMessage && (
                <ErrorDialog
                    title={dialogBoxTitle}
                    errorMessage={errorDialogMessage}
                    isErrorDialogOpen={Boolean(errorDialogMessage)}
                    onClose={(): void => {
                        setErrorDialogMessage('');
                        setDialogBoxTitle(null);
                        store.resetError();
                        requestStore.resetStore();
                    }}
                />
            )}
        </Stack>
    );
});
