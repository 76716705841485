import { Stack, styled, TableCell } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

export enum RuleDiffOrientation {
    Split,
    Unified
}
export enum RuleDiffRowType {
    Insert,
    Delete,
    Modify,
    View
}

interface RuleDiffTableContentCellProps {
    minWidth: string;
    type?: RuleDiffRowType;
    orientation?: RuleDiffOrientation;
    currentValue?: string;
    updatedValue?: string;
    align?: 'left' | 'right';
}

export const RuleDiffTableContentCell = ({
    minWidth,
    type = RuleDiffRowType.View,
    currentValue = '-',
    updatedValue = '-',
    orientation = RuleDiffOrientation.Unified,
    align = 'left'
}: RuleDiffTableContentCellProps): React.ReactElement => {
    const typography = useTypography();
    const palette = usePalette();

    const ContentCell = styled(TableCell)({
        verticalAlign: 'top',
        height: '56px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        padding: '16px',
        borderBottom: 'none',
        ...typography.body2,
        color: palette.label[300]
    });

    const DiffContainer = styled(Stack)({
        width: orientation === RuleDiffOrientation.Unified ? 'auto' : '50%',
        flexGrow: 1,
        minWidth,
        textAlign: align,
        whiteSpace: 'nowrap',
        padding: '16px',
        ...typography.body2,
        color: palette.label[300]
    });

    switch (type) {
        // When type=RuleDiffRowType.Delete, we show the current value as deleted.
        case RuleDiffRowType.Delete:
            return (
                <TableCell
                    align={align}
                    sx={{
                        minWidth,
                        borderBottom: 'none',
                        background: palette.error[100]
                    }}>
                    {currentValue}
                </TableCell>
            );
        // When type=RuleDiffRowType.Insert, we show the updated value as inserted.
        case RuleDiffRowType.Insert:
            return (
                <TableCell
                    align={align}
                    sx={{
                        minWidth,
                        borderBottom: 'none',
                        background: palette.success[100]
                    }}>
                    {updatedValue}
                </TableCell>
            );
        // When type=RuleDiffRowType.Modify, we compare currentValue and updatedValue to check if the cell data is updated to show the diff view, otherwise the diff view is not shown.
        case RuleDiffRowType.Modify:
            if (currentValue === updatedValue) {
                return <ContentCell align={align}>{currentValue}</ContentCell>;
            }
            return (
                <TableCell
                    sx={{
                        borderBottom: 'none',
                        padding: 0,
                        minWidth,
                        flexGrow: 1
                    }}>
                    <Stack
                        direction={orientation === RuleDiffOrientation.Unified ? 'column' : 'row'}>
                        <DiffContainer sx={{ background: palette.error[100] }}>
                            {currentValue}
                        </DiffContainer>
                        <DiffContainer
                            sx={{
                                background: palette.success[100]
                            }}>
                            {updatedValue}
                        </DiffContainer>
                    </Stack>
                </TableCell>
            );
        // When type=RuleDiffRowType.View, current value is shown in the cell.
        case RuleDiffRowType.View:
            return <ContentCell align={align}>{currentValue}</ContentCell>;
    }
};
