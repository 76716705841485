import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { SubmitGenericFundsTransferRequestRPC } from '@resolut-tech/bcn-rpcs';

export class MockSubmitGenericFundsTransferRequestRPCImpl extends SubmitGenericFundsTransferRequestRPC {
    execute(
        _request: SubmitGenericFundsTransferRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            SubmitGenericFundsTransferRequestRPC.Response,
            SubmitGenericFundsTransferRequestRPC.Errors.Errors
        >
    > {
        const response = new SubmitGenericFundsTransferRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                SubmitGenericFundsTransferRequestRPC.Response,
                SubmitGenericFundsTransferRequestRPC.Errors.Errors
            >
        >;
    }
}
