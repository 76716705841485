import { Divider, Stack, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AmountModel } from '../../common/models/AmountModel';
import { getFormattedStringFromNumber } from '../../common/utils/UIUtils';
import { NonZeroAccountDetailsModel } from '../models/NonZeroAccountDetailsModel';
import { Dialog } from '../../../common/components/Dialog';

interface ArchiveNonZeroAccountDetailsDialogProps {
    isArchiveNonZeroAccountDetailsDialogOpen: boolean;
    onArchiveNonZeroAccountDetailsDialogClose: () => void;
    nonZeroAccountDetails: Instance<typeof NonZeroAccountDetailsModel>[];
    onSubmitToArchiveUserWithBalance: () => Promise<void>;
    countdown: number;
    title: string;
    totalBalance?: Instance<typeof AmountModel | null>;
}

export const ArchiveNonZeroAccountDetailsDialog = ({
    isArchiveNonZeroAccountDetailsDialogOpen,
    nonZeroAccountDetails,
    onArchiveNonZeroAccountDetailsDialogClose,
    onSubmitToArchiveUserWithBalance,
    countdown,
    title,
    totalBalance
}: ArchiveNonZeroAccountDetailsDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    const getPrimaryButtonTitle = (): string => {
        if (countdown < 1) {
            return t('common.proceed');
        } else {
            return t('common.proceedWithTimer', { timer: countdown });
        }
    };

    const getAccountDetails = (): React.ReactElement[] => {
        return nonZeroAccountDetails.map(({ id, name, balance }) => {
            return (
                <Stack direction="column" key={id}>
                    <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                        {name}
                    </Typography>
                    <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                        {t('bcnUser.nonZeroBalance', {
                            currency: balance?.currency,
                            number: balance?.number && getFormattedStringFromNumber(balance.number)
                        })}
                    </Typography>
                </Stack>
            );
        });
    };

    return (
        <Dialog
            open={isArchiveNonZeroAccountDetailsDialogOpen}
            title={title}
            primaryButtonText={getPrimaryButtonTitle()}
            isPrimaryButtonDisabled={countdown > 0}
            onPrimaryButtonClick={(): Promise<void> => onSubmitToArchiveUserWithBalance()}
            secondaryButtonText={t('common.cancel')}
            onSecondaryButtonClick={onArchiveNonZeroAccountDetailsDialogClose}
            disableBackdropClick={true}>
            <Stack direction="column" spacing="20px">
                <Typography sx={{ ...typography.small2, color: palette.warning[300] }}>
                    {t('bcnUser.nonZeroBalanceSubtitle')}
                </Typography>
                {getAccountDetails()}
                {totalBalance && (
                    <>
                        <Divider sx={{ borderBottom: `2px dashed ${palette.outline[300]}` }} />
                        <Stack direction="column">
                            <Typography sx={{ ...typography.small2, color: palette.label[300] }}>
                                {t('bcnUser.totalBalanceTransferToOperatingAccount')}
                            </Typography>
                            <Typography sx={{ ...typography.body2, color: palette.label[300] }}>
                                {t('bcnUser.nonZeroTotalBalance', {
                                    currency: totalBalance.currency,
                                    number: getFormattedStringFromNumber(totalBalance.number)
                                })}
                            </Typography>
                        </Stack>
                    </>
                )}
            </Stack>
        </Dialog>
    );
};
