import { Stack, styled } from '@mui/material';
import { Card, usePalette } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApproveDenyActionSection } from './ApproveDenyActionSection';
import { CommentComponent } from './CommentComponent';
import { StatusType } from './StatusComponent';
import { UserActionStatusHeader } from './UserActionStatusHeader';
import { WithdrawActionSection } from './WithdrawActionSection';
import { BOUserCommentModel } from '../models/BOUserCommentModel';

interface PendingRequestCardProps {
    children?: React.ReactNode;
    onApproveClick: () => void;
    onDenyClick: () => void;
    onWithdrawClick: () => void;
    comment: Instance<typeof BOUserCommentModel>;
    showApproveAndDenySection?: boolean;
    showWithdrawSection?: boolean;
}

export const PendingRequestCard = ({
    children,
    comment,
    onApproveClick,
    onDenyClick,
    onWithdrawClick,
    showApproveAndDenySection,
    showWithdrawSection
}: PendingRequestCardProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();

    const CardSection = styled(Stack)({
        borderTop: `1px solid ${palette.outline[200]}`,
        padding: '32px 32px 32px 80px'
    });

    const renderActionSection = (): React.ReactElement => {
        if (showApproveAndDenySection) {
            return (
                <ApproveDenyActionSection
                    onApproveClick={onApproveClick}
                    onDenyClick={onDenyClick}
                />
            );
        }
        if (showWithdrawSection) {
            return <WithdrawActionSection onWithdrawClick={onWithdrawClick} />;
        }
        return <></>;
    };

    return (
        <Card>
            <UserActionStatusHeader
                title={t('common.requestRaisedBy', {
                    name: comment.name
                })}
                subtitle={comment.requestedAt}
                profilePhotoUrl={comment.profilePhoto}
                status={StatusType.PENDING}
                sx={{
                    borderRadius: '8px 8px 0px 0px'
                }}
            />
            <CardSection spacing={2}>
                <CommentComponent text={comment.comment} sx={{ wordBreak: 'break-word' }} />
                {children}
            </CardSection>
            {renderActionSection()}
        </Card>
    );
};
