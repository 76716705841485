import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../common/components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingState } from '../../common/components/LoadingState';
import { ErrorState } from '../../common/components/ErrorState';
import {
    useAgencyBankingWithdrawRequestStore,
    useAgentBasicDetailsStore,
    useCheckAgentStatusUpdateRequestStore
} from '../store/hooks';
import { AgentBasicDetailsSection } from '../components/AgentBasicDetailsSection';
import { AgentRequestHistorySection } from '../components/AgentRequestHistorySection';
import { PendingRequestCard } from '../../common/components/PendingRequestCard';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { LoadingIndicator, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getUserStatus } from '../../common/utils/UIUtils';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { AgentRequestDialog } from '../components/AgentRequestDialog';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import { Status } from '../../common/enums/StatusEnum';
import { useCountdownTimer } from '../../bcn-users/utils/useCountdownTimer';
import { CheckAgentStatusUpdateRequestStoreErrors } from '../store/CheckAgentStatusUpdateRequestStore';
import { AgentProfileManagementSection } from '../components/AgentProfileManagementSection';
import { BreadcrumbComponent } from '../../common/components/BreadcrumbComponent';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { AgentBasicDetailsStoreErrors } from '../store/AgentBasicDetailsStore';

export const AgentDetails = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const [searchParam] = useSearchParams();
    const agentId = searchParam.get('agentId');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const store = useAgentBasicDetailsStore();
    const userStore = useUserStore();
    const typography = useTypography();
    const palette = usePalette();
    const withdrawRequestStore = useAgencyBankingWithdrawRequestStore();
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState<boolean>(false);
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [isRequestCheckDialogOpen, setIsRequestCheckDialogOpen] = useState<boolean>(false);
    const [requestDialogTitle, setRequestDialogTitle] = useState<string>('');
    const CheckAgentStatusUpdateRequestStore = useCheckAgentStatusUpdateRequestStore();
    const [countdown, setCountdown] = useCountdownTimer(5);
    const [isShowNonZeroCommissionAccountDetails, setIsShowNonZeroCommissionAccountDetails] =
        useState(false);
    const loggerStore = useLoggerStore();

    const getAgentDetails = async (): Promise<void> => {
        if (agentId) {
            setLoading(true);
            await store.fetchAgentDetails(agentId);
            setLoading(false);
            setErrorDialogMessage(
                store.error === NetworkingError.InternalError
                    ? t('common.somethingWentWrongProcessingRequest')
                    : null
            );
        } else {
            navigate('/agency-banking/agents', { replace: true });
        }
    };

    const showRequestSection =
        userStore.privileges.includes(UserPrivileges.RequestArchiveAgent) ||
        userStore.privileges.includes(UserPrivileges.RequestActivateDeactivateAgent);

    useEffect(() => {
        getAgentDetails();
    }, []);

    const showApproveAndDenySection = userStore.privileges.includes(
        UserPrivileges.CheckAgentUpdateRequest
    );

    const showWithdrawSection = (): boolean => {
        return userStore.userId === store.requestDetails?.makerComment.userId;
    };

    const getDialogHeadingStatus = (status: UserStatus.UserStatus): string => {
        switch (status) {
            case UserStatus.UserStatus.ACTIVE:
                return t('common.reactivation');
            case UserStatus.UserStatus.DEACTIVATED:
                return t('common.deactivation');
            case UserStatus.UserStatus.ARCHIVED:
                return t('common.archive');
        }
    };

    const onApproveClick = (): void => {
        CheckAgentStatusUpdateRequestStore.setStatus(Status.APPROVED);
        if (store.requestDetails) {
            setRequestDialogTitle(
                t('common.approveStatusRequest', {
                    status: getDialogHeadingStatus(store.requestDetails.updatedUserStatus)
                })
            );
            setIsRequestCheckDialogOpen(true);
        } else {
            loggerStore.debug(
                'Cannot find requestDetails in AgentBasicDetailsStore while approving an agent request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
    };

    const onDenyClick = (): void => {
        CheckAgentStatusUpdateRequestStore.setStatus(Status.DENIED);
        if (store.requestDetails) {
            setRequestDialogTitle(
                t('common.denyStatusRequest', {
                    status: getDialogHeadingStatus(store.requestDetails.updatedUserStatus)
                })
            );
            setIsRequestCheckDialogOpen(true);
        } else {
            loggerStore.debug(
                'Cannot find requestDetails in AgentBasicDetailsStore while denying an agent request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        const requestId = store.requestDetails?.requestId;
        if (requestId) {
            await withdrawRequestStore.withdrawRequest(requestId, comment);
            if (withdrawRequestStore.error) {
                setErrorDialogTitle(t('common.withdrawRequest'));
                switch (withdrawRequestStore.error) {
                    case WithdrawRequestErrors.InvalidRequestId:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyChecked:
                        setErrorDialogMessage(t('common.requestAlreadyChecked'));
                        break;
                    case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                        setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                        setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                        break;
                    case WithdrawRequestErrors.RequestAlreadyDiscarded:
                        setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                        break;
                    case NetworkingError.InternalError:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        break;
                    default:
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                }
            } else {
                // Since the request has been withdrawn, we will fetch the agent details again.
                getAgentDetails();
            }
        } else {
            loggerStore.debug(
                'Cannot find requestId in AgentBasicDetailsStore while withdrawing an agent request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        withdrawRequestStore.removeError();
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    setErrorDialogTitle(null);
                    store.resetError();
                    CheckAgentStatusUpdateRequestStore.resetStore();
                    withdrawRequestStore.removeError();
                }}
            />
        );
    };

    const getPendingCard = (): React.ReactElement => {
        if (store.requestDetails) {
            return (
                <PendingRequestCard
                    onApproveClick={onApproveClick}
                    onDenyClick={onDenyClick}
                    onWithdrawClick={onWithdrawClick}
                    showApproveAndDenySection={showApproveAndDenySection}
                    comment={store.requestDetails.makerComment}
                    showWithdrawSection={showWithdrawSection()}>
                    <Grid container sx={{ marginLeft: '40px', marginTop: '16px' }}>
                        <Grid item xs={6}>
                            <Stack sx={{ ...typography.small2, color: palette.label[300] }}>
                                {t('common.currentStatus')}
                            </Stack>
                            <StatusComponent
                                variant="status-cell"
                                status={getUserStatus(store.requestDetails.currentUserStatus)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Stack sx={{ ...typography.small2, color: palette.label[300] }}>
                                {t('common.updatedStatus')}
                            </Stack>
                            <StatusComponent
                                variant="status-cell"
                                status={getUserStatus(store.requestDetails.updatedUserStatus)}
                            />
                        </Grid>
                    </Grid>
                </PendingRequestCard>
            );
        } else {
            return <></>;
        }
    };

    const onSubmitRequest = async (): Promise<void> => {
        setIsRequestCheckDialogOpen(false);
        setScreenBlockingLoading(true);
        if (store.requestDetails?.requestId) {
            await CheckAgentStatusUpdateRequestStore.checkAgentStatusUpdateRequest(
                store.requestDetails.requestId
            );
        } else {
            loggerStore.debug(
                'Cannot find requestId in AgentBasicDetailsStore while checking an agent request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        if (!CheckAgentStatusUpdateRequestStore.error) {
            CheckAgentStatusUpdateRequestStore.resetStore();
            getAgentDetails();
        } else {
            setErrorDialogTitle(requestDialogTitle);
            switch (CheckAgentStatusUpdateRequestStore.error) {
                case CheckAgentStatusUpdateRequestStoreErrors.NonZeroCommissionAccount:
                    setCountdown(5);
                    setIsRequestCheckDialogOpen(true);
                    setIsShowNonZeroCommissionAccountDetails(true);
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.IncorrectRequestType:
                case CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.BcnUserAccountDisabled:
                    setErrorDialogMessage(
                        t('common.bcnUserAccountDisabled', {
                            id: CheckAgentStatusUpdateRequestStore.bcnUserId
                        })
                    );
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        }
    };

    const onProceedButtonClick = async (): Promise<void> => {
        setIsRequestCheckDialogOpen(false);
        setIsShowNonZeroCommissionAccountDetails(false);
        setScreenBlockingLoading(true);
        if (store.requestDetails?.requestId) {
            await CheckAgentStatusUpdateRequestStore.checkToArchiveAgentUpdateRequestWithCommission(
                store.requestDetails.requestId
            );
        } else {
            loggerStore.debug(
                'Cannot find requestId in AgentBasicDetailsStore while archiving agent with commission'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        if (!CheckAgentStatusUpdateRequestStore.error) {
            CheckAgentStatusUpdateRequestStore.resetStore();
            getAgentDetails();
        } else {
            setErrorDialogTitle(requestDialogTitle);
            switch (CheckAgentStatusUpdateRequestStore.error) {
                case CheckAgentStatusUpdateRequestStoreErrors.CannotCheckSelfRequest:
                    setErrorDialogMessage(t('common.cannotCheckSelfRequest'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.InvalidRequestID:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case CheckAgentStatusUpdateRequestStoreErrors.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        }
    };

    const AgentRequestDialogBox = (): React.ReactElement => {
        return (
            <AgentRequestDialog
                title={requestDialogTitle}
                primaryButtonText={
                    CheckAgentStatusUpdateRequestStore.status === Status.APPROVED
                        ? t('common.approveRequest')
                        : t('common.denyRequest')
                }
                isDialogOpen={isRequestCheckDialogOpen}
                onDialogClose={(): void => {
                    setIsRequestCheckDialogOpen(false);
                    setIsShowNonZeroCommissionAccountDetails(false);
                }}
                onSubmitRequest={onSubmitRequest}
                onProceedButtonClick={onProceedButtonClick}
                countdown={countdown}
                isShowNonZeroCommissionAccountDetails={isShowNonZeroCommissionAccountDetails}
            />
        );
    };

    return (
        <Stack direction="column">
            <LoadingIndicator isLoading={screenBlockingLoading} />
            <PageHeader
                title={t('common.agentDetails')}
                subtitle={t('agencyBanking.viewAgentDetails')}
            />
            {store.error === AgentBasicDetailsStoreErrors.InvalidAgentId ? (
                <ErrorState errorMessage={t('common.somethingWentWrongProcessingRequest')} />
            ) : loading ? (
                <LoadingState />
            ) : (
                <Stack
                    spacing="32px"
                    sx={{ padding: '32px', boxSizing: 'border-box', width: '100%' }}>
                    <BreadcrumbComponent
                        currentLabel={t('common.agentDetails')}
                        sx={{ padding: '0px' }}
                    />
                    {getPendingCard()}
                    {store.basicDetails && agentId && (
                        <AgentBasicDetailsSection
                            agentDetails={store.basicDetails}
                            agentId={agentId}
                        />
                    )}
                    {store.basicDetails &&
                        showRequestSection &&
                        store.basicDetails.status !== UserStatus.UserStatus.ARCHIVED &&
                        agentId && (
                            <AgentProfileManagementSection
                                agentId={agentId}
                                agentStatus={store.basicDetails.status}
                                setScreenLoading={setScreenBlockingLoading}
                                getUpdatedData={(): Promise<void> => getAgentDetails()}
                            />
                        )}
                    {agentId && store.basicDetails && (
                        <AgentRequestHistorySection agentId={agentId} />
                    )}
                    {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
                    {errorDialogMessage && ErrorDialogBox()}
                    {requestDialogTitle && AgentRequestDialogBox()}
                </Stack>
            )}
        </Stack>
    );
});
