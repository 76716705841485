import React from 'react';
import { ErrorComponent } from '../components/ErrorComponent';
import serverOff from '../../../assets/server-off.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const InternalServerError = (): React.ReactElement => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ErrorComponent
            title={t('errors.internalServerError')}
            description={t('errors.internalServerErrorDescription')}
            errorImage={<img src={serverOff} />}
            onGoToHomeClick={(): void => {
                navigate('/');
            }}
        />
    );
};
