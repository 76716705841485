import { Box, SxProps, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';

interface WarningContainerProps {
    text: string;
    sx?: SxProps;
}

export const WarningContainer = ({ text, sx }: WarningContainerProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box
            sx={{
                backgroundColor: palette.warning[100],
                border: `1px solid ${palette.warning[300]}`,
                py: '8px',
                px: '16px',
                borderRadius: '12px',
                ...sx
            }}>
            <Typography sx={{ ...typography.body2, color: palette.warning[300] }}>
                {text}
            </Typography>
        </Box>
    );
};
