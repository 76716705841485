import { CheckFeeDeterminationRuleRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckFeeDeterminationRuleRequestRPCImpl extends CheckFeeDeterminationRuleRequestRPC {
    execute(
        _request: CheckFeeDeterminationRuleRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckFeeDeterminationRuleRequestRPC.Response,
            CheckFeeDeterminationRuleRequestRPC.Errors.Errors
        >
    > {
        const response = new CheckFeeDeterminationRuleRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckFeeDeterminationRuleRequestRPC.Response,
                CheckFeeDeterminationRuleRequestRPC.Errors.Errors
            >
        >;
    }
}
