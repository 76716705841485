import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import errorIcon from '../../../../assets/alert-circle.svg';
import { StyledCenterContainer } from './LoadingState';

export interface ErrorStateProps {
    errorMessage: string;
}

export const ErrorState = ({ errorMessage }: ErrorStateProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <StyledCenterContainer>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <img src={errorIcon} />
            </Box>
            <Typography sx={{ ...typography.body2, color: palette.error[300], marginTop: '6px' }}>
                {errorMessage}
            </Typography>
        </StyledCenterContainer>
    );
};
