import { BOUserComment } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getFullName } from '../../../../utils/StringUtils';
import { getImageUrl } from '../../../../utils/UiUtils';
import { getFormattedTimeDateWithComma } from '../utils/DateUtils';

export const BOUserCommentModel = types.model({
    userId: types.string,
    name: types.string,
    profilePhoto: types.maybeNull(types.string),
    requestedAt: types.string,
    comment: types.string
});

export const getBOUserCommentModel = (
    boUserComment: BOUserComment
): Instance<typeof BOUserCommentModel> => {
    return BOUserCommentModel.create({
        userId: boUserComment.userId.uuid,
        name: getFullName(boUserComment.name),
        profilePhoto: boUserComment.profilePhoto && getImageUrl(boUserComment.profilePhoto),
        requestedAt: getFormattedTimeDateWithComma(new Date(boUserComment.requestedAt.timestamp)),
        comment: boUserComment.comment.text
    });
};
