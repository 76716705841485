import { GetUrlForSignedInUserRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetUrlForSignedInUserRPCImpl extends GetUrlForSignedInUserRPC {
    execute(
        _request: GetUrlForSignedInUserRPC.Request
    ): Promise<
        LeoRPCResult<GetUrlForSignedInUserRPC.Response, GetUrlForSignedInUserRPC.Errors.Errors>
    > {
        const response = new GetUrlForSignedInUserRPC.Response(new URL('https://www.google.com'));
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetUrlForSignedInUserRPC.Response, GetUrlForSignedInUserRPC.Errors.Errors>
        >;
    }
}
