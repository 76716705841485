import { LeoRPCResult, LeoTimestamp, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    BitmapImageType,
    BOUserComment,
    GetGenericFundsTransferDetailsRPC,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    RequestDetails,
    UserName,
    Comment,
    TransactionDetail,
    LocalizedText,
    BOName
} from '@resolut-tech/bcn-rpcs';
import { TransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/transactionDetail';

const getTransactionDetails = (): TransactionDetail[] => {
    return [
        new TransactionDetail(
            new LocalizedText('Transaction Amount'),
            new TransactionDetailEnums.CellType.Bold('MWK 5,000')
        ),
        new TransactionDetail(
            new LocalizedText('From Wallet Type'),
            new TransactionDetailEnums.CellType.Unstyled('System Wallet')
        ),
        new TransactionDetail(
            new LocalizedText('System Wallet Name'),
            new TransactionDetailEnums.CellType.Unstyled('BILLER_AIRTEL_AIRTIME')
        ),
        new TransactionDetail(
            new LocalizedText('From Wallet Currency'),
            new TransactionDetailEnums.CellType.Unstyled('MWK')
        ),
        new TransactionDetail(
            new LocalizedText('To Wallet Type'),
            new TransactionDetailEnums.CellType.Unstyled('User Wallet')
        ),
        new TransactionDetail(
            new LocalizedText('User Wallet Details'),
            new TransactionDetailEnums.CellType.Unstyled('Esther Howard, +2606534982309')
        ),
        new TransactionDetail(
            new LocalizedText('User Wallet ID'),
            new TransactionDetailEnums.CellType.Copy('4cee8447-4812-4165-baf5-038b7fd4d434')
        ),
        new TransactionDetail(
            new LocalizedText('To Wallet Currency'),
            new TransactionDetailEnums.CellType.Unstyled('MWK')
        ),
        new TransactionDetail(
            new LocalizedText('Transaction Fee'),
            new TransactionDetailEnums.CellType.Unstyled('MWK 10')
        ),
        new TransactionDetail(
            new LocalizedText('Linked Transaction ID'),
            new TransactionDetailEnums.CellType.Copy('123e4567-e89b-12d3-a456-426614174000')
        )
    ];
};

export class MockRequestGetGenericFundsTransferDetailsRPC extends GetGenericFundsTransferDetailsRPC {
    execute(
        _request: GetGenericFundsTransferDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetGenericFundsTransferDetailsRPC.Response,
            GetGenericFundsTransferDetailsRPC.Errors.Errors
        >
    > {
        const response = new GetGenericFundsTransferDetailsRPC.Response(
            new RequestDetails.Pending(
                new LeoUUID(),
                new BOUserComment(
                    new LeoUUID(),
                    new UserName(new BOName('Rosie Downey')),
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    ),
                    new LeoTimestamp('2022-09-30T02:03:59Z'),
                    new Comment('The transaction was done by mistake by the user.')
                )
            ),
            false,
            getTransactionDetails()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetGenericFundsTransferDetailsRPC.Response,
                GetGenericFundsTransferDetailsRPC.Errors.Errors
            >
        >;
    }
}
