import React, { createContext, useContext } from 'react';
import { flow, Instance, types } from 'mobx-state-tree';
import { createSignInStore, SignInStore } from '../../../auth/stores/SignInStore';
import { createHomeStore, HomeStore } from '../../../home/store/HomeStore';
import { GetUserPrivilegesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { useGetUserPrivilegesRPCImpl } from '../rpcs/RPC';
import { getPersistedUserPrivileges } from '../../../home/user/UserPrivileges';
import { createNetworkingStore, NetworkingStore } from '../../../networking/store/NetworkingStore';
import { LoggerStore, createLoggerStore } from '../../../../log/LoggerStore';

// Mobx-react recommends injection of stores using React.createContext as compared to using the build in Provider / inject.
// For more info - https://github.com/mobxjs/mobx-react#provider-and-inject

export const RootStore = types
    .model({
        isUserLoggedIn: types.boolean,
        signInStore: SignInStore,
        homeStore: HomeStore,
        loggerStore: LoggerStore,
        networkingStore: NetworkingStore
    })
    .actions((store) => ({
        completeSignInProcess(): void {
            store.signInStore = createSignInStore();
            store.isUserLoggedIn = true;
        },
        signOutUser(): void {
            store.isUserLoggedIn = false;
            store.homeStore = createHomeStore();
        },
        setupUserPrivileges: flow(function* () {
            const request = new GetUserPrivilegesRPC.Request();
            const apiClient = store.networkingStore.useAPIClient();
            const result: LeoRPCResult<
                GetUserPrivilegesRPC.Response,
                GetUserPrivilegesRPC.Errors.Errors
            > = yield useGetUserPrivilegesRPCImpl(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                store.homeStore.setUserPrivileges(response.userId.uuid, response.privileges);
                store.isUserLoggedIn = true;
            } else if (result instanceof LeoRPCResult.Error) {
                // If we are unable to fetch the privileges for a user, we will take them back to the sign-in process
                store.isUserLoggedIn = false;
                store.homeStore = createHomeStore();
            } else {
                store.loggerStore.error(
                    `Unknown error occurred in GetUserPrivilegesRPC with result: ${result}`
                );
            }
        })
    }));

const RootStoreContext = createContext<null | Instance<typeof RootStore>>(null);

const getIsUserLoggedIn = (): boolean => {
    const privileges = getPersistedUserPrivileges();
    if (privileges && privileges.length > 1) {
        return true;
    }
    return false;
};

export const RootStoreProvider = ({
    children
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const rootStore = RootStore.create({
        isUserLoggedIn: getIsUserLoggedIn(),
        signInStore: createSignInStore(),
        loggerStore: createLoggerStore(),
        homeStore: createHomeStore(),
        networkingStore: createNetworkingStore()
    });

    return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
};

export const useRootStore = (): Instance<typeof RootStore> => {
    const store = useContext(RootStoreContext);
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider');
    }
    return store;
};
