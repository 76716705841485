import { RequestBOUserForceResetPasswordRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockRequestBOUserForceResetPasswordRPCImpl extends RequestBOUserForceResetPasswordRPC {
    execute(
        _request: RequestBOUserForceResetPasswordRPC.Request
    ): Promise<
        LeoRPCResult<
            RequestBOUserForceResetPasswordRPC.Response,
            RequestBOUserForceResetPasswordRPC.Errors.Errors
        >
    > {
        const response = new RequestBOUserForceResetPasswordRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                RequestBOUserForceResetPasswordRPC.Response,
                RequestBOUserForceResetPasswordRPC.Errors.Errors
            >
        >;
    }
}
