import { types, Instance } from 'mobx-state-tree';
import { createEvaluateFeeRulesStore, EvaluateFeeRulesStore } from './EvaluateFeeRulesStore';
import {
    createFeeRulesRequestDetailsStore,
    FeeRulesRequestDetailsStore
} from './FeeRulesRequestDetailsStore';
import { UpdateFeeRulesStore, createUpdateFeeRulesStore } from './UpdateFeeRulesStore';
import {
    createViewFeeRulesHistoryStore,
    ViewFeeRulesHistoryStore
} from './ViewFeeRulesHistoryStore';

import { createViewFeeRulesStore, ViewFeeRulesStore } from './ViewFeeRulesStore';

export const FeeRulesStore = types.model({
    updateFeeRulesStore: UpdateFeeRulesStore,
    evaluateFeeRulesStore: EvaluateFeeRulesStore,
    viewFeeRulesStore: ViewFeeRulesStore,
    viewFeeRulesHistoryStore: ViewFeeRulesHistoryStore,
    feeRulesRequestDetailsStore: FeeRulesRequestDetailsStore
});

export const createFeeRulesStore = (): Instance<typeof FeeRulesStore> => {
    return FeeRulesStore.create({
        updateFeeRulesStore: createUpdateFeeRulesStore(),
        viewFeeRulesHistoryStore: createViewFeeRulesHistoryStore(),
        evaluateFeeRulesStore: createEvaluateFeeRulesStore(),
        viewFeeRulesStore: createViewFeeRulesStore(),
        feeRulesRequestDetailsStore: createFeeRulesRequestDetailsStore()
    });
};
