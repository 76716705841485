import { Section } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    SectionRowComponentWithLabel,
    SectionRowComponentWithLabelProp
} from '../../common/components/SectionRowComponentWithLabel';
import {
    getFormattedPhoneNumber,
    getGenderTranslation,
    getUserStatus
} from '../../common/utils/UIUtils';
import { SectionRowCellEnum } from '../../common/types/SectionRowComponentTypes';
import { BOUserBasicDetailsModel, BOUserRequestDetailsModel } from '../store/BOUserDetailStore';
import { BOUserRequestType } from '@resolut-tech/bcn-rpcs';
import { StatusType } from '../../common/components/StatusComponent';
import { getBORoleName } from '../utils/UIUtils';

export interface BOUserBasicDetailsSectionProp {
    userId: string;
    details: Instance<typeof BOUserBasicDetailsModel>;
    requestDetails: Instance<typeof BOUserRequestDetailsModel | null>;
}

export const BOUserBasicDetails = ({
    userId,
    details,
    requestDetails
}: BOUserBasicDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();
    const showDiff = Boolean(requestDetails);

    const showDiffHeader = (): boolean => {
        if (!requestDetails?.requestType) return false;

        switch (requestDetails?.requestType) {
            case BOUserRequestType.BOUserRequestType.ARCHIVE_USER:
            case BOUserRequestType.BOUserRequestType.DEACTIVATE_USER:
            case BOUserRequestType.BOUserRequestType.EDIT_USER:
            case BOUserRequestType.BOUserRequestType.REACTIVATE_USER:
                return true;
            case BOUserRequestType.BOUserRequestType.ADD_USER:
            case BOUserRequestType.BOUserRequestType.FORCE_RESET_PASSWORD:
            case BOUserRequestType.BOUserRequestType.REGENERATE_AUTH_CODE:
                return false;
        }
    };

    const getStatusDiff = (): StatusType | undefined => {
        if (!requestDetails?.requestType) return;

        switch (requestDetails?.requestType) {
            case BOUserRequestType.BOUserRequestType.ARCHIVE_USER:
                return StatusType.ARCHIVED;
            case BOUserRequestType.BOUserRequestType.DEACTIVATE_USER:
                return StatusType.DEACTIVATED;
            case BOUserRequestType.BOUserRequestType.REACTIVATE_USER:
                return StatusType.ACTIVE;
        }
    };

    const getDetailCells = (): React.ReactElement[] => {
        const diffHeader: SectionRowComponentWithLabelProp[] = [
            {
                label: '',
                cell: {
                    type: SectionRowCellEnum.DiffHeader,
                    currentDetailsLabel: t('boUser.currentDetails'),
                    updatedDetailsLabel: t('boUser.updatedDetails')
                }
            }
        ];

        const userDetailSectionCells: SectionRowComponentWithLabelProp[] = [
            ...(showDiffHeader() ? diffHeader : []),
            {
                label: t('common.userPhoto'),
                cell: {
                    type: SectionRowCellEnum.ProfileImage,
                    imageUrl: details.profilePhoto ?? ''
                }
            },
            {
                label: t('common.firstName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: details.firstName,
                    diffText: requestDetails?.firstName,
                    showDiff
                }
            },
            {
                label: t('common.lastName'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: details.lastName,
                    diffText: requestDetails?.lastName,
                    showDiff
                }
            },
            {
                label: t('common.otherNames'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: details.otherNames,
                    diffText: requestDetails?.otherNames,
                    showDiff
                }
            },
            {
                label: t('common.userId'),
                cell: { type: SectionRowCellEnum.Copy, text: userId }
            },
            {
                label: t('common.userStatus'),
                cell: {
                    type: SectionRowCellEnum.UserStatusChip,
                    status: getUserStatus(details.status),
                    updatedStatus: getStatusDiff()
                }
            },
            {
                label: t('common.mobileNumber'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: getFormattedPhoneNumber(details.phoneNumber),
                    diffText:
                        (requestDetails?.phoneNumber &&
                            getFormattedPhoneNumber(requestDetails.phoneNumber)) ??
                        undefined,
                    showDiff
                }
            },
            {
                label: t('common.emailId'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: details.emailId,
                    diffText: requestDetails?.emailId,
                    showDiff,
                    showEllipsis: true
                }
            },
            {
                label: t('common.country'),
                cell: { type: SectionRowCellEnum.Unstyled, text: details.countryName }
            },
            {
                label: t('common.gender'),
                cell: {
                    type: SectionRowCellEnum.Unstyled,
                    text: getGenderTranslation(t, details.gender),
                    diffText:
                        requestDetails?.gender && getGenderTranslation(t, requestDetails.gender),
                    showDiff
                }
            },
            {
                label: t('common.joinDate'),
                cell: { type: SectionRowCellEnum.Unstyled, text: details.joinDate }
            },
            {
                label: t('boUser.userRoles'),
                cell: {
                    type: SectionRowCellEnum.UserRoleChips,
                    roles: details.roles.map(({ workflow, name }) => getBORoleName(workflow, name)),
                    updatedRoles: requestDetails?.roles ?? undefined
                }
            }
        ];

        return userDetailSectionCells.map(({ label, cell }) => (
            <SectionRowComponentWithLabel key={label} label={label} cell={cell} />
        ));
    };

    return (
        <Section
            title={t('common.basicDetails')}
            allowViewChange={false}
            content={getDetailCells()}
        />
    );
};
