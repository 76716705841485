import { GetCountryListRPC, GetCountryListRPCClientImpl } from '@resolut-tech/bcn-rpcs';
import { APIClient } from '@surya-digital/tedwig';
import { MockGetCountryListRPCImpl } from './MockGetCountryListRPCImpl';

const MOCK = false;

export const useGetCountryListRPCClient = (apiClient: APIClient): GetCountryListRPC => {
    if (MOCK) {
        return new MockGetCountryListRPCImpl();
    } else {
        return new GetCountryListRPCClientImpl(apiClient);
    }
};
