import { BCNUserUpdateRequestDetails, UserStatus } from '@resolut-tech/bcn-rpcs';
import { BCNUserUpdateRequestDetailsEnums } from '@resolut-tech/bcn-rpcs/build/back-office/bCNUserUpdateRequestDetails';
import { Instance, types } from 'mobx-state-tree';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';

export const BCNUserUpdateRequestDetailsModel = types.model({
    requestId: types.string,
    currentUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    updatedUserStatus: types.enumeration<UserStatus.UserStatus>(
        'UserStatus',
        Object.values(UserStatus.UserStatus)
    ),
    makerComment: BOUserCommentModel,
    checkerComment: types.maybeNull(BOUserCommentModel),
    status: types.enumeration<BCNUserUpdateRequestDetailsEnums.Status.Status>(
        'BCNUserUpdateRequestDetailsEnums.Status',
        Object.values(BCNUserUpdateRequestDetailsEnums.Status.Status)
    )
});

export const getBCNUserUpdateRequestDetailsModel = (
    requestDetails: BCNUserUpdateRequestDetails
): Instance<typeof BCNUserUpdateRequestDetailsModel> => {
    return BCNUserUpdateRequestDetailsModel.create({
        requestId: requestDetails.requestId.uuid,
        currentUserStatus: requestDetails.currentUserStatus,
        updatedUserStatus: requestDetails.updatedUserStatus,
        makerComment: getBOUserCommentModel(requestDetails.makerComment),
        checkerComment: requestDetails.checkerComment
            ? getBOUserCommentModel(requestDetails.checkerComment)
            : null,
        status: requestDetails.status
    });
};
