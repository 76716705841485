import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { DropdownInputField, DropdownItem, SearchField } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer } from '../../common/components/TableFilterContainer';
import { AgentTransactionSearchFilterOptions } from '../store/AgentTransactionSearchStore';
import {
    getAgentListTransactionStatusOptions,
    getAgentListTransactionTypeOptions,
    getAgentTransactionSearchByOptions,
    getAgentTransactionListInitialFilter
} from '../utils/UIUtils';
import { Button } from '../../../common/components/Button';

interface AgentTransactionListFilterProps {
    filter: Instance<typeof AgentTransactionSearchFilterOptions>;
    setFilter: (newFilter: Instance<typeof AgentTransactionSearchFilterOptions>) => void;
    minimumSearchTextLength: number;
}

export const AgentTransactionListFilter = ({
    filter,
    setFilter,
    minimumSearchTextLength
}: AgentTransactionListFilterProps): React.ReactElement => {
    const { t } = useTranslation();
    const initialFilter = getAgentTransactionListInitialFilter(t);
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter = JSON.stringify(filter) === JSON.stringify(initialFilter);
    const searchByOptions = getAgentTransactionSearchByOptions(t);
    const [searchText, setSearchText] = useState(filter.searchText);
    const [searchBy, setSearchBy] = useState(filter.searchBy);
    const [transactionStatus, setTransactionStatus] = useState(filter.transactionStatus);
    const [transactionType, setTransactionType] = useState(filter.transactionType);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        setSearchText(filter.searchText);
        setSearchBy(filter.searchBy);
        setTransactionStatus(filter.transactionStatus);
        setTransactionType(filter.transactionType);
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setIsSearchDisabled(false);
        setSearchText(inputValue);
    };

    const onSearchClear = (): void => {
        setSearchText('');
        setIsSearchDisabled(false);
    };

    const onSearchByDropdownChange = (selected: DropdownItem): void => {
        setSearchBy(selected.value);
        setIsSearchDisabled(false);
        onSearchClear();
    };

    const onTransactionStatusDropdownChange = (selected: DropdownItem): void => {
        setIsSearchDisabled(false);
        setTransactionStatus(selected.value);
    };

    const onTransactionTypeDropdownChange = (selected: DropdownItem): void => {
        setIsSearchDisabled(false);
        setTransactionType(selected.value);
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchByDropdown"
                        value={searchBy}
                        onSelect={onSearchByDropdownChange}
                        label={t('common.searchBy')}
                        options={searchByOptions}
                    />
                </Grid>
                <Grid item xs={8}>
                    <SearchField
                        name="searchField"
                        value={searchText}
                        isDisabled={false}
                        onTextChange={onSearchChange}
                        onClear={onSearchClear}
                        allowClearField={true}
                        placeholder={`${t('common.searchUsing')} ${
                            searchByOptions.find((item) => item.value === searchBy)!.name
                        }`}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="transactionTypeDropdown"
                        value={transactionType}
                        onSelect={onTransactionTypeDropdownChange}
                        label={t('common.tableHeader.transactionType')}
                        options={getAgentListTransactionTypeOptions(t)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="transactionStatusDropdown"
                        value={transactionStatus}
                        onSelect={onTransactionStatusDropdownChange}
                        label={t('agencyBanking.transactionStatus')}
                        options={getAgentListTransactionStatusOptions(t)}
                    />
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="flex-end" alignItems="center">
                    <Stack direction="row" spacing={1}>
                        <Button
                            name="plain-color"
                            variant="plain-color"
                            color="error"
                            size="medium"
                            title={t('common.reset')}
                            isDisabled={isInitialFilter}
                            onClick={(): void => {
                                setIsSearchDisabled(true);
                                setFilter(initialFilter);
                            }}
                        />
                        <Button
                            name="filled"
                            variant="filled"
                            size="medium"
                            isDisabled={
                                (searchText &&
                                    searchText.trim().length < minimumSearchTextLength) ||
                                isSearchDisabled ||
                                searchText?.trim().length === 0
                            }
                            title={t('common.search')}
                            onClick={(): void => {
                                setIsSearchDisabled(true);

                                setFilter(
                                    AgentTransactionSearchFilterOptions.create({
                                        searchBy,
                                        searchText: searchText.trim(),
                                        transactionStatus,
                                        transactionType
                                    })
                                );
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
