import {
    GetBCNUserRequestsPaginationResponse,
    GetBCNUserRequestsRPC,
    GetBCNUserRequestsSortOrder,
    IntermediateRequestStatus,
    ItemsPerPage,
    PageIndex,
    RequestStatus
} from '@resolut-tech/bcn-rpcs';
import { GetBCNUserRequestsSortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getBCNUserRequestsSortOrder';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types, cast } from 'mobx-state-tree';
import { getPGFullName } from '../../../../utils/StringUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { useGetBCNUserRequestsRPCClient } from '../rpcs/RPC';
import { getSortOrder } from '../../common/utils/TableFilterUtils';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum BCNUserViewRequestsErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const BCNUserViewRequestsModal = types.model({
    userId: types.string,
    requestedBy: types.string,
    evaluatedBy: types.maybeNull(types.string),
    requestedAt: types.string,
    status: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    )
});

const getBCNUserRequestsModal = (
    response: GetBCNUserRequestsPaginationResponse
): Instance<typeof BCNUserViewRequestsModal> => {
    return BCNUserViewRequestsModal.create({
        userId: response.bcnUserId.uuid,
        requestedBy: getPGFullName(response.requestedBy),
        evaluatedBy: response.checkedBy ? getPGFullName(response.checkedBy) : null,
        requestedAt: getFormattedTimeDateWithComma(new Date(response.requestedAt.timestamp)),
        status: response.requestStatus
    });
};

export const BCNUserViewRequestsStore = types
    .model({
        viewRequests: types.array(BCNUserViewRequestsModal),
        requestStatus: types.string,
        totalItems: types.number,
        error: types.maybeNull(
            types.union(
                types.enumeration<BCNUserViewRequestsErrors>(
                    'BCNUserRequestsErrors',
                    Object.values(BCNUserViewRequestsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        updateRequestStatus(requestStatus: string): void {
            store.requestStatus = requestStatus;
        },
        removeError(): void {
            store.error = null;
        },
        getViewRequestList: flow(function* (
            pageIndex: number,
            itemsPerPage: number,
            sortOrder?: 'asc' | 'desc',
            requestStatusDropdownValue?: string
        ) {
            const loggerStore = getLoggerStore(store);
            let requestStatus = null;
            if (RequestStatus.isInstanceOf(requestStatusDropdownValue)) {
                requestStatus = IntermediateRequestStatus.fromDTO({
                    case: requestStatusDropdownValue ?? store.requestStatus
                });
            }
            const request = new GetBCNUserRequestsRPC.Request(
                [
                    new GetBCNUserRequestsSortOrder(
                        0,
                        GetBCNUserRequestsSortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                requestStatus,
                new ItemsPerPage(itemsPerPage),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetBCNUserRequestsRPC.Response,
                GetBCNUserRequestsRPC.Errors.InvalidPageIndex
            > = yield useGetBCNUserRequestsRPCClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const viewRequestList = response.getBCNUserRequestsPaginationResponse.map(
                    (requestDetails: GetBCNUserRequestsPaginationResponse) => {
                        return getBCNUserRequestsModal(requestDetails);
                    }
                );
                store.viewRequests = cast(viewRequestList);
                store.totalItems = response.totalItems;
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case BCNUserViewRequestsErrors.InvalidPageIndex:
                        store.error = BCNUserViewRequestsErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetBCNUserRequestsRPC `
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetBCNUserRequestsRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }))
    .views(() => ({
        itemsPerPage: (): number => 10
    }));

export const createBCNUserViewRequestsStore = (): Instance<typeof BCNUserViewRequestsStore> => {
    return BCNUserViewRequestsStore.create({
        requestStatus: RequestStatus.RequestStatus.PENDING,
        totalItems: 0,
        viewRequests: []
    });
};
