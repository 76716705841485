import {
    SignInBOUserRPC,
    SignInBOUserRPCClientImpl,
    ConfirmAuthCodeRPC,
    ConfirmAuthCodeRPCClientImpl,
    SubmitResetPasswordRPCClientImpl,
    SubmitResetPasswordRPC,
    BackOfficeConfirmSignInOTPRPC,
    BackOfficeConfirmSignInOTPRPCClientImpl,
    BackOfficeResendSignInOTPRPC,
    BackOfficeResendSignInOTPRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockConfirmAuthCodeRPCImpl } from './MockConfirmAuthCodeRPCImpl';
import { MockConfirmSignInOTPRPCImpl } from './MockConfirmSignInOTPRPCImpl';
import { MockResendSignInOTPRPCImpl } from './MockResendSignInOTPRPCImpl';
import { MockSubmitResetPasswordRPCImpl } from './MockSubmitResetPasswordImpl';
import { MockSignInBOUserRPCImpl } from './MockSignInBOUserRPCImpl';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useSignInBoUserRPCClient = (apiClient: APIClient): SignInBOUserRPC => {
    if (MOCK) {
        return new MockSignInBOUserRPCImpl();
    } else {
        return new SignInBOUserRPCClientImpl(apiClient);
    }
};

export const useConfirmAuthCodeRPCClient = (apiClient: APIClient): ConfirmAuthCodeRPC => {
    if (MOCK) {
        return new MockConfirmAuthCodeRPCImpl();
    } else {
        return new ConfirmAuthCodeRPCClientImpl(apiClient);
    }
};

export const useSubmitResetPasswordRPCClient = (apiClient: APIClient): SubmitResetPasswordRPC => {
    if (MOCK) {
        return new MockSubmitResetPasswordRPCImpl();
    } else {
        return new SubmitResetPasswordRPCClientImpl(apiClient);
    }
};

export const useConfirmSignInOTPRPCClient = (
    apiClient: APIClient
): BackOfficeConfirmSignInOTPRPC => {
    if (MOCK) {
        return new MockConfirmSignInOTPRPCImpl();
    } else {
        return new BackOfficeConfirmSignInOTPRPCClientImpl(apiClient);
    }
};

export const useResendSignInOTPRPCClient = (apiClient: APIClient): BackOfficeResendSignInOTPRPC => {
    if (MOCK) {
        return new MockResendSignInOTPRPCImpl();
    } else {
        return new BackOfficeResendSignInOTPRPCClientImpl(apiClient);
    }
};
