import {
    BackOfficeChangePasswordRPC,
    BackOfficeChangePasswordRPCClientImpl,
    BackOfficeChangeProfileImageRPC,
    BackOfficeChangeProfileImageRPCClientImpl,
    DeleteProfileImageRPC,
    DeleteProfileImageRPCClientImpl,
    GetDocumentIdForSignedInUserRPC,
    GetDocumentIdForSignedInUserRPCClientImpl,
    GetProfileDetailsRPC,
    GetProfileDetailsRPCClientImpl,
    GetUrlForSignedInUserRPC,
    GetUrlForSignedInUserRPCClientImpl,
    RegenerateAuthCodesRPC,
    RegenerateAuthCodesRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockBackOfficeChangeProfileImageRPC } from './MockBackOfficeChangeProfileImageRPC';
import { MockBackOfficeChangePasswordRPC } from './MockBackOfficeChangePasswordRPC';
import { MockGetProfileDetailsRPC } from './MockGetProfileDetailsRPC';
import { MockRegenerateAuthCodesRPC } from './MockRegenerateAuthCodesRPC';
import { MockDeleteProfileImageRPC } from './MockDeleteProfileImageRPC';
import { MockGetUrlForSignedInUserRPCImpl } from './MockGetURLForSignedInUserRPCImpl';
import { MockGetDocumentIdForSignedInUserRPCImpl } from './MockGetDocumentIdForSignedInUserRPCImpl';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useGetProfileDetailsRPClient = (apiClient: APIClient): GetProfileDetailsRPC => {
    if (MOCK) {
        return new MockGetProfileDetailsRPC();
    } else {
        return new GetProfileDetailsRPCClientImpl(apiClient);
    }
};

export const useBackOfficeChangePasswordRPCClient = (
    apiClient: APIClient
): BackOfficeChangePasswordRPC => {
    if (MOCK) {
        return new MockBackOfficeChangePasswordRPC();
    } else {
        return new BackOfficeChangePasswordRPCClientImpl(apiClient);
    }
};

export const useRegenerateAuthCodesRPCClient = (apiClient: APIClient): RegenerateAuthCodesRPC => {
    if (MOCK) {
        return new MockRegenerateAuthCodesRPC();
    } else {
        return new RegenerateAuthCodesRPCClientImpl(apiClient);
    }
};

export const useBackOfficeChangeProfileImageRPCClient = (
    apiClient: APIClient
): BackOfficeChangeProfileImageRPC => {
    if (MOCK) {
        return new MockBackOfficeChangeProfileImageRPC();
    } else {
        return new BackOfficeChangeProfileImageRPCClientImpl(apiClient);
    }
};

export const useDeleteProfileImageRPCClient = (apiClient: APIClient): DeleteProfileImageRPC => {
    if (MOCK) {
        return new MockDeleteProfileImageRPC();
    } else {
        return new DeleteProfileImageRPCClientImpl(apiClient);
    }
};

export const useGetURLForSignedInUserRPCClient = (
    apiClient: APIClient
): GetUrlForSignedInUserRPC => {
    if (MOCK) {
        return new MockGetUrlForSignedInUserRPCImpl();
    } else {
        return new GetUrlForSignedInUserRPCClientImpl(apiClient);
    }
};

export const useGetDocumentIdForSignedInUserRPCClient = (
    apiClient: APIClient
): GetDocumentIdForSignedInUserRPC => {
    if (MOCK) {
        return new MockGetDocumentIdForSignedInUserRPCImpl();
    } else {
        return new GetDocumentIdForSignedInUserRPCClientImpl(apiClient);
    }
};
