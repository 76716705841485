import { AgentTransactionDetail, LocalizedText } from '@resolut-tech/bcn-rpcs';
import { AgentTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/agentTransactionDetail';
import { types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';

export interface AgentTransactionDetailView {
    label: string;
    cellType: AgentTransactionDetailEnums.CellType.CellType;
}

// The LocalizedText that we get from the rpc is converted to en string while parsing the response data to AgentTransactionDetailView type from AgentTransactionDetail type.
// localizedLabel variable holds this LocalizedText so that the value can be used while converting the data back to the type AgentTransactionDetail.
let localizedLabel: LocalizedText;

// types.custom needs fromSnapshot and toSnapshot methods are mandatory for using types.custom.
// Since CellType.CellType enum is a complex types which is not a just string enum. It would be difficult to create model for it. Hence creating a custom type.
export const AgentTransactionDetailModel = types.custom<
    AgentTransactionDetail,
    AgentTransactionDetailView
>({
    name: 'AgentTransactionDetailType',
    fromSnapshot(snapshot: AgentTransactionDetail): AgentTransactionDetailView {
        localizedLabel = snapshot.label;
        if (snapshot.cellType instanceof AgentTransactionDetailEnums.CellType.ProfilePhoto) {
            return {
                label: getTranslatedString(snapshot.label),
                cellType: snapshot.cellType
            };
        }
        return {
            label: getTranslatedString(snapshot.label),
            cellType: snapshot.cellType
        };
    },
    toSnapshot(value: AgentTransactionDetailView): AgentTransactionDetail {
        return new AgentTransactionDetail(localizedLabel, value.cellType);
    },
    isTargetType(value: AgentTransactionDetail | AgentTransactionDetail): boolean {
        return !(value instanceof AgentTransactionDetail);
    },
    getValidationMessage(_snapshot: AgentTransactionDetail): string {
        return '';
    }
});
