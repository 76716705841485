import {
    GetAgentTransactionDetailsRPC,
    GetAgentTransactionDetailsRPCClientImpl,
    GetAgentTransactionHistoryRPC,
    GetAgentTransactionHistoryRPCClientImpl,
    GetAgentTransactionListRPC,
    GetAgentTransactionListRPCClientImpl,
    RequestToMoveFundsOutOfYafikaRPC,
    RequestToMoveFundsOutOfYafikaRPCClientImpl,
    CheckMoveFundsOutOfYafikaRequestRPC,
    CheckMoveFundsOutOfYafikaRequestRPCClientImpl,
    WithdrawAgentRequestRPCClientImpl,
    WithdrawAgentRequestRPC,
    GetAgentsRPC,
    GetAgentsRPCClientImpl,
    GetAgentDetailsRPC,
    GetAgentDetailsRPCClientImpl,
    GetAgentDetailsRequestHistoryRPC,
    GetAgentDetailsRequestHistoryRPCClientImpl,
    CheckAgentStatusUpdateRequestRPC,
    CheckAgentStatusUpdateRequestRPCClientImpl,
    CheckToArchiveAgentUpdateRequestWithCommissionRPC,
    CheckToArchiveAgentUpdateRequestWithCommissionRPCClientImpl,
    RequestToArchiveAgentRPC,
    RequestToArchiveAgentRPCClientImpl,
    RequestToReactivateOrDeactivateAgentRPC,
    RequestToReactivateOrDeactivateAgentRPCClientImpl,
    GetAgentRequestsRPCClientImpl,
    GetAgentRequestsRPC,
    RequestToArchiveAgentWithBalanceRPC,
    RequestToArchiveAgentWithBalanceRPCClientImpl,
    GetFundsTransferOutOfYafikaRequestsHistoryRPC,
    GetFundsTransferOutOfYafikaRequestsHistoryRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockGetAgentTransactionDetailsRPCImpl } from './MockGetAgentTransactionDetailsRPCImpl';
import { MockGetAgentTransactionHistoryRPCImpl } from './MockGetAgentTransactionHistoryRPCImpl';
import { MockGetAgentTransactionListRPCImpl } from './MockGetAgentTransactionListRPCImpl';
import { MockWithdrawAgentRequestRPCImpl } from './MockWithdrawAgentRequestRPCImpl';
import { MockRequestToMoveFundsOutOfYafikaRPCImpl } from './MockRequestToMoveFundsOutOfYafikaRPCImpl';
import { APIClient } from '@surya-digital/tedwig';
import { MockGetFundsTransferOutOfYafikaRequestsHistoryRPCImpl } from './MockGetFundsTransferOutOfYafikaRequestsHistoryRPCImpl';
import { MockCheckMoveFundsOutOfYafikaRequestRPCImpl } from './MockCheckMoveFundsOutOfYafikaRequestRPCImpl';
import { MockGetAgentRequestsRPCImpl } from './MockGetAgentRequestsRPCImpl';
import { MockGetAgentsRPCImpl } from './MockGetAgentsRPCImpl';
import { MockGetAgentDetailsRPCImpl } from './MockGetAgentDetailsRPCImpl';
import { MockGetAgentDetailsRequestHistoryRPCImpl } from './MockGetAgentDetailsRequestHistoryRPCImpl';
import { MockCheckAgentStatusUpdateRequestRPCImpl } from './MockCheckAgentStatusUpdateRequestRPCImpl';
import { MockCheckToArchiveAgentUpdateRequestWithCommissionRPCImpl } from './MockCheckToArchiveAgentUpdateRequestWithCommissionRPCImpl';
import { MockRequestToArchiveAgentRPCImpl } from './MockRequestToArchiveAgentRPCImpl';
import { MockRequestToReactivateOrDeactivateAgentRPCImpl } from './MockRequestToReactivateOrDeactivateAgentRPCImpl';
import { MockRequestToArchiveAgentWithBalanceRPCImpl } from './MockRequestToArchiveAgentWithBalanceRPCImpl';

const MOCK = false;

export const useGetAgentTransactionListRPCClient = (
    apiClient: APIClient
): GetAgentTransactionListRPC => {
    if (MOCK) {
        return new MockGetAgentTransactionListRPCImpl();
    } else {
        return new GetAgentTransactionListRPCClientImpl(apiClient);
    }
};

export const useGetAgentTransactionDetailsRPCClient = (
    apiClient: APIClient
): GetAgentTransactionDetailsRPC => {
    if (MOCK) {
        return new MockGetAgentTransactionDetailsRPCImpl();
    } else {
        return new GetAgentTransactionDetailsRPCClientImpl(apiClient);
    }
};

export const useGetAgentTransactionHistoryRPCClient = (
    apiClient: APIClient
): GetAgentTransactionHistoryRPC => {
    if (MOCK) {
        return new MockGetAgentTransactionHistoryRPCImpl();
    } else {
        return new GetAgentTransactionHistoryRPCClientImpl(apiClient);
    }
};

export const useWithdrawAgentRequestRPCClient = (apiClient: APIClient): WithdrawAgentRequestRPC => {
    if (MOCK) {
        return new MockWithdrawAgentRequestRPCImpl();
    } else {
        return new WithdrawAgentRequestRPCClientImpl(apiClient);
    }
};

export const useRequestToMoveFundsOutOfYafikaRPCClient = (
    apiClient: APIClient
): RequestToMoveFundsOutOfYafikaRPC => {
    if (MOCK) {
        return new MockRequestToMoveFundsOutOfYafikaRPCImpl();
    } else {
        return new RequestToMoveFundsOutOfYafikaRPCClientImpl(apiClient);
    }
};

export const useGetFundsTransferOutOfYafikaRequestsHistoryRPCClient = (
    apiClient: APIClient
): GetFundsTransferOutOfYafikaRequestsHistoryRPC => {
    if (MOCK) {
        return new MockGetFundsTransferOutOfYafikaRequestsHistoryRPCImpl();
    } else {
        return new GetFundsTransferOutOfYafikaRequestsHistoryRPCClientImpl(apiClient);
    }
};

export const useCheckMoveFundsOutOfYafikaRequestRPCClient = (
    apiClient: APIClient
): CheckMoveFundsOutOfYafikaRequestRPC => {
    if (MOCK) {
        return new MockCheckMoveFundsOutOfYafikaRequestRPCImpl();
    } else {
        return new CheckMoveFundsOutOfYafikaRequestRPCClientImpl(apiClient);
    }
};

export const useGetAgentRequestsRPCClient = (apiClient: APIClient): GetAgentRequestsRPC => {
    if (MOCK) {
        return new MockGetAgentRequestsRPCImpl();
    } else {
        return new GetAgentRequestsRPCClientImpl(apiClient);
    }
};

export const useGetAgentsRPCClient = (apiClient: APIClient): GetAgentsRPC => {
    if (MOCK) {
        return new MockGetAgentsRPCImpl();
    } else {
        return new GetAgentsRPCClientImpl(apiClient);
    }
};

export const useGetAgentDetailsRPCClient = (apiClient: APIClient): GetAgentDetailsRPC => {
    if (MOCK) {
        return new MockGetAgentDetailsRPCImpl();
    } else {
        return new GetAgentDetailsRPCClientImpl(apiClient);
    }
};

export const useGetAgentDetailsRequestHistoryRPCClient = (
    apiClient: APIClient
): GetAgentDetailsRequestHistoryRPC => {
    if (MOCK) {
        return new MockGetAgentDetailsRequestHistoryRPCImpl();
    } else {
        return new GetAgentDetailsRequestHistoryRPCClientImpl(apiClient);
    }
};

export const useCheckAgentStatusUpdateRequestRPCClient = (
    apiClient: APIClient
): CheckAgentStatusUpdateRequestRPC => {
    if (MOCK) {
        return new MockCheckAgentStatusUpdateRequestRPCImpl();
    } else {
        return new CheckAgentStatusUpdateRequestRPCClientImpl(apiClient);
    }
};

export const useCheckToArchiveAgentUpdateRequestWithCommissionRPCClient = (
    apiClient: APIClient
): CheckToArchiveAgentUpdateRequestWithCommissionRPC => {
    if (MOCK) {
        return new MockCheckToArchiveAgentUpdateRequestWithCommissionRPCImpl();
    } else {
        return new CheckToArchiveAgentUpdateRequestWithCommissionRPCClientImpl(apiClient);
    }
};

export const useRequestToArchiveAgentRPCClient = (
    apiClient: APIClient
): RequestToArchiveAgentRPC => {
    if (MOCK) {
        return new MockRequestToArchiveAgentRPCImpl();
    } else {
        return new RequestToArchiveAgentRPCClientImpl(apiClient);
    }
};

export const useRequestToReactivateOrDeactivateAgentRPCClient = (
    apiClient: APIClient
): RequestToReactivateOrDeactivateAgentRPC => {
    if (MOCK) {
        return new MockRequestToReactivateOrDeactivateAgentRPCImpl();
    } else {
        return new RequestToReactivateOrDeactivateAgentRPCClientImpl(apiClient);
    }
};

export const useRequestToArchiveAgentWithBalanceRPCClient = (
    apiClient: APIClient
): RequestToArchiveAgentWithBalanceRPC => {
    if (MOCK) {
        return new MockRequestToArchiveAgentWithBalanceRPCImpl();
    } else {
        return new RequestToArchiveAgentWithBalanceRPCClientImpl(apiClient);
    }
};
