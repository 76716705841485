import { Instance } from 'mobx-state-tree';
import { TransactionDetailStore } from './TransactionDetailStore';
import { useRootStore } from '../../../store/root/store/RootStore';
import { TransactionSearchStore } from './TransactionSearchStore';
import { LinkedTransactionsStore } from '../../common/store/LinkedTransactionsStore';
import { RefundHistoryStore } from './RefundHistoryStore';
import { RequestTransactionRefundStore } from './RequestTransactionRefundStore';
import { RefundRequestsStore } from './RefundRequestsStore';
import { CheckRequestDetailsStore } from './CheckRequestDetailsStore';
import { RefundWithdrawRequestStore } from './RefundWithdrawRequestStore';

// To avoid cyclic dependency caused by importing stores, store hooks have been added to a different file

export const useTransactionDetailStore = (): Instance<typeof TransactionDetailStore> => {
    return useRootStore().homeStore.transaction.transactionDetailStore;
};

export const useTransactionSearchStore = (): Instance<typeof TransactionSearchStore> => {
    return useRootStore().homeStore.transaction.transactionSearchStore;
};

export const useLinkedTransactionsStore = (): Instance<typeof LinkedTransactionsStore> => {
    return useTransactionDetailStore().linkedTransactionStore;
};

export const useRefundHistoryStore = (): Instance<typeof RefundHistoryStore> => {
    return useTransactionDetailStore().refundHistoryStore;
};

export const useRequestTransactionRefundStore = (): Instance<
    typeof RequestTransactionRefundStore
> => {
    return useRootStore().homeStore.transaction.requestTransactionRefundStore;
};

export const useRefundRequestsStore = (): Instance<typeof RefundRequestsStore> => {
    return useRootStore().homeStore.transaction.refundRequestsStore;
};

export const useCheckRequestDetailsStore = (): Instance<typeof CheckRequestDetailsStore> => {
    return useRootStore().homeStore.transaction.checkRequestDetailsStore;
};

export const useRefundWithdrawRequestStore = (): Instance<typeof RefundWithdrawRequestStore> => {
    return useRootStore().homeStore.transaction.refundWithdrawRequestStore;
};
