import {
    CheckerResponse,
    CheckGenericFundsTransferRequestRPC,
    Comment,
    GetCheckerGenericFundsTransferSummaryRPC
} from '@resolut-tech/bcn-rpcs';
import { CheckerResponseEnums } from '@resolut-tech/bcn-rpcs/build/back-office/checkerResponse';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types } from 'mobx-state-tree';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { AmountModel, getAmountModel } from '../../common/models/AmountModel';
import {
    useCheckGenericFundsTransferRequestRPCClient,
    useGetCheckerGenericFundsTransferSummaryRPCClient
} from '../rpcs/RPC';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';
import { LeoErrors } from '../../common/errors/LeoErrors';

export enum CheckRequestErrors {
    InvalidRequestId = 'INVALID_REQUEST_ID',
    UnableToPerformExchange = 'UNABLE_TO_PERFORM_EXCHANGE',
    InsufficientBalance = 'INSUFFICIENT_BALANCE',
    SenderAccountInactive = 'SENDER_ACCOUNT_INACTIVE',
    SenderProfileDisabled = 'SENDER_PROFILE_DISABLED',
    RequestAlreadyChecked = 'REQUEST_ALREADY_CHECKED',
    RequestAlreadyWithdrawn = 'REQUEST_ALREADY_WITHDRAWN',
    ReceivingAccountWouldCrossLimit = 'RECEIVING_ACCOUNT_WOULD_CROSS_LIMIT',
    RequestUnderProcess = 'REQUEST_UNDER_PROCESS',
    InvalidExchangeRateId = 'INVALID_EXCHANGE_RATE_ID',
    CannotCheckSelfRequest = 'CANNOT_CHECK_SELF_REQUEST',
    RecipientAccountInactive = 'RECIPIENT_ACCOUNT_INACTIVE',
    RecipientProfileDisabled = 'RECIPIENT_PROFILE_DISABLED',
    RequestAlreadyDiscarded = 'REQUEST_ALREADY_DISCARDED',
    RecipientProfileArchived = 'RECIPIENT_PROFILE_ARCHIVED',
    SenderProfileArchived = 'SENDER_PROFILE_ARCHIVED'
}

export const CheckRequestStore = types
    .model({
        amountDebited: types.maybeNull(AmountModel),
        amountCredited: types.maybeNull(AmountModel),
        amount: types.maybeNull(AmountModel),
        fee: types.maybeNull(AmountModel),
        exchangeRateId: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<CheckRequestErrors>(
                    'CheckRequestErrors',
                    Object.values(CheckRequestErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        resetStore(): void {
            store.fee = null;
            store.amount = null;
            store.amountDebited = null;
            store.amountCredited = null;
            store.comment = null;
            store.error = null;
        },
        setComment(comment: string): void {
            store.comment = comment;
        },
        getGenericFundsTransferSummary: flow(function* (requestId: string) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const requestUUID = new LeoUUID(requestId);
                const request = new GetCheckerGenericFundsTransferSummaryRPC.Request(requestUUID);
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    GetCheckerGenericFundsTransferSummaryRPC.Response,
                    GetCheckerGenericFundsTransferSummaryRPC.Errors.Errors
                > = yield useGetCheckerGenericFundsTransferSummaryRPCClient(apiClient).execute(
                    request
                );
                if (result instanceof LeoRPCResult.Response) {
                    const { response } = result;
                    store.amountDebited = getAmountModel(response.amountDebited);
                    store.amountCredited = getAmountModel(response.amountCredited);
                    store.amount = getAmountModel(response.amount);
                    store.fee = response.fee && getAmountModel(response.fee);
                    store.exchangeRateId = response.exchangeRateId?.uuid ?? null;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case CheckRequestErrors.InvalidRequestId:
                            store.error = CheckRequestErrors.InvalidRequestId;
                            break;
                        case CheckRequestErrors.UnableToPerformExchange:
                            store.error = CheckRequestErrors.UnableToPerformExchange;
                            break;
                        case CheckRequestErrors.RequestAlreadyChecked:
                            store.error = CheckRequestErrors.RequestAlreadyChecked;
                            break;
                        case CheckRequestErrors.RequestAlreadyWithdrawn:
                            store.error = CheckRequestErrors.RequestAlreadyWithdrawn;
                            break;
                        case CheckRequestErrors.RequestUnderProcess:
                            store.error = CheckRequestErrors.RequestUnderProcess;
                            break;
                        case CheckRequestErrors.CannotCheckSelfRequest:
                            store.error = CheckRequestErrors.CannotCheckSelfRequest;
                            break;
                        case CheckRequestErrors.RequestAlreadyDiscarded:
                            store.error = CheckRequestErrors.RequestAlreadyDiscarded;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetCheckerGenericFundsTransferSummaryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in GetCheckerGenericFundsTransferSummaryRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckRequestErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in GetCheckerGenericFundsTransferSummaryRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in GetCheckerGenericFundsTransferSummaryRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        }),
        checkGenericFundTransferRequest: flow(function* (
            requestId: string,
            status: CheckerResponseEnums.Status.Status
        ) {
            const loggerStore = getLoggerStore(store);
            store.error = null;
            try {
                const requestUUID = new LeoUUID(requestId);
                const request = new CheckGenericFundsTransferRequestRPC.Request(
                    requestUUID,
                    store.exchangeRateId !== null ? new LeoUUID(store.exchangeRateId) : null,
                    // checkGenericFundTransferRequest function is called only after comment is set. Hence store.comment cannot be null here.
                    new CheckerResponse(status, new Comment(store.comment!))
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    CheckGenericFundsTransferRequestRPC.Response,
                    CheckGenericFundsTransferRequestRPC.Errors.Errors
                > = yield useCheckGenericFundsTransferRequestRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case CheckRequestErrors.InvalidRequestId:
                            store.error = CheckRequestErrors.InvalidRequestId;
                            break;
                        case CheckRequestErrors.InsufficientBalance:
                            store.error = CheckRequestErrors.InsufficientBalance;
                            break;
                        case CheckRequestErrors.SenderAccountInactive:
                            store.error = CheckRequestErrors.SenderAccountInactive;
                            break;
                        case CheckRequestErrors.SenderProfileDisabled:
                            store.error = CheckRequestErrors.SenderProfileDisabled;
                            break;
                        case CheckRequestErrors.UnableToPerformExchange:
                            store.error = CheckRequestErrors.UnableToPerformExchange;
                            break;
                        case CheckRequestErrors.RequestAlreadyChecked:
                            store.error = CheckRequestErrors.RequestAlreadyChecked;
                            break;
                        case CheckRequestErrors.ReceivingAccountWouldCrossLimit:
                            store.error = CheckRequestErrors.ReceivingAccountWouldCrossLimit;
                            break;
                        case CheckRequestErrors.RequestUnderProcess:
                            store.error = CheckRequestErrors.RequestUnderProcess;
                            break;
                        case CheckRequestErrors.RequestAlreadyWithdrawn:
                            store.error = CheckRequestErrors.RequestAlreadyWithdrawn;
                            break;
                        case CheckRequestErrors.InvalidExchangeRateId:
                            store.error = CheckRequestErrors.InvalidExchangeRateId;
                            break;
                        case CheckRequestErrors.CannotCheckSelfRequest:
                            store.error = CheckRequestErrors.CannotCheckSelfRequest;
                            break;
                        case CheckRequestErrors.RecipientAccountInactive:
                            store.error = CheckRequestErrors.RecipientAccountInactive;
                            break;
                        case CheckRequestErrors.RecipientProfileDisabled:
                            store.error = CheckRequestErrors.RecipientProfileDisabled;
                            break;
                        case CheckRequestErrors.RequestAlreadyDiscarded:
                            store.error = CheckRequestErrors.RequestAlreadyDiscarded;
                            break;
                        case CheckRequestErrors.SenderProfileArchived:
                            store.error = CheckRequestErrors.SenderProfileArchived;
                            break;
                        case CheckRequestErrors.RecipientProfileArchived:
                            store.error = CheckRequestErrors.RecipientProfileArchived;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckGenericFundsTransferRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in CheckGenericFundsTransferRequestRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                if (error instanceof Error) {
                    switch (error.name) {
                        case LeoErrors.InvalidLeoUUIDError:
                            store.error = CheckRequestErrors.InvalidRequestId;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in CheckGenericFundsTransferRequestRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error: ${error} occurred in CheckGenericFundsTransferRequestRPC`
                    );
                    store.error = NetworkingError.InternalError;
                }
            }
        })
    }));

export const createCheckRequestStore = (): Instance<typeof CheckRequestStore> => {
    return CheckRequestStore.create();
};
