import { Stack } from '@mui/material';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { PageHeader } from '../../common/components/PageHeader';
import { StatusComponent } from '../../common/components/StatusComponent';
import { getStatusTypeFromRequestStatus } from '../../common/utils/StatusUIUtils';
import { getTableStyleOverrides } from '../../common/utils/UIUtils';
import { AgentRequestsHistoryFilter } from '../components/AgentRequestHistoryFilter';
import { useAgentRequestsHistoryStore } from '../store/hooks';
import { useLoggerStore } from '../../../../log/hooks';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { AgentRequestsHistoryErrors } from '../store/AgentRequestsHistoryStore';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const AgentRequestHistory = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const store = useAgentRequestsHistoryStore();
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();
    const loggerStore = useLoggerStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);

    useEffect(() => {
        breadcrumbStore.setInitialLink(
            t('agencyBanking.agentRequestsHistory'),
            window.location.pathname
        );
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'requestedAt',
                sortable: true,
                name: t('common.requestedAt'),
                width: '160px'
            },
            {
                id: 'requestedBy',
                name: t('common.requestedBy'),
                width: '160px'
            },
            {
                id: 'requestedFor',
                name: t('common.requestedFor'),
                width: '160px'
            },
            {
                id: 'evaluatedBy',
                name: t('common.evaluatedBy'),
                width: '160px'
            },
            {
                id: 'requestStatus',
                name: t('common.requestStatus'),
                width: '160px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getData = async (
        option: TableOptions<Instance<string>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchAgentRequestsHistory(
            option.page ? option.page - 1 : 0,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case AgentRequestsHistoryErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.agentRequests.map((agentRequest) => {
            return [
                { data: agentRequest.requestedAt },
                { data: agentRequest.requestedBy },
                { data: agentRequest.requestedFor },
                { data: agentRequest.evaluatedBy ?? '-' },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getStatusTypeFromRequestStatus(agentRequest.requestStatus)}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    return (
        <Stack>
            <PageHeader
                title={t('agencyBanking.agentRequestsHistory')}
                subtitle={t('agencyBanking.agentRequestsHistorySubtitle')}
            />
            <Table
                name="AgentRequestsHistoryTable"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('agencyBanking.requestsSearchTableLoadingState') }
                }}
                filterOption={{
                    initialFilterValue: store.requestStatus as Instance<string>,
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <AgentRequestsHistoryFilter
                                statusFilter={filter}
                                setStatusFilter={setFilter}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    const selectedAgentId = store.agentRequests[index].agentId;
                    if (selectedAgentId) {
                        navigate(`/agency-banking/agents/details?agentId=${selectedAgentId}`);
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected agent id in the list with length ${store.agentRequests.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    }
                }}
            />
            <ErrorDialog
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={errorDialogMessage ? true : false}
                onClose={(): void => {
                    setErrorDialogMessage(null);
                    store.resetError();
                }}
            />
        </Stack>
    );
});
