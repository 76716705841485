import { FundStatus } from '@resolut-tech/bcn-rpcs';
import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import { TFunction } from 'react-i18next';
import { useUnclaimedFundsListStore } from '../store/hooks';
import { UnclaimedFundsListFilterOptions } from '../store/UnclaimedFundsListStore';

const getSearchByValue = (value: FundStatus.FundStatus, t: TFunction): string | undefined => {
    const values = FundStatus.FundStatus;
    switch (value) {
        case values.UNCLAIMED:
            return t('unclaimedFunds.unclaimed');
        case values.MOVED_OUT:
            return t('unclaimedFunds.moveOut');
    }
};

export const getSelectedFundStatusOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(FundStatus.FundStatus);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value as FundStatus.FundStatus, t);
        return { name: dropdownValue ?? '', value };
    });
};

export const getInitialFilter = (
    t: TFunction,
    initialCountryCode: string
): Instance<typeof UnclaimedFundsListFilterOptions> => {
    const searchBySelectedFundStatus = getSelectedFundStatusOptions(t);
    const store = useUnclaimedFundsListStore();
    return UnclaimedFundsListFilterOptions.create({
        selectedCountryCode: initialCountryCode,
        selectedFundStatus: searchBySelectedFundStatus[0].value,
        startDate: null,
        endDate: store.endDate
    });
};
