import { GetBOPresignedDocumentUploadURLForTransactionRulesRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetBOPresignedDocumentUploadURLForTransactionRulesRPCImpl extends GetBOPresignedDocumentUploadURLForTransactionRulesRPC {
    execute(
        _request: GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Request
    ): Promise<
        LeoRPCResult<
            GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Response,
            GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Errors.Errors
        >
    > {
        const response = new GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Response(
            new URL('https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv'),
            new LeoUUID()
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Response,
                GetBOPresignedDocumentUploadURLForTransactionRulesRPC.Errors.Errors
            >
        >;
    }
}
