import { UnclaimedTransactionDetailEnums } from '@resolut-tech/bcn-rpcs/build/back-office/unclaimedTransactionDetail';
import { Section } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRowComponentWithLabel } from '../../common/components/SectionRowComponentWithLabel';
import {
    SectionRowCellEnum,
    SectionRowCellType
} from '../../common/types/SectionRowComponentTypes';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { UnclaimedTransactionDetailView } from '../models/UnclaimedTransactionDetailsModel';

export interface TransactionDetailsSectionProp {
    transactionDetails: UnclaimedTransactionDetailView[];
}

const getSectionCell = (view: UnclaimedTransactionDetailView): SectionRowCellType => {
    const { cellType } = view;
    if (cellType instanceof UnclaimedTransactionDetailEnums.CellType.Bold) {
        return { type: SectionRowCellEnum.Bold, text: cellType.text };
    }
    if (cellType instanceof UnclaimedTransactionDetailEnums.CellType.Copy) {
        return { type: SectionRowCellEnum.Copy, text: cellType.text };
    }
    if (cellType instanceof UnclaimedTransactionDetailEnums.CellType.PrefixIcon) {
        return {
            type: SectionRowCellEnum.PrefixIcon,
            text: cellType.text,
            statusIcon: cellType.statusIcon
        };
    }
    if (cellType instanceof UnclaimedTransactionDetailEnums.CellType.ProfilePhoto) {
        return {
            type: SectionRowCellEnum.ProfileImage,
            imageUrl: cellType.profilePhoto.toString(),
            showViewPhotoButton: true
        };
    }
    if (cellType instanceof UnclaimedTransactionDetailEnums.CellType.Time) {
        const time = cellType.time.timestamp;
        return {
            type: SectionRowCellEnum.Time,
            text: getFormattedTimeDateWithComma(new Date(time))
        };
    }
    return { type: SectionRowCellEnum.Unstyled, text: cellType.text };
};

const getDetailCells = (
    transactionDetails: UnclaimedTransactionDetailView[]
): React.ReactElement[] => {
    return transactionDetails.map((item) => {
        return (
            <SectionRowComponentWithLabel
                label={item.label}
                cell={getSectionCell(item)}
                key={item.label}
            />
        );
    });
};

export const UnclaimedFundsTransactionDetailSection = ({
    transactionDetails
}: TransactionDetailsSectionProp): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Section
            title={t('common.transactionDetailsTitle')}
            allowViewChange={false}
            content={getDetailCells(transactionDetails)}
            headerHeight="56px"
        />
    );
};
