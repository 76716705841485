import { Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../../common/components/Dialog';

interface AddBOUserDownloadAuthCodeDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onDownloadClick: () => void;
}

export const ApproveAuthCodeRequestDialog = ({
    isDialogOpen,
    onClose,
    onDownloadClick
}: AddBOUserDownloadAuthCodeDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Dialog
            open={isDialogOpen}
            title={t('boUser.approveRegenerateAuthCodesRequest')}
            primaryButtonColor="primary"
            primaryButtonText={t('boUser.approveAndDownloadFile')}
            onPrimaryButtonClick={async (): Promise<void> => {
                onDownloadClick();
                return;
            }}
            secondaryButtonText={t('common.close')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Typography sx={{ ...typography.sh3, color: palette.warning[300] }}>
                {t('boUser.checkRegenerateAuthCodeRequest')}
            </Typography>
        </Dialog>
    );
};
