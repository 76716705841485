import { CheckRefundRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckRefundRequestRPCImpl extends CheckRefundRequestRPC {
    execute(
        _request: CheckRefundRequestRPC.Request
    ): Promise<LeoRPCResult<CheckRefundRequestRPC.Response, CheckRefundRequestRPC.Errors.Errors>> {
        const response = new CheckRefundRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<CheckRefundRequestRPC.Response, CheckRefundRequestRPC.Errors.Errors>
        >;
    }
}
