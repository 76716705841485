import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportsCardComponent } from '../components/ReportsCard';
import { PageHeader } from '../../common/components/PageHeader';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Users from '../../../../assets/users.svg';
import Wallet from '../../../../assets/wallet.svg';
import ArrowLeftRight from '../../../../assets/arrow-left-right.svg';
import CreditCard from '../../../../assets/credit-card.svg';
import BankNote from '../../../../assets/bank-note.svg';
import { ReportType } from '@resolut-tech/bcn-rpcs';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';

export const ReportsGrid = (): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const breadcrumbStore = useBreadcrumbStore();

    useEffect(() => {
        breadcrumbStore.setInitialLink(t('common.reports'), window.location.pathname);
    }, []);

    const onReportsCardClick = (searchParameter: string): void => {
        navigate({
            pathname: '/reports/details',
            search: createSearchParams({
                reportType: searchParameter
            }).toString()
        });
    };

    return (
        <Stack>
            <PageHeader title={t('common.reports')} subtitle={t('reports.viewBcnRelatedReports')} />
            <Grid container p="32px" spacing="32px">
                <Grid item>
                    <ReportsCardComponent
                        title={t('reports.userAcquisitions')}
                        subtitle={t('reports.viewUserAcquisitions')}
                        icon={Users}
                        onReportsCardClick={(): void =>
                            onReportsCardClick(ReportType.ReportType.USER_ACQUISITIONS)
                        }
                    />
                </Grid>
                <Grid item>
                    <ReportsCardComponent
                        title={t('reports.userBalance')}
                        subtitle={t('reports.viewBalance')}
                        icon={Wallet}
                        onReportsCardClick={(): void =>
                            onReportsCardClick(ReportType.ReportType.USER_BALANCE)
                        }
                    />
                </Grid>
                <Grid item>
                    <ReportsCardComponent
                        title={t('common.noOfTransactions')}
                        subtitle={t('reports.viewTransactions')}
                        icon={ArrowLeftRight}
                        onReportsCardClick={(): void =>
                            onReportsCardClick(ReportType.ReportType.NUMBER_OF_TRANSACTIONS)
                        }
                    />
                </Grid>
                <Grid item>
                    <ReportsCardComponent
                        title={t('reports.amountOfTransactions')}
                        subtitle={t('reports.viewAmountOfTransactions')}
                        icon={CreditCard}
                        onReportsCardClick={(): void =>
                            onReportsCardClick(ReportType.ReportType.AMOUNT_OF_TRANSACTIONS)
                        }
                    />
                </Grid>
                <Grid item>
                    <ReportsCardComponent
                        title={t('reports.revenueGenerated')}
                        subtitle={t('reports.viewRevenueGenerated')}
                        icon={BankNote}
                        onReportsCardClick={(): void =>
                            onReportsCardClick(ReportType.ReportType.REVENUE)
                        }
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};
