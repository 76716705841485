import { Stack } from '@mui/material';
import {
    DropdownItem,
    Icon,
    LoadingIndicator,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CountryViewModel } from '../../../store/country-list/CountryListStore';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { LoadingState } from '../../common/components/LoadingState';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { RequestStatus } from '../../common/enums/RequestStatus';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { getFormattedPhoneNumber, getTableStyleOverrides } from '../../common/utils/UIUtils';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { RequestCBSTransferDialog } from '../components/RequestCBSTransferDialog';
import { UnclaimedFundsFilter } from '../components/UnclaimedFundsFilter';
import {
    useRequestCBSTransferStore,
    useUnclaimedFundsListStore,
    useWithdrawCBSTransferStore
} from '../store/hooks';
import { UnclaimedProcessingRequestDetailCard } from '../components/UnclaimedProcessingCard';
import {
    UnclaimedFundsListErrors,
    UnclaimedFundsListFilterOptions
} from '../store/UnclaimedFundsListStore';
import { getInitialFilter } from '../utils/UIUtils';
import { UnclaimedFundsPendingRequestCard } from '../components/UnclaimedFundsPendingRequestCard';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { FundStatus } from '@resolut-tech/bcn-rpcs';
import { WithdrawCBSTransferStoreError } from '../store/WithdrawCBSTransferStore';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const CBSTransferFunds = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const userStore = useUserStore();
    const userPrivileges = userStore.privileges;
    const palette = usePalette();
    const store = useUnclaimedFundsListStore();
    const [countries, setCountries] = useState<CountryViewModel[] | null>(null);
    const [isRequestCBSTransferDialogOpen, setIsRequestCBSTransferDialogOpen] =
        useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const requestCBSTransferStore = useRequestCBSTransferStore();
    const navigate = useNavigate();
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState(false);
    const [screenBlockingLoading, setScreenBlockingLoading] = useState(false);
    const withdrawRequestStore = useWithdrawCBSTransferStore();
    const breadcrumbStore = useBreadcrumbStore();
    const [fundStatus, setFundStatus] = useState<string>('');
    const loggerStore = useLoggerStore();

    const fetchUnclaimedFundsRequestSummary = async (): Promise<void> => {
        setLoading(true);
        await store.fetchCountries();
        setCountries(store.countryList());
        await requestCBSTransferStore.getUnclaimedFundsRequestSummary();
        setLoading(false);
        if (requestCBSTransferStore.error === NetworkingError.InternalError) {
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
    };

    useEffect(() => {
        fetchUnclaimedFundsRequestSummary();
        breadcrumbStore.setInitialLink(
            t('unclaimedFunds.cbsTransferFunds'),
            window.location.pathname
        );
    }, []);

    const showWithdrawSection = (): boolean => {
        return (
            userStore.userId === requestCBSTransferStore.requestDetails?.requesterComment?.userId
        );
    };

    const countryDropdownItems = (): DropdownItem[] => {
        if (countries) {
            return countries.map((countryIterator) => {
                return {
                    name: countryIterator.name,
                    value: countryIterator.countryCode
                };
            });
        }
        return [];
    };

    const getFirstCountryCode = (): string => {
        if (countries) {
            return countryDropdownItems()[0].value;
        } else {
            return '';
        }
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={errorDialogTitle}
                errorMessage={errorDialogMessage}
                isErrorDialogOpen={Boolean(errorDialogMessage)}
                onClose={(): void => {
                    setErrorDialogTitle(null);
                    setErrorDialogMessage(null);
                    requestCBSTransferStore.resetStore();
                    store.removeError();
                }}
            />
        );
    };

    const RequestCBSTransferDialogBox = (): React.ReactElement => {
        return (
            <RequestCBSTransferDialog
                isDialogOpen={isRequestCBSTransferDialogOpen}
                onDialogClose={(): void => {
                    setIsRequestCBSTransferDialogOpen(false);
                }}
                onSuccess={(): void => {
                    fetchUnclaimedFundsRequestSummary();
                }}
                setIsErrorDialogMessage={(error: string): void => {
                    setIsRequestCBSTransferDialogOpen(false);
                    setErrorDialogTitle(t('unclaimedFunds.requestCBSTransfer'));
                    setErrorDialogMessage(error);
                }}
            />
        );
    };

    // There are multiple conditions to determine if the Request CBS Transfer should be disabled or not
    // 1. It should be disabled when the fund status type is Moved Out
    // 2. It should be disabled if for a given search results there are no funds
    // 3. It should be disabled if there is already a transaction in processing state
    const isRequestCBSTransferButtonDisabled: boolean =
        !Boolean(store.unclaimedFundsList.length) ||
        requestCBSTransferStore.requestDetails?.status === RequestStatus.PROCESSING ||
        fundStatus === FundStatus.FundStatus.MOVED_OUT;

    const getPageHeaderActionElement = (): ActionElement | undefined => {
        if (userStore.privileges.includes(UserPrivileges.RequestCBSTransfer)) {
            return {
                primaryButton: {
                    title: t('unclaimedFunds.requestCBSTransfer'),
                    icon: undefined,
                    isDisabled: isRequestCBSTransferButtonDisabled,
                    onClick: (): void => {
                        setIsRequestCBSTransferDialogOpen(true);
                    }
                }
            };
        }
        return undefined;
    };

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'timeDate',
                sortable: true,
                name: t('common.timeDate'),
                width: '160px'
            },
            {
                id: 'userName',
                name: t('common.userName'),
                width: '160px'
            },
            {
                id: 'userMobileNumber',
                name: t('common.userMobileNumber'),
                width: '160px'
            },
            {
                id: 'amount',
                name: t('common.amount'),
                width: '160px'
            },
            {
                id: 'country',
                name: t('common.country'),
                width: '120px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const onWithdrawClick = (): void => {
        setIsWithdrawRequestDialogOpen(true);
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        const requestId = requestCBSTransferStore.requestDetails?.requestId;
        if (requestId) {
            await withdrawRequestStore.withdrawRequest(requestId, comment);
        } else {
            loggerStore.debug(
                'Cannot find requestId in requestCBSTransferStore while withdrawing unclaimed funds request'
            );
            setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        if (withdrawRequestStore.error) {
            setErrorDialogTitle(t('common.withdrawRequest'));
            switch (withdrawRequestStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setErrorDialogMessage(t('common.requestAlreadyChecked'));
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setErrorDialogMessage(t('common.canOnlyWithdrawSelfRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setErrorDialogMessage(t('common.requestAlreadyDiscarded'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setErrorDialogMessage(t('common.requestAlreadyWithdrawn'));
                    break;
                case WithdrawCBSTransferStoreError.InvalidComment:
                    setErrorDialogMessage(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been withdrawn, we will fetch the Unclaimed Funds Request Summary details again.
            fetchUnclaimedFundsRequestSummary();
        }
    };

    const getData = async (
        option: TableOptions<Instance<typeof UnclaimedFundsListFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchUnclaimedFundsList(
            option.filter,
            option.page ? option.page - 1 : 0,
            option.sort?.order
        );
        if (store.error) {
            switch (store.error) {
                case UnclaimedFundsListErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.unclaimedFundsList.map((unclaimedFund) => {
            return [
                { data: unclaimedFund.createdAt },
                { data: unclaimedFund.userName },
                { data: getFormattedPhoneNumber(unclaimedFund.userPhoneNumber) },
                { data: unclaimedFund.amount },
                { data: unclaimedFund.country },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const getPendingCard = (): React.ReactElement => {
        // Show the pending request card for Viewer and Maker, Since Checker can visit ApproveCBSTransfer to check the pending request.
        const showPendingCard = !userPrivileges.includes(UserPrivileges.CheckCBSTransferRequest);
        if (
            showPendingCard &&
            requestCBSTransferStore.requestDetails &&
            requestCBSTransferStore.requestDetails?.status === RequestStatus.PENDING &&
            requestCBSTransferStore.requestDetails.requesterComment
        ) {
            return (
                <Stack
                    sx={{
                        padding: '32px 32px 0px 32px',
                        boxSizing: 'border-box'
                    }}>
                    <UnclaimedFundsPendingRequestCard
                        comment={requestCBSTransferStore.requestDetails.requesterComment}
                        onWithdrawClick={onWithdrawClick}
                        showWithdrawSection={showWithdrawSection()}
                        showViewDetailsSection={!showWithdrawSection()}
                        onViewDetailsButtonClick={(): void => {
                            navigate(
                                `/unclaimed-funds/search/details?requestId=${requestCBSTransferStore.requestDetails?.requestId}`
                            );
                        }}
                    />
                </Stack>
            );
        } else {
            return <></>;
        }
    };

    const getProcessingCard = (): React.ReactElement => {
        const showProcessingCard = Boolean(
            requestCBSTransferStore.requestDetails?.status === RequestStatus.PROCESSING
        );
        if (
            requestCBSTransferStore.requestDetails &&
            showProcessingCard &&
            requestCBSTransferStore.requestDetails.requesterComment
        ) {
            return (
                <Stack
                    sx={{
                        padding: '32px 32px 0px 32px',
                        boxSizing: 'border-box',
                        width: '100%'
                    }}>
                    <UnclaimedProcessingRequestDetailCard
                        makerComment={requestCBSTransferStore.requestDetails.requesterComment}
                        // Checker comment is always present when the request is in `Processing` state.
                        checkerComment={requestCBSTransferStore.requestDetails.evaluatorComment!}
                        onSecondaryButtonClick={(): void => {
                            navigate(
                                `/unclaimed-funds/search/details?requestId=${requestCBSTransferStore.requestDetails?.requestId}`
                            );
                        }}
                    />
                </Stack>
            );
        } else {
            return <></>;
        }
    };

    const WithdrawDialogBox = (): React.ReactElement => {
        return (
            <WithdrawDialog
                isDialogOpen={isWithdrawRequestDialogOpen}
                onClose={(): void => {
                    withdrawRequestStore.resetComment();
                    setIsWithdrawRequestDialogOpen(false);
                }}
                onSubmit={onDialogWithdrawButtonClick}
            />
        );
    };

    return (
        <Stack>
            {screenBlockingLoading && <LoadingIndicator isLoading={screenBlockingLoading} />}
            <PageHeader
                title={t('unclaimedFunds.cbsTransferFunds')}
                subtitle={t('unclaimedFunds.subtitle')}
                actionElement={getPageHeaderActionElement()}
            />
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    {getPendingCard()}
                    {getProcessingCard()}
                    <Table
                        name="cbsTransferFunds"
                        styleOverrides={getTableStyleOverrides(palette)}
                        headers={getHeaders()}
                        onTableOptionsChange={getData}
                        paginationOption={{
                            itemsPerPage: store.itemsPerPage(),
                            getPageIndicatorText(startItem, endItem, totalItems): string {
                                return t('common.paginationIndicationText', {
                                    startItem,
                                    endItem,
                                    totalItems
                                });
                            }
                        }}
                        viewOverrides={{
                            // store.isTableIdle value is used to check if the table has to start with idle mode or not.
                            // Because when the table is filtered and the page is revisited the table should start loading the data, instead of showing idle view.
                            idle: store.isTableIdle
                                ? { message: t('unclaimedFunds.requestCBSTransferIdleState') }
                                : undefined,
                            empty: { message: t('common.noResultsFound') },
                            loading: {
                                message: t('unclaimedFunds.searchingForFunds')
                            }
                        }}
                        filterOption={{
                            initialFilterValue:
                                (store.filterOptions as Instance<
                                    typeof UnclaimedFundsListFilterOptions
                                >) ?? getInitialFilter(t, getFirstCountryCode()),
                            filterComponent(filter, setFilter): React.ReactElement {
                                setFundStatus(filter.selectedFundStatus);
                                return (
                                    <UnclaimedFundsFilter
                                        filter={filter}
                                        setFilter={setFilter}
                                        countryDropdownItems={countryDropdownItems()}
                                        initialCountryCode={getFirstCountryCode()}
                                    />
                                );
                            }
                        }}
                        onRowClick={(_row, index): void => {
                            const selectedTransaction = store.unclaimedFundsList[index];
                            if (selectedTransaction) {
                                navigate(
                                    `/unclaimed-funds/search/transaction-details?unclaimedFundId=${selectedTransaction.unclaimedFundId}`
                                );
                            } else {
                                loggerStore.debug(
                                    `Array index: ${index} for selected Transaction in the list with length ${store.unclaimedFundsList.length} is out of bounds`
                                );
                                setErrorDialogMessage(
                                    t('common.somethingWentWrongProcessingRequest')
                                );
                            }
                        }}
                    />
                    {isRequestCBSTransferDialogOpen && RequestCBSTransferDialogBox()}
                    {errorDialogMessage && ErrorDialogBox()}
                    {isWithdrawRequestDialogOpen && WithdrawDialogBox()}
                </>
            )}
        </Stack>
    );
});
