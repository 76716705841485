import { Box, Stack, styled, Typography } from '@mui/material';
import { Checkbox, TextInputField, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle } from '../../../../assets/icons/AlertCircle';
import { AmountTextField } from '../../common/components/amount-text-field/AmountTextField';

interface AmountDetailsProps {
    amount: string;
    fee: string;
    transactionId: string;
    senderCurrency: string;
    amountErrorText: string | null;
    feeErrorText: string | null;
    onAmountChange: (value: string) => void;
    onFeeChange: (value: string) => void;
    onTransactionIdChange: (value: string) => void;
    showTransactionIdError: boolean;
    isFeeCharged: boolean;
    setIsFeeCharged: () => void;
    isFeeDisable: boolean;
}

export const AmountDetails = ({
    amount,
    fee,
    transactionId,
    senderCurrency,
    amountErrorText,
    feeErrorText,
    onAmountChange,
    onFeeChange,
    onTransactionIdChange,
    showTransactionIdError,
    isFeeCharged,
    setIsFeeCharged,
    isFeeDisable
}: AmountDetailsProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    const StyledTypography = styled(Typography)(() => ({
        ...typography.small1,
        color: palette.label[300]
    }));

    return (
        <Stack spacing="16px">
            <StyledTypography>{t('common.amount')}</StyledTypography>
            <AmountTextField
                name="amount"
                style={{ width: '320px' }}
                value={amount}
                onAmountChange={onAmountChange}
                label={`${t('genericFundsTransfer.enterAmount')}*`}
                inputAdornmentText={senderCurrency}
                inputAdornmentPlacement="right"
                helperIcon={amountErrorText ? <AlertCircle /> : undefined}
                error={amountErrorText ? true : false}
                helperText={amountErrorText ?? ''}
                isDecimalAllowed={true}
            />
            <StyledTypography>{t('common.fee')}</StyledTypography>
            <Box sx={{ width: '125px' }}>
                <Checkbox
                    checked={isFeeCharged}
                    onChange={setIsFeeCharged}
                    label={t('common.chargeFees')}
                    style={{ marginLeft: '8px' }}
                    isDisabled={isFeeDisable}
                />
            </Box>
            <AmountTextField
                name="fee"
                style={{ width: '320px' }}
                isDisabled={!isFeeCharged}
                value={fee}
                onAmountChange={onFeeChange}
                label={t('common.enterFee')}
                inputAdornmentText={senderCurrency}
                inputAdornmentPlacement="right"
                error={feeErrorText ? true : false}
                helperIcon={feeErrorText ? <AlertCircle /> : undefined}
                helperText={feeErrorText ?? ''}
                isDecimalAllowed={true}
            />
            <StyledTypography>{t('genericFundsTransfer.linkTransactionId')}</StyledTypography>
            <TextInputField
                name="linkTransactionId"
                style={{ width: '320px' }}
                value={transactionId}
                type="text"
                onTextChange={onTransactionIdChange}
                label={`${t('genericFundsTransfer.enterTransactionId')}`}
                error={showTransactionIdError}
                inputAdornmentPlacement="right"
                helperIcon={showTransactionIdError ? <AlertCircle /> : undefined}
                helperText={showTransactionIdError ? t('boUser.invalidLinkedTransactionId') : ''}
            />
        </Stack>
    );
};
