import { UserSearchBy } from '@resolut-tech/bcn-rpcs';
import { DropdownItem } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import { TFunction } from 'react-i18next';
import { BCNUserAccountDetailsModel } from '../models/BCNUserAccountDetailModel';
import { BCNUserFilterOptions } from '../store/BCNUserSearchStore';

const getSearchByValue = (value: UserSearchBy.UserSearchBy, t: TFunction): string | undefined => {
    const values = UserSearchBy.UserSearchBy;
    switch (value) {
        case values.MOBILE_NUMBER:
            return t('common.mobileNumber');
        case values.NAME:
            return t('common.name');
    }
};

export const getBCNUserSearchByOptions = (t: TFunction): DropdownItem[] => {
    const values = Object.keys(UserSearchBy.UserSearchBy);
    return values.map((value: string): DropdownItem => {
        const dropdownValue = getSearchByValue(value as UserSearchBy.UserSearchBy, t);
        return { name: dropdownValue ?? '', value };
    });
};

export const getInitialFilter = (t: TFunction): Instance<typeof BCNUserFilterOptions> => {
    const searchByOptions = getBCNUserSearchByOptions(t);

    return BCNUserFilterOptions.create({
        searchBy: searchByOptions[0].value,
        searchText: '',
        selectedUserStatus: ''
    });
};

export const getAccountColumnCount = (
    accountData: Instance<typeof BCNUserAccountDetailsModel>
): number => {
    // count starts from two as the accountId and accountName will always be present
    let count = 2;
    if (accountData.balance) {
        count++;
    }
    if (accountData.status) {
        count++;
    }
    if (accountData.isDefaultAccount) {
        count++;
    }
    return count;
};

export const getKYCDocumentImageSupportedTypes = (): string[] => {
    return ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'jpeg'];
};

export const getKYCDocumentFileSupportedTypes = (): string[] => {
    return ['pdf', 'PDF'];
};
