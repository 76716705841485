import {
    GetUserRolesRPC,
    GetUserRolesRPCClientImpl,
    GetBOUserDetailsRPC,
    GetBOUserDetailsRPCClientImpl,
    GetBOUserDetailRequestsHistoryRPC,
    GetBOUserDetailRequestsHistoryRPCClientImpl,
    GetBOUsersRPC,
    GetBOUsersRPCClientImpl,
    RequestBOUserForceResetPasswordRPC,
    RequestBOUserRegenerateAuthCodesRPC,
    RequestBOUserForceResetPasswordRPCClientImpl,
    RequestBOUserRegenerateAuthCodesRPCClientImpl,
    RequestBOUserArchiveRPC,
    RequestBOUserArchiveRPCClientImpl,
    GetBOUserChangeRequestsRPC,
    GetBOUserChangeRequestsRPCClientImpl,
    GetBOUserRequestSummaryRPC,
    GetBOUserRequestSummaryRPCClientImpl,
    CheckBOUserChangeRequestRPC,
    CheckBOUserChangeRequestRPCClientImpl,
    WithdrawBOUserChangeRequestRPC,
    WithdrawBOUserChangeRequestRPCClientImpl,
    GetNewBOUserDetailsRPC,
    GetNewBOUserDetailsRPCClientImpl,
    ValidateBOUserDetailsRPC,
    ValidateBOUserDetailsRPCClientImpl,
    RequestToAddBOUserRPC,
    RequestToAddBOUserRPCClientImpl,
    RequestBOUserUpdateRPC,
    RequestBOUserUpdateRPCClientImpl,
    CheckNewBOUserRequestRPC,
    CheckNewBOUserRequestRPCClientImpl,
    RequestToReactivateOrDeactivateBOUserRPC,
    RequestToReactivateOrDeactivateBOUserRPCClientImpl,
    CheckRegenerateAuthCodeRequestRPCClientImpl,
    CheckRegenerateAuthCodeRequestRPC
} from '@resolut-tech/bcn-rpcs';
import { MockGetUserRolesRPCImpl } from './MockGetUserRolesRPCImpl';
import { MockGetBOUserDetailsRPCImpl } from './MockGetBOUserDetailsRPCImpl';
import { MockGetBOUserDetailRequestsHistoryRPCImpl } from './MockGetBOUserDetailRequestsHistoryRPCImpl';
import { MockGetBOUsersRPCImpl } from './MockGetBOUsersRPCImpl';
import { MockRequestBOUserForceResetPasswordRPCImpl } from './MockRequestBOUserForceResetPasswordRPCImpl';
import { MockRequestBOUserRegenerateAuthCodesRPCImpl } from './MockRequestBOUserRegenerateAuthCodesRPCImpl';
import { MockRequestBOUserArchiveRPCImpl } from './MockRequestBOUserArchiveRPCImpl';
import { MockGetBOUserChangeRequestsRPCImpl } from './MockGetBOUserChangeRequestsRPCImpl';
import { MockGetBOUserRequestSummaryImpl } from './MockGetBOUserRequestSummaryImpl';
import { MockCheckBOUserChangeRequestRPCImpl } from './MockCheckBOUserChangeRequestRPCImpl';
import { MockWithdrawBOUserChangeRequestRPCImpl } from './MockWithdrawBOUserChangeRequestRPCImpl';
import { MockGetNewBOUserDetailsRPCImpl } from './MockGetNewBOUserDetailsRPCImpl';
import { MockValidateBOUserDetailsRPCImpl } from './MockValidateBOUserDetailsRPCImpl';
import { MockRequestToAddBOUserRPCImpl } from './MockRequestToAddBOUserRPCImpl';
import { MockRequestBOUserUpdateRPCImpl } from './MockRequestBOUserUpdateRPCImpl';
import { MockCheckNewBOUserRequestRPCImpl } from './MockCheckNewBOUserRequestRPCImpl';
import { MockRequestToReactivateOrDeactivateBOUserRPCClientImpl } from './MockRequestToReactivateOrDeactivateBOUserRPCClientImpl.ts';
import { APIClient } from '@surya-digital/tedwig';
import { MockCheckRegenerateAuthCodeRequestRPCImpl } from './MockCheckRegenerateAuthCodeRequestRPCImpl';

const MOCK = false;

export const useGetBOUsersRPCClient = (apiClient: APIClient): GetBOUsersRPC => {
    if (MOCK) {
        return new MockGetBOUsersRPCImpl();
    } else {
        return new GetBOUsersRPCClientImpl(apiClient);
    }
};

export const useGetUserRolesRPCClient = (apiClient: APIClient): GetUserRolesRPC => {
    if (MOCK) {
        return new MockGetUserRolesRPCImpl();
    } else {
        return new GetUserRolesRPCClientImpl(apiClient);
    }
};

export const useGetBOUserDetailsRPCClient = (apiClient: APIClient): GetBOUserDetailsRPC => {
    if (MOCK) {
        return new MockGetBOUserDetailsRPCImpl();
    } else {
        return new GetBOUserDetailsRPCClientImpl(apiClient);
    }
};

export const useGetBOUserDetailRequestsHistoryRPCClient = (
    apiClient: APIClient
): GetBOUserDetailRequestsHistoryRPC => {
    if (MOCK) {
        return new MockGetBOUserDetailRequestsHistoryRPCImpl();
    } else {
        return new GetBOUserDetailRequestsHistoryRPCClientImpl(apiClient);
    }
};

export const useRequestRequestBOUserForceResetPasswordRPCClient = (
    apiClient: APIClient
): RequestBOUserForceResetPasswordRPC => {
    if (MOCK) {
        return new MockRequestBOUserForceResetPasswordRPCImpl();
    } else {
        return new RequestBOUserForceResetPasswordRPCClientImpl(apiClient);
    }
};

export const useRequestBOUserRegenerateAuthCodesRPCClient = (
    apiClient: APIClient
): RequestBOUserRegenerateAuthCodesRPC => {
    if (MOCK) {
        return new MockRequestBOUserRegenerateAuthCodesRPCImpl();
    } else {
        return new RequestBOUserRegenerateAuthCodesRPCClientImpl(apiClient);
    }
};

export const useRequestToReactivateOrDeactivateBOUserRPCClient = (
    apiClient: APIClient
): RequestToReactivateOrDeactivateBOUserRPC => {
    if (MOCK) {
        return new MockRequestToReactivateOrDeactivateBOUserRPCClientImpl();
    } else {
        return new RequestToReactivateOrDeactivateBOUserRPCClientImpl(apiClient);
    }
};

export const useRequestBoUseArchiveRPCClient = (apiClient: APIClient): RequestBOUserArchiveRPC => {
    if (MOCK) {
        return new MockRequestBOUserArchiveRPCImpl();
    } else {
        return new RequestBOUserArchiveRPCClientImpl(apiClient);
    }
};

export const useGetBOUserChangeRequestsRPCClient = (
    apiClient: APIClient
): GetBOUserChangeRequestsRPC => {
    if (MOCK) {
        return new MockGetBOUserChangeRequestsRPCImpl();
    } else {
        return new GetBOUserChangeRequestsRPCClientImpl(apiClient);
    }
};

export const useGetBOUserRequestHistoryRPCClient = (
    apiClient: APIClient
): GetBOUserDetailRequestsHistoryRPC => {
    if (MOCK) {
        return new MockGetBOUserDetailRequestsHistoryRPCImpl();
    } else {
        return new GetBOUserDetailRequestsHistoryRPCClientImpl(apiClient);
    }
};

export const useGetBOUserRequestsSummaryRPCClient = (
    apiClient: APIClient
): GetBOUserRequestSummaryRPC => {
    if (MOCK) {
        return new MockGetBOUserRequestSummaryImpl();
    } else {
        return new GetBOUserRequestSummaryRPCClientImpl(apiClient);
    }
};

export const useCheckBOUserChangeRequestRPCClient = (
    apiClient: APIClient
): CheckBOUserChangeRequestRPC => {
    if (MOCK) {
        return new MockCheckBOUserChangeRequestRPCImpl();
    } else {
        return new CheckBOUserChangeRequestRPCClientImpl(apiClient);
    }
};

export const useWithdrawBOUserChangeRequestRPCClient = (
    apiClient: APIClient
): WithdrawBOUserChangeRequestRPC => {
    if (MOCK) {
        return new MockWithdrawBOUserChangeRequestRPCImpl();
    } else {
        return new WithdrawBOUserChangeRequestRPCClientImpl(apiClient);
    }
};

export const useGetNewBOUserDetailsRPCClient = (apiClient: APIClient): GetNewBOUserDetailsRPC => {
    if (MOCK) {
        return new MockGetNewBOUserDetailsRPCImpl();
    } else {
        return new GetNewBOUserDetailsRPCClientImpl(apiClient);
    }
};

export const useValidateBOUserDetailsRPCClient = (
    apiClient: APIClient
): ValidateBOUserDetailsRPC => {
    if (MOCK) {
        return new MockValidateBOUserDetailsRPCImpl();
    } else {
        return new ValidateBOUserDetailsRPCClientImpl(apiClient);
    }
};

export const useRequestToAddBOUserRPCClient = (apiClient: APIClient): RequestToAddBOUserRPC => {
    if (MOCK) {
        return new MockRequestToAddBOUserRPCImpl();
    } else {
        return new RequestToAddBOUserRPCClientImpl(apiClient);
    }
};

export const useRequestBOUserUpdateRPCClient = (apiClient: APIClient): RequestBOUserUpdateRPC => {
    if (MOCK) {
        return new MockRequestBOUserUpdateRPCImpl();
    } else {
        return new RequestBOUserUpdateRPCClientImpl(apiClient);
    }
};

export const useCheckNewBOUserRequestRPCClient = (
    apiClient: APIClient
): CheckNewBOUserRequestRPC => {
    if (MOCK) {
        return new MockCheckNewBOUserRequestRPCImpl();
    } else {
        return new CheckNewBOUserRequestRPCClientImpl(apiClient);
    }
};

export const useCheckRegenerateAuthCodeRequestRPCClient = (
    apiClient: APIClient
): CheckRegenerateAuthCodeRequestRPC => {
    if (MOCK) {
        return new MockCheckRegenerateAuthCodeRequestRPCImpl();
    } else {
        return new CheckRegenerateAuthCodeRequestRPCClientImpl(apiClient);
    }
};
