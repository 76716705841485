import { CheckNewBOUserRequestRPC } from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockCheckNewBOUserRequestRPCImpl extends CheckNewBOUserRequestRPC {
    execute(
        _request: CheckNewBOUserRequestRPC.Request
    ): Promise<
        LeoRPCResult<CheckNewBOUserRequestRPC.Response, CheckNewBOUserRequestRPC.Errors.Errors>
    > {
        const response = new CheckNewBOUserRequestRPC.Response(
            new URL('https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv')
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<CheckNewBOUserRequestRPC.Response, CheckNewBOUserRequestRPC.Errors.Errors>
        >;
    }
}
