import { AgentTransactionRequestDetails } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { BOUserCommentModel, getBOUserCommentModel } from '../../common/models/BOUserCommentModel';

const PendingRequestDetailsModel = types.model({
    requestId: types.string,
    comment: BOUserCommentModel
});

const ProcessingRequestDetailsModel = types.model({
    checkerComment: BOUserCommentModel,
    makerComment: BOUserCommentModel
});

export const AgentTransactionRequestDetailsModel = types.model({
    isSuccess: types.maybeNull(types.boolean),
    pendingRequestDetails: types.maybeNull(PendingRequestDetailsModel),
    processingRequestDetails: types.maybeNull(ProcessingRequestDetailsModel)
});

export const getAgentTransactionRequestDetailsModel = (
    requestDetails: AgentTransactionRequestDetails.AgentTransactionRequestDetails
): Instance<typeof AgentTransactionRequestDetailsModel> => {
    if (requestDetails instanceof AgentTransactionRequestDetails.Pending) {
        return AgentTransactionRequestDetailsModel.create({
            pendingRequestDetails: PendingRequestDetailsModel.create({
                requestId: requestDetails.requestId.uuid,
                comment: getBOUserCommentModel(requestDetails.comment)
            })
        });
    }
    if (requestDetails instanceof AgentTransactionRequestDetails.Processing) {
        return AgentTransactionRequestDetailsModel.create({
            processingRequestDetails: ProcessingRequestDetailsModel.create({
                checkerComment: getBOUserCommentModel(requestDetails.checkerComment),
                makerComment: getBOUserCommentModel(requestDetails.makerComment)
            })
        });
    }
    return AgentTransactionRequestDetailsModel.create({ isSuccess: true });
};
