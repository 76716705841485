import { getPGFullName } from './../../../../utils/StringUtils';
import {
    GetRefundRequestListRPC,
    GetRefundRequestListPaginationResponse,
    SearchText,
    ItemsPerPage,
    PageIndex,
    IntermediateRequestStatus,
    GetRefundRequestListSortOrder
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { flow, Instance, types, cast } from 'mobx-state-tree';
import { useGetRefundRequestsListClient } from '../rpcs/RPC';
import { getPGAmountString } from '../../common/utils/UIUtils';
import {
    getMoneyRequestInitialFilter,
    getSortOrder,
    MoneyRequestFilterOptions
} from '../../common/utils/TableFilterUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { GetRefundRequestListSortOrderEnums } from '@resolut-tech/bcn-rpcs/build/back-office/getRefundRequestListSortOrder';
import { getFormattedTimeDateWithComma } from '../../common/utils/DateUtils';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export enum RefundRequestsErrors {
    InvalidPageIndex = 'INVALID_PAGE_INDEX'
}

export const RefundRequestModal = types.model({
    requestedAt: types.string,
    requestedBy: types.string,
    evaluatedBy: types.maybeNull(types.string),
    amount: types.string,
    transactionId: types.string,
    status: types.enumeration<IntermediateRequestStatus.IntermediateRequestStatus>(
        'IntermediateRequestStatus',
        Object.values(IntermediateRequestStatus.IntermediateRequestStatus)
    )
});

const getRefundRequestModel = (
    paginationResponse: GetRefundRequestListPaginationResponse
): Instance<typeof RefundRequestModal> => {
    return RefundRequestModal.create({
        requestedAt: getFormattedTimeDateWithComma(
            new Date(paginationResponse.requestedAt.timestamp)
        ),
        requestedBy: getPGFullName(paginationResponse.requestedBy),
        evaluatedBy: paginationResponse.checkedBy
            ? getPGFullName(paginationResponse.checkedBy)
            : null,
        amount: getPGAmountString(paginationResponse.amount),
        transactionId: paginationResponse.transactionId.uuid,
        status: paginationResponse.requestStatus
    });
};

const getTransactionSearchText = (transactionId: string | undefined): SearchText | undefined => {
    if (transactionId) {
        try {
            return new SearchText(transactionId);
        } catch {
            return undefined;
        }
    }
};

export const RefundRequestsStore = types
    .model({
        totalItems: types.number,
        filterOptions: MoneyRequestFilterOptions,
        refundRequests: types.array(RefundRequestModal),
        error: types.maybeNull(
            types.union(
                types.enumeration<RefundRequestsErrors>(
                    'RefundRequestListErrors',
                    Object.values(RefundRequestsErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .views(() => ({
        minimumSearchTextLength: (): number => 3,
        itemsPerPage: (): number => 10
    }))
    .actions((store) => ({
        removeError(): void {
            store.error = null;
        },
        updateFilterOptions(filterOptions: Instance<typeof MoneyRequestFilterOptions>): void {
            store.filterOptions = filterOptions;
        },
        fetchRequestList: flow(function* (
            transactionId: string,
            pageIndex: number,
            sortOrder?: 'asc' | 'desc',
            searchBy?: string
        ) {
            const loggerStore = getLoggerStore(store);
            let requestStatus = null;
            if (IntermediateRequestStatus.isInstanceOf(searchBy)) {
                requestStatus = IntermediateRequestStatus.fromDTO({
                    case: searchBy ?? store.filterOptions.requestStatus
                });
            }
            const request = new GetRefundRequestListRPC.Request(
                [
                    new GetRefundRequestListSortOrder(
                        0,
                        GetRefundRequestListSortOrderEnums.ColumnName.ColumnName.REQUESTED_AT,
                        getSortOrder(sortOrder)
                    )
                ],
                requestStatus,
                getTransactionSearchText(transactionId),
                new ItemsPerPage(store.itemsPerPage()),
                new PageIndex(pageIndex)
            );
            const apiClient = getAPIClient(store);
            const result: LeoRPCResult<
                GetRefundRequestListRPC.Response,
                GetRefundRequestListRPC.Errors.Errors
            > = yield useGetRefundRequestsListClient(apiClient).execute(request);
            if (result instanceof LeoRPCResult.Response) {
                const { response } = result;
                const refundRequestList = response.getRefundRequestListPaginationResponse.map(
                    (paginationResponse: GetRefundRequestListPaginationResponse) => {
                        return getRefundRequestModel(paginationResponse);
                    }
                );
                store.totalItems = response.totalItems;
                store.refundRequests = cast(refundRequestList);
            } else if (result instanceof LeoRPCResult.Error) {
                const { error } = result;
                switch (error.code) {
                    case RefundRequestsErrors.InvalidPageIndex:
                        store.error = RefundRequestsErrors.InvalidPageIndex;
                        break;
                    default:
                        loggerStore.error(
                            `Unhandled error: ${error} occurred in GetRefundRequestListRPC`
                        );
                        store.error = NetworkingError.InternalError;
                }
            } else {
                loggerStore.error(
                    `Unknown error occurred in GetRefundRequestListRPC with result: ${result}`
                );
                store.error = NetworkingError.InternalError;
            }
        })
    }));

export const createRefundRequestsStore = (): Instance<typeof RefundRequestsStore> => {
    return RefundRequestsStore.create({
        totalItems: 0,
        filterOptions: getMoneyRequestInitialFilter(),
        refundRequests: []
    });
};
