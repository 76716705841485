import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dialog } from '../../../common/components/Dialog';

interface DownloadRequestsZipDialogProps {
    isDialogOpen: boolean;
    onCancel: () => void;
}
export const DownloadRequestsZipDialog = ({
    isDialogOpen,
    onCancel
}: DownloadRequestsZipDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const palette = usePalette();

    return (
        <Dialog
            open={isDialogOpen}
            title={t('common.downloadRequestsZip')}
            secondaryButtonText={t('common.close')}
            onSecondaryButtonClick={onCancel}
            disableBackdropClick>
            <Typography
                sx={{
                    ...typography.small2,
                    color: palette.informative[300]
                }}>
                {t('unclaimedFunds.downloadRequestsZipSubtitle')}
            </Typography>
        </Dialog>
    );
};
