import { LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import {
    useGetURLForSignedInUserRPCClient,
    useGetDocumentIdForSignedInUserRPCClient
} from '../rpcs/RPC';
import { flow, Instance, types } from 'mobx-state-tree';
import {
    FileAttributes,
    GetUrlForSignedInUserRPC,
    GetDocumentIdForSignedInUserRPC
} from '@resolut-tech/bcn-rpcs';
import {
    FileUploadError,
    getFileAttribute,
    uploadFileToS3
} from '../../common/utils/FileUploadUtils';
import { FileAttributesEnums } from '@resolut-tech/bcn-rpcs/build/document/fileAttributes';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { APIClient } from '@surya-digital/tedwig';
import Resizer from 'react-image-file-resizer';
import { LoggerStore } from '../../../../log/LoggerStore';
import { getLoggerStore } from '../../../../log/hooks';

const getS3UploadURL = async (
    fileAttribute: FileAttributes,
    apiClient: APIClient,
    loggerStore: Instance<typeof LoggerStore>
): Promise<URL> => {
    const request = new GetUrlForSignedInUserRPC.Request(fileAttribute);
    const result: LeoRPCResult<
        GetUrlForSignedInUserRPC.Response,
        GetUrlForSignedInUserRPC.Errors.Errors
    > = await useGetURLForSignedInUserRPCClient(apiClient).execute(request);
    if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.uploadDestinationURL;
    } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        loggerStore.error(`GetUrlForSignedInUserRPC resulted in error: ${error}`);
        return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
    } else {
        loggerStore.error(
            `Unknown error occurred in GetURLForSignedInUserRPC with result: ${result}`
        );
        return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
    }
};

const getUploadedFileId = async (
    fileSHA256: string,
    apiClient: APIClient,
    loggerStore: Instance<typeof LoggerStore>
): Promise<LeoUUID> => {
    const request = new GetDocumentIdForSignedInUserRPC.Request(fileSHA256);
    const result: LeoRPCResult<
        GetDocumentIdForSignedInUserRPC.Response,
        GetDocumentIdForSignedInUserRPC.Errors.DocumentNotFound
    > = await useGetDocumentIdForSignedInUserRPCClient(apiClient).execute(request);
    if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.recordId;
    } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        loggerStore.error(`GetDocumentIdForSignedInUserRPC resulted in error: ${error}`);
        return Promise.reject(FileUploadError.UploadedFileIdNotFound);
    } else {
        loggerStore.error(
            `Unknown error occurred in GetDocumentIdForSignedInUserRPC with result: ${result}`
        );
        return Promise.reject(FileUploadError.InternalError);
    }
};

const _uploadFile = async (
    file: File,
    fileAttribute: FileAttributes,
    apiClient: APIClient,
    loggerStore: Instance<typeof LoggerStore>
): Promise<LeoUUID> => {
    const uploadURL = await getS3UploadURL(fileAttribute, apiClient, loggerStore);
    await uploadFileToS3(uploadURL, file);
    return getUploadedFileId(fileAttribute.sha256, apiClient, loggerStore);
};

const getResizedImage = async (
    file: File,
    fileExtension: string,
    loggerStore: Instance<typeof LoggerStore>
): Promise<[File, FileAttributes]> => {
    // Back-end expects the resolution image to be 1000 * 1000.
    const RESOLUTION = 1000;
    const resizedImage = (await new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            RESOLUTION,
            RESOLUTION,
            fileExtension,
            100,
            0,
            (resizedImageFile) => {
                resolve(resizedImageFile as File);
            },
            // We expect the resized image to be returned as a file object.
            'file',
            RESOLUTION,
            RESOLUTION
        );
    })) as File;
    const fileAttribute = await getFileAttribute(
        resizedImage,
        // Since the resized image file will always be in JPG format.
        FileAttributesEnums.FileExtension.FileExtension.JPG,
        false,
        loggerStore
    );
    return [resizedImage, fileAttribute];
};

export const UploadProfileImageStore = types
    .model({
        recordId: types.maybeNull(types.string),
        error: types.maybeNull(
            types.enumeration<FileUploadError>('FileUploadError', Object.values(FileUploadError))
        )
    })
    .actions((store) => ({
        resetError(): void {
            store.error = null;
        },
        uploadFile: flow(function* (file: File, fileExtension = 'JPEG') {
            const apiClient = getAPIClient(store);
            const loggerStore = getLoggerStore(store);
            try {
                const result = yield getResizedImage(file, fileExtension, loggerStore).then(
                    ([resizedImage, fileAttribute]) => {
                        return _uploadFile(resizedImage, fileAttribute, apiClient, loggerStore);
                    }
                );
                store.recordId = result.uuid;
            } catch (error) {
                if (error === FileUploadError.MaxFileSizeExceeded) {
                    store.error = FileUploadError.MaxFileSizeExceeded;
                } else if (error === FileUploadError.CouldNotFetchUploadURL) {
                    store.error = FileUploadError.CouldNotFetchUploadURL;
                } else if (error === FileUploadError.InvalidFileFormat) {
                    store.error = FileUploadError.InvalidFileFormat;
                } else if (error === FileUploadError.UploadFailed) {
                    store.error = FileUploadError.UploadFailed;
                } else if (error === FileUploadError.UploadedFileIdNotFound) {
                    store.error = FileUploadError.UploadedFileIdNotFound;
                } else if (error === FileUploadError.InvalidFileName) {
                    store.error = FileUploadError.InvalidFileName;
                } else if (error === FileUploadError.InvalidFileSHA) {
                    store.error = FileUploadError.InvalidFileSHA;
                } else {
                    store.error = FileUploadError.InternalError;
                }
            }
        })
    }));

export const createUploadProfileImageStore = (): Instance<typeof UploadProfileImageStore> => {
    return UploadProfileImageStore.create();
};
