import React from 'react';
import { useTranslation } from 'react-i18next';
import { Instance } from 'mobx-state-tree';
import { AmountModel } from '../../common/models/AmountModel';
import { RequestDetailsDialog } from './RequestDetailsDialog';

interface TransferDetailsDialogProps {
    amount: Instance<typeof AmountModel>;
    fee: Instance<typeof AmountModel> | null;
    amountDebited: Instance<typeof AmountModel>;
    amountCredited: Instance<typeof AmountModel>;
    isDialogOpen: boolean;
    onClose: () => void;
    onSubmitRequest: (comment: string) => void;
}
export const TransferDetailsDialog = ({
    amount,
    fee,
    amountDebited,
    amountCredited,
    isDialogOpen,
    onClose,
    onSubmitRequest
}: TransferDetailsDialogProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <RequestDetailsDialog
            title={t('common.requestFundsTransfer')}
            primaryButtonText={t('common.submitRequest')}
            amount={amount}
            fee={fee}
            amountDebited={amountDebited}
            amountCredited={amountCredited}
            isDialogOpen={isDialogOpen}
            onClose={onClose}
            onSubmit={onSubmitRequest}
            showWarningChip={amountDebited.currency !== amountCredited.currency}
        />
    );
};
