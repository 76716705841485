import {
    BitmapImageType,
    CountryCode,
    CountryInformation,
    GetCountryListRPC,
    LocalizedText,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    ThemedImage
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecuteWithoutDelay } from '../../../networking/MockExecuteHelperWithoutDelay';

export class MockGetCountryListRPCImpl extends GetCountryListRPC {
    execute(
        _request: GetCountryListRPC.Request
    ): Promise<LeoRPCResult<GetCountryListRPC.Response, GetCountryListRPC.Errors.Errors>> {
        const countries = [
            new CountryInformation(
                new LocalizedText('Malawi'),
                new ThemedImage(
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    )
                ),
                new CountryCode('25'),
                '+265'
            ),
            new CountryInformation(
                new LocalizedText('Zambia'),
                new ThemedImage(
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    )
                ),
                new CountryCode('26'),
                '+260'
            ),
            new CountryInformation(
                new LocalizedText('South Africa'),
                new ThemedImage(
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/82c3634eb748dea42a351a0b06101ee7c42616aae627618ffdffb04ca582bd0b'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/82c3634eb748dea42a351a0b06101ee7c42616aae627618ffdffb04ca582bd0b'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/82c3634eb748dea42a351a0b06101ee7c42616aae627618ffdffb04ca582bd0b'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/82c3634eb748dea42a351a0b06101ee7c42616aae627618ffdffb04ca582bd0b'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    )
                ),
                new CountryCode('27'),
                '+27'
            ),
            new CountryInformation(
                new LocalizedText('India'),
                new ThemedImage(
                    new MultiResolutionBitmapImage(
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        ),
                        new RemoteBitmapImage(
                            new URL(
                                'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                            ),
                            BitmapImageType.BitmapImageType.PNG,
                            20,
                            20
                        )
                    )
                ),
                new CountryCode('91'),
                '+91'
            )
        ];
        const response = new GetCountryListRPC.Response(countries);
        return mockExecuteWithoutDelay({ response }) as Promise<
            LeoRPCResult<GetCountryListRPC.Response, GetCountryListRPC.Errors.Errors>
        >;
    }
}
