import {
    BitmapImageType,
    Gender,
    GetKYCDocumentHistoryDetailsRPC,
    KYCData,
    KYCDocument,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    UserPersonalDetails
} from '@resolut-tech/bcn-rpcs';
import { KYCDocumentEnums } from '@resolut-tech/bcn-rpcs/build/back-office/kYCDocument';
import { LeoDate, LeoEmailId, LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockGetKYCDocumentHistoryDetailsRPCImpl extends GetKYCDocumentHistoryDetailsRPC {
    execute(
        _request: GetKYCDocumentHistoryDetailsRPC.Request
    ): Promise<
        LeoRPCResult<
            GetKYCDocumentHistoryDetailsRPC.Response,
            GetKYCDocumentHistoryDetailsRPC.Errors.Errors
        >
    > {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );

        const response = new GetKYCDocumentHistoryDetailsRPC.Response(
            new KYCData(
                new UserPersonalDetails(
                    'Esther',
                    'Howard',
                    'Esther',
                    Gender.Gender.MALE,
                    new LeoDate(),
                    'GILSPB08'
                ),
                new LeoEmailId('esther.howard@email.com'),
                'Blantyre',
                new LeoDate(),
                new LeoDate(),
                'P.O. Box 31674, Milward Road, Opposite Home Pharmacy, Limble, Blantyre, Malawi',
                'P.O. Box 31674, Milward Road, Opposite Home Pharmacy, Limble, Blantyre, Malawi'
            ),
            profilePicture,
            new KYCDocument(
                new URL(
                    'https://topfakeid.com/wp-content/uploads/2020/01/minnesota-fake-id-e1579877856271.jpg'
                ),
                KYCDocumentEnums.DocumentType.DocumentType.JPG
            ),
            new KYCDocument(
                new URL(
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxAz3EiKBkATAw_FkIECzJPGQXdFt0jO9_GgNFg6_VMYTS0rtCrK98E2e56YRPJbq0TU&usqp=CAU'
                ),
                KYCDocumentEnums.DocumentType.DocumentType.PNG
            ),
            null,
            new KYCDocument(
                new URL(
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDilO6mD07jiiebOuPGLIF5W-ZDI5R_nNPLDUCGXtFSx8Y5VbuICYbFc9BITXteywOqKI&usqp=CAU'
                ),
                KYCDocumentEnums.DocumentType.DocumentType.PNG
            ),
            new KYCDocument(
                new URL(
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBsHjeoGdAY8_I7EfJ_PatejqjsKCi8N1lhTZCruj918gBL5TzPXnl9_VyqRlVTpfocGk&usqp=CAU'
                ),
                KYCDocumentEnums.DocumentType.DocumentType.JPG
            ),
            new KYCDocument(
                new URL('https://www.africau.edu/images/default/sample.pdf'),
                KYCDocumentEnums.DocumentType.DocumentType.PDF
            )
        );
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                GetKYCDocumentHistoryDetailsRPC.Response,
                GetKYCDocumentHistoryDetailsRPC.Errors.Errors
            >
        >;
    }
}
