import { BORole } from '@resolut-tech/bcn-rpcs';
import { Instance, types } from 'mobx-state-tree';
import { getTranslatedString } from '../../../../utils/StringUtils';

export const BORoleModel = types.model({
    id: types.string,
    name: types.string,
    workflow: types.string,
    description: types.string
});

export const getBORoleModel = (role: BORole): Instance<typeof BORoleModel> => {
    return BORoleModel.create({
        id: role.id,
        name: getTranslatedString(role.name),
        workflow: getTranslatedString(role.workflow),
        description: getTranslatedString(role.description)
    });
};
