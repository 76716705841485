import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import { CheckGenericFundsTransferRequestRPC } from '@resolut-tech/bcn-rpcs';

export class MockCheckGenericFundsTransferRequestRPCImpl extends CheckGenericFundsTransferRequestRPC {
    execute(
        _request: CheckGenericFundsTransferRequestRPC.Request
    ): Promise<
        LeoRPCResult<
            CheckGenericFundsTransferRequestRPC.Response,
            CheckGenericFundsTransferRequestRPC.Errors.Errors
        >
    > {
        const response = new CheckGenericFundsTransferRequestRPC.Response();
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                CheckGenericFundsTransferRequestRPC.Response,
                CheckGenericFundsTransferRequestRPC.Errors.Errors
            >
        >;
    }
}
