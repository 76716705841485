import { cast, clone, flow, getParent, Instance, types } from 'mobx-state-tree';
import {
    ValidateBOUserDetailsRPC,
    UserName,
    BOName,
    CountryCode,
    BOGender
} from '@resolut-tech/bcn-rpcs';
import { LeoEmailId, LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useValidateBOUserDetailsRPCClient } from '../rpcs/RPC';
import {
    CountryListStore,
    CountryModel,
    CountryViewModel,
    createCountryListStore
} from '../../../store/country-list/CountryListStore';
import { ValidateBOUserErrors } from './ValidateBOUserErrors';
import { BOUserStore } from './BOUserStore';
import { BORoleModel } from '../models/BORoleModel';
import { getPhoneNumberWithCountryCode } from '../../common/utils/UIUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const ValidateBOUserDetailsStore = types
    .model({
        countryListStore: CountryListStore,
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        otherNames: types.maybeNull(types.string),
        country: types.maybeNull(CountryModel),
        gender: types.maybeNull(
            types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender))
        ),
        phoneNumber: types.maybeNull(types.string),
        emailId: types.maybeNull(types.string),
        roles: types.array(BORoleModel),
        userId: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<ValidateBOUserErrors>(
                    'ValidateBOUserDetailsErrors',
                    Object.values(ValidateBOUserErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        setFirstName: (name: string): void => {
            store.firstName = name;
        },
        setLastName: (name: string): void => {
            // last name has to be set to null if it is not present. When we compare null with empty string, it will return true.
            // Even if the data is empty, it will be shown as updated.
            if (!name) store.lastName = null;
            else store.lastName = name;
        },
        setOtherNames: (name: string): void => {
            // other names has to be set to null if it is not present. When we compare null with empty string, it will return true.
            // Even if the data is empty, it will be shown as updated.
            if (!name) store.otherNames = null;
            else store.otherNames = name;
        },
        setCountry(country: CountryViewModel): void {
            const loggerStore = getLoggerStore(store);
            const _country = store.countryListStore.getCountryModel(country) ?? null;
            if (_country) {
                store.country = CountryModel.create({ ..._country });
            } else {
                loggerStore.debug('Could not find selected country in countryListStore');
                store.error = NetworkingError.InternalError;
            }
        },
        setGender: (gender: BOGender.BOGender): void => {
            store.gender = gender;
        },
        setPhoneNumber: (number: string): void => {
            store.phoneNumber = number;
        },
        setEmailId: (email: string): void => {
            store.emailId = email;
        },
        setRoles: (roles: Instance<typeof BORoleModel>[]): void => {
            store.roles = cast(roles.map((role) => clone(role)));
        },
        resetStore: (): void => {
            store.firstName = null;
            store.lastName = null;
            store.emailId = null;
            store.phoneNumber = null;
            store.otherNames = null;
            store.gender = null;
            store.roles = cast([]);
            store.error = null;
            store.country = null;
            store.userId = null;
        },
        removeError: (): void => {
            store.error = null;
        },
        getCountries: flow(function* () {
            yield store.countryListStore.fetchCountries();
        }),
        preFillData(): void {
            const userDetails =
                getParent<typeof BOUserStore>(store).boUserDetailsStore.basicDetails;
            if (userDetails) {
                store.lastName = userDetails.lastName;
                store.firstName = userDetails.firstName;
                store.otherNames = userDetails.otherNames;
                store.gender = userDetails.gender;
                store.emailId = userDetails.emailId;
                store.roles = cast(userDetails.roles.map((role) => clone(role)));
            }
        },
        validateBOUserDetails: flow(function* (userId: string | null) {
            const loggerStore = getLoggerStore(store);
            const {
                firstName,
                lastName,
                otherNames,
                country,
                phoneNumber,
                emailId,
                gender,
                roles
            } = store;

            store.userId = userId;

            if (!firstName || !phoneNumber || !emailId || !country || !gender || !roles.length) {
                loggerStore.debug(
                    'Invalid input in ValidateBOUser. This should not happen since the button is only enabled when all the data is entered'
                );
                store.error = NetworkingError.InternalError;
                return;
            }

            let userUUID = null;
            let leoPhoneNumber;
            let leoEmailId;

            try {
                userUUID = userId ? new LeoUUID(userId) : null;
            } catch (error) {
                store.error = ValidateBOUserErrors.InvalidBOUserId;
                return;
            }

            try {
                leoPhoneNumber = new LeoPhoneNumber(
                    getPhoneNumberWithCountryCode(country.phoneCode, phoneNumber)
                );
            } catch (error) {
                store.error = ValidateBOUserErrors.InvalidPhoneNumber;
                return;
            }

            try {
                leoEmailId = new LeoEmailId(emailId);
            } catch (error) {
                store.error = ValidateBOUserErrors.InvalidEmailId;
                return;
            }

            try {
                const request = new ValidateBOUserDetailsRPC.Request(
                    userUUID,
                    new UserName(new BOName(firstName), lastName ? new BOName(lastName) : null),
                    otherNames ? new BOName(otherNames) : null,
                    new CountryCode(country.countryCode),
                    leoPhoneNumber,
                    leoEmailId,
                    gender,
                    roles.map(({ id }) => id)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    ValidateBOUserDetailsRPC.Response,
                    ValidateBOUserDetailsRPC.Errors.Errors
                > = yield useValidateBOUserDetailsRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case ValidateBOUserErrors.EmailIdAlreadyExists:
                            store.error = ValidateBOUserErrors.EmailIdAlreadyExists;
                            break;
                        case ValidateBOUserErrors.PhoneNumberAlreadyExists:
                            store.error = ValidateBOUserErrors.PhoneNumberAlreadyExists;
                            break;
                        case ValidateBOUserErrors.InvalidRolesCombination:
                            store.error = ValidateBOUserErrors.InvalidRolesCombination;
                            break;
                        case ValidateBOUserErrors.InvalidBOUserId:
                            store.error = ValidateBOUserErrors.InvalidBOUserId;
                            break;
                        case ValidateBOUserErrors.UserAlreadyArchived:
                            store.error = ValidateBOUserErrors.UserAlreadyArchived;
                            break;
                        case ValidateBOUserErrors.UserAlreadyInactive:
                            store.error = ValidateBOUserErrors.UserAlreadyInactive;
                            break;
                        case ValidateBOUserErrors.InvalidCountryCode:
                            store.error = ValidateBOUserErrors.InvalidCountryCode;
                            break;
                        case ValidateBOUserErrors.InvalidRoleId:
                            store.error = ValidateBOUserErrors.InvalidRoleId;
                            break;
                        case ValidateBOUserErrors.AgentCannotBeAnAgentManager:
                            store.error = ValidateBOUserErrors.AgentCannotBeAnAgentManager;
                            break;
                        case ValidateBOUserErrors.UserAppliedToBeAnAgent:
                            store.error = ValidateBOUserErrors.UserAppliedToBeAnAgent;
                            break;
                        case ValidateBOUserErrors.InvalidCountryPhoneCode:
                            store.error = ValidateBOUserErrors.InvalidCountryPhoneCode;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId:
                            store.error = ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber:
                            store.error =
                                ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error occurred: ${error} in ValidateBOUserDetailsRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in ValidateBOUserDetailsRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(`Unknown error: ${error} occurred in ValidateBOUserDetailsRPC`);
                store.error = ValidateBOUserErrors.RequestToValidateBOUserFailed;
            }
        })
    }))
    .views((store) => ({
        countryList(): CountryViewModel[] {
            return store.countryListStore.countryList();
        },
        isStorePrefilled(): boolean {
            return Boolean(store.userId);
        }
    }));

export const createValidateBOUserDetailsStore = (): Instance<typeof ValidateBOUserDetailsStore> => {
    return ValidateBOUserDetailsStore.create({
        countryListStore: createCountryListStore()
    });
};
