import { Box } from '@mui/material';
import { usePalette } from '@surya-digital/leo-reactjs-ui';
import React, { useState } from 'react';
import { FileDownloadCard } from './FileDownloadCard';
import { CommentComponent } from '../CommentComponent';
import { useTranslation } from 'react-i18next';
import { RulesDownloadFileNameEnum } from '../../types/RulesDownloadFileNameEnum';

export interface FileDetails {
    createdAt: string;
    documentId: string;
}

interface PendingRequestBodyProps {
    isLoading: boolean;
    comment: string;
    newRuleFile: FileDetails;
    currentRuleFile: FileDetails | null;
    newRuleFileName: RulesDownloadFileNameEnum;
    currentRuleFileName: RulesDownloadFileNameEnum;
    onDownloadClick: (documentId: string, fileName: RulesDownloadFileNameEnum) => void;
}

export const PendingRequestBody = ({
    isLoading,
    comment,
    newRuleFile,
    currentRuleFile,
    newRuleFileName,
    currentRuleFileName,
    onDownloadClick
}: PendingRequestBodyProps): React.ReactElement => {
    const palette = usePalette();
    const { t } = useTranslation();
    const [fileType, setFileType] = useState<'new' | 'current'>();

    return (
        <>
            <Box
                sx={{
                    borderTop: `1px solid ${palette.outline[200]}`,
                    padding: '32px 32px 32px 80px'
                }}>
                <CommentComponent sx={{ marginBottom: '16px' }} text={comment} />
                <Box sx={{ display: 'flex' }}>
                    {currentRuleFile && (
                        <FileDownloadCard
                            title={t('common.currentRules')}
                            isLoading={isLoading && fileType === 'current'}
                            fileName={currentRuleFileName}
                            timestamp={currentRuleFile.createdAt}
                            status="current"
                            onDownloadClick={(): void => {
                                setFileType('current');
                                onDownloadClick(currentRuleFile.documentId, currentRuleFileName);
                            }}
                        />
                    )}
                    {newRuleFile && (
                        <FileDownloadCard
                            title={t('common.updatedRules')}
                            isLoading={isLoading && fileType === 'new'}
                            fileName={newRuleFileName}
                            timestamp={newRuleFile.createdAt}
                            status="new"
                            onDownloadClick={(): void => {
                                setFileType('new');
                                onDownloadClick(newRuleFile.documentId, newRuleFileName);
                            }}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};
