import * as React from 'react';

export const Contact = (
    props: React.SVGProps<SVGSVGElement>
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5C4.45228 5 4 5.45228 4 6V20C4 20.5477 4.45228 21 5 21H19C19.5477 21 20 20.5477 20 20V6C20 5.45228 19.5477 5 19 5H5ZM2 6C2 4.34772 3.34772 3 5 3H19C20.6523 3 22 4.34772 22 6V20C22 21.6523 20.6523 23 19 23H5C3.34772 23 2 21.6523 2 20V6Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 1C16.5523 1 17 1.44772 17 2V4C17 4.55228 16.5523 5 16 5C15.4477 5 15 4.55228 15 4V2C15 1.44772 15.4477 1 16 1Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 1C8.55228 1 9 1.44772 9 2V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V2C7 1.44772 7.44772 1 8 1Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9ZM8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.45936 17.6588C7.9989 16.6691 9.88404 16 12.0001 16C14.0796 16 15.9896 16.5493 17.5814 17.6863C18.0308 18.0073 18.1349 18.6318 17.8139 19.0812C17.4928 19.5307 16.8683 19.6347 16.4189 19.3137C15.2106 18.4507 13.7206 18 12.0001 18C10.3162 18 8.80135 18.5309 7.54088 19.3412C7.07631 19.6398 6.4576 19.5053 6.15894 19.0408C5.86029 18.5762 5.99479 17.9575 6.45936 17.6588Z"
                fill="currentColor"
            />
        </svg>
    );
};
