import { Stack } from '@mui/material';
import { UserStatus } from '@resolut-tech/bcn-rpcs';
import {
    Icon,
    Table,
    TableHeader,
    TableOptions,
    TableRowItems,
    usePalette
} from '@surya-digital/leo-reactjs-ui';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { SuccessDialog } from '../../common/components/dialog/SuccessDialog';
import { ActionElement, PageHeader } from '../../common/components/PageHeader';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { getFormattedPhoneNumber, getTableStyleOverrides } from '../../common/utils/UIUtils';
import { BOUserFilter } from '../components/BOUserFilter';
import { BOUserFilterOptions, BOUserSearchErrors } from '../store/BOUserSearchStore';
import { useUserStore } from '../../store/hooks';
import { UserPrivileges } from '../../user/UserPrivileges';
import { AddEditBOUserDialog } from '../components/AddEditBOUserDialog';
import {
    useBORoleStore,
    useBOUserSearchStore,
    useRequestToAddEditBOUserStore,
    useValidateBOUserDetailsStore
} from '../store/hooks';
import { getInitialFilter } from '../utils/UIUtils';
import { useBreadcrumbStore } from '../../breadcrumb/store/hooks';
import { ValidateBOUserErrors } from '../store/ValidateBOUserErrors';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const BOUsers = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const navigate = useNavigate();
    const store = useBOUserSearchStore();
    const userStore = useUserStore();
    const [openAddBOUserDialog, setOpenAddBOUserDialog] = useState<boolean>(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState<boolean>(false);
    const breadcrumbStore = useBreadcrumbStore();
    const validateBOUserDetailsStore = useValidateBOUserDetailsStore();
    const addEditBOUserStore = useRequestToAddEditBOUserStore();
    const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
    const loggerStore = useLoggerStore();
    const boRoleStore = useBORoleStore();

    useEffect(() => {
        breadcrumbStore.setInitialLink(t('boUser.title'), window.location.pathname);
    }, []);

    const getHeaders = (): TableHeader => {
        return [
            {
                id: 'name',
                name: t('common.name'),
                width: '160px'
            },
            {
                id: 'mobileNumber',
                name: t('common.mobileNumber'),
                width: '160px'
            },
            {
                id: 'emailId',
                name: t('common.emailId'),
                width: '240px',
                ellipsisContent: true
            },
            {
                id: 'userStatus',
                name: t('common.userStatus'),
                width: '137px'
            },
            { id: 'action', name: '', width: '56px' }
        ];
    };

    const getUserStatus = (status: UserStatus.UserStatus): StatusType => {
        switch (status) {
            case UserStatus.UserStatus.ACTIVE:
                return StatusType.ACTIVE;
            case UserStatus.UserStatus.DEACTIVATED:
                return StatusType.DEACTIVATED;
            case UserStatus.UserStatus.ARCHIVED:
                return StatusType.ARCHIVED;
        }
    };

    const getData = async (
        option: TableOptions<Instance<typeof BOUserFilterOptions>>,
        setTotalItems: React.Dispatch<React.SetStateAction<number>>
    ): Promise<string | TableRowItems> => {
        if (option.filter) store.updateFilterOptions(option.filter);
        await store.fetchBOUserSearch(
            option.filter,
            option.page ? option.page - 1 : 0, // This is done since the server expects the page number to start with 0
            store.itemsPerPage()
        );
        if (store.error) {
            switch (store.error) {
                case BOUserSearchErrors.InvalidPageIndex:
                    return t('common.somethingWentWrongProcessingRequest');
                case NetworkingError.InternalError:
                    setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                    setIsErrorDialogOpen(true);
                    return t('common.somethingWentWrongProcessingRequest');
                default:
                    return t('common.somethingWentWrongProcessingRequest');
            }
        }
        setTotalItems(store.totalItems);
        return store.users.map((user) => {
            return [
                { data: user.name },
                { data: getFormattedPhoneNumber(user.phoneNumber) },
                { data: user.email },
                {
                    data: (
                        <StatusComponent
                            variant="status-cell"
                            status={getUserStatus(user.status)}
                        />
                    )
                },
                {
                    align: 'right',
                    data: (
                        <Icon
                            color={palette.primary[300]}
                            type="chevron-right"
                            height={24}
                            width={24}
                        />
                    )
                }
            ];
        });
    };

    const getErrorMessage = (): string => {
        let error;
        if (validateBOUserDetailsStore.error) {
            error = validateBOUserDetailsStore.error;
        } else if (addEditBOUserStore.error) {
            error = addEditBOUserStore.error;
        } else {
            error = boRoleStore.error;
        }
        switch (error) {
            case ValidateBOUserErrors.UserAlreadyArchived:
                return t('boUser.userAlreadyArchived');
            case ValidateBOUserErrors.UserAlreadyInactive:
                return t('boUser.userAlreadyInactive');
            case ValidateBOUserErrors.AgentCannotBeAnAgentManager:
                return t('boUser.agentCannotBeAnAgentManager');
            case ValidateBOUserErrors.UserAppliedToBeAnAgent:
                return t('boUser.userAppliedToBeAnAgent');
            case ValidateBOUserErrors.CannotUpdateSelfProfile:
                return t('boUser.cannotUpdateSelfProfile');
            case ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId:
                return t('boUser.requestAlreadyRaisedWithSameEmailId');
            case ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber:
                return t('boUser.requestAlreadyRaisedWithSamePhoneNumber');
            case NetworkingError.InternalError:
                return t('common.somethingWentWrongProcessingRequest');
            default:
                return t('common.somethingWentWrongProcessingRequest');
        }
    };

    const AddBOUserDialogBox = (): React.ReactElement => {
        return (
            <AddEditBOUserDialog
                isDialogOpen={openAddBOUserDialog}
                onDialogClose={(): void => {
                    setOpenAddBOUserDialog(false);
                }}
                setIsErrorDialogOpen={(): void => {
                    setErrorDialogMessage(getErrorMessage());
                    setIsErrorDialogOpen(true);
                }}
                onSuccess={(): void => {
                    setOpenAddBOUserDialog(false);
                    setIsSuccessDialogOpen(true);
                }}
            />
        );
    };

    const getPageHeaderActionElement = (): ActionElement | undefined => {
        if (userStore.privileges.includes(UserPrivileges.RequestAddEditBOUser)) {
            const PlusIcon = <Icon type="plus" color="inherit" />;

            return {
                primaryButton: {
                    title: t('boUser.addUser'),
                    icon: PlusIcon,
                    onClick: (): void => {
                        setOpenAddBOUserDialog(true);
                    }
                }
            };
        }
        return undefined;
    };

    const SuccessDialogBox = (): React.ReactElement => {
        return (
            <SuccessDialog
                title={t('boUser.requestAddBOUser')}
                successMessage={t('boUser.addBOUserSuccessMessage')}
                isDialogOpen={isSuccessDialogOpen}
                isCloseIconPresent={false}
                onCancel={(): void => {
                    setIsSuccessDialogOpen(false);
                }}
            />
        );
    };

    const ErrorDialogBox = (): React.ReactElement => {
        return (
            <ErrorDialog
                title={
                    store.error === NetworkingError.InternalError
                        ? null
                        : t('boUser.requestAddBOUser')
                }
                isErrorDialogOpen={isErrorDialogOpen}
                errorMessage={errorDialogMessage}
                onClose={(): void => {
                    setIsErrorDialogOpen(false);
                    validateBOUserDetailsStore.resetStore();
                    addEditBOUserStore.resetStore();
                    store.removeError();
                    boRoleStore.removeError();
                }}
            />
        );
    };

    return (
        <Stack>
            <PageHeader
                title={t('boUser.title')}
                subtitle={t('boUser.subtitle')}
                actionElement={getPageHeaderActionElement()}
            />
            <Table
                name="BOUserTable"
                styleOverrides={getTableStyleOverrides(palette)}
                headers={getHeaders()}
                onTableOptionsChange={getData}
                paginationOption={{
                    itemsPerPage: store.itemsPerPage(),
                    getPageIndicatorText(startItem, endItem, totalItems): string {
                        return t('common.paginationIndicationText', {
                            startItem,
                            endItem,
                            totalItems
                        });
                    }
                }}
                viewOverrides={{
                    empty: { message: t('common.noResultsFound') },
                    loading: { message: t('common.searchTableLoadingState') }
                }}
                filterOption={{
                    initialFilterValue:
                        (store.filterOptions as Instance<typeof BOUserFilterOptions>) ??
                        getInitialFilter(t),
                    filterComponent(filter, setFilter): React.ReactElement {
                        return (
                            <BOUserFilter
                                filter={filter}
                                setFilter={setFilter}
                                minimumSearchTextLength={store.minimumSearchTextLength()}
                            />
                        );
                    }
                }}
                onRowClick={(_row, index): void => {
                    const selectedBOUser = store.users[index];
                    if (selectedBOUser) {
                        navigate(`/bo/users/details?userId=${selectedBOUser.id}`);
                    } else {
                        loggerStore.debug(
                            `Array index: ${index} for selected BO User in the list with length ${store.users.length} is out of bounds`
                        );
                        setErrorDialogMessage(t('common.somethingWentWrongProcessingRequest'));
                        setIsErrorDialogOpen(true);
                    }
                }}
            />
            {openAddBOUserDialog && AddBOUserDialogBox()}
            {isErrorDialogOpen && ErrorDialogBox()}
            {isSuccessDialogOpen && SuccessDialogBox()}
        </Stack>
    );
});
