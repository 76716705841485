import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import alertCircle from '../../../../../assets/alert-circle.svg';
import { Dialog } from '../../../../common/components/Dialog';

interface ErrorDialogProps {
    title?: string | null;
    errorMessage?: string | null;
    isErrorDialogOpen: boolean;
    onClose: () => void;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onPrimaryButtonClick?: () => Promise<void>;
}

export const ErrorDialog = ({
    title,
    errorMessage,
    isErrorDialogOpen,
    onClose,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick
}: ErrorDialogProps): React.ReactElement => {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    if (!isErrorDialogOpen) return <></>;

    return (
        <Dialog
            open={isErrorDialogOpen}
            title={title ?? t('common.somethingWentWrong')}
            primaryButtonText={primaryButtonText}
            onPrimaryButtonClick={onPrimaryButtonClick}
            secondaryButtonText={secondaryButtonText ?? t('common.dismiss')}
            onSecondaryButtonClick={onClose}
            disableBackdropClick>
            <Box sx={{ textAlign: 'center' }}>
                <img src={alertCircle} alt="alert" />
                <Typography sx={{ ...typography.body2, color: palette.error[300] }}>
                    {errorMessage ?? t('common.somethingWentWrongProcessingRequest')}
                </Typography>
            </Box>
        </Dialog>
    );
};
