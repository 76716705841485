import { cast, clone, flow, getParent, Instance, types } from 'mobx-state-tree';
import {
    RequestToAddBOUserRPC,
    Comment,
    UserName,
    BOName,
    CountryCode,
    BOGender,
    RequestBOUserUpdateRPC
} from '@resolut-tech/bcn-rpcs';
import { LeoEmailId, LeoPhoneNumber, LeoRPCResult, LeoUUID } from '@surya-digital/leo-ts-runtime';
import { useRequestBOUserUpdateRPCClient, useRequestToAddBOUserRPCClient } from '../rpcs/RPC';
import { BOUserStore } from './BOUserStore';
import { ValidateBOUserErrors } from './ValidateBOUserErrors';
import { BORoleModel } from '../models/BORoleModel';
import { CountryModel } from '../../../store/country-list/CountryListStore';
import { getPhoneNumberWithCountryCode } from '../../common/utils/UIUtils';
import { getAPIClient } from '../../../networking/store/NetworkingStore';
import { getLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const RequestToAddEditBOUserStore = types
    .model({
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        otherNames: types.maybeNull(types.string),
        country: types.maybeNull(CountryModel),
        gender: types.maybeNull(
            types.enumeration<BOGender.BOGender>('BOGender', Object.values(BOGender.BOGender))
        ),
        phoneNumber: types.maybeNull(types.string),
        emailId: types.maybeNull(types.string),
        roles: types.array(BORoleModel),
        comment: types.maybeNull(types.string),
        error: types.maybeNull(
            types.union(
                types.enumeration<ValidateBOUserErrors>(
                    'RequestToAddBOUserErrors',
                    Object.values(ValidateBOUserErrors)
                ),
                types.enumeration<NetworkingError>(
                    'NetworkingError',
                    Object.values(NetworkingError)
                )
            )
        )
    })
    .actions((store) => ({
        removeError: (): void => {
            store.error = null;
        },
        setComment: (comment: string): void => {
            store.comment = comment;
        },
        setUpStore(): void {
            const loggerStore = getLoggerStore(store);
            const validateBOUserDetailsStore =
                getParent<typeof BOUserStore>(store).validateBOUsersDetailsStore;
            store.firstName = validateBOUserDetailsStore.firstName;
            store.lastName = validateBOUserDetailsStore.lastName;
            if (validateBOUserDetailsStore.country) {
                store.country = cast(
                    CountryModel.create({ ...validateBOUserDetailsStore.country })
                );
            } else {
                loggerStore.debug('Could not find country in ValidateBOUserDetailsStore');
                store.error = NetworkingError.InternalError;
            }
            store.emailId = validateBOUserDetailsStore.emailId;
            store.phoneNumber = validateBOUserDetailsStore.phoneNumber;
            store.otherNames = validateBOUserDetailsStore.otherNames;
            store.gender = validateBOUserDetailsStore.gender;
            store.roles = cast(validateBOUserDetailsStore.roles.map((role) => clone(role)));
        },
        resetStore: (): void => {
            store.firstName = null;
            store.lastName = null;
            store.emailId = null;
            store.country = null;
            store.comment = null;
            store.phoneNumber = null;
            store.error = null;
            store.otherNames = null;
            store.gender = null;
            store.roles = cast([]);
        },
        requestToAddBOUser: flow(function* () {
            const loggerStore = getLoggerStore(store);
            const {
                firstName,
                lastName,
                otherNames,
                country,
                phoneNumber,
                emailId,
                gender,
                roles,
                comment
            } = store;

            if (
                !firstName ||
                !country ||
                !phoneNumber ||
                !emailId ||
                !gender ||
                !roles.length ||
                !comment
            ) {
                loggerStore.debug(
                    'Invalid input in RequestToAddBoUser. This should not happen since the button is only enabled when all the data is entered'
                );
                store.error = NetworkingError.InternalError;
                return;
            }

            try {
                const request = new RequestToAddBOUserRPC.Request(
                    new UserName(new BOName(firstName), lastName ? new BOName(lastName) : null),
                    otherNames ? new BOName(otherNames) : null,
                    new CountryCode(country.countryCode),
                    new LeoPhoneNumber(
                        getPhoneNumberWithCountryCode(country.phoneCode, phoneNumber)
                    ),
                    new LeoEmailId(emailId),
                    gender,
                    roles.map(({ id }) => id),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestToAddBOUserRPC.Response,
                    RequestToAddBOUserRPC.Errors.Errors
                > = yield useRequestToAddBOUserRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case ValidateBOUserErrors.EmailIdAlreadyExists:
                            store.error = ValidateBOUserErrors.EmailIdAlreadyExists;
                            break;
                        case ValidateBOUserErrors.PhoneNumberAlreadyExists:
                            store.error = ValidateBOUserErrors.PhoneNumberAlreadyExists;
                            break;
                        case ValidateBOUserErrors.InvalidRolesCombination:
                            store.error = ValidateBOUserErrors.InvalidRolesCombination;
                            break;
                        case ValidateBOUserErrors.InvalidCountryCode:
                            store.error = ValidateBOUserErrors.InvalidCountryCode;
                            break;
                        case ValidateBOUserErrors.InvalidRoleId:
                            store.error = ValidateBOUserErrors.InvalidRoleId;
                            break;
                        case ValidateBOUserErrors.UserAppliedToBeAnAgent:
                            store.error = ValidateBOUserErrors.UserAppliedToBeAnAgent;
                            break;
                        case ValidateBOUserErrors.AgentCannotBeAnAgentManager:
                            store.error = ValidateBOUserErrors.AgentCannotBeAnAgentManager;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId:
                            store.error = ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber:
                            store.error =
                                ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestToAddBOUserRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestToAddBOUserRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(`Unknown error: ${error} occurred in RequestToAddBOUserRPC`);
                store.error = ValidateBOUserErrors.RequestToValidateBOUserFailed;
            }
        }),
        requestBOUserUpdate: flow(function* (boUserId: string) {
            const loggerStore = getLoggerStore(store);
            const {
                firstName,
                lastName,
                otherNames,
                phoneNumber,
                country,
                emailId,
                gender,
                roles,
                comment
            } = store;

            if (
                !firstName ||
                !phoneNumber ||
                !emailId ||
                !country ||
                !gender ||
                !roles.length ||
                !comment
            ) {
                loggerStore.debug(
                    'Invalid input in RequestToUpdateBoUser. This should not happen since the button is only enabled when all the data is entered'
                );
                store.error = NetworkingError.InternalError;
                return;
            }

            try {
                const request = new RequestBOUserUpdateRPC.Request(
                    new LeoUUID(boUserId),
                    new UserName(new BOName(firstName), lastName ? new BOName(lastName) : null),
                    otherNames ? new BOName(otherNames) : null,
                    new LeoPhoneNumber(
                        getPhoneNumberWithCountryCode(country.phoneCode, phoneNumber)
                    ),
                    new LeoEmailId(emailId),
                    gender,
                    roles.map(({ id }) => id),
                    new Comment(comment)
                );
                const apiClient = getAPIClient(store);
                const result: LeoRPCResult<
                    RequestBOUserUpdateRPC.Response,
                    RequestBOUserUpdateRPC.Errors.Errors
                > = yield useRequestBOUserUpdateRPCClient(apiClient).execute(request);
                if (result instanceof LeoRPCResult.Response) {
                    return;
                } else if (result instanceof LeoRPCResult.Error) {
                    const { error } = result;
                    switch (error.code) {
                        case ValidateBOUserErrors.EmailIdAlreadyExists:
                            store.error = ValidateBOUserErrors.EmailIdAlreadyExists;
                            break;
                        case ValidateBOUserErrors.PhoneNumberAlreadyExists:
                            store.error = ValidateBOUserErrors.PhoneNumberAlreadyExists;
                            break;
                        case ValidateBOUserErrors.InvalidRolesCombination:
                            store.error = ValidateBOUserErrors.InvalidRolesCombination;
                            break;
                        case ValidateBOUserErrors.InvalidCountryPhoneCode:
                            store.error = ValidateBOUserErrors.InvalidCountryPhoneCode;
                            break;
                        case ValidateBOUserErrors.InvalidRoleId:
                            store.error = ValidateBOUserErrors.InvalidRoleId;
                            break;
                        case ValidateBOUserErrors.InvalidBOUserId:
                            store.error = ValidateBOUserErrors.InvalidBOUserId;
                            break;
                        case ValidateBOUserErrors.UserAlreadyArchived:
                            store.error = ValidateBOUserErrors.UserAlreadyArchived;
                            break;
                        case ValidateBOUserErrors.UserAlreadyInactive:
                            store.error = ValidateBOUserErrors.UserAlreadyInactive;
                            break;
                        case ValidateBOUserErrors.CannotUpdateSelfProfile:
                            store.error = ValidateBOUserErrors.CannotUpdateSelfProfile;
                            break;
                        case ValidateBOUserErrors.UserAppliedToBeAnAgent:
                            store.error = ValidateBOUserErrors.UserAppliedToBeAnAgent;
                            break;
                        case ValidateBOUserErrors.AgentCannotBeAnAgentManager:
                            store.error = ValidateBOUserErrors.AgentCannotBeAnAgentManager;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId:
                            store.error = ValidateBOUserErrors.RequestAlreadyRaisedWithSameEmailId;
                            break;
                        case ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber:
                            store.error =
                                ValidateBOUserErrors.RequestAlreadyRaisedWithSamePhoneNumber;
                            break;
                        default:
                            loggerStore.error(
                                `Unhandled error: ${error} occurred in RequestBOUserUpdateRPC`
                            );
                            store.error = NetworkingError.InternalError;
                    }
                } else {
                    loggerStore.error(
                        `Unknown error occurred in RequestBOUserUpdateRPC with result: ${result}`
                    );
                    store.error = NetworkingError.InternalError;
                }
            } catch (error) {
                loggerStore.error(`Unknown error: ${error} occurred in RequestBOUserUpdateRPC`);
                store.error = ValidateBOUserErrors.RequestToValidateBOUserFailed;
            }
        })
    }));

export const createRequestToAddEditBOUserStore = (): Instance<
    typeof RequestToAddEditBOUserStore
> => {
    return RequestToAddEditBOUserStore.create({
        roles: []
    });
};
