import { Box, Grid } from '@mui/material';
import { DropdownInputField, DropdownItem, SearchField } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMoneyRequestInitialFilter, MoneyRequestFilterOptions } from '../utils/TableFilterUtils';
import { TableFilterContainer } from './TableFilterContainer';
import { Button } from '../../../common/components/Button';

interface RequestsListSearchProps {
    filter: Instance<typeof MoneyRequestFilterOptions>;
    setFilter: (newFilter: Instance<typeof MoneyRequestFilterOptions>) => void;
    minimumSearchTextLength: number;
    searchByOptions: DropdownItem[];
    searchFieldPlaceHolder: string;
}

export const RequestsListSearchBar = ({
    filter,
    setFilter,
    minimumSearchTextLength,
    searchByOptions,
    searchFieldPlaceHolder
}: RequestsListSearchProps): React.ReactElement => {
    const { t } = useTranslation();
    // This is used to check if the current filter combination is same as the initial filter combination.
    const isInitialFilter =
        JSON.stringify(filter) === JSON.stringify(getMoneyRequestInitialFilter());
    const [searchValue, setSearchValue] = useState<string>(filter.searchText);
    const [selectedSearchBy, setSelectedSearchBy] = useState(filter.requestStatus);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        setSearchValue(filter.searchText.trim());
        setSelectedSearchBy(filter.requestStatus);
    }, [filter]);

    const onSearchChange = (inputValue: string): void => {
        setIsSearchDisabled(false);
        setSearchValue(inputValue.trim());
    };

    const onSearchClear = (): void => {
        setSearchValue('');
        setIsSearchDisabled(false);
    };

    const onSearchByDropdownChange = (selected: DropdownItem): void => {
        setSelectedSearchBy(selected.value);
        setIsSearchDisabled(false);
        onSearchClear();
    };

    return (
        <TableFilterContainer>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <DropdownInputField
                        name="searchDropdown"
                        value={selectedSearchBy}
                        onSelect={onSearchByDropdownChange}
                        label={t('common.requestStatus')}
                        options={searchByOptions}
                    />
                </Grid>

                <Grid
                    item
                    xs={8}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="16px">
                    <SearchField
                        name="searchField"
                        value={searchValue}
                        isDisabled={false}
                        onTextChange={onSearchChange}
                        onClear={onSearchClear}
                        allowClearField={true}
                        placeholder={searchFieldPlaceHolder}
                    />

                    <Box display="flex" gap="8px">
                        <Button
                            name={t('common.reset')}
                            variant="plain-color"
                            size="medium"
                            isDisabled={isInitialFilter}
                            title={t('common.reset')}
                            color="error"
                            onClick={(): void => {
                                setIsSearchDisabled(true);
                                setFilter(getMoneyRequestInitialFilter());
                            }}
                        />

                        <Button
                            name="filled"
                            variant="filled"
                            size="medium"
                            title={t('common.search')}
                            isDisabled={
                                (searchValue.trim().length !== 0 &&
                                    searchValue.trim().length < minimumSearchTextLength) ||
                                isSearchDisabled
                            }
                            onClick={(): void => {
                                setIsSearchDisabled(true);
                                setFilter({
                                    requestStatus: selectedSearchBy,
                                    searchText: searchValue
                                });
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </TableFilterContainer>
    );
};
