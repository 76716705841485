import { Stack } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../common/components/PageHeader';
import { LoadingState } from '../../common/components/LoadingState';
import { useUpdateFeeRulesStore } from '../store/hooks';
import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { UpdateFeeRulesErrors } from '../store/UpdateFeeRulesStore';
import { DownloadFileErrorDialog } from '../../common/components/DownloadFileError';
import { WithdrawRequestErrors } from '../../common/errors/WithdrawRequestErrors';
import { LoadingIndicator } from '@surya-digital/leo-reactjs-ui';
import { WithdrawDialog } from '../../common/components/dialog/WithdrawDialog';
import { useUserStore } from '../../store/hooks';
import { ViewFeeRulesChangesComponent } from '../components/ViewFeeRulesChangesComponent';
import { useNavigate } from 'react-router-dom';
import { UploadRuleSectionComponent } from '../../common/components/rules/UploadRuleSectionComponent';
import { EditRuleFileSectionComponent } from '../../common/components/rules/EditRuleFileSectionComponent';
import { ErrorDialog } from '../../common/components/dialog/ErrorDialog';
import { PendingRuleRequestDetailsSectionComponent } from '../../common/components/rules/PendingRuleRequestDetailsSectionComponent';
import { UpdateRuleRequestComponent } from '../../common/components/rules/UpdateRuleRequestComponent';
import { RulesDownloadFileNameEnum } from '../../common/types/RulesDownloadFileNameEnum';
import { RuleEditMechanism } from '@resolut-tech/bcn-rpcs';
import { useLoggerStore } from '../../../../log/hooks';
import { NetworkingError } from '../../../error/store/ErrorStore';

export const UpdateFeeRules = observer((): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const updateFeeRuleStore = useUpdateFeeRulesStore();
    const userStore = useUserStore();
    const [loading, setLoading] = useState<boolean>();
    const [screenBlockingLoading, setScreenBlockingLoading] = useState<boolean>(false);
    const [showUploadFileDialog, setShowUploadFileDialog] = useState(false);
    const [withdrawErrorDialogLabel, setWithdrawErrorDialogLabel] = useState<string | null>();
    const [uploadErrorDialogLabel, setUploadErrorDialogLabel] = useState<string | null>();
    const [commentErrorDialogLabel, setCommentErrorDialogLabel] = useState<string | null>();
    const [isRuleUpdated, setIsRuleUpdated] = useState(false);
    const [isTemplateFileDownloading, setIsTemplateFileDownloading] = useState<boolean>(false);
    const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
    const [isDownloadErrorDialogOpen, setIsDownloadErrorDialogOpen] = useState<boolean>(false);
    const [isWithdrawRequestDialogOpen, setIsWithdrawRequestDialogOpen] = useState<boolean>(false);
    const loggerStore = useLoggerStore();

    const showViewFeeRulesChanges = Boolean(
        updateFeeRuleStore.feeRuleDetail?.currentRuleFile &&
            updateFeeRuleStore.feeRuleDetail?.newRuleFile
    );

    const fetchUpdateRules = async (): Promise<void> => {
        setLoading(true);
        updateFeeRuleStore.resetStore();
        await updateFeeRuleStore.fetchFeeRuleDetail();
        setLoading(false);
    };

    useEffect(() => {
        fetchUpdateRules();
    }, []);

    const onDownloadButtonClick = async (
        documentId: string | null,
        fileName: RulesDownloadFileNameEnum,
        updateLoadingIndicator: (value: boolean) => void
    ): Promise<void> => {
        updateLoadingIndicator(true);
        await updateFeeRuleStore.downloadFileStore.downloadFile(documentId, fileName);
        updateLoadingIndicator(false);
        if (updateFeeRuleStore.downloadFileStore.error) {
            setIsDownloadErrorDialogOpen(true);
        }
    };

    const onDownloadTemplateFileButtonClick = async (
        updateLoadingIndicator: (value: boolean) => void
    ): Promise<void> => {
        updateLoadingIndicator(true);
        await updateFeeRuleStore.downloadFileStore.downloadTemplateFile(
            RulesDownloadFileNameEnum.FeeRulesTemplateFile
        );
        updateLoadingIndicator(false);
        if (updateFeeRuleStore.downloadFileStore.error) {
            setIsDownloadErrorDialogOpen(true);
        }
    };

    const onUploadFileDialogClose = (): void => {
        setShowUploadFileDialog(false);
        setUploadErrorDialogLabel(null);
        if (isRuleUpdated) {
            setIsRuleUpdated(false);
            fetchUpdateRules();
        }
        updateFeeRuleStore.resetFileUpload();
    };

    const onRuleFileUploadAgain = (): void => {
        setUploadErrorDialogLabel(null);
        setCommentErrorDialogLabel(null);
        setIsRuleUpdated(false);
        updateFeeRuleStore.resetError();
    };

    const onUpdateRuleSubmitFile = async (recordId: LeoUUID): Promise<void> => {
        updateFeeRuleStore.setFileId(recordId.uuid);
        await updateFeeRuleStore.updateFeeRule(RuleEditMechanism.RuleEditMechanism.FILE_UPLOAD);
        if (updateFeeRuleStore.error) {
            switch (updateFeeRuleStore.error) {
                case UpdateFeeRulesErrors.InvalidComment:
                    setCommentErrorDialogLabel(t('common.invalidComment'));
                    break;
                case UpdateFeeRulesErrors.InvalidFile:
                    setUploadErrorDialogLabel(
                        updateFeeRuleStore.errorLocalizedText ?? t('common.updateRuleError')
                    );
                    break;
                case UpdateFeeRulesErrors.UnknownFile:
                    setUploadErrorDialogLabel(t('common.updateRuleError'));
                    break;
                case NetworkingError.InternalError:
                    setUploadErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setUploadErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            setIsRuleUpdated(true);
        }
    };

    const onDialogWithdrawButtonClick = async (comment: string): Promise<void> => {
        setIsWithdrawRequestDialogOpen(false);
        setScreenBlockingLoading(true);
        updateFeeRuleStore.setComment(comment);
        const _requestId = updateFeeRuleStore.requestId;
        if (_requestId) {
            await updateFeeRuleStore.withdrawFeeRuleRequest(_requestId);
        } else {
            loggerStore.debug('cannot find requestId in UpdateFeeRulesStore');
            setWithdrawErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
        }
        setScreenBlockingLoading(false);
        if (updateFeeRuleStore.error) {
            switch (updateFeeRuleStore.error) {
                case WithdrawRequestErrors.InvalidRequestId:
                    setWithdrawErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyChecked:
                    setWithdrawErrorDialogLabel(t('common.requestAlreadyChecked'));
                    break;
                case WithdrawRequestErrors.CanOnlyWithdrawSelfRequest:
                    setWithdrawErrorDialogLabel(t('common.canOnlyWithdrawSelfRequest'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyDiscarded:
                    setWithdrawErrorDialogLabel(t('common.requestAlreadyDiscarded'));
                    break;
                case WithdrawRequestErrors.RequestAlreadyWithdrawn:
                    setWithdrawErrorDialogLabel(t('common.requestAlreadyWithdrawn'));
                    break;
                case UpdateFeeRulesErrors.InvalidComment:
                    setWithdrawErrorDialogLabel(t('common.invalidComment'));
                    break;
                case NetworkingError.InternalError:
                    setWithdrawErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
                    break;
                default:
                    setWithdrawErrorDialogLabel(t('common.somethingWentWrongProcessingRequest'));
            }
        } else {
            // Since the request has been withdrawn, we will fetch the updated rules again and present the page with an empty request screen
            fetchUpdateRules();
        }
    };

    const showWithdrawRequestButton = Boolean(
        userStore.userId === updateFeeRuleStore.feeRuleDetail?.boUserComment?.userId
    );

    return (
        <Stack>
            <DownloadFileErrorDialog
                isErrorDialogOpen={isDownloadErrorDialogOpen}
                onClose={(): void => setIsDownloadErrorDialogOpen(false)}
                storeError={updateFeeRuleStore.downloadFileStore.error}
            />
            <ErrorDialog
                title={t('common.withdrawRequest')}
                errorMessage={withdrawErrorDialogLabel}
                isErrorDialogOpen={Boolean(withdrawErrorDialogLabel)}
                onClose={(): void => {
                    setWithdrawErrorDialogLabel(null);
                    updateFeeRuleStore.resetError();
                }}
            />
            <PageHeader
                title={t('feeRules.updateRules')}
                subtitle={t('feeRules.updateRulesSubtitle')}
            />
            {showUploadFileDialog && (
                <UpdateRuleRequestComponent
                    title={t('feeRules.updateRuleTitle')}
                    errorLabel={uploadErrorDialogLabel ?? null}
                    commentErrorLabel={commentErrorDialogLabel ?? null}
                    showUploadFileDialog={showUploadFileDialog}
                    store={updateFeeRuleStore.uploadFeeRulesStore}
                    onCommentChange={(_comment): void => {
                        updateFeeRuleStore.setComment(_comment);
                    }}
                    onUploadFileDialogClose={onUploadFileDialogClose}
                    onUpdateRuleSubmitFile={onUpdateRuleSubmitFile}
                    onRuleFileUploadAgain={onRuleFileUploadAgain}
                    isRuleUpdated={isRuleUpdated}
                />
            )}
            {screenBlockingLoading && <LoadingIndicator isLoading={screenBlockingLoading} />}
            {loading ? (
                <LoadingState />
            ) : (
                <>
                    <Stack padding="32px" spacing="32px">
                        {updateFeeRuleStore.feeRuleDetail &&
                            updateFeeRuleStore.feeRuleDetail.boUserComment &&
                            updateFeeRuleStore.feeRuleDetail.newRuleFile && (
                                <PendingRuleRequestDetailsSectionComponent
                                    isLoading={isFileDownloading}
                                    boUserComment={updateFeeRuleStore.feeRuleDetail.boUserComment}
                                    newRuleFile={updateFeeRuleStore.feeRuleDetail.newRuleFile}
                                    currentRuleFile={
                                        updateFeeRuleStore.feeRuleDetail.currentRuleFile
                                    }
                                    newRuleFileName={RulesDownloadFileNameEnum.NewFeeRulesFile}
                                    currentRuleFileName={
                                        RulesDownloadFileNameEnum.CurrentFeeRulesFile
                                    }
                                    onDownloadClick={(documentId, fileName): void => {
                                        onDownloadButtonClick(
                                            documentId,
                                            fileName,
                                            setIsFileDownloading
                                        );
                                    }}
                                    showWithdrawActionButton={showWithdrawRequestButton}
                                    onWithdrawClick={(): void => {
                                        setIsWithdrawRequestDialogOpen(true);
                                    }}
                                />
                            )}
                        {showViewFeeRulesChanges && updateFeeRuleStore.requestId && (
                            <ViewFeeRulesChangesComponent
                                store={updateFeeRuleStore.feeDeterminationRulesDiffStore}
                                requestId={updateFeeRuleStore.requestId}
                            />
                        )}
                        <UploadRuleSectionComponent
                            subtitle={t('feeRules.uploadFeeRuleSectionSubtitle')}
                            isFileDownloading={isTemplateFileDownloading}
                            onUploadButtonClick={(): void => {
                                setShowUploadFileDialog(true);
                            }}
                            onDownloadButtonClick={(): Promise<void> =>
                                onDownloadTemplateFileButtonClick(setIsTemplateFileDownloading)
                            }
                        />
                        <EditRuleFileSectionComponent
                            subtitle={t('feeRules.editRuleSectionSubtitle')}
                            onEditButtonClick={(): void => {
                                navigate('/fee-rules/update/edit');
                            }}
                        />
                    </Stack>

                    <WithdrawDialog
                        isDialogOpen={isWithdrawRequestDialogOpen}
                        onClose={(): void => {
                            setIsWithdrawRequestDialogOpen(false);
                        }}
                        onSubmit={onDialogWithdrawButtonClick}
                    />
                </>
            )}
        </Stack>
    );
});
