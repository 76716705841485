import { LeoRPCResult, LeoTimestamp } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';
import {
    Amount,
    BitmapImageType,
    Currency,
    GetLinkedTransactionsRPC,
    LinkedTransaction,
    LocalizedText,
    MultiResolutionBitmapImage,
    RemoteBitmapImage,
    LocalizedImage,
    ThemedImage
} from '@resolut-tech/bcn-rpcs';

export class MockGetLinkedTransactionsRPCImpl extends GetLinkedTransactionsRPC {
    execute(
        _request: GetLinkedTransactionsRPC.Request
    ): Promise<
        LeoRPCResult<GetLinkedTransactionsRPC.Response, GetLinkedTransactionsRPC.Errors.Errors>
    > {
        const response = new GetLinkedTransactionsRPC.Response([
            new LinkedTransaction(
                new LocalizedImage(
                    new ThemedImage(
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        )
                    )
                ),
                new LocalizedText('Amount Refunded: BWB Prepaid to Esther Howard'),
                new LeoTimestamp(),
                new Amount(50000000, new Currency('MWK')),
                true
            ),
            new LinkedTransaction(
                new LocalizedImage(
                    new ThemedImage(
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        )
                    )
                ),
                new LocalizedText('Fee Transaction Refunded: Yafika to Esther Howard'),
                new LeoTimestamp(),
                new Amount(50000, new Currency('MWK')),
                true
            ),
            new LinkedTransaction(
                new LocalizedImage(
                    new ThemedImage(
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        )
                    )
                ),
                new LocalizedText('Additional Fee Charged: Esther Howard to Yafika'),
                new LeoTimestamp(),
                new Amount(100000, new Currency('MWK')),
                false
            ),
            new LinkedTransaction(
                new LocalizedImage(
                    new ThemedImage(
                        new MultiResolutionBitmapImage(
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            ),
                            new RemoteBitmapImage(
                                new URL(
                                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/f3988ff5510b937a7760f87d4cd4d3092d9d55f5771a0b614ed5f858d2927910'
                                ),
                                BitmapImageType.BitmapImageType.PNG,
                                20,
                                20
                            )
                        )
                    )
                ),
                new LocalizedText('Fee Transaction: Esther Howard to Yafika'),
                new LeoTimestamp(),
                new Amount(50000, new Currency('MWK')),
                false
            )
        ]);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<GetLinkedTransactionsRPC.Response, GetLinkedTransactionsRPC.Errors.Errors>
        >;
    }
}
