import { Box, Grid, styled } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import { Instance } from 'mobx-state-tree';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '../../../../../assets/icons/Download';
import { FileDetailsModel } from '../../models/RulesModel';
import { RulesDownloadFileNameEnum } from '../../types/RulesDownloadFileNameEnum';
import { Button } from '../../../../common/components/Button';

interface FileDownloadActionButtonsProps {
    onDownloadButtonClick: (documentId: string, fileName: RulesDownloadFileNameEnum) => void;
    isLoading: boolean;
    setFileType: React.Dispatch<React.SetStateAction<'old' | 'new' | undefined>>;
    fileType: 'old' | 'new' | undefined;
    oldRuleFile: Instance<typeof FileDetailsModel> | null;
    newRuleFile: Instance<typeof FileDetailsModel> | null;
    oldRuleFileName: RulesDownloadFileNameEnum;
    newRuleFileName: RulesDownloadFileNameEnum;
}

export function FileDownloadActionButtons({
    onDownloadButtonClick,
    isLoading,
    setFileType,
    fileType,
    oldRuleFile,
    newRuleFile,
    oldRuleFileName,
    newRuleFileName
}: FileDownloadActionButtonsProps): React.ReactElement {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    const StyledHeading = styled(Box)(() => ({
        ...typography.small2,
        color: palette.label[300]
    }));

    return (
        <Grid container sx={{ marginTop: '16px' }}>
            {oldRuleFile && (
                <Grid item xs={6}>
                    <StyledHeading>{t('common.oldRules')}</StyledHeading>
                    <Button
                        isLoading={isLoading && fileType === 'old'}
                        style={{ width: '128px' }}
                        name="download"
                        size="small"
                        icon={<Download />}
                        iconPosition="left"
                        title={t('common.downloadFile')}
                        variant="outlined-color"
                        onClick={(): void => {
                            if (oldRuleFile) {
                                setFileType('old');
                                onDownloadButtonClick(oldRuleFile.documentId, oldRuleFileName);
                            }
                        }}
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                <StyledHeading>{t('common.updatedRules')}</StyledHeading>
                <Button
                    isLoading={isLoading && fileType === 'new'}
                    style={{ width: '128px' }}
                    name="download"
                    size="small"
                    icon={<Download />}
                    iconPosition="left"
                    title={t('common.downloadFile')}
                    variant="outlined-color"
                    onClick={(): void => {
                        if (newRuleFile) {
                            setFileType('new');
                            onDownloadButtonClick(newRuleFile.documentId, newRuleFileName);
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}
