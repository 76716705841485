import React from 'react';
import { TextInputField } from '@surya-digital/leo-reactjs-ui';
import { getFormattedAmount, parseFormattedAmount } from './utils/InputFieldUtils';

type InputAdornmentPlacement = 'left' | 'right';

interface AmountTextFieldProps {
    name: string;
    value: string | undefined;
    onAmountChange: (value: string) => void;
    label: string;
    error?: boolean;
    inputAdornmentPlacement?: InputAdornmentPlacement;
    inputAdornmentText?: string;
    isDisabled?: boolean;
    helperText?: string;
    helperIcon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
    style?: React.CSSProperties;
    isDecimalAllowed: boolean;
    isRequired?: boolean;
}

export function AmountTextField({
    isDisabled,
    name,
    value,
    onAmountChange,
    label,
    error,
    inputAdornmentPlacement,
    inputAdornmentText,
    helperText,
    style,
    isDecimalAllowed,
    helperIcon,
    isRequired
}: AmountTextFieldProps): React.ReactElement {
    const onInputChange = (amount: string): void => {
        const formattedValue = getFormattedAmount(amount, isDecimalAllowed);
        onAmountChange(formattedValue);
    };

    return (
        <TextInputField
            name={name}
            value={value ? parseFormattedAmount(value) : ''}
            type="text"
            onTextChange={function (valueOnChange: string): void {
                onInputChange(valueOnChange);
            }}
            isRequired={isRequired}
            label={label}
            error={error}
            isDisabled={isDisabled}
            helperText={helperText}
            helperIcon={helperIcon}
            inputAdornmentPlacement={inputAdornmentPlacement}
            inputAdornmentText={inputAdornmentText}
            style={style}
        />
    );
}
