import {
    CBSTransferForUnclaimedFundsRequestHistoryRPC,
    CBSTransferForUnclaimedFundsRequestHistoryRPCClientImpl,
    CheckCBSTransferRequestForUnclaimedFundsRPC,
    CheckCBSTransferRequestForUnclaimedFundsRPCClientImpl,
    DownloadUnclaimedFundsRequestRPC,
    DownloadUnclaimedFundsRequestRPCClientImpl,
    GetMakerUnclaimedTransactionInfoRPC,
    GetMakerUnclaimedTransactionInfoRPCClientImpl,
    GetCheckerUnclaimedFundsRequestInfoSummaryRPC,
    GetCheckerUnclaimedFundsRequestInfoSummaryRPCClientImpl,
    GetUnclaimedFundsRequestDetailsListRPC,
    GetUnclaimedFundsRequestDetailsListRPCClientImpl,
    GetUnclaimedFundsRequestDetailsRPC,
    GetUnclaimedFundsRequestDetailsRPCClientImpl,
    GetUnclaimedFundsRequestSummaryRPC,
    GetUnclaimedFundsRequestSummaryRPCClientImpl,
    GetUnclaimedTransactionDetailsRPC,
    GetUnclaimedTransactionDetailsRPCClientImpl,
    RequestCBSTransferForUnclaimedFundsRPC,
    RequestCBSTransferForUnclaimedFundsRPCClientImpl,
    WithdrawCBSTransferForUnclaimedFundsRequestRPC,
    WithdrawCBSTransferForUnclaimedFundsRequestRPCClientImpl,
    GetUnclaimedFundListRPC,
    GetUnclaimedFundListRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockGetMakerUnclaimedTransactionInfoRPCImpl } from './MockGetMakerUnclaimedTransactionInfoRPCImpl';
import { MockGetUnclaimedFundsRequestSummaryRPCImpl } from './MockGetUnclaimedFundsRequestSummaryRPCImpl.ts';
import { APIClient } from '@surya-digital/tedwig';
import { MockGetUnclaimedFundsListRPCImpl } from './MockGetUnclaimedFundsListRPCImpl';
import { MockRequestCBSTransferForUnclaimedFundsRPCImpl } from './MockRequestCBSTransferForUnclaimedFundsRPCImpl';
import { MockGetUnclaimedTransactionDetailsRPCImpl } from './MockGetUnclaimedTransactionDetailsRPCImpl';
import { MockGetUnclaimedFundsRequestDetailsListRPCImpl } from './MockGetUnclaimedFundsRequestDetailsListRPCImpl';
import { MockDownloadUnclaimedFundsRequestRPCImpl } from './MockDownloadUnclaimedFundsRequestRPCImpl';
import { MockWithdrawCBSTransferForUnclaimedFundsRequestRPCImpl } from './MockWithdrawCBSTransferForUnclaimedFundsRequestRPCImpl';
import { MockCBSTransferForUnclaimedFundsRequestHistoryImpl } from './MockCBSTransferForUnclaimedFundsRequestHistoryImpl';
import { MockCheckCBSTransferRequestForUnclaimedFundsRPCImpl } from './MockCheckCBSTransferRequestForUnclaimedFundsRPCImpl';
import { MockGetCheckerUnclaimedFundsRequestInfoSummaryRPCImpl } from './MockGetCheckerUnclaimedFundsRequestInfoSummaryRPCImpl';
import { MockGetUnclaimedFundsRequestDetailsRPCImpl } from './MockGetUnclaimedFundsRequestDetailsRPCImpl';

const MOCK = false;

export const useGetUnclaimedFundsListRPCClient = (
    apiClient: APIClient
): GetUnclaimedFundListRPC => {
    if (MOCK) {
        return new MockGetUnclaimedFundsListRPCImpl();
    } else {
        return new GetUnclaimedFundListRPCClientImpl(apiClient);
    }
};

export const useGetMakerUnclaimedTransactionInfoRPCClient = (
    apiClient: APIClient
): GetMakerUnclaimedTransactionInfoRPC => {
    if (MOCK) {
        return new MockGetMakerUnclaimedTransactionInfoRPCImpl();
    } else {
        return new GetMakerUnclaimedTransactionInfoRPCClientImpl(apiClient);
    }
};

export const useGetUnclaimedFundsRequestSummaryRPCClient = (
    apiClient: APIClient
): GetUnclaimedFundsRequestSummaryRPC => {
    if (MOCK) {
        return new MockGetUnclaimedFundsRequestSummaryRPCImpl();
    } else {
        return new GetUnclaimedFundsRequestSummaryRPCClientImpl(apiClient);
    }
};

export const useRequestCBSTransferForUnclaimedFundsRPCClient = (
    apiClient: APIClient
): RequestCBSTransferForUnclaimedFundsRPC => {
    if (MOCK) {
        return new MockRequestCBSTransferForUnclaimedFundsRPCImpl();
    } else {
        return new RequestCBSTransferForUnclaimedFundsRPCClientImpl(apiClient);
    }
};

export const useGetUnclaimedTransactionDetailsRPCClient = (
    apiClient: APIClient
): GetUnclaimedTransactionDetailsRPC => {
    if (MOCK) {
        return new MockGetUnclaimedTransactionDetailsRPCImpl();
    } else {
        return new GetUnclaimedTransactionDetailsRPCClientImpl(apiClient);
    }
};

export const useGetUnclaimedFundsRequestDetailsRPCClient = (
    apiClient: APIClient
): GetUnclaimedFundsRequestDetailsRPC => {
    if (MOCK) {
        return new MockGetUnclaimedFundsRequestDetailsRPCImpl();
    } else {
        return new GetUnclaimedFundsRequestDetailsRPCClientImpl(apiClient);
    }
};

export const useGetUnclaimedFundsRequestDetailsListRPCClient = (
    apiClient: APIClient
): GetUnclaimedFundsRequestDetailsListRPC => {
    if (MOCK) {
        return new MockGetUnclaimedFundsRequestDetailsListRPCImpl();
    } else {
        return new GetUnclaimedFundsRequestDetailsListRPCClientImpl(apiClient);
    }
};

export const useDownloadUnclaimedFundsRequestRPCClient = (
    apiClient: APIClient
): DownloadUnclaimedFundsRequestRPC => {
    if (MOCK) {
        return new MockDownloadUnclaimedFundsRequestRPCImpl();
    } else {
        return new DownloadUnclaimedFundsRequestRPCClientImpl(apiClient);
    }
};

export const useWithdrawCBSTransferForUnclaimedFundsRequestRPCClient = (
    apiClient: APIClient
): WithdrawCBSTransferForUnclaimedFundsRequestRPC => {
    if (MOCK) {
        return new MockWithdrawCBSTransferForUnclaimedFundsRequestRPCImpl();
    } else {
        return new WithdrawCBSTransferForUnclaimedFundsRequestRPCClientImpl(apiClient);
    }
};

export const useCBSTransferForUnclaimedFundsRequestHistoryClient = (
    apiClient: APIClient
): CBSTransferForUnclaimedFundsRequestHistoryRPC => {
    if (MOCK) {
        return new MockCBSTransferForUnclaimedFundsRequestHistoryImpl();
    } else {
        return new CBSTransferForUnclaimedFundsRequestHistoryRPCClientImpl(apiClient);
    }
};

export const useCheckCBSTransferRequestForUnclaimedFundsRPCClient = (
    apiClient: APIClient
): CheckCBSTransferRequestForUnclaimedFundsRPC => {
    if (MOCK) {
        return new MockCheckCBSTransferRequestForUnclaimedFundsRPCImpl();
    } else {
        return new CheckCBSTransferRequestForUnclaimedFundsRPCClientImpl(apiClient);
    }
};

export const useGetCheckerUnclaimedFundsRequestInfoSummaryRPCClient = (
    apiClient: APIClient
): GetCheckerUnclaimedFundsRequestInfoSummaryRPC => {
    if (MOCK) {
        return new MockGetCheckerUnclaimedFundsRequestInfoSummaryRPCImpl();
    } else {
        return new GetCheckerUnclaimedFundsRequestInfoSummaryRPCClientImpl(apiClient);
    }
};
