import { Divider, Grid, Stack, styled, Typography } from '@mui/material';
import { Card, Clock, usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BOUserCommentModel } from '../../common/models/BOUserCommentModel';
import { Instance } from 'mobx-state-tree';
import { StatusComponent, StatusType } from '../../common/components/StatusComponent';
import { Download } from '../../../../assets/icons/Download';
import { Button } from '../../../common/components/Button';

interface UnclaimedProcessingRequestDetailCardProps {
    makerComment: Instance<typeof BOUserCommentModel>;
    checkerComment: Instance<typeof BOUserCommentModel>;
    onSecondaryButtonClick: () => void;
    viewDownloadButton?: boolean;
}

export const UnclaimedProcessingRequestDetailCard = ({
    makerComment,
    checkerComment,
    onSecondaryButtonClick,
    viewDownloadButton
}: UnclaimedProcessingRequestDetailCardProps): React.ReactElement => {
    const { t } = useTranslation();
    const palette = usePalette();
    const typography = useTypography();

    const StyledHeading = styled(Stack)(() => ({
        ...typography.small2,
        color: palette.label[300],
        marginTop: '12px'
    }));

    const StyledContent = styled(Stack)(() => ({
        ...typography.body2,
        color: palette.label[300],
        wordWrap: 'break-word'
    }));

    const getProcessingRequestDetails = (
        comment: Instance<typeof BOUserCommentModel>,
        showRequesterSection: boolean
    ): React.ReactElement => {
        return (
            <Grid
                container
                sx={{
                    marginLeft: '40px',
                    marginTop: '16px'
                }}>
                <Grid item xs={12}>
                    <Typography sx={{ ...typography.sh3, color: palette.label[300] }}>
                        {showRequesterSection
                            ? t('common.requestDetails')
                            : t('common.evaluationDetails')}
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{ paddingRight: '20px' }}>
                    <StyledHeading>{t('common.timeDate')}</StyledHeading>
                    <StyledContent>
                        <StyledContent>{comment.requestedAt}</StyledContent>
                    </StyledContent>
                </Grid>
                <Grid item xs={6}>
                    <StyledHeading>
                        {showRequesterSection ? t('common.requestedBy') : t('common.evaluatedBy')}
                    </StyledHeading>
                    <StyledContent>{comment.name}</StyledContent>
                </Grid>
                <Grid item xs={12}>
                    <StyledHeading>{t('common.comment')}</StyledHeading>
                    <StyledContent>{comment.comment}</StyledContent>
                </Grid>
                {showRequesterSection ? (
                    <>
                        <Button
                            name="secondaryButton"
                            size="small"
                            variant="outlined-color"
                            color="primary"
                            icon={viewDownloadButton ? <Download /> : undefined}
                            iconPosition={viewDownloadButton ? 'left' : undefined}
                            onClick={onSecondaryButtonClick}
                            title={
                                viewDownloadButton
                                    ? t('common.downloadRequestsZip')
                                    : t('common.viewRequestDetails')
                            }
                            style={{
                                width: viewDownloadButton ? '177px' : '147px',
                                marginTop: '8px'
                            }}
                        />
                    </>
                ) : (
                    <Grid item xs={12}>
                        <StyledHeading>{t('common.evaluationAction')}</StyledHeading>
                        <StatusComponent variant="status" status={StatusType.APPROVED} />
                    </Grid>
                )}
            </Grid>
        );
    };
    return (
        <Card
            style={{
                background: palette.warning[100],
                padding: '16px',
                borderRadius: '8px',
                border: `1px solid ${palette.warning[200]}`
            }}>
            <Stack direction="row">
                <Clock color={palette.warning[300]} width="24px" height="24px" />
                <Typography
                    sx={{ color: palette.warning[300], ...typography.small2, marginLeft: '16px' }}>
                    {t('unclaimedFunds.unclaimedProcessingCardTitle')}
                </Typography>
            </Stack>
            {getProcessingRequestDetails(checkerComment, false)}
            <Divider
                sx={{
                    color: palette.outline[200],
                    marginTop: '16px',
                    margin: '16px 32px  0px 32px'
                }}
            />
            {getProcessingRequestDetails(makerComment, true)}
        </Card>
    );
};
