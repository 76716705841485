import { Instance, types } from 'mobx-state-tree';
import { PasswordPolicyModel } from '../../../common/models/PasswordPolicyModel';
import { ChangePasswordStore, createChangePasswordStore } from './ChangePasswordStore';
import { ChangeProfileImageStore, createChangeProfileImageStore } from './ChangeProfileImageStore';
import { createDeleteProfileImageStore, DeleteProfileImageStore } from './DeleteProfileImageStore';
import { createProfileDetailsStore, ProfileDetailsStore } from './ProfileDetailsStore';
import {
    createRegenerateAuthCodesStore,
    RegenerateAuthCodesStore
} from './RegenerateAuthCodesStore';

export const ProfileStore = types
    .model({
        profileDetailsStore: ProfileDetailsStore,
        changePasswordStore: ChangePasswordStore,
        regenerateAuthCodesStore: RegenerateAuthCodesStore,
        deleteProfileImageStore: DeleteProfileImageStore,
        changeProfileImageStore: ChangeProfileImageStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.profileDetailsStore = createProfileDetailsStore();
            store.changePasswordStore = createChangePasswordStore();
            store.regenerateAuthCodesStore = createRegenerateAuthCodesStore();
            store.deleteProfileImageStore = createDeleteProfileImageStore();
            store.changeProfileImageStore = createChangeProfileImageStore();
        }
    }))
    .views((store) => ({
        passwordPolicy(): Instance<typeof PasswordPolicyModel> | null {
            return store.profileDetailsStore.details.passwordPolicy;
        }
    }));

export const createProfileStore = (): Instance<typeof ProfileStore> => {
    return ProfileStore.create({
        profileDetailsStore: createProfileDetailsStore(),
        changePasswordStore: createChangePasswordStore(),
        regenerateAuthCodesStore: createRegenerateAuthCodesStore(),
        deleteProfileImageStore: createDeleteProfileImageStore(),
        changeProfileImageStore: createChangeProfileImageStore()
    });
};
