import {
    CheckBCNUserUpdateRequestRPC,
    CheckBCNUserUpdateRequestRPCClientImpl,
    CheckToArchiveBCNUserUpdateRequestWithBalanceRPC,
    CheckToArchiveBCNUserUpdateRequestWithBalanceRPCClientImpl,
    GetBCNUserDetailsRPC,
    GetBCNUserDetailsRPCClientImpl,
    GetBCNUserListRPC,
    GetBCNUserListRPCClientImpl,
    GetBCNUserRequestHistoryRPC,
    GetBCNUserRequestHistoryRPCClientImpl,
    GetBCNUserRequestsRPC,
    GetBCNUserRequestsRPCClientImpl,
    GetKYCDocumentHistoryDetailsRPC,
    GetKYCDocumentHistoryDetailsRPCClientImpl,
    RequestToArchiveBCNUserRPC,
    RequestToArchiveBCNUserRPCClientImpl,
    RequestToArchiveBCNUserWithBalanceRPC,
    RequestToArchiveBCNUserWithBalanceRPCClientImpl,
    RequestToReactivateOrDeactivateBCNUserRPC,
    RequestToReactivateOrDeactivateBCNUserRPCClientImpl,
    WithdrawBCNUserUpdateRequestRPC,
    WithdrawBCNUserUpdateRequestRPCClientImpl
} from '@resolut-tech/bcn-rpcs';
import { MockCheckBCNUserUpdateRequestRPCImpl } from './MockCheckBCNUserUpdateRequestRPCImpl';
import { MockCheckToArchiveBCNUserUpdateRequestWithBalanceRPCImpl } from './MockCheckToArchiveBCNUserUpdateRequestWithBalanceRPCImpl';
import { MockGetBCNUserDetailsImpl } from './MockGetBCNUserDetailsRPCImpl';
import { MockGetBCNUserRequestHistoryRPCImpl } from './MockGetBCNUserRequestHistoryRPCImpl';
import { MockGetBCNUserRequestsRPCImpl } from './MockGetBCNUserRequestsRPCImpl';
import { MockGetBCNUserListRPCImpl } from './MockGetBCNUserListRPCImpl';
import { MockGetKYCDocumentHistoryDetailsRPCImpl } from './MockGetKYCDocumentHistoryDetailsRPCImpl';
import { MockRequestToReactivateOrDeactivateBCNUserRPCImpl } from './MockRequestToReactivateOrDeactivateBCNUserRPCImpl';
import { MockRequestToArchiveBCNUserRPCImpl } from './MockRequestToArchiveBCNUserRPCImpl';
import { MockRequestToArchiveBCNUserWithBalanceRPCImpl } from './MockRequestToArchiveBCNUserWithBalanceRPCImpl';
import { MockWithdrawBCNUserUpdateRequestRPCImpl } from './MockWithdrawBCNUserUpdateRequestImpl';
import { APIClient } from '@surya-digital/tedwig';

const MOCK = false;

export const useGetBCNUserListRPCClient = (apiClient: APIClient): GetBCNUserListRPC => {
    if (MOCK) {
        return new MockGetBCNUserListRPCImpl();
    } else {
        return new GetBCNUserListRPCClientImpl(apiClient);
    }
};

export const useGetBCNUserDetailsRPCClient = (apiClient: APIClient): GetBCNUserDetailsRPC => {
    if (MOCK) {
        return new MockGetBCNUserDetailsImpl();
    } else {
        return new GetBCNUserDetailsRPCClientImpl(apiClient);
    }
};

export const useWithdrawBCNUserUpdateRequestRPCClient = (
    apiClient: APIClient
): WithdrawBCNUserUpdateRequestRPC => {
    if (MOCK) {
        return new MockWithdrawBCNUserUpdateRequestRPCImpl();
    } else {
        return new WithdrawBCNUserUpdateRequestRPCClientImpl(apiClient);
    }
};

export const useCheckBCNUserUpdateRequestRPCClient = (
    apiClient: APIClient
): CheckBCNUserUpdateRequestRPC => {
    if (MOCK) {
        return new MockCheckBCNUserUpdateRequestRPCImpl();
    } else {
        return new CheckBCNUserUpdateRequestRPCClientImpl(apiClient);
    }
};

export const useGetBCNUserRequestsRPCClient = (apiClient: APIClient): GetBCNUserRequestsRPC => {
    if (MOCK) {
        return new MockGetBCNUserRequestsRPCImpl();
    } else {
        return new GetBCNUserRequestsRPCClientImpl(apiClient);
    }
};

export const useRequestToReactivateOrDeactivateBCNUserRPCClient = (
    apiClient: APIClient
): RequestToReactivateOrDeactivateBCNUserRPC => {
    if (MOCK) {
        return new MockRequestToReactivateOrDeactivateBCNUserRPCImpl();
    } else {
        return new RequestToReactivateOrDeactivateBCNUserRPCClientImpl(apiClient);
    }
};

export const useRequestToArchiveBCNUserRPCClient = (
    apiClient: APIClient
): RequestToArchiveBCNUserRPC => {
    if (MOCK) {
        return new MockRequestToArchiveBCNUserRPCImpl();
    } else {
        return new RequestToArchiveBCNUserRPCClientImpl(apiClient);
    }
};

export const useRequestToArchiveBCNUserWithBalanceRPCClient = (
    apiClient: APIClient
): RequestToArchiveBCNUserWithBalanceRPC => {
    if (MOCK) {
        return new MockRequestToArchiveBCNUserWithBalanceRPCImpl();
    } else {
        return new RequestToArchiveBCNUserWithBalanceRPCClientImpl(apiClient);
    }
};

export const useCheckToArchiveBCNUserUpdateRequestWithBalanceRPCClient = (
    apiClient: APIClient
): CheckToArchiveBCNUserUpdateRequestWithBalanceRPC => {
    if (MOCK) {
        return new MockCheckToArchiveBCNUserUpdateRequestWithBalanceRPCImpl();
    } else {
        return new CheckToArchiveBCNUserUpdateRequestWithBalanceRPCClientImpl(apiClient);
    }
};

export const useGetBCNUserRequestHistoryRPCClient = (
    apiClient: APIClient
): GetBCNUserRequestHistoryRPC => {
    if (MOCK) {
        return new MockGetBCNUserRequestHistoryRPCImpl();
    } else {
        return new GetBCNUserRequestHistoryRPCClientImpl(apiClient);
    }
};

export const useGetKYCDocumentHistoryDetailsRPCClient = (
    apiClient: APIClient
): GetKYCDocumentHistoryDetailsRPC => {
    if (MOCK) {
        return new MockGetKYCDocumentHistoryDetailsRPCImpl();
    } else {
        return new GetKYCDocumentHistoryDetailsRPCClientImpl(apiClient);
    }
};
