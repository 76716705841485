import { Box, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { UserAvatar } from '../../common/components/UserAvatar';

interface UserPhotoRowProps {
    label: string;
    photoUrl?: string;
}

export const UserPhotoRow = ({ label, photoUrl }: UserPhotoRowProps): React.ReactElement => {
    const palette = usePalette();
    const typography = useTypography();

    return (
        <Box sx={{ marginBottom: '8px' }}>
            <Typography
                sx={{ ...typography.small2, color: palette.label[300], marginBottom: '8px' }}>
                {label}
            </Typography>
            <UserAvatar src={photoUrl} size="medium" />
        </Box>
    );
};
