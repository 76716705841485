import { LeoUUID } from '@surya-digital/leo-ts-runtime';
import { getParent, Instance, types } from 'mobx-state-tree';
import { MobileNumberEntryStore, createMobileNumberEntryStore } from './MobileNumberEntryStore';
import { AuthCodeEntryStore, createAuthCodeEntryStore } from './AuthCodeEntryStore';
import { OTPEntryStore, createOTPEntryStore } from './OTPEntryStore';
import { createNewPasswordEntryStore, NewPasswordEntryStore } from './NewPasswordEntryStore';
import { PasswordPolicyModel } from '../../common/models/PasswordPolicyModel';
import { RootStore } from '../../store/root/store/RootStore';

export const SignInStore = types
    .model({
        mobileNumberEntry: MobileNumberEntryStore,
        authCodeEntry: AuthCodeEntryStore,
        otpEntry: OTPEntryStore,
        newPasswordEntryStore: NewPasswordEntryStore
    })
    .actions((store) => ({
        resetStore(): void {
            store.mobileNumberEntry = createMobileNumberEntryStore();
            store.authCodeEntry = createAuthCodeEntryStore();
            store.otpEntry = createOTPEntryStore();
            store.newPasswordEntryStore = createNewPasswordEntryStore();
        },
        completeSignInProcess(): void {
            this.resetStore();
            const rootStore = getParent<typeof RootStore>(store);
            rootStore.completeSignInProcess();
        }
    }))
    .views((store) => ({
        phoneNumber(): string {
            return store.mobileNumberEntry.phoneNumber;
        },
        phoneCode(): string | undefined {
            if (store.mobileNumberEntry.country) {
                return store.mobileNumberEntry.country?.phoneCode;
            }
        },
        authCodeLHS(): string | null {
            return store.mobileNumberEntry.authCodeLHS;
        },
        passwordValidatedToken(): LeoUUID | null {
            const passwordValidatedToken = store.mobileNumberEntry.passwordValidatedToken;
            if (passwordValidatedToken) {
                const tokenId = new LeoUUID(passwordValidatedToken);
                return tokenId;
            }
            return null;
        },
        otpId(): LeoUUID | null {
            const otpId = store.authCodeEntry.otpId;
            if (otpId) {
                const _otpId = new LeoUUID(otpId);
                return _otpId;
            }
            return null;
        },
        otpNextResendAt(): Date | null {
            return store.authCodeEntry.nextResendAt;
        },
        otpValidatedToken(): LeoUUID | null {
            const otpValidatedToken = store.otpEntry.otpValidatedToken;
            if (otpValidatedToken) {
                const _otpValidatedToken = new LeoUUID(otpValidatedToken);
                return _otpValidatedToken;
            }
            return null;
        },
        passwordPolicy(): Instance<typeof PasswordPolicyModel> | null {
            return store.otpEntry.passwordPolicy;
        }
    }));

export const createSignInStore = (): Instance<typeof SignInStore> => {
    return SignInStore.create({
        mobileNumberEntry: createMobileNumberEntryStore(),
        authCodeEntry: createAuthCodeEntryStore(),
        otpEntry: createOTPEntryStore(),
        newPasswordEntryStore: createNewPasswordEntryStore()
    });
};
