import { Instance } from 'mobx-state-tree';
import { useRootStore } from '../../../store/root/store/RootStore';
import { EvaluateTransactionRulesStore } from './EvaluateTransactionRulesStore';
import { TransactionRuleRequestDetailsStore } from './TransactionRuleRequestDetailsStore';
import { UpdateTransactionRulesStore } from './UpdateTransactionRulesStore';
import { ViewTransactionRulesHistoryStore } from './ViewTransactionRulesHistoryStore';
import { ViewTransactionRulesStore } from './ViewTransactionRulesStore';

export const useViewTransactionRulesStore = (): Instance<typeof ViewTransactionRulesStore> => {
    return useRootStore().homeStore.transactionRules.viewTransactionRulesStore;
};

export const useUpdateTransactionRulesStore = (): Instance<typeof UpdateTransactionRulesStore> => {
    return useRootStore().homeStore.transactionRules.updateTransactionRulesStore;
};

export const useViewTransactionRulesHistoryStore = (): Instance<
    typeof ViewTransactionRulesHistoryStore
> => {
    return useRootStore().homeStore.transactionRules.viewTransactionRulesHistoryStore;
};

export const useEvaluateTransactionRulesStore = (): Instance<
    typeof EvaluateTransactionRulesStore
> => {
    return useRootStore().homeStore.transactionRules.evaluateTransactionRulesStore;
};

export const useTransactionRuleRequestDetailsStore = (): Instance<
    typeof TransactionRuleRequestDetailsStore
> => {
    return useRootStore().homeStore.transactionRules.transactionRuleRequestDetailsStore;
};
