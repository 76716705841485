import {
    BackOfficeChangeProfileImageRPC,
    BitmapImageType,
    MultiResolutionBitmapImage,
    RemoteBitmapImage
} from '@resolut-tech/bcn-rpcs';
import { LeoRPCResult } from '@surya-digital/leo-ts-runtime';
import { mockExecute } from '../../../networking/MockExecuteHelper';

export class MockBackOfficeChangeProfileImageRPC extends BackOfficeChangeProfileImageRPC {
    execute(
        _request: BackOfficeChangeProfileImageRPC.Request
    ): Promise<
        LeoRPCResult<
            BackOfficeChangeProfileImageRPC.Response,
            BackOfficeChangeProfileImageRPC.Errors.Errors
        >
    > {
        const profilePicture = new MultiResolutionBitmapImage(
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            ),
            new RemoteBitmapImage(
                new URL(
                    'https://bcn-dev-static.s3.af-south-1.amazonaws.com/594edb07119a1b493ae3453e020ad478a027b3274989414d5bd8cce274082cec'
                ),
                BitmapImageType.BitmapImageType.PNG,
                20,
                20
            )
        );
        const response = new BackOfficeChangeProfileImageRPC.Response(profilePicture);
        return mockExecute({ response }) as Promise<
            LeoRPCResult<
                BackOfficeChangeProfileImageRPC.Response,
                BackOfficeChangeProfileImageRPC.Errors.Errors
            >
        >;
    }
}
