import { Box, styled, SxProps, Typography } from '@mui/material';
import { usePalette, useTypography } from '@surya-digital/leo-reactjs-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum StatusType {
    ACTIVE,
    DEACTIVATED,
    ARCHIVED,
    DEFAULT,
    PENDING,
    APPROVED,
    DENIED,
    REFUNDED,
    DISCARDED,
    WITHDRAWN,
    PROCESSING,
    SUCCEEDED,
    UNCOLLECTED,
    AWAITING_RECIPIENT_COLLECTION,
    REFUND_TO_SENDER_BLOCKED,
    MOVED_TO_UNCLAIMED_FUNDS,
    MOVED_OUT_OF_YAFIKA,
    REVERSED
}

// status variant is used in Cards.
// status-cell variant is used in TableCell.
type StatusComponentVariant = 'status' | 'status-cell';

interface StatusProps {
    variant: StatusComponentVariant;
    status: StatusType;
    sx?: SxProps;
}

export function StatusComponent({ variant, status, sx }: StatusProps): React.ReactElement {
    const palette = usePalette();
    const { t } = useTranslation();
    const typography = useTypography();

    function getBackgroundColor(): string {
        switch (status) {
            case StatusType.ACTIVE:
            case StatusType.APPROVED:
            case StatusType.SUCCEEDED:
            case StatusType.MOVED_OUT_OF_YAFIKA:
                return palette.success[300];
            case StatusType.DEACTIVATED:
            case StatusType.PENDING:
            case StatusType.UNCOLLECTED:
            case StatusType.PROCESSING:
            case StatusType.AWAITING_RECIPIENT_COLLECTION:
            case StatusType.MOVED_TO_UNCLAIMED_FUNDS:
                return palette.warning[300];
            case StatusType.DEFAULT:
                return palette.informative[300];
            case StatusType.ARCHIVED:
            case StatusType.DENIED:
            case StatusType.REFUNDED:
            case StatusType.REFUND_TO_SENDER_BLOCKED:
            case StatusType.REVERSED:
                return palette.error[300];
            case StatusType.DISCARDED:
            case StatusType.WITHDRAWN:
                return palette.background[100];
        }
    }

    function getTextColor(): string {
        switch (status) {
            case StatusType.DISCARDED:
            case StatusType.WITHDRAWN:
                return palette.label[300];
            default:
                return 'white';
        }
    }

    const StyledStatusBox = styled(Box)(() => ({
        borderRadius: '250px',
        padding: variant === 'status' ? '8px 12px' : '6px 10px',
        backgroundColor: getBackgroundColor(),
        textTransform: 'uppercase',
        width: 'fit-content',
        height: 'fit-content'
    }));

    function getTitle(): string {
        switch (status) {
            case StatusType.ACTIVE:
                return t('common.active');
            case StatusType.DEACTIVATED:
                return t('common.deactivated');
            case StatusType.ARCHIVED:
                return t('common.archived');
            case StatusType.DEFAULT:
                return t('common.default');
            case StatusType.PENDING:
                return t('common.pending');
            case StatusType.APPROVED:
                return t('common.approved');
            case StatusType.DENIED:
                return t('common.denied');
            case StatusType.REFUNDED:
                return t('common.refunded');
            case StatusType.DISCARDED:
                return t('common.discarded');
            case StatusType.WITHDRAWN:
                return t('common.withdrawn');
            case StatusType.PROCESSING:
                return t('common.processing');
            case StatusType.SUCCEEDED:
                return t('agencyBanking.success');
            case StatusType.UNCOLLECTED:
                return t('agencyBanking.uncollected');
            case StatusType.AWAITING_RECIPIENT_COLLECTION:
                return t('agencyBanking.awaitingRecipientCollection');
            case StatusType.MOVED_OUT_OF_YAFIKA:
                return t('agencyBanking.movedToRegulator');
            case StatusType.MOVED_TO_UNCLAIMED_FUNDS:
                return t('agencyBanking.movedToUnclaimedFunds');
            case StatusType.REFUND_TO_SENDER_BLOCKED:
                return t('agencyBanking.refundToSenderBlocked');
            case StatusType.REVERSED:
                return t('agencyBanking.reversed');
        }
    }

    return (
        <StyledStatusBox sx={{ ...sx }}>
            <Typography
                sx={{
                    ...(variant === 'status' ? typography.overline1 : typography.overline2),
                    color: getTextColor()
                }}>
                {getTitle()}
            </Typography>
        </StyledStatusBox>
    );
}
